import React, { useEffect } from 'react';

import classes from './BackdropModal.module.css';

interface BackdropModalProps {
    onHide: () => void;
    className?: string;
}

const BackdropModal: React.FC<BackdropModalProps> = ({ onHide, className }) => {
    useEffect(() => {
        const close = (e: KeyboardEvent) => {
            if (e.keyCode === 27) {
                onHide();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, [onHide]);

    return (
        <div
            onClick={onHide}
            className={`${classes['modal-container']} ${
                className ?? ''
            }`}></div>
    );
};

export default BackdropModal;
