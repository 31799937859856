import { t } from '@lingui/macro';
import { FC, memo, useState } from 'react';

import PermissionsMeControl from '@/Utils/permissionsMeControl';
import { wsDeleteMessage } from '@/Utils/socket-actions/MessageActions/messageActions';
import {
    deleteThreadMessage,
    handleCreateThread,
    handleOpenThread,
} from '@/Utils/socket-actions/ThreadActions/threadActions';
import BlueTick from '@/assets/icons/BlueTick.svg';
import EmptyTick from '@/assets/icons/EmptyTick.svg';
import SingleTick from '@/assets/icons/SingleTick.svg';
import checkIcon from '@/assets/icons/check.svg';
import copyIcon from '@/assets/icons/copy.svg';
import editIcon from '@/assets/icons/editManagement.svg';
import reactIcon from '@/assets/icons/react.svg';
import forwardIcon from '@/assets/icons/redo.svg';
import replyIcon from '@/assets/icons/reply.svg';
import threadIcon from '@/assets/icons/threads.svg';
import deleteIcon from '@/assets/icons/trashMessage.svg';
import { ALL_PERMISSIONS } from '@/constants/enum';
import useCustomSweetAlert from '@/hooks/useCustomSweetAlert';
import { useAppSelector } from '@/hooks/useRedux';
import {
    activeChannelIdSelector,
    channelDataDetailsSelector,
    userPermissionSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import { IChannelData } from '@/types';

import classes from './MessageActions.module.css';
import MessageAction from './message-action/MessageAction';
import MessageReactions from './message-reactions/MessageReactions';

interface IProps {
    seenCounts: { seen: number; unseen: number };
    usersLength: number;
    onCopy: () => void;
    msgId: string | undefined;
    inputType: string;
    user_id: string;
    isLastSeen: boolean | undefined;
    canDelete: boolean;
    className?: string;
    onMessageReaction: (e: string) => void;
    onReply: () => void;
    onForward: () => void;
    onEditMessage: () => void;
    message: string;
    threadId: undefined | string;
    removeThreadAction;
    threadLastMessage: boolean;
    handlereadUnread?: (e) => void;
}
const MessageActions: FC<IProps> = props => {
    const {
        seenCounts,
        usersLength,
        onCopy,
        msgId = undefined,
        inputType = '',
        onMessageReaction,
        className,
        user_id,
        canDelete,
        threadId,
        isLastSeen,
        message,
        handlereadUnread,
        onReply,
        onForward,
        onEditMessage,
        removeThreadAction,
        threadLastMessage,
    } = props;

    const [isCopied, setIsCopied] = useState(null);
    const [isReacting, setIsReacting] = useState(false);

    const { areYouSureModal } = useCustomSweetAlert();
    const editableDiv = document.getElementById('contentEditable');
    const editableDiv2 = document.getElementById('contentEditable2');

    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const userPermissions = useAppSelector(userPermissionSelector);
    const userId = useAppSelector(state => state.userReducer.user.id);
    const { lastActiveThread } = useAppSelector(state => state.threadsReducer);
    const channelDetails: IChannelData | {} | undefined = useAppSelector(
        channelDataDetailsSelector,
    );
    const subserverId = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const adminDeleteMessagesPermission =
        userPermissions?.['admin_message.delete'] || false;

    let channelPermissionsEdit: boolean, channelPermissionsDelete: boolean;

    if ('channelPermissions' in channelDetails) {
        channelPermissionsEdit =
            channelDetails?.channelPermissions?.['message.edit'];

        channelPermissionsDelete =
            channelDetails?.channelPermissions?.['message.delete'];
    }

    const copyMessageHandler = () => {
        onCopy();
        setIsCopied({ id: msgId, isCopied: true });
        setTimeout(() => {
            setIsCopied(null);
        }, 500);
    };

    const replyMessageHandler = () => {
        onReply();
        if (inputType === 'threadInput') {
            editableDiv2?.focus();
        } else {
            editableDiv?.focus();
        }
    };

    const reactMessageHandler = () => {
        setIsReacting(true);
    };

    const deleteMessageHandler = () => {
        areYouSureModal(null, null, true, () =>
            inputType === 'threadInput'
                ? deleteThreadMessage(
                      msgId,
                      lastActiveThread.channel_id,
                      lastActiveThread.id,
                  )
                : wsDeleteMessage(msgId),
        );
    };

    const editMessageHandler = () => {
        if (user_id === userId) {
            onEditMessage();
        }
    };

    const getSeenTick = () => {
        if (seenCounts?.seen === usersLength - 1) return BlueTick;
        else if (seenCounts?.unseen === usersLength) return SingleTick;
        else return EmptyTick;
    };

    const createThreadHandler = () => {
        handleCreateThread(
            userId,
            subserverId,
            activeChannelId,
            msgId,
            message,
        );
    };

    const handleThreadOpen = () => {
        handleOpenThread(threadId);
    };

    const messageActionsList = [
        {
            name: isCopied?.id == msgId ? t`Copied` : t`Copy`,
            icon: isCopied?.id == msgId ? checkIcon : copyIcon,
            onClick: copyMessageHandler,
            permissions: ALL_PERMISSIONS,
        },
        {
            name: t`Reply`,
            icon: replyIcon,
            onClick: replyMessageHandler,
            permissions: ['message.create'],
        },
        {
            name: t`Forward`,
            icon: forwardIcon,
            onClick: onForward,
            permissions: ALL_PERMISSIONS,
        },
        !removeThreadAction &&
            !threadLastMessage && {
                name:
                    !threadLastMessage && threadId
                        ? t`Open Thread`
                        : t`Create Thread`,
                icon: threadIcon,
                onClick:
                    !threadLastMessage && threadId
                        ? handleThreadOpen
                        : createThreadHandler,
                permissions: ALL_PERMISSIONS,
            },
        {
            name: t`Reaction`,
            icon: reactIcon,
            onClick: reactMessageHandler,
            permissions: ALL_PERMISSIONS,
        },
        canDelete &&
            channelPermissionsEdit && {
                name: t`Edit`,
                icon: editIcon,
                onClick: editMessageHandler,
                permissions: ['message.edit'],
            },
        (canDelete || adminDeleteMessagesPermission) &&
            channelPermissionsDelete && {
                name: t`Delete`,
                icon: deleteIcon,
                onClick: deleteMessageHandler,
                permissions: ['message.delete', 'admin_message.delete'],
            },

        canDelete &&
            !inputType &&
            isLastSeen && {
                name: t`Status`,
                icon: getSeenTick(),
                onClick: handlereadUnread,
                permissions: ALL_PERMISSIONS,
            },
    ];

    const messageActionsListPermissions = PermissionsMeControl(
        messageActionsList,
        'permissions',
    );

    return (
        <>
            <div className={className}>
                {isReacting ? (
                    <MessageReactions
                        onLeaveReaction={() => setIsReacting(false)}
                        onMessageReaction={onMessageReaction}
                    />
                ) : (
                    <div className={classes['actions-container']}>
                        {messageActionsListPermissions?.map((item, index) => (
                            <MessageAction
                                key={index}
                                onClick={item?.onClick}
                                actionIcon={item?.icon}
                                actionName={item?.name}
                                msgId={msgId}
                                imgStyle={
                                    item?.name?.trim() === 'Create Thread' ||
                                    item?.name?.trim() === 'Open Thread'
                                        ? {
                                              transform: 'rotate(45deg)',
                                              maxHeight: '13px',
                                              height: '100%',
                                          }
                                        : {}
                                }
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};
export default memo(MessageActions);
