import { Trans, t } from '@lingui/macro';
import { FC } from 'react';

import { AppButton, TextInput } from '@/UI';

import classes from '../../DevicePassword.module.css';

import usePutDevicePassword from './usePutDevicePassword';

type Props = {};

const PutDevicePassword: FC<Props> = () => {
    const { password, handlePassword, handleSubmit } = usePutDevicePassword();

    return (
        <>
            <div className={classes['headingWrapper']}>
                <span className="secondaryHeading marginVertical-small">
                    <Trans>Enter Device Key Password</Trans>
                </span>
            </div>
            <div className={classes['inputWrapper']}>
                <TextInput
                    type="password"
                    name="pwd"
                    placeHolder={t`Enter Device Key Password`}
                    label={t`Device Password`}
                    error={password.error}
                    onChange={handlePassword}
                />
            </div>
            <AppButton title={t`Continue`} onClick={handleSubmit} />
        </>
    );
};

export default PutDevicePassword;
