import { useState } from 'react';

import { CustomInput, EmojiKeyBoard, ImgButton } from '@/UI';
import EmojiIcon from '@/assets/icons/emojiPicker.svg';

import classes from './MessageItem.module.css';

type Props = {
    message;
    onKeyDown;
};
const EditingMessage: React.FC<Props> = props => {
    const { message, onKeyDown } = props;

    const [emojiIsShown, setEmojiIsShown] = useState(false);
    const [editedMessage, setEditedMessage] = useState(message);

    return (
        <div className={classes['relative']}>
            <CustomInput
                onFocus={() => setEmojiIsShown(false)}
                onKeyDown={onKeyDown}
                value={editedMessage}
                onChange={event => setEditedMessage(event.target.value)}
                className={classes['editMsgInput']}
            />

            <ImgButton
                type="button"
                className={classes['emoji-btn']}
                onClick={() => setEmojiIsShown(prev => !prev)}
                icon={EmojiIcon}
                disabled={false}
            />

            {emojiIsShown && (
                <EmojiKeyBoard
                    onSelect={(emoji: { emoji: string }) => {
                        setEditedMessage(prev => prev.concat(emoji.emoji));
                    }}
                    width="300px"
                    height="350px"
                    skinTonesDisabled={true}
                    searchDisabled={true}
                />
            )}
        </div>
    );
};
export default EditingMessage;
