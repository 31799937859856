import { IChannelData } from '@/types';

import EditChannelProvider from './EditChannelContext';
import EditChannelMain from './EditChannelMain';

type IProps = {
    channel: IChannelData;
    onClose: () => void;
};

const EditChannel = (props: IProps) => {
    const { channel, onClose } = props;
    return (
        <EditChannelProvider channel={channel} onClose={onClose}>
            <EditChannelMain />
        </EditChannelProvider>
    );
};

export default EditChannel;
