export const selectStyles = {
    control: styles => ({
        ...styles,
        backgroundColor: 'red',
        width: '100%',
    }),
    input: styles => ({ ...styles }),
    placeholder: styles => ({
        ...styles,
        color: '#586C9D',
    }),
    singleValue: styles => ({
        ...styles,
        color: '#586C9D',
    }),
    option: base => ({
        ...base,
        backgroundColor: 'transparent',
        ':hover': {
            backgroundColor: 'var(--main-card-blue-head-opacity)',
        },
        ':active': {
            backgroundColor: 'var(--main-card-blue-head-opacity)',
        },
    }),
};
