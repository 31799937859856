import { t } from '@lingui/macro';

import { AppButton } from '@/UI';
import { BUTTON_SIZE } from '@/constants/enum';
import { useAppSelector } from '@/hooks/useRedux';
import { userPermissionSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './EditChannel.module.css';
import { useEditChannelContext } from './EditChannelContext';

const RightContent: React.FC = () => {
    const { deleteChannelHandler, editChannelHandler } =
        useEditChannelContext();
    const permissions = useAppSelector(userPermissionSelector);

    return (
        <div>
            <div className={classes['button-wrapper']}>
                {permissions?.['channel.delete'] && (
                    <AppButton
                        onClick={deleteChannelHandler}
                        size={BUTTON_SIZE.small}
                        classStyles={classes['btn-color']}
                        title={t`Delete Channel`}
                    />
                )}
                <AppButton
                    onClick={editChannelHandler}
                    size={BUTTON_SIZE.small}
                    title={t`Save`}
                />
            </div>
        </div>
    );
};

export default RightContent;
