import { sendWebSocketData } from '@/Utils/webSocketUtils';
import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';

const PER_PAGE_COUNT = 20;

export const getNotificationsData = (page_num?: number) => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.getNotifications.action,
        data: {
            page_number: page_num,
            perPage: PER_PAGE_COUNT,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const updateNotificationsMetaData = () => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.getNotifications.action,
        data: {
            meta_data: true,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const markAllReadNotifications = () => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.markedAllRead.action,
        data: {},
    };
    sendWebSocketData(JSON.stringify(payload));
};
