import { FC } from 'react';

import classes from './Line.module.css';

interface IProps {
    className?: string;
}
const Line: FC<IProps> = props => {
    const { className = '' } = props;
    return <hr className={classes.line + ' ' + className} />;
};
export default Line;
