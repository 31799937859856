import { t } from '@lingui/macro';
import CryptoJS from 'crypto-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Failure from '@/assets/icons/failure.svg';
import Success from '@/assets/icons/success.svg';
import { SESSION_STORAGE } from '@/constants/enum';
import { useAppDispatch } from '@/hooks/useRedux';
import { ROOT_PATH } from '@/routes/router.types';
import { getUserData } from '@/store/user-reducer';

const useLogin = () => {
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const userEmail: string = localStorage.getItem('user_email');
    const emailRegex = /^[A-Z0-9._%+-]+(@[A-Z0-9.-]+\.[A-Z]{2,})?$/i;

    const [loader, setLoader] = useState(false);
    const [ModalProps, setModalProps] = useState({});
    const [modalVisible, setModalVisible] = useState(false);
    const [value, setValue] = useState({
        email: userEmail,
        password: '',
    });
    const [errorState, setError] = useState({
        emailError: '',
        passwordError: '',
    });

    const onCancelModal = () => setModalVisible(false);

    const onEmailChange = e => {
        setError({ ...errorState, emailError: '' });
        setValue({ ...value, email: e?.target?.value });
    };

    const onPasswordChange = e => {
        setError({ ...errorState, passwordError: '' });
        setValue({ ...value, password: e?.target?.value });
    };

    const validateFields = () => {
        const emailValidate =
            value.email === ''
                ? setError({
                      ...errorState,
                      emailError: t`This field is required`,
                  })
                : !emailRegex.test(value.email)
                ? setError({
                      ...errorState,
                      emailError: t`Please enter a valid email`,
                  })
                : true;
        const passwordValidate =
            value.password === ''
                ? setError(prev => ({
                      ...prev,
                      passwordError: t`This field is required`,
                  }))
                : true;

        return emailValidate && passwordValidate;
    };

    const successModalProps = {
        title: t`Login successful`,
        description: t`You are being redirected to the page please wait`,
        hideButton: true,
        icon: Success,
        showLoader: true,
    };

    const failureModalProps = {
        title: t`Incorrect entry`,
        description: t`The information you entered is incomplete or incorrect. Please check again`,
        icon: Failure,
        leftButtonTitle: t`Reset Password`,
        rightButtonTitle: t`Try Again`,
        leftOnClick: () => {
            navigation(ROOT_PATH.resetPassword);
        },
        rightOnClick: onCancelModal,
    };

    const handleLogin = async () => {
        setLoader(true);

        const validate = validateFields();
        if (validate) {
            const response = await dispatch(getUserData(value));
            if (response.payload?.status) {
                setLoader(false);
                setModalProps(successModalProps);
                setModalVisible(true);
                setTimeout(() => {
                    const hashPassword = CryptoJS.MD5(
                        value.password,
                    ).toString();
                    document.cookie = `${SESSION_STORAGE.devicePassword}=${hashPassword}; path=/`;
                    sessionStorage.setItem(
                        SESSION_STORAGE.devicePassword,
                        hashPassword,
                    );
                    navigation(ROOT_PATH.home);
                }, 500);
            } else {
                setLoader(false);
                failureModalProps.description = response.payload?.message;
                setModalProps(failureModalProps);
                setModalVisible(true);
            }
        } else {
            setLoader(false);
        }
    };

    return {
        onEmailChange,
        onPasswordChange,
        handleLogin,
        errorState,
        ModalProps,
        modalVisible,
        onCancelModal,
        value,
        loader,
    };
};

export default useLogin;
