import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { emailValidation } from '@/Utils/ValidationUtils';
import { ROOT_PATH } from '@/routes/router.types';
import { t } from '@lingui/macro';

const useResetPassword = () => {
    const nav = useNavigate();

    const [email, setEmail] = useState({ value: '', error: '' });
    const [modalVisible, setModalVisible] = useState(false);

    const onChange = e => {
        e.preventDefault();
        setEmail({ error: '', value: e.target.value });
    };

    const handleReset = () => {
        if (email.value === '')
            setEmail({ ...email, error: t`This field is required` });
        else if (emailValidation(email.value))
            setEmail({ ...email, error: t`Please enter a valid email` });
        else {
            setModalVisible(true);
        }
    };

    const onCancelModal = () => {
        setModalVisible(false);
        nav(ROOT_PATH.login);
    };
    return { onCancelModal, modalVisible, handleReset, onChange, email };
};

export default useResetPassword;
