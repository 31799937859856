import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { cleanEditorOutput } from '@/Utils/cleanEditorOutput';
import {
    LockThread,
    deleteThread,
    onJoinThread,
    onLeaveThread,
} from '@/Utils/socket-actions/ThreadActions/threadActions';
import { truncateText } from '@/Utils/textUtils';
import icon from '@/assets/icons/add-square.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close-header.svg';
import { ReactComponent as LockIcon } from '@/assets/icons/lock.svg';
import { ReactComponent as MuteIcon } from '@/assets/icons/sound-mute.svg';
import { ReactComponent as ExitIcon } from '@/assets/icons/trash-red.svg';
import { useAppSelector } from '@/hooks/useRedux';
import { closeThreadAction } from '@/store/thread-reducer/threadsSlice';

import DropDownOptions from '../thread-dropdown-options/DropDownOptions';

import classes from './ThreadChatHeader.module.css';

interface optionsTypes {
    id: string;
    icon: any;
    name: string;
}
const ThreadChatHeader: React.FC<{}> = ({}) => {
    const dispatch = useDispatch();
    const [isOptions, setIsOptions] = useState<boolean>(false);

    const userId = useAppSelector(state => state.userReducer.user.id);

    let subserverId = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const { activeThreadMembers, isLockThread, ownerId, lastActiveThread } =
        useAppSelector(state => state?.threadsReducer);

    const [isThreadMember, setIsthreadMember] = useState(false);
    const message = useAppSelector(state => {
        if (
            lastActiveThread?.channel_id &&
            lastActiveThread?.message_id &&
            state.messageReducer?.messagesList[lastActiveThread?.channel_id]
        ) {
            return state.messageReducer.messagesList[
                lastActiveThread.channel_id
            ][lastActiveThread.message_id];
        } else {
            return null;
        }
    });

    useEffect(() => {
        setIsthreadMember(activeThreadMembers?.includes(userId) || false);
    }, [activeThreadMembers, userId]);

    const threadOptionsArray: optionsTypes[] = [
        !isThreadMember &&
            ownerId !== userId && {
                id: 'joinThread',
                icon: icon,
                name: t`Join Thread`,
            },
        isThreadMember &&
            ownerId !== userId && {
                id: 'leaveThread',
                icon: icon,
                name: t`Leave Thread`,
            },
        {
            id: 'muteThread',
            icon: <MuteIcon width={16} height={18} />,
            name: t`Mute Thread`,
        },
        ownerId === userId &&
            !isLockThread && {
                id: 'lockThread',
                icon: <LockIcon width={16} height={18} />,
                name: t`Lock Thread`,
            },
        ownerId === userId &&
            isLockThread && {
                id: 'lockThread',
                icon: <LockIcon width={16} height={18} />,
                name: t`Unlock Thread`,
            },

        ownerId === userId && {
            id: 'deleteThread',
            icon: <ExitIcon width={16} height={18} />,
            name: t`Delete Thread`,
        },
    ];

    const getThreadOptions = () => {
        return threadOptionsArray.filter(Boolean);
    };
    const threadOptions = getThreadOptions();

    useEffect(() => {
        setOptions(threadOptions);
    }, [activeThreadMembers, userId, isThreadMember, isLockThread]);

    const [options, setOptions] = useState(threadOptions);

    const selectSettingHandler = async (event: optionsTypes) => {
        selectHandler(event);
    };

    const closeThreadHandler = () => {
        dispatch(closeThreadAction());
    };

    const optionsToggleHandler = () => {
        if (!isOptions) {
            setIsOptions(true);
        }
    };

    const handleJoinUser = () => {
        onJoinThread(
            userId,
            subserverId,
            lastActiveThread.channel_id,
            lastActiveThread.id,
        );
    };
    const handleLeaveUser = () => {
        onLeaveThread(
            userId,
            subserverId,
            lastActiveThread.channel_id,
            lastActiveThread.id,
        );
    };
    const handleDeleted = () => {
        deleteThread(
            subserverId,
            lastActiveThread.channel_id,
            lastActiveThread.id,
        );
    };

    const handleLockThread = () => {
        LockThread(
            userId,
            subserverId,
            lastActiveThread.channel_id,
            lastActiveThread.id,
            !isLockThread ? true : false,
        );
    };

    const selectHandler = data => {
        switch (true) {
            case data.id === 'joinThread':
                handleJoinUser();
                break;
            case data.id === 'muteThread':
                break;
            case data.id === 'lockThread':
                handleLockThread();
                break;
            case data.id === 'deleteThread':
                handleDeleted();
                break;
            case data.id === 'leaveThread':
                handleLeaveUser();
                break;
            default:
                break;
        }
        setIsOptions(false);
    };

    const threadName = lastActiveThread?.name;

    const messageContent = message?.message || message?.mediaFiles[0]?.name;
    const displayText = messageContent ? messageContent : threadName;
    const doc = new DOMParser().parseFromString(displayText, 'text/html');
    const textContent = doc.body.textContent || '';
    let headerName = cleanEditorOutput(textContent);
    headerName = truncateText(headerName, 40);

    return (
        <div className={classes['main-header']}>
            <div className={classes['d-flex']}>
                <h2 className={classes['head']}>
                    {headerName !== 'null' &&
                    headerName !== 'undefined' &&
                    headerName &&
                    headerName.length > 0
                        ? headerName
                        : t`[THREAD]`}
                </h2>
                <div className={classes['relative']}>
                    <div
                        className={classes['options']}
                        onClick={optionsToggleHandler}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    {isOptions && (
                        <DropDownOptions
                            options={options}
                            selectSettingHandler={selectSettingHandler}
                            onSelect={selectHandler}
                            onClose={() => {
                                setIsOptions(false);
                            }}
                            className={classes['thread-dropdown']}
                        />
                    )}
                </div>
                <CloseIcon
                    className={`${classes['close']}`}
                    onClick={closeThreadHandler}
                />
            </div>
        </div>
    );
};
export default ThreadChatHeader;
