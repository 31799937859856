import { useEffect } from 'react';

import { sendPing } from '@/Utils/sendPing';
import { isWebSocketConnected } from '@/Utils/webSocketUtils';
import { ReactComponent as PingIcon } from '@/assets/icons/ping.svg';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { resetPingPong } from '@/store/util-reducer';

const PingCount: React.FC<{}> = () => {
    const utilSelector = useAppSelector(state => state.utilReducer);
    const { pingRate, pongsCount, pingsCount } = utilSelector;
    const dispatch = useAppDispatch();

    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            if (pongsCount === pingsCount && isConnected) {
                sendPing();
            }
        }, 10000);
        return () => clearTimeout(timer);
    }, [pongsCount, pingsCount]);

    const isConnected = isWebSocketConnected();

    useEffect(() => {
        if (isConnected && activeServer) {
            dispatch(resetPingPong());
            sendPing();
        }
    }, [isConnected, activeServer]);

    return (
        <div>
            <PingIcon />
            {pingRate ? pingRate : '-'}
        </div>
    );
};
export default PingCount;
