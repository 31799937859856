import { t } from '@lingui/macro';
import { FC, memo, useEffect, useRef, useState } from 'react';

import { CustomInput, Modal } from '@/UI';
import Tabs from '@/UI/tabs/Tabs';
import {
    hideDirectChannel,
    onJoinChannel,
} from '@/Utils/socket-actions/ChannelActions/channelActions';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { notesActions } from '@/store/notes-reducer';
import { p2pActions } from '@/store/p2p-slice';
import {
    activeChannelIdSelector,
    channelDataSelector,
    memberDataSelector,
    publicChannelDataSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import { setActiveChannel } from '@/store/server-data-reducer/server-data-reducer';
import { IChannelData, IMemberData } from '@/types';

import ChannelListItem from './ChannelListItem';
import Empty from './Empty';
import classes from './NewChat.module.css';
import UserListItem from './UserListItem';

interface IProps {
    tabs: string[];
    tabsBody: (IMemberData[] | IChannelData[])[];
    onSubmitForward?: (item: (IChannelData | IMemberData)[]) => void;
    onClose: () => void;
    isForward: boolean;
    header?: string;
    onSubmitNewChat?: (userIdArray: string[]) => void;
}

const NewChat: FC<IProps> = props => {
    const {
        header = t`New Chat`,
        tabs,
        tabsBody,
        isForward,
        onSubmitForward,
        onClose,
        onSubmitNewChat,
    } = props;

    const inputRef = useRef(null);

    const dispatch = useAppDispatch();
    const [content, setContent] = useState<(IMemberData | IChannelData)[]>(
        tabsBody[0],
    );
    const [contentKey, setContentKey] = useState(0);

    const user = useAppSelector(state => state.userReducer.user);

    const [searchInputValue, setSearchInputValue] = useState('');
    const members = useAppSelector(state => memberDataSelector(state));
    const channels = useAppSelector(state => channelDataSelector(state));

    const publicChannels = useAppSelector(state =>
        publicChannelDataSelector(state),
    );
    const isNotesOpen = useAppSelector(state => state.notesReducer.isNotesOpen);
    const isP2PPanelOpen = useAppSelector(
        state => state.p2pReducer.isP2PPanelOpen,
    );

    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const onlyChannels = channels.filter(
        ch => ch.privacy !== 'direct' && !ch?.is_public,
    );
    // remove chat only -> public + private channels
    const mergedChannels = onlyChannels.concat(publicChannels);

    const changeTabHandler = (key: number) => {
        if (key !== contentKey) {
            setContent(tabsBody[key]);
            setContentKey(key);
            setSearchInputValue('');
        }
    };

    const inputChangeHanlder = event => {
        setSearchInputValue(event.target.value);
    };

    const selectUserHandler = item => {
        if (isNotesOpen) {
            dispatch(notesActions.toggleNotesWindow(false));
        }
        if (isP2PPanelOpen) {
            dispatch(p2pActions.toggleP2PPanel({ state: false }));
        }

        switch (isForward) {
            case true:
                let isDirectChat = undefined;
                // is a channel or user
                if (item?.id && !item?._id) {
                    isDirectChat = channels.find(
                        ch =>
                            ch.privacy === 'direct' &&
                            ((ch.name.split('_')[0] === item.id &&
                                ch.name.split('_')[1] === user.id) ||
                                (ch.name.split('_')[1] === item.id &&
                                    ch.name.split('_')[0] === user.id)),
                    );
                    if (isDirectChat?.is_hidden) {
                        // unhide direct channel if hidden
                        hideDirectChannel(isDirectChat._id, false);
                    }
                    // join direct channel if not active only
                    if (isDirectChat && isDirectChat._id !== activeChannelId) {
                        onJoinChannel(isDirectChat._id);
                    }

                    onClose();
                    onSubmitForward(isDirectChat);
                } else if (
                    (item?.privacy === 'channel' && item?._id) ||
                    isDirectChat
                ) {
                    if (item._id !== activeChannelId) {
                        onJoinChannel(item._id);
                    }
                    onClose();
                    onSubmitForward(item);
                }
                break;

            default:
                if (item?.privacy === 'channel' && item?._id) {
                    onJoinChannel(item._id);
                    props.onClose();
                } else {
                    dispatch(setActiveChannel(undefined));
                    onSubmitNewChat(
                        content === tabsBody[0] ? [item?._id] : [item?.id],
                    );
                }
                break;
        }
    };

    useEffect(() => {
        switch (contentKey) {
            case 1:
                if (searchInputValue.trim() !== '') {
                    setContent(
                        members.filter(user =>
                            user.name
                                .toLowerCase()
                                .includes(
                                    searchInputValue.trim().toLowerCase(),
                                ),
                        ),
                    );
                } else {
                    setContent(members);
                }
                break;

            default:
                if (searchInputValue.trim() !== '') {
                    setContent(
                        mergedChannels.filter(
                            ch =>
                                ch.name
                                    .toLowerCase()
                                    .includes(
                                        searchInputValue.trim().toLowerCase(),
                                    ) && ch.privacy !== 'direct',
                        ),
                    );
                } else {
                    setContent(
                        mergedChannels.filter(ch => ch.privacy !== 'direct'),
                    );
                }
                break;
        }
    }, [searchInputValue, contentKey]);

    return (
        <Modal
            removeBodyPadding
            className={classes['new-chat-modal']}
            header={header}
            onClose={onClose}>
            <Tabs tabs={tabs} onChangeTab={changeTabHandler}>
                <CustomInput
                    ref={inputRef}
                    value={searchInputValue}
                    placeholder={`${t`Search for a`} ${
                        contentKey === 0 ? t`channel` : t`user`
                    }`}
                    onChange={inputChangeHanlder}
                    className={classes['NewMessageInput']}
                />
                <div className={classes['list-wrap']}>
                    {content && content.length > 0 ? (
                        <ul className={classes['body-list']}>
                            {contentKey === 0
                                ? content.map((item, key) => (
                                      <ChannelListItem
                                          key={key}
                                          item={item as IChannelData}
                                          onChoose={() =>
                                              selectUserHandler(item)
                                          }
                                      />
                                  ))
                                : content.map((item, key) => (
                                      <UserListItem
                                          key={key}
                                          item={item as IMemberData}
                                          onChoose={() =>
                                              selectUserHandler(item)
                                          }
                                      />
                                  ))}
                        </ul>
                    ) : (
                        <Empty />
                    )}
                </div>
            </Tabs>
        </Modal>
    );
};
export default memo(NewChat);
