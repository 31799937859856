import { motion } from 'framer-motion';

import classes from './NoteDetails.module.css';
import NotesEditor from './NotesEditor';

const NoteDetailsContent: React.FC<{}> = () => {
    return (
        <motion.div
            initial={{ opacity: 0, x: 10 }}
            exit={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            className={`${classes['body']}`}>
            <NotesEditor />
        </motion.div>
    );
};
export default NoteDetailsContent;
