import CryptoJS from 'crypto-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { decryptPassword } from '@/Utils/cryptoUtils';
import { SESSION_STORAGE } from '@/constants/enum';
import { useAppSelector } from '@/hooks/useRedux';
import { ROOT_PATH } from '@/routes/router.types';
import { privateKeyArray } from '@/store/server-data-reducer/selectors-server-data';
import { t } from '@lingui/macro';


const usePutDevicePassword = () => {

    const [password, setPassword] = useState({ value: '', error: '' });
    const navigate = useNavigate();
    const isPrivateKeyAvailable: any[] = useAppSelector(state =>
        privateKeyArray(state),
    );
    const handlePassword = e => {
        setPassword({ value: e.target.value, error: '' });
    };

    const handleSubmit = () => {
        if (password.value === '') {
            return setPassword({
                ...password,
                error: t`This field is required`,
            });
        }
        const privateKey = decryptPassword(
            isPrivateKeyAvailable[0]?.privateKey,
            CryptoJS.MD5(password.value).toString(),
        );
        if (!privateKey) {
            return setPassword({
                ...password,
                error: t`Password Incorrect! Please try again`,
            });
        } else {
            setTimeout(() => {
                const hashPassword = CryptoJS.MD5(password.value).toString();

                sessionStorage.setItem(
                    SESSION_STORAGE.devicePassword,
                    hashPassword,
                );
                navigate(ROOT_PATH.home);
            }, 500);
        }
    };
    return { password, handlePassword, handleSubmit };
};

export default usePutDevicePassword;
