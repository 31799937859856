import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Channel {
    channel_id: string;
    conversations: number;
}

interface UserData {
    channels: Channel[];
    user_id: string;
}

interface DataState {
    data: UserData[];
    metaData:any
}

const initialState: DataState = {
    data: [],
    metaData:{}
};


const StatisticsSlice = createSlice({
    name: 'StatisticsSlice',
    initialState,
    reducers: {
        setStatisticsData: (state, action: PayloadAction<{data:UserData[],metaData:any}>) => {
            state.data = action.payload.data;
            state.metaData = action.payload.metaData
        },
    },
});
export const { setStatisticsData } = StatisticsSlice.actions;

export default StatisticsSlice.reducer;
