import { FC, memo } from 'react';

import classes from './AttachmentMenu.module.css';
import { ReplyContentProps } from './AttachmentTypes';

const ReplyContent: FC<ReplyContentProps> = memo(({ context }) => {
    const { messageText, replyfor, type } = context;

    let content = '';
    if (messageText && typeof messageText === 'string') {
        content = messageText;
    } else {
        if (typeof messageText === 'object') {
            content = messageText.content;
        }
    }

    return (
        <div className={classes['reply-part']}>
            <p>Replying to : {replyfor}</p>

            <p className={classes['mt-10']}>
                {content ? content : ''}
                {type === 'media' && (
                    <span className={classes['reply-to-media']}>
                        Attachment File
                    </span>
                )}
            </p>
        </div>
    );
});
export default ReplyContent;
