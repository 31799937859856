import React, { useState } from 'react';
import { Spinner } from 'react-activity';

import { BUTTON_SIZE } from '@/constants/enum';
import { ButtonSize, ButtonType } from '@/constants/enumTypes';

import classes from './AppButton.module.css';

interface AppButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    size?: ButtonSize | string;
    buttonType?: ButtonType | string;
    title: string;
    leftIcon?: React.ReactNode;
    onClick?: (event: any) => void;
    classStyles?: any;
    [rest: string]: any;
    disable?: any;
    btnCase?: { case: string; content: string[]; style: {}[] };
    loader?: boolean;
}

const buttonTypeClassMap: Record<ButtonType, string> = {
    primary: 'primary',
    secondary: 'secondary',
    transparent: 'transparent',
    whiteWithBorder: 'whiteWithBorder',
};

const AppButton = React.forwardRef<HTMLButtonElement, AppButtonProps>(
    (props, ref) => {
        const {
            size = 'large',
            buttonType = 'primary',
            title,
            leftIcon,
            rightIcon,
            onClick,
            classStyles,
            disable,
            btnCase,
            type,
            style,
            loader,
        } = props;
        const [btnStyle, setBtnStyle] = useState({
            content: btnCase?.content ? btnCase.content[0] : '',
            style: btnCase?.content ? btnCase.style[0] : {},
        });

        const [btnIsDisable, setBtnIsDisable] = useState(
            disable ? true : false,
        );
        const getButtonStyles = () => {
            let buttonStyles = `${classes['app-button']}`;

            if (size) {
                buttonStyles += ` ${classes[`${BUTTON_SIZE[size]}`]}`;
            }
            if (disable) {
                buttonStyles += ` ${classes['disabled']}`;
            } else if (buttonType) {
                buttonStyles += ` ${
                    classes[`${buttonTypeClassMap[buttonType]}`]
                }`;
            }

            return buttonStyles;
        };

        const clickHandler = event => {
            if (!disable) {
                if (btnCase) {
                    setBtnStyle({
                        content: btnCase?.content[1],
                        style: btnCase?.style[1],
                    });
                    setBtnIsDisable(true);
                }

                onClick && onClick(event);
            }
        };

        return (
            <button
                ref={ref}
                style={{ ...btnStyle.style, ...style }}
                className={` ${classStyles} ${getButtonStyles()} `}
                onClick={clickHandler}
                disabled={disable}
                type={type}>
                {leftIcon && (
                    <span className={classes['left-icon']}>{leftIcon}</span>
                )}
                {loader ? (
                    <Spinner />
                ) : btnCase?.content ? (
                    btnStyle?.content
                ) : (
                    title
                )}
                {rightIcon && (
                    <span className={classes['right-icon']}>{rightIcon}</span>
                )}
            </button>
        );
    },
);

export default AppButton;
