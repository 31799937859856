import { FC } from 'react';

import { DEFAULT_IMAGES } from '@/constants';
import { IServerUser } from '@/types/ImemberData';

import classes from '../TableMemberManagement.module.css';
import { ExternalBadge } from '@/UI/externalbadge/ExternalBadge';

type IProps = {
    item: IServerUser;
};

const UserColumn: FC<IProps> = ({ item }) => {
    return (
        <div className={classes['user-column']}>
            <img
                alt="profile"
                src={item?.profile_picture ?? DEFAULT_IMAGES.PROFILE_IMAGE}
            />
            <div className={classes['user-column-text']}>
                <span className={classes['user-name']}>
                    <span
                        style={{
                            color: item?.banned && 'red',
                            textDecoration: item?.banned && 'line-through',
                        }}>
                        {item?.name}
                    </span>
                    {item?.banned && (
                        <span className={classes['user-banned']}>Banned</span>
                    )}
                </span>
                <span className={classes['user-sub']}>{item?.username}</span>
            </div>
            <div style={{ marginLeft: 5 }}>
                {item?.external > 0 && <ExternalBadge />}
            </div>
        </div>
    );
};

export default UserColumn;
