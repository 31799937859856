import { useEffect, useRef } from 'react';
import { Toaster, toast } from 'sonner';

import { checkUserInteraction } from '@/Utils/checkUserInteraction';
import audioFile from '@/assets/audio/pop-2.mp3';
import { useAppSelector } from '@/hooks/useRedux';

import Toast from './Toast';
import classes from './Toast.module.css';

const ToastContainer = () => {
    const audioRef = useRef<HTMLAudioElement>(null);

    const toastState = useAppSelector(
        state => state.notificationsReducer.toast,
    );

    const playAudioFile = () => {
        const didInteract = checkUserInteraction();
        const aud = audioRef?.current;

        if (aud && didInteract) {
            aud.pause();
            aud.currentTime = 0;
            aud.play();
        }
    };
    useEffect(() => {
        if (toastState) {
            toast.custom(
                t => (
                    <Toast
                        onDismiss={() => toast.dismiss(t)}
                        content={toastState}
                    />
                ),
                { duration: 7000 },
            );
            playAudioFile();
        }
    }, [toastState]);

    return (
        <div className={classes['toaster']}>
            <audio
                ref={audioRef}
                src={audioFile}
                controls={false}
                style={{ visibility: 'hidden' }}></audio>
            <Toaster
                closeButton={true}
                offset={12}
                visibleToasts={20}
                position="bottom-right"
                className={classes['toast']}
            />
        </div>
    );
};
export default ToastContainer;
