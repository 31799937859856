import { useMemo } from 'react';

import { useAppSelector } from '@/hooks/useRedux';
import {
    activeChannelIdSelector,
    channelDataSelector,
    sectionDataSelector,
} from '@/store/server-data-reducer/selectors-server-data';

const useSections = (unreadOnly: boolean) => {
    const sections = useAppSelector(sectionDataSelector);
    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const channel = useAppSelector(state => channelDataSelector(state));
    const channelCount = useAppSelector(
        state =>
            state.serverDataReducer.channelCounts?.[
                state.serverDataReducer.activeServer
            ],
    );

    const columns = useMemo(() => {
        let undefinedCh = channel.filter(
            ch =>
                ch?.privacy === 'channel' &&
                (ch?.channel_section_id === '' ||
                    ch?.channel_section_id === 'undefined' ||
                    ch?.channel_section_id === undefined ||
                    ch?.channel_section_id === null),
        );

        let definedSections = sections;

        if (unreadOnly) {
            undefinedCh = undefinedCh?.filter(
                ch => channelCount?.[ch._id] > 0 || ch?._id === activeChannelId,
            );
            definedSections = definedSections?.filter(
                sector =>
                    sector?.channelList?.filter(
                        ch =>
                            channelCount?.[ch._id] > 0 ||
                            ch?._id === activeChannelId,
                    )?.length > 0,
            );
        }

        const undefinedSection = {
            channelList: undefinedCh,
            name: '-',
            _id: 'undefined',
        };
        return [undefinedSection, ...definedSections];
    }, [sections, unreadOnly, channel, activeChannelId, channelCount]);
    return columns;
};
export default useSections;
