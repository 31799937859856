export const addMemberSelectStyles = {
    menu: styles => ({
        ...styles,
        backgroundColor: 'var(--dark-input)',
        border: '1px solid #586C9D',
    }),
    control: styles => ({
        ...styles,
        backgroundColor: 'var(--dark-input)',
        width: '100%',
    }),
    input: styles => ({ ...styles }),
    placeholder: styles => ({
        ...styles,
        color: '#586C9D',
    }),
    singleValue: styles => ({
        ...styles,
        color: '#586C9D',
    }),
    option: base => ({
        ...base,
        backgroundColor: 'var(--dark-input)',
        ':hover': {
            backgroundColor: 'var(--main-card-blue-head-opacity)',
        },
        ':active': {
            backgroundColor: 'var(--main-card-blue-head-opacity)',
        },
    }),
};
