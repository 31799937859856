import { Trans, t } from '@lingui/macro';
import React, { FC, useState } from 'react';

import { ExternalBadge } from '@/UI/externalbadge/ExternalBadge';
import { getChannelDetails_activeServer } from '@/Utils/serverDataHandlers';
import {
    handleAddRoleInChannel,
    handleRemoveRoleInChannel,
} from '@/Utils/socket-actions/ChannelActions/channelActions';
import TrashIcon from '@/assets/icons/TrashIcon';
import { ReactComponent as AddUserIcon } from '@/assets/icons/add-user.svg';
import { DEFAULT_IMAGES } from '@/constants';
import { useCustomSweetAlert } from '@/hooks';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';
import { IChannelData, IMemberData } from '@/types';

import Title from '../Title';

import classes from './AddingRoleBasedMembers.module.css';

type Props = {
    sortByPriority: any;
    ch: IChannelData;
};

function AddingRoleBasedMembers(props: Props) {
    const { sortByPriority, ch } = props;

    const serverMemberData = useAppSelector(state => memberDataSelector(state));
    const { successAlertModal } = useCustomSweetAlert();

    const [selectedRoleItemId, setSelectedRoleItemId] = useState(
        sortByPriority[0]?._id,
    );

    const filterMember = serverMemberData?.filter(item =>
        item?.roles?.includes(selectedRoleItemId),
    );

    const { areYouSureModal } = useCustomSweetAlert();

    const handleAddClick = roleId => {
        const channel_data = getChannelDetails_activeServer(ch?._id);
        if (channel_data?.roleList.includes(roleId)) {
            successAlertModal(
                'This channel already has the specified role added',
            );
        } else {
            areYouSureModal(
                null,
                'Are you sure you want to ADD all users belonging to this role?',
                true,
                () => handleAddRoleInChannel(roleId, ch?._id),
            );
        }
    };

    const handleRemoveClick = roleId => {
        const channel_data = getChannelDetails_activeServer(ch?._id);
        if (!channel_data?.roleList.includes(roleId)) {
            successAlertModal(
                'This channel already has the specified role deleted',
            );
        } else {
            areYouSureModal(
                null,
                'Are you sure you want to REMOVE all users belonging to this role?',
                true,
                () => handleRemoveRoleInChannel(roleId, ch?._id),
            );
        }
    };

    return (
        !ch?.is_public && (
            <>
                <Title
                    title={t`Adding role-based users`}
                    subtitle={t`You can add role-based users`}
                />

                <div className={classes['pvt-container']}>
                    <div className={classes['role-conteiner']}>
                        <div className={classes['role-list-container']}>
                            <b>
                                <Trans>Roles</Trans>
                            </b>
                            <div
                                className={
                                    classes['role-list-scroll-container']
                                }>
                                {sortByPriority?.map((item, index) => (
                                    <div
                                        key={`${index}-${item?._id}`}
                                        onClick={() =>
                                            setSelectedRoleItemId(item?._id)
                                        }
                                        className={
                                            classes['role-list-row-container']
                                        }
                                        style={{
                                            borderColor:
                                                selectedRoleItemId ===
                                                    item?._id && 'white',
                                        }}>
                                        <p className="">{item?.name}</p>
                                        <div
                                            className={
                                                classes['room-icon-container']
                                            }>
                                            {!ch?.roleList?.includes(
                                                item?._id,
                                            ) && (
                                                <AddUserIcon
                                                    fill="var(--white)"
                                                    className={
                                                        classes[
                                                            'room-icon-role'
                                                        ]
                                                    }
                                                    onClick={() =>
                                                        handleAddClick(
                                                            item?._id,
                                                        )
                                                    }
                                                />
                                            )}

                                            {ch?.roleList?.includes(
                                                item?._id,
                                            ) && (
                                                <TrashIcon
                                                    stroke="var(--white)"
                                                    className={
                                                        classes[
                                                            'room-icon-role'
                                                        ]
                                                    }
                                                    onClick={() =>
                                                        handleRemoveClick(
                                                            item._id,
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            <b>
                                <Trans>Server Members</Trans>
                            </b>
                            <div
                                className={
                                    classes['role-member-list-container']
                                }>
                                {filterMember?.map(
                                    (item: IMemberData, index) => (
                                        <div
                                            key={index}
                                            className={
                                                classes[
                                                    'role-member-row-container'
                                                ]
                                            }>
                                            <img
                                                alt=""
                                                src={
                                                    item?.profile_picture ??
                                                    DEFAULT_IMAGES.PROFILE_IMAGE
                                                }
                                            />
                                            <div>{item?.name}</div>
                                            {item?.external > 0 && (
                                                <ExternalBadge />
                                            )}
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    );
}

export default AddingRoleBasedMembers;
