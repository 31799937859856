import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';

import { sendWebSocketData } from '../webSocketUtils';

export const getChannelRolePerm = () => {
    let payload = {
        action: 'role_permission',
        data: {},
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const getPermissionList = () => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.permissionList.action,
        data: {},
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const updateRolewithPermission = () => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.rolePermission.action,
        data: {},
    };
    sendWebSocketData(JSON.stringify(payload));
};
