export const initialValues = {
    modalProps: null,
    tabsManagementProps: null,
    setIsAddUserModal: null,
    filteredMemberData: null,
    handleSortData: null,
    isEditModal: null,
    isRolesModal: null,
    isAddUserModal: null,
    checkedRolesFilterCount: 0
};
