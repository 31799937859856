import { useContext } from 'react';

import UserModal from '../connections-section/user-modal/UserModal';

import classes from './Search.module.css';
import { SearchContext } from './SearchContext';
import SearchResultEmpty from './SearchResultEmpty';
import SearchResultList from './SearchResultList';

const SearchResult: React.FC<{}> = () => {
    const context = useContext(SearchContext);
    const { pageResult, searchType, userModal } = context;

    return (
        <div className={classes['results-container']}>
            {pageResult.length > 0 ? (
                <SearchResultList />
            ) : (
                <SearchResultEmpty />
            )}
            {searchType === 'Users' && userModal.state && (
                <UserModal
                    className={classes['user-modal']}
                    user={userModal.userData}
                />
            )}
        </div>
    );
};
export default SearchResult;
