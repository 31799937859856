import { useContext } from 'react';

import classes from './Search.module.css';
import SearchChannelItem from './SearchChannelItem';
import { SearchContext } from './SearchContext';
import SearchMessageItem from './SearchMessageItem';
import SearchUserItem from './SearchUserItem';

const SearchResultList: React.FC<{}> = () => {
    const context = useContext(SearchContext);
    const { pageResult, searchType } = context;

    return (
        <ul className={classes['search-list']}>
            {pageResult.map((result, key) => {
                if (searchType === 'Messages') {
                    return (
                        <SearchMessageItem
                            result={result}
                            key={`${key}-${result.message_id}`}
                            index={`${key}-${result.message_id}`}
                        />
                    );
                } else if (searchType === 'Users') {
                    return <SearchUserItem result={result} key={key} />;
                } else if (
                    searchType === 'Text Channels' ||
                    searchType === 'Voice Channels'
                ) {
                    return <SearchChannelItem result={result} key={key} />;
                }
            })}
        </ul>
    );
};
export default SearchResultList;
