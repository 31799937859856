import React from 'react';

import { ReactComponent as DownloadingIcon } from '@/assets/icons/download-media.svg';
import { ReactComponent as DownloadIcon } from '@/assets/icons/download-square.svg';

import classes from './CircularProgress.module.css';

interface CircularProgressProps {
    progress: number;
    isDownloading: boolean;
}

const CircularProgress: React.FC<CircularProgressProps> = ({
    progress = 0,
    isDownloading,
}) => {
    const radius = 12;
    const circumference = 2 * Math.PI * radius;
    const offset =
        circumference && progress
            ? circumference - (progress / 100) * circumference
            : circumference;

    return (
        <div className={classes['circular-progress-container']}>
            <svg
                className={`${!isDownloading && classes['hidden']} ${
                    classes['circular-progress-svg']
                }`}
                width="40"
                height="40"
                viewBox="0 0 40 40">
                <circle
                    className={classes['circular-progress-background']}
                    cx="20"
                    cy="20"
                    r={radius}></circle>
                <circle
                    className={classes['circular-progress-foreground']}
                    cx="20"
                    cy="20"
                    r={radius}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}></circle>
            </svg>
            {!isDownloading ? (
                <DownloadIcon
                    fill="var(--title-shadow)"
                    className={classes['icon']}
                />
            ) : (
                <DownloadingIcon
                    height={10}
                    fill="#C5CEE4"
                    className={`${classes['bounce']} ${classes['icon']}`}
                />
            )}
        </div>
    );
};

export default CircularProgress;
