import { Trans, t } from '@lingui/macro';

import closeIcon from '@/assets/icons/close.svg';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';

import MediaMessage from '../../media-message/MediaMessage';

import classes from './ReplyMessage.module.css';

type IProps = {
    onCloseReply: () => void;
    replyData?: {
        user_id?: string;
        messageText?: string | { content: string };
    };
};
const ReplyMessage = props => {
    const { onCloseReply, replyData } = props;

    const closeReplyHandler = () => {
        onCloseReply();
    };

    const members = useAppSelector(state => memberDataSelector(state));
    const member = members.find(e => e.id === replyData.user_id);

    return (
        <div id={replyData.id} className={classes['reply-container']}>
            <div className={classes['d-inline-f']}>
                <p className={classes.italic}>
                    <Trans>Reply to</Trans>:
                </p>
                <p>{member?.name ?? '--USER--'}</p>
            </div>
            <p style={{ marginTop: '8px' }}>
                {replyData?.messageText &&
                    typeof replyData?.messageText === 'string' &&
                    replyData.messageText}

                {replyData?.messageText?.content &&
                    replyData.messageText.content}
            </p>
            {replyData?.mediaFiles && (
                <MediaMessage
                    noCopy
                    className={classes['reply-media']}
                    mediaFiles={replyData.mediaFiles}
                />
            )}

            <img
                onClick={closeReplyHandler}
                className={classes.close}
                src={closeIcon}
                alt={t`close`}
            />
        </div>
    );
};
export default ReplyMessage;
