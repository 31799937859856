import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import Login from '@/components/Login/Login';
import { SESSION_STORAGE } from '@/constants/enum';
import { useAppSelector } from '@/hooks/useRedux';
import { ROOT_PATH } from '@/routes/router.types';

const LoginPage: FC = () => {
    const user = useAppSelector(state => state.userReducer.user);
    const isDevicePassword = sessionStorage.getItem(
        SESSION_STORAGE.devicePassword,
    );
    return user.token !== '' && isDevicePassword ? (
        <Navigate to={ROOT_PATH.auth} />
    ) : (
        <Login />
    );
};
export default LoginPage;
