import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';

import { sendWebSocketData } from '../../webSocketUtils';

export const addNewChannelToInterServer = (channelData: any) => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.AddChannelToOtherServer.action,
        data: channelData,
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const deleteChannelToInterServer = (id: any) => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.deleteInterSubserverChannel.action,
        data: { id: id },
    };
    sendWebSocketData(JSON.stringify(payload));
};
