import fetchData from '@/Utils/fetchData';
import { REQUEST_TYPE } from '@/constants/requstTypes';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const VERSION = '/api/v4';
const ROUTE = VERSION + '/organisation/';

export const getOrganizations = async () => {
    const url = BASE_URL + VERSION + '/organisations';
    const res = await fetchData(REQUEST_TYPE.GET, url);
    return res;
};

export const getOrganizationMembers = async organizationId => {
    const url = BASE_URL + ROUTE + `${organizationId}/members`;
    const res = await fetchData(REQUEST_TYPE.GET, url);
    return res;
};

export const getOrganizationServers = async organizationId => {
    const url = BASE_URL + ROUTE + `${organizationId}/servers`;
    const res = await fetchData(REQUEST_TYPE.GET, url);
    return res;
};

export const putCreateOrganizationMember = async payload => {
    const { organizationId } = payload;
    const url = BASE_URL + ROUTE + `${organizationId}/members`;
    const res = await fetchData(REQUEST_TYPE.PUT, url, {}, payload);
    return res;
};

export const postAddMemberServer = async payload => {
    const { organizationId, serverId, user_id, is_admin, requestType } =
        payload;

    const data = { user_id, is_admin };

    const url =
        BASE_URL + ROUTE + `${organizationId}/server/${serverId}/members?`;
    const res = await fetchData(requestType, url, {}, data);
    return res;
};

export const updateAddMemberServer = async payload => {
    const { organizationId, userId, formData } = payload;
    const url = BASE_URL + ROUTE + `${organizationId}/members/update/${userId}`;
    // const data = {}
    // formData.forEach((value,key)=>{data[key]=value})
    const res = await fetchData(REQUEST_TYPE.PATCH, url, {}, formData);
    return res;
};
