import { t } from '@lingui/macro';
import { ChangeEvent, useContext } from 'react';

import { ReactComponent as CloseIcon } from '@/assets/icons/search-close.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search-icon.svg';
import { NotesContext } from '@/context/notes/NotesContext';

import classes from './NoteDetails.module.css';

const NoteSearch: React.FC<{}> = () => {
    const notesContext = useContext(NotesContext);
    const { searchValue, setSearchValue } = notesContext;

    const changeSearchHandler = (ev: ChangeEvent<HTMLInputElement>) => {
        const value = ev.target.value;
        setSearchValue(value);
    };

    const closeSearchHandler = () => {
        setSearchValue('');
    };

    return (
        <div className={classes['search']}>
            <SearchIcon height={18} className={classes['search-icon']} />
            <input
                placeholder={t`Search`}
                value={searchValue}
                onChange={changeSearchHandler}
                className={classes.input}
                type="text"
            />
            <CloseIcon
                className={`${classes['search-close']}
                        ${
                            searchValue && searchValue !== ''
                                ? classes['visible']
                                : ''
                        }
                    `}
                onClick={closeSearchHandler}
            />
        </div>
    );
};
export default NoteSearch;
