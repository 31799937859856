import { Trans } from '@lingui/macro';
import { useEffect, useRef, useState } from 'react';
import { ViewportList } from 'react-viewport-list';

import { Pagination } from '@/UI';
import UserFilter from '@/UI/userFilter/UserFilter';

import classes from './AuditLogManagement.module.css';
import AuditLogRow from './components/AuditLogRow';
import LogTypeFilterModal from './components/LogTypeFilter/LogTypeFilterModal';
import useAuditLog from './useAuditLog';

type RowItem = {
    action_by: string;
    audit_target: string;
    created_at: string;
    log_details: any[];
    log_type: string;
    subserver_id: string;
    updatedAt: string;
    _id: string;
};

function AuditLogManagement() {
    const {
        memberList,
        channelList,
        sectionList,
        getAudit,
        roleList,
        // handleScroll,
        isURL,
        setPageIndex,
        pageIndex,
        metaData,

        // LogTypeFilterModalProps
        isLogTypeCheckboxSelected,
        onChangeCheckboxLogType,
        allSelectLogTypeCheckbox,
        allClearLogTypeCheckbox,
        checkboxData,

        // Date
        allClearDateFilter,
        onChangeStartDate,
        onChangeEndDate,
        startDate,
        endDate,
        handleChangeData,
        selectedCheckboxUsersData,
    } = useAuditLog();

    const LogTypeFilterModalProps = {
        isLogTypeCheckboxSelected,
        onChangeCheckboxLogType,
        allSelectLogTypeCheckbox,
        allClearLogTypeCheckbox,
        checkboxData,
        // date
        onChangeStartDate,
        onChangeEndDate,
        startDate,
        endDate,
        allClearDateFilter,
    };

    const [isOpenTableData, setIsOpenTableData] = useState<string[]>([]);

    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = 0;
        }
    }, [pageIndex]);


    return (
        <div className={classes['container']}>
            <div className={classes['header']}>
                <h2 style={{ margin: 0 }}>
                    <Trans>Audit Log</Trans>
                </h2>
            </div>
            <div className={classes['top-container']}>
                <Pagination metaData={metaData} setPageIndex={setPageIndex} />
                <div className={classes['top-container-right-seciton']}>
                    <UserFilter
                        selectedCheckboxUsersData={selectedCheckboxUsersData}
                        onChangeData={handleChangeData}
                    />
                    <LogTypeFilterModal {...LogTypeFilterModalProps} />
                </div>
            </div>
            <div className={classes['top-container']}>
                <div
                    className={classes['date-label']}
                    style={{
                        display: 'flex',
                        gap: 16,
                    }}>
                    <p>
                        <Trans>Total Log</Trans>:&nbsp;
                        <b>{getAudit?.meta_data?.total_audit}</b>
                    </p>
                    <p>
                        <Trans>Total Page</Trans>:&nbsp;
                        <b>{getAudit?.meta_data?.total_pages}</b>
                    </p>
                </div>
            </div>
            {!getAudit?.audit_log && (
                <div
                    style={{
                        margin: '0px 16px',
                        width: 'fit-content',
                        textAlign: 'center',
                        fontSize: 12,
                    }}>
                    <b><Trans>No Result Found</Trans>!</b>
                </div>
            )}
            {getAudit?.audit_log?.length > 0 && (
                <div ref={listRef} className={classes['logs-container']}>
                    <ViewportList
                        initialIndex={-1}
                        indexesShift={0}
                        initialAlignToTop={true}
                        overscan={10}
                        items={getAudit?.audit_log ?? []}>
                        {(item: RowItem, key) => (
                            <div key={key}>
                                {
                                    <AuditLogRow
                                        item={item}
                                        setIsOpenTableData={setIsOpenTableData}
                                        isOpenTableData={isOpenTableData}
                                        memberList={memberList}
                                        channelList={channelList}
                                        sectionList={sectionList}
                                        isURL={isURL}
                                        roleList={roleList}
                                    />
                                }
                            </div>
                        )}
                    </ViewportList>
                </div>
            )}
        </div>
    );
}

export default AuditLogManagement;
