const SquareAddIcon = ({ fill }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="add">
                <g id="vuesax/bulk/add">
                    <g id="add_2">
                        <path
                            id="Vector"
                            d="M14.1602 5.20533V5.20671V10.7934C14.1602 11.9073 13.8301 12.7364 13.2799 13.2865C12.7298 13.8366 11.9007 14.1667 10.7868 14.1667H5.20683C4.09294 14.1667 3.264 13.8366 2.71394 13.2859C2.1638 12.7351 1.8335 11.9043 1.8335 10.7867V5.20671C1.8335 4.09281 2.16359 3.26372 2.71372 2.71359C3.26384 2.16347 4.09293 1.83337 5.20683 1.83337H10.7935C11.9075 1.83337 12.7364 2.16351 13.2856 2.71337C13.8346 3.26313 14.1632 4.09175 14.1602 5.20533Z"
                            stroke={fill ? fill : 'white'}
                        />
                        <path
                            id="Vector_2"
                            d="M11.5556 7.55556H8.44444V4.44444C8.44444 4.20148 8.24296 4 8 4C7.75704 4 7.55556 4.20148 7.55556 4.44444V7.55556H4.44444C4.20148 7.55556 4 7.75704 4 8C4 8.24296 4.20148 8.44444 4.44444 8.44444H7.55556V11.5556C7.55556 11.7985 7.75704 12 8 12C8.24296 12 8.44444 11.7985 8.44444 11.5556V8.44444H11.5556C11.7985 8.44444 12 8.24296 12 8C12 7.75704 11.7985 7.55556 11.5556 7.55556Z"
                            fill={fill ? fill : 'white'}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
export default SquareAddIcon;
