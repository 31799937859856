import { t } from '@lingui/macro';
import React, { useState } from 'react';

import { Modal } from '@/UI';
import settings from '@/assets/icons/setting.svg';
import { useAppSelector } from '@/hooks/useRedux';

import classes from './SettingsModal.module.css';
import { componentMap } from './componentMap';

type Props = {};

const SettingsModal = (props: any) => {
    const initialComponent = useAppSelector(
        state => state.utilReducer.settingsModal.component,
    );
    const [selectedComponent, setSelectedComponent] = useState(
        initialComponent ?? 'profileSettings',
    );

    const selectComponent = component => {
        setSelectedComponent(component);
    };

    const SettingsModalNav: React.FC<{}> = () => {
        return (
            <div className={classes['left-navbar']}>
                {Object.keys(componentMap).map(componentId => {
                    const isActive = selectedComponent === componentId;
                    const componentClass = `${classes['navbar-item']} ${
                        isActive ? classes['active'] : ''
                    }`;
                    return (
                        <div
                            className={componentClass}
                            key={componentId}
                            onClick={() => selectComponent(componentId)}>
                            <img
                                alt=""
                                src={componentMap[componentId].icon}
                                className={classes['navbar-icon']}
                            />
                            <span className={classes['navbar-text']}>
                                {componentMap[componentId].name}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <Modal
            removeBodyPadding
            header={t`Settings`}
            className={classes['container']}
            onClose={() => props.onClose()}
            headerIcon={settings}
            bodyStyles={{ height: '100%' }}
            headerClassName={classes['container-header']}>
            <div className={classes['modal-container']}>
                <SettingsModalNav />
                <div className={classes['right-content']}>
                    {React.createElement(
                        componentMap[selectedComponent].component,
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default SettingsModal;
