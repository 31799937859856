import { useCallback, useEffect, useState } from 'react';

interface LongPressEventHandlers {
    onTouchStart: () => void;
    onTouchEnd: () => void;
    onTouchMove: () => void;
    onMouseDown: () => void;
    onMouseUp: () => void;
    onMouseLeave: () => void;
}

const useLongPress = (
    callback: () => void,
    ms: number = 500,
): LongPressEventHandlers => {
    const [startLongPress, setStartLongPress] = useState(false);

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        if (startLongPress) {
            timerId = setTimeout(callback, ms);
        } else {
            clearTimeout(timerId);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [startLongPress, callback, ms]);

    const start = useCallback(() => {
        setStartLongPress(true);
    }, []);

    const stop = useCallback(() => {
        setStartLongPress(false);
    }, []);

    return {
        onTouchStart: start,
        onTouchEnd: stop,
        onTouchMove: stop,
        onMouseDown: start,
        onMouseUp: stop,
        onMouseLeave: stop,
    };
};

export default useLongPress;
