import { Trans, t } from '@lingui/macro';

import { AppButton } from '@/UI';
import { closeWebSocket } from '@/Utils/webSocketUtils';

import classes from './Newtab.module.css';

const Newtab = () => {
    closeWebSocket();
    return (
        <div className={classes['wrap']}>
            <h1 style={{ margin: 0 }}>
                <Trans>App already running</Trans>
            </h1>
            <p>
                <Trans>
                    App is already running in another tab please switch to
                    another tab
                </Trans>
            </p>
            <AppButton
                title={t`Use Here`}
                onClick={() => window.location.reload()}
                classStyles={classes['btn']}
            />
        </div>
    );
};

export default Newtab;
