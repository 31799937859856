import { Trans } from '@lingui/macro';
import React from 'react';

import { formatCreatedDate } from '@/Utils/utils';
import arrow from '@/assets/icons/arrow-down.svg';
import CustomErrorBoundary from '@/components/CustomErrorBoundary/CustomErrorBoundary';
import { DEFAULT_IMAGES } from '@/constants';

import classes from '../AuditLogManagement.module.css';
import { LOG_TYPE_MAPPING } from '../useAuditLog';

interface Item {
    action_by: string;
    audit_target: string;
    created_at: string;
    log_details: any[];
    log_type: string;
    subserver_id: string;
    updatedAt: string;
    _id: string;
}

type RowProps = {
    item: Item;
    memberList: any;
    channelList: any;
    sectionList: any;
    isOpenTableData: string[];
    setIsOpenTableData: React.Dispatch<React.SetStateAction<any>>;
    isURL: any;
    roleList: any;
};

function AuditLogRow(props: RowProps) {
    const handleToggle = () => {
        if (props.isOpenTableData?.includes(props?.item?._id)) {
            props.setIsOpenTableData(prev =>
                prev.filter(id => id !== props?.item?._id),
            );
        } else {
            props.setIsOpenTableData((prev: string[]) => [
                ...prev,
                props.item._id,
            ]);
        }
    };
    const logTypeMapping = LOG_TYPE_MAPPING[props?.item?.log_type] || {
        icon: null,
        text: null,
        targetActionType: null,
    };
    const { icon, text: logTypeText, targetActionType } = logTypeMapping;

    let actionByUser = props.memberList[props?.item?.action_by];
    let auditTarget;

    switch (targetActionType) {
        case 'member':
            auditTarget =
                props.memberList?.[props?.item?.audit_target]?.username;
            break;
        case 'channel':
            const selectedChannel = props?.channelList?.find(
                item => item?._id === props?.item?.audit_target,
            );
            auditTarget = selectedChannel?.name ?? (
                <i className="font-10 font-sm-7">-Private Channel-</i>
            );
            break;
        case 'section':
            auditTarget = props.sectionList?.[props?.item?.audit_target]?.name;
            break;
        case 'role':
            const selectedRole = props?.roleList?.find(
                item => item?._id === props?.item?.audit_target,
            );
            auditTarget = selectedRole?.name;
            break;

        default:
            auditTarget = null;
            break;
    }

    return (
        <CustomErrorBoundary showErrorDetail>
            <div className={classes['log-row-wrap']}>
                <div
                    onClick={() => handleToggle()}
                    style={{
                        cursor:
                            props?.item?.log_details?.length > 0 && 'pointer',
                    }}
                    className={classes['log-row-container']}>
                    <div className="">
                        <img alt="" height={24} width={24} src={icon} />
                    </div>
                    <div>
                        <img
                            alt=""
                            className={classes['profile-picture-img']}
                            src={
                                actionByUser?.profile_picture ??
                                DEFAULT_IMAGES.PROFILE_IMAGE
                            }
                        />
                    </div>
                    <div className="">
                        <div className={classes['description-label-container']}>
                            <div className={classes['description-label']}>
                                <b>{logTypeText}</b>
                                <div className={classes['small-label']}>
                                    {formatCreatedDate(props?.item?.updatedAt)}
                                </div>
                            </div>
                            <div className="">
                                <div className={classes['small-label']}>
                                    <Trans>Action By</Trans> :{' '}
                                    <b>{actionByUser?.username}</b>
                                </div>
                                <div className={classes['small-label']}>
                                    <Trans>Target</Trans> : <b>{auditTarget}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props?.item?.log_details?.length > 0 && (
                        <div className={classes['arrow-area']}>
                            <div className={classes['small-label']}>
                                <Trans>Click for details</Trans>
                            </div>
                            <img
                                alt=""
                                style={{
                                    transform:
                                        props.isOpenTableData?.includes(
                                            props?.item?._id,
                                        ) && 'rotate(180deg)',
                                }}
                                src={arrow}
                            />
                        </div>
                    )}
                </div>
                {/* open section */}
                {props?.item?.log_details?.length > 0 &&
                    props.isOpenTableData?.includes(props?.item?._id) && (
                        <div className={classes['log-details-container']}>
                            <table className={classes['table']}>
                                <thead>
                                    <tr>
                                        <td>
                                            <b>
                                                <Trans>Changed Data</Trans>
                                            </b>
                                        </td>
                                        <td>
                                            <b>
                                                <Trans>New Value</Trans>
                                            </b>
                                        </td>
                                        <td>
                                            <b>
                                                <Trans>Previous Value</Trans>
                                            </b>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props?.item?.log_details?.map(
                                        (item, index) => {
                                            const replacedValue =
                                                item?.data?.replace(/_/g, ' ');

                                            return (
                                                <tr
                                                    key={index}
                                                    className={
                                                        classes['table-tr']
                                                    }>
                                                    <td>
                                                        <div>
                                                            <b
                                                                style={{
                                                                    textTransform:
                                                                        'capitalize',
                                                                }}>
                                                                {replacedValue}
                                                            </b>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className={
                                                                classes[
                                                                    'character-limit'
                                                                ]
                                                            }>
                                                            <p
                                                                className={
                                                                    classes[
                                                                        'small-label'
                                                                    ]
                                                                }>
                                                                {props?.isURL(
                                                                    item?.new_value,
                                                                ) ? (
                                                                    <a
                                                                        className={
                                                                            classes[
                                                                                'small-label'
                                                                            ]
                                                                        }
                                                                        style={{
                                                                            textDecoration:
                                                                                'underline',
                                                                        }}
                                                                        href={
                                                                            item?.new_value
                                                                        }
                                                                        target="_blank">
                                                                        <Trans>
                                                                            Go
                                                                            To
                                                                            Link
                                                                        </Trans>
                                                                    </a>
                                                                ) : (
                                                                    item?.new_value
                                                                )}
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className={
                                                                classes[
                                                                    'character-limit'
                                                                ]
                                                            }>
                                                            <p
                                                                className={
                                                                    classes[
                                                                        'small-label'
                                                                    ]
                                                                }>
                                                                {props?.isURL(
                                                                    item?.previous_value,
                                                                ) ? (
                                                                    <a
                                                                        className={
                                                                            classes[
                                                                                'small-label'
                                                                            ]
                                                                        }
                                                                        style={{
                                                                            textDecoration:
                                                                                'underline',
                                                                        }}
                                                                        href={
                                                                            item?.previous_value
                                                                        }
                                                                        target="_blank">
                                                                        Go To
                                                                        Link
                                                                    </a>
                                                                ) : (
                                                                    item?.previous_value
                                                                )}
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
            </div>
        </CustomErrorBoundary>
    );
}

export default AuditLogRow;
