import { error } from 'console';
import { memo, useEffect, useState } from 'react';

import formatFileSize from '@/Utils/fileSzeFormatter';
import { addFileToDB } from '@/Utils/mediaFilesUtils';
import { downloadFile } from '@/Utils/serverMediaHandler.js';
import { truncateFileName } from '@/Utils/truncateFileName';
import DownloadBtn from '@/components/download-btn/DownloadBtn';
import DownloadedTypeIcon from '@/components/download-btn/DownloadedTypeIcon';
import { useAppSelector } from '@/hooks/useRedux';
import { RootState } from '@/store';

import classes from './SelectedMediaDisplay.module.css';
import { DownloadMediaProps } from './SelectedMediaTypes';

const DownloadMedia: React.FC<DownloadMediaProps> = props => {
    const { file, onDownloadComplete } = props;

    const [downloadError, setDownloadError] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState<number | null>(
        null,
    );

    const userSelector = useAppSelector(
        (state: RootState) => state.userReducer,
    );

    const { token } = userSelector.user;

    const outputFileName = truncateFileName(file?.name ?? '----');

    const downloadMediaHandler = async () => {
        setIsDownloading(true);
        await loadMediaSaveDB(file);
        setIsDownloading(false);
    };

    const handleDownloadProgress = (progress: number) => {
        const roundedProgress = Math.round(progress);
        setDownloadProgress(roundedProgress);
    };

    const loadMediaSaveDB = async fileProps => {
        let fileBlob: Blob;
        let src: string;
        let config: {
            type?: string;
            name?: string;
            data?: File;
            id?: string;
        };

        try {
            const { fileLink, fileInfo, error } = await downloadFile(
                {},
                fileProps,
                token,
                handleDownloadProgress,
            );

            if (error) return setDownloadError('Error Downloading file!');

            config = {
                data: fileLink,
                type: fileInfo?.type,
                name: fileInfo?.name,
                id: fileProps.fileKey,
            };
            // @ts-ignore
            await addFileToDB(config);
            fileBlob = new Blob([fileLink], {
                type: fileInfo?.type,
            });
            src = URL.createObjectURL(fileBlob);
            onDownloadComplete();
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    // useEffect(() => {
    //     if (+file?.size < 1 * 1024 * 1024) {
    //         downloadMediaHandler();
    //     }
    // }, [file?.size]);

    return (
        <div
            className={`${downloadError ? classes['error'] : ''} ${
                classes['download-file-container']
            }`}>
            <DownloadedTypeIcon type={file.type ?? ''} />
            {downloadError && <p>{downloadError}</p>}
            {!downloadError && (
                <div className={classes['mr-auto']}>
                    <p className={classes['label-name']}>{outputFileName}</p>
                    <p className={classes['label-size']}>
                        {file?.size ? `${formatFileSize(file?.size)}` : '- MB'}
                    </p>
                </div>
            )}

            <DownloadBtn
                type={file?.type ?? null}
                isDownloading={isDownloading}
                onDownload={downloadMediaHandler}
                downloaded={downloadProgress}
            />
        </div>
    );
};
export default memo(DownloadMedia);
