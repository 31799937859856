import classes from './Switch.module.css';

type Props = {
    className?: string;
    onChange: (value: boolean) => void;
    checked: boolean;
    disabled?: boolean;
    id?: string;
};

const Switch = (props: Props) => {
    const onChange = event => {
        props.onChange(event.target.checked);
    };

    const {
        id = `switcher-${Math.random()}`,
        className = '',
        checked = false,
        disabled = false,
    } = props;

    return (
        <div
            id={id}
            className={`${className} ${classes['checkbox-indicator']}`}>
            <input
                onChange={onChange}
                type="checkbox"
                checked={checked}
                disabled={disabled}
                className={classes['input']}
            />
            <div className={classes['check-dot']}></div>
        </div>
    );
};

export default Switch;
