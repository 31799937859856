import { t } from '@lingui/macro';

import { Modal } from '@/UI';

import classes from './EditChannel.module.css';
import { useEditChannelContext } from './EditChannelContext';
import Tabs from './Tabs';
import TabsContent from './TabsContent';

const EditChannelMain: React.FC = () => {
    const { onClose } = useEditChannelContext();

    return (
        <Modal
            bodyStyles={{ padding: '0px 16px 0px 0px' }}
            header={t`Edit Channel`}
            className={classes['container']}
            onClose={onClose}
            headerClassName={classes['container-header']}>
            <div className={classes['modal-container']}>
                <Tabs />
                <TabsContent />
            </div>
        </Modal>
    );
};
export default EditChannelMain;
