export function sortMediaOrder(a, b) {
    const typeOrder = { image: 1, video: 2 };

    const getTypeOrder = type => {
        const typePrefix = type.split('/')[0];
        return typeOrder[typePrefix] || 3; 
    };

    const typeA = a.type?.toLowerCase() ?? '';
    const typeB = b.type?.toLowerCase() ?? '';

    const orderA = getTypeOrder(typeA);
    const orderB = getTypeOrder(typeB);

    return orderA - orderB;
}
