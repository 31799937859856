import React, { TextareaHTMLAttributes } from 'react';

import classes from './CustomTextArea.module.css';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    name: string;
    className?: string;
}
const CustomTextArea = React.forwardRef<HTMLTextAreaElement, Props>(
    (props, ref) => {
        const { label, name, className } = props;
        return (
            <>
                {label && (
                    <label className={classes.label} htmlFor={name}>
                        {label}
                    </label>
                )}
                <textarea
                    {...props}
                    name={name}
                    className={classes.areaText + ' ' + className}
                    ref={ref}
                />
            </>
        );
    },
);
export default CustomTextArea;
