import classes from './AttachmentMenu.module.css';

const UploadProgress: React.FC<{ uploadProgress: number }> = ({
    uploadProgress,
}) => {
    return (
        <div className={classes['upload-container']}>
            <div
                className={classes['upload-progress-bar']}
                style={{ width: `${uploadProgress}%` }}
            />
            <div className={classes['upload-info']}>
                <b>{uploadProgress}%</b>
            </div>
        </div>
    );
};
export default UploadProgress;
