import store from '@/store';

import {
    base64ToArrayBuffer,
    decryptDataWithRSA,
    decryptWithAES,
    encryptDataWithAES,
    encryptDataWithRSA,
    exportKeyToJWK,
    generateAESKey,
    importKey,
} from './cryptoUtils';

interface CryptoKeys {
    [key: string]: any;
}

const state = store.getState().serverDataReducer;
const privateKey = state.serverData[state.activeServer]?.privateKey;
const cryptoKeys: CryptoKeys = store.getState().cryptoKeyReducer;
const preferedHash = store.getState().userPreferencesReducer.msg_key_hash;

const { _persist, ...filteredObj } = cryptoKeys;

export const decryptNote = async (
    {
        message,
        token,
        key_hash,
    }: { message: string; token: string; key_hash: string },
    pvtKey?: any,
) => {
    const privateKeysObject = store.getState().cryptoKeyReducer;

    let decryptToken: { key: string; iv?: string | any } | null =
        await decryptDataWithRSA(token, pvtKey);

    if (!decryptToken && key_hash) {
        if (privateKeysObject?.[key_hash]?.privateKey) {
            decryptToken = await decryptDataWithRSA(
                token,
                privateKeysObject?.[key_hash]?.privateKey,
            );
        } else {
            return { is_encrypt: true };
        }
        if (!decryptToken) return { is_encrypt: true };
    }

    const algorithm = { name: 'AES-GCM', length: 256 };

    const AESKey = await importKey(
        JSON.stringify(decryptToken.key ?? decryptToken ?? {}),
        algorithm,
    );
    const encryptedData = base64ToArrayBuffer(message);

    const iv =
        decryptToken.iv && new Uint8Array(Object.values(decryptToken.iv));
    const decryptMessage = await decryptWithAES(encryptedData, AESKey, iv);

    return JSON.parse(decryptMessage);
};

export const onEncryptMessage = async (data: any) => {
    let publicKey =
        store.getState().serverDataReducer.serverData[
            store.getState().serverDataReducer.activeServer
        ]?.publicKey;

    if (data?.key_hash) {
        publicKey = cryptoKeys[data?.key_hash]?.publicKey;
    }

    let encryptedData1 = null;
    let encryptToken = data?.passedToken;

    if (!data?.passedToken || data?.passedToken === '') {
        const AESKey = await generateAESKey();

        const { encryptedData } = await encryptDataWithAES(
            JSON.stringify(data),
            AESKey,
        );
        encryptedData1 = encryptedData;
        const AESKeyJwk = await exportKeyToJWK(AESKey);
        const token = AESKeyJwk;
        encryptToken = await encryptDataWithRSA(token, publicKey);
    }

    return { encryptedData: encryptedData1, encryptToken };
};

const handleNoteEncryption = async ({
    message,
    key_hash,
    passedToken,
}: {
    message: string;
    key_hash: string;
    passedToken: string;
}) => {
    const { encryptedData, encryptToken } = await onEncryptMessage({
        message,
        key_hash,
        passedToken,
    });

    let defaultHashKey =
        store.getState().serverDataReducer.serverData[
            store.getState().serverDataReducer.activeServer
        ]?.defaultHashkey;

    if (key_hash) {
        defaultHashKey = key_hash;
    }
    return {
        message: encryptedData,
        token: encryptToken,
        key_hash: defaultHashKey,
    };
};

export const encryptNote = async ({ data, passedToken = null }) => {
    const keyIsThere = Object.keys(filteredObj).find(
        item => item === preferedHash,
    );

    let sentMessage = {
        data,
        key_hash: undefined,
    };

    if (preferedHash && preferedHash?.trim() !== '' && keyIsThere) {
        sentMessage = { data, key_hash: preferedHash };
    }

    const { message, token, key_hash } = await handleNoteEncryption({
        message: data,
        key_hash: preferedHash,
        passedToken: passedToken,
    });

    return { message, token, key_hash };
};

export const decryptNoteHandler = async ({ message, token, key_hash }) => {
    const decryptData = await decryptNote(
        { message, token, key_hash },
        privateKey,
    );
    return { decryptData: decryptData };
};
