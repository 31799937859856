import { Trans } from '@lingui/macro';

import { Switch } from '@/UI';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { userPreferencesActions } from '@/store/user-preferences-slice';

import classes from './ExperimentalMode.module.css';

const ExperimentalMode: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const isDevMode = useAppSelector(
        state => state.userPreferencesReducer.isDevMode,
    );

    const toggleMode = (value: boolean) => {
        dispatch(userPreferencesActions.toggleDevMode(value));
    };

    return (
        <div className={`${classes['wrap']}`}>
            <div className={classes['flex-container']}>
                <Trans>Activate / DeActivate Experimental Mode</Trans>
                <Switch
                    checked={isDevMode !== null ? isDevMode : false}
                    onChange={toggleMode}
                />
            </div>
        </div>
    );
};
export default ExperimentalMode;
