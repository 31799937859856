import { ChangeEvent, useContext, useState } from 'react';

import { AppButton, Modal } from '@/UI';
import { NotesContext } from '@/context/notes/NotesContext';

import classes from './ColorPickerPopup.module.css';

interface IProps {}

const ColorPickerPopup: React.FC<IProps> = () => {
    const notesContext = useContext(NotesContext);

    const { setIsColorPickModal } = notesContext;

    const [hexVal, setHexVal] = useState('#ffffff');
    const [isValid, setIsValid] = useState(true);

    const colorChangeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
        const inputValue = ev.target.value;
        const isValidHexColor = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(
            inputValue,
        );
        setIsValid(isValidHexColor);
        setHexVal(inputValue);
    };

    return (
        <Modal
            headerClassName={classes['header']}
            onClose={() => setIsColorPickModal(false)}
            header="Choose color">
            <div className={`${classes['container']}`}>
                <input
                    style={{ background: hexVal }}
                    value={hexVal}
                    className={classes['color']}
                    type="color"
                    name="color-pick"
                    onChange={colorChangeHandler}
                />
                <p>Or</p>
                <input
                    onChange={colorChangeHandler}
                    type="text"
                    value={hexVal}
                    className={`${!isValid ? classes['invalid'] : ''} ${
                        classes['hex-wrap']
                    }`}
                />
                <div className={classes['btn-wrap']}>
                    <AppButton
                        onClick={() => setIsColorPickModal(false)}
                        title="Cancel"
                        classStyles={classes['cancel']}
                    />
                    <AppButton
                        onClick={() => {}}
                        title="Save"
                        buttonType="primary"
                        classStyles={classes['save']}
                    />
                </div>
            </div>
        </Modal>
    );
};
export default ColorPickerPopup;
