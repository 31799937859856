export function truncateFileName(input: string, length?: number): string {
    let maxLetters = 15;
    if (length) {
        maxLetters = length;
    }
    const lastDotIndex = input.lastIndexOf('.');
    const fileName = input.substring(0, lastDotIndex);
    const extension = input.substring(lastDotIndex);

    if (fileName.length <= maxLetters) {
        return input;
    }

    return `${fileName.substring(0, maxLetters)} ..${extension}`;
}
