import React, { useEffect, useState } from 'react';

import { Icon, TextInput } from '@/UI';
import DropDown from '@/UI/drop-down/DropDown';
import { sortMemberRoleList } from '@/Utils/memberUtils';
import PermissionsMeControlBoolean from '@/Utils/permissionsMeControlBoolean';
import { getServer, uploadFiles } from '@/Utils/serverMediaHandler.js';
import { removeUserFromChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import { truncateText } from '@/Utils/textUtils';
import { SetRandomRoleNames } from '@/Utils/useRandomRoleNames';
import useStatusHelper from '@/Utils/useStatusHelper';
import add from '@/assets/icons/add-outline.svg';
import closeIcon from '@/assets/icons/close-header.svg';
import cross from '@/assets/icons/crossWhite.svg';
import editIcon from '@/assets/icons/edit-pencil.svg';
import SearchIcon from '@/assets/icons/search-light.svg';
import tickIcon from '@/assets/icons/singleTickWhite.svg';
import trash from '@/assets/icons/trash-red.svg';
import useOrganizationAddMember from '@/components/ManagementModal/organization_member/organization-add-members/useOrganizationAddMember';
import { DEFAULT_IMAGES } from '@/constants';
import { useCustomSweetAlert } from '@/hooks';
import { useAppSelector } from '@/hooks/useRedux';
import {
    activeChannelIdSelector,
    channelDataDetailsSelector,
    serverDetailSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import classes from './UserModal.module.css';
import useUserModal from './useUserModal';

interface Props {}

const UserModal = React.forwardRef<HTMLDivElement, any>((props, ref) => {
    const {
        modalRef,
        style,
        selectRole,
        deleteRole,
        showRoleModal,
        searchData,
        onSearch,
        user,
        startDMHandler,
        userRedux,
        setShowRoleModal,
    } = useUserModal(props);

    const sortedMemberList = sortMemberRoleList(userRedux?.roles);

    const currentUser = useAppSelector(state => state.userReducer.user);

    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );

    const memberUser = memberList[currentUser?.id];
        
    const isExternal =  memberUser.external === 1;
    const rolesList = isExternal
        ? SetRandomRoleNames(sortedMemberList)
        : sortedMemberList;

    const handleUserRemove = user_id => {
        removeUserFromChannel([user_id], activeChannelId);
    };

    // USER EDIT MODE
    const [isEditMode, setIsEditMode] = useState(false);
    const [userNameInputValue, setUserNameInputValue] = useState(
        userRedux?.name ?? '',
    );
    const [userUsernameInputValue, setUserUsernameInputValue] = useState(
        userRedux?.username ?? '',
    );
    const [userPhotoInputValue, setUserPhotoInputValue] = useState([]);

    const server = useAppSelector(state => serverDetailSelector(state));
    const organisationId = server.customer_id;
    let formData = new FormData();

    let memberUpdateProps = {
        userId: userRedux?.id,
        organizationId: organisationId,
        formData: formData,
    };

    const inputClassName = `${classes['user-modal-edit-input']} ${
        isEditMode
            ? classes['editInput-enabled-style']
            : classes['editInput-disabled-style']
    }`;

    const { handleMemberUpdate } = useOrganizationAddMember(memberUpdateProps);

    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );
    const serverLink = process.env.REACT_APP_GET_SERVER_LINK_URL;
    let channelDetails = useAppSelector(channelDataDetailsSelector);

    const isAllowedToAssign = PermissionsMeControlBoolean(['role.assign']);
    const isAllowedToEditUser = PermissionsMeControlBoolean(['user.edit']);
    const { areYouSureModal } = useCustomSweetAlert();
    const activeChannelId = useAppSelector(activeChannelIdSelector);
    let channelPermissionsUserDelete =
        channelDetails?.channelPermissions?.['user.delete'];

    const submitImage = async file => {
        const { responseLink } = await getServer(file, serverLink);

        const { uploadedFileLink } = await uploadFiles(
            file,
            activeServer,
            responseLink,
            user.token,
            '',
            false,
        );
        return { uploadedFileLink: uploadedFileLink.data };
    };

    const fetchUpdateUserInfo = async () => {
        let profilePhoto = userPhotoInputValue?.[0];
        let serverProfilePhoto = userPhotoInputValue?.[0];

        if (typeof serverProfilePhoto === 'object') {
            serverProfilePhoto = await submitImage(serverProfilePhoto.file);
            profilePhoto = `https://${serverProfilePhoto.uploadedFileLink.server}/api/download/${serverProfilePhoto.uploadedFileLink.fileKey}`;
        }

        userUsernameInputValue !== userRedux.username &&
            formData.append('username', userUsernameInputValue);
        userNameInputValue !== userRedux.name &&
            formData.append('name', userNameInputValue);
        await handleMemberUpdate();
    };

    const clearForm = () => {
        setIsEditMode(false);
        setUserNameInputValue(userRedux?.name);
        setUserUsernameInputValue(userRedux?.username);
    };

    const editCancelOnClick = () => {
        setIsEditMode(false);
        clearForm();
    };

    const editSubmitOnClick = () => {
        fetchUpdateUserInfo();
        clearForm();
    };

    useEffect(() => {
        if (userRedux.profile_picture) {
            setUserPhotoInputValue([userRedux.profile_picture]);
        }
        setUserNameInputValue(userRedux?.name);
        setUserUsernameInputValue(userRedux?.username);
    }, [userRedux]);

    // USER EDIT MODE

    const _renderRolesModal = () => {
        return (
            <DropDown
                className={classes['roles-modal-container']}
                onClose={() => setShowRoleModal(false)}>
                <div>
                    <TextInput
                        textStyle={{ padding: 0 }}
                        icon={SearchIcon}
                        placeHolder="Roles"
                        onChange={onSearch}
                    />
                    <div className={classes['roles-list-wrapper']}>
                        {searchData?.map((item: any, key: number) => (
                            <div
                                key={key}
                                onClick={() => selectRole(item._id)}
                                className={classes['roles-list-item']}>
                                <div
                                    className={classes['circle']}
                                    style={{
                                        backgroundColor: item.color,
                                    }}
                                />
                                <span style={{ color: 'white' }}>
                                    {item.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </DropDown>
        );
    };

    const _renderRoleList = () => (
        <>
            {rolesList?.map((item, key) => {
                return (
                    <div
                        key={key}
                        className={classes.badgeClass}
                        onClick={() => {
                            deleteRole(item);
                        }}>
                        <div
                            className={classes['badge']}
                            style={{
                                backgroundColor: item?.color,
                            }}
                        />

                        {item?.name}
                        <img src={cross} alt="" />
                    </div>
                );
            })}
        </>
    );

    const { getStatusById } = useStatusHelper();

    return (
        <div
            ref={ref}
            className={classes['container'] + ' ' + props.className}
            style={style}>
            <div
                id={`modal-${userRedux.id}`}
                ref={modalRef}
                className={classes.modal}>
                <img
                    className={classes['banner-img']}
                    src={userRedux?.banner_image ?? DEFAULT_IMAGES.COVER_IMAGE}
                    alt="banner"
                />
                <div className={classes['padding-container']}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        {true && (
                            <Icon
                                onClick={startDMHandler}
                                className={classes['profile-img']}
                                imgClass={classes['profile-img']}
                                imageURL={
                                    userRedux?.profile_picture ??
                                    DEFAULT_IMAGES.PROFILE_IMAGE
                                }
                                name={
                                    userRedux.name
                                        ? userRedux.name
                                        : 'user image'
                                }
                                hasBadge
                                badgeStyle={{
                                    bottom: 0,
                                    right: 0,
                                }}>
                                {getStatusById(userRedux.status)?.icon &&
                                    userRedux.state && (
                                        <span>
                                            <img
                                                alt=""
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                                src={
                                                    getStatusById(
                                                        userRedux.status,
                                                    )?.icon
                                                }
                                            />
                                        </span>
                                    )}
                                {!userRedux.state && (
                                    <span
                                        style={{
                                            display: 'flex',
                                        }}>
                                        <span
                                            style={{
                                                width: '14px',
                                                height: '14px',
                                                backgroundColor: 'var(--gray)',
                                                borderRadius: 999,
                                                aspectRatio: 1 / 1,
                                            }}
                                        />
                                    </span>
                                )}
                            </Icon>
                        )}

                        {isAllowedToEditUser && (
                            <>
                                {isEditMode && (
                                    <div
                                        className={
                                            classes['editIconContainer']
                                        }>
                                        <img
                                            onClick={editCancelOnClick}
                                            className={classes['closeIcon']}
                                            src={closeIcon}
                                            alt="cancel"
                                        />
                                        <img
                                            onClick={editSubmitOnClick}
                                            className={classes['tickIcon']}
                                            src={tickIcon}
                                            alt="save"
                                        />
                                    </div>
                                )}

                                {!isEditMode && (
                                    <div
                                        className={
                                            classes['editIconContainer']
                                        }>
                                        <div
                                            className={
                                                classes[
                                                    'editIconLabelContainer'
                                                ]
                                            }>
                                            <span
                                                className={
                                                    classes['editIconLabel']
                                                }>
                                                You can edit the user.
                                            </span>
                                            <span
                                                className={
                                                    classes[
                                                        'editIconLabelTriangle'
                                                    ]
                                                }
                                            />
                                        </div>
                                        <img
                                            onClick={() => setIsEditMode(true)}
                                            className={classes['editIcon']}
                                            src={editIcon}
                                            alt="edit"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <h2>
                        {isAllowedToEditUser && (
                            <input
                                id="user-modal-edit-input"
                                onChange={e =>
                                    setUserNameInputValue(e.target.value)
                                }
                                disabled={!isEditMode}
                                className={inputClassName}
                                placeholder="Name..."
                                value={truncateText(
                                    userRedux.name
                                        ? userNameInputValue
                                        : 'User',
                                    25,
                                )}
                            />
                        )}
                        {!isAllowedToEditUser &&
                            truncateText(
                                userRedux.name ? userNameInputValue : 'User',
                                25,
                            )}
                    </h2>
                    <p>
                        {isAllowedToEditUser && (
                            <input
                                id="user-modal-edit-input"
                                onChange={e =>
                                    setUserUsernameInputValue(e.target.value)
                                }
                                disabled={!isEditMode}
                                className={inputClassName}
                                placeholder="Username..."
                                value={
                                    userRedux.username
                                        ? userUsernameInputValue
                                        : '@'
                                }
                            />
                        )}
                        {!isAllowedToEditUser &&
                            (userRedux.username ? userUsernameInputValue : '@')}
                    </p>

                    <br />
                    <h2>Roles</h2>
                    <div className={classes['roles-container']}>
                        {userRedux?.roles?.length > 0 && _renderRoleList()}
                        {isAllowedToAssign && (
                            <div
                                onClick={() => setShowRoleModal(true)}
                                className={classes.addClass}>
                                <img
                                    alt=""
                                    src={add}
                                    className={classes['add-icon']}
                                />
                            </div>
                        )}
                    </div>
                    {isAllowedToAssign && channelPermissionsUserDelete && (
                        <div
                            onClick={() =>
                                areYouSureModal(null, '', true, () =>
                                    handleUserRemove(userRedux?.id),
                                )
                            }
                            style={{ marginTop: 16 }}
                            className={classes.addClass}>
                            <img src={trash} style={{ width: 14 }} alt="" />
                            <span style={{ marginLeft: 4 }}>
                                Remove From Channel
                            </span>
                        </div>
                    )}
                </div>
            </div>
            {showRoleModal && _renderRolesModal()}
        </div>
    );
});
export default UserModal;
