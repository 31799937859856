import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useRef, useState } from 'react';

import useClickOutside from '@/hooks/useClickOutside';

import classes from './Popover.module.css';

interface PopoverProps {
    trigger: React.ReactNode;
    content: React.ReactNode;
}

const Popover: FC<PopoverProps> = ({ trigger, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const popoverRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        if (!isAnimating) {
            setIsOpen(prev => !prev);
        }
    };

    const handleClose = () => {
        if (isOpen) {
            setIsOpen(false);
        }
    };

    useClickOutside([triggerRef, popoverRef], handleClose);

    const onAnimationComplete = () => {
        setIsAnimating(false);
    };

    return (
        <div ref={triggerRef} className={classes['popover-container']}>
            <div className={classes['popover-trigger']} onClick={handleToggle}>
                {trigger}
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.6 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.6 }}
                        transition={{ duration: 0.3 }}
                        ref={popoverRef}
                        className={classes['popover-content']}
                        onAnimationStart={() => setIsAnimating(true)}
                        onAnimationComplete={onAnimationComplete}>
                        {content}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Popover;
