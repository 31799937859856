const acceptedMediaTypes = `
image/jpeg, 
image/png, 
image/jpg, 
image/svg, 
video/mp4, 
video/mov,
application/pdf,
application/zip,
application/rar, 
font/ttf, 
font/otf
`;
export default acceptedMediaTypes;
