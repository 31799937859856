import { Trans, t } from '@lingui/macro';
import { useRef, useState } from 'react';
import Select from 'react-select';

import { TextInput } from '@/UI';
import MediaInput from '@/UI/media-input/MediaInput';
import { useAppSelector } from '@/hooks/useRedux';
import { sectionDataSelector } from '@/store/server-data-reducer/selectors-server-data';

import { addMemberSelectStyles } from '../ManagementModal/organization_member/organization-create-member/selectStyles';

import classes from './EditChannel.module.css';
import { useEditChannelContext } from './EditChannelContext';
import Title from './Title';

const EditChannelContent: React.FC = () => {
    const {
        defaultBg,
        mediaInputValue,
        setMediaInputValue,
        channelName,
        channelDescription,
        setChannelName,
        setChannelDescription,
        sectionId,
        setSectionId,
    } = useEditChannelContext();

    const bannerRef = useRef();

    const [defaultBanner, setDefaultBanner] = useState([defaultBg]);

    const section = useAppSelector(state => sectionDataSelector(state));

    const mediaChangeHandler = data => {
        setMediaInputValue(data[0]);
        setDefaultBanner(null);
    };

    const removeBannerHandler = () => {
        setMediaInputValue([]);
        setDefaultBanner(null);
    };

    let selectOptions = section.map(item => {
        return { value: item._id, label: item.name };
    });
    selectOptions.unshift({ value: '', label: '---' });

    return (
        <>
            <Title
                title={t`Details`}
                subtitle={t`You can make all the edits and changes about the channel here.`}
            />
            <div className={classes['channel-edit-form-container']}>
                <div style={{}}>
                    <p style={{ fontSize: '12px' }}>
                        <Trans>Channel Background Image</Trans>
                    </p>
                    <div className={classes['media-container']}>
                        <MediaInput
                            onChange={mediaChangeHandler}
                            onRemoveMedia={removeBannerHandler}
                            ref={bannerRef}
                            accept="image/jpg, image/png, image/jpeg"
                            passDefaultLink={
                                mediaInputValue ?? defaultBanner ?? null
                            }
                            wrapClass={classes['media']}
                        />
                    </div>
                </div>
                <div style={{ minWidth: '400px' }}>
                    <TextInput
                        value={channelName}
                        onChange={newValue =>
                            setChannelName(newValue.target.value)
                        }
                        containerStyle={{ margin: '16px 0' }}
                        title={t`Channel Name`}
                        placeHolder={t`Channel Name`}
                        wrapClassName={classes['input-wrap']}
                    />
                    <TextInput
                        value={channelDescription}
                        onChange={newValue =>
                            setChannelDescription(newValue.target.value)
                        }
                        containerStyle={{ margin: '16px 0' }}
                        title={t`Channel Description`}
                        placeHolder={t`Channel Description`}
                        multiple
                        wrapClassName={classes['input-wrap']}
                        maxLength={1000}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label
                            className={classes['section-label']}
                            htmlFor="channel-section">
                            <Trans>Channel Section</Trans>
                        </label>
                        <Select
                            name="channel-section"
                            isSearchable
                            styles={addMemberSelectStyles}
                            placeholder={t`Select Section`}
                            defaultValue={selectOptions.find(
                                el => el.value === sectionId,
                            )}
                            className={`${classes['select']}`}
                            onChange={ev => setSectionId(ev.value)}
                            options={selectOptions}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default EditChannelContent;
