import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import notificationsSlice from './Notifications-count';
import BackendSearchResultsSlice from './backend-search-results';
import cryptoSlice from './crypto-keys-reducer';
import messagesSlice from './messages-reducer/messages-reducer';
import mobileViewSlice from './mobile-view-slice';
import notesSlice from './notes-reducer';
import organizationSlice from './organization-reducer';
import p2pSlice from './p2p-slice';
import indexedDBSearchSlice from './search-reducer/indexed-db-search-reducer.js';
import serverDataSlice from './server-data-reducer/server-data-reducer';
import socketConnectionSlice from './socket-connection-reducer';
import statisticsSlice from './statistics-reducer';
import themeSlice from './theme-color-reducer';
import threadsSlice from './thread-reducer/threadsSlice';
import userPreferencesSlice from './user-preferences-slice';
import userSlice from './user-reducer';
import utilSlice from './util-reducer';
import WSSlice from './ws-reducer';

const serverPersistConfig = {
    key: 'server',
    storage: storage,
    whitelist: ['activeServer', 'serverData'],
};
const userPersistConfig = {
    key: 'user',
    storage: storage,
};

const cryptoKeysPersistConfig = {
    key: 'crypto_keys',
    storage: storage,
};
const preferencesPersistConfig = {
    key: 'preferences',
    storage: storage,
};

const rootReducer = combineReducers({
    socketConnectionReducer: socketConnectionSlice,
    themeColorReducer: themeSlice,
    notificationsReducer: notificationsSlice,
    serverDataReducer: persistReducer(serverPersistConfig, serverDataSlice),
    cryptoKeyReducer: persistReducer(cryptoKeysPersistConfig, cryptoSlice),
    utilReducer: utilSlice,
    userReducer: persistReducer(userPersistConfig, userSlice),
    indexedDBReducer: indexedDBSearchSlice,
    messageReducer: messagesSlice,
    WSReducer: WSSlice,
    organizationReducer: organizationSlice,
    BackendSearchResultsReducer: BackendSearchResultsSlice,
    statisticsReducer: statisticsSlice,
    threadsReducer: threadsSlice,
    notesReducer: notesSlice,
    p2pReducer: p2pSlice,
    mobileViewReducer: mobileViewSlice,

    userPreferencesReducer: persistReducer(
        preferencesPersistConfig,
        userPreferencesSlice,
    ),
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            immutableCheck: { warnAfter: 64 },
            serializableCheck: {
                warnAfter: 64,
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
    devTools: process.env.NODE_ENV !== 'production' && {
        actionSanitizer: action => action,
        stateSanitizer: state => state,
    },
});
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
