import {
    ButtonHTMLAttributes,
    MouseEvent,
    ReactNode,
    Ref,
    forwardRef,
} from 'react';

import classes from './ImgButton.module.css';

interface ImgButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    icon?: string;
    children?: ReactNode;
    className?: string;
}

const ImgButton = forwardRef<HTMLButtonElement, ImgButtonProps>(
    (props, ref: Ref<HTMLButtonElement>) => {
        const {
            children,
            icon,
            className = '',
            onClick,
            type,
            disabled,
            style,
        } = props;

        const clickButtonHandler = (event: MouseEvent<HTMLButtonElement>) => {
            onClick && onClick(event);
        };

        return (
            <button
                ref={ref}
                type={type}
                onClick={clickButtonHandler}
                className={`${classes.ImgButton} ${className}`}
                disabled={disabled}
                style={style}>
                {!icon ? (
                    children
                ) : (
                    <img src={icon} alt="send" className={classes.sendIcon} />
                )}
            </button>
        );
    },
);

export default ImgButton;
