import { memo } from 'react';

import formatFileSize from '@/Utils/fileSzeFormatter';
import { truncateFileName } from '@/Utils/truncateFileName';
import DownloadedTypeIcon from '@/components/download-btn/DownloadedTypeIcon';
import SaveBtn from '@/components/save-btn/SaveBtn';

import AudioPlayer from './AudioPlayer';
import classes from './SelectedMediaDisplay.module.css';
import { UnknownMediaProps } from './SelectedMediaTypes';

const AudioMedia: React.FC<UnknownMediaProps> = props => {
    const { file } = props;
    const outputFileName = truncateFileName(file?.name ?? '');

    return (
        <div
            className={`${classes['audio-wrap']} ${classes['download-file-container']}`}
            onClick={e => e.stopPropagation()}>
            <div className={classes['audio-header']}>
                <DownloadedTypeIcon type={'audio'} />
                <div className={classes['file-info-wrap']}>
                    <p className={classes['label-name']}>{outputFileName}</p>
                    <p className={classes['label-size']}>
                        {file.data?.size
                            ? `${formatFileSize(+file.data.size)}`
                            : 'Unknown size'}
                    </p>
                </div>
                <SaveBtn
                    className={classes['save-btn']}
                    src={file?.src}
                    name={file?.name ?? 'Unknown'}
                />
            </div>
            <AudioPlayer file={file} />
        </div>
    );
};
export default memo(AudioMedia);
