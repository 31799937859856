import React, { ReactNode, createContext, useEffect, useState } from 'react';

import { handleGetStatisticsRequest } from '@/Utils/socket-actions/StatisticsActions/statistics';
import { useAppSelector } from '@/hooks/useRedux';

import { IMetaData } from './types';
import { ContextProps } from './types';

interface IProps {
    children: ReactNode;
}
const todayDate = new Date();
const today = todayDate.toISOString().split('T')[0];
const sevenDaysAgo = new Date(todayDate);
sevenDaysAgo.setDate(todayDate.getDate() - 7);
const sevenDaysAgoISOString = sevenDaysAgo.toISOString().split('T')[0];

const initialValues = {
    startDate: sevenDaysAgoISOString,
    endDate: today,
    memberData: [],
    searchText: '',
    sortToggle: true,
    status: false,
    pageIndex: 1,
    metaData: null,
    statisticsData: [],
    selectedCheckboxUsersData: [],
    onChangeEndDate: () => {},
    onChangeStartDate: () => {},
    setSearchText: () => {},
    handleSortData: () => {},
    setStatus: () => {},
    handleOnline: () => {},
    setPageIndex: () => {},
    increasePageIndex: () => {},
    handleChangeData: () => {},
};

export const StatisticsContext = createContext<ContextProps>(initialValues);

export const StatisticsProvider: React.FC<IProps> = ({ children }) => {
    const visibleItems = 20;

    const [status, setStatus] = useState(initialValues.status);
    const [startDate, setStartDate] = useState(initialValues.startDate);
    const [endDate, setEndDate] = useState(initialValues.endDate);
    const [memberData, setmemberData] = useState(initialValues.memberData);
    const [searchText, setSearchText] = useState(initialValues.searchText);
    const [sortToggle, setSortToggle] = useState(initialValues.sortToggle);
    const [pageIndex, setPageIndex] = useState(initialValues.pageIndex);
    const [metaData, setMetaData] = useState<IMetaData>(initialValues.metaData);
    const [statisticsData, setStatisticsData] = useState(
        initialValues.statisticsData,
    );
    const [selectedCheckboxUsersData, setSelectedCheckboxUsersData] = useState(
        initialValues.selectedCheckboxUsersData,
    );

    const ReduxData = useAppSelector(state => state?.statisticsReducer);
    const channelList = useAppSelector(
        state =>
            state.serverDataReducer.channelList[
                state.serverDataReducer.activeServer
            ],
    );

    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );
    const statisticsResult = ReduxData?.data;

    const handleChannelData = () => {
        const updatedMemberData = statisticsData?.map(member => {
            const updatedChannels = member?.channels?.map(channel => {
                return {
                    ...channel,
                    channel_name:
                        channelList[channel?.channel_id]?.name || 'UNKNOWN',
                };
            });
            return {
                ...member,
                channels: updatedChannels,
            };
        });
        // @ts-ignore
        setMetaData(ReduxData?.metaData);

        setmemberData(updatedMemberData);
    };

    const onChangeStartDate = e => {
        setStartDate(e.target.value);
    };
    const onChangeEndDate = e => {
        setEndDate(e.target.value);
    };

    const increasePageIndex = () => {
        setPageIndex(prev => prev + 1);
    };

    const handleOnline = () => {
        setStatus(!status);
    };

    const handleChangeData = data => {
        setSelectedCheckboxUsersData(data);
    };

    const handleSortData = key => {
        setSortToggle(prev => !prev);
        let sortData = [...memberData];
        let keyType = typeof sortData[0][key];
        if (key === 'conversations') {
            keyType = typeof sortData[0]?.channels[0]?.conversations;
        }

        if (key === 'state') {
            if (sortToggle) {
                sortData = sortData.sort((a, b) => {
                    if ('state' in a === 'state' in b) {
                        return 0;
                    }
                    if ('state' in a) {
                        return -1;
                    }
                    if ('state' in b) {
                        return 1;
                    }

                    return 0;
                });
            } else {
                sortData.reverse();
            }
        }
        if (sortToggle) {
            if (keyType === 'string')
                sortData = sortData?.sort((a, b) =>
                    a[key]?.localeCompare(b[key]),
                );

            if (keyType === 'object')
                sortData = sortData?.sort(
                    (a, b) => b[key]?.length - a[key]?.length,
                );
            if (keyType === 'number') {
                sortData = sortData.sort((a, b) => a.status - b.status);
            }
            if (keyType === 'number' && key === 'conversations') {
                sortData = sortData?.sort(
                    (a, b) => a.totalConversations - b.totalConversations,
                );
            }
        }
        // reverse
        else {
            if (keyType === 'string') {
                sortData = sortData?.sort((a, b) =>
                    b[key]?.localeCompare(a[key]),
                );
            }

            if (keyType === 'object') {
                sortData = sortData?.sort(
                    (a, b) => a[key]?.length - b[key]?.length,
                );
            }
            if (keyType === 'number') {
                sortData = sortData.sort((a, b) => b.status - a.status);
            }
            if (keyType === 'number' && key === 'conversations') {
                sortData = sortData?.sort(
                    (a, b) => b.totalConversations - a.totalConversations,
                );
            }
        }

        setmemberData(sortData);
    };

    const handleFilterMembers = () => {
        const filteredMembers = searchText
            ? memberData?.filter(member => {
                  return (
                      member?.username
                          ?.toLowerCase()
                          ?.includes(searchText?.toLowerCase()) ||
                      member?.name
                          ?.toLowerCase()
                          ?.includes(searchText?.toLowerCase())
                  );
              })
            : memberData;
        setmemberData(filteredMembers);
    };

    useEffect(() => {
        if (endDate > today) {
            setEndDate(today);
        } else if (startDate > today) {
            setStartDate(today);
        }
    }, [endDate, startDate]);

    useEffect(() => {
        handleGetStatisticsRequest(
            startDate,
            endDate,
            pageIndex,
            visibleItems,
            selectedCheckboxUsersData,
        );
    }, [
        startDate,
        endDate,
        pageIndex,
        visibleItems,
        selectedCheckboxUsersData,
    ]);

    useEffect(() => {
        const users = (statisticsResult?.message_statistic_data || [])
            .map(item => {
                const user = memberList[item?.user_id];
                return user
                    ? {
                          ...user,
                          channels: item.channels,
                          totalConversations: item?.total_conversations,
                      }
                    : null;
            })
            .filter(Boolean);
        setStatisticsData(users);
    }, [statisticsResult, memberList]);

    useEffect(() => {
        handleChannelData();
    }, [statisticsData]);

    useEffect(() => {
        if (searchText.length > 0) {
            handleFilterMembers();
        } else {
            setmemberData(memberData);
            handleChannelData();
        }
    }, [searchText]);
    useEffect(() => {
        setPageIndex(1);
    }, [selectedCheckboxUsersData, startDate, endDate]);

    useEffect(() => {
        if (status) {
            const onlineFilter = memberData?.filter(val => val.state);
            setmemberData(onlineFilter);
        } else {
            setmemberData(statisticsData);
        }
    }, [status]);

    const contextValues: ContextProps = {
        startDate,
        endDate,
        onChangeStartDate,
        onChangeEndDate,
        memberData,
        searchText,
        setSearchText,
        handleSortData,
        setStatus,
        status,
        handleOnline,
        sortToggle,
        setPageIndex,
        increasePageIndex,
        metaData,
        handleChangeData,
        selectedCheckboxUsersData,
    };

    return (
        <StatisticsContext.Provider value={contextValues}>
            {children}
        </StatisticsContext.Provider>
    );
};
