import fetchData from '@/Utils/fetchData';
import { REQUEST_TYPE } from '@/constants/requstTypes';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

interface IEditNote {
    subserverId: string;
    noteId: string;
    params: { title: string; body: string };
}

export const getNotesList = async ({ subserverId }) => {
    const url = `${baseUrl}/api/v1/user/notes/${subserverId}`;
    const { data, error } = await fetchData(REQUEST_TYPE.GET, url);

    return { data, error };
};

export const createNoteApi = async ({ subserverId, params }) => {
    const url = `${baseUrl}/api/v1/user/notes/${subserverId}`;

    const { data } = await fetchData(REQUEST_TYPE.POST, url, params);
    return { data };
};

export const deleteNoteApi = async ({ subserverId, noteId }) => {
    const url = `${baseUrl}/api/v1/user/notes/${subserverId}/${noteId}`;

    const { data } = await fetchData(REQUEST_TYPE.DELETE, url);
    return { data };
};

export const editNoteApi = async ({
    subserverId,
    noteId,
    params,
}: IEditNote) => {
    const url = `${baseUrl}/api/v1/user/notes/${subserverId}/${noteId}`;

    const { data } = await fetchData(REQUEST_TYPE.PATCH, url, params);
    return { data };
};
