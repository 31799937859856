import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/library.css';

import { BUTTON_SIZE, BUTTON_TYPE } from '@/constants/enum';
import crossWhite from '@/assets/icons/crossWhite.svg';

import AppButton from '../AppButton/AppButton';

import classes from './ConfirmationModal.module.css';

interface IConfirmationModal {
    visible: boolean;
    icon?: string;
    title?: string;
    description?: string;
    hideButton?: boolean;
    showLoader?: boolean;
    leftButtonTitle?: string;
    rightButtonTitle?: string;
    leftOnClick?: () => void;
    rightOnClick?: () => void;
    onCancel: () => void;
}

const ConfirmationModal = (props: IConfirmationModal) => {
    const {
        visible,
        icon,
        title,
        description,
        hideButton,
        showLoader,
        leftButtonTitle,
        leftOnClick,
        rightButtonTitle,
        rightOnClick,
        onCancel,
    } = props;

    const handleLeftIcon = () => {
        leftOnClick && leftOnClick();
    };

    const handleRightIcon = () => {
        rightOnClick && rightOnClick();
    };

    if (!visible) return null;

    return (
        <div className={classes['backdrop']} onClick={onCancel}>
            <div
                className={classes['modalContainer']}
                onClick={e => e.stopPropagation()}>
                <img
                    alt=""
                    src={crossWhite}
                    className={classes['cancel']}
                    onClick={onCancel}
                />
                <img alt="" src={icon} className={classes['icon']} />
                <div
                    className={`${classes['textWrapper']} ${
                        hideButton && !showLoader && classes['onlyText']
                    }`}>
                    <span className={classes['title']}>{title}</span>
                    {description && (
                        <span className={classes['description']}>
                            {description}
                        </span>
                    )}
                </div>
                {!hideButton && (
                    <div className={classes['buttonWrapper']}>
                        <AppButton
                            buttonType={BUTTON_TYPE.transparent}
                            size={BUTTON_SIZE.medium}
                            title={leftButtonTitle}
                            onClick={handleLeftIcon}
                        />
                        <AppButton
                            size={BUTTON_SIZE.medium}
                            title={rightButtonTitle}
                            onClick={handleRightIcon}
                        />
                    </div>
                )}
                {showLoader && <Spinner className={classes['spinner']} />}
            </div>
        </div>
    );
};

export default ConfirmationModal;
