import { Trans, t } from '@lingui/macro';
import { useContext } from 'react';

import { TextInput } from '@/UI';
import UserFilter from '@/UI/userFilter/UserFilter';
import SearchIcon from '@/assets/icons/search-icon.svg';

import SearchRow from './SearchRow';
import classes from './Statistics.module.css';
import { StatisticsContext } from './StatisticsContext';

const StatisticsHeader: React.FC<{}> = () => {
    const {
        searchText,
        setSearchText,
        handleChangeData,
        selectedCheckboxUsersData,
    } = useContext(StatisticsContext);

    return (
        <div className={classes['header']}>
            <h2 style={{ margin: 0 }}>
                <Trans>Statistics</Trans>
            </h2>
            <div className={classes['filters-wrap']}>
                <TextInput
                    wrapClassName={classes['search-input']}
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    icon={SearchIcon}
                    placeHolder={t`Search`}
                />
                <SearchRow />
                <div className={classes['user-filter']}>
                    <UserFilter
                        selectedCheckboxUsersData={selectedCheckboxUsersData}
                        onChangeData={handleChangeData}
                    />
                </div>
            </div>
        </div>
    );
};
export default StatisticsHeader;
