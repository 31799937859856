
import NoteDetails from './note-details/NoteDetails';
import NotesList from './notes-list/NotesList';
import NotesSidebar from './notes-sidebar/NotesSidebar';

const DesktopNotesLayout: React.FC<{}> = () => {
    return (
        <>
            <NotesSidebar />
            <NotesList />
            <NoteDetails />
        </>
    );
};
export default DesktopNotesLayout;
