import { FC } from 'react';

import AttachementMenuStore from './AttachementMenuStore';
import { AttachementMenuProvider } from './AttachmentMenuContext';
import { AttachMenuProps } from './AttachmentTypes';

const AttachmentMenu: FC<AttachMenuProps> = props => {
    return (
        <AttachementMenuProvider>
            <AttachementMenuStore {...props} />
        </AttachementMenuProvider>
    );
};
export default AttachmentMenu;
