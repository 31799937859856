import React, { InputHTMLAttributes } from 'react';

import CrossIcon from '@/assets/icons/close-header.svg';
import SearchIcon from '@/assets/icons/search-light.svg';

import classes from './SearchInput.module.css';

interface ISearchInput
    extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    onChange?: (e: any) => void;
    [rest: string]: any;
}

const SearchInput = React.forwardRef((props: ISearchInput, ref) => {
    return (
        <div
            className={classes.relative + ' ' + props.className}
            style={props.containerStyle}>
            <input
                ref={ref}
                className={classes['searchInput']}
                type="text"
                autoFocus={props.autoFocus}
                onChange={e => {
                    props.onChange(e.target.value);
                }}
                style={{
                    ...props.style,
                    paddingInlineEnd: props.onClose ? 32 : undefined,
                }}
                value={props.value}
                placeholder={props?.placeholder ? props.placeholder : ''}
                {...props.rest}
            />
            {!props.RemoveIcon && (
                <img
                    src={SearchIcon}
                    alt="search"
                    className={
                        props?.from === 'channel'
                            ? classes['filter-icon']
                            : classes['search-icon']
                    }
                />
            )}

            {props.onClose && (
                <img
                    src={CrossIcon}
                    alt="close"
                    className={classes['close-icon']}
                    onClick={props.onClose}
                />
            )}
        </div>
    );
});
export default SearchInput;
