import React from 'react';
import ContentLoader from 'react-content-loader';

interface ImageLoaderProps {
    className?: string;
    width?: number;
    height?: number;
    bgc?: string;
    fgc?: string;
    speed?: number;
    extra?: Record<string, any>;
}

const ImageLoader: React.FC<ImageLoaderProps> = ({
    className,
    width,
    height,
    bgc,
    fgc,
    speed,
    extra,
}) => (
    <ContentLoader
        className={className || ''}
        speed={speed || 2.5}
        width={width || 64}
        height={height || 64}
        viewBox={`0 0 ${width || 64} ${height || 64}`}
        backgroundColor={bgc || 'var(--main-bg-blue)'}
        foregroundColor={fgc || 'var(--text-secondary)'}
        {...extra}>
        <rect
            x="0"
            y="0"
            rx="3"
            ry="3"
            width={width || 64}
            height={height || 64}
        />
    </ContentLoader>
);

export default ImageLoader;
