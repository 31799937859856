import React from "react";
import DateIndicator from "../date-indicator/DateIndicator";
import Line from "../hr/Line";
import classes from "./DateLineBreak.module.css";

const DateLineBreak: React.FC<{ date: Date }> = (props) => {
  return (
    <div className={classes["date-break"]}>
      <Line className={classes.hr}/>
      <DateIndicator date={props.date} className={classes.date} />
    </div>
  );
};

export default DateLineBreak;
