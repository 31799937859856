import { Trans, t } from '@lingui/macro';
import { FC } from 'react';
import { useRouteError } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useRedux';
import { activeChannelIdSelector } from '@/store/server-data-reducer/selectors-server-data';

import packageJson from '../../package.json';

const ErrorPage: FC = () => {
    const error: any = useRouteError();
    const currentDate = new Date().toISOString();
    const user = useAppSelector(state => state.userReducer.user);
    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const activeServerId = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const mailBody = `
    -----------------------------------------------------------------------------------------------
    %0D
    ${t`Error`}: ${error?.message}
    %0D
    -----------------------------------------------------------------------------------------------
    %0D
    ${t`Date`}: ${currentDate}
    %0D
    -----------------------------------------------------------------------------------------------
    %0D
    Auth User ID: ${user ? user?.id : t`Not Found` + '!'}
    %0D
    -----------------------------------------------------------------------------------------------
    %0D
    Active Server ID: ${activeServerId ? activeServerId : t`Not Found` + '!'}
    %0D
    -----------------------------------------------------------------------------------------------
    %0D
    Active Channel ID: ${activeChannelId ? activeChannelId : t`Not Found` + '!'}
    %0D
    -----------------------------------------------------------------------------------------------
    %0D
    %0D
    -----------------------------------------------------------------------------------------------
    %0D
    ⓘ If you have a description or file describing the error, you can add it!
    %0D
    -----------------------------------------------------------------------------------------------
    `;

    return (
        <div
            style={{
                color: 'white',
                textAlign: 'start',
                backgroundColor: 'black',
                padding: 36,
                borderRadius: 16,
                border: '2px solid orange',
                width: '70%',
                wordBreak: 'break-all',
                margin: '24px auto',
            }}>
            <h2>Covhat Version: {packageJson.version}</h2>
            <div>
                <Trans>Oops, something went wrong</Trans>!
            </div>
            <p>
                <Trans>We apologize for the inconvenience</Trans>.
            </p>
            <p>
                <Trans>Please try again later or contact support</Trans>.
            </p>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 12,
                    paddingTop: 12,
                }}>
                <a
                    href={`mailto:yhwachsternritter@hotmail.com?subject=Covchat ${packageJson?.version} - Bug Report&body=${mailBody}`}>
                    <button
                        style={{
                            border: '2px solid red',
                            color: 'red',
                            padding: '12px 20px',
                            borderRadius: 8,
                            background: 'black',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                        }}>
                        <Trans>Report the bug</Trans>
                    </button>
                </a>

                <a href="/">
                    <button
                        style={{
                            border: '2px solid white',
                            color: 'white',
                            padding: '12px 20px',
                            borderRadius: 8,
                            background: 'black',
                            cursor: 'pointer',
                        }}>
                        <Trans>Go to home</Trans>
                    </button>
                </a>
            </div>
            <hr
                style={{
                    border: '1px solid orange',
                    margin: '24px 0px',
                    borderRadius: 50,
                    background: 'orange',
                    opacity: 0.5,
                }}
            />
            <div>
                <p>
                    <Trans>Error Details</Trans>:
                </p>
                <code style={{ color: 'orange' }}>{error?.message}</code>
            </div>
        </div>
    );
};
export default ErrorPage;
