import axios, { AxiosResponse } from 'axios';

import { RootState } from '@/store';

import { getStoreState } from './dataHandler';

const fetchData = async (
    requestType: string,
    endPoint: string,
    body?: any,
    params?: any,
    headers?: any,
    responseType?: any,
    onDownloadProgressCallback?: (progress: number) => void,
    onUploadProgressCallback?: (progress: number) => void,
) => {
    let url = '';
    let error;
    let data;
    let header;
    const store: RootState = getStoreState();
    const userToken = 'Bearer ' + store?.userReducer?.user?.token;

    let allHeaders = {
        Authorization: userToken,
        Accept: 'text/html, application/json, image/*, audio/*, video/*',
        contentType: 'Application/json',
        ...headers,
    };

    if (endPoint) {
        url = endPoint;
    }
    let axiosConfig: any = {
        method: requestType,
        url,
        headers: allHeaders,
        onDownloadProgress: progressEvent => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            if (onDownloadProgressCallback) {
                onDownloadProgressCallback(progress);
            }
        },
        onUploadProgress: progressEvent => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            if (onUploadProgressCallback) {
                onUploadProgressCallback(progress);
            }
        },
    };
    if (body) {
        axiosConfig = {
            ...axiosConfig,
            data: body,
        };
    }
    if (params) {
        axiosConfig = {
            ...axiosConfig,
            params: params,
        };
    }
    if (responseType) {
        axiosConfig = {
            ...axiosConfig,
            ...responseType,
        };
    }

    try {
        const response: AxiosResponse = await axios(axiosConfig);
        data = response.data;
        error = null;
        header = response.headers;

        return {
            data,
            error,
            header,
        };
    } catch (error) {
        error = error?.response?.data?.error
            ? error.response.data.error
            : error;
        data = null;
        header = null;
        return {
            data,
            error,
            header,
        };
    }
};

export default fetchData;
