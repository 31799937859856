import { t, Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';

import { AppButton, Line, TextInput } from '@/UI';
import { exportKeys } from '@/Utils/exportFullKey';
import { exportKeysForNode } from '@/Utils/exportNodeFullKey';
import PermissionsMeControlBoolean from '@/Utils/permissionsMeControlBoolean';
import upload from '@/assets/icons/document-upload.svg';
import CustomErrorBoundary from '@/components/CustomErrorBoundary/CustomErrorBoundary';
import { BUTTON_SIZE, SESSION_STORAGE } from '@/constants/enum';
import { useAppSelector } from '@/hooks/useRedux';
import { serverDetailSelector } from '@/store/server-data-reducer/selectors-server-data';

import EncryptionManageLoader from './EncryptionManageLoader';
import classes from './EncryptionManagement.module.css';
import RowContainer from './RowContainer';
import useEncryptionManagement from './useEncryptionManagement';

const EncryptionManagement = () => {
    const { handleChange, files, handleAddPrivateKeys, handleUpdateMessages } =
        useEncryptionManagement();

    const decryptLoader = useAppSelector(
        state => state.utilReducer.decryptionLoader,
    );

    const serverData = useAppSelector(state => serverDetailSelector(state));
    const { name, publicKey, privateKey } = serverData;

    const permission = ['owner', 'administrator'];

    const [showKeys, setShowKeys] = useState(false);
    const isOwner = PermissionsMeControlBoolean(permission);

    useEffect(() => {
        setShowKeys(isOwner);
    }, [isOwner]);

    return (
        <CustomErrorBoundary>
            <div className={classes['container']}>
                <div className={classes['header']}>
                    <h2 style={{ margin: 0 }}>
                        <Trans>Encryption Management</Trans>
                    </h2>
                    <span className={classes['desc']}>
                        <Trans>Current Encryption Version 2.0</Trans>
                    </span>
                </div>

                {decryptLoader ? (
                    <EncryptionManageLoader />
                ) : (
                    <div className={classes['content']}>
                        <RowContainer
                            title={t`Migrate Message Encryption`}
                            desc={t`Update messages encryption to version 2`}>
                            <AppButton
                                title={t`Update Messages`}
                                onClick={handleUpdateMessages}
                                classStyles={classes['appButton']}
                                disable={decryptLoader}
                            />
                        </RowContainer>
                        <Line className={classes['hr']} />
                        <RowContainer
                            title={t`Update Private Encryption`}
                            desc={t`Update private encryption to version 2`}>
                            <div className={classes['inputWrapper']}>
                                <TextInput
                                    isDisabled
                                    icon={upload}
                                    value={files?.fileName ?? ''}
                                    placeHolder={t`Upload private keys`}
                                    onClick={handleChange}
                                    error={files?.error}
                                    type="input"
                                    success={files?.success}
                                />
                            </div>
                            <AppButton
                                title={t`Upload Keys to update`}
                                onClick={handleAddPrivateKeys}
                                classStyles={classes['appButton']}
                                size={BUTTON_SIZE.medium}
                                disable={decryptLoader}
                            />
                        </RowContainer>
                        <Line className={classes['hr']} />

                        <div className={classes['new-key']}>
                            <img
                                alt=""
                                className={classes['new-keys-img']}
                                src={upload}
                            />

                            {showKeys && (
                                <RowContainer
                                    title={t`Export Server Keys`}
                                    desc={`--server:${
                                        serverData.id
                                    }:${serverData.name.replace(
                                        /\s/g,
                                        '_',
                                    )}_node.json`}
                                    className={classes['flex-row']}>
                                    <AppButton
                                        title={t`Export Server Keys`}
                                        onClick={() =>
                                            exportKeys(
                                                name,
                                                privateKey,
                                                publicKey,
                                            )
                                        }
                                        classStyles={classes['appButton']}
                                        disable={decryptLoader}
                                    />
                                    <AppButton
                                        title={t`Export Server Keys for node`}
                                        onClick={() =>
                                            exportKeysForNode(
                                                name,
                                                privateKey,
                                                publicKey,
                                            )
                                        }
                                        classStyles={classes['appButton']}
                                        disable={decryptLoader}
                                    />
                                </RowContainer>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </CustomErrorBoundary>
    );
};

export default EncryptionManagement;
