import { RootState } from '..';
import { createSelector } from '@reduxjs/toolkit';

import { IMessageData } from '@/types';

export const messageDataSelector = createSelector(
    (state: RootState) =>
        state.serverDataReducer.activeChannelId[
            state.serverDataReducer.activeServer
        ],
    (state: RootState) => state.messageReducer.messagesList,
    (activeChannelId, messagesList) => {
        if (activeChannelId) {
            const current = messagesList[activeChannelId] || {};
            return Object.values(current).reverse();
        }
        return [];
    },
);

export const cacheMessageDataSelector: (
    state: RootState,
) => IMessageData[] | any = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer, messageReducer }) => {
        if (serverDataReducer?.activeChannelId) {
            const current =
                messageReducer.cacheMessages[
                    serverDataReducer?.activeChannelId[
                        serverDataReducer.activeServer
                    ]
                ] ?? {};
            // const currentSort = Object.values(current)?.sort(
            //     (a: any, b: any) => b.created_at - a.created_at,
            // );
            return Object.values(current).reverse();
        }
        return [];
    },
);
