let scrollCallback: ((props: any) => void) | null = null;

export const setScrollCallback = (callback: (props: any) => void) => {
    setTimeout(() => {
        scrollCallback = callback;
    }, 300);
};

export const triggerScroll = (props: any) => {
    if (scrollCallback) {
        scrollCallback(props);
    }
};
