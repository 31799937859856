import { Trans } from '@lingui/macro';

import classes from './MentionsInput.module.css';
import MentionsListItem from './MentionsListItem';
import useMentionsInput from './useMentionsInput';

const MentionsInput = props => {
    const { classname } = props;
    const {
        ulRef,
        selectedItemIndex,
        handleMouseEnter,
        handleSubmit,
        filteredData,
    } = useMentionsInput(props);

    return (
        <div className={classes['container']}>
            <ul className={classname} ref={ulRef}>
                {filteredData?.length > 0 ? (
                    filteredData?.map((res, key) => {
                        return (
                            <MentionsListItem
                                key={`${res?.id}-${key}`}
                                item={res}
                                isSelected={selectedItemIndex === key}
                                onMouseEnter={() => handleMouseEnter(key)}
                                onClick={handleSubmit}
                            />
                        );
                    })
                ) : (
                    <p className={classes['default']}>
                        <Trans>No Result Found</Trans>
                    </p>
                )}
            </ul>
        </div>
    );
};

export default MentionsInput;
