import MemberManagementContent from './MemberManagementContent';
import { MemberManagementProvider } from './context/MemberManagementContext';

const MemberManagement = () => {
    return (
        <MemberManagementProvider>
            <MemberManagementContent />
        </MemberManagementProvider>
    );
};

export default MemberManagement;
