import { createSlice } from '@reduxjs/toolkit';

const initialState: {
    mainWS: boolean;
    WSElectron: boolean;
    WSNotification: boolean;
    WSSearch: boolean;
    WSFindSearch: boolean;
} = {
    mainWS: false,
    WSElectron: false,
    WSNotification: false,
    WSSearch: false,
    WSFindSearch: false,
};
const socketConnectionSlice = createSlice({
    initialState,
    name: 'socket_connection_slice',
    reducers: {
        updateConnectionState(state, action) {
            state.mainWS = action.payload;
        },
        updateElectronConnectionState(state, action) {
            state.WSElectron = action.payload;
        },
        updateNotificationConnectionState(state, action) {
            state.WSNotification = action.payload;
        },
        updateSearchSocket(state, action) {
            state.WSSearch = action.payload;
        },
        updateFindSearchSocket(state, action) {
            state.WSFindSearch = action.payload;
        },
    },
});
export const socketConnectionAcrtions = socketConnectionSlice.actions;
export default socketConnectionSlice.reducer;
