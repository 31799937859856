import { AnimatePresence, motion } from 'framer-motion';

import CircularProgress from '@/UI/circular-progress/CircularProgress';

import classes from './DownloadBtn.module.css';

type Props = {
    downloaded: number;
    onDownload: () => void;
    type: null | string;
    isDownloading: boolean;
};

const DownloadBtn: React.FC<Props> = props => {
    const { isDownloading, downloaded, onDownload } = props;

    return (
        <motion.div onClick={onDownload} className={`${classes['dl-button']}`}>
            <AnimatePresence>
                <CircularProgress
                    isDownloading={isDownloading}
                    progress={downloaded ? downloaded : 0}
                />
            </AnimatePresence>
        </motion.div>
    );
};
export default DownloadBtn;
