import { Trans, t } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useState } from 'react';
import { Dots } from 'react-activity';

import TabsManagement from '@/UI/TabsManagement/TabsManagement';
import addMembersSVG from '@/assets/icons/add-user-outline.svg';
import CustomErrorBoundary from '@/components/CustomErrorBoundary/CustomErrorBoundary';

import classes from './OrganizationMember.module.css';
import OrganizationAddMembers from './organization-add-members/OrganizationAddMembers';
import OrganizationCreateMember from './organization-create-member/OrganizationCreateMember';
import TableOrganizationMember from './table/TableOrganizationMember';
import useOrganizationMember from './useOrganizationMember';

type Props = {};

const OrganizationMember: FC = () => {
    const [selectedUser, setSelectedUser] = useState({});
    const [visibleRightTab, setVisibleRightTab] = useState(false);
    const [rightModalContent, setRightModalContent] = useState(-1);
    const [page, setPage] = useState('1');

    const tabs = [
        {
            id: 0,
            value: 'all-member',
            label: t`Members`,
        },
    ];

    const {
        handleSearchInput,
        searchText,
        onChangeCheckbox,
        isOpencheckboxModal,
        setIsOpenCheckboxModal,
        allClearCheckbox,
        allSelectCheckbox,
        handleSortData,
        isRoleSelected,
        filteredMemberData,
        onChangeTab,
        organization,
        servers,
        reduxLoading,
    } = useOrganizationMember({
        tabData: tabs,
    });

    const tabsManagementProps = {
        handleSearchInput,
        tabs,
        searchText,
        onChangeTab,
        isOpencheckboxModal,
        setIsOpenCheckboxModal,
        allSelectCheckbox,
        allClearCheckbox,
        isRoleSelected,
        onChangeCheckbox,
        checkboxFilterOptionsData: organization,
        isNoRole: false,
    };
    return (
        <>
            <div className={classes['container']}>
                <div className={classes['header']}>
                    <h2 style={{ margin: 0 }}>
                        <Trans>Organization Member</Trans>
                    </h2>
                    <TabsManagement {...tabsManagementProps}>
                        <img
                            alt={t`Add`}
                            src={addMembersSVG}
                            onClick={() => {
                                setVisibleRightTab(true);
                                setRightModalContent(0);
                            }}
                        />
                    </TabsManagement>
                </div>
                {reduxLoading === 'pending' && (
                    <div
                        style={{
                            margin: '1px auto',
                            width: 'fit-content',
                            textAlign: 'center',
                            fontSize: 12,
                        }}>
                        <b>
                            <Trans>Loading</Trans>...
                        </b>
                        <Dots />
                    </div>
                )}
                {reduxLoading === 'failed' && (
                    <div
                        style={{
                            margin: '1px auto',
                            width: 'fit-content',
                            textAlign: 'center',
                            fontSize: 12,
                        }}>
                        <b>
                            <Trans>Failed</Trans>!
                        </b>
                    </div>
                )}
                {reduxLoading === 'succeeded' && (
                    <div
                        style={{
                            height: '100%',
                            overflow: 'hidden',
                        }}>
                        <CustomErrorBoundary>
                            <TableOrganizationMember
                                data={filteredMemberData}
                                onSort={handleSortData}
                                onAddUser={item => {
                                    setVisibleRightTab(true);
                                    setSelectedUser(item);
                                    setRightModalContent(1);
                                }}
                                organization={organization[0]}
                            />
                        </CustomErrorBoundary>
                        {filteredMemberData?.length === 0 && (
                            <div
                                style={{
                                    margin: '0px auto',
                                    width: 'fit-content',
                                    textAlign: 'center',
                                    fontSize: 12,
                                }}>
                                <b>
                                    <Trans>No Result Found</Trans>!
                                </b>
                            </div>
                        )}
                    </div>
                )}
                <CustomErrorBoundary showErrorDetail>
                    <AnimatePresence>
                        {visibleRightTab && (
                            <motion.div
                                transition={{ duration: 0.2 }}
                                style={{
                                    width: 'auto',
                                    right: 0,
                                    position: 'absolute',
                                    zIndex: 99,
                                    height: '100%',
                                }}
                                initial={{
                                    x: '100%',
                                }}
                                animate={{ x: 0 }}
                                exit={{ x: '100%' }}
                                className={`${classes['right-tab']}`}>
                                {visibleRightTab &&
                                    (rightModalContent === 1 ? (
                                        <OrganizationAddMembers
                                            server={servers}
                                            user={selectedUser}
                                            organizationId={
                                                organization?.[0]?.id
                                            }
                                            organizationList={organization}
                                            onClose={() =>
                                                setVisibleRightTab(false)
                                            }
                                        />
                                    ) : (
                                        <OrganizationCreateMember
                                            server={servers}
                                            organizationId={
                                                organization?.[0]?.id
                                            }
                                            onClose={() =>
                                                setVisibleRightTab(false)
                                            }
                                            page={page}
                                            setPage={setPage}
                                        />
                                    ))}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </CustomErrorBoundary>
            </div>
        </>
    );
};

export default OrganizationMember;
