import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Badge, Icon } from '@/UI';
import {
    hideDirectChannel,
    onJoinChannel,
} from '@/Utils/socket-actions/ChannelActions/channelActions';
import { truncateText } from '@/Utils/textUtils';
import useStatusHelper from '@/Utils/useStatusHelper';
import CloseBorderIcon from '@/assets/icons/CloseBorderIcon';
import { DEFAULT_IMAGES } from '@/constants';
import { useAppDispatch } from '@/hooks/useRedux';
import { mobileViewActions } from '@/store/mobile-view-slice';
import { IChannelData } from '@/types';

import classes from './DMSection.module.css';

type memberType = {
    status: number;
    id: string;
    roles: string[];
    state: boolean;
    name: string;
    username: string;
    profile_picture?: string;
    email: string;
    email_verified_at: null;
    is_banned: number;
    created_at: string;
    updated_at: string;
    pivot: {
        chat_subserver_id: string;
        user_id: string;
        admin: number;
    };
    banned: boolean;
};
interface DMItemTypes {
    user: { channel: IChannelData; member: memberType };
    index: number;
    active: boolean;
    count: number | string;
}

const DMItem: React.FC<DMItemTypes> = React.memo(props => {
    const { getStatusById } = useStatusHelper();
    const { user, index, active, count } = props;

    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const joinDMHandler = () => {
        onJoinChannel(user.channel._id);
        if (isMobile) {
            dispatch(mobileViewActions.changeActiveTab(1));
        }
    };
    return (
        <li
            className={active ? classes['active-wrap'] : classes.wrap}
            key={`${user?.member?.id}-${index}`}
            onClick={joinDMHandler}>
            <Icon
                className={classes['icon']}
                imgClass={classes['icon-img']}
                imageURL={
                    user?.member?.profile_picture ??
                    DEFAULT_IMAGES.PROFILE_IMAGE
                }
                name={user?.member?.name}
                hasBadge={true}
                badgeStyle={{
                    backgroundColor: user?.member?.state ? '' : 'var(--gray)',
                    right: '-4px',
                    bottom: '-4px',
                    width: '8px',
                    height: '8px',
                    border: '3px solid var(--main-card-blue)',
                }}>
                {getStatusById(user.member?.status?.toString())?.icon &&
                user?.member?.state ? (
                    <img
                        alt=""
                        style={{
                            width: '10px',
                            height: '10px',
                        }}
                        src={
                            getStatusById(user.member.status?.toString())?.icon
                        }
                    />
                ) : (
                    <span></span>
                )}
            </Icon>

            <p className={classes['name']}>
                {truncateText(user.member?.name, 25)}
            </p>
            {+count > 0 && (
                <Badge className={classes['room-count-badge']}>
                    {+count > 99 ? '99+' : count}
                </Badge>
            )}
            <CloseBorderIcon
                stroke="var(--white)"
                className={classes['room-icon']}
                onClick={() => hideDirectChannel(user.channel._id, true)}
            />
        </li>
    );
});

export default DMItem;
