import { useEffect, useState } from 'react';

import HashtagIcon from '@/assets/icons/HashtagIcon';
import VolumeIcon from '@/assets/icons/VolumeIcon';

const useRoomTypeIcon = (channelType, className, extra) => {
    const [icon, setIcon] = useState();
    useEffect(() => {
        switch (channelType?.trim()) {
            case 'voice':
                setIcon(<VolumeIcon {...extra} className={className} />);
                break;
            default:
                setIcon(<HashtagIcon {...extra} className={className} />);
        }
    }, [channelType]);

    return {
        icon,
    };
};
export default useRoomTypeIcon;
