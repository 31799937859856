var userInteracted = false;
export const checkUserInteraction = () => {
    document.addEventListener('click', () => {
        userInteracted = true; // User clicked on the document
    });

    document.addEventListener('keypress', () => {
        userInteracted = true; // User pressed a key on the document
    });
    return userInteracted;
};
