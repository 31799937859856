import { Trans, t } from '@lingui/macro';

import { htmlParser } from '@/Utils/htmlParser';
import { handleOpenThread } from '@/Utils/socket-actions/ThreadActions/threadActions';
import { truncateText } from '@/Utils/textUtils';
import { formatCreatedDate } from '@/Utils/utils';
import { DEFAULT_IMAGES } from '@/constants';
import { useAppSelector } from '@/hooks/useRedux';

import classes from './MessageThreadLabel.module.css';

interface MessageThreadLabelProps {
    threadMessage: any;
    messageCount: number;
}

const MessageThreadLabel: React.FC<MessageThreadLabelProps> = ({
    messageCount,
    threadMessage,
}) => {
    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );
    const user = memberList[threadMessage?.user_id];

    const handleThread = () => {
        handleOpenThread(threadMessage?.thread_id);
    };
    let headerName = htmlParser(threadMessage?.message);
    return (
        <div
            onClick={handleThread}
            className={`${classes['thread-container']}`}>
            <div
                className={`${classes['d-flex']} ${classes['justify-between']}`}>
                {threadMessage?.type === 'text' ||
                threadMessage?.type === 'reply'
                    ? truncateText(headerName, 20)
                    : t`Send an attachment`}
                <div>
                    <span className={classes['total-num']}>{messageCount}</span>
                    <span
                        style={{ marginLeft: 5 }}
                        className={classes['total-num']}>
                        <Trans>Messages</Trans>
                    </span>
                </div>
            </div>
            <div className={`${classes['d-flex']} ${classes['justify-start']}`}>
                <img
                    className={classes['img']}
                    src={
                        user?.profile_picture
                            ? user?.profile_picture
                            : DEFAULT_IMAGES.PROFILE_IMAGE
                    }
                    alt=""
                />
                <p>{user?.name}</p>
                <span>{formatCreatedDate(threadMessage?.created_at)}</span>
            </div>
        </div>
    );
};
export default MessageThreadLabel;
