import { INoteContextProps } from '@/types/notesTypes';

export const initialContextValues: INoteContextProps = {
    noteCat: [],
    isFetchingCat: false,
    errorFetchCat: false,
    isFetchingNotes: false,
    errorFetchNotes: false,
    notes: [],
    activeNote: null,
    activeCategory: 'All',
    isForwardModal: false,
    isDeleteModal: false,
    isColorPickModal: false,
    isFilterModalOpen: false,
    filterType: null,
    searchValue: '',
    filteredNotes: [],
    tabs: {
        activeTab: 0,
        indexShift: 0,
        indexWidth: 83.5,
    },
    isAddNewGroup: false,
    isAddNewNote: false,
    isEditCategory: null,
    decryptedNotesBody: {},
    isEditorChanges: 0,
    body: null,
    isDeleteCategory: { status: false, id: '' },
    isFullMode: false,
    isEditNoteModal: null,
    selectedNotes: [],
    activeMobileStep: 0,
    setActiveMobileStep: () => {},
    setSelectedNotes: () => {},
    setIsEditNoteModal: () => {},
    setIsFullMode: () => {},
    copyNoteContent: () => {},
    setIsDeleteCategory: () => {},
    saveNote: async () => {
        return {
            data: {
                status: null,
                message: '',
            },
        };
    },
    changeNoteBodyHandler: (ev, editor) => {},
    setIsEditorChanges: () => 0,
    setDecryptedNotesBody: () => {},
    setNotes: () => {},
    setIsEditCategory: id => {},
    openCreatedNote: name => {},
    getNotes: async () => {
        return {
            data: null,
        };
    },
    removeCategory: id => {},
    setIsAddNewNote: () => {},
    fetchCategories: () => {},
    setIsAddNewGroup: () => {},
    setActiveNote: note => {},
    setActiveCategory: () => {},
    onForwardHandler: config => {},
    setSearchValue: () => {},
    setIsForwardModal: () => {},
    setIsDeleteModal: () => {},
    setIsColorPickModal: () => {},
    setFilterType: type => {},
    toggleFiltersModal: () => {},
    changeTabs: () => {},
};
