import fetchData from '@/Utils/fetchData';
import { REQUEST_TYPE } from '@/constants/requstTypes';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const getServers = async () => {
    const url = BASE_URL + '/api/v1/user/servers';
    const res = await fetchData(REQUEST_TYPE.GET, url);
    return res;
};

export const getMembers = async subserver_id => {
    const url = BASE_URL + `/api/v1/user/server/members/${subserver_id}`;
    const res = await fetchData(REQUEST_TYPE.GET, url);
    return res;
};

export const setPvtKeys = async (subserver_id, pkey) => {
    const url = BASE_URL + `/api/v1/server/${subserver_id}/setpkey`;
    const res = await fetchData(REQUEST_TYPE.POST, url, { pkey: pkey });
    return res;
};
