export type languagesType = {
    id: number;
    name: string;
    description: string;
    languageCode: string;
};

export const languagesArray = [
    {
        id: 0,
        name: 'English',
        description: 'Hello, Let’s start.',
        languageCode: 'en',
    },
    {
        id: 1,
        name: 'Turkish',
        description: 'Merhaba, Hadi Başlayalım.',
        languageCode: 'tr',
    },
    {
        id: 2,
        name: 'Russian',
        description: 'Привет, давай начнем.',
        languageCode: 'ru',
    },
    {
        id: 3,
        name: 'Portuguese',
        description: 'lá, vamos começar.',
        languageCode: 'pt',
    },
    {
        id: 4,
        name: 'Espanyol',
        description: 'Hola, vamos a empezar.',
        languageCode: 'es',
    },
];
