import { RootState } from '..';
import { createSelector } from '@reduxjs/toolkit';

export const messageThreadDataSelector = createSelector(
    (state: RootState) => state.threadsReducer.lastActiveThread.id,
    (state: RootState) => state.threadsReducer.activeThreadmessagesList,
    (activeThreadId, messagesList) => {
        if (activeThreadId) {
            const current = messagesList[activeThreadId] || {};
            return Object.values(current).reverse();
        }
        return [];
    },
);

export const threadListDataSelector = createSelector(
    (state: RootState) => state.serverDataReducer.activeServer,
    (state: RootState) => state.threadsReducer.threadList,

    (activeServer, threadList) => {
        if (activeServer) {
            const current = threadList[activeServer] || {};
            return Object.values(current);
        }
        return [];
    },
);
