import ReactDOM from 'react-dom';

import ToastContainer from '@/UI/toast/ToastContainer';

import HomeContent from '../HomeContent/HomeContent';
import ServersListBar from '../ServersListBar';
import SettingsModal from '../SettingsModal/SettingsModal';
import UploadServerKeys from '../UploadServerKeys/UploadServerKeys';
import WarningPage from '../Warning/WarningPage';
import MobileNavBar from '../mobile-navbar/MobileNavBar';

import classes from './Home.module.css';
import useHome from './useHome';

const Home = () => {
    const {
        activeServer,
        privateKey,
        chooseServerHandler,
        handleCloseSettingsModal,
        settingsModal,
        serverData,
        emptyData,
        isMobile,
        activeMobileTab,
    } = useHome();
    return (
        <div
            className={classes['home-container']}
            style={{
                gridTemplateColumns:
                    isMobile && activeMobileTab === 0
                        ? '64px 1fr'
                        : isMobile && activeMobileTab !== 0 && '1fr',
            }}>
            {((isMobile && activeMobileTab === 0) || !isMobile) && (
                <ServersListBar
                    style={{
                        height:
                            isMobile && !privateKey
                                ? '100vh'
                                : isMobile && 'calc(100dvh - 64px)',
                    }}
                    refServer={activeServer ? activeServer : 0}
                    onChooseServer={chooseServerHandler}
                />
            )}

            {serverData.length === 0 && emptyData && <WarningPage />}
            {privateKey && privateKey !== undefined ? (
                <>
                    <HomeContent />
                    {ReactDOM.createPortal(
                        <ToastContainer />,
                        document.getElementById('toast-root'),
                    )}
                    {settingsModal.visible && (
                        <SettingsModal onClose={handleCloseSettingsModal} />
                    )}
                    {isMobile && <MobileNavBar />}
                </>
            ) : (
                serverData.length !== 0 && <UploadServerKeys />
            )}
        </div>
    );
};
export default Home;
