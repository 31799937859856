import React, { useEffect, useState } from 'react';

import { Line } from '@/UI';
import { arraysAreEqual } from '@/Utils/objectUtils';
import { useAppSelector } from '@/hooks/useRedux';
import arrowIcon from '@/assets/icons/arrow-down.svg';
import {
    directChannelDataSelector,
    memberDataSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import { IChannelData } from '@/types';

import DMItem from './DMItem';
import classes from './DMSection.module.css';

const DMSection = props => {
    const activeChannelId = props.activeChannel;

    const [receivers, setReceivers] = useState([]);
    const [toggleBody, setToggleBody] = useState(true);

    const members = useAppSelector(memberDataSelector);
    const userId = useAppSelector(state => state.userReducer.user.id);
    const countList = useAppSelector(
        state =>
            state.serverDataReducer.channelCounts[
                state.serverDataReducer.activeServer
            ],
    );
    const channel: IChannelData[] = useAppSelector(directChannelDataSelector);
    const channelCount = useAppSelector(
        state =>
            state.serverDataReducer.channelCounts?.[
                state.serverDataReducer.activeServer
            ],
    );

    useEffect(() => {
        let memberArray = [];
        let privateChannels = channel?.filter(
            ch =>
                !ch?.is_hidden ||
                channelCount?.[ch?._id] > 0 ||
                ch?._id === activeChannelId,
        );
        if (props.unreadOnly) {
            privateChannels = privateChannels.filter(
                ch => channelCount[ch?._id] > 0 || ch?._id === activeChannelId,
            );
        }
        privateChannels.forEach(userMsg => {
            let receiverId: string[] | string = userMsg.name.split('_');

            if (receiverId.length === 1 || receiverId[0] === receiverId[1]) {
                receiverId = receiverId[0];
            } else {
                receiverId = receiverId.find(id => id !== userId);
            }

            if (receiverId) {
                memberArray.push({
                    channel: userMsg,
                    member: members.filter(e => e.id === receiverId)[0],
                });
            }
        });
        if (!arraysAreEqual(receivers, memberArray)) {
            setReceivers(memberArray);
        }
    }, [channel, props.unreadOnly, activeChannelId, members]);

    return (
        <>
            {receivers.length > 0 && (
                <div className={classes['container']}>
                    <div
                        onClick={() => setToggleBody(prev => !prev)}
                        className={classes['d-flex']}>
                        <img
                            style={
                                toggleBody
                                    ? { transform: 'rotate(0)' }
                                    : { transform: 'rotate(-90deg)' }
                            }
                            src={arrowIcon}
                            alt=""
                        />
                        <h3>{props.title}</h3>
                    </div>
                    {toggleBody && (
                        <>
                            <Line className={classes.hr} />
                            <ul>
                                {receivers?.map((user, key) => {
                                    return (
                                        <DMItem
                                            user={user}
                                            index={key}
                                            key={key + user?.channel?._id}
                                            active={
                                                user?.channel?._id ===
                                                activeChannelId
                                            }
                                            count={
                                                user?.channel?._id
                                                    ? countList?.[
                                                          user.channel._id
                                                      ] ?? 0
                                                    : 0
                                            }
                                        />
                                    );
                                })}
                            </ul>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default React.memo(DMSection);
