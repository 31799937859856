import { Key } from 'react';

import dotsTree from '@/assets/icons/dots-tree.svg';

import classes from './TableMemberManagement.module.css';

export type optionTypes = {
    icon: string;
    label: string;
    onClick: (e) => void;
    userPermissions: string[];
};
type IProps = {
    data;
    onMouseEnter;
    onMouseLeave;
    canMap: boolean;
    actionIconsPermissions: optionTypes[];
};

const HoverIcons: React.FC<IProps> = props => {
    const { data, onMouseEnter, onMouseLeave, canMap, actionIconsPermissions } =
        props;

    return (
        <div
            className={classes['tree-dots-container']}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            <img alt="options" style={{ cursor: 'pointer' }} src={dotsTree} />
            {canMap && (
                <div className={classes['edit-image-popup-container']}>
                    <div className={classes['edit-image-popup']}>
                        <div className={classes['triangle']}></div>
                        {actionIconsPermissions.map((item, index: Key) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => item.onClick(data)}
                                    className={classes['edit-image-popup-row']}>
                                    <img
                                        alt="action"
                                        height={18}
                                        width={18}
                                        src={item?.icon}
                                    />
                                    <p>{item?.label}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
export default HoverIcons;
