import { memo } from 'react';

import formatFileSize from '@/Utils/fileSzeFormatter';
import { truncateFileName } from '@/Utils/truncateFileName';
import DownloadedTypeIcon from '@/components/download-btn/DownloadedTypeIcon';
import SaveBtn from '@/components/save-btn/SaveBtn';

import classes from './SelectedMediaDisplay.module.css';
import { PdfMediaProps } from './SelectedMediaTypes';

const PdfMedia: React.FC<PdfMediaProps> = props => {
    const { file } = props;

    const outputFileName = truncateFileName(file?.name ?? '');

    const openPdf = (e: React.MouseEvent<HTMLDivElement>) => {
        // don't wrap the div with <a> -> divs can not be inside <a>
        e.stopPropagation();
        const link = document.createElement('a');
        link.href = file.src;
        link.target = '_blank';
        link.rel = 'noreferrer';
        link.click();
    };
    return (
        <div
            onClick={openPdf}
            className={`cursor-pointer ${classes['download-file-container']}`}>
            <DownloadedTypeIcon type={'pdf'} />
            <div className={classes['mr-auto']}>
                <p className={classes['label-name']}>{outputFileName}</p>
                <p className={classes['label-size']}>
                    {file.data?.size
                        ? `${formatFileSize(+file.data.size)}`
                        : 'Unknown size'}
                </p>
            </div>
            <SaveBtn
                className={classes['save-btn']}
                src={file?.src}
                name={file?.name ?? 'Unknown'}
            />
        </div>
    );
};
export default memo(PdfMedia);
