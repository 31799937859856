export const cleanEditorOutput = editorOutput => {
    let cleanedText = editorOutput.replace(/<[^>]+>/g, '');

    // Split text by '<br />' and get the first non-empty line
    const lines = cleanedText.split('<br />');
    let firstLine = '';
    for (const line of lines) {
        if (line.trim() !== '') {
            firstLine = line.trim();
            break;
        }
    }
    // Remove additional text after ![Alt Text]()
    firstLine = firstLine.replace(/\!\[Alt Text\]\(.+?\)/g, 'Image');

    // Remove Markdown header syntax
    firstLine = firstLine.replace(/^#+\s*/, '');

    // Remove ~~ before and after the text, but keep the text inside them
    firstLine = firstLine.replace(/~~(.+?)~~/g, '$1');

    // Remove ** before and after the text, but keep the text inside them
    firstLine = firstLine.replace(/\*\*(.+?)\*\*/g, '$1');

    // Remove * before and after the text, but keep the text inside them
    firstLine = firstLine.replace(/\*(.+?)\*/g, '$1');

    // Extract URL from Markdown link syntax
    firstLine = firstLine.replace(/\[(.*?)\]\((.*?)\)/g, '$2');

    // Replace Markdown table syntax with "Table"
    firstLine = firstLine.replace(
        /\|.*?\|.*?\r?\n\|--.*?\r?\n\|.*?\|.*?\|/,
        ' Table ',
    );
    firstLine = firstLine.replace(/-\s*\[\s*.*?\s*\]/g, ' CheckList ');

    return firstLine;
};
