import classes from './EditChannel.module.css';
import { useEditChannelContext } from './EditChannelContext';

const Tabs: React.FC<{}> = () => {
    
    const { componentMap, selectedComponent, selectComponent } =
        useEditChannelContext();

    return (
        <div className={classes['left-navbar']}>
            {Object.keys(componentMap).map((componentId, key) => (
                <div
                    key={key}
                    className={`
                        ${classes['navbar-item']} ${
                        selectedComponent === componentId
                            ? classes['active']
                            : ''
                    }`}
                    onClick={() => selectComponent(componentId)}>
                    {typeof componentMap[componentId].icon === 'string' ? (
                        <img
                            alt=""
                            src={componentMap[componentId].icon}
                            className={classes['navbar-icon']}
                        />
                    ) : (
                        componentMap[componentId].icon
                    )}

                    <span className={classes['navbar-text']}>
                        {componentMap[componentId].name}
                    </span>
                </div>
            ))}
        </div>
    );
};
export default Tabs;
