import { t } from '@lingui/macro';

import logout from '@/assets/icons/logout.svg';
import { useCustomSweetAlert } from '@/hooks';

import classes from './ServersListBar.module.css';

type IProps = {
    logoutHandler;
};
const LogoutItem: React.FC<IProps> = ({ logoutHandler }) => {
    const { areYouSureModal } = useCustomSweetAlert();

    return (
        <li
            className={classes['logout-item']}
            onClick={() =>
                areYouSureModal(
                    t`Logout`,
                    t`Are you sure you want to log out?`,
                    false,
                    logoutHandler,
                )
            }>
            <img style={{ width: 36 }} src={logout} alt={t`Logout`} />
        </li>
    );
};
export default LogoutItem;
