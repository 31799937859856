import { RootState } from '.';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    getOrganizationMembers,
    getOrganizationServers,
    getOrganizations,
    postAddMemberServer,
    putCreateOrganizationMember,
    updateAddMemberServer,
} from '@/api/organization';
import { REQUEST_TYPE } from '@/constants/requstTypes';

import { updateServerMemberData } from './server-data-reducer/server-data-reducer';

// initial state
const initialState = {
    organizationList: [],
    organizationMembers: {},
    organizationServers: {},
    loading: 'idle',
};

export const getOrganizationsData = createAsyncThunk(
    'organization/getOrganizations',
    async () => {
        const res = await getOrganizations();
        return res.data;
    },
);

export const getOrganizationsMembersData = createAsyncThunk(
    'organization/getMembers',
    async (payload: any) => {
        const res = await getOrganizationMembers(payload);
        return res.data;
    },
);

export const getOrganizationsServersData = createAsyncThunk(
    'organization/getServers',
    async (payload: any) => {
        const res = await getOrganizationServers(payload);
        return res.data;
    },
);

export const putCreateMember = createAsyncThunk(
    'organization/createMember',
    async (payload: any) => {
        const res = await putCreateOrganizationMember(payload);
        return res;
    },
);

export const postAddMembertoServer = createAsyncThunk(
    'organization/createMember',
    async (payload: any) => {
        const res = await postAddMemberServer(payload);
        return res;
    },
);

export const patchUpdateMembertoServer = createAsyncThunk(
    'organization/updateMember',
    async (payload: any) => {
        const res = await updateAddMemberServer(payload);
        return res.data;
    },
);

const organizationSlice = createSlice({
    name: 'organization',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getOrganizationsData.fulfilled, (state, action) => {
            state.organizationList = action.payload.data;
        });
        builder
            .addCase(getOrganizationsData.pending, state => {
                state.loading = 'pending';
            })
            .addCase(getOrganizationsMembersData.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                const organizationId = action.meta.arg;
                state.organizationMembers[organizationId] =
                    action.payload.data.map(item => {
                        return { ...item, organization_id: organizationId };
                    });
            })
            .addCase(getOrganizationsData.rejected, state => {
                state.loading = 'failed';
            });
        builder.addCase(
            getOrganizationsServersData.fulfilled,
            (state, action) => {
                const organizationId = action.meta.arg;
                state.organizationServers[organizationId] = action.payload.data;
            },
        );
        builder.addCase(postAddMembertoServer.fulfilled, (state, action) => {
            const { organizationId, serverId, user_id, requestType } =
                action.meta.arg;

            if (action?.payload?.status) {
                if (requestType === REQUEST_TYPE.DELETE) {
                    let userIndex = state.organizationMembers[
                        organizationId
                    ].findIndex(obj => obj.id === user_id);

                    if (userIndex !== -1) {
                        // Find the server index within the user's servers array
                        let serverIndex = state.organizationMembers[
                            organizationId
                        ][userIndex].servers.findIndex(
                            item => item.id === serverId,
                        );

                        if (serverIndex !== -1) {
                            // Use filter to create a new array without the server with the specified ID
                            state.organizationMembers[organizationId][
                                userIndex
                            ].servers = state.organizationMembers[
                                organizationId
                            ][userIndex].servers.filter(
                                item => item.id !== serverId,
                            );
                        }
                    }
                }
            }
        });
    },
});

export const organizationActions = organizationSlice.actions;
export default organizationSlice.reducer;
