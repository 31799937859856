import { t } from '@lingui/macro';
import { useEffect, useMemo } from 'react';

import { addFileToDB, checkFileExist } from '@/Utils/mediaFilesUtils';
import { onDecryptMessage } from '@/Utils/onDecryptMessage';
import { downloadFile } from '@/Utils/serverMediaHandler.js';
import { handleAuthSuccess } from '@/Utils/socket-actions/AuthActions/sendAuthActionsListner';
import {
    deleteChannelHandler,
    deleteSectionHandler,
    getChannelMember,
    getRolesList,
    handleMemberRoles,
    handleMemberRolesUpdate,
    leaveChannelHandler,
    onJoinChannel,
} from '@/Utils/socket-actions/ChannelActions/channelActions';
import {
    channelDeleteSuccess,
    channelRoleUsersHandler,
    editChannelHandler,
    handleChannelCount,
    handleChannelJoinSuccess,
    handleChannelList,
    handleChannelMembersList,
    handleChannelReactions,
    handleCreateChannel,
    handleCreateChannelMemberPermission,
    handleCreateSection,
    handleFetchChannelPermmission,
    handleGetChannelMemberPermission,
    handleGetChannelPermissions,
    handleMemberList,
    handlePublicChannelList,
    handleReadAllMessages,
    handleRolesList,
    handleSectionList,
} from '@/Utils/socket-actions/ChannelActions/channelListnerActions';
import { sendDesktopAuth } from '@/Utils/socket-actions/DesktopAuthActions/DesktopAuthActionsListner';
import {
    handleAddChannelToOtherServer,
    handleDeleteIneterServer,
    handleInterServerError,
    handleOtherServerChannels,
} from '@/Utils/socket-actions/InterSubServerActions/interSubServerActionsListener';
import {
    lastSeenMessage,
    onEncryptMessage,
    sendSearchMessagesDesktop,
} from '@/Utils/socket-actions/MessageActions/messageActions';
import {
    deleteMessageHandler,
    handleEditReaction,
    handleMessageCreateSeen,
    handleMessageList,
    handleMessagesInRangle,
    onNewMessage,
} from '@/Utils/socket-actions/MessageActions/messageActionsListner';
import { getNotificationsData } from '@/Utils/socket-actions/NotificationActions/notificationActions';
import {
    handleChannelNotficationUpdate,
    handleNewNotifcation,
    handleNewNotifcationMention,
    handleNewNotifcationReaction,
    handleNotifcationList,
    handleNotifcationMentionList,
    handleNotifcationReactionList,
} from '@/Utils/socket-actions/NotificationActions/notificationActionsListner';
import {
    searchChatServerDataHandler,
    setChatSearchError,
    setSearchTimeZone,
} from '@/Utils/socket-actions/SearchActions/SearchActionsListener';
import { handleGetStatistics } from '@/Utils/socket-actions/StatisticsActions/statisticsActionsListener';
import { handleGetAuditLogs } from '@/Utils/socket-actions/StatisticsActions/statisticsActionsListener';
import {
    editMessageHandler,
    handleActiveThreadMembers,
    handleCreateThread,
    handleDeleteThread,
    handleDeleteThreadMessage,
    handleGetThreasList,
    handleJoinThread,
    handleLeaveThread,
    handleLockThread,
    handleThreadDeltails,
    handleThreadMessageList,
    handleThreadMessageUpdate,
    handleThreadReactionUpdate,
    handleaddThreadMessage,
} from '@/Utils/socket-actions/ThreadActions/threadListnerActions';
import {
    getLastSeenUsers,
    handleFetchData,
    handleFetchUserData,
    handleMemberListUpdate,
    handlePermissionMe,
    handleProfileStatusUpdate,
    handleUserBanUpdate,
} from '@/Utils/socket-actions/UserActions/userActionsListner';
import { updateRolewithPermission } from '@/Utils/socket-actions/getChannelPermission';
import { updatePingHandler } from '@/Utils/socket-actions/updatePingHandler';
import {
    sendFindSearchData,
    sendWebSocketData,
    setEventHandler,
    setSearchFindEventHandler,
    setSearchSocketHandler,
} from '@/Utils/webSocketUtils';
import { fetchStrangeUserData } from '@/api/member';
import SOCKET_ACTIONS from '@/constants/socketActions';
import { backendSearchActions } from '@/store/backend-search-results';
import { messageDataSelector } from '@/store/messages-reducer/selectors-messages';
import { indexedDBSearchActions } from '@/store/search-reducer/indexed-db-search-reducer.js';
import {
    activeChannelIdSelector,
    activeServerIdSelector,
    notificationSelector,
    privateKeyArray,
} from '@/store/server-data-reducer/selectors-server-data';
import {
    editSectionDetails,
    setActiveChannel,
    toggleDMVisibility,
    updatePrivateKey,
    updateRolePermissions,
    updateServer,
    updateServerPermissions,
} from '@/store/server-data-reducer/server-data-reducer';
import {
    updateDecryptionLoader,
    updateEncryptionMetaData,
    updateIsFetchSearchHeader,
} from '@/store/util-reducer';
import { IMessageData, IServerData } from '@/types';

import useCustomSweetAlert from './useCustomSweetAlert';
import useLogoutHandler from './useLogoutHandler';
import { useAppDispatch, useAppSelector } from './useRedux';

var sendLastSeen = false;

const useChatHelper = () => {
    const { errorAlertModal } = useCustomSweetAlert();
    const { logoutHandler } = useLogoutHandler();

    const dispatch = useAppDispatch();

    const serverData: IServerData = useAppSelector(
        ({ serverDataReducer }) =>
            serverDataReducer?.serverData[serverDataReducer?.activeServer],
    );
    const memoServerData = useMemo(() => serverData, [serverData]);

    const privateKeySelector = useAppSelector(privateKeyArray);
    const privateKeysObject = Object.fromEntries(
        privateKeySelector.map(item => [item['id'], item]),
    );

    const notifcationList = useAppSelector(notificationSelector);
    const activeServer = useAppSelector(activeServerIdSelector);
    const activeChannel = useAppSelector(activeChannelIdSelector);
    const activeThreadMembers = useAppSelector(
        state => state.threadsReducer.activeThreadMembers,
    );
    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );
    const strangerMemberList = useAppSelector(
        state =>
            state.serverDataReducer.strangerMemberList[
                state.serverDataReducer.activeServer
            ],
    );

    const privateKey = serverData?.privateKey ?? '';
    const publicKey = serverData?.publicKey ?? '';

    // const cryptoKeys = useAppSelector(state => state.cryptoKeyReducer);
    // const privateKey =
    // cryptoKeys['b5e953818a4dd5fea143666d172fdbe1']?.privateKey ?? '';
    // const publicKey =
    // cryptoKeys['b5e953818a4dd5fea143666d172fdbe1']?.publicKey ?? '';

    // const channel: any = useAppSelector(state => channelDataSelector(state));

    const userSelector = useAppSelector(state => state.userReducer.user);
    const messagesArray: IMessageData[] | any = useAppSelector(state =>
        messageDataSelector(state),
    );
    const lastSeenUsers = useAppSelector(
        state => state.messageReducer.lastSeenMessage,
    );
    const dispatchUpdatePrivateKey = () => {
        dispatch(
            updatePrivateKey({
                id: activeServer,
                defaultHashkey: undefined,
                privateKey: undefined,
                publicKey: undefined,
            }),
        );
    };

    const handleAuthError = data => {
        errorAlertModal(
            t`Auth Error`,
            data,
            dispatchUpdatePrivateKey,
            dispatchUpdatePrivateKey,
        );
    };

    const onDownloadMedia = async mediaFiles => {
        const filePromises = mediaFiles.map(async item => {
            try {
                const file = await downloadFile({}, item, userSelector.token);
                const { fileLink, fileInfo } = file;
                return {
                    data: fileLink,
                    type: fileInfo?.type,
                    filename: fileInfo?.name,
                    id: item.fileKey,
                };
            } catch (error) {
                return null;
            }
        });

        return Promise.all(filePromises);
    };

    const handleMediaMessages = async (decryptMessage, messageId, active) => {
        if (!active) {
            return null;
        }
        if (decryptMessage?.mediaFiles !== undefined && messageId) {
            const fileExists = await checkFileExist(
                decryptMessage?.mediaFiles[0]?.fileKey,
            );
            if (!fileExists) {
                const downloadedFiles = await onDownloadMedia(
                    decryptMessage.mediaFiles,
                );
                downloadedFiles &&
                    downloadedFiles.map(async (el: any) => {
                        if (messageId !== undefined && el !== null) {
                            el.messageId = messageId;
                            await addFileToDB(el);
                        }
                    });
            }
        }
    };

    const sendWsMessageRange = messageId => {
        const payload = {
            action: SOCKET_ACTIONS.messageRange.action,
            data: {
                message_id: messageId,
            },
        };
        sendWebSocketData(JSON.stringify(payload));
    };

    const getAllChannelsLastMessagesHandler = async (data, channelId) => {
        for (const channelData of data) {
            await handleMessageList({
                channel_id: channelData?.channel_id,
                messages: channelData?.messages,
                prev: channelData?.prev,
                handleMediaMessages,
                privateKey: privateKey,
                privateKeysObject: privateKeysObject,
            });
            const desktopMessages = channelData.messages.map((el: any) => {
                return {
                    message_id: el._id,
                    sender_id: el.user_id,
                    channel_id: el.channel_id,
                    text: el.message,
                    created_at: el.created_at,
                    token: el.token,
                };
            });
            sendSearchMessagesDesktop(desktopMessages);

            // FOR STRANGER USERS
            const userIdSet = new Set();

            channelData?.messages?.forEach(message => {
                userIdSet.add(message.user_id);
            });
            const uniqueUserIds: any[] = Array.from(userIdSet);

            uniqueUserIds?.map(item => {
                if (
                    (memberList && memberList?.[item]) ||
                    (strangerMemberList && strangerMemberList?.[item])
                ) {
                    return;
                } else {
                    fetchStrangeUserData({ user_id: item });
                }
            });
            // FOR STRANGER USERS
        }

        channelId &&
            channelId !== activeChannel &&
            setTimeout(() => {
                onJoinChannel(activeChannel);
            }, 800);
    };

    const sendSearchKeyword = (keyword: string) => {
        let payload = {
            event: 'search',
            data: keyword,
        };
        sendFindSearchData(JSON.stringify(payload));
    };

    const migrateEncryption = async data => {
        const getPrevPrivateKey = sessionStorage.getItem('prev_privateKey');
        const { metaData } = data;
        dispatch(updateEncryptionMetaData(metaData));
        // return;
        try {
            const promiseResolve = await Promise.all(
                data?.messages?.map(
                    async (item: IMessageData, index: number) => {
                        if (item.encryption_version === 1) {
                            let decryptMessage: any = item.message;
                            try {
                                decryptMessage = await onDecryptMessage(
                                    item,
                                    getPrevPrivateKey ?? serverData.privateKey,
                                );

                                if (decryptMessage) {
                                    const { encryptToken, encryptedData } =
                                        await onEncryptMessage(decryptMessage);
                                    const newEncryptedMessage = {
                                        message_id: item._id,
                                        token: encryptToken,
                                        message: encryptedData,
                                        encryption_version: 2,
                                    };
                                    const payload = {
                                        action: 'message_admin_update',
                                        data: newEncryptedMessage,
                                    };
                                    sendWebSocketData(JSON.stringify(payload));

                                    return newEncryptedMessage;
                                }
                            } catch (error) {
                                console.log('decryptMessage ERROR', item);
                            }
                        } else return;
                    },
                ),
            );
            if (promiseResolve) console.log('PEOMISE RESOLVE');
        } catch (error) {
            console.error(error);
        }

        if (metaData?.total_pages === 0)
            return dispatch(updateDecryptionLoader(false));
        if (metaData?.currentPageNumber !== metaData?.total_pages) {
            const payload = {
                action: 'get_all_messages',
                data: {
                    page_num: metaData?.currentPageNumber + 1,
                    message_encryption: data?.messages?.[0]?.encryption_version,
                },
            };
            sendWebSocketData(JSON.stringify(payload));
        } else dispatch(updateDecryptionLoader(false));
    };

    const eventHandler = (event: MessageEvent) => {
        const { data, action } = JSON.parse(event.data);

        const actionHandlers = {
            [SOCKET_ACTIONS.auth.error]: () => handleAuthError(data),
            [SOCKET_ACTIONS.auth.success]: () =>
                handleAuthSuccess({
                    activeChannel: activeChannel,
                    activeServer: activeServer,
                    privateKey: privateKey,
                    publicKey: publicKey,
                    serverData: serverData,
                }),

            //channels actions
            [SOCKET_ACTIONS.channelList.action]: () =>
                handleChannelList(data, privateKey),

            [SOCKET_ACTIONS.channelJoin.success]: () => {
                handleChannelJoinSuccess({
                    data: data,
                    notifcationList: notifcationList,
                });
            },
            [SOCKET_ACTIONS.channelSection.action]: () =>
                handleSectionList(data),

            [SOCKET_ACTIONS.channelCreate.success]: () => {
                onJoinChannel(data._id);
            },
            [SOCKET_ACTIONS.channelCreate.action]: () => {
                handleCreateChannel(data);
            },
            [SOCKET_ACTIONS.sectionCreate.action]: () => {
                handleCreateSection(data);
            },
            [SOCKET_ACTIONS.messageCount.action]: () => {
                handleChannelCount(data);
            },
            [SOCKET_ACTIONS.channelDelete.action]: () => {
                deleteChannelHandler(data);
            },
            [SOCKET_ACTIONS.channelDelete.success]: () => {
                channelDeleteSuccess();
            },
            [SOCKET_ACTIONS.channelEdit.action]: () => {
                const targetData = data?.data ?? data;
                editChannelHandler({
                    data: targetData,
                    privateKey: privateKey,
                });
            },
            [SOCKET_ACTIONS.channelMember.success]: () => {
                handleChannelMembersList(data);
            },
            [SOCKET_ACTIONS.channelMember.action]: () => {
                handleChannelMembersList(data);
            },
            [SOCKET_ACTIONS.sectionEdit.action]: () => {
                dispatch(editSectionDetails(data));
            },
            [SOCKET_ACTIONS.channelHide.success]: () => {
                dispatch(
                    toggleDMVisibility({
                        id: data.channel_id,
                        is_hidden: data.is_hidden,
                    }),
                );
                if (activeChannel === data.channel_id && data.is_hidden) {
                    dispatch(setActiveChannel(''));
                }
            },
            [SOCKET_ACTIONS.addRoleInChannel.success]: () => {
                channelRoleUsersHandler(data);
            },
            [SOCKET_ACTIONS.removeRoleInChannel.success]: () => {
                channelRoleUsersHandler(data);
            },
            [SOCKET_ACTIONS.addUserInChannel.action]: () => {
                channelRoleUsersHandler(data);
            },
            [SOCKET_ACTIONS.removeUserFromChannel.action]: () => {
                channelRoleUsersHandler(data);
            },
            [SOCKET_ACTIONS.userChannelCreate.success]: () => {
                channelRoleUsersHandler(data);
            },
            [SOCKET_ACTIONS.sectionDelete.action]: () => {
                deleteSectionHandler(data);
            },
            [SOCKET_ACTIONS.channelLeave.success]: () => {
                leaveChannelHandler(data);
            },
            [SOCKET_ACTIONS.userChannelRemove.success]: () => {
                getChannelMember(data?.channel_id);
            },
            [SOCKET_ACTIONS.createChannelUsersPermissions.success]: () => {
                handleCreateChannelMemberPermission(data);
            },
            [SOCKET_ACTIONS.getChannelUsersPermissions.success]: () => {
                handleGetChannelMemberPermission(data);
            },
            [SOCKET_ACTIONS.getChannelUsersPermissions.error]: () => {
                handleGetChannelMemberPermission(data);
            },
            [SOCKET_ACTIONS.fetchChannelPermissions.action]: () => {
                handleFetchChannelPermmission({
                    data: data,
                    userSelector: userSelector,
                    activeChannel: activeChannel,
                });
            },
            [SOCKET_ACTIONS.publicChannelList.action]: () => {
                handlePublicChannelList({ data: data, privateKey: privateKey });
            },
            [SOCKET_ACTIONS.channelReactions.action]: () => {
                handleChannelReactions(data);
            },
            [SOCKET_ACTIONS.channelPermission.action]: () => {
                handleGetChannelPermissions(data);
            },
            [SOCKET_ACTIONS.channelPermission.success]: () => {
                handleGetChannelPermissions(data);
            },
            //Roles
            [SOCKET_ACTIONS.role.action]: () => {
                handleRolesList(data);
            },
            [SOCKET_ACTIONS.rolePermission.action]: () => {
                dispatch(updateRolePermissions(data));
            },
            [SOCKET_ACTIONS.roleCreate.success]: () => {
                getRolesList();
                updateRolewithPermission();
            },
            [SOCKET_ACTIONS.roleUpdate.success]: () => {
                getRolesList();
                updateRolewithPermission();
            },
            [SOCKET_ACTIONS.userRoles.action]: () => {
                handleMemberRoles(data);
            },
            [SOCKET_ACTIONS.userRoleCreate.success]: () => {
                handleMemberRolesUpdate();
            },
            [SOCKET_ACTIONS.userRoleDelete.success]: () => {
                handleMemberRolesUpdate();
            },
            [SOCKET_ACTIONS.roleDelete.success]: () => {
                getRolesList();
            },

            //Messages
            [SOCKET_ACTIONS.channelMessages.action]: () => {
                handleMessageList({
                    channel_id: data?.channel_id,
                    messages: data?.messages,
                    prev: data?.prev,
                    handleMediaMessages,
                    privateKey: privateKey,
                    privateKeysObject: privateKeysObject,
                });
                if (sendLastSeen && data.messages) {
                    lastSeenMessage(data.messages);
                }
            },
            [SOCKET_ACTIONS.chat.action]: () => {
                onNewMessage({
                    data: data,
                    handleMediaMessages,
                    privateKey: privateKey,
                });
                if (data?.seen_users?.length) {
                    handleMessageCreateSeen(data, lastSeenUsers);
                }
            },
            [SOCKET_ACTIONS.reactionUpdate.action]: () => {
                handleEditReaction(data);
            },
            [SOCKET_ACTIONS.rangeMessage.success]: () => {
                handleMessagesInRangle({
                    data: data,
                    channel_id: data?.channel_id,
                    handleMediaMessages,
                    privateKey: privateKey,
                });
            },
            [SOCKET_ACTIONS.messageDelete.action]: () => {
                deleteMessageHandler(data);
            },
            [SOCKET_ACTIONS.messageEdit.action]: () => {
                editMessageHandler(data, privateKey);
            },
            [SOCKET_ACTIONS.messageUpdate.action]: () => {
                editMessageHandler(data, privateKey);
            },

            [SOCKET_ACTIONS.getLastHundredMessages.success]: () => {
                getAllChannelsLastMessagesHandler(data, activeChannel);
            },
            [SOCKET_ACTIONS.ReadAllMessages.success]: () => {
                handleReadAllMessages(activeServer);
            },

            //members
            [SOCKET_ACTIONS.memberUpdate.action]: () => {
                handleMemberListUpdate(data);
            },
            [SOCKET_ACTIONS.fetchUserData.action]: () => {
                handleFetchUserData(data);
            },
            [SOCKET_ACTIONS.memberOnline.action]: () => handleMemberList(data),
            [SOCKET_ACTIONS.userStatus.action]: () => {
                handleMemberList(data);
                handleProfileStatusUpdate(data, userSelector);
            },
            [SOCKET_ACTIONS.memberStatusUpdate.action]: () => {
                handleMemberList(data.all_users_status);
            },
            //permissions
            [SOCKET_ACTIONS.permissionList.action]: () => {
                dispatch(updateServerPermissions(data));
            },

            // Notifications
            [SOCKET_ACTIONS.mentionNotificaitionList.action]: () => {
                handleNotifcationMentionList(data);
            },
            [SOCKET_ACTIONS.mentionNotificationUpdate.action]: () => {
                handleNewNotifcationMention(data);
            },
            [SOCKET_ACTIONS.reactionNotificationList.action]: () => {
                handleNotifcationReactionList(data);
            },
            [SOCKET_ACTIONS.reactionNotificationUpdate.action]: () => {
                handleNewNotifcationReaction(data);
            },
            [SOCKET_ACTIONS.channelNotificationUpdate.success]: () => {
                handleChannelNotficationUpdate(data, privateKey);
            },
            [SOCKET_ACTIONS.notification.action]: () => {
                handleNewNotifcation(data);
            },
            [SOCKET_ACTIONS.getNotifications.success]: () => {
                handleNotifcationList({
                    data: data,
                    handleMediaMessages,
                    privateKey: privateKey,
                });
            },
            [SOCKET_ACTIONS.markedAllRead.success]: () => {
                getNotificationsData(0);
            },
            // DO NOT DELETE
            [SOCKET_ACTIONS.getAllMessages.success]: () =>
                migrateEncryption(data),

            //users actions

            [SOCKET_ACTIONS.userBanUpdate.action]: () => {
                handleUserBanUpdate({
                    activeServer: activeServer,
                    data: data,
                    logoutHandler,
                    userSelector: userSelector,
                });
            },
            [SOCKET_ACTIONS.LastSeenUsers.action]: () => {
                getLastSeenUsers(data);
            },
            [SOCKET_ACTIONS.permissionMe.action]: () => {
                handlePermissionMe(data);
            },
            [SOCKET_ACTIONS.fetchData.action]: () => {
                handleFetchData(activeServer);
            },

            [SOCKET_ACTIONS.searchResult.action]: () =>
                searchChatServerDataHandler(data),
            [SOCKET_ACTIONS.timezone.success]: () => setSearchTimeZone(data),
            [SOCKET_ACTIONS.search.error]: () => setChatSearchError(data),

            //audit logs actions

            [SOCKET_ACTIONS.getAuditLogs.success]: () => {
                handleGetAuditLogs({ data: data, privateKey: privateKey });
            },

            //statistics actions
            [SOCKET_ACTIONS.getStatistics.success]: () => {
                handleGetStatistics(data);
            },

            //thread actions
            [SOCKET_ACTIONS.createThread.success]: () => {
                handleCreateThread(data, messagesArray);
            },
            [SOCKET_ACTIONS.getThreadMessages.success]: () => {
                handleThreadMessageList({
                    messages: data?.messages,
                    thread_id: data?.thread_id,
                    prev: data?.prev,
                    privateKey,
                    handleMediaMessages,
                });
            },
            [SOCKET_ACTIONS.getThreadDetails.success]: () => {
                handleThreadDeltails(data);
            },
            [SOCKET_ACTIONS.getThreadMembers.success]: () => {
                handleActiveThreadMembers(data);
            },
            // [SOCKET_ACTIONS.createThreadMessages.success]: () => {
            // handleaddThreadMessage(data);
            // },
            [SOCKET_ACTIONS.threadMessageUpdate.action]: () => {
                handleThreadMessageUpdate({
                    data: data,
                    privateKey: privateKey,
                    handleMediaMessages,
                    messagesArray: messagesArray,
                });
            },
            [SOCKET_ACTIONS.threadMessage.action]: () => {
                handleaddThreadMessage({
                    data: data,
                    privateKey: privateKey,
                    handleMediaMessages,
                    messagesArray: messagesArray,
                    activeThreadMembers: activeThreadMembers,
                });
            },
            [SOCKET_ACTIONS.joinThread.success]: () => {
                handleJoinThread({
                    data: data,
                    activeThreadMembers: activeThreadMembers,
                });
            },
            [SOCKET_ACTIONS.leaveThread.success]: () => {
                handleLeaveThread({
                    data: data,
                    activeThreadMembers: activeThreadMembers,
                });
            },
            // [SOCKET_ACTIONS.deleteThread.success]: () => {
            // handleDeleteThread(data);
            // },
            [SOCKET_ACTIONS.threadDeleted.action]: () => {
                handleDeleteThread(data);
            },
            [SOCKET_ACTIONS.threadLockBroadcast.action]: () => {
                handleLockThread(data);
            },
            [SOCKET_ACTIONS.threadMessageDelete.action]: () => {
                handleDeleteThreadMessage(data);
            },
            [SOCKET_ACTIONS.threadReactionUpdate.action]: () => {
                handleThreadReactionUpdate(data);
            },
            [SOCKET_ACTIONS.editThreadMessage.action]: () => {
                editMessageHandler(data, privateKey);
            },
            [SOCKET_ACTIONS.getThreads.action]: () => {
                handleGetThreasList({
                    data: data,
                    privateKey: privateKey,
                    handleMediaMessages,
                });
            },

            ['update_role_permission']: () => {
                handleFetchData(activeServer);
            },
            ['pong']: () => {
                updatePingHandler();
            },
            //interserver actions
            [SOCKET_ACTIONS.AddChannelToOtherServer.success]: () => {
                handleAddChannelToOtherServer(data);
            },
            [SOCKET_ACTIONS.getInterServerList.success]: () => {
                handleOtherServerChannels(data);
            },
            [SOCKET_ACTIONS.deleteInterSubserverChannel.success]: () => {
                handleDeleteIneterServer(data);
            },
            [SOCKET_ACTIONS.AddChannelToOtherServer.error]: () => {
                handleInterServerError(data);
            },

            ['version']: () => {
                if (activeServer) {
                    dispatch(
                        updateServer({
                            id: activeServer,
                            version: data?.version,
                        }),
                    );
                }
            },
        };

        const actionHandler = actionHandlers[action];

        if (actionHandler) {
            actionHandler();
        }
    };

    const searchWSHandler = async (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        const action = data.event;
        const actionHandlers = {
            ['info']: () => {
                dispatch(
                    indexedDBSearchActions.setDesktopMessage(data.message),
                );
            },
            ['message']: () => {
                if (data.message === 'RSA key set successfully.') {
                    sendDesktopAuth(userSelector);
                }
                if (data.message === 'Process finish.') {
                    dispatch(updateIsFetchSearchHeader(false));
                }
            },
        };

        const actionHandler = actionHandlers[action];
        if (actionHandler) {
            actionHandler();
        }
    };

    const searchFindWSHandler = async (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        const action = data.event;
        const actionHandlers = {
            ['search_results']: () => {
                let parsedMessages = [];
                data.data.map(msg => {
                    try {
                        const text = JSON.parse(msg?.text);
                        parsedMessages.push({
                            ...msg,
                            text:
                                text.content.trim() === ''
                                    ? '-Reply-'
                                    : text.content.trim(),
                        });
                    } catch (err) {
                        const text = msg?.text;
                        parsedMessages.push({
                            ...msg,
                            text: text,
                        });
                    }
                });
                dispatch(
                    backendSearchActions.setBackEndSearchResult(parsedMessages),
                );
            },
        };

        const actionHandler = actionHandlers[action];
        if (actionHandler) {
            actionHandler();
        }
    };

    useEffect(() => {
        setEventHandler(eventHandler);
    }, [memoServerData]);

    useEffect(() => {
        setSearchSocketHandler(searchWSHandler);
    }, []);

    useEffect(() => {
        setSearchFindEventHandler(searchFindWSHandler);
    }, []);

    return {
        sendSearchKeyword,
        sendWsMessageRange,
    };
};

export default useChatHelper;
