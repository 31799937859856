import AppTable from '@/UI/AppTable/AppTable';
import { formatCreatedDate } from '@/Utils/utils';
import { DEFAULT_IMAGES } from '@/constants';
import add from '@/assets/icons/add-outline.svg';
import arrowDown from '@/assets/icons/arrowDownPurple.svg';
import { IMember } from '@/types/ImemberData';

import classes from './TableOrganizationMember.module.css';
import { t } from '@lingui/macro';

type Props = {};

const TableOrganizationMember = (props: any) => {
    const data: IMember[] = props?.data ?? [];
    const { onSort, onAddUser } = props;

    const _renderHeaderCell = item => (
        <div
            onClick={() => (!item?.disableSortFilter ? onSort(item.key) : {})}
            className={classes['header-cell']}>
            <span>{item.label}</span>
            {!item?.disableSortFilter && <img alt="" src={arrowDown} />}
        </div>
    );

    const _renderUserColumn = item => (
        <div className={classes['user-column']}>
            <img
                alt=""
                src={item?.profile_picture ?? DEFAULT_IMAGES.PROFILE_IMAGE}
            />
            <div className={classes['user-column-text']}>
                <span className={classes['user-name']}>{item.name}</span>
                <span className={classes['user-sub']}>{item.username}</span>
            </div>
        </div>
    );

    const _renderEmail = item => (
        <span className={classes['email']}>{item}</span>
    );

    const _renderCreatedAt = item => (
        <span className={classes['email']}>{formatCreatedDate(item)}</span>
    );

    const _renderGroupColumn = item => {
        return (
            <div className={classes['groups-wrapper']}>
                {item.organizations?.map((item, index) => {
                    return (
                        <div key={index} className={classes.badgeClass}>
                            <div className={classes['badge']} />
                            {item.organization_name}
                        </div>
                    );
                })}
            </div>
        );
    };

    const actionIcons = [
        {
            icon: add,
            onClick: item => {
                onAddUser(item);
            },
        },
    ];

    const _renderHoverIcons = data => {
        return (
            <div className={classes['hover-icon-container']}>
                {actionIcons.map((item, index) => (
                    <div
                        onClick={() => item.onClick(data)}
                        className={
                            classes['hover-icon-item'] +
                            ' ' +
                            (index === 0 && classes['block'])
                        }>
                        <img alt="" src={item.icon} />
                    </div>
                ))}
            </div>
        );
    };

    const columns = [
        {
            key: 'name',
            label: t`User`,
            render: item => _renderUserColumn(item),
        },

        {
            key: 'email',
            label: t`Email`,
            render: item => _renderEmail(item.email),
            disableSortFilter: true,
        },
        {
            key: 'groups',
            label: t`Organizations`,
            render: item => _renderGroupColumn(item),
            disableSortFilter: true,
        },
        {
            key: 'created_at',
            label: t`Join Date`,
            render: item => _renderCreatedAt(item?.created_at),
        },
    ];

    return (
        <AppTable
            columns={columns}
            data={data}
            className={classes['table']}
            header={item => _renderHeaderCell(item)}
            hoverIcons={item => _renderHoverIcons(item)}
        />
    );
};

export default TableOrganizationMember;
