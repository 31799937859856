import fetchData from '@/Utils/fetchData';
import { REQUEST_TYPE } from '@/constants/requstTypes';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const getNoteCategories = async ({ subserverId }) => {
    const url = `${baseUrl}/api/v1/user/note/section/${subserverId}`;
    const { data, error, header } = await fetchData(REQUEST_TYPE.GET, url);

    return { data, error, header };
};

export const createNoteCategory = async ({ subserverId, params }) => {
    const url = `${baseUrl}/api/v1/user/note/section/${subserverId}`;

    const { data, error, header } = await fetchData(
        REQUEST_TYPE.POST,
        url,
        params,
    );
    return { data, error, header };
};
export const deleteNoteCategoryApi = async ({ subserverId, catId, body }) => {
    const url = `${baseUrl}/api/v1/user/note/section/${subserverId}/${catId}`;

    const { data } = await fetchData(REQUEST_TYPE.DELETE, url, body);
    return { data };
};

export const editNoteCategory = async ({ subserverId, categoryId, params }) => {
    const url = `${baseUrl}/api/v1/user/note/section/${subserverId}/${categoryId}`;

    const { data, error, header } = await fetchData(
        REQUEST_TYPE.PATCH,
        url,
        params,
    );
    return { data, error, header };
};
