export function splitObjectValues(obj) {
    const obj1 = {};
    const obj2 = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            const halfIndex = Math.ceil(value.length / 2);
            obj1[key] = value.substring(0, halfIndex);
            obj2[key] = value.substring(halfIndex);
        }
    }

    return [obj1, obj2];
}

export function joinObjects(obj1, obj2) {
    const joinedObj = { ...obj1 };

    for (const key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (
                typeof obj2[key] === 'string' &&
                typeof obj1[key] === 'string'
            ) {
                joinedObj[key] = obj1[key] + obj2[key];
            } else {
                joinedObj[key] = obj2[key];
            }
        }
    }

    return joinedObj;
}

export function getObjectExcludingKeys(obj, keysToExclude) {
    const result = {};
    for (const key in obj) {
        if (!keysToExclude.includes(key)) {
            result[key] = obj[key];
        }
    }
    return result;
}

export function sortObject(obj) {
    if (typeof obj !== 'object' || Array.isArray(obj)) {
        return obj;
    }

    const sortedObj = {};
    const keys = Object.keys(obj).sort();

    for (const key of keys) {
        sortedObj[key] = sortObject(obj[key]);
    }

    return sortedObj;
}

export function filterKeysinObject(obj, keysToKeep) {
    const newObj = {};

    for (const key of keysToKeep) {
        if (obj.hasOwnProperty(key)) {
            newObj[key] = obj[key];
        }
    }

    return newObj;
}

export function arraysAreEqual(arr1, arr2) {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Iterate over each element in the first array
    for (let i = 0; i < arr1.length; i++) {
        const obj1 = arr1[i];
        const obj2 = arr2[i];

        // Compare the objects in the two arrays
        if (!objectsAreEqual(obj1, obj2)) {
            return false;
        }
    }

    // If we've reached this point, the arrays are equal
    return true;
}

export function arraysAreEqualOptimized(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const set = new Set();

    for (const obj of arr1) {
        set.add(JSON.stringify(obj)); // Convert object to a JSON string for comparison
    }

    for (const obj of arr2) {
        if (!set.has(JSON.stringify(obj))) {
            return false;
        }
    }

    return true;
}

export function objectsAreEqual(obj1, obj2) {
    // Convert the objects to JSON and compare the strings
    const json1 = JSON.stringify(obj1);
    const json2 = JSON.stringify(obj2);
    return json1 === json2;
}

export function getTrueKeysObjects(jsonObj: any) {
    const trueKeys: string[] = [];
    for (const key in jsonObj) {
        if (jsonObj.hasOwnProperty(key) && jsonObj[key] === true) {
            trueKeys.push(key);
        }
    }
    return trueKeys;
}
