import { Trans, msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import DropDown from '@/UI/drop-down/DropDown';
import { ReactComponent as AddIcon } from '@/assets/icons/add-square-2.svg';
import { ReactComponent as FiltersIcon } from '@/assets/icons/filters-2.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/search-close.svg';
import { NotesContext } from '@/context/notes/NotesContext';

import DeleteNotePopover from '../delete-note/DeleteNotePopover';
import NoteSearch from '../note-details/NoteSearch';

import classes from './NotesList.module.css';

interface IProps {}

const NotesListHeader: React.FC<IProps> = () => {
    const notesContext = useContext(NotesContext);
    const {
        selectedNotes,
        activeNote,
        setIsAddNewNote,
        isFilterModalOpen,
        setFilterType,
        filterType,
        toggleFiltersModal,
        isFullMode,
    } = notesContext;

    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const changeStatusHandler = filter => {
        setFilterType(filter.toLowerCase().trim());
        toggleFiltersModal();
    };

    const startNewNote = () => {
        setIsAddNewNote(true);
    };
    const { _ } = useLingui();

    const sortOptions = [_(msg`Date`), _(msg`Name`), _(msg`Fav`)];

    return (
        <div
            className={`${classes['header']}`}
            style={{ display: isFullMode ? 'none' : 'flex' }}>
            <p>
                {filterType
                    ? `${t`Filtred by`}: ${filterType}`
                    : t`Filter Notes`}
            </p>
            <span className={classes['icons-wrap']}>
                {(activeNote || selectedNotes.length > 0) && (
                    <DeleteNotePopover />
                )}
                <AddIcon
                    onClick={startNewNote}
                    height={20}
                    width={20}
                    className={`cursor-pointer ${classes['add-note']}`}
                />
                <FiltersIcon
                    onClick={() => toggleFiltersModal()}
                    className="cursor-pointer"
                    width={18}
                    height={18}
                />
            </span>
            {isMobile && <NoteSearch />}

            {isFilterModalOpen && (
                <DropDown
                    header={
                        <>
                            <span>
                                <Trans>Sort By</Trans>
                            </span>
                            <CloseIcon
                                onClick={() => toggleFiltersModal(false)}
                            />
                        </>
                    }
                    className={classes['dropdown']}
                    selectOptions={sortOptions}
                    onClick={changeStatusHandler}
                    onClose={() => toggleFiltersModal(false)}
                />
            )}
        </div>
    );
};
export default NotesListHeader;
