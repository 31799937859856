import { motion } from 'framer-motion';
import { memo, useContext } from 'react';

import { truncateText } from '@/Utils/textUtils';
import { ReactComponent as EditIcon } from '@/assets/icons/edit-pencil.svg';
import { ReactComponent as FolderIcon } from '@/assets/icons/folder.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/trash-red.svg';
import { NotesContext } from '@/context/notes/NotesContext';

import classes from './NotesSidebar.module.css';

interface IProps {
    title: string;
    icon?: string;
    index: number;
    id: string;
}

const SidebarListItem: React.FC<IProps> = props => {
    const { title, icon, index, id } = props;

    const notesContext = useContext(NotesContext);
    const {
        setIsEditCategory,
        setActiveCategory,
        setIsDeleteCategory,
        activeCategory,
    } = notesContext;

    const chooseCateg = () => {
        setActiveCategory(id);
    };

    const editCategory = (ev: React.MouseEvent<SVGElement>) => {
        ev.stopPropagation();
        setIsEditCategory(id);
    };

    const deleteCategory = (ev: React.MouseEvent<SVGElement>) => {
        ev.stopPropagation();
        setIsDeleteCategory({ status: true, id: id });
    };

    return (
        <motion.li
            initial={{ opacity: 0, x: 0 }}
            exit={{ opacity: 0.2, x: 5 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.07 * index }}
            onClick={chooseCateg}
            className={`${
                activeCategory && id === activeCategory ? classes['active'] : ''
            } ${classes['list-item']}`}>
            {icon ? <span>{icon}</span> : <FolderIcon />}
            {truncateText(title, 20)}
            {id !== 'All' && (
                <span className={classes['settings-icons-wrap']}>
                    <EditIcon
                        className={classes['edit-icon']}
                        onClick={editCategory}
                    />
                    <TrashIcon
                        className={classes['trash-icon']}
                        height={15}
                        onClick={deleteCategory}
                    />
                </span>
            )}
        </motion.li>
    );
};
export default memo(SidebarListItem);
