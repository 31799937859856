import { getChannelMemberList } from '@/Utils/memberUtils';

import CustomErrorBoundary from '../CustomErrorBoundary/CustomErrorBoundary';

import ChannelMemberManagement from './ChannelMembers/ChannelMemberManagement';
import { useEditChannelContext } from './EditChannelContext';

const RenderMembers: React.FC<{}> = ({}) => {
    const { channel } = useEditChannelContext();

    const members = getChannelMemberList(channel._id);

    return (
        <CustomErrorBoundary>
            <ChannelMemberManagement members={members} channel={channel} />
        </CustomErrorBoundary>
    );
};
export default RenderMembers;
