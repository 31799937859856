import { FC, memo, useCallback, useEffect, useState } from 'react';

import { checkfileAvailability } from '@/Utils/checkFileAvailability';
import { getFileById } from '@/Utils/mediaFilesUtils';

import ImageMedia from '../ImageMedia';
import LoadingMedia from '../LoadingMedia';

import ReplyMediaOther from './ReplyMediaOther';
import classes from './ReplyMedia.module.css';

type Props = { file };
const ReplyMediaSort: FC<Props> = props => {
    const { file } = props;

    const [fileExists, setFileExists] = useState<null | boolean>(null);
    const [updatedFile, setUpdatedFile] = useState(file);

    const updateFileUrl = useCallback(async () => {
        let mediaFile: any = {};
        try {
            mediaFile = await getFileById(file?.fileKey);
            const fileBlob = new Blob([mediaFile.data], {
                type: mediaFile.type,
            });
            mediaFile.src = URL.createObjectURL(fileBlob);
            setUpdatedFile(mediaFile);
        } catch (error) {
            console.error('Error getting file from DB');
        }
    }, [file]);

    async function getIfChecked() {
        if (!fileExists) {
            const isChecked = await checkfileAvailability(file);
            setFileExists(isChecked);
        }
    }

    useEffect(() => {
        if (!file) {
            return;
        }
        getIfChecked();
    }, [file, fileExists]);

    useEffect(() => {
        if (fileExists && file && !file?.src) {
            updateFileUrl();
        }
    }, [fileExists, file, updateFileUrl]);

    return (
        <article className={classes['article']}>
            {updatedFile?.type === undefined ? (
                <LoadingMedia />
            ) : !updatedFile?.data || !fileExists ? (
                <ReplyMediaOther
                    onClick={e => e.stopPropagation()}
                    type={file?.type}
                    name={file?.name ?? ''}
                    size={file?.size ?? 0}
                />
            ) : updatedFile?.src && updatedFile?.type ? (
                <>
                    {updatedFile.type?.startsWith('image/') && !updatedFile.type?.includes('image/svg') ? (
                        <ImageMedia
                            name={updatedFile?.name ?? ''}
                            index={0}
                            noCopy={true}
                            src={updatedFile.src}
                        />
                    ) : (
                        <ReplyMediaOther
                            onClick={e => e.stopPropagation()}
                            type={file?.type}
                            name={file?.name ?? ''}
                            size={file?.size ?? 0}
                        />
                    )}
                </>
            ) : (
                <ReplyMediaOther
                    onClick={e => e.stopPropagation()}
                    type={file?.type}
                    name={file?.name ?? ''}
                    size={file?.size ?? 0}
                />
            )}
        </article>
    );
};
export default memo(ReplyMediaSort);
