import { Trans, t } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import TabsManagement from '@/UI/TabsManagement/TabsManagement';
import BackdropModal from '@/UI/backdrop-modal/BackdropModal';
import InviteFriends from '@/UI/invite-modal/InviteFriends';
import { removeUserFromChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import { sortByKey } from '@/Utils/sortByKey';
import addMembersSVG from '@/assets/icons/add-user-outline.svg';
import CustomErrorBoundary from '@/components/CustomErrorBoundary/CustomErrorBoundary';
import MemberProfileEdit from '@/components/ManagementModal/member-management/member-profile-edit/MemberProfileEdit';
import MemberRoleEdit from '@/components/ManagementModal/member-management/member-role-edit/MemberRoleEdit';
import TableMemberManagement from '@/components/ManagementModal/member-management/table/TableMemberManagement';
import { MANAGEMENT_TYPES } from '@/constants/enum';
import { useCustomSweetAlert } from '@/hooks';
import useMemberTable from '@/hooks/useMemberTable';
import { useAppSelector } from '@/hooks/useRedux';
import { rolesListSelector } from '@/store/server-data-reducer/selectors-server-data';
import { IMemberData } from '@/types';

import classes from './ChannelMemberManagement.module.css';

type Props = {
    members?: IMemberData[];
    channel: any;
};

const MemberManagement = (props: Props) => {
    const rolesList = useAppSelector(rolesListSelector);
    const sortByPriority = sortByKey(rolesList, 'priority');

    const tabs = [
        {
            id: 0,
            value: 'all-member',
            label: t`All`,
        },
        {
            id: 2,
            value: 'online-member',
            label: t`Online`,
        },
        {
            id: 3,
            value: 'offline-member',
            label: t`Offline`,
        },
        {
            id: 4,
            value: 'banned-member',
            label: t`Banned`,
        },
    ];

    const {
        handleSearchInput,
        searchText,
        onChangeCheckbox,
        isOpencheckboxModal,
        setIsOpenCheckboxModal,
        allClearCheckbox,
        allSelectCheckbox,
        handleMemberServerBlockUpdate,
        handleMemberServerUnblockUpdate,
        handleSortData,
        isRoleSelected,
        filteredMemberData,
        onChangeTab,
    } = useMemberTable({
        tabData: tabs,
        checkboxData: sortByPriority,
        mainMemberData: props?.members,
    });

    const [isEditModal, setIsEditModal] = useState(false);
    const [isRolesModal, setIsRolesModal] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState(false);

    const tabsManagementProps = {
        handleSearchInput,
        tabs,
        searchText,
        onChangeTab,
        isOpencheckboxModal,
        setIsOpenCheckboxModal,
        checkboxFilterOptionsData: sortByPriority,
        allSelectCheckbox,
        allClearCheckbox,
        isRoleSelected,
        onChangeCheckbox,
    };

    const modalProps = {
        isEditModal,
        setIsEditModal,
        isRolesModal,
        setIsRolesModal,
        selectedUserData,
        setSelectedUserData,
        handleMemberServerBlockUpdate,
        handleMemberServerUnblockUpdate,
    };
    const [isInvite, setIsInvite] = useState(false);
    const { areYouSureModal } = useCustomSweetAlert();

    const fixedProsp = {
        height: '100%',
        width: '400px',
        backgroundColor: 'var(--left-sidebar)',
        color: 'white',
        position: 'absolute',
        top: '0px',
        right: '0px',
        zIndex: 999,
        borderRadius: '12px 0px 0px 12px',
        margin: '0px',
    };

    return (
        <CustomErrorBoundary>
            <div className={classes['container']}>
                <div className={classes['header']}>
                    <h2 style={{ margin: 0 }}>
                        <Trans>Channel Member Managment</Trans>
                    </h2>
                    <TabsManagement {...tabsManagementProps}>
                        <img
                            alt={t`add`}
                            src={addMembersSVG}
                            onClick={event => {
                                event.stopPropagation();
                                setIsInvite(true);
                            }}
                        />
                    </TabsManagement>
                </div>
                <div
                    style={{
                        height: '100%',
                        overflow: 'hidden',
                    }}>
                    <TableMemberManagement
                        onSort={handleSortData}
                        data={filteredMemberData}
                        modalProps={modalProps}
                        managementType={MANAGEMENT_TYPES.MEMBERMANAGEMENT}
                        onRemoveChannel={e => {
                            areYouSureModal(null, '', true, () =>
                                removeUserFromChannel(
                                    [e.id],
                                    props.channel._id,
                                ),
                            );
                        }}
                    />
                </div>
                <AnimatePresence>
                    {isEditModal && (
                        <motion.div
                            transition={{ duration: 0.2 }}
                            initial={{
                                x: '100%',
                                ...fixedProsp,
                            }}
                            animate={{ x: 0 }}
                            exit={{ x: '100%' }}>
                            <MemberProfileEdit modalProps={modalProps} />
                        </motion.div>
                    )}
                    {isRolesModal && (
                        <motion.div
                            transition={{ duration: 0.2 }}
                            initial={{
                                x: '100%',
                                ...fixedProsp,
                            }}
                            animate={{ x: 0 }}
                            exit={{ x: '100%' }}>
                            <MemberRoleEdit modalProps={modalProps} />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            {isInvite && (
                <>
                    <BackdropModal
                        onHide={() => setIsInvite(false)}
                        className={classes.backdrop}
                    />

                    <InviteFriends
                        onClose={() => setIsInvite(false)}
                        serverName={''}
                        channelName={props.channel.name}
                        channelId={props.channel._id}
                        channel={props.channel}
                    />
                </>
            )}
        </CustomErrorBoundary>
    );
};

export default MemberManagement;
