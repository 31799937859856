import { ReactComponent as EditIcon } from '@/assets/icons/edit-note.svg';

import classes from './EditNotes.module.css';

interface IProps {
    onEdit: () => void;
}
const EditNotes: React.FC<IProps> = props => {
    const { onEdit } = props;

    return (
        <div className={`${classes['wrap']}`} onClick={onEdit}>
            <EditIcon height={21} width={18} className={classes['icon']} />
        </div>
    );
};
export default EditNotes;
