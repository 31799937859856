import { useEffect, useState } from 'react';

import { DisabledInput } from '@/UI/disabledInput/DisabledInput';
import { getServer, uploadFiles } from '@/Utils/serverMediaHandler.js';
import { sendThreadMessage } from '@/Utils/socket-actions/ThreadActions/threadActions';
import { INPUT_TYPES } from '@/constants/enum';
import { useAppSelector } from '@/hooks/useRedux';
import { messageDataSelector } from '@/store/messages-reducer/selectors-messages';
import { activeChannelIdSelector } from '@/store/server-data-reducer/selectors-server-data';
import { IMessageData } from '@/types';

import NewMessageForm from '../messages/new-message/NewMessageForm';

import classes from './MessageThreadChat.module.css';
import ThreadChatHeader from './thread-chat-header/ThreadChatHeader';
import ThreadsList from './threads-list/ThreadsList';

const MessageThreadChat: React.FC<{ handleNewChat: any }> = ({
    handleNewChat,
}) => {
    const serverLink = process.env.REACT_APP_GET_SERVER_LINK_URL;

    const [isReply, setIsReply] = useState<boolean>(false);
    const [replyData, setReplyData] = useState<any>({});

    const [uploadProgress, setUploadProgress] = useState(0);
    const [mediaUploaded, setMediaUploaded] = useState(false);

    const user = useAppSelector(state => state.userReducer.user);
    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );
    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const { lastActiveThread } = useAppSelector(state => state.threadsReducer);
    const selectedMessage = useAppSelector(state => {
        if (
            lastActiveThread?.channel_id &&
            lastActiveThread?.message_id &&
            state.messageReducer?.messagesList[lastActiveThread?.channel_id]
        ) {
            return state.messageReducer.messagesList[
                lastActiveThread.channel_id
            ][lastActiveThread.message_id];
        } else {
            return null;
        }
    });

    const messagesArray: IMessageData[] | any = useAppSelector(state =>
        messageDataSelector(state),
    );

    const filterSenderMessage = messagesArray?.find(
        item => item?.sender_message_id === lastActiveThread?.message_id,
    );

    const isMergedChannelOwnerSide = selectedMessage ? true : false;

    const handleUploadProgress = progress => {
        const roundedProgress = Math.round(progress);
        setUploadProgress(roundedProgress === 100 ? 0 : roundedProgress);
    };

    const sendMediaHandler = async args => {
        const subserver = activeServer;
        const filesArray = [];

        const submitAll = async mediaFile => {
            const { responseLink } = await getServer(mediaFile, serverLink);

            const { uploadedFileLink } = await uploadFiles(
                mediaFile.file,
                subserver,
                responseLink,
                user.token,
                activeChannelId,
                true,
                handleUploadProgress,
            );

            return { uploadedFileLink: uploadedFileLink.data };
        };

        const promises = args?.mediaFiles?.map(async mediaFile => {
            const { uploadedFileLink } = await submitAll(mediaFile);
            filesArray.push({
                ...uploadedFileLink,
                type: mediaFile.type,
                name: mediaFile.name,
                size: mediaFile.size,
            });
        });
        await Promise.all(promises);

        if (filesArray.length === args.mediaFiles.length) {
            const payload = {
                replyData: args?.replyData,
                message: args.textMessage ? args.textMessage : '',
                mediaFiles: filesArray,
            };

            sendThreadMessage(
                'media',
                payload,
                [],
                activeChannelId
                    ? activeChannelId
                    : lastActiveThread?.channel_id,
                lastActiveThread?.id,
                lastActiveThread?.message_id,
                selectedMessage?.is_thread_member ? true : false,
                lastActiveThread?.channel_id,
                lastActiveThread?.subserver_id,
                isMergedChannelOwnerSide,
                selectedMessage,
                filterSenderMessage,
            );
            setMediaUploaded(true);
        } else {
            alert('One or more media files could not be uploaded.');
        }
    };

    const sendMessageHandler = message => {
        if (message.type === 'media') {
            sendMediaHandler(message.message);
        } else if (message.type === 'reply') {
            sendThreadMessage(
                message.type,
                message,
                message.mention_id,
                activeChannelId
                    ? activeChannelId
                    : lastActiveThread?.channel_id,
                lastActiveThread?.id,
                lastActiveThread?.message_id,
                message.role_mentions,
                lastActiveThread?.channel_id,
                lastActiveThread?.subserver_id,
                isMergedChannelOwnerSide,
                selectedMessage,
                filterSenderMessage,
            );
        } else {
            sendThreadMessage(
                message.type,
                message,
                message.mention_id,
                activeChannelId
                    ? activeChannelId
                    : lastActiveThread?.channel_id,
                lastActiveThread?.id,
                lastActiveThread?.message_id,
                message.role_mentions,
                lastActiveThread?.channel_id,
                lastActiveThread?.subserver_id,
                isMergedChannelOwnerSide,
                selectedMessage,
                filterSenderMessage,
            );
        }
    };

    const ownerId = useAppSelector(state => state.threadsReducer.ownerId);
    const isLockThread = useAppSelector(
        state => state.threadsReducer.isLockThread,
    );
    const userId = useAppSelector(state => state.userReducer.user.id);

    const closeReplyHandler = () => {
        setIsReply(false);
        setReplyData({});
    };

    useEffect(() => {
        if (mediaUploaded) {
            setMediaUploaded(false);
        }
    }, [mediaUploaded]);

    const handleReplyMessage = data => {
        setIsReply(true);
        setReplyData(data);
    };

    return (
        <section className={classes['main-wrapper']}>
            <ThreadChatHeader />

            <div
                className={classes['thread-messages-list']}
                style={{ flex: 1, overflowY: 'auto' }}>
                <ThreadsList
                    onReplyMessage={handleReplyMessage}
                    handleNewChat={handleNewChat}
                />
            </div>
            {ownerId === userId || !isLockThread ? (
                <div className={classes['form-wrapper']}>
                    <NewMessageForm
                        mediaUploaded={mediaUploaded}
                        onSendMessage={sendMessageHandler}
                        replyTo={replyData}
                        isReply={isReply}
                        closeReply={closeReplyHandler}
                        activeChannelId={activeChannelId}
                        uploadProgress={uploadProgress}
                        inputType={INPUT_TYPES.THREADINPUT}
                    />
                </div>
            ) : (
                <DisabledInput placeholder={'This thread is locked by owner'} />
            )}
        </section>
    );
};
export default MessageThreadChat;
