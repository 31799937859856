import { FormEvent, useContext, useEffect, useRef } from 'react';

import { AppButton, CustomInput, ImgButton } from '@/UI';
import MediaAddButton from '@/UI/media-add-button/MediaAddButton';
import MediaInput from '@/UI/media-input/MediaInput';
import EmojiIcon from '@/assets/icons/emojiPicker.svg';

import classes from './AttachmentMenu.module.css';
import { MediaMenuContext } from './AttachmentMenuContext';
import { FormDataTypes, replyProps } from './AttachmentTypes';
import { t } from '@lingui/macro';

const AttachementMenuForm: React.FC<{
    reply: replyProps;
    pastedFiles: File[] | null;
    onSendMessage: (formData: any) => void;
}> = ({ reply, onSendMessage, pastedFiles }) => {
    const submitBtnRef = useRef(null);
    const inputRef = useRef(null);
    const mediaFileRef = useRef(null);
    const menuContext = useContext(MediaMenuContext);

    const {
        isBtnDisabled,
        msgInputValue,
        mediaInputValue,
        secondClicker,
        toggleLoading,
        toggleSecondClick,
        inputChangeHanlder,
        toggleEmojiBoardHandler,
        onInputFocus,
        mediaChangeHandler,
        removeHandler,
        clearModal,
    } = menuContext;

    useEffect(() => {
        const sendMediaListener = (event: KeyboardEvent) => {
            if (
                event.key === 'Enter' &&
                !isBtnDisabled &&
                submitBtnRef?.current
            ) {
                submitBtnRef.current?.click();
            }
        };
        document.addEventListener('keydown', sendMediaListener);
        return () => document.removeEventListener('keydown', sendMediaListener);
    }, [isBtnDisabled]);

    const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        toggleLoading(true);

        const msgValue = msgInputValue;
        let formData: FormDataTypes = {
            textMessage: msgValue,
            mediaFiles: mediaInputValue,
        };

        if (reply) {
            formData = {
                ...formData,
                replyData: {
                    original_message_id: reply.id,
                    original_user_id: reply.user_id,
                    original_message: reply.messageText,
                },
            };
        }
        onSendMessage(formData);
        clearModal();
    };
    return (
        <form onSubmit={formSubmitHandler}>
            <MediaInput
                clicker={secondClicker}
                onChange={mediaChangeHandler}
                ref={mediaFileRef}
                multiple
                passDefaultFiles={pastedFiles ? pastedFiles : null}
                onRemoveMedia={removeHandler}
            />
            <MediaAddButton onClick={() => toggleSecondClick(true)} />
            <div className={classes['relative']}>
                <ImgButton
                    className={classes['emoji-btn']}
                    type="button"
                    onClick={toggleEmojiBoardHandler}
                    icon={EmojiIcon}
                    disabled={false}
                />
                <CustomInput
                    autoFocus
                    value={msgInputValue}
                    ref={inputRef}
                    onFocus={onInputFocus}
                    placeholder={t`Description`}
                    onChange={inputChangeHanlder}
                    className={classes['NewMessageInput']}
                />
                <AppButton
                    ref={submitBtnRef}
                    type="submit"
                    disabled={isBtnDisabled}
                    classStyles={classes.sendBTN}
                    title={t`Send`}
                    disable={isBtnDisabled}
                />
            </div>
        </form>
    );
};
export default AttachementMenuForm;
