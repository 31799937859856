// Custom Hook: useLogoutHandler.js
import { useNavigate } from 'react-router-dom';

import { clearCookie } from '@/Utils/utils';
import { SESSION_STORAGE } from '@/constants/enum';
import { useAppDispatch } from '@/hooks/useRedux';
import { ROOT_PATH } from '@/routes/router.types';
import {
    resettableReduce,
} from '@/store/server-data-reducer/server-data-reducer';

import { clearDB } from '../Utils/mediaFilesUtils';

function useLogoutHandler() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    async function logoutHandler(clearData: boolean = false) {
        localStorage.removeItem('persist:cache_messages');
        localStorage.removeItem('persist:user');
        localStorage.removeItem('persist:server');
        localStorage.removeItem('profileStatus');
        // localStorage.removeItem('lastSelectedServer');
        localStorage.clear();
        sessionStorage.clear();
        await clearDB();
        !clearData && navigate(ROOT_PATH.auth);
        clearCookie(SESSION_STORAGE.devicePassword);
        dispatch(resettableReduce('reset'));
        window.location.href = ROOT_PATH.auth; // Redirect
    }

    async function resetDataHandler() {
        sessionStorage.clear();
        window.location.reload();
    }

    return { logoutHandler, resetDataHandler };
}

export default useLogoutHandler;
