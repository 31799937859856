import { Trans, t } from '@lingui/macro';
import { FC, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-activity';

import { Switch } from '@/UI';
import { removeUserFromChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import { sortByKey } from '@/Utils/sortByKey';
import deleteIcon from '@/assets/icons/trashMessage.svg';
import warningSvg from '@/assets/icons/warning.svg';
import { useCustomSweetAlert } from '@/hooks';
import { useAppSelector } from '@/hooks/useRedux';
import {
    activeChannelIdSelector,
    channelMembersSelector,
    userPermissionSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import { useEditChannelContext } from '../EditChannelContext';
import Title from '../Title';

import classes from './ChannelUserPermissinons.module.css';
import ChannnelUserPermissinonsMemberList from './Members';
import ChannnelUserPermissinonsRoleList from './Roles';
import useChannelUserPermissions from './useChannelUserPermissions';

const ChannelUserPermissinons: FC = () => {
    const membersContainerRef = useRef();

    const { channel } = useEditChannelContext();

    const [selectedUser, setSelectedUser] = useState({});
    const [selectedEveryone, setSelectedEveryone] = useState(true);
    const [selectedRole, setSelectedRole] = useState({});

    const [searchText, setSearchText] = useState('');
    const [isSelectedRole, setIsSelectedRole] = useState(false);
    const [loading, setLoading] = useState(false);

    const everyonePermissions = useAppSelector(
        state => state.utilReducer.everyoneChannelPermission,
    );

    const rolesList = useAppSelector(
        state =>
            state.serverDataReducer.rolesList[
                state.serverDataReducer.activeServer
            ],
    );
    const sortByPriority = sortByKey(rolesList, 'priority')?.filter(
        filterItem => filterItem?.slug !== 'admin',
    );

    const {
        handleGetChannelUsersPermissions,
        handleCreateChannelUsersPermissions,
    } = useChannelUserPermissions();

    // get the members ids only inside the channel
    const channelMembers = useAppSelector(channelMembersSelector);
    const userId = useAppSelector(state => state.userReducer.user.id);

    const { areYouSureModal } = useCustomSweetAlert();

    // members full data from subserver
    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );

    const activeChannelId = useAppSelector(activeChannelIdSelector);

    const mePermissions = useAppSelector(userPermissionSelector);
    const userDeletePermission = mePermissions['user.delete'];

    // get only the full details of members inside channel only
    const channelMembersList = [];
    channelMembers?.forEach(item => channelMembersList.push(memberList[item]));
    const members = channelMembersList;

    const [isRoleMembersZero, setIsRoleMembersZero] = useState(false);

    const selectedRoleInRedux = rolesList?.find(
        item => item?.id === selectedRole?.id,
    );

    useEffect(() => {
        setIsRoleMembersZero(
            selectedRoleInRedux?.channel_permissions?.[channel?._id]
                ?.role_members === 0,
        );
    }, [selectedRole, channel, sortByPriority]);

    const onClickUser = (user, ch) => {
        handleGetChannelUsersPermissions({
            user_id: user?.id,
            channel_id: ch?._id,
        });
        setSelectedEveryone(false);
        setSelectedUser(user);
    };

    const onClickEveryone = () => {
        setSelectedUser({});
        setSelectedEveryone(true);
        handleGetChannelUsersPermissions({
            channel_id: channel?._id,
            everyone: true,
        });
    };

    const onClickRole = (role, ch) => {
        setSelectedRole(role);
        handleGetChannelUsersPermissions({
            role_id: role?.id,
            channel_id: ch?._id,
        });
    };

    const onChangeSearchText = text => {
        setSearchText(text);
    };

    const handleChangeTab = value => {
        setSearchText('');
        if (membersContainerRef.current) {
            membersContainerRef.current.scrollTop = 0;
        }
        if (value === 'roles') {
            onClickRole(sortByPriority?.[0], channel);
            setSelectedUser({});
            setSelectedEveryone(false);
            setIsSelectedRole(true);
        } else if (value === 'members') {
            setSelectedRole({});
            onClickEveryone();
            setIsSelectedRole(false);
        }
    };

    const handleSwitchChange = (
        userId,
        roleId,
        key: string,
        checked: boolean,
    ) => {
        setLoading(true);
        handleCreateChannelUsersPermissions({
            user_id: userId,
            role_id: roleId,
            permissions: [{ key, value: checked }],
            is_role: isSelectedRole,
            everyone: selectedEveryone,
        });
        const second = selectedRole?.id ? 500 : 200;
        setTimeout(() => {
            setLoading(false);
        }, second);
    };

    const membersProps = {
        isSelectedRole,
        onClickEveryone,
        onClickUser,
        selectedEveryone,
        searchText,
        selectedUser,
        channel,
        members,
        setLoading,
        loading,
        userId,
    };

    const rolesProps = {
        onClickRole,
        searchText,
        channel,
        selectedRole,
        loading,
        setLoading,
        sortByPriority,
    };

    useEffect(() => {
        handleChangeTab('members');
    }, []);

    const handlRemoveUser = () => {
        areYouSureModal(
            null,
            t`you want to remove this user from channel?`,
            true,
            () => {
                removeUserFromChannel([selectedUser.id], activeChannelId);
                setSelectedUser({});
            },
        );
    };

    return (
        <div>
            <Title
                title={t`Channel User Permissions`}
                subtitle={t`You can change the permissions of channel members`}
            />

            <div className={classes['pvt-container']}>
                <div className={classes['pvt-text']}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'start',
                            width: '100%',
                            gap: 16,
                            pointerEvents: loading ? 'none' : 'auto',
                            userSelect: loading ? 'none' : 'auto',
                        }}>
                        <div
                            style={{
                                width: '50%',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    opacity: loading ? 0.2 : 1,
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 4,
                                    }}>
                                    <span
                                        className={`${classes['tab-item']} ${
                                            !isSelectedRole &&
                                            classes['selected']
                                        }`}
                                        onClick={() =>
                                            handleChangeTab('members')
                                        }>
                                        <Trans>Members</Trans>
                                    </span>
                                    <span className={`${classes['tab-item']}`}>
                                        /
                                    </span>
                                    <span
                                        onClick={() => handleChangeTab('roles')}
                                        className={`${classes['tab-item']} ${
                                            isSelectedRole &&
                                            classes['selected']
                                        }`}>
                                        <Trans>Roles</Trans>
                                    </span>
                                </div>
                                <input
                                    onChange={e =>
                                        onChangeSearchText(e.target.value)
                                    }
                                    type="search"
                                    value={searchText}
                                    className={classes['search-input']}
                                    placeholder={
                                        isSelectedRole
                                            ? t`Search Role`
                                            : t`Search Member`
                                    }
                                />
                            </div>
                            <div
                                ref={membersContainerRef}
                                className={classes['members-container']}>
                                {loading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: '92%',
                                            display: 'flex',
                                            alignItems: 'start',
                                            justifyContent: 'center',
                                        }}>
                                        <Spinner size={52} />
                                    </div>
                                )}
                                {isSelectedRole ? (
                                    <ChannnelUserPermissinonsRoleList
                                        {...rolesProps}
                                    />
                                ) : (
                                    <ChannnelUserPermissinonsMemberList
                                        {...membersProps}
                                    />
                                )}
                            </div>
                        </div>
                        {(selectedUser?.id ||
                            selectedEveryone ||
                            selectedRole?.id) && (
                            <div
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    opacity: loading ? 0.2 : 1,
                                }}>
                                <div
                                    className={
                                        classes['permissions-container']
                                    }>
                                    {isRoleMembersZero && (
                                        <div
                                            className={
                                                classes['warning-modal']
                                            }>      
                                            <img
                                                alt=""
                                                src={warningSvg}
                                                style={{ height: 36 }}
                                            />
                                            <span>
                                                <Trans>
                                                    No member has been added to
                                                    this role yet
                                                </Trans>
                                                !
                                            </span>
                                        </div>
                                    )}

                                    {Object?.entries(
                                        channel?.channelPermissions || {},
                                    )?.map(
                                        ([key, value]: [string, boolean]) => {
                                            let userSwitchValue = false;
                                            let roleSwitchValue = false;
                                            let everyoneSwitchValue = false;

                                            const user = members?.find(
                                                element =>
                                                    selectedUser?.id ===
                                                    element?.id,
                                            );

                                            const role = rolesList?.find(
                                                element =>
                                                    selectedRole?.id ===
                                                    element?.id,
                                            );

                                            if (
                                                selectedUser &&
                                                user?.channel_permissions &&
                                                user?.channel_permissions[
                                                    channel?._id
                                                ]
                                            ) {
                                                userSwitchValue =
                                                    user?.channel_permissions[
                                                        channel?._id
                                                    ][key];
                                            }

                                            if (
                                                selectedRole &&
                                                role?.channel_permissions &&
                                                role?.channel_permissions[
                                                    channel?._id
                                                ]
                                            ) {
                                                roleSwitchValue =
                                                    role?.channel_permissions[
                                                        channel?._id
                                                    ][key];
                                            }

                                            if (selectedEveryone) {
                                                everyoneSwitchValue =
                                                    everyonePermissions[key];
                                            }

                                            return (
                                                <div
                                                    style={{
                                                        opacity:
                                                            isRoleMembersZero
                                                                ? 0.2
                                                                : 1,
                                                        pointerEvents:
                                                            isRoleMembersZero
                                                                ? 'none'
                                                                : 'auto',
                                                        userSelect:
                                                            isRoleMembersZero
                                                                ? 'none'
                                                                : 'auto',
                                                    }}
                                                    key={key}
                                                    className={
                                                        classes[
                                                            'permission-item'
                                                        ]
                                                    }>
                                                    <div
                                                        className={
                                                            classes['pvt-text']
                                                        }>
                                                        <span
                                                            style={{
                                                                textTransform:
                                                                    'capitalize',
                                                            }}>
                                                            {key
                                                                .split('.')
                                                                .join(' ')}
                                                        </span>
                                                    </div>
                                                    <Switch
                                                        disabled={loading}
                                                        id={`switchChPermission-${key}`}
                                                        checked={
                                                            (selectedUser &&
                                                                userSwitchValue) ||
                                                            (selectedRole &&
                                                                roleSwitchValue) ||
                                                            (selectedEveryone &&
                                                                everyoneSwitchValue)
                                                        }
                                                        onChange={checked => {
                                                            handleSwitchChange(
                                                                selectedUser?.id,
                                                                selectedRole?.id,
                                                                key,
                                                                checked,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            );
                                        },
                                    )}
                                    {selectedUser?.id &&
                                        selectedUser?.id !== userId &&
                                        userDeletePermission && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    marginTop: 10,
                                                    alignItems: 'center',
                                                }}>
                                                <span>
                                                    <Trans>Remove User</Trans>
                                                </span>
                                                <img
                                                    alt=""
                                                    onClick={handlRemoveUser}
                                                    src={deleteIcon}
                                                    style={{
                                                        height: 25,
                                                        width: 30,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChannelUserPermissinons;
