import { motion } from 'framer-motion';
import { ChangeEvent } from 'react';

import classes from './LinearProgress.module.css';

type Props = {
    className?: string;
    value: number | string;
    id?: string;
    onNewPosition?: (event: number) => void;
};

const LinearProgress: React.FC<Props> = props => {
    const { value, className = '', id = '', onNewPosition = () => {} } = props;

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        onNewPosition(+event.target.value);
    };
    
    return (
        <motion.div
            initial={{ scaleX: 0 }}
            exit={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            id={id}
            className={`${className} ${classes['wrap']}`}>
            <input
                onChange={changeHandler}
                className={classes['input']}
                type="range"
                min={0}
                max={100}
            />
            <span
                style={{
                    transform: `scaleX(${+value < 100 ? +value / 100 : 1})`,
                }}
                className={classes['inner']}
            />
        </motion.div>
    );
};
export default LinearProgress;
