import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';
import MobileBackward from '@/components/mobile-backwoard/MobileBackward';
import { DEFAULT_IMAGES } from '@/constants';
import { useRoomTypeIcon } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { mobileViewActions } from '@/store/mobile-view-slice';
import {
    channelDataDetailsSelector,
    serverDetailSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import classes from '../ChatAreaSection.module.css';

import ChatAreaIcons from './ChatAreaIcons';

type IProps = {};

const ChatAreaHeader = props => {
    const { member } = props;
    const dispatch = useAppDispatch();

    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const [copiedText, setCopiedText] = useState(false);

    let channelDetails: any = useAppSelector(channelDataDetailsSelector);

    const { icon } = useRoomTypeIcon(
        channelDetails?.type,
        classes['header-img'],
        { fill: 'var(--text-3)', width: '32', height: '32' },
    );

    const handleCopyOnClick = () => {
        setCopiedText(true);
        navigator.clipboard.writeText(`${channelDetails?._id}` ?? '');

        setTimeout(() => {
            setCopiedText(false);
        }, 1000);
    };

    return (
        <div className={classes['room-header']}>
            {isMobile && (
                <MobileBackward
                    onClick={() =>
                        dispatch(mobileViewActions.changeActiveTab(0))
                    }
                    className={classes['backward']}
                />
            )}
            {props.type === 'NEW' && (
                <>
                    <img
                        alt=""
                        src={
                            props?.userData?.user_image
                                ? props.userData?.user_image
                                : DEFAULT_IMAGES.PROFILE_IMAGE
                        }
                        className={classes['header-img']}
                    />
                    <h1 className={classes['room-name-header']}>
                        {props.roomName}
                    </h1>
                </>
            )}
            {props.type === 'ACTIVE_CHANNEL' && (
                <>
                    {channelDetails?.privacy === 'channel' && (
                        <div className={classes['header-inner-1']}>
                            <div className={classes['header-inner-2']}>
                                {icon}
                                <div className={classes['room-name-wrapper']}>
                                    <h1 className={classes['room-name-header']}>
                                        {channelDetails?.name}
                                    </h1>
                                    <div
                                        onClick={() => handleCopyOnClick()}
                                        className={
                                            classes['room-id-header-container']
                                        }>
                                        <span>ID: {channelDetails?._id}</span>
                                        <CopyIcon height={12} width={12} />
                                        {copiedText && (
                                            <span
                                                className={
                                                    classes['copiedText']
                                                }>
                                                ✓
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <ChatAreaIcons
                                setIsSearchInput={props.setIsSearchInput}
                                setIsThreadList={props.setIsThreadList}
                                setIsEditChannel={props?.setIsEditChannel}
                            />
                        </div>
                    )}
                    {channelDetails?.privacy === 'direct' && (
                        <>
                            <img
                                className={classes['icon']}
                                src={
                                    member?.profile_picture ??
                                    DEFAULT_IMAGES.PROFILE_IMAGE
                                }
                                alt={member?.name}
                            />
                            <h1 className={classes['room-name-header']}>
                                {member?.name}
                            </h1>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default ChatAreaHeader;
