import { Trans } from '@lingui/macro';
import { useState } from 'react';

import { truncateText } from '@/Utils/textUtils';
import arrowDown from '@/assets/icons/arrowDownPurple.svg';
import arrowUp from '@/assets/icons/arrowUp.svg';

import classes from './TableStatistics.module.css';

type IProps = {
    item;
};
const ChannelsCol: React.FC<IProps> = ({ item }) => {
    const initialVisibleChannels = 2;

    const [visibleChannels, setVisibleChannels] = useState(
        initialVisibleChannels,
    );
    const isExpanded = visibleChannels === initialVisibleChannels;

    const toggleChannels = () => {
        // Toggle between initialVisibleChannels and all channels
        setVisibleChannels(prevVisibleChannels =>
            prevVisibleChannels === initialVisibleChannels
                ? item?.channels?.length
                : initialVisibleChannels,
        );
    };

    return (
        <div className={classes['main-container']}>
            {item?.channels?.slice(0, visibleChannels).map((channel, index) => (
                <div key={index} className={classes['reaction-container']}>
                    <p className={classes['text']}>
                        {truncateText(channel?.channel_name, 14)}
                    </p>
                    <p className={classes['text']}>{channel?.messages}</p>
                </div>
            ))}

            {item?.channels?.length > initialVisibleChannels && (
                <div
                    className={classes['reaction-container']}
                    onClick={() => toggleChannels()}>
                    <div style={{ display: 'flex', gap: 6 }}>
                        <p className={classes['text-colored']}>
                            {isExpanded &&
                                ` ${
                                    item?.channels?.length -
                                    initialVisibleChannels
                                }`}
                            &nbsp;
                            {isExpanded ? (
                                <Trans>More</Trans>
                            ) : (
                                <Trans>Close</Trans>
                            )}
                        </p>
                        <img
                            alt="arrow"
                            src={isExpanded ? arrowDown : arrowUp}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
export default ChannelsCol;
