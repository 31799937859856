import { Trans, t } from '@lingui/macro';
import { useEffect, useRef, useState } from 'react';
import { ViewportList } from 'react-viewport-list';

import { Badge, Card, Line } from '@/UI';
import { markAllReadNotifications } from '@/Utils/socket-actions/NotificationActions/notificationActions';
import { sendWebSocketData } from '@/Utils/webSocketUtils';
import closeIcon from '@/assets/icons/close-header.svg';
import notificationSVG from '@/assets/icons/notification.svg';
import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { updateFocusNotification } from '@/store/util-reducer';

import classes from './Notification.module.css';
import NotificationItem from './NotificationItem/NotificationItem';

type Props = { alignToParent: string };

const Notification = (props: Props) => {
    const listRef = useRef(null);
    const mainRef = useRef(null);

    const dispatch = useAppDispatch();

    const NOTIFICATION_FILTERS = {
        ALL: t`All`,
        UNREAD: t`Unread`,
    };
    const [style, setStyle] = useState({});
    const [active, setActive] = useState(NOTIFICATION_FILTERS.UNREAD);

    const metaData = useAppSelector(
        state =>
            state.serverDataReducer.notifications[
                state.serverDataReducer.activeServer
            ]?.metaData,
    );

    const data = useAppSelector(
        state =>
            state.serverDataReducer.notifications[
                state.serverDataReducer.activeServer
            ]?.data,
    );

    const unreadMessages = data?.filter(el => !el.users[0]?.is_read);

    const DATA_MAP = {
        [NOTIFICATION_FILTERS.ALL]: {
            name: t`All`,
            data: data,
        },
        [NOTIFICATION_FILTERS.UNREAD]: {
            name: t`Unread`,
            data: unreadMessages,
        },
    };

    const handleClickOutside = (event: any, ref) => {
        if (ref.current && !ref.current.contains(event.target)) {
            event.preventDefault();
            dispatch(updateFocusNotification(false));
        }
    };

    useEffect(() => {
        if (props.alignToParent) {
            const wind = window.innerWidth;
            const left = document
                .getElementById(props.alignToParent)
                .getBoundingClientRect().x;
            setStyle({ right: `${wind - left - 50}px` });
        }
    }, [props.alignToParent]);

    useEffect(() => {
        if (mainRef) {
            document.addEventListener('mousedown', event =>
                handleClickOutside(event, mainRef),
            );

            return () => {
                document.removeEventListener('mousedown', event =>
                    handleClickOutside(event, mainRef),
                );
            };
        }
    }, [mainRef, dispatch]);

    const _renderHeader = () => (
        <>
            <div className={classes['room-header']}>
                <img src={notificationSVG} alt={'room type'} />
                <h1 className={classes['room-name-header']}>
                    <Trans>Notification</Trans>
                </h1>
            </div>
            <Line className={classes['strong-hr']} />
        </>
    );

    const _renderFilter = (text: string, badge: string | number) => (
        <div
            onClick={() => setActive(text)}
            className={`${classes['filter-item']}  ${
                active === text && classes['active']
            }`}>
            <span className={classes['tab-par']}>{text}</span>
            {Number(badge) > 0 && (
                <Badge className={classes['badge']}>{badge}</Badge>
            )}
        </div>
    );

    const _renderFilterSection = () => (
        <div className={classes['filter-container']}>
            <div className={classes['filter-wrapper']}>
                {_renderFilter(t`Unread`, unreadMessages?.length)}
                {_renderFilter(t`All`, unreadMessages?.length)}
            </div>
            <div className={classes['markAllRead-container']}>
                <span
                    className={classes['markAllRead']}
                    onClick={() => markAllReadNotifications()}>
                    <Trans>Mark All as Read</Trans>
                </span>
            </div>
        </div>
    );

    useEffect(() => {
        const ulElement = listRef.current;
        const handleScrolling = () => {
            if (
                listRef &&
                Math.abs(Math.ceil(ulElement.scrollTop)) ===
                    Math.ceil(
                        ulElement.scrollHeight -
                            ulElement.getBoundingClientRect().height,
                    ) &&
                metaData?.current_page_number !== metaData?.total_pages
            ) {
                if (listRef.current) {
                    listRef.current.style.scrollBehavior = 'auto';
                }
                sendWebSocketData(
                    JSON.stringify({
                        action: SOCKET_ACTIONS_WITH_TYPES.getNotifications
                            .action,
                        data: {
                            page_number: metaData?.current_page_number + 1,
                            perPage: 20,
                        },
                    }),
                );
            }
        };

        ulElement?.addEventListener('scroll', handleScrolling);

        return () => {
            ulElement?.removeEventListener('scroll', handleScrolling);
        };
    }, [metaData, listRef]);

    const renderCondition =
        (Object.keys(style).length > 0 &&
            props?.alignToParent &&
            props?.alignToParent !== '') ||
        !props?.alignToParent;

    return (
        renderCondition && (
            <div
                id="notification-ref-cont"
                style={style}
                className={classes['server-padding-container']}
                ref={mainRef}>
                <section className={classes['chat-area-container']}>
                    <Card className={classes.chatCard}>
                        {_renderHeader()}
                        {_renderFilterSection()}

                        <div
                            ref={listRef}
                            className={classes['notification-list']}>
                            {DATA_MAP[active]?.data?.length > 0 ? (
                                <ViewportList
                                    items={DATA_MAP[active]?.data}
                                    initialAlignToTop={false}
                                    overscan={10}>
                                    {(item, index) => (
                                        <NotificationItem
                                            item={item}
                                            key={`${index}-${item._id}`}
                                        />
                                    )}
                                </ViewportList>
                            ) : (
                                <div
                                    style={{ margin: '16px 24px' }}
                                    className={classes['markAllRead']}>
                                    <Trans>No New Notifications</Trans>
                                </div>
                            )}
                        </div>
                    </Card>
                </section>
            </div>
        )
    );
};

export default Notification;
