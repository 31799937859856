import { Dispatch, SetStateAction, useState } from 'react';

import { Icon } from '@/UI';
import IndicatorIcon from '@/assets/icons/IndicatorIcon';
import { DEFAULT_IMAGES } from '@/constants';
import { IServerData } from '@/types';

import classes from './ServersListBar.module.css';

type IProps = {
    el: IServerData;
    chooseServerHandler: (serverId: string) => void;
    activeServer: string | null | undefined;
    isOpenTooltip: boolean;
    setIsOpenTooltip: Dispatch<SetStateAction<boolean>>;
};
const ServerListItem: React.FC<IProps> = props => {
    const {
        isOpenTooltip,
        setIsOpenTooltip,
        el,
        chooseServerHandler,
        activeServer,
    } = props;

    const [xAxis, setXAxis] = useState(0);
    const [yAxis, setYAxis] = useState(0);

    const handleOnMouseEnter = event => {
        const axis = event?.currentTarget?.getBoundingClientRect();
        setIsOpenTooltip(true);
        setXAxis(axis.x);
        setYAxis(axis.y);
    };

    const handleOnMouseLeave = () => {
        setIsOpenTooltip(false);
    };

    const IconStyle = {
        opacity: el?.privateKey ? 1 : 0.5,
    };

    const badgeStyle = {
        backgroundColor: 'var(--red)',
        border: '2px solid var(--main-bg-blue)',
        bottom: '0',
        right: '0',
        minWidth: '1.5rem',
        minHeight: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    const insideBadgeStyle = {
        backgroundColor: 'var(--green)',
        border: '2px solid var(--main-bg-blue)',
        bottom: '0',
        right: '0',
        minWidth: '1.5rem',
        minHeight: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    return (
        <li
            onMouseEnter={e => handleOnMouseEnter(e)}
            onMouseLeave={handleOnMouseLeave}
            className={classes['list-item']}
            key={`${el.id}`}
            id={el.id}>
            <Icon
                onClick={() => chooseServerHandler(el.id)}
                className={classes['server-badge']}
                imgClass={classes['badge-img']}
                imgStyle={IconStyle}
                imageURL={
                    el?.settings?.logo
                        ? el.settings.logo
                        : DEFAULT_IMAGES.SERVER_IMAGE
                }
                name={el.name}
                hasBadge={el?.total_count > 0 ? true : false}
                badgeStyle={
                    activeServer === el.id ? insideBadgeStyle : badgeStyle
                }>
                {el?.total_count > 99 ? '+99' : el.total_count}
            </Icon>
            {el.id === activeServer && (
                <IndicatorIcon
                    fill={'var(--main-bg-blue)'}
                    stroke={'var(--room-item)'}
                    className={classes.indicator}
                />
            )}
            {isOpenTooltip && (
                <div
                    style={{
                        top: yAxis + 16,
                        left: xAxis + 100,
                    }}
                    className={classes.tooltip}>
                    <div className={classes['dropdown-arrow']}></div>
                    {el.name}
                </div>
            )}
        </li>
    );
};
export default ServerListItem;
