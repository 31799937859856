import { t, Trans } from '@lingui/macro';
import { FC, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Badge from '@/UI/badge/Badge';
import Card from '@/UI/card/Card';
import MessageIcon from '@/assets/icons/MessageIcon';
import SearchIcon from '@/assets/icons/SearchIcon';
import editMessageIcon from '@/assets/icons/edit-message.svg';
import Search from '@/components/search/Search';
import SearchProvider from '@/components/search/SearchContext';
import SearchDesktop from '@/components/search/SearchDesktop';
import { useAppSelector } from '@/hooks/useRedux';
import { indexedDBSearchActions } from '@/store/search-reducer/indexed-db-search-reducer.js';
import { channelCountsListSelector } from '@/store/server-data-reducer/selectors-server-data';

import NotesIcons from './NotesIcons';
import classes from './ServerEditor.module.css';

type Props = {
    onUnreadChOnly: (unread: boolean) => void;
    onNewChat: () => void;
};

const ServerEditor: FC<Props> = props => {
    const { onUnreadChOnly, onNewChat } = props;
    const dispatch = useDispatch();

    const [isSearchInput, setIsSearchInput] = useState(false);
    const [unreadOnly, setUnreadOnly] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
    const isConnected = useAppSelector(state => state.socketConnectionReducer);
    const countList = useAppSelector(channelCountsListSelector);
    const unreadChannel = isConnected.mainWS
        ? countList?.reduce((acc, curr) => acc + (curr ?? 0), 0)
        : 0;

    const unreadHandler = () => {
        setUnreadOnly(prev => !prev);
    };

    const handleClose = () => {
        setIsSearchInput(false);
        dispatch(indexedDBSearchActions.setSearchError(''));
    };

    useEffect(() => {
        onUnreadChOnly(unreadOnly);
    }, [unreadOnly]);

    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'f' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                setIsSearchInput(prev => !prev);
            }
        };

        document.addEventListener('keydown', down);
        return () => document.removeEventListener('keydown', down);
    }, []);

    return (
        <Card className={classes['notify-card']}>
            <div
                className={classes['dummy-search']}
                onClick={() => {
                    setIsSearchInput(true);
                }}>
                <SearchIcon stroke="var(--white)" />
                <span>
                    <Trans>Search</Trans>...
                </span>
            </div>
            <div className={classes['d-flex']}>
                <div className={classes['unread-wrap']} onClick={unreadHandler}>
                    <div className={classes['relative']}>
                        <MessageIcon
                            style={
                                unreadOnly
                                    ? { borderBottom: '1px solid #A39ED2' }
                                    : {}
                            }
                            fill={unreadOnly ? 'var(--blue-metal)' : '#A39ED2'}
                        />
                        <Badge className={classes['badge']} limit={true}>
                            {unreadChannel}
                        </Badge>
                    </div>
                    <p>
                        <Trans>Unread</Trans>
                    </p>
                </div>
                {!isMobile && <NotesIcons />}
                <div onClick={onNewChat}>
                    <div className={classes['relative']}>
                        <img src={editMessageIcon} alt="edit-message" />
                    </div>
                    <p>
                        <Trans>New Chat</Trans>
                    </p>
                </div>
            </div>

            {isSearchInput &&
                (isConnected.WSSearch ? (
                    <SearchDesktop
                        searchTitle="Search Results"
                        onClose={() => setIsSearchInput(false)}
                    />
                ) : (
                    <SearchProvider onClose={handleClose}>
                        <Search searchTitle={t`Search Results`} />
                    </SearchProvider>
                ))}
        </Card>
    );
};
export default memo(ServerEditor);
