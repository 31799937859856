import React from 'react';
import { Navigate } from 'react-router-dom';

import ResetPassword from '@/components/ResetPassword/ResetPassword';
import { LOCAL_STORAGE } from '@/constants/enum';
import { useAppSelector } from '@/hooks/useRedux';
import { ROOT_PATH } from '@/routes/router.types';

type Props = {};

const ResetPage = (props: Props) => {
    const user = useAppSelector(state => state.userReducer.user);
    return <ResetPassword />;
};

export default ResetPage;
