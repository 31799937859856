import { FC, RefObject } from 'react';

import DropDown from '@/UI/drop-down/DropDown';
import PermissionsMeControl from '@/Utils/permissionsMeControl';
import useSubServerOptions, { optionTypes } from '@/constants/subServerOptions';
import { useAppSelector } from '@/hooks/useRedux';

type Props = {
    onSelect: (event: any) => void;
    onClose: () => void;
    passedRef?: RefObject<HTMLImageElement>;
};

const ServerDropDown: FC<Props> = props => {
    const { passedRef, onSelect, onClose } = props;
    const channelListEditModeValue = useAppSelector(
        state => state.utilReducer.channelListEditMode,
    );
    const { listArray } = useSubServerOptions(channelListEditModeValue);

    const PermissionsMeControlData = PermissionsMeControl(
        listArray,
        'userPermissions',
    );

    const selectSettingHandler = (option: optionTypes) => {
        onSelect(option);
    };

    return (
        <DropDown
            avoidableCLose={passedRef}
            onClose={() => onClose()}
            selectOptions={PermissionsMeControlData}
            onClick={selectSettingHandler}
            pointer
        />
    );
};
export default ServerDropDown;
