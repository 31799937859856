import moment from 'moment';

import store from '@/store';
import { updateMessagePing, updatePongsCount } from '@/store/util-reducer';

export const updatePingHandler = () => {
    store.dispatch(updatePongsCount());

    const pingDate = store.getState().utilReducer.pingDate;
    const date = new Date();

    const timeDifference = date.getTime() - pingDate;

    store.dispatch(updateMessagePing(timeDifference));
};
