import { AnimatePresence } from 'framer-motion';
import { useContext } from 'react';

import { NotesContext } from '@/context/notes/NotesContext';

import NoteDetails from './note-details/NoteDetails';
import NotesList from './notes-list/NotesList';
import NotesSidebar from './notes-sidebar/NotesSidebar';

const MobileNotesLyout: React.FC<{}> = () => {
    const context = useContext(NotesContext);
    const { activeMobileStep } = context;

    return (
        <AnimatePresence>
            {activeMobileStep === 0 && (
                <>
                    <NotesSidebar />
                    <NotesList />
                </>
            )}
            {activeMobileStep === 1 && <NoteDetails />}
        </AnimatePresence>
    );
};
export default MobileNotesLyout;
