import { Spinner } from 'react-activity';

import { useAppSelector } from '@/hooks/useRedux';

const EncryptionManageLoader: React.FC<{}> = () => {
    const metaData = useAppSelector(
        state => state.utilReducer.migrationMetadata,
    );

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                gap: 16,
            }}>
            <Spinner />
            <span>{`Encrypting data with new keys`}</span>
            <span>
                {`Page ${metaData.currentPageNumber} of ${metaData.total_pages}`}
            </span>
        </div>
    );
};
export default EncryptionManageLoader;
