import React from 'react';

import classes from './Badge.module.css';

interface IBadge {
    style?: React.CSSProperties;
    children?: React.ReactNode;
    className?: any;
    onClick?: Function;
    limit?: boolean;
}

const Badge = (props: IBadge) => {
    let child = props.children;

    if (props.limit && Number(child) > 99) {
        child = '99+';
    }

    if (Number(child) === 0) return <></>;
    return (
        <div
            onClick={() => {
                props?.onClick && props?.onClick();
            }}
            style={props.style}
            className={classes.badge + ' ' + props.className}>
            {child}
        </div>
    );
};
export default Badge;
