import React from 'react';

import classes from './CustomInput.module.css';

const CustomInput = React.forwardRef<HTMLInputElement, any>((props, ref) => {
    return (
        <>
            {props?.label && (
                <label className={classes.label}>{props.label}</label>
            )}
            <input
                {...props}
                ref={ref}
                className={classes['custom-input'] + ' ' + props.className}
                type={props.type}
                value={props?.value}
                onChange={event => props?.onChange && props.onChange(event)}
                placeholder={props.placeholder}
                onFocus={props?.onFocus}
            />
        </>
    );
});
export default React.memo(CustomInput);
