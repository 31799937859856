const StatusIcon = ({ stroke, className = '', onClick = () => {} }) => {
    return (
        <svg
            className={className}
            onClick={onClick}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Icon">
                <g id="profile">
                    <g id="Group">
                        <path
                            id="Vector"
                            d="M12.7131 3.30003C15.3598 5.9467 15.3131 10.2667 12.5798 12.86C10.0531 15.2533 5.95315 15.2533 3.41981 12.86C0.679812 10.2667 0.633137 5.9467 3.28647 3.30003C5.88647 0.693365 10.1131 0.693365 12.7131 3.30003Z"
                            stroke={stroke ? stroke : 'white'}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Vector_2"
                            d="M10.5596 10.7134C9.14628 12.0467 6.85296 12.0467 5.44629 10.7134"
                            stroke={stroke ? stroke : 'white'}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
export default StatusIcon;
