import { t } from '@lingui/macro';
import { FC, createContext, useContext } from 'react';
import { useState } from 'react';

import { encryptDataWithRSA } from '@/Utils/cryptoUtils';
import { getChannelMemberList } from '@/Utils/memberUtils';
import { getServer, uploadFiles } from '@/Utils/serverMediaHandler.js';
import {
    deleteChannel,
    editChannel,
} from '@/Utils/socket-actions/ChannelActions/channelActions';
import { sortByKey } from '@/Utils/sortByKey';
import permissionsIcon from '@/assets/icons/Hashtag.svg';
import detailsIcon from '@/assets/icons/editManagement.svg';
import memberIcon from '@/assets/icons/personalcard.svg';
import { useCustomSweetAlert } from '@/hooks';
import { useAppSelector } from '@/hooks/useRedux';
import {
    rolesListSelector,
    serverDetailSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import { IChannelData } from '@/types';

import AddingRoleBasedMembers from './AddingRoleBasedMembers/AddingRoleBasedMembers';
import ChannelUserPermissinons from './ChannnelUserPermissinons/ChannelUserPermissinons';
import EditChannelContent from './EditChannelContent';
import Permissions from './Permissions';
import RenderMembers from './RenderMembers';
import RightContent from './RightContent';

const defaultValue = {
    componentMap: {},
    selectedComponent: 'details',
    selectComponent: () => {},
    onClose: () => {},
    isPrivate: false,
    setIsPrivate: () => {},
    defaultBg: '',
    mediaInputValue: null,
    setMediaInputValue: () => {},
    channelName: '',
    channelDescription: '',
    setChannelName: () => {},
    setChannelDescription: () => {},
    sectionId: '',
    setSectionId: () => {},
    channel: null,
    deleteChannelHandler: () => {},
    editChannelHandler: () => {},
};
type IContext = {
    componentMap;
    selectedComponent;
    selectComponent;
    isPrivate;
    setIsPrivate;
    onClose: () => void;
    defaultBg;
    mediaInputValue;
    setMediaInputValue;
    channelName;
    channelDescription;
    setChannelName;
    setChannelDescription;
    sectionId;
    setSectionId;
    channel;
    deleteChannelHandler;
    editChannelHandler;
};
const EditChannelContext = createContext<IContext>(defaultValue);

export const useEditChannelContext = () => useContext(EditChannelContext);

const EditChannelProvider: FC<{
    channel: IChannelData;
    onClose;
    children;
}> = props => {
    const { channel, onClose, children } = props;
    const [mediaInputValue, setMediaInputValue] = useState(null);
    const [channelName, setChannelName] = useState(channel?.name);
    const [channelDescription, setChannelDescription] = useState(
        channel?.description ? channel?.description : '',
    );
    const [sectionId, setSectionId] = useState(channel?.channel_section_id);
    const [isPrivate, setIsPrivate] = useState(!channel?.is_public);

    const members = getChannelMemberList(channel._id);
    const { areYouSureModal } = useCustomSweetAlert();

    const { successAlertModal } = useCustomSweetAlert();

    //file upload---------------------------------------------
    const serverLink = process.env.REACT_APP_GET_SERVER_LINK_URL;
    const server = useAppSelector(state => serverDetailSelector(state));

    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );
    const submitImage = async file => {
        const { responseLink } = await getServer(file, serverLink);
        const { uploadedFileLink } = await uploadFiles(
            file,
            activeServer,
            responseLink,
            user.token,
            '',
            false,
        );
        return { uploadedFileLink: uploadedFileLink.data };
    };

    const deleteChannelHandler = () => {
        areYouSureModal(null, null, true, () => deleteChannel(channel?._id));
    };

    const editChannelHandler = async () => {
        let serverBg = mediaInputValue ?? '';
        let clientBg = mediaInputValue ?? '';

        if (typeof serverBg === 'object') {
            serverBg = await submitImage(serverBg?.file);
            clientBg = `https://${serverBg?.uploadedFileLink?.server}/api/download/${serverBg?.uploadedFileLink?.fileKey}`;
        }
        const encryptChannelName = await encryptDataWithRSA(
            channelName,
            server?.publicKey,
        );
        let payload = {
            name: encryptChannelName,
            channel_id: channel?._id,
            description: channelDescription,
            channel_section_id: sectionId,
            is_public: !isPrivate,
            background_image: '',
        };
        if (typeof mediaInputValue === null || mediaInputValue) {
            payload.background_image = clientBg;
        } else if (typeof mediaInputValue === 'undefined') {
            payload.background_image = '';
        }
        editChannel(payload);
        successAlertModal();
    };

    const user = useAppSelector(state => state.userReducer.user);
    const rolesList = useAppSelector(rolesListSelector);
    const sortByPriority = sortByKey(rolesList, 'priority');

    const [selectedComponent, setSelectedComponent] = useState('details');
    const selectComponent = component => setSelectedComponent(component);
    let componentMap = {};
    const tabDetails = {
        name: t`Details`,
        icon: detailsIcon,
        component: <EditChannelContent />,
        buttons: <RightContent />,
    };
    const tabPermissions = {
        name: t`Permissions`,
        icon: permissionsIcon,
        component: (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                }}>
                <Permissions />
                <div>
                    <AddingRoleBasedMembers
                        sortByPriority={sortByPriority}
                        ch={channel}
                    />
                </div>
                {!channel?.is_public && <ChannelUserPermissinons />}
            </div>
        ),
        buttons: <RightContent />,
    };
    const tabMembers = {
        name: t`Members`,
        icon: memberIcon,
        component: <RenderMembers />,
    };

    if (!channel?.is_public) {
        componentMap = {
            details: tabDetails,
            members: tabMembers,
            permissions: tabPermissions,
        };
    } else {
        componentMap = {
            details: tabDetails,
            permissions: tabPermissions,
        };
    }

    const values = {
        componentMap,
        selectedComponent,
        selectComponent,
        onClose,
        isPrivate,
        setIsPrivate,
        defaultBg: channel.background_image,
        mediaInputValue,
        setMediaInputValue,
        channelName,
        channelDescription,
        setChannelName,
        setChannelDescription,
        sectionId,
        setSectionId,
        channel,
        deleteChannelHandler,
        editChannelHandler,
    };
    return (
        <EditChannelContext.Provider value={values}>
            {children}
        </EditChannelContext.Provider>
    );
};
export default EditChannelProvider;
