import { Trans } from '@lingui/macro';

import { Line } from '@/UI';
import ThreadListSvg from '@/assets/icons/ThreadList.svg';

import classes from './ThreadList.module.css';

type IProps = {
    count: number;
};
const ThreadsListHeader: React.FC<IProps> = props => {
    const { count } = props;
    return (
        <>
            <div className={classes['header']}>
                <img
                    className={classes['image-icon']}
                    src={ThreadListSvg}
                    alt={'room type'}
                />
                <p className={classes['name-header']}>
                    <Trans>Thread</Trans>
                </p>
                <p className={classes['thread-count']}>
                    {count && count > 0 ? `${count}` : ''}
                </p>
            </div>
            <Line className={classes['strong-hr']} />
        </>
    );
};
export default ThreadsListHeader;
