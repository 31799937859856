import { Trans, t } from '@lingui/macro';

import { Switch } from '@/UI';

import classes from './EditChannel.module.css';
import { useEditChannelContext } from './EditChannelContext';
import Title from './Title';

const Permissions: React.FC = () => {
    const { isPrivate, setIsPrivate } = useEditChannelContext();

    return (
        <div>
            <Title
                title={t`Channel Permissions`}
                subtitle={t`You can set all channel related permissions here`}
            />
            <div className={classes['pvt-container']}>
                <div className={classes['pvt-text']}>
                    <span className={classes['pvt-title']}>
                        <Trans>Private Channel</Trans>
                    </span>
                    <span className={classes['subtitle']}>
                        <Trans>
                            Making a channel private ensures that only selected
                            members and roles can view it
                        </Trans>
                        .
                    </span>
                </div>
                <Switch checked={isPrivate} onChange={e => setIsPrivate(e)} />
            </div>
        </div>
    );
};
export default Permissions;
