import { Trans } from '@lingui/macro';

import classes from './MediaAddButton.module.css';

const MediaAddButton = ({ onClick = () => {} }) => {
    return (
        <div className={classes['relative']}>
            <div className={classes['add']} onClick={onClick}>
                +&nbsp;<Trans>Add</Trans>
            </div>
        </div>
    );
};
export default MediaAddButton;
