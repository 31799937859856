const DB_NAME = 'fileDatabase';
const OBJECT_STORE_NAME = 'files';

// Open or create the IndexedDB database
const dbPromise = indexedDB.open(DB_NAME, 1);

export interface FileEntry {
    id?: number;
    type: string;
    data?: Blob;
    name: string;
}

dbPromise.onupgradeneeded = (event: any) => {
    const db = event.target.result;

    // Create an object store
    if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
        const objectStore = db.createObjectStore(OBJECT_STORE_NAME, {
            keyPath: 'id',
            autoIncrement: true,
        });
    }
};

// Function to add a file to IndexedDB
export function addFileToDB(fileEntry: FileEntry) {
    return new Promise<number>((resolve, reject) => {
        const transaction = dbPromise.result.transaction(
            [OBJECT_STORE_NAME],
            'readwrite',
        );
        const store = transaction.objectStore(OBJECT_STORE_NAME);

        // Check if an ID is provided, if not, let the auto-increment handle it
        if (fileEntry.id !== undefined) {
            // Check if the ID already exists
            const getRequest = store.get(fileEntry.id);

            getRequest.onsuccess = (event: any) => {
                const existingFile: FileEntry = event.target.result;
                if (existingFile) {
                    // Return a message indicating that the file already exists
                    resolve(-1);
                } else {
                    // No existing file found, proceed with adding new entry
                    const addRequest = store.add(fileEntry);

                    addRequest.onsuccess = (event: any) => {
                        resolve(event.target.result);
                    };

                    addRequest.onerror = (event: any) => {
                        reject(event.error);
                    };
                }
            };

            getRequest.onerror = (event: any) => {
                reject(event.error);
            };
        } else {
            // No ID provided, let auto-increment handle it
            const addRequest = store.add(fileEntry);

            addRequest.onsuccess = (event: any) => {
                resolve(event.target.result);
            };

            addRequest.onerror = (event: any) => {
                reject(event.error);
            };
        }
    });
}

// Function to retrieve a file Blob from IndexedDB by ID
export function getFileById(fileId: string) {
    return new Promise<FileEntry>((resolve, reject) => {
        const transaction = dbPromise.result.transaction(
            [OBJECT_STORE_NAME],
            'readonly',
        );
        const store = transaction.objectStore(OBJECT_STORE_NAME);

        const request = store.get(fileId);

        request.onsuccess = (event: any) => {
            const result: FileEntry = event.target.result;
            if (result) {
                resolve(result);
            }
        };

        request.onerror = (event: any) => {
            reject(event.error);
        };
    });
}

export function checkFileExist(itemId: string): Promise<boolean> | boolean {
    if (itemId) {
        return new Promise<boolean>((resolve, reject) => {
            const transaction = dbPromise.result.transaction(
                [OBJECT_STORE_NAME],
                'readonly',
            );
            const store = transaction.objectStore(OBJECT_STORE_NAME);

            const request = store?.get(itemId);

            request.onsuccess = (event: any) => {
                const result: FileEntry = event.target.result;
                resolve(!!result); // Resolves to true if item exists, false if not
            };

            request.onerror = (event: any) => {
                reject(event.error);
            };
        });
    }
    return false;
}

export function clearDB() {
    return new Promise<void>((resolve, reject) => {
        const transaction = dbPromise.result.transaction(
            [OBJECT_STORE_NAME],
            'readwrite',
        );
        const store = transaction.objectStore(OBJECT_STORE_NAME);

        const clearRequest = store.clear();

        clearRequest.onsuccess = () => {
            resolve();
        };

        clearRequest.onerror = event => {
            reject(event.error);
        };
    });
}
