import defaultUserImage from '../assets/images/default-user-image.jpeg';

export const DEFAULT_IMAGES = {
    PROFILE_IMAGE: defaultUserImage,
    SERVER_IMAGE:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4vtD-1-8W76r9UVfE7lP1fTZ_yV6mniyrxtmgesyAmg02NChfPyKixhkOgHXBxmaYxWs&usqp=CAU',
    COVER_IMAGE: 'https://www.tgsin.in/images/joomlart/demo/default.jpg',
};

export const MESSAGE_VERSION = 1;
