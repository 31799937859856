import { Trans, t } from '@lingui/macro';
import Select from 'react-select';

import { AppButton, CustomInput, Modal } from '@/UI';
import { truncateText } from '@/Utils/textUtils';
import { INote } from '@/types/notesTypes';

import classes from './NewNoteSection.module.css';
import { styles } from './NewNoteSelectStyle';
import useNewNoteModal from './useNewNoteModal';

interface IProps {
    isEdit?: { state: boolean; item: INote };
}

const NewNoteModal: React.FC<IProps> = props => {
    const { isEdit } = props;

    const {
        selectOptions,
        nameRef,
        isError,
        isPosting,
        isSuccess,
        isFieldError,
        activeNote,
        setIsEditNoteModal,
        setCatId,
        submitHandler,
        setIsAddNewNote,
        nameChangeHandler,
    } = useNewNoteModal(isEdit);

    const modalHeader = isEdit ? t`Edit Note` : t`New Note`;

    const defaultSelectValue =
        isEdit && isEdit.state
            ? selectOptions.find(
                  el => activeNote && el.value === activeNote?.note_section_id,
              )
            : selectOptions[0];

    const closeHandler = () => {
        isEdit && isEdit.state
            ? setIsEditNoteModal(null)
            : setIsAddNewNote(false);
    };

    return (
        <Modal
            header={modalHeader}
            className={classes.modal}
            headerClassName={classes['header']}
            onClose={closeHandler}>
            <form onSubmit={submitHandler}>
                <div className={`${classes['container']}`}>
                    {!isPosting && isError && (
                        <p className={classes['error']}>{isError}</p>
                    )}
                    {!isPosting && isSuccess && (
                        <p className={classes['success']}>
                            {isEdit ? (
                                <Trans>Note updated successfully.</Trans>
                            ) : (
                                <Trans>Note created successfully.</Trans>
                            )}
                        </p>
                    )}
                    <div className={classes['input-wrap']}>
                        <CustomInput
                            onChange={nameChangeHandler}
                            autoFocus
                            label={t`Note Name:`}
                            ref={nameRef}
                            type={'text'}
                            defaultValue={
                                isEdit &&
                                isEdit?.state &&
                                truncateText(isEdit.item?.title, 15)
                            }
                            placeholder={t`Name`}
                            className={`${
                                isFieldError.name ? classes['error'] : ''
                            } ${classes['input']}`}
                        />
                    </div>
                    <div className={classes['input-wrap']}>
                        <label htmlFor="category-name">
                            <Trans>Group</Trans>:
                        </label>
                        <Select
                            name="category-name"
                            isSearchable={false}
                            styles={styles}
                            placeholder={t`Select Category`}
                            defaultValue={defaultSelectValue}
                            className={`${classes['select']}`}
                            onChange={ev => {
                                // @ts-ignore
                                setCatId(ev.value);
                            }}
                            // @ts-ignore
                            options={selectOptions}
                        />
                    </div>
                    <div className={classes['btn-wrap']}>
                        <AppButton
                            onClick={closeHandler}
                            title={t`Cancel`}
                            classStyles={classes['cancel']}
                            disable={isPosting}
                        />
                        <AppButton
                            title={t`Save`}
                            type="submit"
                            buttonType="primary"
                            classStyles={classes['save']}
                            disable={isPosting}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};
export default NewNoteModal;
