import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import { formatDuration } from '@/Utils/handleDurations';
import removeIcon from '@/assets/icons/trash.svg';

import ImgButton from '../custom-buttons/ImgButton';
import ReplyMediaOther from '../display-media/reply-media/ReplyMediaOther';

import classes from './DisplayAttachment.module.css';

export type FileType = {
    file: File;
    id: string;
    lastModified: number | string;
    lastModifiedDate: Date;
    name: string;
    size: number;
    src: string | undefined;
    type: string;
};

type Props = {
    onRemoveMediaItem: (id: string) => void;
    onRemoveImageLink: (key: number | string) => void;
    defaultImageLink: (string | undefined | null)[];
    files: FileType[];
    className: string;
};

const DisplayAttachment: FC<Props> = props => {
    const {
        onRemoveImageLink,
        onRemoveMediaItem,
        files,
        defaultImageLink,
        className = '',
    } = props;

    const [filesState, setFilesState] = useState<FileType[]>([]);
    const [fitScreen, setFitScreen] = useState('');
    const [videoDurations, setVideoDurations] = useState([]);

    const removeItemHandler = (event, id: string) => {
        event.stopPropagation();
        onRemoveMediaItem(id);
    };
    const removeImageLink = (event, key) => {
        event.stopPropagation();
        onRemoveImageLink(key);
    };

    const handleVideoLoadedMetadata = (
        event: SyntheticEvent<HTMLVideoElement, Event>,
        key: number,
    ) => {
        const videoElement = event.currentTarget as HTMLVideoElement;
        setVideoDurations(prevDurations => {
            const duration = videoElement.duration;
            const newDurations = [...prevDurations];
            newDurations[key] = duration;
            return newDurations;
        });
    };

    useEffect(() => {
        setFitScreen(
            filesState.length === 1 || defaultImageLink.length === 1
                ? classes['fit-screen']
                : classes['mediaContainer'],
        );
    }, [filesState]);

    useEffect(() => {
        setFilesState(files);
    }, [files]);

    return (
        <div className={classes['selected-items'] + ' ' + className}>
            {defaultImageLink && defaultImageLink.length > 0
                ? defaultImageLink.map((image, key) => (
                      <div className={fitScreen} key={key}>
                          <ImgButton
                              type={'button'}
                              onClick={event => {
                                  removeImageLink(event, key);
                              }}
                              className={classes['remove-icon']}
                              icon={removeIcon}
                          />
                          <img
                              src={
                                  image ??
                                  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4vtD-1-8W76r9UVfE7lP1fTZ_yV6mniyrxtmgesyAmg02NChfPyKixhkOgHXBxmaYxWs&usqp=CAU'
                              }
                              alt={'default'}
                          />
                      </div>
                  ))
                : filesState.map((file, key) => (
                      <div className={fitScreen} key={key}>
                          <ImgButton
                              type={'button'}
                              onClick={event => {
                                  removeItemHandler(event, file.id);
                              }}
                              className={classes['remove-icon']}
                              icon={removeIcon}
                          />
                          {file.type?.startsWith('image/') &&
                          !file.type.includes('svg') ? (
                              <img
                                  src={file?.src ?? ''}
                                  alt={file?.name ?? ''}
                              />
                          ) : file.type?.startsWith('video/') ? (
                              <>
                                  <video
                                      muted
                                      className={classes['video']}
                                      controls={false}
                                      src={file.src}
                                      onLoadedMetadata={(
                                          e: React.SyntheticEvent<
                                              HTMLVideoElement,
                                              Event
                                          >,
                                      ) => {
                                          handleVideoLoadedMetadata(e, key);
                                      }}
                                      autoPlay={true}>
                                      <source
                                          src={file?.src}
                                          type={file?.type ?? 'video/mp4'}
                                      />
                                  </video>
                                  <div className={classes['video-duration']}>
                                      {videoDurations[key]
                                          ? formatDuration(videoDurations[key])
                                          : '-- : --'}
                                  </div>
                              </>
                          ) : (
                              <ReplyMediaOther
                                  className={classes['others']}
                                  type={file?.type}
                                  name={file?.name ?? ''}
                                  size={file?.size ?? 0}
                                  truncateLength={50}
                              />
                          )}
                      </div>
                  ))}
        </div>
    );
};
export default DisplayAttachment;
