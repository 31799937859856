export const encryptFile = async file => {
    try {
        const fileData = await readFileAsArrayBuffer(file);
        const encryptedData = await encryptData(fileData);
        const encryptedFile = new File([encryptedData], file.name, {
            type: file.type,
        });
        return {
            encryptedFile,
        };
    } catch (error) {
        console.error('Error encrypting file:', error);
    }
};

const readFileAsArrayBuffer = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsArrayBuffer(file);
    });
};

const encryptData = async data => {
    const secretKey = '0123456789abcdef0123456789abcdef'; // Replace with a strong secret key
    const keyBytes = new TextEncoder().encode(secretKey);
    const iv = window.crypto.getRandomValues(new Uint8Array(16));
    const algorithm = { name: 'AES-CBC', length: 128, iv };
    const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        keyBytes,
        algorithm,
        false,
        ['encrypt'],
    );
    const encryptedData = await window.crypto.subtle.encrypt(
        algorithm,
        cryptoKey,
        data,
    );
    const encryptedBytes = new Uint8Array(encryptedData);
    const fullEncryptedData = new Uint8Array(iv.length + encryptedBytes.length);
    fullEncryptedData.set(iv);
    fullEncryptedData.set(encryptedBytes, iv.length);
    return fullEncryptedData;
};

export const decryptFile = async encryptedFile => {
    if (encryptedFile) {
        try {
            const encryptedData = await readFileAsArrayBuffer(encryptedFile);
            const decryptedData = await decryptData(encryptedData);
            const decryptedFile = new File([decryptedData], encryptedFile.name);
            return {
                decryptedFile,
            };
        } catch (error) {
            console.error('Error decrypting file:', error);
        }
    }
};

const decryptData = async file => {
    const secretKey = '0123456789abcdef0123456789abcdef'; // Replace with a strong secret key
    const keyBytes = new TextEncoder().encode(secretKey);
    const iv = file.slice(0, 16);
    const algorithm = { name: 'AES-CBC', length: 128, iv };
    const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        keyBytes,
        algorithm,
        false,
        ['decrypt'],
    );
    const encryptedData = file.slice(16); // Slice off the IV from the encrypted data
    const decryptedData = await window.crypto.subtle.decrypt(
        algorithm,
        cryptoKey,
        encryptedData,
    );
    return decryptedData;
};
