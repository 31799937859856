import { useLingui } from '@lingui/react';
import { FC } from 'react';

import DropDown from '@/UI/drop-down/DropDown';
import { languagesArray } from '@/constants/languagesArray';
import { dynamicActivate, locales } from '@/i18n';

import classes from './LanguagesModal.module.css';

type propsTypes = {
    onChangeLanguage: () => void;
    onCLose?: () => void;
    className?: string;
};

const LanguagesModal: FC<propsTypes> = props => {
    const { onChangeLanguage, onCLose, className = '' } = props;
    const { i18n } = useLingui();
    return (
        <DropDown
            header={'Languages'}
            className={`${className} ${classes['container']}`}
            onClose={onCLose}>
            <div className={classes['list-container']}>
                {languagesArray?.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => {
                            localStorage.setItem(
                                'selectedLanguage',
                                Object.keys(locales)[index],
                            );
                            dynamicActivate(Object.keys(locales)[index]);
                            onChangeLanguage();
                        }}
                        className={classes['language-item-row']}>
                        <input
                            type="checkbox"
                            disabled={i18n.locale === item?.languageCode}
                            defaultChecked={i18n.locale === item?.languageCode}
                        />
                        <span className={classes['languageName']}>
                            {item.name}
                        </span>
                        <span className={classes['languageDescription']}>
                            {item.description}
                        </span>
                    </div>
                ))}
            </div>
        </DropDown>
    );
};
export default LanguagesModal;
