import { createBrowserRouter } from 'react-router-dom';

import {
    AuthPage,
    DevicePasswordPage,
    DownloadKeysPage,
    ErrorPage,
    HomePage,
    LoginPage,
    Page404,
    ResetPage,
} from '@/pages';

import ProtectedRoute from './ProtectedRoute';
import { ROOT_PATH } from './router.types';

const router = createBrowserRouter([
    {
        path: '/',
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: (
                    <ProtectedRoute>
                        <HomePage />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROOT_PATH.auth,

                children: [
                    { index: true, element: <AuthPage /> },
                    { path: ROOT_PATH.login, element: <LoginPage /> },
                    {
                        path: ROOT_PATH.devicePassword,
                        element: <DevicePasswordPage />,
                    },
                    { path: ROOT_PATH.resetPassword, element: <ResetPage /> },
                ],
            },
            { path: '*', element: <Page404 /> },
            { path: ROOT_PATH.downloadKeys, element: <DownloadKeysPage /> },
        ],
    },
]);
export default router;
