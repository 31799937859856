import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';

import { encryptNote } from '@/Utils/notesHelper';
import { createNoteApi } from '@/api/notes/noteApis';
import { NotesContext } from '@/context/notes/NotesContext';
import { useAppSelector } from '@/hooks/useRedux';
import { INote } from '@/types/notesTypes';

const useNewNoteModal = (isEdit: { state: boolean; item: INote }) => {
    const nameRef = useRef<HTMLInputElement>(null);

    const noteContext = useContext(NotesContext);
    const {
        noteCat,
        activeNote,
        saveNote,
        getNotes,
        openCreatedNote,
        setActiveCategory,
        setIsEditNoteModal,
        setIsAddNewNote,
    } = noteContext;

    const [catId, setCatId] = useState<string>(null);

    const [isError, setIsError] = useState(null);
    const [isPosting, setIsPosting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);
    const [isBodyEncrypted, setIsBodyEncrypted] = useState({
        message: null,
        token: null,
        key_hash: null,
    });

    const [isFieldError, setIsFieldError] = useState({
        name: false,
        cat: false,
    });

    const subserverId = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    let selectOptions = noteCat.map(item => {
        return { value: item.id, label: item.title };
    });

    selectOptions.unshift({ value: '', label: '---' });

    const nameChangeHandler = () => {
        if (!isFieldError.name && nameRef.current.value.trim() === '') {
            setIsFieldError(prev => {
                return { ...prev, name: true };
            });
        } else if (isFieldError.name && nameRef.current.value.trim() !== '') {
            setIsFieldError(prev => {
                return { ...prev, name: false };
            });
        }
    };

    const afterCreateNoteHandler = async (id: string) => {
        setActiveCategory(catId ? catId : 'all');
        const { data } = await getNotes();

        setTimeout(() => {
            openCreatedNote(id);
            setIsPosting(false);
            setIsAddNewNote(false);
            setIsEditNoteModal(null);
        }, 500);
    };

    async function generateNoteBody() {
        const { message, token, key_hash } = await encryptNote({
            data: '<p>Welcome to note</p>',
        });

        if (message && token && key_hash) {
            setIsBodyEncrypted({
                message: message,
                token: token,
                key_hash: key_hash,
            });
        }
    }

    const submitHandler = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const name = nameRef.current.value.trim();
        if (name === '') {
            return setIsFieldError(prev => {
                return { ...prev, name: true };
            });
        }
        setIsPosting(true);

        const params = {
            title: nameRef.current.value.trim(),
            note_section_id: catId ? catId : '',
            body: isBodyEncrypted.message,
            key_hash: isBodyEncrypted.key_hash,
            encryption_token: isBodyEncrypted.token,
            fav: 0,
        };

        if (
            !isBodyEncrypted.key_hash ||
            !isBodyEncrypted.message ||
            !isBodyEncrypted.token
        ) {
            setIsPosting(false);
            setIsSuccess(false);
            setIsError('could not add note encryption, try again');
            return;
        }
        if (isEdit) {
            const { data } = await saveNote({
                note_section_id: catId,
                title: nameRef.current.value.trim(),
            });
            if (data.status === false) {
                setIsError(data.message);
                setIsPosting(false);
                setIsSuccess(false);
            } else {
                setIsError(null);
                setIsSuccess(true);
                afterCreateNoteHandler(data.data?.id);
            }
            return;
        }
        const { data } = await createNoteApi({ subserverId, params });
        if (data.status === false) {
            setIsError(data.message);
            setIsPosting(false);
            setIsSuccess(false);
        } else {
            setIsError(null);
            setIsSuccess(true);
            afterCreateNoteHandler(data.data?.id);
        }
    };

    useEffect(() => {
        generateNoteBody();
    }, []);

    return {
        activeNote,
        selectOptions,
        nameRef,
        isError,
        isPosting,
        isSuccess,
        isFieldError,
        setCatId,
        submitHandler,
        setIsAddNewNote,
        setIsEditNoteModal,
        nameChangeHandler,
    };
};
export default useNewNoteModal;
