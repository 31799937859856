import { FC, memo } from 'react';

import { Badge, Icon } from '@/UI';
import { ExternalBadge } from '@/UI/externalbadge/ExternalBadge';
import { getHighestMemberRole } from '@/Utils/memberUtils';
import { truncateText } from '@/Utils/textUtils';
import { DEFAULT_IMAGES } from '@/constants';
import { statusById } from '@/constants/status';

import classes from './Connections.module.css';
import { ItemProps } from './connectionsTypes';

const ConnectionsItem: FC<ItemProps> = memo(props => {
    const { item, onOpenProfileModal } = props;

    const role = getHighestMemberRole(item);
    const badgeState = statusById?.[item?.status]?.icon || null;

    return (
        <li
            key={item.id}
            id={`user-item-${item?.id}`}
            className={classes['userItem']}
            onClick={() => onOpenProfileModal(item)}>
            <Icon
                className={classes['person-badge']}
                imageURL={item?.profile_picture ?? DEFAULT_IMAGES.PROFILE_IMAGE}
                name={item?.name ? item?.name : 'user image'}
                hasBadge
                badgeStyle={{
                    bottom: -4,
                    right: -4,
                    width: '10px',
                    height: '10px',
                }}>
                <Badge className={classes['dot']}>
                    {badgeState && item?.state && (
                        <img
                            alt=""
                            style={{
                                width: '14px',
                                height: '14px',
                            }}
                            src={badgeState}
                        />
                    )}
                    {!item?.state && (
                        <span
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: 'var(--gray)',
                                borderRadius: 999,
                                aspectRatio: 1 / 1,
                            }}
                        />
                    )}
                </Badge>
            </Icon>

            <span
                className={classes['username']}
                style={{ color: role?.color }}>
                {item?.name ? (
                    truncateText(item.name, 18)
                ) : (
                    <i
                        style={{
                            fontWeight: 400,
                        }}>
                        - localhost user -
                    </i>
                )}
            </span>
            {item?.external > 0 && <ExternalBadge />}
        </li>
    );
});

export default ConnectionsItem;
