import CryptoJS from 'crypto-js';

import { PLATFORM, SESSION_STORAGE } from '@/constants/enum';

import { decryptPassword } from '../cryptoUtils';
import { sortObject } from '../objectUtils';
import { checkPlatform, getCookie } from '../utils';
import {
    connectFindSearchSocket,
    connectNotificationSocket,
    connectNotificationSocketDesktop,
    connectSearchSocket,
    connectWebSocket,
} from '../webSocketUtils';

// // Define a type for the process object in a web browser environment
// interface WebBrowserProcess {
//     type?: string;
// }

// // Declare the process object on the window with the custom type
// declare global {
//     interface Window {
//         process?: WebBrowserProcess;
//     }
// }

const getRSAKeysHashKey = (privateKey, publicKey) => {
    const devicePassword = getCookie(SESSION_STORAGE.devicePassword);
    const privateKeyJWK = decryptPassword(privateKey, devicePassword);
    const publicKeyJWK = decryptPassword(publicKey, devicePassword);
    const hash_key = CryptoJS.MD5(
        JSON.stringify({
            privateKey: sortObject(privateKeyJWK),
            publicKey: sortObject(publicKeyJWK),
        }),
    );
    return hash_key.toString();
};

export const connect = async (
    privateKey,
    publicKey,
    userToken,
    subserver_id,
    websocketUrl: string,
) => {
    const hash_key = getRSAKeysHashKey(privateKey, publicKey);
    connectWebSocket(userToken, subserver_id, websocketUrl, hash_key);
};
const SEARCH_ON_CHAT_SERVER = true;

export const connectWSSearch = (privateKey, publicKey) => {
    !SEARCH_ON_CHAT_SERVER && connectSearchSocket(privateKey, publicKey);
    !SEARCH_ON_CHAT_SERVER && connectFindSearchSocket(privateKey, publicKey);
};

export const connectNotificationServer = (userToken: string, url: string) => {
    connectNotificationSocket(userToken, url);
    const platform = checkPlatform();
    if (platform === PLATFORM.DESKTOP) {
        connectNotificationSocketDesktop();
    }
};
