import { FC, Key, createContext, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { sortMediaOrder } from '@/Utils/sortMediaOrder';
import { MediaTypes } from '@/components/messages/media-message/MediaMessageTypes';

import { IContext, IProviderProps } from './SelectedMediaTypes';

const initialValue: IContext = {
    filesArray: [],
    showReadMore: () => {
        return false;
    },
    setFilesArray: () => {},
    allowView: false,
    viewMediaHandler: () => {},
    files: [],
    newLoadedMediaHandler: () => {},
    isMediaView: {
        state: false,
        initialSlide: 0,
    },
    closeMediaHandler: () => {},
    owner: { name: '', profile_picture: null },
    fetchedUrls: [],
    message: '',
    filesLength: 0,
};

export const MediaDisplayContext = createContext<IContext>(initialValue);
export const useMediaDisplayContext = () => useContext(MediaDisplayContext);

const MediaDisplayProvider: FC<IProviderProps> = props => {
    const isLargeScreen = useMediaQuery({ query: '(min-width: 1600px)' });
    const isTabScreen = useMediaQuery({ query: '(max-width: 1200px)' });

    const { allowView, msg, files, children } = props;
    const owner = msg?.owner ?? { name: '', profile_picture: null };
    const message = msg?.message ?? '';

    const [filesArray, setFilesArray] = useState<MediaTypes[]>([]);
    const [fetchedUrls, setFetchedUrls] = useState([]);

    const [isMediaView, setIsMediaView] = useState(initialValue.isMediaView);

    useEffect(() => {
        if (files) {
            const sliceFiles = [...files]
                .sort(sortMediaOrder)
                .slice(0, isLargeScreen ? 4 : isTabScreen ? 2 : 3);
            setFilesArray(sliceFiles);
        }
    }, [files]);

    const viewMediaHandler = (key: Key) => {
        if (!allowView) return;
        setIsMediaView({ state: true, initialSlide: key });
    };

    const closeMediaHandler = () => {
        setIsMediaView({ state: false, initialSlide: 0 });
    };
    const newLoadedMediaHandler = (src: string, file: MediaTypes) => {
        setFetchedUrls(prev => [...prev, { ...file, src: src }]);
    };

    const showReadMore = (index: number) => {
        let condition =
            files.length > 3 && filesArray.length < 4 && index === 2;
        if (isLargeScreen) {
            condition =
                files.length > 4 && filesArray.length < 5 && index === 3;
        } else if (isTabScreen) {
            condition =
                files.length > 2 && filesArray.length < 3 && index === 1;
        }
        return condition;
    };

    const value = {
        filesArray,
        sortMediaOrder,
        showReadMore,
        setFilesArray,
        allowView,
        viewMediaHandler,
        files,
        newLoadedMediaHandler,
        isMediaView,
        closeMediaHandler,
        owner,
        fetchedUrls,
        message,
        filesLength: files.length,
    };
    return (
        <MediaDisplayContext.Provider value={value}>
            {children}
        </MediaDisplayContext.Provider>
    );
};

export default MediaDisplayProvider;
