const VolumeIcon = ({
    width = '20',
    height = '20',
    fill = '#DCDBE7',
    className = '',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 13.9583C14.8667 13.9583 14.7416 13.9166 14.625 13.8333C14.35 13.6249 14.2917 13.2333 14.5 12.9583C15.8083 11.2166 15.8083 8.78328 14.5 7.04161C14.2917 6.76661 14.35 6.37494 14.625 6.16661C14.9 5.95828 15.2917 6.01661 15.5 6.29161C17.1333 8.47494 17.1333 11.5249 15.5 13.7083C15.375 13.8749 15.1917 13.9583 15 13.9583Z"
                fill={fill}
            />
            <path
                d="M16.5249 16.0417C16.3916 16.0417 16.2666 16 16.1499 15.9167C15.8749 15.7084 15.8166 15.3167 16.0249 15.0417C18.2499 12.075 18.2499 7.92502 16.0249 4.95836C15.8166 4.68336 15.8749 4.29169 16.1499 4.08336C16.4249 3.87502 16.8166 3.93336 17.0249 4.20836C19.5832 7.61669 19.5832 12.3834 17.0249 15.7917C16.9082 15.9584 16.7166 16.0417 16.5249 16.0417Z"
                fill={fill}
            />
            <path
                d="M13.1249 6.17505V13.8251C13.1249 15.2584 12.6083 16.3334 11.6833 16.8501C11.3083 17.0584 10.8916 17.1584 10.4583 17.1584C9.79158 17.1584 9.07492 16.9334 8.34159 16.4751L5.90825 14.9501C5.74159 14.8501 5.54992 14.7917 5.35825 14.7917H4.58325V5.20839H5.35825C5.54992 5.20839 5.74159 5.15005 5.90825 5.05005L8.34159 3.52505C9.55825 2.76672 10.7499 2.63339 11.6833 3.15005C12.6083 3.66672 13.1249 4.74172 13.1249 6.17505Z"
                fill={fill}
            />
            <path
                d="M4.58342 5.20837V14.7917H4.16675C2.15008 14.7917 1.04175 13.6834 1.04175 11.6667V8.33337C1.04175 6.31671 2.15008 5.20837 4.16675 5.20837H4.58342Z"
                fill={fill}
            />
        </svg>
    );
};
export default VolumeIcon;
