import { t, Trans } from '@lingui/macro';
import { useState } from 'react';

import TabsManagement from '@/UI/TabsManagement/TabsManagement';
import { useAppSelector } from '@/hooks/useRedux';
import classes from './VersionDownload.module.css';
import VersionItem from './version-item/VersionItem';

const VersionDownload = () => {
    const getVersionListData = useAppSelector(
        state => state.serverDataReducer.versionList,
    );
    const [selectedItem, setSelectedItem] = useState(null);
    const handleItemClick = item => {
        setSelectedItem(item);
    };
    const tabs = [
        {
            id: 0,
            value: 'new-version',
            label: t`New Version`,
            date: t`Last Updated` + ': 2023-04-02',
        },
    ];

    return (
        <div className={classes['container']}>
            <div className={classes['header']}>
                <h2 style={{ margin: 0 }}>
                    <Trans>Download</Trans>
                </h2>
                <span className={classes['sub-heading']}>
                    <Trans>
                        You can download the version suitable for you from here
                        and review the future
                    </Trans>
                    .
                </span>
            </div>
            <div>
                <TabsManagement fromPage="VersionDownload" tabs={tabs} />
            </div>
            <div className={classes['version-item-container']}>
                {getVersionListData?.map((val, index) => (
                    <VersionItem
                        versionItem={val}
                        index={index}
                        isSelected={selectedItem === index}
                        onSelect={handleItemClick}
                    />
                ))}
            </div>
        </div>
    );
};

export default VersionDownload;
