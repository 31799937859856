import classes from '../TableMemberManagement.module.css';

type IProps = {
    item: string;
};
const EmailColumn: React.FC<IProps> = ({ item }) => {
    return (
        <div className={classes['email']}>
            <p>{item}</p>
        </div>
    );
};
export default EmailColumn;
