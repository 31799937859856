import { Trans, t } from '@lingui/macro';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { AppButton } from '@/UI';
import CustomInput from '@/UI/custom-input/CustomInput';
import CustomTextArea from '@/UI/custom-text-area/CustomTextArea';
import MediaInput from '@/UI/media-input/MediaInput';
import { useAppSelector } from '@/hooks/useRedux';

import classes from './CustomizationStep.module.css';

type Props = {
    onSubmit: (formData: any) => void;
    className: string;
};

const CustomizationStep: FC<Props> = props => {
    const bannerRef = useRef();
    const profileRef = useRef();
    const chatBgRef = useRef();
    const nameRef = useRef();
    const descriptionRef = useRef();

    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const serverDetails = useAppSelector(
        state => state?.serverDataReducer?.serverData[activeServer],
    );

    const [canSubmit, setCanSubmit] = useState(false);
    const [mediaInputValue, setMediaInputValue] = useState([]);
    const [profileImage, setProfileImage] = useState([]);
    const [chatBgImage, setChatBgImage] = useState([]);
    const [defaultBanner, setDefaultBanner] = useState([]);
    const [defaultLogo, setDefaultLogo] = useState([]);
    const [defaultChatBg, setDefaultChatBg] = useState([]);
    const [serverName, setServerName] = useState('');
    const [serverDescription, setServerDescription] = useState('');

    const mediaChangeHandler = data => {
        setMediaInputValue(data);
        setDefaultBanner([]);
    };

    const profileImgChangeHandler = data => {
        setProfileImage(data);
        setDefaultLogo([]);
    };

    const chatBgChangeHandler = data => {
        setChatBgImage(data);
        setDefaultChatBg([]);
    };

    const removeBannerHandler = () => {
        setMediaInputValue([]);
    };

    const removeHandler = () => {
        setProfileImage([]);
    };

    const removeChatBgHandler = () => {
        setChatBgImage([]);
    };

    const submitNewServerHandler = event => {
        event.preventDefault();
        const formData = {
            serverBanner: mediaInputValue,
            serverLogo: profileImage,
            serverName: serverName,
            serverDescription: serverDescription,
            serverChatBg: chatBgImage,
        };
        props.onSubmit(formData);
    };

    useEffect(() => {
        if (
            serverName !== '' &&
            serverDescription !== '' &&
            mediaInputValue.length > 0 &&
            profileImage.length > 0
        ) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [
        serverName,
        mediaInputValue,
        chatBgImage,
        profileImage,
        serverDescription,
    ]);

    useEffect(() => {
        if (serverDetails?.banner) {
            setMediaInputValue([serverDetails.banner]);
            setDefaultBanner([serverDetails.banner]);
        }
    }, [serverDetails.banner]);

    useEffect(() => {
        if (serverDetails?.logo) {
            setProfileImage([serverDetails.logo]);
            setDefaultLogo([serverDetails.logo]);
        }
    }, [serverDetails.logo]);

    useEffect(() => {
        if (serverDetails?.chat_bg) {
            setChatBgImage([serverDetails.chat_bg]);
            setDefaultChatBg([serverDetails.chat_bg]);
        }
    }, [serverDetails.chat_bg]);

    useEffect(() => {
        if (serverDetails?.description) {
            setServerDescription(serverDetails.description);
        }
    }, [serverDetails.description]);

    useEffect(() => {
        if (serverDetails?.name) {
            setServerName(serverDetails.name);
        }
    }, [serverDetails.name]);

    return (
        <form onSubmit={submitNewServerHandler}>
            <div className={classes['form-wrap']}>
                <div className="relative">
                    <div className={classes['media-container']}>
                        <MediaInput
                            wrapClass={classes['server-banner']}
                            className={classes['media']}
                            ref={bannerRef}
                            onChange={mediaChangeHandler}
                            onRemoveMedia={removeBannerHandler}
                            accept="image/jpg, image/png, image/jpeg"
                            passDefaultLink={
                                defaultBanner.length > 0
                                    ? defaultBanner
                                    : undefined
                            }
                        />
                    </div>
                    <MediaInput
                        wrapClass={classes['profile-img']}
                        mini
                        onChange={profileImgChangeHandler}
                        ref={profileRef}
                        onRemoveMedia={removeHandler}
                        className={classes['server-img']}
                        accept="image/jpg, image/png, image/jpeg"
                        passDefaultLink={
                            defaultLogo.length > 0 ? defaultLogo : undefined
                        }
                    />
                </div>
                <div className={classes['grid']}>
                    <div className={classes['chat-img']}>
                        <p>
                            <Trans>Default Chat Background Image</Trans>
                        </p>
                        <div className={classes['media-container']}>
                            <MediaInput
                                className={classes['media']}
                                onChange={chatBgChangeHandler}
                                onRemoveMedia={removeChatBgHandler}
                                ref={chatBgRef}
                                accept="image/jpg, image/png, image/jpeg"
                                passDefaultLink={
                                    defaultChatBg.length > 0
                                        ? defaultChatBg
                                        : undefined
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <CustomInput
                            ref={nameRef}
                            type={'text'}
                            placeholder={'Server Name'}
                            className={classes['server-name']}
                            label={t`Server Name`}
                            value={serverName}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                setServerName(event.target.value)
                            }
                        />
                    </div>
                    <div>
                        <CustomTextArea
                            ref={descriptionRef}
                            label={t`About Server`}
                            name={'server-description-input'}
                            id={'serverDescriptionInput'}
                            className={classes['text-area']}
                            placeholder="Informative content about the server"
                            value={serverDescription}
                            onChange={event =>
                                setServerDescription(event.target.value)
                            }
                        />
                    </div>
                </div>
                <AppButton
                    title={t`Save`}
                    type="submit"
                    classStyles={classes['btn']}
                    disable={!canSubmit}
                />
            </div>
        </form>
    );
};
export default CustomizationStep;
