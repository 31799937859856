import { useEffect, useState } from 'react';

import { blockUserToServer, unblockUserToServer } from '@/api/member';

import useCustomSweetAlert from './useCustomSweetAlert';
import { useAppDispatch, useAppSelector } from './useRedux';

type MemberTableProps = {
    mainMemberData?: any[];
    tabData?: {
        id: number;
        value: string;
        label: string;
    }[];
    checkboxData?: any[];
};

const useMemberTable = (props?: MemberTableProps) => {
    const dispatch = useAppDispatch();
    const myUser = useAppSelector(state => state.userReducer.user);
    let subserver_id = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const [sortType, setSortType] = useState('');
    const [tabData, setTabData] = useState(props?.tabData);
    const [mainMemberData, setMainMemberData] = useState(props?.mainMemberData);
    const [filteredMemberData, setFilteredMemberData] = useState(
        props?.mainMemberData,
    );

    const [searchText, setSearchText] = useState('');

    const checkboxNoRoleFilterItem = {
        _id: 'no-role-id',
        name: 'No Role',
        slug: 'no-role',
        color: '#555555',
        priority: 3,
        display_separately: false,
    };
    const [checkboxData, setCheckboxData] = useState([
        checkboxNoRoleFilterItem,
        ...props?.checkboxData,
    ]);
    const [selectedCheckboxData, setSelectedCheckboxData] = useState([]);
    const [isOpencheckboxModal, setIsOpenCheckboxModal] = useState(false);

    const [selectedTabItem, setSelectedTabItem] = useState(tabData?.[0]);

    const [sortToggle, setSortToggle] = useState(false);

    const { successAlertModal, errorAlertModal } = useCustomSweetAlert();

    // REAL TIME
    useEffect(() => {
        setTabData(props?.tabData);
        setMainMemberData(props?.mainMemberData);
        setCheckboxData([checkboxNoRoleFilterItem, ...props?.checkboxData]);
    }, [props?.mainMemberData]);

    useEffect(() => {
        const filteredProps = props?.mainMemberData
            .map(item => filteredMemberData?.find(el => el?.id === item?.id))
            .filter(Boolean);

        if (filteredMemberData.length < filteredProps.length) {
            setFilteredMemberData(props?.mainMemberData);
        } else {
            const newData = filteredMemberData
                ?.map(item =>
                    props?.mainMemberData?.find(el => el?.id === item?.id),
                )
                .filter(Boolean);
            setFilteredMemberData(newData);
            sortDataWithState(sortType, sortToggle, newData);
        }
    }, [props?.mainMemberData]);

    useEffect(() => {
        let filteredData = mainMemberData;

        // ROLE & NO ROLE
        if (selectedCheckboxData && selectedCheckboxData.length > 0) {
            filteredData = filteredData?.filter(item => {
                const noRoleSelected = selectedCheckboxData.some(
                    role => role._id === 'no-role-id',
                );
                const hasRoles = item?.roles?.length > 0;

                if (noRoleSelected && !hasRoles) {
                    return true;
                }

                return item?.roles?.some(roleId =>
                    selectedCheckboxData.some(role => role?._id === roleId),
                );
            });
        }

        // TAB
        filteredData = filteredData?.filter(itemState => {
            if (selectedTabItem?.value === 'all-member') {
                return true && !itemState?.banned;
            } else if (selectedTabItem?.value === 'online-member') {
                return itemState?.state === true && !itemState?.banned;
            } else if (selectedTabItem?.value === 'offline-member') {
                return (
                    itemState?.state === false ||
                    (itemState?.state === undefined && !itemState?.banned)
                );
            } else if (selectedTabItem?.value === 'banned-member') {
                return itemState?.banned === true;
            }
            return false;
        });

        // SEARCH
        filteredData = filteredData?.filter(item => {
            const usernameMatch = item?.username
                ?.toLowerCase()
                .includes(searchText?.toLowerCase());
            const nameMatch = item?.name
                ?.toLowerCase()
                .includes(searchText?.toLowerCase());

            return usernameMatch || nameMatch;
        });
        setFilteredMemberData(filteredData);
        sortDataWithState(sortType, sortToggle, filteredData);
    }, [selectedTabItem, searchText, selectedCheckboxData, mainMemberData]);

    // FILTER DATA

    // ROLE FILTER
    const isRoleSelected = item => {
        return selectedCheckboxData?.some(
            checkboxItem => checkboxItem._id === item._id,
        );
    };

    const onChangeCheckbox = item => {
        if (
            selectedCheckboxData?.some(
                checkboxItem => checkboxItem._id === item._id,
            )
        ) {
            setSelectedCheckboxData(
                selectedCheckboxData?.filter(role => role._id !== item._id),
            );
        } else {
            setSelectedCheckboxData([...selectedCheckboxData, item]);
        }
    };

    const allSelectCheckbox = () => {
        setSelectedCheckboxData(checkboxData);
    };

    const allClearCheckbox = () => {
        setSelectedCheckboxData([]);
    };
    // ROLE FILTER

    // TAB CHANGE
    const onChangeTab = item => {
        setSelectedTabItem(item);
    };
    // TAB CHANGE

    // HANDLE SEARCH
    const handleSearchInput = text => {
        setSearchText(text);
    };
    // HANDLE SEARCH

    // USER BAN
    const handleMemberServerBlockUpdate = async userId => {
        const payload = {
            userId: userId,
            subserverId: subserver_id,
            currentId: myUser?.id,
        };
        const res = await dispatch(blockUserToServer(payload));
        if (res?.payload?.data?.success) {
            successAlertModal();
        } else {
            errorAlertModal(null, res?.payload?.error);
        }
    };
    // USER BAN

    // USER UNBAN
    const handleMemberServerUnblockUpdate = async userId => {
        const payload = {
            userId: userId,
            subserverId: subserver_id,
            currentId: myUser?.id,
        };
        const res = await dispatch(unblockUserToServer(payload));
        if (res?.payload?.data?.success) {
            successAlertModal();
        } else {
            errorAlertModal(null, res?.payload?.error);
        }
    };
    // USER UNBAN

    const sortDataWithState = (
        key: string,
        tempSortToggle: boolean,
        newData?: any[],
    ) => {
        let sortData = [];
        if (newData) {
            sortData = [...newData];
        } else {
            sortData = [...filteredMemberData];
        }
        const keyType = typeof sortData[0]?.[key];
        if (key === 'state') {
            if (tempSortToggle) {
                sortData = sortData.sort((a, b) => {
                    if ('state' in a === 'state' in b) {
                        return 0;
                    }
                    if ('state' in a) {
                        return -1;
                    }
                    if ('state' in b) {
                        return 1;
                    }
                    return 0;
                });
            } else {
                sortData.reverse();
            }
        }
        if (tempSortToggle) {
            if (keyType === 'string') {
                sortData = sortData?.sort((a, b) =>
                    a[key]?.localeCompare(b[key]),
                );
            }

            if (keyType === 'object')
                sortData = sortData?.sort(
                    (a, b) => b[key]?.length - a[key]?.length,
                );
        } else {
            if (keyType === 'string') {
                sortData = sortData?.sort((a, b) =>
                    b[key]?.localeCompare(a[key]),
                );
            }

            if (keyType === 'boolean') {
                sortData = sortData?.sort((a, b) => a[key] - b[key]);
            }

            if (keyType === 'object') {
                sortData = sortData?.sort(
                    (a, b) => a[key]?.length - b[key]?.length,
                );
            }
        }
        setFilteredMemberData(sortData);
    };

    const handleSortData = (key: string) => {
        const tempSortToggle = !sortToggle;
        tempSortToggle ? setSortType(key) : setSortType('');
        setSortToggle(prev => !prev);
        sortDataWithState(key, tempSortToggle);
    };

    return {
        filteredMemberData,
        searchText,
        isOpencheckboxModal,
        setIsOpenCheckboxModal,
        isRoleSelected,
        onChangeCheckbox,
        handleSearchInput,
        allClearCheckbox,
        allSelectCheckbox,
        setCheckboxData,
        checkboxData,
        handleMemberServerUnblockUpdate,
        handleMemberServerBlockUpdate,
        handleSortData,
        onChangeTab,
        checkedRolesFilterCount: selectedCheckboxData.length,
    };
};

export default useMemberTable;
