import { AnimatePresence } from 'framer-motion';
import { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { mobileViewActions } from '@/store/mobile-view-slice';
import {
    activeChannelIdSelector,
    channelDataDetailsSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import useHomeContent from '../HomeContent/useHomeContent';
import Notification from '../Notification/Notification';
import ChatAreaSection from '../chat-area-section/ChatAreaSection';
import EmptyChatArea from '../chat-area-section/EmptyChatArea';
import Connections from '../connections-section/Connections';
import MessageThreadChat from '../message-thread-chat/MessageThreadChat';
import NotesStore from '../notes/NotesStore';
import ServerSection from '../server-details-section/ServerSection';

import MobileContentMotion from './MobileContentMotion';

type IProps = {};

const MobileViewTabsContent: React.FC<IProps> = () => {
    const { createNewChatTemplate, chatAreaSectionConfig } = useHomeContent();
    const dispatch = useAppDispatch();

    const activeMobileTab = useAppSelector(
        state => state.mobileViewReducer.activeTab,
    );
    const channelList = useAppSelector(
        state => state.serverDataReducer.channelList,
    );
    const welcomeScreenValue = useAppSelector(
        state => state.utilReducer.welcomeScreen,
    );

    const callbackHandler = useCallback(
        (id: string) => createNewChatTemplate([id]),
        [],
    );
    const isThreadOpen = useAppSelector(
        state => state.threadsReducer.isOpenThread,
    );
    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const channelDetails: any = useAppSelector(channelDataDetailsSelector);
    const focusNotification = useAppSelector(
        state => state.utilReducer.focusNotification,
    );
    useEffect(() => {
        if (!isThreadOpen && activeMobileTab === 4) {
            if (activeChannelId) {
                dispatch(mobileViewActions.changeActiveTab(1));
            } else {
                dispatch(mobileViewActions.changeActiveTab(0));
            }
        }
    }, [isThreadOpen]);
    const userId = useAppSelector(state => state.userReducer.user?.id);
    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );
    const isUserExternal = memberList?.[userId]?.external;

    return (
        <AnimatePresence>
            {((activeMobileTab === 0 && channelList) ||
                (activeMobileTab === 0 && welcomeScreenValue)) && (
                <MobileContentMotion
                    initial={{ opacity: 0, x: -50 }}
                    animate={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 0.4 },
                    }}
                    exit={{ opacity: 0, x: '-100%' }}>
                    <ServerSection onNewChat={callbackHandler} />
                </MobileContentMotion>
            )}
            {activeMobileTab === 1 && (
                <MobileContentMotion
                    initial={{ opacity: 0, x: 30 }}
                    animate={{
                        opacity: 1,
                        x: 0,
                        transition: { delay: 0.5, duration: 0.5 },
                    }}
                    exit={{ opacity: 0, x: -30 }}>
                    {!activeChannelId ? (
                        <EmptyChatArea />
                    ) : (
                        <>
                            <ChatAreaSection
                                onNewChat={callbackHandler}
                                {...chatAreaSectionConfig}
                            />
                            {focusNotification && (
                                <Notification alignToParent="header-bell-icon" />
                            )}
                        </>
                    )}
                </MobileContentMotion>
            )}
            {activeMobileTab === 2 && (
                <MobileContentMotion
                    initial={{ opacity: 0, x: -50 }}
                    animate={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 0.4 },
                    }}
                    exit={{ opacity: 0, x: '-100%' }}>
                    <NotesStore />
                </MobileContentMotion>
            )}
            {activeMobileTab === 3 &&
                activeChannelId &&
                channelDetails.privacy !== 'direct' &&
                isUserExternal === 0 && (
                    <MobileContentMotion
                        initial={{ opacity: 0, x: -50 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            transition: { duration: 0.4 },
                        }}
                        exit={{ opacity: 0, x: '-100%' }}>
                        <Connections />
                    </MobileContentMotion>
                )}
            {activeMobileTab === 4 && isThreadOpen && (
                <MobileContentMotion
                    initial={{ opacity: 0, x: -50 }}
                    animate={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 0.4 },
                    }}
                    exit={{
                        opacity: 0,
                        x: '-100%',
                    }}
                    transition={{ delay: 0.2 }}>
                    <MessageThreadChat handleNewChat={callbackHandler} />
                </MobileContentMotion>
            )}
        </AnimatePresence>
    );
};

export default MobileViewTabsContent;
