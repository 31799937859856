import { ChangeEvent } from 'react';

import { truncateText } from '@/Utils/textUtils';
import { ReactComponent as CheckIcon } from '@/assets/icons/check.svg';
import { IRole } from '@/types';

import classes from './ForwardNote.module.css';

interface IProps {
    item: IRole;
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
const RolesListItem: React.FC<IProps> = props => {
    const { checked, item, onChange } = props;
    return (
        <div className={classes['body-item-wrap']}>
            <li className={classes['body-item']}>
                <input
                    checked={checked}
                    value={item._id}
                    onChange={onChange}
                    type="checkbox"
                    className={classes['select']}
                />
                <div className={classes['index']}>
                    <CheckIcon />
                </div>
                {truncateText(item.name || '---', 25)}
            </li>
        </div>
    );
};
export default RolesListItem;
