import compressImage from './compressImages';

const processMedia = async (filesObject: FileList | File[]) => {
    if (!filesObject?.length || filesObject?.length === 0) {
        return;
    }
    let mediaArray = [];

    for (let i = 0; i < filesObject.length; i++) {
        if (!filesObject[i] || !filesObject[i]?.type) {
            return;
        }
        let itemType = '';

        if (filesObject[i].type.startsWith('image/')) {
            const compressedFile = await compressImage(filesObject[i]);
            mediaArray.push({
                lastModified: compressedFile.lastModified,
                lastModifiedDate: compressedFile.lastModifiedDate,
                name: compressedFile.name,
                size: compressedFile.size,
                type: compressedFile.type,
                src: URL.createObjectURL(compressedFile),
                file: compressedFile,
            });
        } else {
            itemType = filesObject[i].type;
            if (filesObject[i].type && filesObject[i].type?.startsWith('video/')) {
                itemType = filesObject[i].type ?? 'video/mp4';
            }

            const blob = new Blob([filesObject[i]], {
                type: filesObject[i].type,
            });
            const blobUrl = URL.createObjectURL(blob);

            mediaArray.push({
                lastModified: filesObject[i].lastModified,
                lastModifiedDate: filesObject[i]?.lastModified,
                name: filesObject[i].name,
                size: filesObject[i].size,
                type: itemType,
                src: blobUrl,
                file: filesObject[i],
            });
        }
    }
    return {
        mediaArray,
    };
};
export default processMedia;
