import { FC, useEffect, useRef } from 'react';
import { ViewportList } from 'react-viewport-list';

import {
    getThreadListScroll,
    handleOpenThread,
} from '@/Utils/socket-actions/ThreadActions/threadActions';
import useClickOutside from '@/hooks/useClickOutside';
import { useAppSelector } from '@/hooks/useRedux';
import { threadListDataSelector } from '@/store/thread-reducer/threadSelector';
import { IMessageData } from '@/types';

import classes from './ThreadList.module.css';
import ThreadListItem from './ThreadListItem';
import ThreadsListHeader from './ThreadsListHeader';

type IThread = {
    _id: string;
    lastMessage: IMessageData;
    messageCount: number | null | undefined;
    thread_name: null | string;
};
type IProps = {
    setIsThreadList;
};
export const ThreadList: FC<IProps> = props => {
    const listContainerRef = useRef(null);
    const { setIsThreadList } = props;
    const data = useAppSelector(state => state.threadsReducer.metaData);
    const threadList = useAppSelector(state => threadListDataSelector(state));
    let subserverId = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    let metaData = data[subserverId];
    const thridItems = threadList.sort((a: IThread, b: IThread) => {
        const dateA = new Date(a?.lastMessage?.created_at);
        const dateB = new Date(b?.lastMessage?.created_at);
        return dateB - dateA;
    });
    const handleClickThread = (threadId, channelId) => {
        handleOpenThread(threadId, channelId);
        setIsThreadList(false);
    };

    useEffect(() => {
        const handleClickOutside = event => {
            if (
                listContainerRef.current &&
                !listContainerRef.current.contains(event.target)
            ) {
                setIsThreadList(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const ulElement = listContainerRef.current;
        const handleScrolling = () => {
            if (
                listContainerRef &&
                Math.abs(Math.ceil(ulElement.scrollTop)) ===
                    Math.ceil(
                        ulElement.scrollHeight -
                            ulElement.getBoundingClientRect().height,
                    ) &&
                metaData?.current_page_number !== metaData?.total_pages
            ) {
                if (listContainerRef.current) {
                    listContainerRef.current.style.scrollBehavior = 'auto';
                }
                getThreadListScroll(metaData?.current_page_number + 1, 20);
            }
        };

        ulElement?.addEventListener('scroll', handleScrolling);

        return () => {
            ulElement?.removeEventListener('scroll', handleScrolling);
        };
    }, [metaData]);

    return (
        <div className={classes['main-container']}>
            <ThreadsListHeader count={metaData?.total_thread} />
            <div ref={listContainerRef} className={classes['list-container']}>
                <ViewportList
                    items={thridItems}
                    initialAlignToTop={true}
                    overscan={10}
                    itemMargin={12}
                    initialOffset={0}
                    axis="y">
                    {(item, index) => (
                        <ThreadListItem
                            key={index}
                            handleClickThread={handleClickThread}
                            val={item}
                            index={index}
                        />
                    )}
                </ViewportList>
            </div>
        </div>
    );
};
