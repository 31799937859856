import { Trans } from '@lingui/macro';

import { TextInput } from '@/UI';
import calender from '@/assets/icons/calendar.svg';

import classes from '../AuditLogManagement.module.css';

type Props = {
    onChangeStartDate: (e) => void;
    onChangeEndDate: (e) => void;
    startDate: any;
    endDate: any;
};

function AuditLogDateFilter(props: Props) {
    return (
        <div className={classes['date-filter-container']}>
            <div className="">
                <TextInput
                    style={{ width: 140 }}
                    icon={calender}
                    containerStyle={{ position: 'relative' }}
                    type="date"
                    title={
                        <b className={classes['date-label']}>
                            <Trans>First Date</Trans>
                        </b>
                    }
                    onChange={e => props.onChangeStartDate(e)}
                    value={props.startDate}
                />
            </div>
            <div className="">
                <TextInput
                    style={{ width: 140 }}
                    icon={calender}
                    containerStyle={{ position: 'relative' }}
                    type="date"
                    title={
                        <b className={classes['date-label']}>
                            <Trans>End Date</Trans>
                        </b>
                    }
                    onChange={e => props.onChangeEndDate(e)}
                    value={props.endDate}
                />
            </div>
        </div>
    );
}

export default AuditLogDateFilter;
