import { useCallback, useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';

import { onDecryptMessage } from '@/Utils/onDecryptMessage';
import {
    deleteReaction,
    reactionCreate,
    wsEditMessage,
} from '@/Utils/socket-actions/MessageActions/messageActions';
import {
    CreateThreadReaction,
    deleteThreadReaction,
    threadEditMessage,
} from '@/Utils/socket-actions/ThreadActions/threadActions';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { updateMessage } from '@/store/messages-reducer/messages-reducer';
import { updateThreadMessage } from '@/store/thread-reducer/threadsSlice';

type Props = {};

const useMessageItem = (props: any) => {
    const { item, onReplyMessage } = props;
    const { message, user_id, _id, is_encrypt } = item;

    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );

    const strangerMemberList = useAppSelector(
        state =>
            state.serverDataReducer.strangerMemberList[
                state.serverDataReducer.activeServer
            ],
    );

    // OWNER

    let owner = memberList?.[user_id] || strangerMemberList?.[user_id];

    // const [isActionsShown, setIsActionsShown] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedMessage, setEditedMessage] = useState(message);
    const [emojiIsShown, setEmojiIsShown] = useState(false);
    const [isReplyMsg, setIsReplyMsg] = useState(false);
    const [messageUser, setMessageUser] = useState({});
    const [isForward, setIsForward] = useState(false);
    const [isMediaView, setIsMediaView] = useState({
        state: false,
        data: [],
        initialSlide: 0,
    });
    let userData = useAppSelector(state => state.userReducer.user.id);
    const { lastActiveThread } = useAppSelector(state => state?.threadsReducer);
    let subserverId = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );
    const dispatch = useAppDispatch();
    const cryptoKeys = useAppSelector(state => state.cryptoKeyReducer);
    const updateDecryption = async () => {
        const privateKey = cryptoKeys?.[item.key_hash]?.privateKey;
        let decryptMessage: any = item.message;
        try {
            decryptMessage = await onDecryptMessage(item, privateKey);
        } catch (error) {
            return console.error(error);
        }
        if (decryptMessage.is_encrypt) return;

        if (item?.thread_id) {
            dispatch(
                updateThreadMessage({
                    ...item,
                    ...decryptMessage,
                    is_encrypt: false,
                }),
            );
        } else {
            dispatch(
                updateMessage({
                    ...item,
                    ...decryptMessage,
                    is_encrypt: false,
                }),
            );
        }
    };

    useEffect(() => {
        if (is_encrypt && item.key_hash) updateDecryption();
    }, [cryptoKeys]);

    const replyMessageHandler = useCallback(() => {
        let config: {} = {
            replyfor: owner?.name ?? '--USER--',
            id: _id,
            user_id: user_id,
            messageText: message,
            type: item.type,
            key_hash: item?.key_hash,
            sender_message_id: item.sender_message_id,
        };
        if (item.type === 'media') {
            config = { ...config, mediaFiles: item?.mediaFiles };
        }
        onReplyMessage(config);
    }, []);

    const clickReactionHandler = useCallback(
        (event: string, item, inputType) => {
            const foundEmoji = props.item.reactions?.find(
                el => el.emoji === event,
            );
            // is clicked emoji there in the reactions array
            if (foundEmoji) {
                let userReacted = { _id: '', state: false };
                foundEmoji.list.forEach(listItem => {
                    if (listItem.user_id === userData) {
                        userReacted = { _id: listItem._id, state: true };
                        return;
                    }
                });

                if (userReacted.state === true) {
                    inputType === 'threadInput'
                        ? deleteThreadReaction(
                              userReacted?._id,
                              lastActiveThread.channel_id,
                              lastActiveThread.id,
                          )
                        : deleteReaction(userReacted._id, props.item);
                } else {
                    inputType === 'threadInput'
                        ? CreateThreadReaction(
                              props.item._id,
                              subserverId,
                              lastActiveThread?.channel_id,
                              lastActiveThread.id,
                              event,
                          )
                        : reactionCreate({
                              message_id: props.item._id,
                              emoji: event,
                              messageItem: props.item,
                          });
                }
            } else {
                inputType === 'threadInput'
                    ? CreateThreadReaction(
                          props.item._id,
                          subserverId,
                          lastActiveThread?.channel_id,
                          lastActiveThread.id,
                          event,
                      )
                    : reactionCreate({
                          message_id: props.item._id,
                          emoji: event,
                          messageItem: props.item,
                      });
            }
        },
        [props.item],
    );

    const editMessageHandler = useCallback(() => {
        setIsEditing(true);
        // setIsActionsShown(false);
    }, []);

    const forwardMsgHandler = useCallback(() => {
        setIsForward(true);
    }, []);

    const copyMsgHandler = useCallback(() => {
        let message = sanitizeHtml(
            item?.message == 'object' ? item?.message?.content : item?.message,
            {
                allowedTags: ['br'],
            },
        );
        const copyMessageWithOwner =
            'from: ' + `${owner?.name}` + '\n\nmessage: ' + `${message}`;
        navigator.clipboard.writeText(copyMessageWithOwner ?? '');
    }, []);

    const handleKeyPress = (event: any, inputType: any) => {
        if (event.key === 'Enter') {
            if (inputType === 'threadInput') {
                threadEditMessage(
                    { id: _id, message: event.target.value },
                    lastActiveThread.id,
                    lastActiveThread.channel_id,
                    subserverId,
                );
            } else {
                wsEditMessage({ id: _id, message: event.target.value });
            }
            setIsEditing(false);
        }
    };

    const viewMediaHandler = (data, key) => {
        setIsMediaView({ state: true, data: data, initialSlide: key });
    };

    const closeMediaHandler = useCallback(() => {
        setIsMediaView({ state: false, data: [], initialSlide: 0 });
    }, []);

    return {
        owner,
        isEditing,
        editedMessage,
        setEditedMessage,
        emojiIsShown,
        setEmojiIsShown,
        isReplyMsg,
        setIsReplyMsg,
        messageUser,
        setMessageUser,
        replyMessageHandler,
        clickReactionHandler,
        editMessageHandler,
        handleKeyPress,
        forwardMsgHandler,
        copyMsgHandler,
        isForward,
        setIsForward,
        isMediaView,
        viewMediaHandler,
        closeMediaHandler,
    };
};

export default useMessageItem;
