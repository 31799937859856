import { useEffect, useMemo } from 'react';

import { decryptDataWithRSA } from '@/Utils/cryptoUtils';
import { getStoreState } from '@/Utils/dataHandler';
import { onDecryptMessage } from '@/Utils/onDecryptMessage';
import { getChannelList_activeServer } from '@/Utils/serverDataHandlers';
import { onJoinChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import { sendSearchMessagesDesktop } from '@/Utils/socket-actions/MessageActions/messageActions';
import { handleOpenThread } from '@/Utils/socket-actions/ThreadActions/threadActions';
import { triggerScroll } from '@/Utils/targetMessageService';
import { checkPlatform } from '@/Utils/utils';
import {
    isWebSocketConnected,
    sendDesktopNotificationSocketData,
    setDesktopNotificationHandler,
    setNotificationsEventHandler,
} from '@/Utils/webSocketUtils';
import { fetchStrangeUserData } from '@/api/member';
import { PLATFORM } from '@/constants/enum';
import { RootState } from '@/store';
import { notificationsActions } from '@/store/Notifications-count';
import { addMessage } from '@/store/messages-reducer/messages-reducer';
import {
    getServerData,
    setActiveServer,
    updateProfileMember,
    updateServer,
} from '@/store/server-data-reducer/server-data-reducer';
import { openThreadAction } from '@/store/thread-reducer/threadsSlice';
import { updateUserData } from '@/store/user-reducer';
import { IServerData } from '@/types';

import { useAppDispatch, useAppSelector } from './useRedux';

var sentDesktopNotificationData = [];

const useNotificationServerHelper = () => {
    const dispatch = useAppDispatch();
    const serverListObject = useAppSelector(
        state => state.serverDataReducer.serverData,
    );
    const userSelector = useAppSelector(state => state.userReducer.user);
    const allServerData = useAppSelector(
        ({ serverDataReducer }) => serverDataReducer?.serverData,
    );
    const userId = useAppSelector(state => state.userReducer.user.id);
    const serverData: IServerData = useAppSelector(
        ({ serverDataReducer }) =>
            serverDataReducer?.serverData[serverDataReducer?.activeServer],
    );
    const memoServerData = useMemo(() => serverData, [serverData]);

    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );
    const updateServersListHandler = serverObject => {
        dispatch(updateServer(serverObject));
    };

    const handleprofileUpdate = data => {
        dispatch(updateProfileMember(data));
        if (data.id === userId) {
            dispatch(updateUserData(data));
        }
    };

    // const handleProfileStatusUpdate = data => {
    //     dispatch(updateProfileMember(data));
    //     const findMe = data.find(item => item?.id === userId);
    //     if (findMe?.id) {
    //         dispatch(updateUserData(findMe));
    //     }
    // };

    const serverListUpdateHandler = () => {
        dispatch(getServerData());
    };

    const handleNotifications = async (data: any) => {
        const channelList = Object.values(
            getChannelList_activeServer(data.subserver_id) ?? {},
        );
        const allMessage = channelList.find(
            item =>
                item._id == data.channel_id && item.notification_state === 1,
        );
        const isOnlyMentioned = channelList.find(
            item =>
                item._id == data.channel_id && item.notification_state === 2,
        );
        const messagesArray = [
            {
                message_id: data._id,
                sender_id: data.user_id,
                channel_id: data.channel_id,
                text: data.message,
                created_at: data.created_at,
                token: data.token,
            },
        ];

        sendSearchMessagesDesktop(messagesArray);

        try {
            let decryptMessage;
            let decryptChannelName;
            if (data?.message) {
                decryptMessage = await onDecryptMessage(
                    data,
                    serverListObject[data.subserver_id].privateKey,
                );
            }
            if (data?.channel_name) {
                decryptChannelName = await decryptDataWithRSA(
                    data.channel_name,
                    serverListObject[data.subserver_id].privateKey,
                );
            }
            const newdata = {
                ...data,
                ...decryptMessage,
                channel_name:
                    decryptChannelName?.ciphertext ??
                    decryptChannelName ??
                    data?.channel_name,
            };

            const store: RootState = getStoreState();

            const strangerMemberList =
                store.serverDataReducer.strangerMemberList[
                    store.serverDataReducer.activeServer
                ];

            let userResponse = {};
            if (strangerMemberList && strangerMemberList?.[data.user_id]) {
                userResponse = strangerMemberList?.[data.user_id];
            } else {
                const fetchStrangerUser = await fetchStrangeUserData({
                    user_id: data.user_id,
                });
                userResponse = fetchStrangerUser.data;
            }

            const isMentioned =
                decryptMessage?.mention_id?.find(
                    item => item.user_id == userId,
                ) ||
                decryptMessage?.role_mentions?.includes(item => item == userId);

            const mentionEveryone = data.everyone;

            if (!data?.thread_id && !data?.emoji) {
                dispatch(addMessage(newdata));
            }
            if (userSelector?.status === 1 || userSelector?.status === 3) {
                if (
                    allMessage ||
                    (isOnlyMentioned && (isMentioned || mentionEveryone))
                ) {
                    const platform = checkPlatform();
                    if (platform === PLATFORM.DESKTOP) {
                        sendDesktopNotificationSocketData(
                            newdata,
                            allServerData[data.subserver_id],
                            userResponse,
                        );
                    } else {
                        dispatch(notificationsActions.addNewToast(newdata));
                    }

                    sentDesktopNotificationData = [
                        ...sentDesktopNotificationData,
                        newdata,
                    ];
                }
            }
        } catch (error) {
            console.error(
                'error with try decrypt function "00000" ---->',
                error,
            );
        }
    };

    const handleUpdateServerCount = (data: {
        subserver_id: string;
        total_count: number;
        private_count: string;
    }) => {
        dispatch(
            updateServer({
                id: data.subserver_id,
                total_count: data.total_count,
                private_count: data.private_count,
            }),
        );
    };
    const joinDesktopChannelHandler = (Id: string) => {
        try {
            window.require('electron').ipcRenderer.send('onclickfocus');
        } catch (e) {}

        const targetMessage = sentDesktopNotificationData.find(
            item => item._id === Id,
        );

        if (targetMessage) {
            if (targetMessage.subserver_id.trim() !== activeServer) {
                dispatch(setActiveServer(targetMessage.subserver_id));
                setTimeout(() => {
                    if (isWebSocketConnected()) {
                        if (targetMessage?.thread_id) {
                            handleOpenThread(targetMessage?.thread_id);
                            dispatch(
                                openThreadAction({
                                    _id: targetMessage?.thread_id,
                                    channel_id: targetMessage?.channel_id,
                                    message_id: targetMessage?.message_id,
                                    // name:data?.thread_name
                                }),
                            );
                        } else {
                            isWebSocketConnected() &&
                                onJoinChannel(targetMessage.channel_id);
                        }
                    }
                }, 400);
                setTimeout(() => {
                    triggerScroll(targetMessage);
                }, 500);
            } else {
                if (targetMessage?.thread_id) {
                    handleOpenThread(targetMessage?.thread_id);
                    dispatch(
                        openThreadAction({
                            _id: targetMessage?.thread_id,
                            channel_id: targetMessage?.channel_id,
                            message_id: targetMessage?.message_id,
                            // name:data?.thread_name
                        }),
                    );
                } else {
                    isWebSocketConnected() &&
                        onJoinChannel(targetMessage.channel_id);
                }
                setTimeout(() => {
                    triggerScroll(targetMessage);
                }, 300);
            }
        }
    };
    const notificationEventHandler = async (event: MessageEvent) => {
        let data = event.data;

        data = JSON.parse(data);
        const action = data.event;

        const actionHandlers = {
            ['update_server']: () => {
                updateServersListHandler(data.data);
            },
            ['notification']: () => {
                handleNotifications(data.data);
            },
            ['profile_update']: () => {
                handleprofileUpdate(data.data);
            },
            ['server_list_update']: () => {
                serverListUpdateHandler();
            },
            ['server_msg_count_update']: () => {
                handleUpdateServerCount(data.data);
            },
        };

        const actionHandler = actionHandlers[action];
        if (actionHandler) {
            actionHandler();
        }
    };

    const desktopNotificationHandler = (event: MessageEvent) => {
        const { Id, Type } = JSON.parse(event.data);
        const actionHandlers = {
            ['click']: () => {
                joinDesktopChannelHandler(Id);
            },
        };

        const actionHandler = actionHandlers[Type];
        if (actionHandler) {
            actionHandler();
        }
    };
    useEffect(() => {
        setNotificationsEventHandler(notificationEventHandler);
    }, [memoServerData]);

    useEffect(() => {
        setDesktopNotificationHandler(desktopNotificationHandler);
    }, [memoServerData]);

    return {};
};

export default useNotificationServerHelper;
