import { EmojiClickData } from 'emoji-picker-react';
import React, { ChangeEvent, ReactNode, createContext, useState } from 'react';

import { mediaFileTypes, mediaProp } from './AttachmentTypes';

interface ContextProps {
    isBtnDisabled: boolean;
    emojiIsShown: boolean;
    msgInputValue: string;
    mediaInputValue: mediaFileTypes[];
    isLoading: boolean;
    secondClicker: boolean;
    toggleLoading: (status: boolean) => void;
    toggleBtnDisability: (status: boolean) => void;
    toggleSecondClick: (status: boolean) => void;
    inputChangeHanlder: (event: ChangeEvent<HTMLInputElement>) => void;
    toggleEmojiBoardHandler: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => void;
    selectEmojiHandler: (emoji: EmojiClickData) => void;
    onInputFocus: () => void;
    mediaChangeHandler: (files: mediaProp[]) => void;
    removeHandler: (id: string) => void;
    clearModal: () => void;
}

const initialContextValues: ContextProps = {
    isBtnDisabled: true,
    emojiIsShown: false,
    msgInputValue: '',
    mediaInputValue: [],
    isLoading: false,
    secondClicker: false,
    toggleSecondClick: status => {},
    toggleLoading: status => {},
    toggleBtnDisability: status => {},
    inputChangeHanlder: event => {},
    toggleEmojiBoardHandler: event => {},
    selectEmojiHandler: emoji => {},
    onInputFocus: () => {},
    mediaChangeHandler: files => {},
    removeHandler: id => {},
    clearModal: () => {},
};

export const MediaMenuContext =
    createContext<ContextProps>(initialContextValues);

interface PropsTypes {
    children: ReactNode;
}

export const AttachementMenuProvider: React.FC<PropsTypes> = ({ children }) => {
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [emojiIsShown, setEmojiIsShown] = useState(false);
    const [msgInputValue, setMsgInputValue] = useState('');
    const [mediaInputValue, setMediaInputValue] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [secondClicker, setSecondClicker] = useState(false);

    const inputChangeHanlder = (event: ChangeEvent<HTMLInputElement>) => {
        setMsgInputValue(event.target.value);
    };

    const toggleEmojiBoardHandler = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
        setEmojiIsShown(prev => {
            return !prev;
        });
    };

    const selectEmojiHandler = (emoji: EmojiClickData) => {
        setMsgInputValue(prev => prev.concat(emoji.emoji));
    };

    const onInputFocus = () => {
        setEmojiIsShown(false);
    };

    const mediaChangeHandler = (files: mediaProp[]) => {
        setMediaInputValue(files);
    };

    const removeHandler = (id: string) => {
        setMediaInputValue(prev => prev.filter(e => e.id !== id));
    };
    const toggleBtnDisability = (status: boolean) => {
        setIsBtnDisabled(status);
    };
    const toggleLoading = (status: boolean) => {
        setIsLoading(status);
    };
    const toggleSecondClick = (status: boolean) => {
        setSecondClicker(status);
    };
    const clearModal = () => {
        setMsgInputValue('');
        setMediaInputValue([]);
    };
    const contextValues: ContextProps = {
        isBtnDisabled,
        toggleBtnDisability,
        emojiIsShown,
        msgInputValue,
        mediaInputValue,
        isLoading,
        secondClicker,
        toggleLoading,
        toggleSecondClick,
        inputChangeHanlder,
        toggleEmojiBoardHandler,
        selectEmojiHandler,
        onInputFocus,
        mediaChangeHandler,
        removeHandler,
        clearModal,
    };

    return (
        <MediaMenuContext.Provider value={contextValues}>
            {children}
        </MediaMenuContext.Provider>
    );
};
