import store from '.';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { fetchBannedToServer } from '@/api/member';
import { IServerData } from '@/types';

import { getMembersData } from './server-data-reducer/server-data-reducer';

type Props = {
    activeServer?: string;
    settingsModal: { visible: boolean; component?: string };
    messageLoader: boolean;
    decryptionLoader: boolean;
    migrationMetadata: any;
    isFetchSearchHeader: boolean;
    timeZone: string;
    channelListEditMode: boolean;
    searchLoading: boolean;
    welcomeScreen: boolean;
    focusNotification: boolean;
    everyoneChannelPermission: {};
    memberLoader: boolean;
    memberCountLoader: boolean;
    interServerError: string;
    interServerSuccess: boolean;
    pingRate: null | number | string;
    pingDate: number;
    pingsCount: number;
    pongsCount: number;
};

// const initialServer = store?.getState()?.serverDataReducer?.serverData[0] ?? {};

const initialState: Props = {
    timeZone: '',
    messageLoader: false,
    settingsModal: { visible: false, component: 'profileSettings' },
    decryptionLoader: false,
    migrationMetadata: {},
    isFetchSearchHeader: false,
    channelListEditMode: false,
    searchLoading: false,
    welcomeScreen: false,
    focusNotification: false,
    interServerError: '',
    interServerSuccess: false,
    everyoneChannelPermission: {
        'channel.view': true,
        'message.create': true,
        'message.delete': true,
        'message.edit': true,
    },
    memberLoader: false,
    memberCountLoader: true, // for total member count loader (member & fetch-banned api & server change)
    pingRate: null,
    pingDate: null,
    pingsCount: 0,
    pongsCount: 0,
};

export const UtilSlice = createSlice({
    name: 'util',
    initialState: initialState,
    reducers: {
        setWelcomeScreen: (state: any, { payload }: PayloadAction<boolean>) => {
            state.welcomeScreen = payload;
        },
        setChannelListEditModeToggle: (state: any) => {
            state.channelListEditMode = !state.channelListEditMode;
        },
        triggerSettingsModal: (
            state: any,
            {
                payload,
            }: PayloadAction<{ visible: boolean; component?: string }>,
        ) => {
            state.settingsModal = { ...payload };
        },
        updateFocusNotification: (
            state: any,
            { payload }: PayloadAction<boolean>,
        ) => {
            state.focusNotification = payload;
        },
        setMessageLoader: (state: any, { payload }: PayloadAction<boolean>) => {
            state.messageLoader = payload;
        },
        setTimeZone: (state: any, { payload }: PayloadAction<string>) => {
            state.timeZone = payload;
        },
        setSearchLoading: (state: any, { payload }: PayloadAction<boolean>) => {
            state.searchLoading = payload;
        },
        updateDecryptionLoader: (
            state: any,
            { payload }: PayloadAction<boolean>,
        ) => {
            state.decryptionLoader = payload;
        },
        updateEncryptionMetaData: (
            state: any,
            { payload }: PayloadAction<any>,
        ) => {
            state.migrationMetadata = payload;
        },
        updateIsFetchSearchHeader: (
            state: any,
            { payload }: PayloadAction<any>,
        ) => {
            state.isFetchSearchHeader = payload;
        },
        setInterServerError: (state: any, { payload }: PayloadAction<any>) => {
            state.interServerError = payload;
        },
        setInterServerSuccess: (
            state: any,
            { payload }: PayloadAction<any>,
        ) => {
            state.interServerSuccess = payload;
        },
        updateEveryoneChannelPermission: (
            state: any,
            { payload }: PayloadAction<any>,
        ) => {
            const channelPermissions = {
                'channel.view': true,
                'message.create': true,
                'message.delete': true,
                'message.edit': true,
            };

            state.everyoneChannelPermission = {
                ...state.everyoneChannelPermission,
                channelPermissions,
            };

            payload?.permissions?.forEach(itemPerm => {
                if (
                    state.everyoneChannelPermission[itemPerm.permission_type] &&
                    itemPerm.permission_value === false
                ) {
                    state.everyoneChannelPermission[itemPerm.permission_type] =
                        itemPerm.permission_value;
                }
            });

            // state.everyoneChannelPermission = payload;
        },
        updateCreateEveryoneChannelPermission: (
            state: any,
            { payload }: PayloadAction<any>,
        ) => {
            const everyoneData = payload?.everyone;

            const existingEveryone = { ...state.everyoneChannelPermission };

            const newEveryoneObject = {
                key: everyoneData[0]?.key,
                value: everyoneData[0]?.value,
            };

            existingEveryone[newEveryoneObject.key] = newEveryoneObject.value;

            state.everyoneChannelPermission = existingEveryone;
        },
        setMemberLoader: (state, { payload }) => {
            state.memberLoader = payload;
        },
        setMemberCountLoader: (state, { payload }) => {
            state.memberCountLoader = payload;
        },
        updateMessagePing(state, action) {
            state.pingRate = action.payload;
        },
        updatePing(state, action) {
            state.pingDate = action.payload;
        },
        updatePingsCount(state) {
            state.pingsCount = state.pingsCount + 1;
        },
        updatePongsCount(state) {
            state.pongsCount = state.pongsCount + 1;
        },
        resetPingPong(state) {
            state.pongsCount = 0;
            state.pingsCount = 0;
        },
    },

    extraReducers: builder => {
        builder.addCase(getMembersData.fulfilled, (state, { payload }) => {
            state.memberLoader = false;
        });
        builder.addCase(fetchBannedToServer.fulfilled, (state, { payload }) => {
            state.memberCountLoader = false;
        });
    },
});

export const {
    triggerSettingsModal,
    setMessageLoader,
    updateDecryptionLoader,
    updateEncryptionMetaData,
    updateIsFetchSearchHeader,
    setTimeZone,
    setSearchLoading,
    setChannelListEditModeToggle,
    setWelcomeScreen,
    updateFocusNotification,
    updateEveryoneChannelPermission,
    updateCreateEveryoneChannelPermission,
    setMemberLoader,
    setMemberCountLoader,
    setInterServerError,
    setInterServerSuccess,
    updateMessagePing,
    updatePing,
    updatePingsCount,
    updatePongsCount,
    resetPingPong,
} = UtilSlice.actions;
export default UtilSlice.reducer;
