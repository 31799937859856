import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import SunIcon from '@/assets/icons/SunIcon';
import nightIcon from '@/assets/icons/night_on.svg';
import { RootState } from '@/store';
import { themeColorActions } from '@/store/theme-color-reducer.js';

import classes from './ThemeToggle.module.css';

const ThemeToggle: FC = () => {
    const dispatch = useDispatch();
    const themeRef = useRef<HTMLUListElement>(null);
    const [style, setStyle] = useState({});

    const defaultTheme = useSelector(
        (state: RootState) => state.themeColorReducer.themeColor,
    );

    const toggleTheme = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value.trim().toUpperCase();
        dispatch(themeColorActions.toggleTheme({ themeName: name }));
    };

    useEffect(() => {
        switch (defaultTheme) {
            case 'LIGHT':
                setStyle({ transform: 'translateX(calc(100% + 4px))' });

                break;

            default:
                setStyle({ transform: 'translateX(0)' });
                break;
        }
    }, [defaultTheme]);

    return (
        <ul ref={themeRef} className={classes['toggle']}>
            <div className={classes['indicator']} style={style}></div>
            <li>
                <input
                    type="radio"
                    className={classes['theme-toggler']}
                    name="theme-toggler"
                    value="dark"
                    onChange={toggleTheme}
                    defaultChecked={defaultTheme === 'DARK'}
                />
                <img src={nightIcon} alt="dark" />
            </li>
            <li>
                <SunIcon
                    fill={defaultTheme === 'DARK' ? 'var(--white)' : '#FFE600'}
                    fillOpacity={defaultTheme === 'DARK' ? '0.2' : '0.9'}
                />
                <input
                    type="radio"
                    className={classes['theme-toggler']}
                    name="theme-toggler"
                    value="light"
                    onChange={toggleTheme}
                    defaultChecked={defaultTheme === 'LIGHT'}
                />
            </li>
        </ul>
    );
};
export default ThemeToggle;
