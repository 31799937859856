import { useContext } from 'react';

import { Line } from '@/UI';
import HashtagIcon from '@/assets/icons/HashtagIcon';
import VolumeIcon from '@/assets/icons/VolumeIcon';

import { ReactComponent as LockIcon } from '@/assets/icons/lock.svg';

import classes from './Search.module.css';
import { SearchContext } from './SearchContext';

interface IProps {
    result: any;
}
const SearchChannelItem: React.FC<IProps> = ({ result }) => {
    const context = useContext(SearchContext);
    const { joinChannelHandler } = context;

    return (
        <li
            id={result._id}
            className={classes['search-item']}
            onClick={() => joinChannelHandler(result._id)}>
            <div className={classes['d-flex']}>
                {!result.is_public && (
                    <LockIcon
                        fill={'var(--text-2)'}
                        className={classes['room-type-icon']}
                    />
                )}
                {result.is_public && result.type === 'text' && (
                    <HashtagIcon className={''} />
                )}
                {result.is_public && result.type === 'voice' && (
                    <VolumeIcon className={''} />
                )}

                <p>{result.name}</p>
                <p className={classes['text-color']}>{result?.section}</p>
            </div>
            <Line />
        </li>
    );
};
export default SearchChannelItem;
