import React, { useRef, useState } from 'react';

import optionsImg from '@/assets/icons/options.svg';
import { DEFAULT_IMAGES } from '@/constants';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';

import OfflineMembersCount from './OfflineMembers';
import OnlineMembersCount from './OnlineMembers';
import PingCount from './PingCount';
import classes from './ServerBanner.module.css';
import ServerBannerModals from './ServerBannerModals';

const ServerBanner = () => {
    const dropdownRef = useRef(null);
    const [showSettings, setShowSettings] = useState<boolean>(false);

    const ServerSelector = useAppSelector(state => state.serverDataReducer);
    const { activeServer } = ServerSelector;
    const serverDetails = ServerSelector?.serverData[activeServer];
    const settings = serverDetails?.settings;
    const id = serverDetails?.id;
    const banner = settings?.banner;

    const style = {
        backgroundImage: `url("${banner ?? DEFAULT_IMAGES.COVER_IMAGE}")`,
        bacgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    const settingsToggler = () => {
        setShowSettings(prev => !prev);
    };

    const toggleSettingsHandler = (status: boolean) => {
        setShowSettings(status);
    };

    const membersSelector = useAppSelector(state => memberDataSelector(state));
    const members = membersSelector.filter(item => !item?.banned).length;

    const userId = useAppSelector(state => state.userReducer.user.id);
    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );
    const isUserExternal = memberList?.[userId]?.external;

    return (
        <div
            id={`${id}`}
            style={style}
            className={classes['server-Banner-container']}>
            <div className={classes['d-start']}>
                <div className={classes['people-counter']}>
                    {isUserExternal === 0 && (
                        <>
                            <OfflineMembersCount
                                membersSelector={membersSelector}
                            />
                            <OnlineMembersCount
                                membersSelector={membersSelector}
                            />
                        </>
                    )}
                    <PingCount />
                </div>
                {members > 0 && (
                    <img
                        ref={dropdownRef}
                        onClick={settingsToggler}
                        src={optionsImg}
                        alt="options"
                        className={classes['options-icon']}
                    />
                )}
                <ServerBannerModals
                    dropdownRef={dropdownRef}
                    id={id}
                    showSettings={showSettings}
                    toggleSettings={toggleSettingsHandler}
                />
            </div>
        </div>
    );
};
export default React.memo(ServerBanner);
