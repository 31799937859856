import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const CryptoKeysSlice = createSlice({
    name: 'cryptoKeys',
    initialState: initialState,
    reducers: {
        setCryptoKeys(state, { payload }) {
            const { hashKey, privateKey, publicKey } = payload;
            state[hashKey] = { privateKey, publicKey };
        },
    },
});

export const { setCryptoKeys } = CryptoKeysSlice.actions;
export default CryptoKeysSlice.reducer;
