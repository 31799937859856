import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';

import { sendWebSocketData } from '../webSocketUtils';

export const getPrevMessages = (range: string, prev: boolean) => {
    const channelMessage = {
        action: SOCKET_ACTIONS_WITH_TYPES.channelMessages.action,
        data: {
            date: range,
            prev: prev,
        },
    };
    sendWebSocketData(JSON.stringify(channelMessage));
};
