import { CSSProperties, FC, ReactNode } from 'react';

import classes from './EncryptionManagement.module.css';

type Props = {
    title: string;
    desc: string;
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
};
const RowContainer: FC<Props> = props => {
    const { title, desc, children, className = '', style = {} } = props;
    return (
        <div
            style={style}
            className={`${className} ${classes['row-container']}`}>
            <h3>{title}</h3>
            <span className={classes['desc']}>{desc}</span>
            <div className={classes['row-wrapper']}>{children}</div>
        </div>
    );
};
export default RowContainer;
