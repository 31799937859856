import CryptoJS from 'crypto-js';
import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';

import {
    RSA_CONSTANTS,
    decryptDataWithRSA,
    encryptDataWithRSA,
    importKey,
} from '@/Utils/cryptoUtils';
import { joinObjects } from '@/Utils/objectUtils';
import {
    handleEncryptKeyWithPassword,
    handleFileInputChange,
} from '@/Utils/uploadKeysUtils';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { setCryptoKeys } from '@/store/crypto-keys-reducer';
import { p2pActions } from '@/store/p2p-slice';
import { updatePrivateKey } from '@/store/server-data-reducer/server-data-reducer';
import { setWelcomeScreen } from '@/store/util-reducer';
import { IServerData } from '@/types';

const useUploadServerKeys = () => {
    const dispatch = useAppDispatch();

    const activeServer: string = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const serverData: IServerData = useAppSelector(
        state => state.serverDataReducer.serverData[activeServer],
    );
    const p2pSubserverKeys = useAppSelector(
        state => state.p2pReducer.subserverKeys,
    );

    const initialState = {
        fileName: '',
        privateKey: undefined,
        publiceKey: undefined,
        error: '',
        success: '',
    };

    const [files, setFiles] = useState<{
        fileName: string;
        privateKey?: JsonWebKey | undefined;
        publicKey?: JsonWebKey | undefined;
        error: string;
        success?: string;
    }>();

    const handleChange = () => {
        handleFileInputChange((file, fileContent) => {
            if (file.type === 'application/json') {
                const jsonData: {
                    privateKey: JsonWebKey;
                    publicKey: JsonWebKey;
                } = JSON.parse(fileContent);
                setFiles({
                    fileName: file.name,
                    privateKey: jsonData.privateKey,
                    publicKey: jsonData.publicKey,
                    error: '',
                });
            } else
                setFiles({
                    fileName: '',
                    privateKey: undefined,
                    publicKey: undefined,
                    error: t`Invalid Key File`,
                });
        });
    };

    const handleAddPrivateKeys = async (isDefault: boolean = true) => {
        isDefault && dispatch(setWelcomeScreen(true));
        if (files?.privateKey === undefined) return handleChange();

        // get keys from json in JWK format
        let publicKey_Complete = files.publicKey;
        let privateKey_Complete = files.privateKey;
        try {
            //check if pkeypart exist in server data,
            //also if the uploaded file is full key (for old versions) or half key (new versions)
            const textToEncrypt = 'cov_12';
            if (
                serverData?.pkey_parts &&
                Object.keys(files.privateKey).length > 7
            ) {
                setFiles({ ...files, error: t`Invalid Key File` });
                return;
            }
            if (
                serverData?.pkey_parts &&
                files.privateKey.alg === undefined &&
                Object.keys(files.privateKey).length === 7
            ) {
                const { publicKey, privateKey } = serverData.pkey_parts;
                publicKey_Complete = joinObjects(files.publicKey, publicKey);
                privateKey_Complete = joinObjects(files.privateKey, privateKey);
            }
            const privateKey_RSAConstant = {
                ...RSA_CONSTANTS('decrypt'),
                ...privateKey_Complete,
            };
            const cryptoKey = await importKey(privateKey_RSAConstant);

            if (!cryptoKey) {
                throw new Error('cryptoKey not defined ');
            }

            const { encrpytedPrivateKey, encrpytedPublicKey } =
                await handleEncryptKeyWithPassword(
                    privateKey_Complete,
                    publicKey_Complete,
                );

            const encryptedText = await encryptDataWithRSA(
                textToEncrypt,
                encrpytedPublicKey,
            );

            const decryptedText = await decryptDataWithRSA(
                encryptedText,
                encrpytedPrivateKey,
            );

            if (textToEncrypt !== decryptedText) {
                console.log('error');
                setFiles({ ...files, error: t`Invalid Key File` });
                return;
            }

            const hashKey = CryptoJS.MD5(
                JSON.stringify(privateKey_RSAConstant),
            ).toString();
            isDefault &&
                dispatch(
                    updatePrivateKey({
                        id: activeServer,
                        defaultHashkey: hashKey,
                        privateKey: encrpytedPrivateKey,
                        publicKey: encrpytedPublicKey,
                    }),
                );

            dispatch(
                setCryptoKeys({
                    hashKey: hashKey,
                    privateKey: encrpytedPrivateKey,
                    publicKey: encrpytedPublicKey,
                }),
            );

            setFiles({
                ...initialState,
                success: 'Keys Update Successfully',
            });
        } catch (error) {
            console.log('ERROR', error);
            setFiles({ ...files, error: t`Invalid Key File` });
        }
    };

    const handleAddPrivateKeysP2P = async (isDefault: boolean = true) => {
        isDefault && dispatch(setWelcomeScreen(true));
        let publicKey_Complete = p2pSubserverKeys.publicKey;
        let privateKey_Complete = p2pSubserverKeys.privateKey;

        try {
            const textToEncrypt = 'cov_12';
            if (
                serverData?.pkey_parts &&
                Object.keys(files.privateKey).length > 7
            ) {
                setFiles({ ...files, error: t`Invalid Key File` });
                return;
            }
            if (
                serverData?.pkey_parts &&
                files.privateKey.alg === undefined &&
                Object.keys(files.privateKey).length === 7
            ) {
                const { publicKey, privateKey } = serverData.pkey_parts;
                publicKey_Complete = joinObjects(files.publicKey, publicKey);
                privateKey_Complete = joinObjects(files.privateKey, privateKey);
            }
            const privateKey_RSAConstant = {
                ...RSA_CONSTANTS('decrypt'),
                ...privateKey_Complete,
            };
            const cryptoKey = await importKey(privateKey_RSAConstant);
            if (!cryptoKey) {
                throw new Error('cryptoKey not defined ');
            }

            const { encrpytedPrivateKey, encrpytedPublicKey } =
                await handleEncryptKeyWithPassword(
                    privateKey_Complete,
                    publicKey_Complete,
                );

            const encryptedText = await encryptDataWithRSA(
                textToEncrypt,
                encrpytedPublicKey,
            );

            const decryptedText = await decryptDataWithRSA(
                encryptedText,
                encrpytedPrivateKey,
            );

            if (textToEncrypt !== decryptedText) {
                console.log('error');
                setFiles({ ...files, error: t`Invalid Key File` });
                return;
            }

            const hashKey = CryptoJS.MD5(
                JSON.stringify(privateKey_RSAConstant),
            ).toString();

            isDefault &&
                dispatch(
                    updatePrivateKey({
                        id: activeServer,
                        defaultHashkey: hashKey,
                        privateKey: encrpytedPrivateKey,
                        publicKey: encrpytedPublicKey,
                    }),
                );
            dispatch(
                setCryptoKeys({
                    hashKey: hashKey,
                    privateKey: encrpytedPrivateKey,
                    publicKey: encrpytedPublicKey,
                }),
            );

            setFiles({
                ...initialState,
                success: 'Keys Update Successfully',
            });
            dispatch(p2pActions.updateP2PKeys({}));
        } catch (error) {
            console.log('ERROR', error);
            setFiles({ ...files, error: t`Invalid Key File` });
        }
    };

    useEffect(() => {
        setFiles(initialState);
    }, [activeServer]);

    useEffect(() => {
        dispatch(p2pActions.updateIsInKeyUpload(true));
    }, []);

    useEffect(() => {
        if (p2pSubserverKeys?.privateKey && p2pSubserverKeys?.publicKey) {
            handleAddPrivateKeysP2P();
        }
    }, [p2pSubserverKeys]);

    return { files, handleChange, handleAddPrivateKeys, serverData };
};

export default useUploadServerKeys;
