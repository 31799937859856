import { onDecryptMessage } from '@/Utils/onDecryptMessage';
import store from '@/store';
import {
    addMentionNotifications,
    addNotifications,
    addReactionNotifications,
    updateMentionNotifications,
    updateNotifications,
    updateReactionNotifications,
} from '@/store/server-data-reducer/server-data-reducer';
import { IMessageData } from '@/types';

import { editChannelHandler } from '../ChannelActions/channelListnerActions';

export const handleNotifcationMentionList = async ({
    data,
    privateKey,
    handleMediaMessages,
}: {
    data: any;
    privateKey: string;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
    if (data.length === 0) {
        return store.dispatch(updateMentionNotifications(data));
    }

    const decryptDataList = await Promise.all(
        data?.map(async (item: IMessageData) => {
            let decryptMessage: any = item.message;
            try {
                decryptMessage = await onDecryptMessage(item, privateKey);
            } catch (error) {
                console.error(error);
            }
            if (item.type === 'media') {
                await handleMediaMessages(decryptMessage, item._id, false);
            }
            return { ...item, ...decryptMessage };
        }),
    );
    store.dispatch(updateMentionNotifications(decryptDataList));
};

export const handleNewNotifcationMention = async ({
    data,
    privateKey,
    handleMediaMessages,
}: {
    data: any;
    privateKey: string;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
    let decryptMessage: any = data.message;
    let messageItem = { ...data };
    try {
        decryptMessage = await onDecryptMessage(data, privateKey);
        messageItem = { ...messageItem, ...decryptMessage };
    } catch (error) {
        console.error(error);
    }

    if (data.type === 'media')
        await handleMediaMessages(decryptMessage, data._id, false);
    store.dispatch(addMentionNotifications(messageItem));
};

export const handleNotifcationReactionList = async ({
    data,
    handleMediaMessages,
    privateKey,
}: {
    data: any;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
    privateKey: string;
}) => {
    if (data.length === 0) {
        return store.dispatch(updateReactionNotifications(data));
    }

    const decryptDataList = await Promise.all(
        data?.map(async (item: IMessageData) => {
            let decryptMessage: any = item.message;
            try {
                decryptMessage = await onDecryptMessage(item, privateKey);
            } catch (error) {
                console.error(error);
            }
            if (item.type === 'media') {
                await handleMediaMessages(decryptMessage, item._id, false);
            }
            return { ...item, ...decryptMessage };
        }),
    );
    store.dispatch(updateReactionNotifications(decryptDataList));
};

export const handleNewNotifcationReaction = async ({
    data,
    privateKey,
    handleMediaMessages,
}: {
    data: any;
    privateKey: string;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
    let decryptMessage: any = data.message;
    let messageItem = { ...data };
    try {
        decryptMessage = await onDecryptMessage(data, privateKey);
        messageItem = { ...messageItem, ...decryptMessage };
    } catch (error) {
        console.error(error);
    }

    if (data.type === 'media')
        await handleMediaMessages(decryptMessage, data._id, false);
    store.dispatch(addReactionNotifications(messageItem));
};

export const handleChannelNotficationUpdate = (
    data: any,
    privateKey: string,
) => {
    editChannelHandler({ data: data?.data, privateKey: privateKey });
};

export const handleNotifcationList = async ({
    data,
    privateKey,
    handleMediaMessages,
}: {
    data: any;
    privateKey: string;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
    if (data.notification_list === undefined) {
        return store.dispatch(
            updateNotifications({
                metaData: data.meta_data,
                data: undefined,
            }),
        );
    }
    if (data.notification_list.length === 0) {
        return store.dispatch(
            updateNotifications({
                metaData: data.meta_data,
                data: data.notification_list,
            }),
        );
    }

    const decryptDataList = await Promise.all(
        data?.notification_list?.map(async (item: IMessageData) => {
            const parseItem =
                typeof item.message === 'string'
                    ? JSON.parse(item.message)
                    : item.message;
            const messageItem = {
                ...item,
                notification_type: item.type,
                ...parseItem,
            };
            let decryptMessage: any = parseItem.message;
            try {
                decryptMessage = await onDecryptMessage(parseItem, privateKey);
            } catch (error) {
                console.error(error);
            }
            if (item.type === 'media') {
                await handleMediaMessages(decryptMessage, item._id, false);
            }
            return { ...messageItem, ...decryptMessage };
        }),
    );

    store.dispatch(
        updateNotifications({
            metaData: data.meta_data,
            data: decryptDataList,
        }),
    );
};

export const handleNewNotifcation = async ({
    data,
    privateKey,
    handleMediaMessages,
}: {
    data: any;
    privateKey: string;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
 
    const parseItem = data.message;
    let decryptMessage: any = parseItem;
    let messageItem = {
        ...data,
        ...parseItem,
        notification_type: data.type,
    };
    try {
        decryptMessage = await onDecryptMessage(parseItem, privateKey);
        messageItem = { ...messageItem, ...decryptMessage };
    } catch (error) {
        console.error(error);
    }

    if (data.type === 'media') {
        await handleMediaMessages(decryptMessage, data._id, false);
    }
    store.dispatch(addNotifications(messageItem));
};
