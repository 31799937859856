import { Trans, msg, t } from '@lingui/macro';
import { useState } from 'react';

import { AppTable } from '@/UI';
import { formatCreatedDate } from '@/Utils/utils';
import arrowDown from '@/assets/icons/arrowDownPurple.svg';
import dotsTree from '@/assets/icons/dots-tree.svg';
import removeSVG from '@/assets/icons/trash.svg';
import { useAppSelector } from '@/hooks/useRedux';

import classes from './TableServerManagement.module.css';

type Props = {
    data: any[];
    deleteInterServer: (id: string) => void;
};

const TableServerManagement = (props: Props) => {
    const _renderHeaderCell = item => (
        <div onClick={() => {}} className={classes['header-cell']}>
            <span>{item.label}</span>
            {!item?.disableSortFilter && <img alt="" src={arrowDown} />}
        </div>
    );

    const _renderContent = item => (
        <div className={classes['email']}>
            <p>{item}</p>
        </div>
    );

    const channelObject = useAppSelector(
        state =>
            state.serverDataReducer.channelList[
                state.serverDataReducer.activeServer
            ],
    );

    const serverList = useAppSelector(
        state => state.serverDataReducer.serverData,
    );
    const [selectedEditPopup, setSelectedEditPopup] = useState(null);

    const _renderHoverIcons = data => {
        return (
            <div
                className={classes['tree-dots-container']}
                onMouseEnter={() => setSelectedEditPopup(data._id)}
                onMouseLeave={() => setSelectedEditPopup(null)}>
                <img alt="" style={{ cursor: 'pointer' }} src={dotsTree} />
                {data?._id == selectedEditPopup && selectedEditPopup && (
                    <div className={classes['edit-image-popup-container']}>
                        <div className={classes['edit-image-popup']}>
                            <div className={classes['triangle']}></div>
                            <div
                                onClick={() => {
                                    props.deleteInterServer(data?._id);
                                }}
                                className={classes['edit-image-popup-row']}>
                                <img
                                    alt="remove"
                                    height={18}
                                    width={18}
                                    src={removeSVG}
                                />
                                <p>
                                    <Trans>Delete</Trans>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const columns = [
        {
            key: 'current_channel_id',
            label: t`Current Channel`,
            render: item =>
                _renderContent(channelObject?.[item.current_channel_id]?.name), // Replace _renderUserColumn with your actual function
        },
        {
            key: 'receiver_subserver_id',
            label: t`Target Server`,
            render: item =>
                _renderContent(serverList?.[item.receiver_subserver_id]?.name),
        },

        {
            key: '_id',
            label: t`Target Channel ID`,
            render: item => _renderContent(item._id), // Render the 'email' property directly from the data object
            disableSortFilter: true,
        },
        {
            key: 'created_at',
            label: t`Date`,
            render: item => _renderContent(formatCreatedDate(item.created_at)),
            disableSortFilter: true,
        },
    ];

    const filterData = props?.data?.filter(
        item => channelObject?.[item.current_channel_id]?.name !== undefined,
    );

    return (
        <AppTable
            columns={columns}
            data={filterData ?? []}
            header={item => _renderHeaderCell(item)}
            hoverIcons={data => _renderHoverIcons(data)}
        />
    );
};

export default TableServerManagement;
