import { useMediaQuery } from 'react-responsive';

import ContentGrid from './ContentGrid';
import DesktopNotesLayout from './DesktopNotesLayout';
import MobileNotesLyout from './MobileNotesLyout';
import classes from './Notes.module.css';
import NotesHeader from './notes-header/NotesHeader';
import NotesPopups from './notes-popus/NotesPopups';
import NotesTabs from './notes-tab/NotesTabs';

const Notes: React.FC<{}> = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    return (
        <section className={classes['section']}>
            <NotesHeader />
            <NotesTabs />
            <ContentGrid>
                {isMobile ? <MobileNotesLyout /> : <DesktopNotesLayout />}
            </ContentGrid>
            <NotesPopups />
        </section>
    );
};
export default Notes;
