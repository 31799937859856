import { FC, RefObject } from 'react';

import DropDown from '@/UI/drop-down/DropDown';
import ThemeToggle from '@/UI/theme-toggle/ThemeToggle';
import { getProfileOptions } from '@/constants/profileOptions';

import classes from './ProfileModal.module.css';

export type optionsType = {
    id: number;
    name: string;
    icon: string;
};

type propsTypes = {
    onCLose: () => void;
    onSelectOption: (option: optionsType) => void;
    passedRef?: RefObject<HTMLDivElement>;
};

const ProfileModal: FC<propsTypes> = props => {
    const { onCLose, onSelectOption, passedRef } = props;
    const profileOptions = getProfileOptions();
    
    const profileOptionsData = Object.keys(profileOptions).map(
        item => profileOptions[item],
    );
    const selectOptionHandler = (option: optionsType) => {
        onSelectOption(option);
    };

    return (
        <DropDown
            avoidableCLose={passedRef}
            onClose={onCLose}
            className={classes['container']}
            selectOptions={profileOptionsData}
            onClick={selectOptionHandler}>
            <ThemeToggle />
        </DropDown>
    );
};
export default ProfileModal;
