import { Trans } from '@lingui/macro';

import classes from './Connections.module.css';

const ConnectionsSearchEmpty: React.FC<{}> = () => {
    return (
        <p className={classes['no-result']}>
            <Trans>No Result Found</Trans>
        </p>
    );
};
export default ConnectionsSearchEmpty;
