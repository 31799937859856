import { FC, memo } from 'react';

import MediaDisplayProvider from './MediaDisplayContext';
import SelectedMediaContent from './SelectedMediaContent';
import { Props } from './SelectedMediaTypes';

const SelectedMediaDisplay: FC<Props> = props => {
    const { noCopy } = props;
    return (
        <MediaDisplayProvider {...props}>
            <SelectedMediaContent noCopy={noCopy} />
        </MediaDisplayProvider>
    );
};
export default memo(SelectedMediaDisplay);
