import { decryptDataWithRSA } from '@/Utils/cryptoUtils';
import { getChannelRolePerm } from '@/Utils/socket-actions/getChannelPermission';
import { sendWebSocketData } from '@/Utils/webSocketUtils';
import store from '@/store';
import { mapReactions } from '@/store/messages-reducer/messages-reducer';
import {
    addChannel,
    addSection,
    markReadNotifications,
    reJoinChannel,
    removeChannel,
    setActiveChannel,
    updateChannelCount,
    updateChannelDetails,
    updateChannelList,
    updateChannelMembers,
    updateChannelPermissions,
    updateCreateChannelMemberPermissions,
    updateCreateChannelRolePermissions,
    updateGetChannelMemberPermissions,
    updateGetChannelRolePermissions,
    updateMemberList,
    updatePublicChannelList,
    updateRolesList,
    updateSectionList,
    updateServer,
} from '@/store/server-data-reducer/server-data-reducer';
import {
    updateCreateEveryoneChannelPermission,
    updateEveryoneChannelPermission,
} from '@/store/util-reducer';
import { IChannelData, IMessageData } from '@/types';

import { getChannelMember } from './channelActions';

export const handleChannelList = async (
    data: IChannelData[],
    privateKey: string,
) => {
    const decryptList = await Promise.all(
        data.map(async item => {
            if (item.privacy === 'direct') return item;
            const decryptName: any = await decryptDataWithRSA(
                item.name,
                privateKey,
            );
            if (typeof decryptName === 'string')
                return { ...item, name: decryptName };
            else return item;
        }),
    );
    store.dispatch(updateChannelList(decryptList));
};

export const handleChannelJoinSuccess = ({
    data,
    notifcationList,
}: {
    data: any;
    notifcationList: any;
}) => {
    if (data?.re_join) {
        store.dispatch(reJoinChannel(data)); //add channel
    }
    if (
        notifcationList?.data?.length > 0 &&
        notifcationList?.data?.some(item => item.channel_id === data.channel_id)
    ) {
        store.dispatch(markReadNotifications(data.channel_id));
    }
    getChannelRolePerm();
};

export const handleSectionList = data => {
    store.dispatch(updateSectionList(data));
};

export const handleCreateChannel = data => {
    store.dispatch(addChannel(data));
};

export const handleCreateSection = (data: {}) => {
    store.dispatch(addSection(data));
};

export const handleChannelCount = async (data: []) => {
    data.map((item: any) => {
        store.dispatch(updateChannelCount(item));
    });
};

export const deleteChannelHandler = data => {
    store.dispatch(removeChannel(data));
};

export const channelDeleteSuccess = () => {
    store.dispatch(setActiveChannel(''));
};

export const editChannelHandler = async ({
    data,
    privateKey,
}: {
    data: any;
    privateKey: string;
}) => {
    const decryptName: any = await decryptDataWithRSA(data?.name, privateKey);
    let config = data;
    if (typeof decryptName === 'string') {
        config = {
            ...config,
            name: decryptName,
        };
    }
    store.dispatch(updateChannelDetails(config));
};

export const handleChannelMembersList = data => {
    store.dispatch(updateChannelMembers(data));
};

export const channelRoleUsersHandler = data => {
    getChannelMember(data?._id || data?.channel_id);
};

export const handleCreateChannelMemberPermission = data => {
    if (data?.everyone.length > 0) {
        store.dispatch(updateCreateEveryoneChannelPermission(data));
    } else if (data?.is_role) {
        store.dispatch(updateCreateChannelRolePermissions(data));
    } else {
        store.dispatch(updateCreateChannelMemberPermissions(data));
    }
};

export const handleGetChannelMemberPermission = data => {
    if (data?.user_id) {
        store.dispatch(updateGetChannelMemberPermissions(data));
    } else if (data?.role_id || data?.role_members === 0) {
        store.dispatch(updateGetChannelRolePermissions(data));
    } else {
        store.dispatch(updateEveryoneChannelPermission(data));
    }
};

export const handleFetchChannelPermmission = ({
    data,
    activeChannel,
    userSelector,
}: {
    data: any;
    activeChannel: string;
    userSelector: any;
}) => {
    const user_id = data?.all_data?.[0]?.user_id;
    if (userSelector?.id === user_id && activeChannel === data?.channel_id) {
        sendWebSocketData(
            JSON.stringify({
                action: 'channel_permission',
                data: {
                    channel_id: data?.channel_id,
                },
            }),
        );
    }
};

export const handlePublicChannelList = async ({
    data,
    privateKey,
}: {
    data: any;
    privateKey: string;
}) => {
    const decryptList = await Promise.all(
        data.map(async item => {
            const decryptName: any = await decryptDataWithRSA(
                item.name,
                privateKey,
            );
            if (typeof decryptName === 'string')
                return { ...item, name: decryptName };
            else return item;
        }),
    );
    store.dispatch(updatePublicChannelList(decryptList));
};

export const handleChannelReactions = data => {
    store.dispatch(mapReactions(data));
};

export const handleGetChannelPermissions = (data: any) => {
    store.dispatch(
        updateChannelPermissions({
            channelPermissions: data,
        }),
    );
};

export const handleMemberList = data => {
    store.dispatch(updateMemberList(data));
};

export const handleReadAllMessages = (activeServer: string) => {
    store.dispatch(
        updateServer({
            id: activeServer,
            total_count: 0,
        }),
    );
};

//Roles

export const handleRolesList = data => {
    store.dispatch(updateRolesList(data));
};
