import React from 'react';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/library.css';
import { ViewportList } from 'react-viewport-list';

import DateLineBreak from '@/UI/date-line-beak/DateLineBreak';
import { dateFormatHHMM } from '@/Utils/dateUtils';
import MessageItem from '@/components/messages/message-item/MessageItem';
import EmptyMessages from '@/components/empty-messages/EmptyMessages';
import { INPUT_TYPES } from '@/constants/enum';
import { IMessageData } from '@/types';

import classes from './ThreadsList.module.css';
import useThreadList from './useThreadList';

const ThreadsList: React.FC<{
    members?: [];
    onReplyMessage?: Function;
    handleNewChat?:any,
    messages?: {
        _id: string;
        created_at: string;
        user_name: string;
        user_id: string;
        message: string;
        className: string;
        ownerImg: string;
        reactions: {}[];
        isSending: boolean;
    }[];

    scrollToMessage?: { _id: string; created_at: string | Date };
}> = props => {
    const {
        displayBuffer,
        messageReplyHandler,
        listRef,
        loadMore,
        viewRef,
        targetScrollMessage,
        scrollToEnd,
    } = useThreadList(props);

   

    const handleReaction = index => {
        if (index === displayBuffer.length - 1) {
            setTimeout(() => {
                scrollToEnd();
            }, 200);
        }
    };

    const _renderMessagesItem = (item: IMessageData, index) => {
        if (item === undefined) {
            console.log('undefined ERRROR');
            return <></>;
        }

        const reversedIndex = displayBuffer.length - index;
        const date = new Date(item?.created_at);
        const prevDate = new Date(displayBuffer[index - 1]?.created_at);

        const showDate =
            reversedIndex < displayBuffer.length &&
            date.toLocaleDateString() !== prevDate.toLocaleDateString() &&
            prevDate.toLocaleDateString !== undefined;
        const sameOwner = item?.user_id === displayBuffer[index - 1]?.user_id;
        return (
            <div
                id={`thread-list-item-${index}-${item?._id}`}
                key={`thread-list-item-${index}-${item?._id}`}>
                <div
                    style={{
                        padding: '0 16px',
                        marginLeft: 16,
                    }}>
                    {reversedIndex === displayBuffer.length && (
                        <DateLineBreak date={date} />
                    )}
                    {showDate && <DateLineBreak date={date} />}
                </div>

                <MessageItem
                    id={item?._id}
                    messageStyle={
                        (targetScrollMessage?.original_message_id ===
                            item?._id ||
                            targetScrollMessage?._id === item?._id) &&
                        true
                    }
                    key={`thread-list-item-${index}-${item?._id}`}
                    item={item}
                    onReplyMessage={messageReplyHandler}
                    time={dateFormatHHMM(date)}
                    onNewChat={props?.handleNewChat}
                    onReaction={() => handleReaction(index)}
                    removeThreadAction={true}
                    inputType={INPUT_TYPES.THREADINPUT}
                />
            </div>
        );
    };

    if (displayBuffer.length === 0) {
        return (
            <div ref={listRef} className={classes['message-container']}>
                <EmptyMessages title="No thread messages yet" />
            </div>
        );
    }

    return (
        <div ref={listRef} className={classes['message-container']}>
            {displayBuffer.length > 0 && (
                <>
                    <ViewportList
                        items={displayBuffer.sort(
                            (a, b) =>
                                new Date(a.created_at) - new Date(b.created_at),
                        )}
                        initialIndex={displayBuffer.length - 1}
                        indexesShift={displayBuffer.length - 1}
                        initialAlignToTop={false}
                        initialPrerender={10}
                        overscan={10}
                        children={_renderMessagesItem}
                        ref={viewRef}
                        viewportRef={listRef}
                    />
                </>
            )}
        </div>
    );
};
export default React.memo(ThreadsList);
