import { Trans, t } from '@lingui/macro';
import React, { FC, useEffect, useRef, useState } from 'react';

import { Icon } from '@/UI';
import ImgButton from '@/UI/custom-buttons/ImgButton';
import { handleUserStatusUpdate } from '@/Utils/socket-actions/UserActions/userActions';
import useStatusHelper from '@/Utils/useStatusHelper';
import micIcon from '@/assets/icons/microphone.svg';
import settingsIcon from '@/assets/icons/setting-gray.svg';
import { DEFAULT_IMAGES } from '@/constants';
import { StatusType } from '@/constants/status';
import { useCustomSweetAlert, useLogoutHandler } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { triggerSettingsModal } from '@/store/util-reducer';

import classes from './ShortProfile.module.css';
import LanguagesModal from './languages-modal/LanguagesModal';
import ProfileModal, { optionsType } from './profile-modal/ProfileModal';
import UserStatus from './user-status/UserStatus';

type propsTypes = {
    name: string;
    username: string;
};

const ShortProfile: FC<propsTypes> = props => {
    const dispatch = useAppDispatch();
    const profileRef = useRef(null);

    const [micIsDisable, setMicIsDisable] = useState(true);
    const [statusModal, setStatusModal] = useState(false);
    const [languagesModal, setLanguagesModal] = useState(false);
    const [isProfileOptions, setIsProfileOptions] = useState(false);

    const user = useAppSelector(state => state.userReducer.user);
    const myStatus = user?.status;
    const { getStatusById } = useStatusHelper();
    const { areYouSureModal } = useCustomSweetAlert();
    const { logoutHandler } = useLogoutHandler();

    const toggleMicHandler = () => {
        setMicIsDisable(prev => !prev);
    };

    const toggleSettingsHandler = () => {
        dispatch(triggerSettingsModal({ visible: true }));
    };

    const toggleProfileOptionsHandler = () => {
        setIsProfileOptions(prev => !prev);
    };

    const changeStatusHandler = (statusOption: StatusType) => {
        setStatusModal(false);
        handleUserStatusUpdate(statusOption?.id);
    };

    const closeLanguageModal = () => {
        setLanguagesModal(false);
    };

    const selectOptionHandler = (option: optionsType) => {
        switch (option.id) {
            case 0:
                setStatusModal(true);
                setIsProfileOptions(false);
                break;
            case 8:
                areYouSureModal(
                    t`Logout`,
                    t`Are you sure you want to log out?`,
                    false,
                    () => logoutHandler(),
                );
                break;

            case 9:
                setLanguagesModal(true);
                setIsProfileOptions(false);
                break;

            default:
                console.log('Unkown Option Select!');
                break;
        }
    };
    useEffect(() => {
        if (myStatus) {
            localStorage.setItem('user_last_status', myStatus);
        }
    }, [myStatus]);

    return (
        <div className={classes['container']}>
            <Icon
                ref={profileRef}
                onClick={toggleProfileOptionsHandler}
                className={classes['badge']}
                badgeClass={classes['badge-img']}
                imageURL={user?.profile_picture ?? DEFAULT_IMAGES.PROFILE_IMAGE}
                name={user?.name}
                hasBadge={true}>
                {getStatusById(user?.status)?.icon ? (
                    <img
                        style={{
                            width: '16px',
                            height: '16px',
                        }}
                        src={getStatusById(user?.status)?.icon}
                        alt=""
                    />
                ) : (
                    <span></span>
                )}
            </Icon>
            <div className={classes['flex-start']}>
                <h2>{props.name}</h2>
                <p>#{props.username}</p>
            </div>

            <div className={classes['d-flex']}>
                <div
                    className={`${classes['relative']} ${classes['tooltip']}`}
                    style={{ cursor: 'not-allowed', opacity: '1' }}>
                    <span className={classes['tooltiptext']}>
                        <Trans>Voice chat feature will be added soon...</Trans>
                    </span>

                    <ImgButton
                        type="button"
                        className={classes['mic-btn']}
                        onClick={toggleMicHandler}
                        icon={micIcon}
                        disabled={true}
                    />

                    {micIsDisable && (
                        <div className={classes['mute-mic']}></div>
                    )}
                </div>
                <ImgButton
                    type="button"
                    className={classes['settings-btn']}
                    onClick={toggleSettingsHandler}
                    icon={settingsIcon}
                    disabled={false}
                />
            </div>

            {isProfileOptions && (
                <ProfileModal
                    passedRef={profileRef}
                    onCLose={() => {
                        setIsProfileOptions(false);
                    }}
                    onSelectOption={selectOptionHandler}
                />
            )}

            {statusModal && (
                <UserStatus
                    onCLose={() => setStatusModal(false)}
                    onChangeStatus={changeStatusHandler}
                />
            )}

            {languagesModal && (
                <LanguagesModal
                    onCLose={closeLanguageModal}
                    onChangeLanguage={closeLanguageModal}
                />
            )}
        </div>
    );
};
export default React.memo(ShortProfile);
