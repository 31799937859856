import { motion } from 'framer-motion';
import { useCallback, useContext, useEffect, useState } from 'react';

import { formatDate2 } from '@/Utils/formatDate';
import { decryptNoteHandler } from '@/Utils/notesHelper';
import { truncateText } from '@/Utils/textUtils';
import { ReactComponent as HeartIcon } from '@/assets/icons/heart-empty.svg';
import { ReactComponent as LockIcon } from '@/assets/icons/note-lock.svg';
import { NotesContext } from '@/context/notes/NotesContext';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';
import { INoteListItemProps } from '@/types/notesTypes';

import { editNoteFav } from '../editNote';
import EditNotes from '../notes-header/notes-edit/EditNotes';

import classes from './NotesList.module.css';

const NotesListItem: React.FC<INoteListItemProps> = props => {
    const { note } = props;
    const noteContext = useContext(NotesContext);
    const {
        setIsEditNoteModal,
        setSelectedNotes,
        setDecryptedNotesBody,
        setNotes,
        saveNote,
        setActiveNote,
        activeNote,
    } = noteContext;

    const [isSelected, setIsSelected] = useState(false);

    const [firstElementContent, setFirstElementContent] = useState<string>('');

    const allMembers = useAppSelector(state => memberDataSelector(state));
    const noteMember = allMembers.find(member => member.id === note.user_id);

    const updateSelectedNotes = (state: boolean) => {
        if (state) {
            setSelectedNotes(prev => {
                const removed = prev.filter(el => el !== note.id);
                return removed;
            });
        } else {
            setSelectedNotes(prev => [...prev, note.id]);
        }
    };

    const contextMenuHandler = (
        e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    ) => {
        e.preventDefault();
        updateSelectedNotes(isSelected);
        setIsSelected(prev => !prev);
    };

    const EditNoteHandler = () => {
        setIsEditNoteModal(note);
    };

    const changeFavSuccess = (newfavValue: number) => {
        setNotes(prev => {
            const targetIndex = prev.findIndex(el => el.id === note.id);
            const newArray = [
                ...prev.slice(0, targetIndex),
                { ...prev[targetIndex], fav: newfavValue },
                ...prev.slice(targetIndex + 1),
            ];

            return newArray;
        });
        if (activeNote && note.id === activeNote.id) {
            setActiveNote(prev => {
                return {
                    ...prev,
                    fav: newfavValue,
                };
            });
        }
    };

    const cleanBody = finalData => {
        // Check if htmlContent contains HTML tags
        if (/<[^>]*>/i.test(finalData)) {
            const htmlElements = finalData.match(/<[^>]*>([^<]*)<\/[^>]*>/g);
            // Iterate through each element to find the first non-empty content
            for (let i = 0; i < htmlElements.length; i++) {
                const content = htmlElements[i]
                    .replace(/<\/?[^>]+(>|$)/g, '')
                    .trim();

                content.replace(/&[^\s]*;/g, '');
                if (content !== '' && !content.match(/^&[a-zA-Z]+;$/)) {
                    setFirstElementContent(content.replace(/&[^\s]*;/g, ''));
                    break;
                }
            }
        } else {
            setFirstElementContent(finalData ? finalData.trim() : '');
        }
    };

    const decryptBody = useCallback(async () => {
        let finalData = note;
        if (note.encryption_token && note.key_hash) {
            const { decryptData } = await decryptNoteHandler({
                message: note.body,
                token: note.encryption_token,
                key_hash: note.key_hash,
            });

            finalData = { ...finalData, body: decryptData?.message };
            setDecryptedNotesBody(prev => {
                return { ...prev, [finalData.id]: finalData.body };
            });

            cleanBody(finalData.body);
        }
    }, [note.body]);

    const chooseNote = async () => {
        if (activeNote?.id !== note?.id) {
            await saveNote();
            setActiveNote(note);
        }
    };

    useEffect(() => {
        decryptBody();
    }, [note.body]);

    return (
        <motion.li
            onContextMenu={contextMenuHandler}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.01 }}
            onClick={chooseNote}
            className={`${isSelected ? classes['selected-note'] : ''} ${
                activeNote?.id === note?.id ? classes['active-note'] : ''
            } ${classes['list-item']}`}>
            <div className={classes['item-title']}>
                <h5>{note.title}</h5>
                <EditNotes onEdit={EditNoteHandler} />
                <HeartIcon
                    onClick={ev => editNoteFav(ev, note, changeFavSuccess)}
                    className={`${classes['heart']} ${
                        note.fav ? classes['active'] : ''
                    }`}
                />
            </div>
            <p className={classes['date-name-wrap']}>
                <span className={classes['name']}>{noteMember.name}</span>
                &nbsp;
                <span className="text-dot"></span>
                &nbsp;
                <span className={classes['date']}>
                    {formatDate2({
                        date: new Date(note.created_at),
                        returnTime: true,
                    })}
                </span>
                &nbsp;
                <LockIcon />
            </p>
            <p>{truncateText(firstElementContent, 80)}</p>
        </motion.li>
    );
};
export default NotesListItem;
