import { Trans } from '@lingui/macro';

import { ExternalBadge } from '@/UI/externalbadge/ExternalBadge';
import { DEFAULT_IMAGES } from '@/constants';
import { IChannelData, IMemberData } from '@/types';

import classes from '../ChannelUserPermissinons.module.css';

type Props = {
    onClickEveryone: (channel: {}) => void;
    onClickUser: (item: {}, channel: {}) => void;
    selectedEveryone: boolean;
    searchText: string;
    selectedUser: {};
    members: IMemberData[];
    channel: IChannelData;
    setLoading: any;
    loading: boolean;
    userId: string;
};

function ChannnelUserPermissinonsMemberList(props: Props) {
    const {
        onClickEveryone,
        onClickUser,
        selectedEveryone,
        searchText,
        selectedUser,
        channel,
        members,
        loading,
        userId,
    } = props;
    return (
        <div
            style={{
                opacity: loading ? 0.2 : 1,
            }}>
            {!searchText && (
                <div
                    onClick={() => onClickEveryone(channel)}
                    className={`${classes['member-item']} ${
                        selectedEveryone && classes['selected']
                    }`}>
                    <span>@</span>
                    <div className="">
                        <Trans>Everyone</Trans>
                    </div>
                </div>
            )}

            {members
                ?.filter(item => item?.id !== userId)
                ?.filter(filterItem =>
                    filterItem?.username
                        ?.toLowerCase()
                        .includes(searchText.toLowerCase()),
                )
                ?.map((item: IMemberData, index) => (
                    <div
                        key={item?.id}
                        onClick={() => onClickUser(item, channel)}
                        className={`${classes['member-item']} ${
                            selectedUser?.id === item?.id && classes['selected']
                        }`}>
                        <img
                            alt=""
                            className={classes['member-image']}
                            src={
                                item?.profile_picture ??
                                DEFAULT_IMAGES.PROFILE_IMAGE
                            }
                        />
                        <div className="">{item?.username}</div>
                        {item.external > 0 && <ExternalBadge />}
                    </div>
                ))}
        </div>
    );
}

export default ChannnelUserPermissinonsMemberList;
