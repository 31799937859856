import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import emptyChatimg from '@/assets/images/empty_chat_bg.png';

const Page404 = () => {
    return (
        <div className="m-auto centerContainer items-center justifyCenter gap-3">
            <img width={300} src={emptyChatimg} alt="covchat" />
            <h1 className="gradient-text text-7xl m-0">404</h1>
            <p className="gradient-text">
                <Trans>this link is not correct</Trans>!
            </p>
            <Link className="btn-link" to="/">
                <Trans>Go Home</Trans>
            </Link>
        </div>
    );
};

export default Page404;
