import { Trans } from '@lingui/macro';
import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as NotesIcon } from '@/assets/icons/note-1.svg';
import MobileBackward from '@/components/mobile-backwoard/MobileBackward';
import { NotesContext } from '@/context/notes/NotesContext';

import classes from './NotesHeader.module.css';

const NotesHeader: React.FC<{}> = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
    const context = useContext(NotesContext);
    const { setActiveNote, setActiveMobileStep, activeMobileStep } = context;

    return (
        <div className={`${classes['wrap']}`}>
            {isMobile && activeMobileStep > 0 && (
                <MobileBackward
                    className={classes['backward']}
                    onClick={() => {
                        setActiveMobileStep(prev => prev - 1);
                        setActiveNote(null);
                    }}
                />
            )}
            <NotesIcon />
            <h2>
                <Trans>Notes</Trans>
            </h2>
        </div>
    );
};
export default NotesHeader;
