import DOMPurify from 'dompurify';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import sanitizeHtml from 'sanitize-html';

import { htmlParser } from '@/Utils/htmlParser';
import { getChannelMemberList } from '@/Utils/memberUtils';
import { sortByKey } from '@/Utils/sortByKey';
import { useAppSelector } from '@/hooks/useRedux';
import store from '@/store';
import {
    channelDataSelector,
    rolesListSelector,
} from '@/store/server-data-reducer/selectors-server-data';

type Props = {};

const useNewMessageForm = (props: any) => {
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [emojiIsShown, setEmojiIsShown] = useState(false);
    const [msgInputValue, setMsgInputValue] = useState<any>('');
    const [isAttachMenu, setIsAttachMenu] = useState(false);
    const [pastedFiles, setPastedFiles] = useState<any>();

    const [mentionText, setMentionText] = useState('');
    const [mentionsModal, setMentionsModal] = useState(false);
    const [mentionsChannelModal, setMentionsChannelModal] = useState(false);
    const [mentionsRoleModal, setMentionsRoleModal] = useState(false);
    const members = getChannelMemberList();
    //mention
    const CONSTANT_MENTIONS = [
        { id: '0001', name: '@everyone', username: 'everyone' },
    ];
    const [mentionArray, setMentionArray] = useState([]);
    const [mentionsData, setMentionsData] = useState([]);

    // const serverData = useAppSelector(state => serverDetailSelector(state));
    const channelList = useAppSelector(
        state =>
            state.serverDataReducer.channelList[
                state.serverDataReducer.activeServer
            ],
    );
    const inputRef: MutableRefObject<HTMLInputElement> = useRef();

    const directMessage =
        channelList?.[props.activeChannelId]?.privacy !== 'direct';

    const allChannelAndDirect = useAppSelector(state =>
        channelDataSelector(state),
    );
    const allChannel = allChannelAndDirect?.filter(
        item => item?.privacy === 'channel',
    );

    const rolesList = useAppSelector(rolesListSelector);
    const allRoles = sortByKey(rolesList, 'priority');

    const [channelArray, setChannelArray] = useState([]);

    const [channelsData, setChannelsData] = useState([]);
    const [roleArray, setRoleArray] = useState([]);

    const [rolesData, setRolesData] = useState([]);
    // submit text message
    const extractTextWithLineBreaks = html => {
        const decoder = document.createElement('textarea');
        decoder.innerHTML = html;
        const sanitizedHtml = sanitizeHtml(decoder.value, {
            allowedTags: ['span', 'br', 'ins', 'code', 'blockquote'],
            allowedAttributes: {
                span: ['style'],
            },

            parseStyleAttributes: false,
        });
        return sanitizedHtml;
        // return decoder.value;
    };

    const submitMessasgeHandler = event => {
        event.preventDefault();

        let type = 'text';
        let message: any = extractTextWithLineBreaks(msgInputValue);
        const text = htmlParser(message);
        if (text.length > 0) {
            const state = store.getState().serverDataReducer;
            let replyData = null,
                key_hash = null;
            if (message.trim() === '') return;

            if (props.isReply) {
                type = 'reply';
                message = extractTextWithLineBreaks(msgInputValue);

                replyData = {
                    original_message_id: props.replyTo.id,
                    original_user_id: props.replyTo.user_id,
                    original_message: props.replyTo.messageText,
                    original_sender_message_id:
                        props?.replyTo?.sender_message_id ?? null,
                };

                key_hash = props.replyTo.key_hash;
            }

            let allRolesMention = roleArray.map(item => {
                return { role_id: item.id, rolename: item.name };
            });

            const onSendMessageConfing = {
                type: type,
                replyData,
                message: message,
                mention_id: message.includes('@everyone') ? [] : mentionArray,
                everyone: message.includes('@everyone') ? true : false,
                channel_mentions: channelArray,
                role_mentions: allRolesMention,
            };
            if (key_hash) {
                onSendMessageConfing.key_hash = key_hash;
            }
            props?.onSendMessage(onSendMessageConfing);

            // throw new Error('message send request not handled')
            setMsgInputValue('');
            setMentionArray([]);
            setChannelArray([]);
            setRoleArray([]);
            closeReplyHandler();
        }
    };

    const submitAttachmentMessageHandler = data => {
        props.onSendMessage({ type: 'media', message: data });
    };

    const inputChangeHanlder = event => {
        const messageInput = event.target.value;

        setMsgInputValue(messageInput);
        if (mentionsModal) {
            // Extract the new letters typed after '@'
            const newMention = event.target.value.slice(
                event.target.value.lastIndexOf('@') + 1,
            );
            setMentionText(newMention);
        }
        if (mentionsChannelModal) {
            // Extract the new letters typed after '@'
            const newMention = event.target.value.slice(
                event.target.value.lastIndexOf('#') + 1,
            );
            setMentionText(newMention);
        }
        if (mentionsRoleModal) {
            // Extract the new letters typed after '$'
            const newMention = event.target.value.slice(
                event.target.value.lastIndexOf('$') + 1,
            );
            setMentionText(newMention);
        }
    };

    const filterMentionArray = () => {
        if (members?.length > 0) {
            const updatedData = members.map(item => {
                const isMemberInArray = mentionArray?.some(
                    arrItem => arrItem?.user_id === item?.id,
                );

                return {
                    ...item,
                    selectable: !isMemberInArray,
                };
            });

            const filteredConstantMentions = CONSTANT_MENTIONS.filter(
                constantMention =>
                    !mentionArray.some(
                        mentionItem =>
                            mentionItem?.user_id === constantMention?.id,
                    ),
            );

            setMentionsData([...updatedData, ...filteredConstantMentions]);
        }
    };

    const filterChannelsArray = () => {
        if (allChannel?.length > 0) {
            const updatedData = allChannel?.map(item => {
                const isChannelInArray = channelArray?.some(
                    arrItem => arrItem?.id === item?._id,
                );

                return {
                    ...item,
                    selectable: !isChannelInArray,
                };
            });

            setChannelsData(updatedData);
        }
    };

    const filterRolesArray = () => {
        if (allRoles?.length > 0) {
            const updatedData = allRoles?.map(item => {
                const isRoleInArray = roleArray?.some(
                    arrItem => arrItem?.id === item?.id,
                );

                return {
                    ...item,
                    selectable: !isRoleInArray,
                };
            });

            setRolesData(updatedData);
        }
    };

    const [removedMentionTagId, setRemovedMentionTagId] = useState('');
    const handleKeyDown = event => {
        if (event.key === 'Backspace') {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const previousElement = selection?.baseNode?.previousElementSibling;
            const insideElement = selection?.baseNode?.parentNode;
            let extractedId;
            let match;
            setRemovedMentionTagId('');
            if (
                range.endOffset === 1 &&
                range.startContainer.nodeType === Node.TEXT_NODE &&
                previousElement
            ) {
                // previousElement id
                const previousElementId = previousElement.id;

                const mentionElement =
                    document.getElementById(previousElementId);

                if (mentionElement) {
                    mentionElement.remove();
                    match = mentionElement?.id?.match(/mention-(.+)/);
                    extractedId = match?.[1];
                }
            }
            if (insideElement) {
                // insideElement id
                const insideElementId = insideElement?.id;

                // Check if the ID starts with a certain prefix
                if (insideElementId && insideElementId.startsWith('mention-')) {
                    insideElement.remove();
                    match = insideElementId?.match(/mention-(.+)/);
                }
            }
            extractedId = match?.[1];
            setRemovedMentionTagId(extractedId);
        }
    };

    const onMentionsSubmit = item => {
        if (item === undefined) return setMentionsModal(false);
        if (item === undefined) return setMentionsChannelModal(false);
        if (item === undefined) return setMentionsRoleModal(false);

        setMentionArray(prev => [
            ...prev,
            { user_id: item.id, username: item.username },
        ]);

        replaceTags(item?.username, item?.id);
        setMentionsModal(false);
        setMentionsChannelModal(false);
        setMentionsRoleModal(false);

        inputRef?.current?.focus();
        setMentionsData(members);
        setChannelsData(allChannel);
        setRolesData(allRoles);
        setMentionText('');
        // formatMentions();
    };

    const onMentionsChannelSubmit = item => {
        if (item === undefined) return setMentionsChannelModal(false);
        setChannelArray(prev => [...prev, { name: item.name, id: item._id }]);
        replaceChannelTags(item?.name, item?._id);
        setMentionsChannelModal(false);

        inputRef?.current?.focus();
        setChannelsData(allChannel);
        setMentionText('');
        // formatMentions();
    };

    const onMentionsRoleSubmit = item => {
        if (item === undefined) return setMentionsRoleModal(false);
        setRoleArray(prev => [...prev, { name: item.name, id: item._id }]);
        replaceRoleTag(item?.name, item?._id, item?.color);
        setMentionsRoleModal(false);

        inputRef?.current?.focus();
        setRolesData(allRoles);
        setMentionText('');
    };

    const replaceTags = (username, id) => {
        const lastIndex = msgInputValue.lastIndexOf('@');
        setMsgInputValue(prev =>
            prev.slice(0, lastIndex).concat(formatMention(username, id)),
        );
    };

    const replaceChannelTags = (name, id) => {
        const lastIndex = msgInputValue.lastIndexOf('#');
        setMsgInputValue(prev =>
            prev.slice(0, lastIndex).concat(formatChannelMention(name, id)),
        );
    };

    const replaceRoleTag = (name, id, color) => {
        const lastIndex = msgInputValue.lastIndexOf('$');
        setMsgInputValue(prev =>
            prev.slice(0, lastIndex).concat(formatRoleMention(name, id)),
        );
    };

    const replaceLinks = () => {
        let replacedText = inputRef?.current?.textContent;

        const linkPattern = /https?:\/\/\S+\.\S{2,}/g;
        const match = inputRef?.current.textContent.match(linkPattern);

        match?.forEach(el => {
            const alreadyFormattedLink = inputRef.current.innerHTML.includes(
                formatLink(el),
            );
            if (alreadyFormattedLink) return;

            replacedText = msgInputValue.replace(el, formatLink(el));
            setMsgInputValue(replacedText);
        });
    };

    const formatLink = match => {
        return `<a style="color: var(--purple);cursor: pointer;text-decoration: underline;" target="_blank">${match}</a>&nbsp;`;
    };

    const formatMention = (username, id) => {
        return `&nbsp;<p contenteditable="false" id='mention-${id}' style='display: inline; cursor: pointer; width: fit-content; height: fit-content; 
        padding: 2px 3px; justify-content: center; align-items: center; border-radius: 4px; background: 
        var(--tag-bg-brown); color: var(--tag-text); font-size: 12px; font-weight: 500; line-height: 16px;'
        >@${username}</p>&nbsp;`;
    };

    const formatChannelMention = (name, id) => {
        return `&nbsp;<p contenteditable="false" id='mention-${id}' style='display: inline; cursor: pointer; width: fit-content; height: fit-content; 
        padding: 2px 3px; justify-content: center; align-items: center; border-radius: 4px; background: 
        var(--text-secondary); color: var(--msg-actions); font-size: 12px; font-weight: 500; line-height: 16px;'
        >#${name}</p>&nbsp;`;
    };

    const formatRoleMention = (name, id) => {
        return `&nbsp;<p contenteditable="false" id='mention-${id}' style='display: inline; cursor: pointer; width: fit-content; height: fit-content; 
        padding: 2px 3px; justify-content: center; align-items: center; border-radius: 4px; background: 
        #555; font-size: 12px; font-weight: 500; line-height: 16px; position: relative;'
        >$${name}</p>&nbsp;`;
    };

    const keyHandlers = {
        '@': () => {
            if (directMessage) setMentionsModal(true);
        },
        '#': () => {
            if (directMessage) setMentionsChannelModal(true);
        },
        $: () => {
            if (directMessage) setMentionsRoleModal(true);
        },
        ' ': e => {
            replaceLinks();
            if (mentionsModal) {
                setMentionsModal(false);
            }
            if (mentionsChannelModal) {
                setMentionsChannelModal(false);
            }
            if (mentionsRoleModal) {
                setMentionsRoleModal(false);
            }
        },
        Backspace: () => {
            if (mentionsModal && mentionText === '') {
                setMentionsModal(false);
            } else if (
                !mentionsModal &&
                msgInputValue.charAt(msgInputValue.length - 1) === '~'
            ) {
            }

            if (mentionsChannelModal && mentionText === '') {
                setMentionsChannelModal(false);
            } else if (
                !mentionsChannelModal &&
                msgInputValue.charAt(msgInputValue.length - 1) === '~'
            ) {
            }

            if (mentionsRoleModal && mentionText === '') {
                setMentionsRoleModal(false);
            } else if (
                !mentionsRoleModal &&
                msgInputValue.charAt(msgInputValue.length - 1) === '~'
            ) {
            }
        },
        Enter: e => {
            if (!e.shiftKey && msgInputValue !== '') {
                e.preventDefault();
                !mentionsModal &&
                    !mentionsChannelModal &&
                    !mentionsRoleModal &&
                    submitMessasgeHandler(e);
            }
        },
        ArrowUp: e => {
            if (mentionsModal) e.preventDefault();
            if (mentionsChannelModal) e.preventDefault();
            if (mentionsRoleModal) e.preventDefault();
        },
        Escape: e => {
            if (mentionsModal) setMentionsModal(false);
            if (mentionsChannelModal) setMentionsModal(false);
            if (mentionsRoleModal) setMentionsRoleModal(false);
        },
    };

    const handleKeyPress = e => {
        if (keyHandlers[e.key]) {
            keyHandlers[e.key](e);
        }
    };

    useEffect(() => {
        if (mentionsModal && mentionsData.length === 0) {
            setMentionsModal(false);
        }
        if (mentionsChannelModal && channelsData.length === 0) {
            setMentionsChannelModal(false);
        }
        if (mentionsRoleModal && rolesData.length === 0) {
            setMentionsRoleModal(false);
        }
        inputRef?.current?.addEventListener('keydown', handleKeyPress);

        // Remove event listener when component unmounts
        return () => {
            inputRef.current &&
                inputRef.current.removeEventListener('keydown', handleKeyPress);
        };
    }, [
        mentionText,
        mentionsModal,
        mentionsChannelModal,
        inputRef,
        msgInputValue,
    ]);

    useEffect(() => {
        mentionsModal && filterMentionArray();
        mentionsChannelModal && filterChannelsArray();
        mentionsRoleModal && filterRolesArray();

        if (removedMentionTagId) {
            setMentionArray(prevMentionArray =>
                prevMentionArray?.filter(
                    item => !removedMentionTagId.includes(item?.user_id),
                ),
            );
            setChannelArray(prevMentionArray =>
                prevMentionArray?.filter(
                    item => !removedMentionTagId.includes(item?.id),
                ),
            );
            setRoleArray(prevMentionArray =>
                prevMentionArray?.filter(
                    item => !removedMentionTagId.includes(item?.id),
                ),
            );
        }
    }, [
        mentionText,
        mentionsModal,
        mentionsChannelModal,
        mentionsRoleModal,
        removedMentionTagId,
    ]);

    const toggleEmojiBoardHandler = event => {
        event.preventDefault();
        setEmojiIsShown(prev => !prev);
    };

    const selectEmojiHandler = emoji => {
        setMsgInputValue(prev => prev.concat(emoji.emoji));
    };

    const onInputFocus = () => {
        setEmojiIsShown(false);
        setIsAttachMenu(false);
    };

    const toggleAddMediaHandler = () => {
        if (isAttachMenu) {
            setPastedFiles(null);
            setIsAttachMenu(false);
        } else {
            setIsAttachMenu(true);
        }
    };

    const closeReplyHandler = () => {
        props.closeReply();
    };

    useEffect(() => {
        const pasteHandler = event => {
            const clipboardData = event.clipboardData;
            const files = clipboardData?.files;
            const isInputFocused = document.activeElement === inputRef?.current;
            if (!files || files.length === 0) return;
            if (files && files.length > 0 && isInputFocused) {
                event.preventDefault();
                setIsAttachMenu(true);
                setPastedFiles(Object.values(files));
            }
        };
        document.addEventListener('paste', pasteHandler);

        return () => document.removeEventListener('paste', pasteHandler);
    }, [inputRef]);

    const sanitizeHtmlEventHandlers = html => {
        const sanitizedHtml = DOMPurify.sanitize(html, {
            removeEventListener: true,
            FORBID_ATTR: ['style'],
            FORBID_STYLES: ['position', 'z-index', 'height', 'width'],
        });
        return sanitizedHtml;
    };

    const handlePaste = event => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text');
        const sanitizedHtml = sanitizeHtmlEventHandlers(pastedText);
        const updatedContentWithBreaks = pastedText.replaceAll(
            '<br />',
            '\n  ',
        );
        const updatedContentWithEntities = updatedContentWithBreaks
            .replaceAll(/>/g, '&gt;')
            .replaceAll(/</g, '&lt;');
        document.execCommand('insertHTML', false, sanitizedHtml);
        inputRef.current.scrollTop = inputRef.current.scrollHeight;
    };

    useEffect(() => {
        if (msgInputValue !== '') {
            setIsBtnDisabled(false);
        } else {
            setIsBtnDisabled(true);
            mentionsModal && setMentionsModal(false);
            mentionsChannelModal && setMentionsChannelModal(false);
            mentionsRoleModal && setMentionsChannelModal(false);
        }
    }, [msgInputValue]);

    useEffect(() => {
        inputRef?.current?.focus();
        setChannelsData(allChannel);
        setRolesData(allRoles);
        setMentionsData([...members, ...CONSTANT_MENTIONS]);
        setMsgInputValue('');
        setMentionArray([]);
    }, [props.activeChannelId]);

    const handleController = value => {
        setMsgInputValue(value);
    };
    return {
        isAttachMenu,
        submitAttachmentMessageHandler,
        pastedFiles,
        emojiIsShown,
        selectEmojiHandler,
        submitMessasgeHandler,
        closeReplyHandler,
        toggleAddMediaHandler,
        inputRef,
        onInputFocus,
        msgInputValue,
        inputChangeHanlder,
        toggleEmojiBoardHandler,
        isBtnDisabled,
        mentionsModal,
        mentionsChannelModal,
        handleKeyPress,
        mentionsData,
        channelsData,
        rolesData,
        allChannel,
        onMentionsSubmit,
        onMentionsChannelSubmit,
        onMentionsRoleSubmit,
        replaceTags,
        replaceChannelTags,
        replaceRoleTag,
        handleKeyDown,
        handlePaste,
        handleController,
        setMentionsRoleModal,
        mentionsRoleModal,
        mentionText,
    };
};

export default useNewMessageForm;
