import { FC, memo } from 'react';
import { Spinner } from 'react-activity';
import { ViewportList } from 'react-viewport-list';

import { useAppSelector } from '@/hooks/useRedux';

import classes from './Connections.module.css';
import ConnectionsItem from './ConnectionsItem';
import { ViewportProps } from './connectionsTypes';

const ConnectionsViewPort: FC<ViewportProps> = memo(props => {
    const { items, viewPortRif, onOpenProfileModal } = props;

    const isLoading = useAppSelector(state => state.utilReducer.memberLoader);

    const settings = {
        overscan: 4,
        viewportRef: viewPortRif,
        initialIndex: 0,  
        indexesShift: 0,
        initialAlignToTop: false,
        items: items,
    };

    return (
        <ul className={classes['viewport-parent']}>
            {!items && isLoading ? (
                <Spinner className={classes['spin-wrap']} size={14} />
            ) : (
                <ViewportList {...settings}>
                    {(item, key) => (
                        <ConnectionsItem
                            onOpenProfileModal={onOpenProfileModal}
                            item={item}
                            key={`${key}-${item?.id || ''}`}
                        />
                    )}
                </ViewportList>
            )}
        </ul>
    );
});

export default ConnectionsViewPort;
