import { AnimatePresence } from 'framer-motion';
import { useContext } from 'react';

import { NotesContext } from '@/context/notes/NotesContext';

import NoteCategoryLoader from './NoteCategoryLoader';
import classes from './NotesSidebar.module.css';
import SidebarListItem from './SidebarListItem';

const SidebarList: React.FC<{}> = () => {
    const notesContext = useContext(NotesContext);
    const { isFullMode, isFetchingCat, noteCat, errorFetchCat } = notesContext;

    return (
        <ul
            style={{ display: isFullMode ? 'none' : 'block' }}
            className={classes['list']}>
            {isFetchingCat && <NoteCategoryLoader />}
            {!isFetchingCat && errorFetchCat && <p>error fetching groups</p>}
            {!isFetchingCat && noteCat && (
                <>
                    <SidebarListItem
                        id={'All'}
                        index={0}
                        title={'All'}
                        key={0}
                    />
                    <AnimatePresence>
                        {noteCat.map((item, key) => (
                            <SidebarListItem
                                index={key + 1}
                                icon={item?.icon}
                                id={item.id}
                                title={item.title}
                                key={key + 1}
                            />
                        ))}
                    </AnimatePresence>
                </>
            )}
        </ul>
    );
};
export default SidebarList;
