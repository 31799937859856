import { Trans, t } from '@lingui/macro';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import FilterIcon from '@/assets/icons/filter-icon.svg';
import SearchIcon from '@/assets/icons/search-light.svg';
import { MemberManagementContext } from '@/components/ManagementModal/member-management/context/MemberManagementContext';

import Badge from '../badge/Badge';
import SearchInput from '../search-input/SearchInput';

import classes from './TabsManagement.module.css';

type Props = {
    children?: React.ReactNode;
    handleSearchInput?: any;
    tabs?: any;
    searchText?: any;
    onChangeTab?: any;
    isOpencheckboxModal?: any;
    setIsOpenCheckboxModal?: any;
    checkboxFilterOptionsData?: any;
    allSelectCheckbox?: any;
    allClearCheckbox?: any;
    isRoleSelected?: any;
    onChangeCheckbox?: any;
    fromPage?: string;
    isNoRole?: boolean;
};

const TabsManagement: FC<Props> = props => {
    const [active, setActive] = useState(props.tabs[0]?.value);
    const [searchOpen, setSearchOpen] = useState(false);
    const modalRef = useRef(null);

    const isNoRole = props?.isNoRole ?? true;
    const handleTabChange = item => {
        props.onChangeTab && props.onChangeTab(item);
        setActive(item.value);
    };

    const closeRoleFilterModal = () => {
        props.setIsOpenCheckboxModal(false);
    };

    useEffect(() => {
        const handleClickOutside = event => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeRoleFilterModal();
            }
        };

        if (props.isOpencheckboxModal) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [props.isOpencheckboxModal]);

    const _renderFilter = (item: object, key) => (
        <div
            key={key}
            onClick={() => handleTabChange(item)}
            className={`${classes['filter-item']}  ${
                active === item?.value && classes['active-backgroud']
            }`}>
            <span>{item?.label}</span>
        </div>
    );

    const _renderVersionFilter = (item: any, key) => {
        return (
            <div
                key={key}
                onClick={() => handleTabChange(item)}
                className={`${classes['filter-item-version']} ${
                    active === item?.value && classes['active']
                }`}>
                <span
                    className={`${classes['tab-par']} ${
                        active === item?.value ? classes['active'] : ''
                    }`}>
                    {item?.label}
                </span>
                <span className={classes['date-label']}>{item?.date}</span>
            </div>
        );
    };

    const handleSearch = text => {
        props.handleSearchInput && props.handleSearchInput(text);
    };

    const checkboxNoRoleFilterItem = {
        _id: 'no-role-id',
        name: 'No Role',
        slug: 'no-role',
        color: '#555555',
        priority: 3,
        display_separately: false,
    };
    const { checkedRolesFilterCount } = useContext(MemberManagementContext);

    const _renderRoleFilter = () => {
        return (
            <div ref={modalRef} className={classes['filter-icon-container']}>
                <div className="relative">
                    <img
                        alt="filter"
                        style={{
                            filter:
                                props.isOpencheckboxModal && 'brightness(99)',
                        }}
                        className={classes['filter-container-img']}
                        onClick={() =>
                            props.setIsOpenCheckboxModal(prev => !prev)
                        }
                        src={FilterIcon}
                    />
                    <Badge className={classes['badge']}>
                        {checkedRolesFilterCount}
                    </Badge>
                </div>
                {props.isOpencheckboxModal && (
                    <div className={classes['filter-role-modal']}>
                        <div className={classes['triangle']}></div>
                        <div
                            className={
                                classes['filter-role-modal-btn-container']
                            }>
                            <div className={classes['filter-role-modal-btn']}>
                                <span
                                    onClick={() => props?.allSelectCheckbox()}>
                                    <Trans>Select All</Trans>
                                </span>
                            </div>
                            <div className={classes['filter-role-modal-btn']}>
                                <span onClick={() => props?.allClearCheckbox()}>
                                    <Trans>Clear All</Trans>
                                </span>
                            </div>
                        </div>

                        <div className={classes['filter-modal-row-container']}>
                            {isNoRole && (
                                <div
                                    key={checkboxNoRoleFilterItem._id}
                                    className={classes['filter-role-modal-row']}
                                    onClick={() =>
                                        props?.onChangeCheckbox(
                                            checkboxNoRoleFilterItem,
                                        )
                                    }>
                                    <input
                                        checked={props?.isRoleSelected(
                                            checkboxNoRoleFilterItem,
                                        )}
                                        type="checkbox"
                                        id={`role-checkbox-${checkboxNoRoleFilterItem._id}`}
                                        name={`role-checkbox-${checkboxNoRoleFilterItem._id}`}
                                    />
                                    <div
                                        className={
                                            classes['filter-modal-row-name']
                                        }>
                                        {checkboxNoRoleFilterItem?.name}
                                    </div>
                                </div>
                            )}

                            {props?.checkboxFilterOptionsData?.map(
                                (item, index) => (
                                    <div
                                        key={index}
                                        className={
                                            classes['filter-role-modal-row']
                                        }
                                        onClick={() =>
                                            props?.onChangeCheckbox(item)
                                        }>
                                        <input
                                            checked={props?.isRoleSelected(
                                                item,
                                            )}
                                            type="checkbox"
                                            id={`role-checkbox-${item.id}`}
                                            name={`role-checkbox-${item.id}`}
                                        />
                                        <div
                                            className={
                                                classes['filter-modal-row-name']
                                            }>
                                            {item?.name}
                                        </div>
                                    </div>
                                ),
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={classes['header-container']}>
            <div className={classes['header-wrapper']}>
                {Object?.values(props?.tabs ?? []).map((item, key) =>
                    props?.fromPage === 'VersionDownload'
                        ? _renderVersionFilter(item, key)
                        : _renderFilter(item, key),
                )}
            </div>
            <div className={classes['icons-container']}>
                {props?.checkboxFilterOptionsData && _renderRoleFilter()}
                {props.handleSearchInput &&
                    (searchOpen ? (
                        <SearchInput
                            onChange={text => handleSearch(text)}
                            onClose={() => {
                                setSearchOpen(false);
                                props?.handleSearchInput('');
                            }}
                            placeholder={t`Name or username`}
                            value={props?.searchText ?? ''}
                            autoFocus={true}
                        />
                    ) : (
                        <img
                            alt="search"
                            src={SearchIcon}
                            onClick={() => setSearchOpen(true)}
                        />
                    ))}
                {props.children}
            </div>
        </div>
    );
};

export default TabsManagement;
