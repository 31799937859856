import { Trans, t } from '@lingui/macro';
import { useEffect, useRef, useState } from 'react';

import { TextInput } from '@/UI';
import { truncateText } from '@/Utils/textUtils';
import PersonIcon from '@/assets/icons/profile-user.svg';
import { DEFAULT_IMAGES } from '@/constants';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './UserFilter.module.css';

function UserFilter(props) {
    const [isOpencheckboxModal, setIsOpenCheckboxModal] = useState(false);
    const memberListArray = useAppSelector(state => memberDataSelector(state));

    const [memberSearchText, setMemberSearchText] = useState('');
    const { selectedCheckboxUsersData, onChangeData } = props;
    const allSelectUserCheckbox = () => {
        onChangeData(memberListArray);
    };

    const allClearUserCheckbox = () => {
        onChangeData([]);
    };

    const isUserCheckboxSelected = item => {
        return selectedCheckboxUsersData?.some(
            checkboxItem => checkboxItem?.id === item?.id,
        );
    };

    const onChangeCheckboxUsers = item => {
        if (
            selectedCheckboxUsersData?.some(
                checkboxItem => checkboxItem?.id === item?.id,
            )
        ) {
            onChangeData(
                selectedCheckboxUsersData?.filter(
                    logType => logType?.id !== item?.id,
                ),
            );
        } else {
            onChangeData([...selectedCheckboxUsersData, item]);
        }
    };

    const onChangeMemberSearch = e => {
        setMemberSearchText(e.target.value);
    };
    const allClearSearchMemberInput = () => {
        setMemberSearchText('');
    };
    const filteredMembers = memberListArray?.filter(member => {
        return member?.username
            ?.toLowerCase()
            ?.includes(memberSearchText?.toLowerCase());
    });

    const modalRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = event => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsOpenCheckboxModal(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div ref={modalRef} className={classes['filter-icon-container']}>
            <img
                alt=""
                style={{
                    filter: isOpencheckboxModal && 'brightness(99)',
                    opacity: !isOpencheckboxModal && 0.5,
                }}
                className={classes['filter-container-img']}
                onClick={() => setIsOpenCheckboxModal(prev => !prev)}
                src={PersonIcon}
            />
            {isOpencheckboxModal && (
                <div className={classes['filter-role-modal']}>
                    <div className={classes['triangle']}></div>
                    <div className={classes['filter-role-modal-btn-container']}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                            }}>
                            <div className={classes['filter-role-modal-btn']}>
                                <span onClick={() => allSelectUserCheckbox()}>
                                    <Trans>Select All</Trans>
                                </span>
                            </div>

                            <div className={classes['filter-role-modal-btn']}>
                                <span onClick={() => allClearUserCheckbox()}>
                                    <Trans>Clear All</Trans>
                                </span>
                            </div>
                        </div>
                        <div className={classes['search-user-wrap']} style={{}}>
                            <TextInput
                                style={{
                                    height: 16,
                                    width: 280,
                                }}
                                placeHolder={t`Search Member`}
                                onChange={onChangeMemberSearch}
                                value={memberSearchText}
                            />
                            {memberSearchText && (
                                <div
                                    onClick={() => allClearSearchMemberInput()}
                                    className={classes['date-label']}>
                                    <b
                                        style={{
                                            cursor: 'pointer',
                                            marginLeft: 10,
                                        }}>
                                        Clear
                                    </b>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes['triangle']}></div>
                    <div className={classes['filter-row-container']}>
                        {filteredMembers?.map((item, index) => (
                            <div
                                key={index}
                                className={classes['filter-role-modal-row']}
                                onClick={e => {
                                    e.preventDefault();
                                    onChangeCheckboxUsers(item);
                                }}
                                style={{
                                    // border: isUserCheckboxSelected(item)
                                    //     ? '2px solid rgba(255, 255, 255, 1)'
                                    //     : '2px solid rgba(255, 255, 255, 0)',
                                    background: isUserCheckboxSelected(item)
                                        ? 'var(--blue-primary)'
                                        : '',
                                }}>
                                <input
                                    checked={isUserCheckboxSelected(item)}
                                    type="checkbox"
                                    id={`role-checkbox-${item?.id}`}
                                    name={`role-checkbox-${item?.id}`}
                                    style={{ display: 'none' }} // hide the actual checkbox
                                    onChange={e => {
                                        e.preventDefault();
                                        onChangeCheckboxUsers(item);
                                    }}
                                />
                                <label htmlFor={`role-checkbox-${item?.id}`}>
                                    <img
                                        alt=""
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 4,
                                            cursor: 'pointer',
                                            userSelect: 'none',
                                        }}
                                        src={
                                            item?.profile_picture ??
                                            DEFAULT_IMAGES.PROFILE_IMAGE
                                        }
                                    />
                                </label>
                                <div
                                    className={
                                        classes['filter-modal-row-name']
                                    }>
                                    {truncateText(item?.username, 16)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserFilter;
