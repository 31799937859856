import { FC } from 'react';

type propsTypes = {
    width?: string;
    height?: string;
    fill: string;
    fillOpacity?: string;
};

const SunIcon: FC<propsTypes> = props => {
    const { width, height, fill, fillOpacity } = props;
    return (
        <svg
            width={width ? width : '16'}
            height={height ? height : '18'}
            viewBox="0 0 16 18"
            fill={fill ? fill : 'white'}
            xmlns="http://www.w3.org/2000/svg">
            <g id="Sun_Off">
                <path
                    d="M12.5714 9C12.5714 11.4853 10.5247 13.5 8 13.5C5.47527 13.5 3.42857 11.4853 3.42857 9C3.42857 6.51472 5.47527 4.5 8 4.5C10.5247 4.5 12.5714 6.51472 12.5714 9Z"
                    fill={fill ? fill : 'white'}
                    fillOpacity={fillOpacity ? fillOpacity : '0.2'}
                />
                <path
                    d="M9.14286 16.875C9.14286 17.4963 8.63118 18 8 18C7.36882 18 6.85714 17.4963 6.85714 16.875C6.85714 16.2537 7.36882 15.75 8 15.75C8.63118 15.75 9.14286 16.2537 9.14286 16.875Z"
                    fill={fill ? fill : 'white'}
                    fillOpacity={fillOpacity ? fillOpacity : '0.2'}
                />
                <path
                    d="M16 5.0625C16 5.68382 15.4883 6.1875 14.8571 6.1875C14.226 6.1875 13.7143 5.68382 13.7143 5.0625C13.7143 4.44118 14.226 3.9375 14.8571 3.9375C15.4883 3.9375 16 4.44118 16 5.0625Z"
                    fill={fill ? fill : 'white'}
                    fillOpacity={fillOpacity ? fillOpacity : '0.2'}
                />
                <path
                    d="M9.14286 1.125C9.14286 1.74632 8.63118 2.25 8 2.25C7.36882 2.25 6.85714 1.74632 6.85714 1.125C6.85714 0.50368 7.36882 0 8 0C8.63118 0 9.14286 0.50368 9.14286 1.125Z"
                    fill={fill ? fill : 'white'}
                    fillOpacity={fillOpacity ? fillOpacity : '0.2'}
                />
                <path
                    d="M16 12.9375C16 13.5588 15.4883 14.0625 14.8571 14.0625C14.226 14.0625 13.7143 13.5588 13.7143 12.9375C13.7143 12.3162 14.226 11.8125 14.8571 11.8125C15.4883 11.8125 16 12.3162 16 12.9375Z"
                    fill={fill ? fill : 'white'}
                    fillOpacity={fillOpacity ? fillOpacity : '0.2'}
                />
                <path
                    d="M2.28571 12.9375C2.28571 13.5588 1.77404 14.0625 1.14286 14.0625C0.511675 14.0625 0 13.5588 0 12.9375C0 12.3162 0.511675 11.8125 1.14286 11.8125C1.77404 11.8125 2.28571 12.3162 2.28571 12.9375Z"
                    fill={fill ? fill : 'white'}
                    fillOpacity={fillOpacity ? fillOpacity : '0.2'}
                />
                <path
                    d="M2.28571 5.0625C2.28571 5.68382 1.77404 6.1875 1.14286 6.1875C0.511675 6.1875 0 5.68382 0 5.0625C0 4.44118 0.511675 3.9375 1.14286 3.9375C1.77404 3.9375 2.28571 4.44118 2.28571 5.0625Z"
                    fill={fill ? fill : 'white'}
                    fillOpacity={fillOpacity ? fillOpacity : '0.2'}
                />
            </g>
        </svg>
    );
};
export default SunIcon;
