const IndicatorIcon = ({
    fill = '#181436',
    stroke = '#302C55',
    style = {},
    className = '',
}) => {
    return (
        <svg
            style={style}
            className={className}
            width="86"
            height="116"
            viewBox="0 0 86 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                id="Vector 27"
                d="M31 20.6667H53.2028C64.2989 20.6667 74.8964 16.0575 82.4623 7.94078L88 2V114L82.4623 108.059C74.8964 99.9425 64.2989 95.3333 53.2028 95.3333H31C14.4315 95.3333 1 81.9019 1 65.3333V50.6667C1 34.0981 14.4315 20.6667 31 20.6667Z"
                fill={fill}
                stroke={stroke}
            />
        </svg>
    );
};
export default IndicatorIcon;
