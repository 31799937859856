import { useRef, useState } from 'react';

import { sendWebSocketData } from '@/Utils/webSocketUtils';
import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';
import { useAppSelector } from '@/hooks/useRedux';
import {
    rolesListSelector,
    serverDetailSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import { IMemberData, IServerData } from '@/types';

type Props = {};

const useUserModal = (props: any) => {
    const modalRef = useRef();
    const { user } = props;
    let style = {
        top: `clamp(0px, ${props?.YPosition + 10}px, 50vh)`,
        bottom: 'auto',
    };
    if (props.style) {
        style = { ...style, ...props.style };
    }
    const startDMHandler = () => {
        props.onNewChat(user.id);
    };

    const server: IServerData = useAppSelector(state =>
        serverDetailSelector(state),
    );
    const rolesList = useAppSelector(rolesListSelector);
    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );

    const roleList = rolesList?.reduce((arr, curr: any) => {
        return { ...arr, [curr._id]: curr };
    }, {});
    const userRedux: IMemberData = memberList[user.id];

    const selectRole = role => {
        let payload = {
            action: SOCKET_ACTIONS_WITH_TYPES.userRoleCreate.action,
            data: {
                users: [user.id],
                roles: [role],
            },
        };
        sendWebSocketData(JSON.stringify(payload));
        setShowRoleModal(false);
    };

    const deleteRole = role => {
        let payload = {
            action: SOCKET_ACTIONS_WITH_TYPES.userRoleDelete.action,
            data: {
                users: [user.id],
                roles: [role],
            },
        };
        sendWebSocketData(JSON.stringify(payload));
        setShowRoleModal(false);
    };

    const [showRoleModal, setShowRoleModal] = useState(false);
    const [searchData, setSearchData] = useState(rolesList);

    const onSearch = e => {
        let query = e.target.value.toLowerCase();
        const newData = [...rolesList];
        setSearchData(
            newData.filter((el: any) => el.name.toLowerCase().includes(query)),
        );
    };

    return {
        modalRef,
        style,
        roleList,
        selectRole,
        deleteRole,
        showRoleModal,
        searchData,
        onSearch,
        user,
        startDMHandler,
        userRedux,
        setShowRoleModal,
    };
};

export default useUserModal;
