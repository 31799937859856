const ExitIcon = ({ className = '', onClick = (a: any) => {} }) => {
    return (
        <svg
            className={className}
            onClick={onClick}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Icon">
                <g id="logout">
                    <path
                        id="Vector"
                        d="M5.93311 5.03997C6.13977 2.63997 7.37311 1.65997 10.0731 1.65997H10.1598C13.1398 1.65997 14.3331 2.85331 14.3331 5.83331V10.18C14.3331 13.16 13.1398 14.3533 10.1598 14.3533H10.0731C7.39311 14.3533 6.15977 13.3866 5.93977 11.0266"
                        stroke="#FB433A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M10.0002 8H2.41357"
                        stroke="#FB433A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_3"
                        d="M3.89984 5.76666L1.6665 8L3.89984 10.2333"
                        stroke="#FB433A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
};
export default ExitIcon;
