import { ReactComponent as ServersIcon } from '@/assets/icons/audit-log-icon.svg';
import { ReactComponent as MessagesIcon } from '@/assets/icons/message.svg';
import { ReactComponent as NotesIcon } from '@/assets/icons/notes.svg';
import { ReactComponent as ConnectionIcon } from '@/assets/icons/profile-user.svg';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { mobileViewActions } from '@/store/mobile-view-slice';
import { activeChannelIdSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './MobileNavBar.module.css';

const MobileNavBar: React.FC<{}> = () => {
    const dispatch = useAppDispatch();
    const activeMobileTab = useAppSelector(
        state => state.mobileViewReducer.activeTab,
    );
    const activeChannelId = useAppSelector(activeChannelIdSelector);

    const chooseMobileOption = (key: number) => {
        dispatch(mobileViewActions.changeActiveTab(key));
    };

    const listItems = [
        { icon: <ServersIcon />, name: 'Servers' },
        { icon: <MessagesIcon />, name: 'Messages' },
        { icon: <NotesIcon />, name: 'Notes' },
        { icon: <ConnectionIcon />, name: 'People' },
    ];

    const addContactTab = (key: number) => {
        return (!activeChannelId || activeChannelId === '') && key === 3;
    };

    return (
        <div className={`${classes['main']}`}>
            <ul>
                {listItems.map((el, key) => {
                    if (addContactTab(key)) {
                        return;
                    }
                    return (
                        <li
                            className={
                                activeMobileTab === key
                                    ? classes['active-tab']
                                    : ''
                            }
                            key={key}
                            onClick={() => chooseMobileOption(key)}>
                            {el.icon}
                            {el.name}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
export default MobileNavBar;
