import { useFormik } from 'formik';
import { useState } from 'react';

import { fetchBannedToServer } from '@/api/member';
import { REQUEST_TYPE } from '@/constants/requstTypes';
import useCustomSweetAlert from '@/hooks/useCustomSweetAlert';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
    getOrganizationsMembersData,
    postAddMembertoServer,
    putCreateMember,
} from '@/store/organization-reducer';
import { serverDetailSelector } from '@/store/server-data-reducer/selectors-server-data';
import { getMembersData } from '@/store/server-data-reducer/server-data-reducer';

//form
interface FormValues {
    username: string;
    name: string;
    email: string;
    password: string;
    confirm_password: string;
    external: boolean;
}

function useMemberAddUser(props) {
    const { setIsAddUserModal } = props;

    const dispatch = useAppDispatch();
    const { successAlertModal, errorAlertModal } = useCustomSweetAlert();
    const [loading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const serverData = useAppSelector(state => serverDetailSelector(state));
    const activeServerId = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );
    const activeOrganizationId = serverData?.customer_id;

    // form
    const initialValues: FormValues = {
        username: '',
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        external: false,
    };

    // 1. Create User
    const handleMemberCreate = async (values, organizationId) => {
        try {
            let payload = values;
            let returnResUser;

            payload.organizationId = organizationId;
            payload.role = isAdmin ? 'admin' : 'member';
            payload.external = payload.external ? '1' : '0';
            const res = await dispatch(putCreateMember(payload));

            if (!res?.payload?.data) {
                errorAlertModal('Error', res?.payload?.error?.message);
                return;
            }
            if (res?.payload?.data?.status == false) {
                errorAlertModal('Error', res?.payload?.data?.message);
            }
            if (res?.payload?.data?.user) {
                returnResUser = res?.payload?.data?.user;
            }
            if (res?.payload?.data?.status) {
                setIsAdmin(false);
                formik.setFieldValue('external', false);
                dispatch(getOrganizationsMembersData(organizationId));
            } else {
                formik.setErrors(res?.payload?.data?.errors ?? {});
            }

            return returnResUser;
        } catch (error) {
            console.error('Error in handleMemberCreate:', error);
        }
    };

    // 2. moves user to server
    const handleMemberSave = async (user, organizationId) => {
        try {
            let requestType = REQUEST_TYPE.POST;
            const payload = {
                organizationId: organizationId,
                user_id: user?.id,
                serverId: activeServerId,
                is_admin: isAdmin ? 1 : 0,
                requestType: requestType,
            };
            const res = await dispatch(postAddMembertoServer(payload));
            if (!res?.payload?.data) {
                errorAlertModal('Error', res?.payload?.error?.message);
                return;
            }
        } catch (error) {
            console.error('Error in handleMemberSave:', error);
        }
    };

    // form submit
    const onSubmit = async (values: FormValues, actions) => {
        try {
            setLoading(true);
            const user = await handleMemberCreate(values, activeOrganizationId);

            if (user) {
                await handleMemberSave(user, activeOrganizationId);
                await dispatch(getMembersData(activeServerId));
                await dispatch(fetchBannedToServer(activeServerId));
                successAlertModal();
                setIsAddUserModal(false);
            }

            actions.setSubmitting(false);
        } catch (error) {
            console.error('Error in onSubmit:', error);
        } finally {
            setLoading(false);
        }
    };

    // validate
    const MIN_PASSWORD_LENGTH = 8;

    const validate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        // Validate required fields only if they have been touched
        if (!values.username && formik.touched.username) {
            errors.username = 'Username is required!';
        }

        if (!values.name && formik.touched.name) {
            errors.name = 'Name is required!';
        }

        if (!values.email && formik.touched.email) {
            errors.email = 'Email is required!';
        }

        if (!values.password && formik.touched.password) {
            errors.password = 'Password is required!';
        } else if (
            values.password &&
            values.password.length < MIN_PASSWORD_LENGTH
        ) {
            errors.password = `Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`;
        }

        if (!values.confirm_password && formik.touched.confirm_password) {
            errors.confirm_password = 'Confirm Password is required!';
        } else if (
            values.password &&
            values.confirm_password &&
            values.password !== values.confirm_password
        ) {
            errors.confirm_password = 'Passwords do not match!';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate,
    });
    return { loading, isAdmin, setIsAdmin, serverData, formik };
}

export default useMemberAddUser;
