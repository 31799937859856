import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as FullScreenIcon } from '@/assets/icons/fullscreen.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/heart-empty.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/note-copy.svg';
import { NotesContext } from '@/context/notes/NotesContext';

import DeleteNotePopover from '../delete-note/DeleteNotePopover';
import { editNoteFav } from '../editNote';

import classes from './NoteDetails.module.css';
import NoteSaveStatus from './NoteSaveStatus';
import NoteSearch from './NoteSearch';

const NoteDetailsHeader: React.FC<{}> = () => {
    const notesContext = useContext(NotesContext);
    const {
        setActiveNote,
        setNotes,
        activeNote,
        setIsFullMode,
        isFullMode,
        copyNoteContent,
        setIsForwardModal,
        setIsColorPickModal,
    } = notesContext;
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const forwardHandler = (
        ev: React.MouseEvent<SVGSVGElement, MouseEvent>,
    ) => {
        ev.stopPropagation();
        setIsForwardModal(true);
    };

    const openColorPicker = () => {
        setIsColorPickModal(true);
    };

    const changeFavSuccess = (newfavValue: number) => {
        setNotes(prev => {
            const targetIndex = prev.findIndex(el => el.id === activeNote.id);

            const newArray = [
                ...prev.slice(0, targetIndex),
                { ...prev[targetIndex], fav: newfavValue },
                ...prev.slice(targetIndex + 1),
            ];

            return newArray;
        });

        setActiveNote(prev => {
            return {
                ...prev,
                fav: newfavValue,
            };
        });
    };

    return (
        <div
            className={classes['icons-wrap']}
            onClick={e => e.preventDefault()}>
            {/* <ForwardIcon 
                onClick={forwardHandler}
                className="cursor-pointer"
                height={18}
                width={16}
            /> */}
            {activeNote && (
                <>
                    {!isMobile && (
                        <FullScreenIcon
                            height={18}
                            width={16}
                            fill="#ffffff"
                            onClick={() => setIsFullMode(prev => !prev)}
                        />
                    )}
                    <NoteSaveStatus />
                    <HeartIcon
                        onClick={ev =>
                            editNoteFav(ev, activeNote, changeFavSuccess)
                        }
                        className={`"cursor-pointer ${classes['heart']} ${
                            activeNote && activeNote.fav
                                ? classes['active']
                                : ''
                        }`}
                        height={18}
                        width={16}
                    />
                    <CopyIcon
                        onClick={copyNoteContent}
                        className="cursor-pointer"
                        height={18}
                        width={16}
                    />
                    {activeNote && <DeleteNotePopover />}
                </>
            )}
            {!isFullMode && !isMobile && <NoteSearch />}
        </div>
    );
};
export default NoteDetailsHeader;
