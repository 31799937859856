import { Trans } from '@lingui/macro';
import { useContext } from 'react';

import { Pagination } from '@/UI';
import classes from './Statistics.module.css';
import { StatisticsContext } from './StatisticsContext';

interface IProps {}
const StatisticsPagination: React.FC<IProps> = () => {
    const { metaData, setPageIndex } = useContext(StatisticsContext);

    return (
        <div style={{ gap: 0 }} className={classes['pagination']}>
            <div style={{ marginTop: 15 }}>
                <Pagination metaData={metaData} setPageIndex={setPageIndex} />
            </div>
            <div
                className={classes['date-label']}
                style={{ display: 'flex', gap: 10 }}>
                <p className="mb-0">
                    <Trans>Total Log</Trans>:{' '}
                    <b>{metaData?.total_message_statistic}</b>
                </p>
                <p className="mb-0">
                    <Trans>Total Page</Trans>: <b>{metaData?.total_pages}</b>
                </p>
            </div>
        </div>
    );
};
export default StatisticsPagination;
