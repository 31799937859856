import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import useIndexedDBUuid from '@/hooks/useIndexedDBUuid';

import './App.css';
import { checkPlatform, getCookie } from './Utils/utils';
import { fetchVersionList } from './components/ManagementModal/version-download/useVersionDownload';
import Newtab from './components/Newtab/Newtab';
import DesktopTitleBar from './components/desktop-title-bar/DesktopTitleBar';
import { PLATFORM, SESSION_STORAGE } from './constants/enum';
import { defaultLocale, dynamicActivate } from './i18n';
import router from './routes/router';

function App() {
    const dispatch = useDispatch();
    // void i18n;
    useIndexedDBUuid();

    const [newtab, setNewtab] = useState(false);

    // if (!selectedLanguageLocalStorage) {
    //     setLanguage('en');
    // }

    useEffect(() => {
        const broadcast = new BroadcastChannel('myChannelName');
        const isOpen = sessionStorage.getItem('appIsOpen');
        const myCookieValue = getCookie(SESSION_STORAGE.devicePassword);
        myCookieValue &&
            sessionStorage.setItem(
                SESSION_STORAGE.devicePassword,
                myCookieValue,
            );
        !isOpen && sessionStorage.setItem('appIsOpen', 'true');
        broadcast.postMessage({ appIsOpen: true });
        broadcast.onmessage = event => {
            if (event.data.appIsOpen) {
                setNewtab(true);
            }
        };
        dispatch(fetchVersionList());

        return;
    }, [dispatch]);

    const platform = checkPlatform();
    document.documentElement.setAttribute('data-platform', platform);

    useEffect(() => {
        dynamicActivate(defaultLocale);
    }, [defaultLocale]);

    if (newtab) return <Newtab />;
    return (
        <div className={`App`}>
            <I18nProvider i18n={i18n}>
                {platform === PLATFORM.DESKTOP && <DesktopTitleBar />}
                <RouterProvider router={router} />
            </I18nProvider>
        </div>
    );
}

export default App;
