import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UserPreferencesState {
    msg_key_hash: string | null;
    isDevMode: boolean | null;
}

const initialState: UserPreferencesState = {
    msg_key_hash: null,
    isDevMode: null,
};

const userPreferencesSlice = createSlice({
    name: 'user-preferences',
    initialState,
    reducers: {
        updateMsgKeyHash(state, action: PayloadAction<string | null>) {
            state.msg_key_hash = action.payload;
        },
        toggleDevMode(state, action: PayloadAction<boolean | null>) {
            state.isDevMode = action.payload;
        },
    },
});

export const userPreferencesActions = userPreferencesSlice.actions;
export default userPreferencesSlice.reducer;
