import { memo } from 'react';
import { Spinner } from 'react-activity';

import classes from './SelectedMediaDisplay.module.css';

const LoadingMedia: React.FC<{}> = () => {
    return (
        <div className={classes['media-loading-container']}>
            <Spinner color="white" />
        </div>
    );
};
export default memo(LoadingMedia);
