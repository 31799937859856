import React, { useEffect, useRef, useState } from 'react';
import sanitizeHtml from 'sanitize-html';

import { AppMarkdown } from '@/UI';
import {
    formatChannelMention,
    formatMention,
    formatMentionEveryone,
    formatRoleMention,
} from '@/Utils/messageFormatter';
import { onJoinChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import UserModal from '@/components/connections-section/user-modal/UserModal';
import useChatHelper from '@/hooks/useChatHelper';
import { useAppSelector } from '@/hooks/useRedux';
import {
    channelDataSelector,
    rolesListSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import classes from './MessageContent.module.css';

const MessageContent = ({
    message,
    onNewChat,
    mentionClickable,
    channel_mentions,
    role_mentions,
    messageId,
}: any) => {
    const modalRef = useRef();

    const [replacedContent, setReplacedContent] = useState<string>('');
    const [isModal, setIsModal] = useState('');
    const [mentions, setMentions] = useState({
        members: [],
        channel: [],
        links: [],
        roles: [],
    });

    const memberObj = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );
    const members = Object.values(memberObj ?? {});

    const channels = useAppSelector(state => channelDataSelector(state));

    const rolesList = useAppSelector(rolesListSelector);

    useEffect(() => {
        let mentionElement;
        // Attach click event after component is mounted
        if (mentions.members?.length > 0) {
            mentions.members.forEach(el => {
                mentionElement = document.getElementById(
                    `${messageId}-${el?.username}`,
                );
                if (mentionElement) {
                    mentionElement.addEventListener('click', () => {
                        if (mentionClickable) {
                            setIsModal(el);
                        }
                    });
                }
            });
        }

        if (mentions.channel?.length > 0) {
            mentions.channel.forEach(el => {
                mentionElement = document.getElementById(
                    `${messageId}-${el?._id}`,
                );
                if (mentionElement) {
                    mentionElement.addEventListener('click', () => {
                        if (mentionClickable) {
                            onJoinChannel(el._id);
                        }
                    });
                }
            });
        }

        // Cleanup event listener on component unmount
        return () => {
            if (mentionElement) {
                mentionElement.removeEventListener('click', () => {});
            }
        };
    }, [mentions, replacedContent]);

    const linkPattern = /(?:https?:\/\/)?(?:www\.)?\S+\.\S+/g;
    useEffect(() => {
        const mentionPattern = /(@[^~\s]+)/g;
        const mentionEveryone = /@everyone/g;
        const channelTagPattern = /(#[^~\s]+)/g;
        const roleTagPattern = /(\$[^~\s]+)/g;

        let replacedText: string = '';
        if (typeof message === 'object') {
            replacedText = message?.content;
        } else {
            replacedText = message;
        }
        channel_mentions?.length > 0 &&
            channel_mentions.forEach(item => {
                try {
                    const regex = new RegExp(`#${item?.name}`, 'g');
                    item.id &&
                        typeof replacedText === 'string' &&
                        (replacedText = replacedText?.replace(
                            regex,
                            `#${item.id}`,
                        ));
                } catch (error) {
                    console.log('Error', error);
                }
            });

        role_mentions?.length > 0 &&
            role_mentions.forEach(item => {
                try {
                    const regex = new RegExp(`${item?.rolename}`, 'g');
                    item.role_id &&
                        typeof replacedText === 'string' &&
                        (replacedText = replacedText?.replace(
                            regex,
                            `${item.role_id} `,
                        ));
                } catch (error) {
                    console.log('Error', error);
                }
            });

        replacedText = sanitizeHtml(replacedText, {
            allowedTags: ['span', 'br', 'ins', 'code', 'blockquote'],
            allowedAttributes: {
                span: ['style'],
            },
            parseStyleAttributes: false,
        });

        replacedText = replacedText.replace(mentionEveryone, match => {
            return formatMentionEveryone('@everyone');
        });

        replacedText = replacedText.replaceAll(
            mentionPattern,
            (match, username) => {
                const member = members?.find(
                    m => `@${m.username}` === username,
                );

                if (member) {
                    if (
                        mentions.members.findIndex(
                            el => el.id === member.id,
                        ) !== -1
                    )
                        return match;
                    else
                        setMentions(prev => {
                            return {
                                ...prev,
                                members: [...prev.members, member],
                            };
                        });

                    return formatMention(
                        member?.username
                            ? '@' + member?.username
                            : member?.name,
                        `${messageId}-${member?.username}`,
                    );
                } else {
                    return match;
                }
            },
        );
        replacedText = replacedText.replaceAll(
            channelTagPattern,
            (match, channelId) => {
                const channel: any = channels.find(
                    (ch: any) => ch?._id === channelId.replace('#', ''),
                );
                if (channel) {
                    if (
                        mentions.members.findIndex(
                            el => el._did === channel._id,
                        ) !== -1
                    )
                        return;
                    else
                        setMentions(prev => {
                            return {
                                ...prev,
                                channel: [...prev.channel, channel],
                            };
                        });

                    return formatChannelMention(
                        channel?.name ? '#' + channel?.name : channel?._id,
                        `${messageId}-${channel?._id}`,
                    );
                } else {
                    return match;
                }
            },
        );

        // ROLES
        replacedText = replacedText.replaceAll(
            roleTagPattern,
            (match, roleId) => {
                const role = rolesList?.find(
                    m => m?._id === roleId.replace('$', ''),
                );
                if (role) {
                    if (
                        mentions.roles.findIndex(el => el.id === role._id) !==
                        -1
                    )
                        return match;
                    else
                        setMentions(prev => {
                            return {
                                ...prev,
                                roles: [...prev.roles, role],
                            };
                        });

                    return formatRoleMention(
                        role?.name ? role?.name : role?._id,
                        `${messageId}-${role?._id}`,
                    );
                } else {
                    return match;
                }
            },
        );
        setReplacedContent(replacedText);
    }, [message]);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            const handleClickOutside = (event: any) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsModal('');
                }
            };
            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerter(modalRef);

    return (
        <div className={classes['content']}>
            <AppMarkdown>
                {replacedContent.replaceAll('<br />', '&nbsp;  \n')}
            </AppMarkdown>

            {isModal !== '' && (
                <UserModal
                    ref={modalRef}
                    className={classes['user-modal']}
                    onNewChat={id => {
                        onNewChat(id);
                    }}
                    user={isModal}
                />
            )}
        </div>
    );
};
export default React.memo(MessageContent);
