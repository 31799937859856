
import { sortMemberRoleList } from '@/Utils/memberUtils';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from '../TableMemberManagement.module.css';

type IProp = { itemId: string | undefined };

const GroupColumn: React.FC<IProp> = ({ itemId }) => {
    const currentUser = useAppSelector(state => state.userReducer.user);

    const memberDataSelectorData = useAppSelector(state =>
        memberDataSelector(state),
    );

    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );

    const userRoles = memberDataSelectorData.find(
        (el: any) => el?.id === itemId,
    );

    const memberUser = memberList[currentUser?.id];
    const isExternal = memberUser.external === 1;

    const sortedMemberList = sortMemberRoleList(userRoles?.roles ?? []);

    return (
        <div className={classes['_renderGroupColumn']}>
            {sortedMemberList?.map((itemRole, keyRole) => {
                return (
                    <div key={keyRole} className={classes.badgeClass}>
                        <div
                            className={classes['badge']}
                            style={{
                                backgroundColor: itemRole?.color,
                            }}
                        />
                        {isExternal ? itemRole?.fakeName : itemRole?.name}
                    </div>
                );
            })}
        </div>
    );
};
export default GroupColumn;
