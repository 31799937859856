import { Trans } from '@lingui/macro';
import { FC } from 'react';

import classes from './TableStatistics.module.css';

const StatusCol: FC<{ item }> = ({ item }) => (
    <div className={classes['status-wrapper']}>
        <div
            className={classes['status']}
            style={{
                backgroundColor: item.state ? 'var(--green-2)' : 'var(--red)',
            }}
        />
        <span>
            {item.state ? <Trans>Online</Trans> : <Trans>Offline</Trans>}
        </span>
    </div>
);
export default StatusCol;
