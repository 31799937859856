import { Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { VList } from 'virtua';

import classes from './InviteFriends.module.css';
import ListItem from './ListItem';

type Props = {
    data: any;
    searchData: any;
    sendInvitationHandler: any;
    selectedId: any;
    setSelectedId: any;
    selectedTab: string;
    channel: any;
    section_id: any;
    handleInviteSave: any;
};

function ModalList(props: Props) {
    const {
        data,
        searchData,
        sendInvitationHandler,
        selectedId,
        setSelectedId,
        selectedTab,
        channel,
        section_id,
        handleInviteSave,
    } = props;

    const [addedItem, setAddedItem] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAddedItem(null);
        }, 1500);

        return () => clearTimeout(timer);
    }, [addedItem]);

    return (
        <div
            style={{
                position: 'relative',
            }}>
            <div
                style={{
                    opacity: !addedItem?.name ? 0 : 0.9,
                    zIndex: !addedItem?.name ? -1 : 9,
                    transform: !addedItem?.name
                        ? 'translateY(-100%)'
                        : 'translateY(-16px)',
                    transition: 'all 0.2s ease',
                }}
                className={classes['added-success-container']}>
                {addedItem?.name && (
                    <div className="">
                        <b>
                            <u>{addedItem?.name}</u>{' '}
                        </b>
                        <Trans>Successfully Added</Trans>!
                    </div>
                )}
            </div>

            <VList className={classes['list']}>
                {data?.length > 0 ? (
                    searchData?.length > 0 ? (
                        searchData?.map((el, key: number) => (
                            <ListItem
                                key={key}
                                index={key}
                                el={el}
                                sendInvitationHandler={sendInvitationHandler}
                                setSelectedId={setSelectedId}
                                selectedId={selectedId}
                                selectedTab={selectedTab}
                                channel={channel}
                                section_id={section_id}
                                handleInviteSave={handleInviteSave}
                                setAddedItem={setAddedItem}
                            />
                        ))
                    ) : (
                        <b
                            style={{
                                opacity: 0.5,
                                textAlign: 'center',
                                padding: '16px 0px',
                            }}>
                            <Trans>Not Found</Trans>!
                        </b>
                    )
                ) : (
                    <b
                        style={{
                            opacity: 0.5,
                            textAlign: 'center',
                            padding: '16px 0px',
                        }}>
                        <Trans>Loading</Trans>...
                    </b>
                )}
            </VList>
        </div>
    );
}

export default ModalList;
