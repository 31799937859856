import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from 'ckeditor5-build-full';
import { motion } from 'framer-motion';
import { useContext } from 'react';

import { NotesContext } from '@/context/notes/NotesContext';

import './NotesEditor.css';
import { ckEditorConfig } from './ckEditorConfig';

const NotesEditor: React.FC<{}> = () => {
    const context = useContext(NotesContext);
    const { changeNoteBodyHandler, body } = context;

    return (
        <motion.div
            initial={{ opacity: 0, y: 10 }}
            exit={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="ckeditor-wrapper">
            <CKEditor
                onChange={changeNoteBodyHandler}
                editor={FullEditor}
                data={`${body ? body : ''}`}
                config={ckEditorConfig}
            />
        </motion.div>
    );
};
export default NotesEditor;
