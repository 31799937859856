import React, { FC } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import ChannelsGroup from '../section/ChannelsGroup';

import { onDragEnd } from './onChannelDragEnd';
import useSections from './useSections';

type SectionsProps = {
    unreadOnly?: boolean;
};
const Sections: FC<SectionsProps> = ({ unreadOnly = false }) => {
    const columns = useSections(unreadOnly);

    return (
        <DragDropContext onDragEnd={result => onDragEnd(result, columns)}>
            {columns.map((column, index) => (
                <Droppable key={column._id} droppableId={column._id}>
                    {provided => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}>
                            <ChannelsGroup
                                unreadOnly={unreadOnly}
                                key={`${index}-${column._id}`}
                                section={column}
                            />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            ))}
        </DragDropContext>
    );
};

export default Sections;
