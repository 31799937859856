import { FC } from 'react';
import Slider from 'react-slick';

import checkIcon from '@/assets/icons/check.svg';
import copyIcon from '@/assets/icons/copy.svg';
import downloadIcon from '@/assets/icons/download.svg';
import { DEFAULT_IMAGES } from '@/constants';

import AnimatedCloseBtn from '../animated-close-btn/AnimatedCloseBtn';
import Icon from '../main-icon/Icon';

import classes from './MediaViewer.module.css';
import { MediaViewerProps } from './MediaViewerTypes';
import './slickStyles.css';
import useMediaViewer from './useMediaViewer';

const MediaViewer: FC<MediaViewerProps> = props => {
    const { data, message, initialSlide, owner, onClose } = props;

    const {
        settings,
        mediaToShow,
        isCopiedPhoto,
        isImage,
        downloadLinkRef,
        downloadMediaItemHandler,
        copyBlobImageToClipboard,
    } = useMediaViewer(data, initialSlide);

    return (
        mediaToShow?.length > 0 && (
            <>
                <div className={classes['backdrop']} onClick={onClose}></div>
                <div className={classes['modal']}>
                    <AnimatedCloseBtn onClose={onClose} />
                    <Slider {...settings}>
                        {mediaToShow.length > 0 &&
                            mediaToShow.map((mediaItem, key) => (
                                <div
                                    key={key}
                                    className={classes['main-image-wraper']}>
                                    {mediaItem.type.includes('image') && (
                                        <img
                                            data-name={
                                                mediaItem?.name
                                                    ? mediaItem.name
                                                    : 'covchat-image'
                                            }
                                            className={classes['slider-img']}
                                            src={mediaItem.src}
                                            alt={mediaItem.name}
                                        />
                                    )}
                                    {mediaItem.type.includes('video') && (
                                        <video
                                            onClick={e => e.stopPropagation()}
                                            src={mediaItem.src}
                                            controls
                                            className={classes['slider-img']}>
                                            <source
                                                type={
                                                    mediaItem?.type 
                                                    // 'video/mp4'
                                                }
                                                src={mediaItem.src}
                                                data-name={
                                                    mediaItem?.name
                                                        ? mediaItem.name
                                                        : 'covchat-video'
                                                }
                                            />
                                        </video>
                                    )}
                                </div>
                            ))}
                    </Slider>
                    <div className={classes['message-info']}>
                        <Icon
                            name={owner?.name ?? ''}
                            className={classes['owner-image']}
                            imageURL={
                                owner?.profile_picture ??
                                DEFAULT_IMAGES.PROFILE_IMAGE
                            }
                        />
                        <div>
                            <p>{owner?.name}</p>
                            <p className={classes['message']}>
                                {message ? message : ''}
                            </p>
                        </div>
                    </div>
                    <div className={classes['tools-bar']}>
                        {isImage && (
                            <div
                                onClick={copyBlobImageToClipboard}
                                className={classes['copy-icon-container']}>
                                <img
                                    alt=""
                                    className={classes['copy-icon']}
                                    src={copyIcon}
                                    style={{
                                        opacity: isCopiedPhoto ? '0.7' : '',
                                    }}
                                />
                                <img
                                    alt=""
                                    src={checkIcon}
                                    className={
                                        isCopiedPhoto
                                            ? classes['copied']
                                            : classes['copy']
                                    }
                                />
                            </div>
                        )}

                        <img
                            src={downloadIcon}
                            className={classes['downlaod-icon']}
                            onClick={downloadMediaItemHandler}
                            alt=""
                        />
                        <a
                            style={{ display: 'none' }}
                            href="/"
                            ref={downloadLinkRef}>
                            -
                        </a>
                    </div>
                </div>
            </>
        )
    );
};
export default MediaViewer;
