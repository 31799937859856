import { createChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';

var firstDMessage: { config: { type: string | undefined } } = {
    config: { type: undefined },
};
const useChatActions = () => {
    const createDMChannel = async (chnConfig: {} | any) => {
        firstDMessage = { config: chnConfig };
        createChannel(firstDMessage.config);
    };

    return { createDMChannel };
};

export default useChatActions;
