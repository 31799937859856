import { ReactComponent as LangIcon } from '@/assets/icons/languages-icon.svg';

import Popover from '../popover/Popover';
import LanguagesModal from '../server-details-section/short-profile/languages-modal/LanguagesModal';

import classes from './Login.module.css';

const LoginLanguageSwitch: React.FC = () => {
    const Content = () => (
        <LanguagesModal
            onCLose={() => {}}
            onChangeLanguage={() => {}}
            className={classes['lang-dropdown']}
        />
    );

    const Trigger = () => (
        <div className={classes['trigger-wrap']}>
            <LangIcon />
        </div>
    );

    return (
        <div className={classes['language-wrap']}>
            <Popover content={<Content />} trigger={<Trigger />} />
        </div>
    );
};

export default LoginLanguageSwitch;
