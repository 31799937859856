import { FC, useContext, useRef } from 'react';

import { NotesContext } from '@/context/notes/NotesContext';

import classes from './NotesTabs.module.css';
import { getOptions } from './options';

const NotesTabs: FC<{}> = () => {
    const itemRef = useRef(null);
    const options = getOptions();
    const notesContext = useContext(NotesContext);
    const { changeTabs, tabs } = notesContext;

    const handleTabClick = index => {
        const children = itemRef?.current?.children ?? '';
        const width = children[index].getBoundingClientRect().width;
        const parentLeft = itemRef.current?.getBoundingClientRect()?.left ?? 0;
        const shift = children[index].getBoundingClientRect().left;

        changeTabs({
            activeTab: index,
            indexShift: shift - parentLeft,
            indexWidth: width,
        });
    };

    return (
        <div className={classes['tabs-container']} ref={itemRef}>
            {options.map((tabItem, index) => (
                <div
                    key={index}
                    className={`${classes['tab']} ${
                        index === tabs.activeTab ? classes['active'] : ''
                    }`}
                    onClick={() => handleTabClick(index)}>
                    {tabItem}
                </div>
            ))}
            <div
                className={classes['index-line']}
                style={{
                    width: `${tabs.indexWidth}px`,
                    transform: `translateX(${tabs.indexShift}px)`,
                }}
            />
        </div>
    );
};

export default NotesTabs;
