import { copyImageToClipboard } from 'copy-image-clipboard';
import { memo, useState } from 'react';

import SaveBtn from '@/components/save-btn/SaveBtn';
import { ReactComponent as CheckIcon } from '@/assets/icons/check.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy-3.svg';

import classes from './SelectedMediaDisplay.module.css';
import { ImageMediaProps } from './SelectedMediaTypes';

const ImageMedia: React.FC<ImageMediaProps> = props => {
    const { noCopy, src, index, name } = props;

    const [isCopiedPhoto, setIsCopiedPhoto] = useState(false);

    const copyBlobImageToClipboard = (
        event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    ) => {
        event.stopPropagation();

        copyImageToClipboard(src)
            .then(() => {
                setIsCopiedPhoto(true);
                setTimeout(() => {
                    setIsCopiedPhoto(false);
                }, 3000);
            })
            .catch(error => {
                console.error('Error copying image: ', error);
            });
    };
    return (
        <div className={classes['image-container-1']}>
            {!noCopy && (
                <span className={classes['copy-button-container']}>
                    <SaveBtn src={src} name={name} />
                    <span>
                        {isCopiedPhoto ? (
                            <CheckIcon height={16} />
                        ) : (
                            <CopyIcon
                                onClick={copyBlobImageToClipboard}
                                height={15}
                                width={16}
                                fill="white"
                            />
                        )}
                    </span>
                </span>
            )}

            <img src={src} key={index} alt={name} />
        </div>
    );
};
export default memo(ImageMedia);
