import { useEffect, useState } from 'react';

import { truncateText } from '@/Utils/textUtils';
import { formatCreatedDate } from '@/Utils/utils';
import { DEFAULT_IMAGES } from '@/constants';
import { useAppSelector } from '@/hooks/useRedux';
import DoubleTick from '@/assets/icons/BlueTick.svg';
import EmptyTick from '@/assets/icons/EmptyTick.svg';

import classes from './Popup.module.css';

const ReadUnReadPopup = props => {
    const { target, time, filteredUsers, userId } = props;

    const [menuPosition, setMenuPosition] = useState(target);

    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );

    const handleMenuClick = owner => {
        const messageRect = owner?.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (windowHeight - messageRect?.bottom >= 250) {
            setMenuPosition('auto');
        } else {
            setMenuPosition(0);
        }
    };

    useEffect(() => {
        handleMenuClick(target);
    }, [target]);

    return (
        <div
            className={`${classes['owner-image-popup-container']}     ${
                filteredUsers.length > 0 ? classes.scrollable : ''
            }`}
            style={{ bottom: menuPosition }}>
            <div
                className={`${classes['owner-image-popup']} ${classes['width']}`}
                // ref={modalRef}
            >
                {filteredUsers
                    ?.filter(
                        item =>
                            item?.user_id !== userId &&
                            item?.status !== 'unseen',
                    )
                    .map((item, index) => {
                        const user = memberList[item?.user_id];

                        return (
                            <div
                                key={index}
                                className={`${classes['owner-image-popup-row']} ${classes['space']}`}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                    <img
                                        className={classes['icon']}
                                        src={
                                            user?.profile_picture
                                                ? user?.profile_picture
                                                : DEFAULT_IMAGES.PROFILE_IMAGE
                                        }
                                        alt="Profile"
                                    />
                                    <div
                                        style={{
                                            flexDirection: 'column',
                                            marginLeft: 10,
                                        }}>
                                        <p
                                            style={{
                                                fontSize: '14px',
                                                alignSelf: 'center',
                                                fontWeight: 'bold',
                                            }}>
                                            {truncateText(user?.name, 20)}
                                        </p>
                                        <p
                                            style={{
                                                fontSize: '13px',
                                                marginTop: 20,
                                            }}>
                                            {item?.status === 'seen'
                                                ? formatCreatedDate(
                                                      item.message_timestamp,
                                                  )
                                                : formatCreatedDate(time)}
                                        </p>
                                    </div>
                                </div>

                                <img
                                    style={{
                                        height: '10px',
                                        width: '19px',
                                    }}
                                    src={
                                        item?.status === 'seen'
                                            ? DoubleTick
                                            : EmptyTick
                                    }
                                    alt="Double Tick"
                                />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
export default ReadUnReadPopup;
