import attach from '@/assets/icons/attach.svg';
import EmojiIcon from '@/assets/icons/emojiPicker.svg';
import { ReactComponent as SendIcon } from '@/assets/icons/send.svg';

import ImgButton from '../custom-buttons/ImgButton';

import classes from './DisabledInput.module.css';

export const DisabledInput = props => {
    return (
        <div className={classes['disabled-input-container']}>
            <div className={classes['disabled-input']}>
                <img alt="" className={classes['img']} src={attach} />
                <span className={classes['permission-text']}>
                    {props?.placeholder}
                </span>
                <div
                    style={{
                        display: 'flex',
                        gap: 10,
                        margin: 0,
                    }}>
                    <img alt="" className={classes['img']} src={EmojiIcon} />
                    <ImgButton type="submit" className={classes.sendBTN}>
                        <SendIcon />
                    </ImgButton>
                </div>
            </div>
        </div>
    );
};
