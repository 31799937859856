import { decryptDataWithRSA } from '@/Utils/cryptoUtils';
import store from '@/store';
import { updateAuditLogs } from '@/store/server-data-reducer/server-data-reducer';
import { setStatisticsData } from '@/store/statistics-reducer';

export const handleGetStatistics = data => {
    store.dispatch(
        setStatisticsData({ data: data, metaData: data?.meta_data }),
    );
};

export const handleGetAuditLogs = async ({
    data,
    privateKey,
}: {
    data: any;
    privateKey: string;
}) => {
    if (data?.auditLog?.length === 0) {
        return store.dispatch(updateAuditLogs(data));
    }
    const decryptList = await Promise.all(
        data?.audit_log.map(async item => {

            if (item?.log_type?.startsWith('channel')) {
                const findNameItem = item?.log_details.find(
                    itemLogDetail => itemLogDetail?.data === 'name',
                );
                const decryptNewValue: any = await decryptDataWithRSA(
                    findNameItem?.new_value,
                    privateKey,
                );
                const decryptPreviousValue: any = await decryptDataWithRSA(
                    findNameItem?.previous_value,
                    privateKey,
                );

                // if (
                //     typeof decryptNewValue === 'string' ||
                //     typeof decryptPreviousValue === 'string'
                // ) {
                const findNameItemIndex = item?.log_details.findIndex(
                    el => el === findNameItem,
                );
                let temp = [...item.log_details];
                temp[findNameItemIndex] = {
                    ...findNameItem,
                    new_value:
                        typeof decryptNewValue === 'string'
                            ? decryptNewValue
                            : decryptNewValue?.ciphertext,
                    previous_value:
                        typeof decryptPreviousValue === 'string'
                            ? decryptPreviousValue
                            : decryptPreviousValue?.ciphertext,
                };
                return {
                    ...item,
                    log_details: temp,
                };
                // }
            } else return item;
        }),
    );
    const lastData = {
        audit_log: decryptList,
        meta_data: data.meta_data,
    };

    store.dispatch(updateAuditLogs(lastData));
};
