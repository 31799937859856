import arrowDown from '@/assets/icons/arrowDownPurple.svg';

import classes from './TableMemberManagement.module.css';

type IProps = {
    item;
    onClick;
};
const HeaderCell: React.FC<IProps> = ({ item, onClick }) => {
    return (
        <div onClick={onClick} className={classes['header-cell']}>
            <span>{item.label}</span>
            {!item?.disableSortFilter && <img alt="" src={arrowDown} />}
        </div>
    );
};
export default HeaderCell;
