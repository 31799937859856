import { FC, ReactNode, useRef, useState } from 'react';

import classes from './Tabs.module.css';

interface IProps {
    tabs: string[];
    tabsBody?: ReactNode;
    onChangeTab: (key: number) => void;
    className?: string;
    children?: ReactNode;
}
const Tabs: FC<IProps> = props => {
    const tabRef = useRef();

    const { tabs, tabsBody, onChangeTab, className = '', children } = props;
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const chooseTabHandler = key => {
        onChangeTab(key);
        setActiveTabIndex(key);
    };

    return (
        <div className={classes['container'] + ' ' + className}>
            <nav className={classes['tabs-header']}>
                {tabs && tabs.length > 0 && (
                    <ul className={classes['tabs-list']}>
                        {tabs.map((tab, key) => (
                            <li
                                ref={tabRef}
                                className={classes['tab-item']}
                                onClick={() => chooseTabHandler(key)}
                                key={key}>
                                <input
                                    type="radio"
                                    id={`tabItem-${key}`}
                                    name={'chooseTab'}
                                    value={tab}
                                    defaultChecked={
                                        key === activeTabIndex ? true : false
                                    }
                                />
                                <p className={classes['active-tab']}>{tab}</p>
                            </li>
                        ))}
                    </ul>
                )}
            </nav>
            <div className={classes['tabs-body']}>
                {children ? children : tabsBody ? tabsBody : ''}
            </div>
        </div>
    );
};
export default Tabs;
