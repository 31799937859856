import { Trans } from '@lingui/macro';
import { FC } from 'react';

import { dateFormatHHMM } from '@/Utils/dateUtils';
import { handleOpenThread } from '@/Utils/socket-actions/ThreadActions/threadActions';
import { truncateText } from '@/Utils/textUtils';
import errorSVG from '@/assets/icons/errorNotification.svg';
import success from '@/assets/icons/successNotification.svg';
import warning from '@/assets/icons/warning-white.svg';
import MediaMessage from '@/components/messages/media-message/MediaMessage';
import MessageContent from '@/components/messages/message-item/message-content/MessageContent';
import RenderReactions from '@/components/messages/message-item/render-reactions/RenderReactions';
import { DEFAULT_IMAGES } from '@/constants';
import { NOTIFICATION_TYPES } from '@/constants/enum';
import useNotificationHelper from '@/hooks/useNotificationHelper';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';
import { updateFocusNotification } from '@/store/util-reducer';
import { IMessageData } from '@/types';
import { IUser } from '@/types/slices/UserTypes';

import classes from './NotificationItem.module.css';

interface IExtendedMessageData extends IMessageData {
    notification_type: string;
}

type IProps = {
    item: IExtendedMessageData;
};

const notification_map = {
    [NOTIFICATION_TYPES.PRIMARY]: { icon: '', color: '' },
    [NOTIFICATION_TYPES.SUCCESS]: { icon: success, color: 'var(--green)' },
    [NOTIFICATION_TYPES.WARNING]: { icon: warning, color: 'var(--orange)' },
    [NOTIFICATION_TYPES.ERROR]: { icon: errorSVG, color: 'var(--red)' },
};

const NotificationItem: FC<IProps> = props => {
    const { item } = props;
    const dispatch = useAppDispatch();
    const date = new Date(item.created_at);

    const notificationType =
        notification_map[item.notification_type] ??
        notification_map[NOTIFICATION_TYPES.PRIMARY];
    const { joinChannelHandler } = useNotificationHelper();

    const members = useAppSelector(state => memberDataSelector(state));
    const user: IUser = useAppSelector(state => state.userReducer.user);
    const channelList = useAppSelector(
        state =>
            state.serverDataReducer.channelList[
                state.serverDataReducer.activeServer
            ],
    );

    const memberObject = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );

    const formmatData = () => {
        const emojiMap = new Map();
        item.reactions?.forEach((el: any) => {
            const emoji = el.emoji;
            const user_id = el.user_id;

            if (!emojiMap.has(emoji)) {
                emojiMap.set(emoji, { emoji: emoji, list: [] });
            }

            emojiMap.get(emoji).list.push({ user_id: user_id });
        });
        return Array.from(emojiMap.values());
    };

    const handleNotificationClick = () => {
        if (item?.thread_id ?? false) {
            dispatch(updateFocusNotification(false));
            dispatch(handleOpenThread(item.thread_id));
        } else {
            dispatch(updateFocusNotification(false));
            joinChannelHandler(item);
        }
    };

    return (
        <div
            onClick={handleNotificationClick}
            style={{
                borderLeft: `2px solid ${notificationType.color}`,
                borderBottom: '1px solid var(--notifications-line)',
            }}
            className={`${classes['container']} ${
                item.users[0].is_read ? classes['unread'] : classes['read']
            }`}>
            <div className={classes['title-container']}>
                <div className={classes['title-container']}>
                    <span
                        style={{ color: notificationType.color }}
                        className={classes['hashtag-title']}>
                        # {channelList[item.channel_id]?.name}
                    </span>
                    {item?.thread_id && (
                        <span style={{ color: 'var(--date-color)' }}>
                            <Trans>THREAD</Trans>
                        </span>
                    )}
                    {notificationType.icon && (
                        <div
                            className={classes['title-icon-container1']}
                            style={{
                                backgroundColor: notificationType.color,
                            }}>
                            <img
                                alt=""
                                src={notificationType.icon}
                                className={classes['title-icon']}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={classes['container-row']}>
                <img
                    alt=""
                    src={
                        memberObject[item.user_id]?.profile_picture ??
                        DEFAULT_IMAGES.PROFILE_IMAGE
                    }
                    className={classes['userImage']}
                />
                <div className={classes['wrapper']}>
                    <div className={classes['name-date-row']}>
                        <span className={classes['username']}>
                            {truncateText(memberObject[item.user_id]?.name, 25)}
                        </span>
                        <div className={classes['circleBreak']} />
                        <span className={classes['date']}>
                            {dateFormatHHMM(date)}
                        </span>
                    </div>
                    <div className={classes['message-wrapper']}>
                        <span className={classes['message']}>
                            {item.type === 1 && (
                                <MessageContent
                                    mentionClickable={false}
                                    message={'You are added in this channel'}
                                    onNewChat={() => {}}
                                    channel_mentions={item?.channel_mentions}
                                    role_mentions={item?.role_mentions}
                                    messageId={item?._id}
                                />
                            )}
                            {item.type !== 'reply' && item.type !== 1 && (
                                <MessageContent
                                    mentionClickable={false}
                                    message={
                                        item?.message?.substring(0, 200) +
                                        (item?.message?.length > 200
                                            ? '...'
                                            : '')
                                    }
                                    onNewChat={() => {}}
                                    channel_mentions={item?.channel_mentions}
                                    role_mentions={item?.role_mentions}
                                    messageId={item?._id}
                                />
                            )}
                        </span>
                    </div>
                    {item?.type === 'media' && (
                        <MediaMessage mediaFiles={item?.mediaFiles} />
                    )}
                    {item.reactions?.length > 0 && (
                        <RenderReactions
                            reactions={formmatData()}
                            user={user}
                            members={members}
                            clickReactionHandler={() => {}}
                        />
                    )}
                </div>
                {!item.users[0].is_read && (
                    <div
                        style={{ backgroundColor: notificationType.color }}
                        className={classes['unread-circle']}
                    />
                )}
            </div>
        </div>
    );
};

export default NotificationItem;
