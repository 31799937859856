import profileUser from '@/assets/icons/profile-user.svg';

export const formatLink = match => {
    return `<a style="color: var(--blue-primary);cursor: pointer;text-decoration: underline;" target="_blank"
               key={'link' + key} href=${match}>${match}</a>`;
};

export const formatMention = (username, id) => {
    return `<span id='${id}' style='display: inline; cursor: pointer; width: fit-content; height: fit-content; 
        padding: 2px 3px; justify-content: center; align-items: center; border-radius: 4px; background: 
        var(--tag-bg-brown); color: var(--tag-text); font-size: 12px; font-weight: 500; line-height: 16px;'
        >${username}</span>`;
};

export const formatMentionEveryone = username => {
    return `<span style='display: inline; cursor: pointer; width: fit-content; height: fit-content; 
        padding: 2px 3px; justify-content: center; align-items: center; border-radius: 4px; background: 
        var(--blue-1); color: var(--text-4); font-size: 12px; font-weight: 500; line-height: 16px;'
        >${username}</span>`;
};

export const formatChannelMention = (name, id) => {
    return `<span id='${id}' style='display: inline; cursor: pointer; width: fit-content; height: fit-content; 
        padding: 2px 3px; justify-content: center; align-items: center; border-radius: 4px; background: 
        var(--text-secondary); color: var(--msg-actions); font-size: 12px; font-weight: 500; line-height: 16px;'
        >${name}</span>`;
};

export const formatRoleMention = (name, id) => {
    return `<span id='${id}' style='display: inline; cursor: auto; width: fit-content; height: fit-content; 
    padding: 2px 2px 3px 16px; justify-content: center; align-items: center; border-radius: 4px; background: 
    #555; font-size: 12px; color: white; font-weight: 500; line-height: 16px; position: relative;'
    >
    <img src='${profileUser}' style='position:absolute; left: 4px; top: 2px; height: 14px; width:fit-content' />
    ${name}
    </span>`;
};
