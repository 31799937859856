import { useContext } from 'react';

import { Icon, Line } from '@/UI';
import { truncateText } from '@/Utils/textUtils';
import { DEFAULT_IMAGES } from '@/constants';

import classes from './Search.module.css';
import { SearchContext } from './SearchContext';

interface IProps {
    result: any;
}

const SearchUserItem: React.FC<IProps> = props => {
    const { result } = props;

    const context = useContext(SearchContext);
    const { setUserModal } = context;

    return (
        <li
            id={result.user_id}
            onClick={() =>
                setUserModal({
                    userData: result,
                    state: true,
                })
            }
            className={classes['search-item']}>
            <div className={classes['d-flex']}>
                <Icon
                    className={classes['icon']}
                    imageURL={
                        result?.profile_picture
                            ? result?.profile_picture
                            : DEFAULT_IMAGES.PROFILE_IMAGE
                    }
                    name={result.name ? result.name : 'icon'}
                />
                <div>
                    <p>{truncateText(result.name, 25)}</p>
                    <p>@{result.username}</p>
                </div>
            </div>
            <Line />
        </li>
    );
};
export default SearchUserItem;
