import { MutableRefObject, RefObject, useEffect } from 'react';

const useClickOutside = (
    refs: Array<
        RefObject<HTMLElement | null> | MutableRefObject<HTMLElement | null>
    >,
    callback: () => void,
) => {
    const handleClickOutside = (event: MouseEvent) => {
        const isOutside = refs.every(ref => {
            return (
                ref &&
                ref.current &&
                !ref.current.contains(event.target as Node)
            );
        });

        if (isOutside) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [refs, callback]);

    return refs;
};

export default useClickOutside;
