import { createAsyncThunk } from '@reduxjs/toolkit';

import fetchData from '@/Utils/fetchData';
import { REQUEST_TYPE } from '@/constants/requstTypes';
import store from '@/store';
import { updateStrangerMemberList } from '@/store/server-data-reducer/server-data-reducer';
import { setMemberCountLoader } from '@/store/util-reducer';

// ban
export const blockUserFromServer = async payload => {
    const { userId, subserverId, currentId } = payload;
    const fetch_payload = {
        user_id: userId,
        subserver_id: subserverId,
    };
    const url =
        process.env.REACT_APP_BASE_API_URL +
        '/api/v4/subserver/manage/' +
        subserverId +
        '/ban';
    const res = await fetchData(REQUEST_TYPE.POST, url, fetch_payload);
    return res;
};
export const blockUserToServer = createAsyncThunk(
    'member/updateMemberBlock',
    async (payload: any) => {
        const res = await blockUserFromServer(payload);
        return res;
    },
);
// ban

// unban
export const unblockUserFromServer = async payload => {
    const { userId, subserverId, currentId } = payload;
    const fetch_payload = {
        user_id: userId,
        subserver_id: subserverId,
    };
    const url =
        process.env.REACT_APP_BASE_API_URL +
        '/api/v4/subserver/manage/' +
        subserverId +
        '/unban';
    const res = await fetchData(REQUEST_TYPE.POST, url, fetch_payload);
    return res;
};
export const unblockUserToServer = createAsyncThunk(
    'member/updateMemberUnblock',
    async (payload: any) => {
        const res = await unblockUserFromServer(payload);
        return res;
    },
);
// unban

// fetch-banned
export const fetchBlockUserFromServer = async subserverId => {
    store.dispatch(setMemberCountLoader(true));
    const url =
        process.env.REACT_APP_BASE_API_URL +
        '/api/v4/subserver/manage/' +
        subserverId +
        '/ban';
    const res = await fetchData(REQUEST_TYPE.GET, url);
    return res;
};

export const fetchBannedToServer = createAsyncThunk(
    'member/fetchBanned',
    async (payload: any) => {
        const res = await fetchBlockUserFromServer(payload);
        return res.data;
    },
);
// fetch-banned

export const fetchStrangeUserData = async ({ user_id }) => {
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/member/${user_id}`;
    const userResponse = await fetchData(REQUEST_TYPE.GET, url);
    store.dispatch(
        updateStrangerMemberList([{ id: user_id, ...userResponse.data }]),
    );
    return userResponse;
};
