import store from '..';
import { faker } from '@faker-js/faker';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { isObjectEmpty } from '@/Utils/isObjectEmpty';
import { changeKey, convertToObjects } from '@/Utils/utils';
import { fetchBannedToServer } from '@/api/member';
import { getMembers, getServers } from '@/api/server';
import { fetchVersionList } from '@/components/ManagementModal/version-download/useVersionDownload';
import { CHANNEL_PERMISSIONS } from '@/constants/enum';
import {
    IChannelData,
    IMemberData,
    IMessageData,
    IRole,
    ISectionList,
    IServerData,
    IServerList,
} from '@/types';

import { patchUpdateMembertoServer } from '../organization-reducer';
import { setMemberCountLoader, setMemberLoader } from '../util-reducer';

interface IServerSlice {
    serverData: IServerList;
    activeServer: string;
    activeChannelId: { [serverId: string]: string };
    memberList: { [serverId: string]: { [memberId: string]: IMemberData } };
    channelList: { [serverId: string]: { [channelId: string]: IChannelData } };
    sectionList: any;
    rolesList: { [serverId: string]: IRole[] };
    mentionsNotifications: { [serverId: string]: IMessageData[] };
    reactionsNotifications: { [serverId: string]: IMessageData[] };
    notifications: {
        [serverId: string]: { metaData: any; data: IMessageData[] };
    };

    publicChannelList: {
        [serverId: string]: { [channelId: string]: IChannelData };
    };
    bannedMembers: { [serverId: string]: any[] };
    permissionList: any;
    userPermissions: any;
    channelCounts: any;
    auditLogs: any;
    versionList: any;
    otherServerChannels: { [serverId: string]: any };
    strangerMemberList: {
        [serverId: string]: { [memberId: string]: IMemberData };
    };
}

const initialState: IServerSlice = {
    serverData: {},
    activeServer: '',
    activeChannelId: {},
    memberList: {},
    channelList: {},
    sectionList: {},
    rolesList: {},
    mentionsNotifications: {},
    reactionsNotifications: {},
    publicChannelList: {},
    bannedMembers: {},
    permissionList: {},
    userPermissions: {},
    channelCounts: {},
    auditLogs: [],
    versionList: [],
    notifications: {},
    otherServerChannels: {},
    strangerMemberList: {},
};

export const getServerData = createAsyncThunk(
    'server/getServerData',
    async () => {
        const res = await getServers();
        return { data: res.data, error: res?.error || null };
    },
);

export const getMembersData = createAsyncThunk(
    'server/getMembersData',
    async (payload: any) => {
        store.dispatch(setMemberLoader(true));
        store.dispatch(setMemberCountLoader(true));
        const serverPayload = payload;

        const { data, error } = await getMembers(serverPayload);

        if (error || !data) {
            setTimeout(() => {
                store.dispatch(getMembersData(serverPayload));
            }, 400);
        } else {
            return data;
        }
    },
);

export const ServerDataSlice = createSlice({
    name: 'serverData',
    initialState,
    reducers: {
        updateServer: (state: any, { payload }: PayloadAction<any>) => {
            state.serverData[payload.id] = {
                ...state.serverData[payload.id],
                ...payload,
            };
        },
        setActiveServer: (state: any, { payload }: PayloadAction<string>) => {
            state.activeServer = payload;
        },

        setActiveChannel: (state: any, { payload }: PayloadAction<string>) => {
            state.activeChannelId = {
                ...state?.activeChannelId,
                [state.activeServer]: payload,
            };
        },

        updatePrivateKey: (
            state: any,
            {
                payload,
            }: PayloadAction<{
                id: string;
                defaultHashkey?: string;
                privateKey?: string;
                publicKey?: string;
            }>,
        ) => {
            state.serverData[payload?.id] = {
                ...state.serverData[payload?.id],
                privateKey: payload?.privateKey,
                publicKey: payload?.publicKey,
                defaultHashkey: payload?.defaultHashkey,
            };
        },

        updatePublicChannelList: (
            state: IServerSlice,
            { payload }: PayloadAction<IChannelData[]>,
        ) => {
            const activeServerData =
                state.publicChannelList[state.activeServer];
            const updatedPublicChannelList = {
                ...convertToObjects(payload, '_id'),
            };
            state.publicChannelList[state.activeServer] =
                updatedPublicChannelList;
        },

        updateChannelList: (
            state: IServerSlice,
            { payload }: PayloadAction<IChannelData[]>,
        ) => {
            if (payload?.length > 0) {
                const activeServerData =
                    state.channelList[payload?.[0].subserver_id];
                const updatedChannelList = {
                    ...convertToObjects(payload, '_id', activeServerData),
                };
                state.channelList[payload?.[0].subserver_id] = {
                    // ...state.channelList[payload?.[0].subserver_id],
                    ...updatedChannelList,
                };
            } else state.channelList[state.activeServer] = {};
        },
        updateChannelCount: (
            state: IServerSlice,
            { payload }: PayloadAction<{ channel_id: string; count: number }>,
        ) => {
            if (!state.channelCounts[state.activeServer]) {
                state.channelCounts[state.activeServer] = {};
            }

            state.channelCounts[state.activeServer][payload.channel_id] =
                payload.count;
        },

        updateChannelPermissions: (
            state: IServerSlice,
            {
                payload,
            }: PayloadAction<{
                channelPermissions?: any;
            }>,
        ) => {
            state.channelList[state.activeServer][
                state.activeChannelId[state?.activeServer]
            ] = {
                ...state.channelList[state.activeServer]?.[
                    state.activeChannelId[state.activeServer]
                ],
                channelPermissions: payload.channelPermissions,
            };
        },

        updateChannelMembers: (
            state: IServerSlice,
            {
                payload,
            }: PayloadAction<{
                roles: string[];
                channel_id: string;
                members: string[];
            }>,
        ) => {
            state.channelList[state.activeServer][payload.channel_id] = {
                ...state.channelList[state.activeServer]?.[payload.channel_id],
                memberList: payload.members,
                roleList: payload.roles,
            };
        },
        updateProfileMember(state, action) {
            if (state.memberList[state.activeServer]) {
                Object.keys(state.memberList[state.activeServer]).forEach(
                    function (memberKey, index) {
                        if (
                            state.memberList[state.activeServer][memberKey]
                                .id === action.payload.id
                        ) {
                            state.memberList[state.activeServer][memberKey] = {
                                ...state.memberList[state.activeServer][
                                    memberKey
                                ],
                                ...action.payload,
                            };
                        }
                    },
                );
            }
        },
        updateSectionList: (
            state: IServerSlice,
            { payload }: PayloadAction<ISectionList[]>,
        ) => {
            state.sectionList[state.activeServer] = {
                ...convertToObjects(payload, '_id'),
                // ...state.serverData[state.activeServer].sectionList,
            };
        },

        editSectionDetails(state, { payload }) {
            if (state.activeServer === payload.subserver_id) {
                state.sectionList[payload.subserver_id][payload._id] = {
                    ...state.sectionList?.[payload.subserver_id]?.[payload._id],
                    ...payload,
                };
            }
        },

        deleteSectionReducer(state, { payload }) {
            delete state.sectionList[state.activeServer][payload.section_id];
        },

        updateMemberList: (
            state: IServerSlice,
            { payload }: PayloadAction<IMemberData[]>,
        ) => {
            const newPayload = changeKey(payload, 'user_id', 'id');
            state.memberList[state.activeServer] = {
                ...state.memberList[state.activeServer],
                ...convertToObjects(
                    newPayload,
                    'id',
                    state?.memberList[state?.activeServer],
                ),
            };
        },
        updateStrangerMemberList: (
            state: IServerSlice,
            { payload }: PayloadAction<IMemberData[]>,
        ) => {
            const newPayload = changeKey(
                payload,
                'profilePicture',
                'profile_picture',
            );
            state.strangerMemberList[state.activeServer] = {
                ...state.strangerMemberList[state.activeServer],
                ...convertToObjects(
                    newPayload,
                    'id',
                    state?.strangerMemberList[state?.activeServer],
                ),
            };
        },
        updateServerMemberData: (
            state: IServerSlice,
            { payload }: PayloadAction<IMemberData>,
        ) => {
            state.memberList[state.activeServer][payload.id] = {
                ...state?.memberList?.[state.activeServer]?.[payload.id],
                ...payload,
            };
        },
        updateMemberListUpdate: (
            state: IServerSlice,
            { payload }: PayloadAction<{ user_id: string; state: boolean }>,
        ) => {
            if (
                state.memberList &&
                state.activeServer in state.memberList &&
                state.memberList[state.activeServer]
            ) {
                // Check if state.memberList[state.activeServer][payload.user_id] is defined
                if (payload.user_id in state.memberList[state.activeServer]) {
                    state.memberList[state.activeServer][
                        payload.user_id
                    ].state = payload.state;
                } else {
                    console.error(
                        `User with ID ${payload.user_id} not found in member list.`,
                    );
                }
            } else {
                console.error(
                    `Active server ${state.activeServer} not found in member list.`,
                );
            }
        },
        updateMemberRoles: (state, { payload }) => {
            const payloadUserIds = payload.map(item => item.user_id);

            Object.keys(state.memberList[state.activeServer]).forEach(key => {
                if (payloadUserIds.includes(key)) {
                    // Update roles from payload for matching members
                    const payloadItem = payload.find(
                        item => item.user_id === key,
                    );
                    state.memberList[state.activeServer][key].roles =
                        payloadItem.roles;
                } else {
                    // Set roles to an empty array for non-matching members
                    state.memberList[state.activeServer][key].roles = [];
                }
            });
        },
        updateRolesList: (state: any, { payload }: any) => {
            state.rolesList[state.activeServer] = payload
                ?.map((item1: any) => {
                    const matchingItem = state.rolesList[
                        state.activeServer
                    ]?.find(item2 => {
                        return item2?.id === item1?._id;
                    });
                    return {
                        ...item1,
                        ...matchingItem,
                        fakeName: faker.internet.userName(),
                    };
                })
                .sort((a, b) => a.priority - b.priority);
        },

        addChannel: (
            state: IServerSlice,
            { payload }: PayloadAction<IChannelData>,
        ) => {
            state.channelList[state.activeServer][payload._id] = payload;
        },

        reJoinChannel: (
            state: IServerSlice,
            { payload }: PayloadAction<IChannelData | any>,
        ) => {
            let publicChannelList = Object.values(
                state.publicChannelList?.[state?.activeServer] ?? {},
            );

            const rejoindChannel = publicChannelList.find(
                ch => ch._id === payload.channel_id,
            );
            state.channelList[state.activeServer][payload.channel_id] =
                rejoindChannel;
        },

        // deleteMessage(state, { payload }: any) {
        //     if (
        //         state.activeServer &&
        //         payload.channel_id === state.activeChannelId
        //     ) {
        //         delete state.channelList[state.activeServer][
        //             state.activeChannelId
        //         ]?.messageList[payload.message_id];
        //     }
        // },

        addSection(state, { payload }) {
            if (state.activeServer === payload.subserver_id) {
                state.sectionList[state.activeServer][payload._id] = payload;
            }
        },

        removeChannel(state, action) {
            if (action.payload.subserver_id === state.activeServer) {
                setActiveChannel('');
                delete state.channelList[action.payload.subserver_id][
                    action.payload._id
                ];
            }
        },

        updateChannelDetails(state, { payload }) {
            state.channelList[state.activeServer][payload._id] = {
                ...state.channelList[state.activeServer][payload._id],
                ...payload,
            };
        },

        updateServerPermissions(
            state: IServerSlice,
            { payload }: PayloadAction<object[]>,
        ) {
            state.permissionList[state.activeServer] = payload;
        },

        updateRolePermissions(
            state: IServerSlice,
            { payload }: PayloadAction<object[]>,
        ) {
            state.rolesList[state.activeServer] = payload
                ?.map((item1: any) => {
                    const matchingItem = state.rolesList[
                        state.activeServer
                    ]?.find(item2 => item2._id === item1.id);
                    return {
                        ...matchingItem,
                        ...item1,
                    };
                })
                .sort((a, b) => a.priority - b.priority);
        },

        toggleDMVisibility(state, { payload }) {
            state.channelList[state.activeServer][payload.id].is_hidden =
                payload.is_hidden;
        },

        updatePermissions(state, action) {
            state.userPermissions[state.activeServer] = action.payload;
        },

        resettableReduce(state, action) {
            if (action.payload === 'reset') {
                state = initialState;
            }
        },

        updateMentionNotifications(
            state: IServerSlice,
            { payload }: PayloadAction<any[]>,
        ) {
            if (payload && payload?.length > 0)
                state.mentionsNotifications[`${payload[0].subserver_id}`] =
                    payload;
            else state.mentionsNotifications[state.activeServer] = payload;
        },

        addMentionNotifications(
            state: IServerSlice,
            { payload }: PayloadAction<any>,
        ) {
            state.mentionsNotifications[state.activeServer] = [
                payload,
                ...state.mentionsNotifications[state.activeServer],
            ];
        },

        updateReactionNotifications(
            state: IServerSlice,
            { payload }: PayloadAction<any[]>,
        ) {
            if (payload && payload?.length > 0)
                state.reactionsNotifications[`${payload[0].subserver_id}`] =
                    payload;
            else state.reactionsNotifications[state.activeServer] = payload;
        },

        addReactionNotifications(
            state: IServerSlice,
            { payload }: PayloadAction<any>,
        ) {
            state.reactionsNotifications[state.activeServer] = [
                payload,
                ...state.reactionsNotifications[state.activeServer],
            ];
        },

        // ------- Notifications ------- //
        updateNotifications(
            state: IServerSlice,
            { payload }: PayloadAction<{ metaData: any; data: any }>,
        ) {
            if (
                payload &&
                payload?.data?.length > 0 &&
                payload?.metaData.current_page_number !== 1
            ) {
                const prevNotifications =
                    state.notifications?.[payload.data[0].subserver_id]?.data ??
                    [];
                state.notifications[`${payload.data[0].subserver_id}`] = {
                    metaData: payload?.metaData,
                    data: [...prevNotifications, ...payload.data],
                };
            } else if (payload?.data === undefined && payload.metaData) {
                state.notifications[state.activeServer].metaData =
                    payload?.metaData;
            } else state.notifications[state.activeServer] = payload;
        },

        addNotifications(state: IServerSlice, { payload }: PayloadAction<any>) {
            if (state.notifications[state.activeServer]?.data === undefined) {
                state.notifications[state.activeServer] = {
                    data: [],
                    metaData: {
                        total_unread_notification: 0,
                    },
                };
            }
            state.notifications[state.activeServer].data = [
                payload,
                ...(state?.notifications?.[state?.activeServer]?.data ?? []),
            ];
            state.notifications[
                state.activeServer
            ].metaData.total_unread_notification += 1;
        },

        markReadNotifications(
            state: IServerSlice,
            { payload }: PayloadAction<any>,
        ) {
            const updatedData = state?.notifications?.[
                state?.activeServer
            ]?.data?.map(item =>
                item.channel_id === payload
                    ? {
                          ...item,
                          users: [
                              {
                                  ...item.users[0],
                                  is_read: true,
                              },
                          ],
                      }
                    : item,
            );

            state.notifications[state.activeServer] = {
                ...state.notifications[state.activeServer],
                data: updatedData,
            };
        },

        markThreadReadNotifications(
            state: IServerSlice,
            { payload }: PayloadAction<any>,
        ) {
            const updatedData = state?.notifications?.[
                state?.activeServer
            ]?.data?.map(item =>
                item.thread_id === payload
                    ? {
                          ...item,
                          users: [
                              {
                                  ...item.users[0],
                                  is_read: true,
                              },
                          ],
                      }
                    : item,
            );

            state.notifications[state.activeServer] = {
                ...state.notifications[state.activeServer],
                data: updatedData,
            };
        },

        removeServerData: (
            state: any,
            { payload }: PayloadAction<{ id: string; object: Object }>,
        ) => {
            state.serverData[payload.id] = payload;
        },
        // FOR AUDIT LOG SCROLL PAGINATION
        //     updateAuditLogs: (
        //         state,
        //         { payload }: PayloadAction<{ audit_log; meta_data: any }>,
        //     ) => {
        //         const newLogs = payload.audit_log;
        //         const existingLogs = state.auditLogs?.audit_log || [];

        //         newLogs.forEach(newLog => {
        //             const index = existingLogs?.findIndex(
        //                 log => log?._id === newLog?._id,
        //             );
        //             if (index !== -1) {
        //                 existingLogs[index] = newLog;
        //             } else {
        //                 existingLogs.push(newLog);
        //             }
        //         });

        //         const sortLogList = existingLogs?.sort(
        //             (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
        //         );
        //         state.auditLogs = {
        //             audit_log: sortLogList,
        //             meta_data: payload.meta_data,
        //         };
        //     },
        updateAuditLogs: (
            state,
            { payload }: PayloadAction<{ audit_log; meta_data: any }>,
        ) => {
            state.auditLogs = payload;
        },

        updateCreateChannelMemberPermissions: (
            state,
            { payload }: PayloadAction<{ data: any } | any>,
        ) => {
            const chId = payload.channel_id;

            payload.all_data.forEach(item => {
                const userId = item?.user_id;
                const selectedUser =
                    state.memberList?.[payload.subserver_id]?.[userId];

                item?.permissions?.forEach(itemPerm => {
                    if (!selectedUser.channel_permissions) {
                        selectedUser.channel_permissions = {};
                    }
                    if (!selectedUser.channel_permissions[chId]) {
                        selectedUser.channel_permissions[chId] = {};
                    }
                    selectedUser.channel_permissions[chId][itemPerm.key] =
                        itemPerm.value;
                });
            });
        },

        updateCreateChannelRolePermissions: (
            state,
            { payload }: PayloadAction<{ data: any } | any>,
        ) => {
            const chId = payload.channel_id;

            payload.all_data?.forEach(item => {
                const roleId = item?.role_id;
                const selectedRoles = state.rolesList?.[payload.subserver_id];
                const selectedRole: any = selectedRoles.find(
                    (role: any) => role.id === roleId,
                );

                item?.permissions?.forEach(itemPerm => {
                    if (!selectedRole.channel_permissions) {
                        selectedRole.channel_permissions = {};
                    }
                    if (!selectedRole.channel_permissions[chId]) {
                        selectedRole.channel_permissions[chId] = {};
                    }
                    selectedRole.channel_permissions[chId][itemPerm.key] =
                        itemPerm.value;
                });
            });
        },

        updateGetChannelMemberPermissions: (
            state,
            { payload }: PayloadAction<{ data: any } | any>,
        ) => {
            const chId = payload?.channel_id;
            const userId = payload?.user_id;
            // const channelPermissions =
            //     state.channelList[state.activeServer][chId].channelPermissions;

            const selectedUser =
                state.memberList?.[state.activeServer]?.[userId];

            selectedUser.channel_permissions = {
                ...selectedUser?.channel_permissions,
                [chId]: { ...CHANNEL_PERMISSIONS },
            };

            payload?.permissions?.forEach(itemPerm => {
                if (
                    selectedUser.channel_permissions[chId][
                        itemPerm.permission_type
                    ] &&
                    itemPerm.permission_value === false
                ) {
                    selectedUser.channel_permissions[chId][
                        itemPerm.permission_type
                    ] = itemPerm.permission_value;
                }
            });
        },

        updateGetChannelRolePermissions: (
            state,
            { payload }: PayloadAction<{ data: any } | any>,
        ) => {
            const chId = payload?.channel_id;
            const roleId = payload?.role_id;

            // const channelPermissions =
            //     state.channelList[state.activeServer][chId].channelPermissions;

            const selectedRoles = state.rolesList?.[state.activeServer];
            const selectedRole: any = selectedRoles?.find(
                (role: any) => role.id === roleId,
            );

            selectedRole.channel_permissions = {
                ...selectedRole?.channel_permissions,
                [chId]: { ...CHANNEL_PERMISSIONS },
            };

            if (payload?.role_members === 0) {
                selectedRole.channel_permissions[chId] = { ...payload };
            } else {
                payload?.permissions?.forEach(itemPerm => {
                    if (
                        selectedRole.channel_permissions[chId][
                            itemPerm.permission_type
                        ] &&
                        itemPerm.permission_value === false
                    ) {
                        selectedRole.channel_permissions[chId][
                            itemPerm.permission_type
                        ] = itemPerm.permission_value;
                    }
                });
            }
        },
        addOtherServerChannelList: (state, { payload }) => {
            const itemsToAdd = Array.isArray(payload) ? payload : [payload];
            state.otherServerChannels[state.activeServer] = {
                ...state.otherServerChannels[state.activeServer],
                ...convertToObjects(
                    itemsToAdd,
                    '_id',
                    state.otherServerChannels[state.activeServer],
                ),
            };
        },

        updateOtherServerChannelList: (state, { payload }) => {
            state.otherServerChannels[state.activeServer] = {
                ...convertToObjects(
                    payload,
                    '_id',
                    state.otherServerChannels[state.activeServer],
                ),
            };
        },
        deleteOtherServerChannelList(state, { payload }) {
            delete state.otherServerChannels[state.activeServer][payload._id];
        },
    },

    extraReducers: builder => {
        builder
            .addCase(
                getServerData.fulfilled,
                (state: IServerSlice, { payload }) => {
                    const data = payload?.data ?? {};
                    const error = payload?.error || null;
                    if (!error && !isObjectEmpty({ ...payload?.data })) {
                        state.serverData = {
                            ...convertToObjects(data, 'id', state.serverData),
                        };
                    }
                },
            )
            .addCase(getServerData.rejected, (state, { payload }) => {
                console.error('Failed to fetch server data:');
            });
        builder.addCase(getMembersData.fulfilled, (state, { payload }) => {
            state.memberList[state.activeServer] = {
                ...convertToObjects(
                    payload,
                    'id',
                    state.memberList[state.activeServer],
                ),
            };
        });
        builder.addCase(
            patchUpdateMembertoServer.fulfilled,
            (state, action) => {
                if (action?.payload?.status === true) {
                    state.memberList[state.activeServer][
                        action.meta.arg.userId
                    ] = {
                        ...state.memberList[state.activeServer]?.[
                            action.meta.arg.userId
                        ],
                        ...action.meta.arg.formData,
                    };
                }
            },
        );

        builder.addCase(fetchBannedToServer.fulfilled, (state, action) => {
            setMemberCountLoader(false);
            const bannedMembers = action.payload.data;
            const activeServer = state.activeServer;
            const memberList = state.memberList[activeServer];

            const updatedMemberList = { ...memberList };

            for (const userId in updatedMemberList) {
                if (!updatedMemberList.hasOwnProperty(userId)) {
                    continue;
                }
                const member = updatedMemberList[userId];
                const isBanned = bannedMembers.some(
                    bannedMember => bannedMember.user_id === userId,
                );

                updatedMemberList[userId] = {
                    ...member,
                    banned: isBanned,
                };
            }

            return {
                ...state,
                bannedMembers: {
                    ...state.bannedMembers,
                    [activeServer]: bannedMembers,
                },
                memberList: {
                    ...state.memberList,
                    [activeServer]: updatedMemberList,
                },
            };
        });
        builder.addCase(fetchVersionList.fulfilled, (state, action) => {
            state.versionList = action.payload;
        });
    },
});

export const {
    updatePrivateKey,
    setActiveServer,
    updateChannelList,
    updatePublicChannelList,
    updateChannelCount,
    updateRolesList,
    setActiveChannel,
    updateMemberList,
    updateMemberListUpdate,
    editSectionDetails,
    addChannel,
    updateSectionList,
    deleteSectionReducer,
    addSection,
    removeChannel,
    updateChannelDetails,
    updateChannelMembers,
    updateChannelPermissions,
    updateServerPermissions,
    updateRolePermissions,
    toggleDMVisibility,
    updateMemberRoles,
    updatePermissions,
    updateServer,
    resettableReduce,
    updateMentionNotifications,
    updateReactionNotifications,
    addMentionNotifications,
    updateServerMemberData,
    addReactionNotifications,
    removeServerData,
    reJoinChannel,
    updateProfileMember,
    updateAuditLogs,
    updateNotifications,
    addNotifications,
    markReadNotifications,
    updateCreateChannelMemberPermissions,
    updateCreateChannelRolePermissions,
    updateGetChannelMemberPermissions,
    updateGetChannelRolePermissions,
    markThreadReadNotifications,
    addOtherServerChannelList,
    updateOtherServerChannelList,
    deleteOtherServerChannelList,
    updateStrangerMemberList,
} = ServerDataSlice.actions;
export default ServerDataSlice.reducer;
