import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { generateRSAKeyPair } from '@/Utils/cryptoUtils';
import { ROOT_PATH } from '@/routes/router.types';

const useDownloadServerKeys = () => {
    const serverDetails = {
        serverName: 'Server1',
        id: 'asda311',
    };
    const navigate = useNavigate();
    const [keys, setKeys] = useState<{
        publicKey: CryptoKey;
        privateKey: CryptoKey;
    }>();

    const generateKey = async () => {
        try {
            const keyPair = await generateRSAKeyPair();
            keyPair && setKeys(keyPair);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        generateKey();
    }, []);

    // const getQrValue = (): string => {
    //     const jsonString = JSON.stringify(keys, null, 2);
    //     const fileType = 'application/json';

    //     const blob = new File([jsonString], 'keys.txt', { type: 'text/plain' });
    //     const url = URL.createObjectURL(blob);
    //     return url;
    // };

    const handleDownload = async (payload?: any, fileName?: string) => {
        // const exportedKey = await window.crypto.subtle.exportKey(
        //     'jwk',
        //     keys.privateKey,
        // );

        const exportedKeys = await Promise.all([
            window.crypto.subtle.exportKey('jwk', keys.publicKey),
            window.crypto.subtle.exportKey('jwk', keys.privateKey),
        ]);

        const jsonString = JSON.stringify({
            publicKey: exportedKeys[0],
            privateKey: exportedKeys[1],
        });

        const fileType = 'application/json';

        const blob = new Blob([jsonString], { type: fileType });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${serverDetails.serverName}_Keys.json`;

        link.click();

        URL.revokeObjectURL(url);
    };

    const handlePrivateKey = () => {
        handleDownload(
            keys.privateKey,
            `${serverDetails.serverName}_Keys.json`,
        );
        return;
    };

    const handlePublicKey = () => {
        // Download Public Key from file Logic
        handleDownload(
            keys.privateKey,
            `${serverDetails.serverName}_Keys.json`,
        );
        return;
    };

    const handleContinue = () => {
        navigate(ROOT_PATH.devicePassword, {
            state: { privateKey: keys.privateKey },
        });
    };
    return {
        handlePrivateKey,
        handlePublicKey,
        handleContinue,
        keys,
        handleDownload,
        // handleEncryptData,
        // handleDecryptData,
        // handleChange,
    };
};

export default useDownloadServerKeys;
