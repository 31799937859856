import { FC, useEffect, useState } from 'react';

import ImgButton from '@/UI/custom-buttons/ImgButton';
import EmojiKeyBoard from '@/UI/emoji-keyboard/EmojiKeyBoard';
import plusIcon from '@/assets/icons/plus.svg';

import classes from './MessageReactions.module.css';

interface IProps {
    onLeaveReaction: () => void;
    onMessageReaction: (icon: string) => void;
}
const MessageReactions: FC<IProps> = props => {
    const { onLeaveReaction, onMessageReaction } = props;

    const [isEmojiBoardOpen, setIsEmojiBoardOpen] = useState(false);
    const basicReactions = ['👍', '👎', '❤️', '😂', '😢'];
    const [target, setTraget] = useState(null);
    const [menuPosition, setMenuPosition] = useState(false);

    const handleEmojiboardOpen = owner => {
        const messageRect = owner?.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if (messageRect) {
            const distanceToTop = messageRect.top;
            const distanceToBottom = windowHeight - messageRect.bottom;
            if (distanceToTop > distanceToBottom) {
                setMenuPosition(true);
            } else {
                setMenuPosition(false);
            }
        }
    };

    useEffect(() => {
        handleEmojiboardOpen(target);
    }, [target]);

    const handleOpenboard = e => {
        setIsEmojiBoardOpen(true);
        setTraget(e);
    };

    return (
        <div
            onMouseLeave={onLeaveReaction}
            className={classes['react-container']}>
            {basicReactions.map((icon, key) => (
                <ImgButton
                    key={key}
                    type={'button'}
                    onClick={event => {
                        switch (true) {
                            case event.detail === 2: {
                                break;
                            }
                            case event.detail > 2: {
                                event.preventDefault();
                                break;
                            }
                            default: {
                                onMessageReaction(icon);
                                break;
                            }
                        }
                    }}>
                    <span className={classes['icon']}>{icon}</span>
                </ImgButton>
            ))}
            <ImgButton
                type={'button'}
                onClick={e => handleOpenboard(e?.currentTarget)}
                className={classes['open-emoji-board']}
                icon={plusIcon}
            />
            {isEmojiBoardOpen && (
                <div
                    className={classes['keyboard']}
                    style={{
                        position: 'absolute',
                        transform: menuPosition
                            ? `translateY(${-20}px)`
                            : `translateY(${410}px)`,
                        zIndex: 2,
                    }}>
                    <EmojiKeyBoard
                        onSelect={event => {
                            onMessageReaction(event.emoji);
                            setIsEmojiBoardOpen(false);
                            onLeaveReaction();
                        }}
                        width="300px"
                        height="400px"
                        skinTonesDisabled={false}
                        searchDisabled={false}
                    />
                </div>
            )}
        </div>
    );
};
export default MessageReactions;
