import { Trans, t } from '@lingui/macro';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-activity';

import { AppButton, Switch, TextInput } from '@/UI';
import closeIcon from '@/assets/icons/close-header.svg';
import { DEFAULT_IMAGES } from '@/constants';
import { BUTTON_SIZE } from '@/constants/enum';
import { useAppSelector } from '@/hooks/useRedux';
import { serverDetailSelector } from '@/store/server-data-reducer/selectors-server-data';

import useOrganizationAddMember from '../../organization_member/organization-add-members/useOrganizationAddMember';

import classes from './MemberProfileEdit.module.css';

interface FormValues {
    username: string;
    name: string;
    email: string;
    password: string;
    confirm_password: string;
    external?: boolean;
}

const MemberProfileEdit = (props: any) => {
    const containerRef = useRef(null);

    const { inputAreaClassName = '', modalProps } = props;
    const { isEditModal, setIsEditModal, selectedUserData, setIsDataChange } =
        modalProps;

    const [loading, setLoading] = useState(false);

    // form
    const initialValues: FormValues = {
        username: selectedUserData?.username ?? '',
        name: selectedUserData?.name ?? '',
        email: selectedUserData?.email ?? '',
        password: '',
        confirm_password: '',
        external: selectedUserData?.external ?? false,
    };
    const server = useAppSelector(state => serverDetailSelector(state));
    const organisationId = server.customer_id;
    let formData = new FormData();
    // fetchData
    let memberUpdateProps = {
        userId: selectedUserData?.id,
        organizationId: organisationId,
        formData: formData,
    };

    const { handleMemberUpdate } = useOrganizationAddMember(memberUpdateProps);

    async function fetchUpdateUserInfo(values: FormValues) {
        setLoading(false);
        values?.username != selectedUserData?.username &&
            formData.append('username', values?.username);
        values?.name != selectedUserData?.name &&
            formData.append('name', values?.name);
        values?.email != selectedUserData?.email &&
            formData.append('email', values?.email);
        values?.password && formData.append('password', values?.password);
        values?.external != selectedUserData?.external &&
            formData.append('external', values?.external ? '1' : '0');

        handleMemberUpdate();
    }

    const onSubmit = (values: FormValues, actions) => {
        // Handle form submission here
        fetchUpdateUserInfo(values).then(() => setIsDataChange(true));
        actions.setSubmitting(false);
    };

    const validate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (values.password) {
            if (!values.confirm_password) {
                errors.confirm_password = 'Confirm Password is required!';
            } else if (values.confirm_password !== values.password) {
                errors.confirm_password = 'Passwords do not match!';
            }
        }

        return errors;
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate,
    });

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            username: selectedUserData?.username ?? '',
            name: selectedUserData?.name ?? '',
            email: selectedUserData?.email ?? '',
            password: '',
            confirm_password: '',
            external: selectedUserData?.external ?? false,
        });
    }, [selectedUserData]);

    const _renderHeader = () => (
        <div className={classes['left-side-header']}>
            <h2>
                <Trans>Member Edit</Trans>
            </h2>
            <div
                onClick={() => setIsEditModal(false)}
                className={classes['left-side-header-close']}>
                <img src={closeIcon} alt="" />
            </div>
        </div>
    );

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                setIsEditModal(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef, isEditModal]);
    return (
        <div ref={containerRef} className={classes['left-side-container']}>
            <div className={classes['left-side-inner-container']}>
                {_renderHeader()}
                <form
                    autoComplete="new-password"
                    onSubmit={formik.handleSubmit}
                    className={classes['form-container']}>
                    <div className={classes['profile-pic']}>
                        <img
                            alt=""
                            src={
                                selectedUserData?.profile_picture ??
                                DEFAULT_IMAGES.PROFILE_IMAGE
                            }
                            className={classes['media-img']}
                        />
                    </div>
                    <div
                        className={`${inputAreaClassName} ${classes['input-area']}`}>
                        <div>
                            <TextInput
                                title={t`Name`}
                                placeHolder={t`Name`}
                                name="name"
                                value={formik.values['name']}
                                onChange={formik.handleChange}
                                textStyle={{ width: '100%' }}
                            />
                            <TextInput
                                title={t`Username`}
                                placeHolder={t`Username`}
                                name="username"
                                value={formik.values['username']}
                                onChange={formik.handleChange}
                                textStyle={{ width: '100%' }}
                            />
                        </div>
                        <TextInput
                            title={t`Email`}
                            placeHolder={t`Email`}
                            name="email"
                            value={formik.values['email']}
                            onChange={formik.handleChange}
                        />
                        <TextInput
                            title={t`Password`}
                            placeHolder={t`Password`}
                            name="password"
                            value={formik.values['password']}
                            onChange={formik.handleChange}
                            type="password"
                        />
                        <TextInput
                            title={t`Confirm Password`}
                            placeHolder={t`Confirm Password`}
                            name="confirm_password"
                            value={formik.values['confirm_password']}
                            onChange={formik.handleChange}
                            type="password"
                            onBlur={formik.handleBlur}
                            error={
                                formik.values.password &&
                                formik.touched.confirm_password &&
                                formik.errors?.confirm_password
                            }
                        />
                        <div className="">
                            <label
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 16,
                                    cursor: 'pointer',
                                }}>
                                <Trans>External</Trans>&nbsp; :
                                <Switch
                                    id="external"
                                    onChange={e =>
                                        formik.setFieldValue('external', e)
                                    }
                                    checked={formik.values['external']}
                                />
                            </label>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                        }}>
                        <div style={{ display: 'flex' }}>
                            <AppButton
                                classStyles={classes['appButton']}
                                type="submit"
                                title={
                                    loading ? <Spinner /> : <Trans>Save</Trans>
                                }
                                size={BUTTON_SIZE.medium}
                                disable={loading}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MemberProfileEdit;
