import { motion } from 'framer-motion';
import React, { CSSProperties, ReactNode } from 'react';
import ReactDOM from 'react-dom';

import closeIcon from '@/assets/icons/close-header.svg';

import BackdropModal from '../backdrop-modal/BackdropModal';

import classes from './Modal.module.css';

interface IModal {
    className?: string | string[];
    removeBodyPadding?: boolean;
    header?: string;
    headerIcon?: string | ReactNode;
    children?: React.ReactNode;
    bodyStyles?: CSSProperties;
    onClose: () => void;
    headerClassName?: any;
}

const Modal = (props: IModal) => {
    const {
        header,
        className = '',
        onClose,
        bodyStyles,
        headerClassName,
        removeBodyPadding,
    } = props;

    const bodyStyle = {
        padding: removeBodyPadding && 0,
        ...bodyStyles,
    };

    return ReactDOM.createPortal(
        <>
            <BackdropModal onHide={onClose} className={classes.backdrop} />
            <motion.div
                initial={{ y: -30, opacity: 0 }}
                exit={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}  
                className={classes['modal-container'] + ' ' + className}>
                {header && (
                    <div
                        className={
                            classes['modal-header'] + ' ' + headerClassName
                        }>
                        {props.headerIcon &&
                        typeof props.headerIcon === 'string' ? (
                            <img src={props.headerIcon} alt={props.header} />
                        ) : (
                            props.headerIcon
                        )}
                        <span className={classes['modal-header-title']}>
                            {props.header}
                        </span>
                        <img
                            className={classes.close}
                            onClick={onClose}
                            src={closeIcon}
                            alt="close"
                            id="close-modal-icon"
                        />
                    </div>
                )}
                <div style={bodyStyle} className={classes['card-body']}>
                    {props.children}
                </div>
            </motion.div>
        </>,
        document.getElementById('modal-root'),
    );
};
export default Modal;
