import { memo } from 'react';

import { NotesProvider } from '@/context/notes/NotesContext';

import Notes from './Notes';

const NotesStore: React.FC<{}> = () => {
    
    return (
        <NotesProvider>
            <Notes />
        </NotesProvider>
    );
};
export default memo(NotesStore);
