import { t } from '@lingui/macro';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import SocketToast from '@/UI/toast/SocketToast';
import { isWebSocketConnected } from '@/Utils/webSocketUtils';
import { useAppSelector } from '@/hooks/useRedux';
import { setWelcomeScreen } from '@/store/util-reducer';

import LoadingPage from '../LoadingPage/LoadingPage';
import MobileViewTabsContent from '../mobile-view-tab-content/MobileViewTabsContent';
import ServerSection from '../server-details-section/ServerSection';

import classes from './HomeContent.module.css';
import RenderSections from './RenderSections';
import useHomeContent from './useHomeContent';

const HomeContent = () => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const isConnected = useAppSelector(state => state.socketConnectionReducer);

    const [showToast, setShowToast] = useState(false);
    const [showToastIsConnected, setShowToastIsConnected] = useState(false);
    const [toastClass, setToastClass] = useState('');
    const [toastText, setToastText] = useState('');

    const channelList = useAppSelector(
        state => state.serverDataReducer.channelList,
    );
    const welcomeScreenValue = useAppSelector(
        state => state.utilReducer.welcomeScreen,
    );

    const { createNewChatTemplate, chatAreaSectionConfig } = useHomeContent();

    const callbackHandler = useCallback(
        (id: string) => createNewChatTemplate([id]),
        [],
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setWelcomeScreen(false));
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        let timeoutIdFalse;
        if (isWebSocketConnected()) {
            setToastClass(classes['connected']);
            setToastText(t`Connected`);
            setShowToastIsConnected(true);

            timeoutIdFalse = setTimeout(() => {
                setShowToast(false);
            }, 1000);
        } else {
            setShowToast(true);
            setShowToastIsConnected(false);
            setToastClass(classes['connecting']);
            setToastText(t`Connecting to server`);
        }

        return () => {
            clearTimeout(timeoutIdFalse);
        };
        
    }, [isConnected, isWebSocketConnected()]);

    return (
        <div className={classes['server-padding-container']}>
            {welcomeScreenValue && <LoadingPage mainText={t`Welcome`} />}

            {showToast && (
                <SocketToast
                    className={toastClass}
                    text={toastText}
                    loading={!showToastIsConnected}
                />
            )}
            {isMobile ? (
                <MobileViewTabsContent />
            ) : (
                <>
                    {(channelList || welcomeScreenValue) && (
                        <ServerSection onNewChat={callbackHandler} />
                    )}
                    <RenderSections
                        chatAreaSectionConfig={chatAreaSectionConfig}
                        callbackHandler={callbackHandler}
                    />
                </>
            )}
        </div>
    );
};

export default memo(HomeContent);
