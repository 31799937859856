import React from 'react';

import DropDown from '@/UI/drop-down/DropDown';

import { optionsTypes } from './threaadOptions';

const DropDownOptions: React.FC<{
    className: string;
    selectSettingHandler: any;
    options: any;
    onClose: () => void;
    onSelect: (event: optionsTypes) => void;
}> = ({ className, onClose, options, selectSettingHandler }) => {
    return (
        <DropDown
            onClose={onClose}
            selectOptions={options}
            onClick={selectSettingHandler}
            pointer
            className={className}
        />
    );
};

export default DropDownOptions;
