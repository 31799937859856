import { t } from '@lingui/macro';

import { ReactComponent as HashtagIcon } from '@/assets/icons/Hashtag.svg';
import { ReactComponent as VolumeIcon } from '@/assets/icons/volume-audio.svg';

type objType = {
    id: string;
    value: 'text' | 'voice';
    name: string;
    icon: any;
    info: string;
};

type Type = {
    text: objType;
    voice: objType;
};

export const channelTypes: Type = {
    text: {
        id: 'textChannelType',
        value: 'text',
        name: t`Text`,
        info: t`You can share topics, notes by sending messages.`,
        icon: <HashtagIcon fill={'var(--navy-1)'} />,
    },
    voice: {
        id: 'voiceChannelType',
        value: 'voice',
        name: t`Voice`,
        info: t`Allows you to share screen by speaking voice and video`,
        icon: <VolumeIcon fill="var(--navy-1)" />,
    },
};
export const getChannelTypes = () => {
    return {
        text: {
            id: 'textChannelType',
            value: 'text',
            name: t`Text`,
            info: t`You can share topics, notes by sending messages.`,
            icon: <HashtagIcon fill={'var(--navy-1)'} />,
        },
        voice: {
            id: 'voiceChannelType',
            value: 'voice',
            name: t`Voice`,
            info: t`Allows you to share screen by speaking voice and video`,
            icon: <VolumeIcon fill="var(--navy-1)" />,
        },
    };
};
