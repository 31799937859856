import { Trans } from '@lingui/macro';

import { ReactComponent as NotesIcon } from '@/assets/icons/notes.svg';
import { useAppDispatch } from '@/hooks/useRedux';
import { notesActions } from '@/store/notes-reducer';

import classes from './ServerEditor.module.css';

const NotesIcons: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const toggleNotesWindow = () => {
        dispatch(notesActions.toggleNotesWindow(true));
    };

    return (
        <>
            <div onClick={toggleNotesWindow} className="cursor-pointer">
                <NotesIcon className={classes['note']} />
                <p className={classes['mt-5']}>
                    <Trans>Notes</Trans>
                </p>
            </div>
        </>
    );
};
export default NotesIcons;
