import { customUploadAdapter } from './uploadAdapter';

export const ckEditorConfig = {
    toolbar: {
        ckfinder: {
            uploadUrl:
                '/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',
        },
        // extraPlugins: [customUploadAdapter],
        items: [
            'undo',
            'redo',
            'heading',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'code',
            'removeFormat',
            'specialCharacters',
            'horizontalLine',
            'pageBreak',
            'link',
            'insertTable',
            'highlight',
            'blockQuote',
            'mediaEmbed',
            'codeBlock',
            'htmlEmbed',
            'alignment',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            'selectAll',
        ],
        shouldNotGroupWhenFull: true,
    },
};
