import { Trans, t } from '@lingui/macro';
import React, { forwardRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { AppButton, Badge, Modal } from '@/UI';
import InviteFriends from '@/UI/invite-modal/InviteFriends';
import {
    leaveChannel,
    onJoinChannel,
} from '@/Utils/socket-actions/ChannelActions/channelActions';
import ExitIcon from '@/assets/icons/ExitIcon';
import { ReactComponent as AddUserIcon } from '@/assets/icons/add-user.svg';
import { ReactComponent as LockIcon } from '@/assets/icons/lock.svg';
import { ReactComponent as SettingIcon } from '@/assets/icons/setting.svg';
import EditChannel from '@/components/EditChannel/EditChannel';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import useRoomTypeIcon from '@/hooks/useRoomTypeIcon.js';
import { mobileViewActions } from '@/store/mobile-view-slice';
import {
    activeChannelIdSelector,
    userPermissionSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import { RoomItemProps } from '../channelDataTypes';

import classes from './ChannelItem.module.css';

const ChannelItem = forwardRef<HTMLLIElement, RoomItemProps>((props, ref) => {
    const { channel, id } = props;
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    // this function will rerturn the correct icon for the room type
    const { icon } = useRoomTypeIcon(channel?.type, classes['room-type-icon'], {
        fill: 'var(--text-2)',
    });

    const [isInvite, setIsInvite] = useState(false);
    const [isLeaveConfirm, setIsLeaveConfirm] = useState(false);
    const [isEditChannel, setIsEditChannel] = useState(false);
    const [channelClass, setChannelClass] = useState(classes['roomItem']);

    const permissions = useAppSelector(userPermissionSelector);
    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const channelCountList = useAppSelector(
        state =>
            state.serverDataReducer.channelCounts?.[
                state.serverDataReducer.activeServer
            ],
    );

    const channelCount = channelCountList?.[channel._id];
    const invitePermission = permissions?.['user.invite'];

    const chooseRoomHandler = (event: React.MouseEvent, roomId: string) => {
        event.stopPropagation();
        if (roomId !== activeChannelId) {
            onJoinChannel(roomId);
        }
        if (isMobile) {
            dispatch(mobileViewActions.changeActiveTab(1));
        }
    };

    const addToRoomHandler = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsInvite(true);
    };

    useEffect(() => {
        if (channel._id === activeChannelId) {
            setChannelClass(classes['active-channel']);
        } else if (channelCount > 0 && channel._id !== activeChannelId) {
            setChannelClass(classes['unread-channel']);
        } else {
            setChannelClass(classes['roomItem']);
        }
    }, [channel, activeChannelId, channelCount]);

    const leaveChannelHandler = () => {
        id && leaveChannel(id);
        setIsLeaveConfirm(false);
    };

    return (
        <>
            {isInvite && (
                <InviteFriends
                    onClose={() => setIsInvite(false)}
                    channelName={channel.name}
                    channelId={id}
                    channel={channel}
                />
            )}
            <li
                ref={ref}
                className={channelClass}
                onClick={event => chooseRoomHandler(event, id)}
                {...props}>
                <div className={classes['room-name-container']}>
                    {channelCount > 0 && channel._id !== activeChannelId && (
                        <Badge className={classes['room-count-badge']}>
                            {channelCount > 99 ? '99+' : channelCount}
                        </Badge>
                    )}
                    {!channel?.is_public ? (
                        <LockIcon
                            fill={'var(--text-2)'}
                            className={classes['room-type-icon']}
                        />
                    ) : (
                        channel.type && icon
                    )}
                    <p className={classes.roomName}>{channel.name}</p>
                </div>
                {!isMobile &&
                    channel.privacy === 'channel' &&
                    !channel.is_public &&
                    invitePermission && (
                        <AddUserIcon
                            fill="var(--white)"
                            className={classes['room-icon']}
                            onClick={(event: React.MouseEvent) => {
                                event.stopPropagation();
                                chooseRoomHandler(event, id);
                                addToRoomHandler(event);
                            }}
                        />
                    )}
                {!isMobile && permissions?.['channel.edit'] && (
                    <SettingIcon
                        fill="var(--white)"
                        className={classes['room-icon']}
                        onClick={() => setIsEditChannel(true)}
                    />
                )}
                {!permissions?.['channel.delete'] && (
                    <ExitIcon
                        className={classes['room-icon']}
                        onClick={event => {
                            event.stopPropagation();
                            setIsLeaveConfirm(true);
                        }}
                    />
                )}
            </li>

            {isLeaveConfirm && (
                <Modal
                    className={classes.modal}
                    header="Confirm Leave"
                    onClose={() => setIsLeaveConfirm(false)}>
                    <p>
                        <Trans>
                            By clicking on Delete button you wont be able to use
                            this channel anymore.
                        </Trans>
                    </p>
                    <AppButton
                        onClick={leaveChannelHandler}
                        title={t`Leave Channel`}
                        classStyles={classes['delete']}
                    />
                </Modal>
            )}
            {isEditChannel && (
                <EditChannel
                    // @ts-ignore
                    channel={channel}
                    onClose={() => {
                        setIsEditChannel(false);
                    }}
                />
            )}
        </>
    );
});
export default ChannelItem;
