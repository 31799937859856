import { FC } from 'react';

import { useAppSelector } from '@/hooks/useRedux';
import addMembersSVG from '@/assets/icons/add-user.svg';
import addIcon from '@/assets/icons/add.svg';
import arrowDown from '@/assets/icons/arrow-down.svg';
import editIcon from '@/assets/icons/edit-pencil.svg';
import { userPermissionSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './SectionHeader.module.css';

type HeadlindTypes = {
    onInviteSection: (e: React.MouseEvent<HTMLImageElement>) => void;
    onEditSection: () => void;
    onSectionInvite: () => void;
    onToggleAccordion: () => void;
    isAccordion: boolean;
    title: string;
    id: string;
};
const SectionHeader: FC<HeadlindTypes> = props => {
    const {
        onInviteSection,
        onEditSection,
        onSectionInvite,
        onToggleAccordion,
        isAccordion,
        title,
        id,
    } = props;

    const clickInviteHandler = e => {
        onInviteSection(e);
    };

    const clickEditHandler = () => {
        onEditSection();
    };

    const addRoomHandler = () => {
        onSectionInvite();
    };

    const toggleRoomsAccordionHandler = () => {
        onToggleAccordion();
    };

    const permissions = useAppSelector(userPermissionSelector);
    return (
        <div className={classes['headline-container']}>
            <div
                className={classes['collapse-rooms-header']}
                onClick={toggleRoomsAccordionHandler}>
                <img
                    style={
                        isAccordion
                            ? { transform: 'rotate(0)' }
                            : {
                                  transform: 'rotate(-90deg)',
                              }
                    }
                    className={classes.icon}
                    src={arrowDown}
                    alt="down"
                />
                <h3 className={classes['title']}>{title}</h3>
            </div>
            <div className={classes['title-edit']}>
                {permissions?.['user.invite'] && (
                    <img
                        src={addMembersSVG}
                        onClick={e => clickInviteHandler(e)}
                        alt="invite"
                        className={classes.icon}
                        style={{ width: 16, marginRight: 16, opacity: 0.5 }}
                    />
                )}
                {id !== '' && id && permissions?.['channel.edit'] && (
                    <img
                        onClick={clickEditHandler}
                        className={classes['editIcon'] + ' ' + classes.icon}
                        src={editIcon}
                        alt="change"
                    />
                )}
                {permissions?.['channel.create'] && (
                    <img
                        onClick={addRoomHandler}
                        className={classes.icon}
                        src={addIcon}
                        alt="add"
                    />
                )}
            </div>
        </div>
    );
};
export default SectionHeader;
