import { sortMediaOrder } from '@/Utils/sortMediaOrder';

import { useMediaDisplayContext } from './MediaDisplayContext';
import MediaSlider from './MediaSlider';
import classes from './SelectedMediaDisplay.module.css';
import SelectedMediaDisplaySort from './SelectedMediaDisplaySort';

const SelectedMediaContent: React.FC<{ noCopy: boolean }> = props => {
    const { noCopy } = props;
    const { filesArray } = useMediaDisplayContext();

    return (
        <div className={`${classes['download-items-container']}`}>
            {filesArray
                .filter(file => file)
                .sort(sortMediaOrder)
                ?.map((file, key) => {
                    return (
                        <SelectedMediaDisplaySort
                            key={key}
                            index={key}
                            file={file}
                            noCopy={noCopy}
                        />
                    );
                })}
            <MediaSlider />
        </div>
    );
};
export default SelectedMediaContent;
