import { Ref, forwardRef } from 'react';

import DisplayAttachment from '../display-attachment/DisplayAttachment';

import classes from './MediaInput.module.css';
import MediaInputEmptyContent from './MediaInputEmptyContent';
import useMediaInput from './useMediaInput';

export interface MediaInputProps {
    clicker?: boolean;
    passDefaultFiles?: File[] | null;
    passDefaultLink?: string[];
    onRemoveMedia: (id: string) => void;
    mini?: boolean;
    removeInfoText?: boolean;
    onChange: (files: any[]) => void;
    className?: string;
    multiple?: boolean;
    accept?: string;
    wrapClass?: string;
}

const MediaInput = forwardRef(
    (props: MediaInputProps, ref: Ref<HTMLInputElement>) => {
        const { wrapClass = '', removeInfoText, mini } = props;

        const {
            handleDragOver,
            handleDrop,
            removeImageLinkHandler,
            removeMediaHandler,
            openMediaInputHandler,
            changeHandler,
            errorFileSize,
            galleryClass,
            mediaFiles,
            defaultImageLink,
        } = useMediaInput(props, ref);

        return (
            <div
                className={`${wrapClass} ${classes['scroll']}`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}>
                <div
                    style={{
                        padding:
                            (mediaFiles && mediaFiles.length === 1) ||
                            defaultImageLink.length === 1
                                ? 0
                                : '10px',
                    }}
                    onClick={openMediaInputHandler}
                    className={`${classes.container} ${
                        props.className && props.className
                    }`}>
                    <input
                        onChange={changeHandler}
                        ref={ref}
                        type="file"
                        name="media"
                        id="mediaAttachments"
                        className={classes['media-input']}
                        multiple={props.multiple ?? false}
                        accept={props?.accept || undefined}
                    />
                    {(mediaFiles && mediaFiles.length > 0) ||
                    defaultImageLink.length > 0 ? (
                        <DisplayAttachment
                            files={mediaFiles}
                            className={galleryClass}
                            onRemoveMediaItem={removeMediaHandler}
                            onRemoveImageLink={removeImageLinkHandler}
                            defaultImageLink={defaultImageLink}
                        />
                    ) : (
                        <MediaInputEmptyContent
                            removeInfoText={removeInfoText}
                            mini={mini}
                            errorFileSize={errorFileSize}
                        />
                    )}
                </div>
            </div>
        );
    },
);

export default MediaInput;
