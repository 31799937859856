import { Trans, t } from '@lingui/macro';
import React, { FC, useContext } from 'react';

import { Modal } from '@/UI';
import SearchInput from '@/UI/search-input/SearchInput';
import SearchIcon from '@/assets/icons/SearchIcon';

import Pagination from '../pagination/Pagination';

import classes from './Search.module.css';
import { SearchContext } from './SearchContext';
import SearchResult from './SearchResult';
import SearchResultTitle from './SearchResultTitle';

type IProps = {
    searchTitle: string;
};

const Search: FC<IProps> = props => {
    const { searchTitle } = props;

    const context = useContext(SearchContext);
    const {
        ChangeSearchValueHandler,
        bodyStyle,
        isFromChatAreaSection,
        searchRef,
        ChangeChatAreaSearchHandler,
        searchResult,
        changePageHandler,
        closeModal,
    } = context;

    return (
        <Modal
            className={classes.modal}
            header={searchTitle}
            bodyStyles={bodyStyle}
            headerIcon={<SearchIcon stroke={'var(--keep-white)'} />}
            onClose={() => {
                closeModal();
            }}>
            <p className={classes['par']}>
                <Trans>
                    You can search channels, servers, contacts and more
                </Trans>
                ...
            </p>
            <SearchInput
                ref={searchRef}
                className={classes['search-input']}
                RemoveIcon
                placeholder={
                    isFromChatAreaSection
                        ? t`Messges | @ for Users`
                        : t`# for text channels | @ for Users | * for Servers | ! for voice channels`
                }
                onChange={
                    isFromChatAreaSection
                        ? ChangeChatAreaSearchHandler
                        : ChangeSearchValueHandler
                }
            />
            <SearchResultTitle />
            <SearchResult />
            <Pagination
                pagesArray={searchResult}
                itemsPerPage={20}
                pageRange={3}
                onChangePage={changePageHandler}
            />
        </Modal>
    );
};
export default React.memo(Search);
