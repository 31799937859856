const CloseBorderIcon = ({ stroke, className, onClick = () => {} }) => {
    return (
        <svg
            onClick={onClick}
            className={className}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 1L1 15M1 1L15 15"
                stroke={stroke ? stroke : '#DCDBE7'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default CloseBorderIcon;
