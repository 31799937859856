import React from 'react';

import classes from './DateIndicator.module.css';

const DateIndicator: React.FC<{
    date: Date;
    className: string;
    fullDate?: boolean;
}> = props => {
    const day = props.date.getDate();
    const month = props.date.getMonth() + 1; // getMonth returns from 0
    const year = props.date.getFullYear();
    const hours = props.date.getHours();
    const minutes = props.date.getMinutes();
    const seconds = props.date.getSeconds();

    return (
        <div className={classes['date-container'] + ' ' + props.className}>
            {props.fullDate
                ? `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
                : `${day}.${month}.${year}`}
        </div>
    );
};
export default DateIndicator;
