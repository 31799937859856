import { FC } from 'react';

import checkIcon from '@/assets/icons/check.svg';
import { getChannelTypes } from '@/constants/channeltypes';

import classes from './ChannelTypeOptions.module.css';

type Props = {
    onChangeType: (value: string) => void;
};
const ChannelTypeOptions: FC<Props> = props => {
    const { onChangeType } = props;
    const channelTypes = getChannelTypes();
    return (
        <div className={classes['']}>
            <ul>
                {Object.keys(channelTypes).map((item, key) => (
                    <li className={classes['list-item']} key={key}>
                        <input
                            type="radio"
                            name="channelType"
                            value={channelTypes[item].value}
                            onChange={event => {
                                onChangeType(event.target.value);
                            }}
                            defaultChecked={channelTypes[item].value === 'text'}
                            disabled={channelTypes[item].value === 'voice'}
                        />
                        {typeof channelTypes[item].icon === 'object' ? (
                            channelTypes[item].icon
                        ) : (
                            <img
                                src={channelTypes[item].icon}
                                alt={channelTypes[item].name}
                            />
                        )}
                        <div className={classes['paragraphs']}>
                            <p>{channelTypes[item].name}</p>
                            <p>{channelTypes[item].info}</p>
                        </div>
                        <div className={classes['check']}>
                            <img src={checkIcon} alt="choose" />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default ChannelTypeOptions;
