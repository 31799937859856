const SearchImage = ({ fill = '#252144', className = '' }) => {
    return (
        <svg
            className={className}
            width="192"
            height="192"
            viewBox="0 0 192 192"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="vuesax/bulk/search-status">
                <g id="search-status">
                    <path
                        id="Vector"
                        d="M116 86H68C64.72 86 62 83.28 62 80C62 76.72 64.72 74 68 74H116C119.28 74 122 76.72 122 80C122 83.28 119.28 86 116 86Z"
                        fill={fill}
                    />
                    <path
                        id="Vector_2"
                        d="M92 110H68C64.72 110 62 107.28 62 104C62 100.72 64.72 98 68 98H92C95.28 98 98 100.72 98 104C98 107.28 95.28 110 92 110Z"
                        fill={fill}
                    />
                    <path
                        id="Vector_3"
                        opacity="0.4"
                        d="M92 168C133.974 168 168 133.974 168 92C168 50.0264 133.974 16 92 16C50.0264 16 16 50.0264 16 92C16 133.974 50.0264 168 92 168Z"
                        fill={fill}
                    />
                    <path
                        id="Vector_4"
                        d="M170.4 176C168.96 176 167.52 175.44 166.48 174.4L151.6 159.52C149.44 157.36 149.44 153.84 151.6 151.6C153.76 149.44 157.28 149.44 159.52 151.6L174.4 166.48C176.56 168.64 176.56 172.16 174.4 174.4C173.28 175.44 171.84 176 170.4 176Z"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    );
};
export default SearchImage;
