import { Trans, t } from '@lingui/macro';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { AppButton, CustomInput, Modal } from '@/UI';
import Tabs from '@/UI/tabs/Tabs';
import { useAppSelector } from '@/hooks/useRedux';
import {
    memberDataSelector,
    rolesListSelector,
    userPermissionSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import { IMemberData, IRole } from '@/types';

import classes from './ForwardNote.module.css';
import MembersListItem from './MembersListItem';
import RolesListItem from './RolesListItem';

interface IProps {
    onClose: () => void;
    onForward: (data: any) => void;
}

const ForwardNote: FC<IProps> = ({ onClose, onForward }) => {
    const tabs = [t`Roles`, t`Users`];
    const inputRef = useRef<HTMLInputElement>(null);

    const [contentKey, setContentKey] = useState<number>(0);
    const [selectedItems, setSelectedItems] = useState<{
        [key: string]: boolean;
    }>({});
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    const rolesList: IRole[] = useAppSelector(rolesListSelector);
    const membersList: IMemberData[] = useAppSelector(
        memberDataSelector,
    ).filter(user => user.name && user.id);
    const userPermissions = useAppSelector(userPermissionSelector);

    const tabBody = [
        rolesList,
        userPermissions?.['pvt_message.create'] ? membersList : [],
    ];

    const [content, setContent] = useState<(IRole | IMemberData)[]>(tabBody[0]);

    const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchInputValue(event.target.value);
    };

    const changeTabHandler = (key: number) => {
        setContent(tabBody[key]);
        setContentKey(key);
        setSearchInputValue('');
        setSelectedItems({});
    };

    useEffect(() => {
        const filteredContent = tabBody[contentKey].filter(item =>
            item.name
                .toLowerCase()
                .includes(searchInputValue.trim().toLowerCase()),
        );
        setContent(filteredContent);
    }, [searchInputValue, contentKey]);

    const selectUserHandler = () => {
        const selectedItemsArray = Object.keys(selectedItems).filter(
            key => selectedItems[key],
        );

        const submitData = selectedItemsArray.map(key => {
            if (contentKey === 0) {
                return (content as IRole[]).find(
                    item => item?._id === key,
                ) as IRole;
            } else {
                return (content as IMemberData[]).find(
                    item => item?.id === key,
                ) as IMemberData;
            }
        });

        onForward({ data: submitData, receiveType: contentKey });
    };

    const toggleSelectUser = (event: ChangeEvent<HTMLInputElement>) => {
        const key = event.target.value;
        setSelectedItems(prev => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    return (
        <Modal
            headerClassName={classes['modal-header']}
            removeBodyPadding
            className={classes['new-chat-modal']}
            header="Forward"
            onClose={onClose}>
            <Tabs tabs={tabs} onChangeTab={changeTabHandler}>
                <div className={classes['tabs-body']}>
                    <CustomInput
                        ref={inputRef}
                        value={searchInputValue}
                        placeholder={`${t`Search for a`} ${
                            contentKey === 0 ? t`role` : t`user`
                        }`}
                        onChange={inputChangeHandler}
                        className={classes['NewMessageInput']}
                    />

                    <ul className={classes['body-list']}>
                        {searchInputValue.trim() !== '' &&
                            content.length === 0 && (
                                <p className={classes['no-results']}>
                                    <Trans>No results found</Trans>
                                </p>
                            )}
                        {contentKey === 0
                            ? (content as IRole[]).map((item, index) => (
                                  <RolesListItem
                                      checked={selectedItems[item._id] || false}
                                      onChange={toggleSelectUser}
                                      item={item}
                                      key={index}
                                  />
                              ))
                            : (content as IMemberData[]).map((item, index) => (
                                  <MembersListItem
                                      checked={selectedItems[item.id] || false}
                                      onChange={toggleSelectUser}
                                      item={item}
                                      key={index}
                                  />
                              ))}
                    </ul>
                    <AppButton
                        buttonType="primary"
                        title="Forward"
                        onClick={selectUserHandler}
                        classStyles={classes['submit']}
                        disable={false}
                        loader={false}
                    />
                </div>
            </Tabs>
        </Modal>
    );
};

export default ForwardNote;
