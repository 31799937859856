import { FC } from 'react';

import { Modal } from '@/UI';

import classes from './CreateServer.module.css';
import EditSubServerBody from './EditSubServerBody';
import { t } from '@lingui/macro';

type Props = {
    onClose: () => void;
};

const EditSubServer: FC<Props> = props => {
    const { onClose } = props;

    return (
        <Modal
            className={classes['modal']}
            header={t`Edit Server`}
            removeBodyPadding
            onClose={onClose}>
            <EditSubServerBody onClose={onClose} />
        </Modal>
    );
};
export default EditSubServer;
