import { ExternalBadge } from '@/UI/externalbadge/ExternalBadge';
import { truncateText } from '@/Utils/textUtils';
import { DEFAULT_IMAGES } from '@/constants';

import classes from './TableStatistics.module.css';

const UserCol: React.FC<{ item }> = ({ item }) => {
    return (
        <div className={classes['user-column']}>
            <img
                alt=""
                src={
                    item ? item?.profile_picture : DEFAULT_IMAGES.PROFILE_IMAGE
                }
            />
            <div className={classes['user-column-text']}>
                <span className={classes['user-name']}>
                    {truncateText(item.name, 25)}
                </span>
                <span className={classes['user-sub']}>{item.username}</span>
            </div>
            <div style={{ marginLeft: 5 }}>
                {item?.external > 0 && <ExternalBadge />}
            </div>
        </div>
    );
};
export default UserCol;
