import { SESSION_STORAGE } from '@/constants/enum';
import store from '@/store';
import {
    editMessage,
    updateMessageThread,
} from '@/store/messages-reducer/messages-reducer';
import { mobileViewActions } from '@/store/mobile-view-slice';
import { markThreadReadNotifications } from '@/store/server-data-reducer/server-data-reducer';
import {
    addThreadMessage,
    closeThreadAction,
    deleteThreadMessage,
    editThreadMessage,
    editThreadMsgReaction,
    getThreadList,
    openThreadAction,
    updateLockThread,
    updateOwnerId,
    updateThreadList,
    updateThreadMembers,
    updatethreadMessageList,
    updatethreadNextMessageList,
} from '@/store/thread-reducer/threadsSlice';
import { IMessageData } from '@/types';

import { decryptPassword } from '../../cryptoUtils';
import { onDecryptMessage } from '../../onDecryptMessage';

export const handleCreateThread = (data, messagesArray) => {
    let messageItem: any = { ...data?.thread_data };
    const filterSenderMessage = messagesArray?.find(
        item => item.sender_message_id === messageItem?.message_id,
    );
    const filterSenderMessageId =
        filterSenderMessage?._id || messageItem.message_id;
    messageItem = {
        ...data.thread_data,
        message_id: filterSenderMessageId,
    };
    store.dispatch(openThreadAction(messageItem));
    store.dispatch(mobileViewActions.changeActiveTab(4));
    store.dispatch(updateOwnerId({ ownerId: messageItem?.owner_id }));
    store.dispatch(
        updateThreadList({
            subserver_id: messageItem?.subserver_id,
            _id: messageItem?._id,
            name: messageItem?.name,
            user_id: messageItem?.owner_id,
        }),
    );
    store.dispatch(
        updateMessageThread({
            messageId: data?.thread_data?.message_id,
            channel_id: data?.thread_data?.channel_id,
            thread_id: data?.thread_data?._id,
        }),
    );
};

export const handleThreadMessageList = async ({
    messages,
    thread_id,
    prev,
    privateKey,
    handleMediaMessages,
}: {
    messages: IMessageData[];
    thread_id: string;
    prev: boolean;
    privateKey: string;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
    let decrypt_jwk = decryptPassword(
        privateKey,
        sessionStorage.getItem(SESSION_STORAGE.devicePassword),
    );

    const cryptoKey = await window.crypto.subtle.importKey(
        'jwk',
        decrypt_jwk,
        { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
        false,
        ['decrypt'],
    );

    const decryptDataList = await Promise.all(
        messages?.map(async (item: IMessageData) => {
            let decryptMessage: any = item;
            try {
                decryptMessage = await onDecryptMessage(item, cryptoKey);
            } catch (error) {
                console.error(error);
            }
            if (
                item.type === 'media' ||
                (item.type === 'forward' &&
                    decryptMessage?.forwardedMessage?.type === 'media')
            ) {
                handleMediaMessages(decryptMessage, item._id, false);
            }

            return { ...item, ...decryptMessage };
        }),
    );

    // dispatch(setMessageLoader(false));
    if (prev === true) {
        store.dispatch(
            updatethreadNextMessageList({
                messages: decryptDataList,
                thread_id,
            }),
        );
    } else {
        store.dispatch(
            updatethreadMessageList({
                messages: decryptDataList,
                thread_id,
            }),
        );
    }
};

export const handleThreadDeltails = async data => {
    store.dispatch(
        openThreadAction({
            name: data?.name,
            _id: data?._id,
            channel_id: data?.channel_id,
            message_id: data?.message_id,
            subserver_id: data?.subserver_id,
        }),
    );
    store.dispatch(mobileViewActions.changeActiveTab(4));
    store.dispatch(updateOwnerId({ ownerId: data?.owner_id }));
    store.dispatch(updateLockThread({ isLockThread: data?.is_thread_lock }));
    store.dispatch(markThreadReadNotifications(data?._id));
};

export const handleActiveThreadMembers = data => {
    store.dispatch(
        updateThreadMembers({ activeThreadMembers: data?.thread_members }),
    );
};

export const handleThreadMessageUpdate = async ({
    data,
    privateKey,
    handleMediaMessages,
    messagesArray,
}: {
    data: any;
    privateKey: string;
    messagesArray: any;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
    let lastMessage = data[0]?.lastMessage;
    let decryptMessage: any = lastMessage;
    let messageItem: any = { ...lastMessage };

    const filterSenderMessage = messagesArray?.find(
        item => item.sender_message_id === messageItem?.message_id,
    );
    const filterSenderMessageId =
        filterSenderMessage?._id || messageItem.message_id;
    messageItem = { ...lastMessage, message_id: filterSenderMessageId };

    try {
        decryptMessage = await onDecryptMessage(lastMessage, privateKey);
        messageItem = { ...messageItem, ...decryptMessage };
    } catch (error) {
        console.error(error);
    }

    if (data.type === 'forward') {
        if (messageItem.forwardedMessage.type === 'media') {
            await handleMediaMessages(
                messageItem.forwardedMessage.message,
                messageItem.forwardedMessage._id,
                false,
            );
        }
    }
    if (
        data.type === 'media'
    ) {
        await handleMediaMessages(decryptMessage, data._id, false);
    }
    let itemMessage = { ...data[0].lastMessage, ...decryptMessage };
    store.dispatch(
        updateMessageThread({
            lastMessage: itemMessage,
            messageCount: data[0]?.messageCount,
            messageId: filterSenderMessageId,
            // isThreadMember:lastMessage?.is_thread_member,
            isThreadMute: false,
        }),
    );
    store.dispatch(
        updateThreadList({
            subserver_id: messageItem?.subserver_id,
            _id: data[0]?._id,
            lastMessage: itemMessage,
        }),
    );
};

export const handleaddThreadMessage = async ({
    data,
    privateKey,
    handleMediaMessages,
    messagesArray,
    activeThreadMembers,
}: {
    data: any;
    privateKey: string;
    messagesArray: any;
    activeThreadMembers: any;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
    let decryptMessage: any = data;
    let messageItem: any = { ...data };

    // FILTER
    const filterSenderMessage = messagesArray?.find(
        item => item.sender_message_id === messageItem?.message_id,
    );
    const filterSenderMessageId =
        filterSenderMessage?._id || messageItem.message_id;
    messageItem = { ...data, message_id: filterSenderMessageId };
    //FILTER END

    try {
        decryptMessage = await onDecryptMessage(data, privateKey);
        messageItem = { ...messageItem, ...decryptMessage };
    } catch (error) {
        console.error(error);
    }

    if (data.type === 'forward') {
        if (messageItem.forwardedMessage.type === 'media') {
            await handleMediaMessages(
                messageItem.forwardedMessage.message,
                messageItem.forwardedMessage._id,
                false,
            );
        }
    }
    if (
        data.type === 'media'
    ) {
        await handleMediaMessages(decryptMessage, data._id, false);
    }
    store.dispatch(addThreadMessage(messageItem));
    if (!activeThreadMembers?.includes(data?.user_id)) {
        store.dispatch(
            updateThreadMembers({
                activeThreadMembers: [...activeThreadMembers, data?.user_id],
            }),
        );
    }
};

export const handleJoinThread = ({
    data,
    activeThreadMembers,
}: {
    data: any;
    activeThreadMembers: any;
}) => {
    store.dispatch(
        updateThreadMembers({
            ...activeThreadMembers,
            activeThreadMembers: data?.thread_user_data?.user_id,
        }),
    );
};

export const handleLeaveThread = ({
    data,
    activeThreadMembers,
}: {
    data: any;
    activeThreadMembers: any;
}) => {
    const members = activeThreadMembers?.filter(val => !val === data?.user_id);
    store.dispatch(
        updateThreadMembers({
            ...activeThreadMembers,
            activeThreadMembers: members,
        }),
    );
    store.dispatch(closeThreadAction());
};

export const handleDeleteThread = data => {
    store.dispatch(
        updateMessageThread({
            lastMessage: null,
            messageCount: null,
            messageId: data?.message_id,
            channel_id: data?.channel_id,
            // isThreadMember:lastMessage?.is_thread_member,
            isThreadMute: null,
        }),
    );
    store.dispatch(
        updateThreadList({
            subserver_id: data?.subserver_id,
            _id: data?.thread_id,
            isDeletedThread: true,
        }),
    );
    store.dispatch(closeThreadAction());
};

export const handleLockThread = data => {
    store.dispatch(updateLockThread({ isLockThread: data?.is_thread_lock }));
};

export const handleDeleteThreadMessage = data => {
    store.dispatch(
        deleteThreadMessage({
            thread_id: data?.thread_id,
            message_id: data?.message_id,
        }),
    );
};

export const handleThreadReactionUpdate = data => {
    if (data.is_deleted) {
        store.dispatch(
            editThreadMsgReaction({ type: 'DELETE', reactionData: data }),
        );
    } else {
        store.dispatch(
            editThreadMsgReaction({ type: 'ADD', reactionData: data }),
        );
    }
};

export const editMessageHandler = async (data: any, privateKey: string) => {
    let decryptMessage: any = data.message;
    let messageItem = { ...data };
    try {
        decryptMessage = await onDecryptMessage(data, privateKey);
        messageItem = { ...messageItem, ...decryptMessage };
    } catch (error) {
        console.error(error);
    }
    if (data?.message_id) {
        store.dispatch(
            editThreadMessage({
                thread_id: data?.thread_id,
                message_id: data?._id,
                msg: decryptMessage?.message,
            }),
        );
    } else {
        store.dispatch(
            editMessage({
                channel_id: data.channel_id,
                message_id: data._id,
                msg: decryptMessage.message,
            }),
        );
    }
};

export const handleGetThreasList = async ({
    data,
    privateKey,
    handleMediaMessages,
}: {
    data: any;
    privateKey: string;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
    let decrypt_jwk = decryptPassword(
        privateKey,
        sessionStorage.getItem(SESSION_STORAGE.devicePassword),
    );

    const cryptoKey = await window.crypto.subtle.importKey(
        'jwk',
        decrypt_jwk,
        { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
        false,
        ['decrypt'],
    );

    const decryptDataList = await Promise.all(
        data?.thread_list?.map(async (item: any) => {
            let decryptMessage = item?.lastMessage?.message;
            let decryptedMessageData;

            try {
                decryptedMessageData = await onDecryptMessage(
                    item?.lastMessage,
                    cryptoKey,
                );
            } catch (error) {
                console.error(error);
            }

            if (
                item.type === 'media' ||
                (item.type === 'forward' &&
                    decryptedMessageData?.forwardedMessage?.type === 'media')
            ) {
                handleMediaMessages(decryptMessage, item._id, false);
            }

            const updatedLastMessage = {
                ...item?.lastMessage,
                message: decryptedMessageData?.message,
            };

            return { ...item, lastMessage: updatedLastMessage };
        }),
    );

    store.dispatch(
        getThreadList({
            subserver_id: data.subserver_id,
            thread_list: decryptDataList,
            metaData: data?.metaData,
        }),
    );
};
