import { AnimatePresence } from 'framer-motion';
import { memo, useEffect, useRef, useState } from 'react';

import { ReactComponent as PauseIcon } from '@/assets/icons/pause.svg';
import { ReactComponent as PlayIcon } from '@/assets/icons/play-2.svg';
import { MediaTypes } from '@/components/messages/media-message/MediaMessageTypes';

import LinearProgress from '../linear-progress/LinearProgress';

import classes from './SelectedMediaDisplay.module.css';

interface TimerProps {
    audioBlob?: Blob;
    file?: MediaTypes;
}

const AudioPlayer: React.FC<TimerProps> = props => {
    const { file } = props;
    const audioRef = useRef(null);
    const [audioDuration, setAudioDuration] = useState<number | null>(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [playingStatus, setPlayingStatus] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const isAudioPlaying = () => {
        return audioRef.current && !audioRef.current.paused;
    };

    const toggleAudio = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (isAudioPlaying()) {
            audioRef.current.pause();
            setPlayingStatus(false);
        } else {
            audioRef.current.play();
            setPlayingStatus(true);
        }
    };

    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
            2,
            '0',
        )}`;
    };
    const changePositionHandler = positionValue => {
        const newTimePosition = (audioDuration * positionValue) / 100;
        audioRef.current.currentTime = newTimePosition;
        setCurrentTime(newTimePosition);
        setPlayingStatus(true);
        audioRef.current.play();
    };

    const playHandler = (event: React.SyntheticEvent<HTMLAudioElement>) => {
        setPlayingStatus(true);

        const currentAudio = event.currentTarget;
        const otherPlayers = document.querySelectorAll<HTMLAudioElement>(
            '.music-message-player',
        );

        otherPlayers.forEach(audioElement => {
            if (audioElement !== currentAudio && !audioElement.paused) {
                audioElement.pause();
            }
        });
    };

    useEffect(() => {
        const audioElement = audioRef.current;

        audioElement.addEventListener('loadedmetadata', () => {
            setAudioDuration(Math.floor(audioElement.duration));
        });

        audioElement.addEventListener('timeupdate', () => {
            setCurrentTime(audioElement.currentTime);
        });
        audioElement.addEventListener('ended', () => {
            setPlayingStatus(false);
        });

        return () => {
            audioElement.removeEventListener('loadedmetadata', () => {});
            audioElement.removeEventListener('timeupdate', () => {});
            audioElement.removeEventListener('ended', () => {});
        };
    }, [file, audioRef]);

    return (
        <div className={classes['audio-controls']}>
            <audio
                className="music-message-player"
                onCanPlay={() => {
                    setIsReady(true);
                }}
                onPause={() => setPlayingStatus(false)}
                onPlay={playHandler}
                ref={audioRef}
                src={file.src}>
                <source type="audio/mpeg" src={file.src} />
            </audio>
            {isReady && (
                <div className={classes['icon-wrap']} onClick={toggleAudio}>
                    {playingStatus ? <PauseIcon /> : <PlayIcon />}
                </div>
            )}
            {audioDuration !== null && (
                <label
                    className={classes['timer']}
                    htmlFor={`audio-file-${file.name}`}>
                    {formatTime(currentTime)}
                </label>
            )}
            <AnimatePresence>
                {isReady && (
                    <LinearProgress
                        className={classes['audio-progress']}
                        onNewPosition={changePositionHandler}
                        id={`audio-file-${file.name}`}
                        value={(currentTime * 100) / audioDuration || 0}
                    />
                )}
            </AnimatePresence>
        </div>
    );
};
export default memo(AudioPlayer);
