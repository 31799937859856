import { redirect } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useRedux';
import { ROOT_PATH } from '@/routes/router.types';

import Home from '../components/Home/Home';

const HomePage = () => {
    const user = useAppSelector(state => state.userReducer.user);

    if (user.token === '') {
        return redirect(ROOT_PATH.auth);
    }
    return <Home />;
};

export default HomePage;
