import ContentLoader from 'react-content-loader';

const NoteCategoryLoader = props => (
    <ContentLoader
        speed={2}
        width={200}
        height={150}
        viewBox="-20 0 200 150"
        backgroundColor="#0d0a23"
        foregroundColor="#302c55"
        {...props}>
        <circle cx="12" cy="20" r="8" />
        <rect x="25" y="14" rx="3" ry="3" width="150" height="12" />
        <circle cx="12" cy="50" r="8" />
        <rect x="25" y="45" rx="3" ry="3" width="150" height="12" />
        <circle cx="12" cy="82" r="8" />
        <rect x="25" y="76" rx="3" ry="3" width="150" height="12" />
    </ContentLoader>
);

export default NoteCategoryLoader;
