import { t } from '@lingui/macro';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { onJoinChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import { handleOpenThread } from '@/Utils/socket-actions/ThreadActions/threadActions';
import { triggerScroll } from '@/Utils/targetMessageService';
import { truncateText } from '@/Utils/textUtils';
import { isWebSocketConnected } from '@/Utils/webSocketUtils';
import hashtagIcon from '@/assets/icons/Hashtag.svg';
import closeIcon from '@/assets/icons/close-header.svg';
import MessageContent from '@/components/messages/message-item/message-content/MessageContent';
import { DEFAULT_IMAGES } from '@/constants/index';
import { useAppSelector } from '@/hooks/useRedux';
import { Notification } from '@/store/Notifications-count';
import { mobileViewActions } from '@/store/mobile-view-slice';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';
import { setActiveServer } from '@/store/server-data-reducer/server-data-reducer';
import { openThreadAction } from '@/store/thread-reducer/threadsSlice';
import { IChannelData } from '@/types';

import Icon from '../main-icon/Icon';

import classes from './Toast.module.css';

type Props = {
    content: Notification;
    onDismiss: () => void;
};

const Toast: FC<Props> = props => {
    const { content, onDismiss } = props;
    const dispatch = useDispatch();
    const [channelName, setChannelName] = useState('');

    const hasMedia =
        content.type === 'media' ||
        (content.type === 'forward' &&
            content?.forwardedMessage &&
            typeof content.forwardedMessage === 'object' &&
            content.forwardedMessage?.type === 'media');

    let messageParagraph = content?.message;
    let emoji = content?.emoji;

    if (hasMedia) {
        messageParagraph = messageParagraph.concat(` -> ${t`Attachment File`}`);
    }

    const members = useAppSelector(state => memberDataSelector(state));
    const serverSelector = useAppSelector(state => state.serverDataReducer);
    const strangerMemberList = useAppSelector(
        state =>
            state.serverDataReducer.strangerMemberList[
                state.serverDataReducer.activeServer
            ],
    );

    const { activeServer } = serverSelector;
    const channelList = serverSelector.channelList[activeServer];
    const serverData = serverSelector?.serverData[content.subserver_id];
    const member =
        members?.find(e => e?.id === content?.user_id) ||
        strangerMemberList?.[content?.user_id];

    const joinChannelHandler = data => {
        if (!data?.subserver_id || !data?.channel_id || !isWebSocketConnected())
            return;
        if (data.subserver_id.trim() !== activeServer) {
            dispatch(setActiveServer(data.subserver_id));
        }

        setTimeout(() => {
            if (data?.thread_id) {
                handleOpenThread(data?.thread_id);
                dispatch(
                    openThreadAction({
                        _id: data?.thread_id,
                        channel_id: data?.channel_id,
                        message_id: data?.message_id,
                    }),
                );
                dispatch(mobileViewActions.changeActiveTab(4));
            } else {
                isWebSocketConnected() && onJoinChannel(data.channel_id);
            }
        }, 900);

        setTimeout(() => {
            triggerScroll(data);
        }, 1200);

        onDismiss();
    };

    useEffect(() => {
        let targetChannel: IChannelData;
        if (content?.thread_id !== undefined) {
            targetChannel = channelList[content?.channel_id];
            setChannelName(targetChannel?.name);
        } else {
            setChannelName(content?.channel_name);
        }
    }, [content]);

    useEffect(() => {
        setTimeout(() => {
            onDismiss();
        }, 7000);
    }, []);

    return (
        <div
            onClick={() => joinChannelHandler(content)}
            className={classes['container']}>
            <img
                className={classes.close}
                onClick={event => {
                    event.stopPropagation();
                    onDismiss();
                }}
                src={closeIcon}
                alt="close"
            />
            <div className={classes['d-flex']}>
                <img
                    className={classes['server-icon']}
                    src={hashtagIcon}
                    alt={'#'}
                />
                <p>
                    {`${serverData.name} > ${channelName}${
                        content?.thread_id ? ' >[THREAD]' : ''
                    }`}
                </p>
            </div>
            <div className={classes['d-flex']}>
                <Icon
                    className={classes['icon']}
                    imageURL={
                        member?.profile_picture !== null
                            ? member?.profile_picture
                            : DEFAULT_IMAGES.PROFILE_IMAGE
                    }
                    name={member?.name}
                />
                <div style={{ overflowWrap: 'anywhere' }}>
                    <p className={classes.name}>{member?.name}</p>

                    <MessageContent
                        mentionClickable={false}
                        message={
                            emoji
                                ? 'Reacted on message ' + emoji
                                : truncateText(messageParagraph, 90)
                        }
                        channel_mentions={content?.channel_mentions}
                        role_mentions={content?.role_mentions}
                        messageId={content?._id}
                    />
                </div>
            </div>
        </div>
    );
};
export default Toast;
