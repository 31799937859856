import { Trans, t } from '@lingui/macro';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { AppButton, Switch, TextInput } from '@/UI';
import { sendWebSocketData } from '@/Utils/webSocketUtils';
import { fetchBannedToServer } from '@/api/member';
import CustomErrorBoundary from '@/components/CustomErrorBoundary/CustomErrorBoundary';
import { BUTTON_SIZE } from '@/constants/enum';
import SOCKET_ACTIONS from '@/constants/socketActions';
import { useCustomSweetAlert } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
    memberDataSelector,
    rolesListSelector,
    serverDetailSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import { getMembersData } from '@/store/server-data-reducer/server-data-reducer';

import useOrganizationAddMember from '../../organization_member/organization-add-members/useOrganizationAddMember';

import classes from './EditRoles.module.css';

type Props = {
    defaultRole: {
        name: string;
        _id?: string;
        permissions?: any;
        color?: string;
        display_separately?: boolean;
    };
    permissionList: [];
};

const EditRoles = (props: Props) => {
    const { defaultRole, permissionList } = props;
    const dispatch = useAppDispatch();
    const memberListArray = useAppSelector(state => memberDataSelector(state));
    const rolesList = useAppSelector(rolesListSelector);
    const [rolesListData, setRolesListData] = useState<any>(rolesList);
    const [selectedRole, setSelectedRole] = useState(defaultRole);
    const [newButtonDisable, setNewButtonDisable] = useState(false);
    const rightSection = useRef(null);

    const filterRoleMembers = memberListArray?.filter(item =>
        item?.roles?.includes(selectedRole?._id),
    );

    const externalMembers = filterRoleMembers?.filter(
        item => item.external === 1,
    );

    const applyButtonExternalMembers = filterRoleMembers?.filter(
        item => item?.external === 0 && !item?.banned,
    );

    const { areYouSureModal, successAlertModal } = useCustomSweetAlert();

    const onNewRole = () => {
        if (defaultRole._id === undefined) {
            const getDefaultPermission = permissionList?.reduce(
                (acc, curr: any) => ({
                    ...acc,
                    [curr.slug]: curr.default_permission,
                }),
                {},
            );
            const newRole = {
                name: 'New Role',
                permissions: getDefaultPermission,
            };
            setRolesListData([...rolesListData, newRole]);
            setSelectedRole(newRole);
            setNewButtonDisable(true);
        }
    };

    useEffect(() => {
        if (!rolesListData.length) {
            onNewRole();
        }
        if (selectedRole) {
            setSelectedRole(rolesListData[0]);
        }
    }, []);

    useEffect(() => {
        if (rightSection && rightSection.current) {
            rightSection.current.scrollTop = 0;
        }
    }, [selectedRole?._id]);

    const handlePermission = (e: boolean, slug: any) => {
        if (slug === 'administrator' && e) {
            const updatedPermissions = { ...selectedRole?.permissions };

            for (const key in updatedPermissions) {
                if (updatedPermissions?.hasOwnProperty(key)) {
                    updatedPermissions[key] = true;
                }
            }
            return setSelectedRole({
                ...selectedRole,
                permissions: updatedPermissions,
            });
        } else if (!e) {
            const updateList = {
                ...selectedRole?.permissions,
                [slug]: e,
                administrator: false,
            };
            setSelectedRole({ ...selectedRole, permissions: updateList });
        } else {
            const updateList = {
                ...selectedRole?.permissions,
                [slug]: e,
            };
            setSelectedRole({ ...selectedRole, permissions: updateList });
        }
    };

    const handleDisplaySeparately = e => {
        setSelectedRole({ ...selectedRole, display_separately: e });
    };

    const handlesubmit = () => {
        const transformedArray = Object?.entries(
            selectedRole?.permissions,
        )?.map(([key, value]) => {
            return { key, value };
        });
        const data: any = {
            role_name: selectedRole.name,
            color: selectedRole.color,
            permissions: transformedArray,
            display_separately: selectedRole.display_separately,
        };

        if (selectedRole._id) {
            data.role_id = selectedRole._id;
        }

        const payload = {
            action: selectedRole._id
                ? SOCKET_ACTIONS.roleUpdate.action
                : SOCKET_ACTIONS.roleCreate.action,
            data: data,
        };

        successAlertModal();
        sendWebSocketData(JSON.stringify(payload));
        setNewButtonDisable(false);
    };

    const _renderPermission = item => {
        if (
            item.slug !== 'administrator' &&
            selectedRole?.permissions?.administrator
        )
            return;
        return (
            <div
                className={classes['permission-wrapper']}
                style={{
                    opacity:
                        item?.slug === 'administrator'
                            ? 1
                            : selectedRole?.permissions?.administrator
                            ? 0.4
                            : 1,
                }}>
                <div className={classes['pvt-text']}>
                    <span className={classes['pvt-title']}>{item?.name}</span>
                    <span className={classes['desc']}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: item?.description,
                            }}
                        />
                    </span>
                </div>
                <Switch
                    onChange={e => {
                        handlePermission(e, item?.slug);
                    }}
                    checked={
                        selectedRole?.permissions?.[item?.slug] ??
                        item?.default_permission
                    }
                />
            </div>
        );
    };

    const onDeleteRole = () => {
        let payload = {
            action: 'role_delete',
            data: {
                role_id: selectedRole._id,
            },
        };
        sendWebSocketData(JSON.stringify(payload));
    };

    // drag
    const [dataDrag, setDataDrag] = useState(rolesListData);

    useEffect(() => {
        setDataDrag(rolesListData);
    }, [rolesListData]);

    useEffect(() => {
        setRolesListData(rolesList);
    }, [rolesList]);

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const draggedItem = dataDrag[result.source.index];

        const items = Array.from(dataDrag);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setDataDrag(items);
        onDragEndCustom(result, draggedItem, items);
    };

    const onDragEndCustom = (result, draggedItem, lastItems) => {
        lastItems?.forEach((element, index) => {
            const transformedArray = Object?.entries(element?.permissions)?.map(
                ([key, value]) => {
                    return { key, value };
                },
            );

            const payloadData = {
                role_id: element?.id,
                role_name: element?.name,
                color: element?.color,
                permissions: transformedArray,
                display_separately: element?.display_separately,
                priority: index,
            };

            const payload = {
                action: element?.id ? SOCKET_ACTIONS.roleUpdate.action : null,
                data: payloadData,
            };

            sendWebSocketData(JSON.stringify(payload));
        });
    };
    const _renderDragItem = (role, index) => {
        return (
            <Draggable key={role.id} draggableId={role.id} index={index}>
                {provided => (
                    <div
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <div
                            className={
                                classes['navbar-item'] +
                                ' ' +
                                (selectedRole?.name === role?.name &&
                                    classes['active'])
                            }
                            key={role.id}
                            onClick={() => setSelectedRole(role)}>
                            <span className={classes['navbar-text']}>
                                {role?.name}
                            </span>
                        </div>
                    </div>
                )}
            </Draggable>
        );
    };

    const LeftBarList = () => (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {provided => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classes['droppable-list']}>
                        {dataDrag?.map((role, index) => {
                            return _renderDragItem(role, index);
                        })}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );

    // external
    const server = useAppSelector(state => serverDetailSelector(state));
    const organisationId = server.customer_id;
    let formData = new FormData();
    let memberUpdateProps = {
        userId: '',
        organizationId: organisationId,
        formData: formData,
    };
    const { handleMemberUpdate } = useOrganizationAddMember(memberUpdateProps);

    const [externalButtonLoading, setExternalButtonLoading] = useState(false);

    const handleApplyExternelButtonClick = async (externalValue: string) => {
        let externalMembersArray = [];
        if (externalValue === '1') {
            externalMembersArray = applyButtonExternalMembers;
        } else {
            externalMembersArray = externalMembers;
        }
        try {
            externalMembersArray?.forEach(async item => {
                memberUpdateProps.userId = item?.id;
                formData.append('external', externalValue);

                setExternalButtonLoading(true);

                await handleMemberUpdate({ isAlertShown: false });
            });
        } catch (error) {
            console.error('Error updating members:', error);
            // errorAlertModal();
        } finally {
            setExternalButtonLoading(false);
            successAlertModal();
            await dispatch(getMembersData(server?.id));
            await dispatch(fetchBannedToServer(server?.id));
        }
    };

    return (
        <div className={classes['modal-container']}>
            {/* Left Navbar */}
            <div className={classes['left-navbar']}>
                <span className={classes['left-main-par']} style={{}}>
                    <Trans>
                        You can drag and drop to change the priority of roles
                    </Trans>
                    .
                </span>
                {dataDrag?.length > 0 && (
                    <CustomErrorBoundary>
                        <LeftBarList />
                    </CustomErrorBoundary>
                )}
                <AppButton
                    disable={newButtonDisable}
                    title={t`Add New Role`}
                    leftIcon={<h2 style={{ marginRight: 4 }}>+</h2>}
                    size={BUTTON_SIZE.medium}
                    onClick={onNewRole}
                    classStyles={classes['add-role-btn']}
                />
            </div>

            <CustomErrorBoundary>
                {/* Right Content */}
                <div className={classes['container']} ref={rightSection}>
                    <div className={classes['header']}>
                        <h2 style={{ margin: 0 }}>
                            <Trans>Edit Roles</Trans>
                        </h2>
                        <span className={classes['sub-heading']}>
                            <Trans>Edit Roles and roles permissions</Trans>
                        </span>
                    </div>
                    <div className={classes['content']}>
                        <TextInput
                            containerStyle={{ width: '100%' }}
                            placeHolder={t`Role Name`}
                            title={t`Role Name`}
                            defaultValue={selectedRole?.name}
                            value={selectedRole?.name}
                            onChange={e =>
                                setSelectedRole({
                                    ...selectedRole,
                                    name: e.currentTarget.value,
                                })
                            }
                        />
                        <div className={classes['color-wrap']} style={{}}>
                            <TextInput
                                wrapClassName={classes['color-text-wrap']}
                                placeHolder={`${t`Role Color with`} #`}
                                defaultValue={selectedRole?.color}
                                value={selectedRole?.color}
                                onChange={e =>
                                    setSelectedRole({
                                        ...selectedRole,
                                        color: e.currentTarget.value,
                                    })
                                }
                                title={t`Color Code`}
                            />
                            <h3 style={{ paddingTop: 8 }}>
                                <Trans>OR</Trans>
                            </h3>
                            <TextInput
                                type="color"
                                wrapClassName={classes['color-pick-wrap']}
                                defaultValue={selectedRole?.color}
                                value={selectedRole?.color}
                                onChange={e =>
                                    setSelectedRole({
                                        ...selectedRole,
                                        color: e.currentTarget.value,
                                    })
                                }
                                title={t`Color Palette`}
                            />
                        </div>
                        <hr className={classes['line']} />
                        <div
                            className={classes['permission-wrapper']}
                            style={{
                                justifyContent: 'space-between',
                            }}>
                            <span className={classes['pvt-title']}>
                                <Trans>
                                    Display role members separately from online
                                    members
                                </Trans>
                            </span>
                            <Switch
                                onChange={handleDisplaySeparately}
                                checked={
                                    selectedRole?.display_separately ??
                                    defaultRole?.display_separately
                                }
                            />
                        </div>
                        <hr className={classes['line']} />
                        <div className="">
                            <div>
                                <div className={classes['pvt-title']}>
                                    {t`You can activate the external mode for members in the selected role.`}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 4,
                                        marginTop: 12,
                                        height: 36,
                                    }}>
                                    <AppButton
                                        style={{
                                            height: 36,
                                            width: '100%',
                                            opacity:
                                                applyButtonExternalMembers?.length ===
                                                0
                                                    ? 0.5
                                                    : 1,
                                        }}
                                        title={
                                            <span>
                                                <Trans>
                                                    Open external mode for
                                                    members
                                                </Trans>
                                                :{' '}
                                                <b>
                                                    {
                                                        applyButtonExternalMembers?.length
                                                    }
                                                </b>
                                            </span>
                                        }
                                        size={BUTTON_SIZE.small}
                                        loader={externalButtonLoading}
                                        disable={
                                            externalButtonLoading ||
                                            applyButtonExternalMembers?.length ===
                                                0
                                        }
                                        onClick={() =>
                                            handleApplyExternelButtonClick('1')
                                        }
                                    />
                                    <AppButton
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'red',
                                            height: '100%',
                                            opacity:
                                                externalMembers?.length === 0
                                                    ? 0.5
                                                    : 1,
                                        }}
                                        title={
                                            <span>
                                                <Trans>
                                                    Close external mode for
                                                    members
                                                </Trans>
                                                :{' '}
                                                <b>{externalMembers?.length}</b>
                                            </span>
                                        }
                                        size={BUTTON_SIZE.small}
                                        loader={externalButtonLoading}
                                        disable={
                                            externalButtonLoading ||
                                            externalMembers?.length === 0
                                        }
                                        onClick={() =>
                                            handleApplyExternelButtonClick('0')
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                        <hr className={classes['line']} />
                        <h3>
                            <Trans>Permissions</Trans>
                        </h3>
                        <div className={classes['permission-container']}>
                            {permissionList?.map(item =>
                                _renderPermission(item),
                            )}
                        </div>
                    </div>
                    <div className={classes['left-nav-list']}>
                        <AppButton
                            title={t`Delete`}
                            buttonType={'secondary'}
                            size={BUTTON_SIZE.medium}
                            style={{
                                color: 'red',
                                borderColor: 'red',
                                borderWidth: 2,
                            }}
                            onClick={() =>
                                areYouSureModal(
                                    t`Role will be deleted`,
                                    t`Are you sure you want to delete this role?`,
                                    false,
                                    onDeleteRole,
                                )
                            }
                        />
                        <AppButton
                            title={t`Save`}
                            size={BUTTON_SIZE.medium}
                            onClick={handlesubmit}
                        />
                    </div>
                </div>
            </CustomErrorBoundary>
        </div>
    );
};

export default EditRoles;
