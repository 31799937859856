import { FC } from 'react';

import { Icon } from '@/UI';
import { ExternalBadge } from '@/UI/externalbadge/ExternalBadge';
import { truncateText } from '@/Utils/textUtils';
import { DEFAULT_IMAGES } from '@/constants';
import { IMemberData } from '@/types';

import classes from './NewChat.module.css';

interface IProps {
    onChoose: () => void;
    item: IMemberData;
}

const UserListItem: FC<IProps> = props => {
    const { onChoose, item } = props;
    return (
        <li onClick={onChoose} className={classes['body-item']}>
            <Icon
                className={classes['person-badge']}
                imageURL={
                    item.profile_picture
                        ? item.profile_picture
                        : DEFAULT_IMAGES.PROFILE_IMAGE
                }
                name={item.name ? item.name : 'User'}
            />

            {item.name ? truncateText(item.name, 25) : 'User'}
            {item?.external > 0 && <ExternalBadge />}
        </li>
    );
};
export default UserListItem;
