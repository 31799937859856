import { Trans } from '@lingui/macro';

import { useAppSelector } from '@/hooks/useRedux';
import { serverDataSelector } from '@/store/server-data-reducer/selectors-server-data';
import { IServerData } from '@/types';

import packageInfo from '../../../package.json';

import './style.css';

function VersionPopover() {
    const serversData: IServerData[] = useAppSelector(state =>
        serverDataSelector(state),
    );

    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );
    const activeServerData = serversData?.find(
        item => item.id === activeServer,
    );

    return (
        <div className="version-popover">
            <Trans>Version</Trans>&nbsp;ⓘ
            <div className="version-details">
                <div className="triangle"></div>
                <div>
                    <b>UI:</b> <span>{packageInfo?.version ?? '-'}</span>
                </div>
                <div>
                    <b>SV:</b> <span>{activeServerData?.version ?? '-'}</span>
                </div>
            </div>
        </div>
    );
}

export default VersionPopover;
