import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';

import { sendWebSocketData } from '../../webSocketUtils';

export const handleUserStatusUpdate = status => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.updateUserStatus.action,
        data: {
            status: status,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

