import { Trans, t } from '@lingui/macro';

import { ReactComponent as ExportIcon } from '@/assets/icons/export.svg';
import { ReactComponent as GalleryIcon } from '@/assets/icons/gallery.svg';

import classes from './MediaInput.module.css';

type Props = {
    mini: boolean | undefined;
    removeInfoText: boolean | undefined;
    errorFileSize: boolean;
};
const MediaInputEmptyContent: React.FC<Props> = props => {
    const { removeInfoText, mini, errorFileSize } = props;
    return (
        <div className={classes['fileInputAlternative']}>
            <div>
                <GalleryIcon
                    width="22"
                    height="22"
                    className={classes['gallery-img']}
                />

                {!removeInfoText && (
                    <>
                        <p className={classes['export-par']}>
                            <ExportIcon className={classes['export-icon']} />
                            {mini ? t`Upload` : t`Upload Media`}
                        </p>

                        <p className={classes['size-text']}>
                            {mini ? '256x256 px' : '1090x300 px'}
                        </p>
                        <p
                            style={{
                                color: errorFileSize ? 'red' : undefined,
                                opacity: errorFileSize ? 0.7 : undefined,
                            }}
                            className={classes['size-text']}>
                            <Trans>Up to</Trans>{' '}
                            <u>
                                <b>
                                    <Trans>50MB</Trans>
                                </b>
                            </u>{' '}
                            <Trans>upload is allowed for pdf and images</Trans>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};
export default MediaInputEmptyContent;
