import { Trans } from '@lingui/macro';
import { memo } from 'react';

import packageInfo from '../../../package.json';

const Version = props => {
    const styles = {
        fontSize: '12px',
        margin: '8px 0px',
        color: 'var(--text-heading)',
        textAlign: 'center' as 'center',
    };
    const year = new Date().getFullYear();
    return props.from === 'ServerListBar' ? (
        <span style={styles}>
            <Trans>Version</Trans>&nbsp;{packageInfo.version} <br />
        </span>
    ) : (
        <span style={styles}>
            <Trans>CovChat</Trans>&nbsp;
            <Trans>Version</Trans>&nbsp;
            {packageInfo.version}
            <br /> {year}
        </span>
    );
};

export default memo(Version);
