
import { ReactComponent as SearchIcon } from '@/assets/icons/search-icon.svg';

import classes from './Connections.module.css';
import { SearchProps } from './connectionsTypes';
import { t } from '@lingui/macro';

const ConnectionsSearch: React.FC<SearchProps> = ({ onSearch, search }) => {
    return (
        <div className={classes['input-container']}>
            <SearchIcon
                className={classes['search-icon']}
                stroke="var(--gray-5)"
                height={20}
            />
            <input
                className={classes['searchUser']}
                onChange={text => onSearch(text.target.value)}
                placeholder={t`Search User`}
                value={search}
                autoFocus={true}
                type="search"
            />
        </div>
    );
};
export default ConnectionsSearch;
