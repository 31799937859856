import { decryptPassword } from '@/Utils/cryptoUtils';
import { fetchBannedToServer } from '@/api/member';
import store from '@/store';
import {
    getMembersData,
    updatePrivateKey,
} from '@/store/server-data-reducer/server-data-reducer';

import { onJoinChannel } from '../ChannelActions/channelActions';
import {
    isP2PSocketConnected,
    sendP2PSocketData,
} from '../p2p-connection/p2pConnection';

export const handleAuthSuccess = async ({
    activeServer,
    privateKey,
    serverData,
    publicKey,
    activeChannel,
}: {
    activeServer: string;
    privateKey: string;
    serverData: any;
    publicKey: string;
    activeChannel: string;
}) => {
    try {
        await store.dispatch(getMembersData(activeServer));
        await store.dispatch(fetchBannedToServer(activeServer));

        if (!serverData.defaultHashkey) {
            const privateKeyObject = decryptPassword(privateKey);
            const hashKey = CryptoJS.MD5(
                JSON.stringify(privateKeyObject),
            ).toString();
            store.dispatch(
                updatePrivateKey({
                    id: activeServer,
                    defaultHashkey: hashKey,
                    privateKey,
                    publicKey,
                }),
            );
        }
        if (activeChannel) onJoinChannel(activeChannel);
        // DO NOT DELETE
        // const payload = {
        //     action: 'get_all_messages',
        //     data: {},
        // };
        // sendWebSocketData(JSON.stringify(payload));
    } catch (error) {
        console.error('Error in handleAuthSuccess:', error);
    }
};
