import { createSlice } from '@reduxjs/toolkit';

const indexedDBSearchSlice = createSlice({
    name: 'indexedDBSearchData',
    initialState: {
        totalBlocks: 0,
        fetchedBlocks: 0,
        db: [],
        lastSentChunkToDesktop: null,
        lastReceivedChunkToDesktop: 0,
        desktopMessage: '',
        searchResults: [],
        searchError: '',
    },
    reducers: {
        setTotalSyncNumber(state, action) {
            state.totalBlocks = action.payload;
        },
        increaseFetchedNumber(state) {
            state.fetchedBlocks = state.fetchedBlocks + 1;
        },
        clearFetchedNumber(state) {
            state.fetchedBlocks = 0;
        },

        updateDB(state, action) {
            let updatedValues = state.db;
            action.payload.forEach(element => {
                const itemIndex = updatedValues.findIndex(
                    e => e._id === element._id,
                );
                updatedValues[itemIndex] = element;
                state.db = updatedValues;
            });
        },

        addToDB(state, action) {
            state.db = [...state.db, ...action.payload];
        },

        setDB(state, action) {
            state.db = action.payload;
        },

        clearDB(state) {
            state.db = [];
        },
        lastSentChunkToDesktop(state, action) {
            state.lastSentChunkToDesktop = action.payload;
        },
        lastReceivedChunkToDesktop(state, action) {
            state.lastReceivedChunkToDesktop = action.payload;
        },
        increaseLastSentChunkToDesktop(state) {
            state.lastSentChunkToDesktop = state.lastSentChunkToDesktop + 1;
        },
        setDesktopMessage(state, action) {
            state.desktopMessage = action.payload;
        },
        setSearchResults(state, action) {
            state.searchResults = action.payload;
        },
        setSearchError(state, action) {
            state.searchError = action.payload;
        },
    },
});
export const indexedDBSearchActions = indexedDBSearchSlice.actions;
export default indexedDBSearchSlice.reducer;
