import { Trans } from '@lingui/macro';

import PermissionsMeControl from '@/Utils/permissionsMeControl';
import mailIcon from '@/assets/icons/mail.svg';
import mentionIcon from '@/assets/icons/mention.svg';
import trashIcon from '@/assets/icons/trash.svg';
import useCustomSweetAlert from '@/hooks/useCustomSweetAlert';
import { useAppSelector } from '@/hooks/useRedux';
import { channelDataDetailsSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './Popup.module.css';

const UserPopup = props => {
    const { handleUserRemove, modalRef, owner } = props;

    const { areYouSureModal } = useCustomSweetAlert();

    const editableDiv = document.getElementById('contentEditable');
    const editableDiv2 = document.getElementById('contentEditable2');
    const formatMention = (username, id) => {
        return `&nbsp;<p contenteditable="false" id='mention-${id}' style='display: inline; cursor: pointer; width: fit-content; height: fit-content; 
        padding: 2px 3px; justify-content: center; align-items: center; border-radius: 4px; background: 
        var(--tag-bg-brown); color: var(--tag-text); font-size: 12px; font-weight: 500; line-height: 16px;'
        >@${username}</p>&nbsp;`;
    };

    let channelDetails = useAppSelector(channelDataDetailsSelector);

    let channelPermissionsCreate =
        channelDetails?.channelPermissions?.['message.create'];

    let channelPermissionsUserDelete =
        channelDetails?.channelPermissions?.['user.delete'];

    const ownerPopupData = [
        channelPermissionsCreate && {
            icon: mailIcon,
            label: (
                <p>
                    <Trans>Send Message</Trans>
                </p>
            ),
            onPress: item => {
                props.onNewChat(item?.id);
            },
            permissions: ['message.create'],
        },
        channelPermissionsCreate && {
            icon: mentionIcon,
            label: (
                <p>
                    <Trans>Mention</Trans>
                </p>
            ),
            onPress: item => {
                const targetEditableDiv =
                    props?.inputType === 'threadInput'
                        ? editableDiv2
                        : editableDiv;

                targetEditableDiv?.focus();
                targetEditableDiv.innerHTML =
                    (item &&
                        targetEditableDiv.innerHTML.concat(
                            formatMention(item?.username, item?.id),
                        )) ||
                    '';
            },
            permissions: ['message.create'],
        },
        channelPermissionsUserDelete && {
            icon: trashIcon,
            label: (
                <p>
                    <Trans>Remove from the channel</Trans>
                </p>
            ),
            onPress: item => {
                areYouSureModal(null, '', true, () =>
                    handleUserRemove(item?.id),
                );
            },
            permissions: ['owner', 'administrator', 'user.delete'],
        },
    ];
    const filterOwnerMenu = PermissionsMeControl(ownerPopupData, 'permissions');

    return (
        <div
            className={`${classes['owner-image-popup-container']} ${classes['bottom']}`}>
            <div className={classes['owner-image-popup']} ref={modalRef}>
                <div className={classes['triangle']}></div>
                {filterOwnerMenu?.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => {
                            item.onPress && item?.onPress(owner);
                        }}
                        className={classes['owner-image-popup-row']}>
                        <img alt="" height={18} width={18} src={item?.icon} />
                        <p>{item?.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserPopup;
