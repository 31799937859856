// usage: const value =  PermissionsMeControlBoolean(['per1','per2']) -> true or false
import { useAppSelector } from '@/hooks/useRedux';
import {
    userPermissionSelector,
} from '@/store/server-data-reducer/selectors-server-data';

export default function PermissionsMeControlBoolean(permissionsArray) {
    //permissions me
    const permissionsMe = useAppSelector(userPermissionSelector);
    // const permissionsMe = server.userPermissions;
    //permissions me

    //Me Permissions but only true key
    function getTrueKeys(jsonObj: Record<string, boolean>) {
        const trueKeys: string[] = [];
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key) && jsonObj[key] === true) {
                trueKeys.push(key);
            }
        }
        return trueKeys;
    }

    // true or false
    const isAllowed = permissionsArray?.some(permission =>
        getTrueKeys(permissionsMe)?.includes(permission),
    );
    // true or false

    return isAllowed || false;
}
