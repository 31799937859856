import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { Icon } from '@/UI';
import { removeUserFromChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import ErrorCircleSvg from '@/assets/icons/circle-exclamation-solid.svg';
import { DEFAULT_IMAGES } from '@/constants';
import useLongPress from '@/hooks/useLongPress';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';
import { IUser } from '@/types/slices/UserTypes';

import MediaMessage from '../media-message/MediaMessage';
import MessageActions from '../message-actions/MessageActions';

import EditingMessage from './EditingMessage';
import MessageHashWarning from './MessageHashWarning';
import classes from './MessageItem.module.css';
import MessageItemHeader from './MessageItemHeader';
import { Props } from './MessageItemsTypes';
import ReplyPart from './ReplyPart';
import ForwardPart from './forward-part/ForwardPart';
import MessageContent from './message-content/MessageContent';
import MessageThreadLabel from './message-thread/MessageThreadLabel';
import RenderMessageModals from './render-modals/RenderMessageModals';
import ReadUnReadPopup from './render-popups/ReadUnreadPopup';
import SendErrorPopup from './render-popups/SendErrorPopup';
import UserPopup from './render-popups/UserPopup';
import RenderReactions from './render-reactions/RenderReactions';
import useMessageItem from './useMessageItem';

const MessageItem = (props: Props) => {
    const {
        type,
        message,
        mediaFiles,
        reactions,
        user_id,
        _id,
        forwardedMessage,
        channel_mentions,
        role_mentions,
        channel_id,
        replyData,
        created_at,
        total_thread_message_count,
        thread_last_message,
        is_encrypt,
        key_hash,
        sending_error,
        thread_id,
    } = props.item;

    const { removeThreadAction } = props;

    const {
        owner,
        isEditing,
        replyMessageHandler,
        clickReactionHandler,
        editMessageHandler,
        handleKeyPress,
        forwardMsgHandler,
        copyMsgHandler,
        isForward,
        setIsForward,
    } = useMessageItem(props);

    const messageRef = useRef(null);
    const modalRef = useRef(null);

    const [target, setTraget] = useState(null);
    const [scrollMessageStyle, setScrollMessageStyle] = useState({});
    const [isRead, setIsRead] = useState(false);
    const [selectedOwnerPopup, setSelectedOwnerPopup] = useState(null);
    const [sendErrorPopup, setSendErrorPopup] = useState(false);
    const [filteredUsers, setFilterdUsers] = useState([]);
    const [seenCounts, setSeenCounts] = useState();
    const [longPressed, setLongPressed] = useState(false);

    const user: IUser = useAppSelector(state => state.userReducer.user);
    const userId = user.id;

    const members = useAppSelector(state => memberDataSelector(state));
    const strangerMemberList = useAppSelector(
        state =>
            state.serverDataReducer.strangerMemberList[
                state.serverDataReducer.activeServer
            ],
    );
    const lastSeenUsers = useAppSelector(
        state => state.messageReducer.lastSeenMessage,
    );

    const isForwardMedia = type === 'forward' && forwardedMessage?.mediaFiles;

    const memoizedMediaFiles = useMemo(() => {
        let returnFiles = type === 'media' ? mediaFiles : mediaFiles;
        return {
            mediaFiles: returnFiles,
        };
    }, []);

    const handleUserRemove = user_id => {
        removeUserFromChannel([user_id], channel_id);
    };

    const openOwnerPopup = id => {
        setIsRead(false);
        setSelectedOwnerPopup(prev => (prev === id ? null : id));
    };

    const handleNewChat = useCallback((id: string) => props.onNewChat(id), []);

    const handleOwnerClick = () => {
        openOwnerPopup(owner?.id);
        setIsRead(false);
    };

    const handleLastSeen = () => {
        const users = lastSeenUsers.map(val => {
            const isSeen =
                new Date(val.message_timestamp) >= new Date(created_at) &&
                userId !== val?.user_id;

            const userWithStatus = {
                ...val,
                status: isSeen ? 'seen' : 'unseen',
            };
            return userWithStatus;
        });
        setFilterdUsers(users);
    };

    useEffect(() => {
        const closeModalOnOutsideClick = e => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                setSelectedOwnerPopup(null);
                setIsRead(false);
                setSendErrorPopup(false);
            }
        };
        if (selectedOwnerPopup !== null)
            document.body.classList.add('no-scroll');

        document.addEventListener('mousedown', closeModalOnOutsideClick);

        return () => {
            document.removeEventListener('mousedown', closeModalOnOutsideClick);
        };
    }, [selectedOwnerPopup]);

    useEffect(() => {
        if (lastSeenUsers?.length > 0) {
            handleLastSeen();
        }
    }, [lastSeenUsers]);

    useEffect(() => {
        const counts = filteredUsers?.reduce(
            (acc, user) => {
                acc[user.status]++;
                return acc;
            },
            { seen: 0, unseen: 0 },
        );
        setSeenCounts(counts);
    }, [filteredUsers]);

    useEffect(() => {
        if (props.messageStyle) {
            setScrollMessageStyle({ backgroundColor: 'var(--room-item)' });
        }

        setTimeout(() => {
            setScrollMessageStyle({});
        }, 1700);
    }, [props.messageStyle]);

    const onLongPress = () => {
        setLongPressed(true);
    };

    useLongPress(onLongPress, 500);

    return (
        <div
            ref={messageRef}
            key={`message-list-item-${_id}`}
            style={{ flexWrap: 'wrap', ...scrollMessageStyle }}
            onMouseLeave={() => {
                setSelectedOwnerPopup(null);
                setIsRead(false);
            }}
            className={`${classes['message-line-container']} ${
                thread_last_message && total_thread_message_count
                    ? classes['active-thread']
                    : ''
            }`}>
            <div
                onClick={handleOwnerClick}
                style={{
                    position: 'relative',
                    flex: 'none',
                }}>
                <Icon
                    name={owner?.name ?? '--USER--'}
                    className={classes['owner-image']}
                    imageURL={
                        owner?.profile_picture ?? DEFAULT_IMAGES.PROFILE_IMAGE
                    }
                />

                {selectedOwnerPopup &&
                    selectedOwnerPopup !== userId &&
                    !isRead && (
                        <UserPopup
                            handleUserRemove={handleUserRemove}
                            modalRef={modalRef}
                            owner={owner}
                            onNewChat={handleNewChat}
                            inputType={props?.inputType}
                        />
                    )}
            </div>
            <div className={classes['font-12']} style={{ flex: 1 }}>
                <MessageItemHeader
                    owner={owner}
                    userId={user_id}
                    time={props.time}
                    onClick={() => openOwnerPopup(owner?.id)}
                />

                {isEditing && (
                    <EditingMessage
                        message={props.item.message}
                        onKeyDown={e => {
                            handleKeyPress(e, props.inputType);
                        }}
                    />
                )}

                {(type === 'reply' ||
                    (type === 'media' && replyData) ||
                    (type === 'media' && message?.replyTo)) && (
                    <ReplyPart
                        replyProps={replyData ? replyData : message?.replyTo}
                    />
                )}
                {type === 'forward' && (
                    <ForwardPart forwardContent={forwardedMessage} />
                )}
                {owner?.id === selectedOwnerPopup && isRead && (
                    <ReadUnReadPopup
                        modalRef={modalRef}
                        target={target}
                        filteredUsers={filteredUsers}
                        userId={userId}
                    />
                )}

                {is_encrypt && key_hash ? (
                    <MessageHashWarning />
                ) : (
                    <MessageContent
                        mentionClickable={true}
                        message={message}
                        onNewChat={handleNewChat}
                        channel_mentions={channel_mentions}
                        role_mentions={role_mentions}
                        messageId={_id}
                    />
                )}

                {(isForwardMedia || type === 'media' || type === 'forward') && (
                    <MediaMessage
                        allowView
                        msg={{
                            owner: {
                                name: owner?.name,
                                profile_picture:
                                    owner?.profile_picture ||
                                    DEFAULT_IMAGES.PROFILE_IMAGE,
                            },
                            message: props.item?.message,
                        }}
                        {...memoizedMediaFiles}
                    />
                )}
                {reactions?.length > 0 && (
                    <RenderReactions
                        reactions={reactions}
                        user={user}
                        members={members}
                        strangerMemberList={strangerMemberList}
                        clickReactionHandler={e => {
                            clickReactionHandler(e, {}, props?.inputType);
                        }}
                    />
                )}
            </div>

            {sending_error && (
                <img
                    alt=""
                    onClick={() => setSendErrorPopup(prev => !prev)}
                    src={ErrorCircleSvg}
                    className={classes['error-circle']}
                />
            )}

            {!sending_error && (
                <MessageActions
                    className={`${
                        longPressed ? classes['showActionBtns'] : ''
                    } ${classes['action-buttons']}`}
                    onEditMessage={editMessageHandler}
                    user_id={owner?.id}
                    canDelete={user_id === userId}
                    msgId={_id}
                    isLastSeen={lastSeenUsers?.length > 1}
                    message={props?.item?.message}
                    onReply={replyMessageHandler}
                    onForward={forwardMsgHandler}
                    onCopy={copyMsgHandler}
                    seenCounts={seenCounts}
                    usersLength={filteredUsers?.length}
                    threadId={thread_id}
                    inputType={props.inputType}
                    removeThreadAction={removeThreadAction}
                    threadLastMessage={
                        thread_last_message && total_thread_message_count > 0
                    }
                    onMessageReaction={e => {
                        props.onReaction();
                        clickReactionHandler(e, {}, props?.inputType);
                    }}
                    handlereadUnread={e => {
                        openOwnerPopup(owner?.id);
                        setIsRead(true);
                        setTraget(e.currentTarget);
                    }}
                />
            )}
            {thread_last_message !== undefined && total_thread_message_count ? (
                <MessageThreadLabel
                    threadMessage={thread_last_message}
                    messageCount={total_thread_message_count}
                    messageId={_id}
                />
            ) : null}
            <RenderMessageModals
                owner={owner}
                isForward={isForward}
                setIsForward={setIsForward}
                item={props.item}
            />
            {sendErrorPopup && (
                <SendErrorPopup modalRef={modalRef} messageItem={props.item} />
            )}
        </div>
    );
};

export default React.memo(MessageItem);
