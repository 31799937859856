import { useFormik } from 'formik';
import { useState } from 'react';

import { REQUEST_TYPE } from '@/constants/requstTypes';
import { useValidation } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
    getOrganizationsMembersData,
    postAddMembertoServer,
    putCreateMember,
} from '@/store/organization-reducer';

type Props = {
    onClose?: any;
    server: [];
    selectedServer: [];
    page: any;
    setPage: any;
};

const useOrganizationCreateMember = (props?: Props) => {
    const { server } = props;
    const [serverData, setServerData] = useState(server);
    const [searchText, setSearchText] = useState('');
    const [selectedServer, setSelectedServer] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [admin, setAdmin] = useState(false);
    const [page, setPage] = useState('1');

    const ROLES = {
        ADMIN: 'admin',
        MEMBER: 'member',
    };
    const organizationList = useAppSelector(
        state => state.organizationReducer.organizationList,
    );
    const dispatch = useAppDispatch();

    interface FormValues {
        organizationId: string;
        username: string;
        name: string;
        email: string;
        password: string;
        confirm_password: string;
        external: boolean;
    }

    const handleSave = async (user, selectedServer) => {
        const data = selectedServer;
        try {
            await Promise.all(
                data?.map(async item => {
                    let requestType = REQUEST_TYPE.POST;
                    const payload = {
                        organizationId: item?.organizationId,
                        user_id: user?.id,
                        serverId: item?.id,
                        is_admin: admin ? 1 : 0,
                        requestType: requestType,
                    };
                    await dispatch(postAddMembertoServer(payload));
                    handleClose();
                }),
            );
        } catch (error) {
            console.error('Error in handleSave:', error);
        }
    };

    const onSubmit = async (values: FormValues) => {
        let valid = validate(values);
        let payload = values;
        let returnResUser;

        if (Object.keys(valid).length > 0) {
            return formik.validateForm().then(() => {
                formik.setErrors(valid);
            });
        }
        if (page === '1') {
            payload.organizationId = values.organizationId;
            payload.role = admin ? ROLES.ADMIN : ROLES.MEMBER;
            payload.external = payload.external ? '1' : '0';
            setPage('2');
        }
        if (page === '2') {
            const res = await dispatch(putCreateMember(payload));
            if (res?.payload?.data?.user) {
                returnResUser = res?.payload?.data?.user;
            }
            if (res?.payload?.data?.status) {
                setSuccessMessage(res.payload?.data?.message);
                setAdmin(false);
                formik.setFieldValue('external', false);
                dispatch(getOrganizationsMembersData(values.organizationId));
                setTimeout(() => {
                    setSuccessMessage('');
                }, 1000);
            } else {
                setPage('1');
                formik.setErrors(res?.payload?.data?.errors ?? {});
            }
        }
        return returnResUser;
    };

    const { validateEmail, isRequired, validatedErrorObject } = useValidation();
    const validate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        errors.name = isRequired(values.name);
        errors.username = isRequired(values.username);
        errors.organizationId = isRequired(values.organizationId);
        errors.email = validateEmail(values.email);
        errors.password = isRequired(values.password);

        if (values.password && values.password.length < 8) {
            errors.password = 'Password must be at least 8 characters long!';
        }
        if (values.password) {
            if (!values.confirm_password) {
                errors.confirm_password = 'Confirm Password is required!';
            } else if (values.confirm_password !== values.password) {
                errors.confirm_password = 'Passwords do not match!';
            }
        }
        return validatedErrorObject(errors);
    };

    // form
    const initialValues: FormValues = {
        organizationId: '',
        username: '',
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        external: false,
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
    });

    const handleClose = () => {
        props?.onClose();
        setTimeout(() => {
            formik.resetForm();
            setAdmin(false);
            setSelectedServer([]);
            setPage('1');
        }, 500);
    };

    // SERVERS
    const handleServerClick = item => {
        const currentSelectedServers = selectedServer;

        // Check if the item is already selected
        const isSelected = currentSelectedServers.some(
            selectedItem => selectedItem.id === item.id,
        );

        // If the item is already selected, remove it; otherwise, add it
        const updatedSelectedServers = isSelected
            ? currentSelectedServers.filter(
                  selectedItem => selectedItem.id !== item.id,
              )
            : [...currentSelectedServers, item];

        // Update local state with the new selected servers
        setSelectedServer(updatedSelectedServers);
    };

    const filterServerData = text => {
        setSearchText(text);
        let tempData = [...server];
        tempData = tempData.filter(item =>
            item?.name?.toLowerCase().includes(text.toLowerCase()),
        );
        setServerData(tempData);
    };

    return {
        formik,
        handleClose,
        successMessage,
        admin,
        setAdmin,
        organizationList,
        selectedServer,
        handleServerClick,
        serverData,
        filterServerData,
        searchText,
        handleSave,
        onSubmit,
        page,
        setPage,
    };
};

export default useOrganizationCreateMember;
