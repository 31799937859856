const compressImage = async image => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, img.width, img.height);
                const fromByteToMB = 1024 * 1024; // 1.5MB
                let wantedSize;
                if (image.size > 3 * fromByteToMB) {
                    wantedSize = 0.2;
                } else if (image.size > 1.5 * fromByteToMB) {
                    wantedSize = 0.4;
                } else if (image.size > 0.6 * fromByteToMB) {
                    wantedSize = 0.5;
                } else {
                    wantedSize = 0.8;
                }

                canvas.toBlob(
                    blob => {
                        resolve(
                            new File([blob], image.name, {
                                type: image.type,
                            }),
                        );
                    },
                    image.type,
                    wantedSize,
                ); // Adjust compression quality as needed (0.7 = 70% quality)
            };
        };
        reader.onerror = reject;
        reader.readAsDataURL(image);
    });
};

export default compressImage;
