import { Trans, t } from '@lingui/macro';
import { useContext, useState } from 'react';
import Select from 'react-select';

import { AppButton, Modal, Switch } from '@/UI';
import { deleteNoteCategoryApi } from '@/api/notes/noteCategoriesApis';
import { NotesContext } from '@/context/notes/NotesContext';
import { useAppSelector } from '@/hooks/useRedux';

import { styles } from '../new-note-section/NewNoteSelectStyle';

import classes from './DeleteCategoryModal.module.css';

const DeleteCategoryModal: React.FC<{}> = () => {
    const context = useContext(NotesContext);

    const {
        noteCat,
        activeCategory,
        isDeleteCategory,
        getNotes,
        removeCategory,
        setActiveCategory,
        setIsDeleteCategory,
    } = context;

    const [isDeleteNotes, setIsDeleteNotes] = useState(false);
    const [catId, setCatId] = useState('');
    let catName = '';
    const [isSubmitting, setIsSubmitting] = useState(false);

    const subserverId = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    let selectOptions: { value: string; label: string }[] = [
        { value: '', label: 'All' },
    ];
    noteCat.forEach(item => {
        if (item.id && isDeleteCategory.id && item.id !== isDeleteCategory.id) {
            selectOptions.push({ value: item.id, label: item.title });
        } else if (
            item.id &&
            isDeleteCategory.id &&
            item.id === isDeleteCategory.id
        ) {
            catName = item.title;
        }
    });

    const deleteApi = async () => {
        setIsSubmitting(true);
        let body = {};
        if (isDeleteNotes) {
            body = { clear_notes: 1 };
        } else {
            body = {
                new_section_id: catId,
            };
        }
        const { data } = await deleteNoteCategoryApi({
            subserverId: subserverId,
            catId: isDeleteCategory.id,
            body: body,
        });
        setIsSubmitting(false);

        if (data?.status) {
            removeCategory(isDeleteCategory.id);
            if (activeCategory === isDeleteCategory.id) {
                setActiveCategory('All');
            }
            setIsDeleteCategory({ status: false, id: '' });
            getNotes();
        }
    };

    const deleteCategory = ev => {
        ev.preventDefault();
        deleteApi();
    };

    return (
        <Modal
            header={t`Delete Note`}
            className={classes.modal}
            headerClassName={classes['header']}
            onClose={() => setIsDeleteCategory({ status: false, id: '' })}>
            <form onSubmit={deleteCategory}>
                <div className={`${classes['container']}`}>
                    <label className={classes['flex']} htmlFor="">
                        <Trans>Delete all notes inside</Trans>&nbsp;{catName}
                        <Switch
                            className={classes['switch']}
                            checked={isDeleteNotes}
                            onChange={val => setIsDeleteNotes(val)}
                        />
                    </label>
                    {!isDeleteNotes && (
                        <div className={classes['input-wrap']}>
                            <label htmlFor="category-name">
                                <Trans>Move notes to</Trans>:
                            </label>
                            <Select
                                name="category-name"
                                isSearchable={true}
                                styles={styles}
                                placeholder={t`Select Category`}
                                defaultValue={''}
                                className={`${classes['select']}`}
                                onChange={ev => {
                                    // @ts-ignore
                                    setCatId(ev.value);
                                }}
                                // @ts-ignore
                                options={selectOptions}
                            />
                        </div>
                    )}

                    <div className={classes['btn-wrap']}>
                        <AppButton
                            onClick={() =>
                                setIsDeleteCategory({ status: false, id: '' })
                            }
                            title={t`Cancel`}
                            classStyles={classes['cancel']}
                            disable={isSubmitting}
                        />
                        <AppButton
                            title={t`Delete`}
                            type="submit"
                            buttonType="danger"
                            classStyles={classes['save']}
                            disable={isSubmitting}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};
export default DeleteCategoryModal;
