import { Trans, t } from '@lingui/macro';
import { useEffect, useRef } from 'react';
import Select from 'react-select';

import { AppButton, Switch, TextInput } from '@/UI';
import closeSVG from '@/assets/icons/close-header.svg';
import addUserSVG from '@/assets/icons/user-green-add-white-bg.svg';
import { BUTTON_SIZE, BUTTON_TYPE } from '@/constants/enum';

import classes from './OrganizationCreateMember.module.css';
import OrganizationCreateMemberServerList from './OrganizationCreateMemberServerList';
import { addMemberSelectStyles } from './selectStyles';
import useOrganizationCreateMember from './useOrganizationCreateMember';

type Props = {};

const OrganizationCreateMember = (props: any) => {
    const {
        formik,
        handleClose,
        successMessage,
        admin,
        setAdmin,
        organizationList,
        handleSave,
        onSubmit,
        selectedServer,
        handleServerClick,
        filterServerData,
        searchText,
        serverData,
        page,
        setPage,
    } = useOrganizationCreateMember(props);

    const otherProps = {
        formik,
        handleClose,
        successMessage,
        admin,
        setAdmin,
        organizationList,
        handleSave,
        onSubmit,
        selectedServer,
        handleServerClick,
        filterServerData,
        searchText,
        serverData,
        page,
        setPage,
    };

    const _renderHeader = () => (
        <div className={classes['header']}>
            <div className={classes['heading-wrapper']}>
                <img alt="" src={addUserSVG} />
                <div className={classes['text-wrapper']}>
                    <h2>
                        <Trans>Create User</Trans>
                    </h2>
                    <span>
                        <Trans>For Organization</Trans>
                    </span>
                </div>
            </div>
            <img alt={t`close`} src={closeSVG} onClick={handleClose} />
        </div>
    );
    const _renderTextField = (key, type?, placeHolder?) => (
        <TextInput
            style={{ width: '100%' }}
            placeHolder={placeHolder + '*'}
            name={key}
            value={formik.values[key]}
            onChange={formik.handleChange}
            error={formik?.errors[key]}
            type={type}
            autoComplete="new-password"
        />
    );

    const _renderButtons = () => (
        <div className={classes['button-wrapper']}>
            <div
                onClick={handleClose}
                style={{
                    width: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Trans>Cancel</Trans>
            </div>
            <AppButton
                size={BUTTON_SIZE.medium}
                buttonType={BUTTON_TYPE.primary}
                title={t`Next`}
                style={{ width: '100%' }}
                onClick={() => {
                    formik.submitForm();
                }}
                type={'button'}
            />
        </div>
    );

    const containerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                handleClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef, handleClose]);

    let selectOptions = organizationList.map(item => {
        return { value: item.id, label: item.name };
    });
    const selectRef = useRef(null);

    return (
        <div ref={containerRef} className={classes['container']}>
            <form
                autoComplete="new-password"
                style={{ height: '95%' }}
                onSubmit={formik.handleSubmit}>
                {_renderHeader()}

                <div
                    className={`${classes['form-success']} ${
                        successMessage !== ''
                            ? classes['visible']
                            : classes['invisible']
                    }`}>
                    <span>{successMessage}</span>
                </div>

                {/* PAGE-1 */}
                {page === '1' && (
                    <div className={classes['content-container']}>
                        <span className={classes['span-correct-info']}>
                            <Trans>
                                Make sure you enter the correct information to
                                add users to the server
                            </Trans>
                            .
                        </span>
                        <label
                            className={classes['section-label']}
                            htmlFor="channel-section">
                            <Trans>Select Organization</Trans>
                        </label>

                        <Select
                            ref={selectRef}
                            name="organizationId"
                            isSearchable
                            styles={addMemberSelectStyles}
                            placeholder={`${t`Select Organization`}*`}
                            className={`${classes['select']}`}
                            value={selectOptions.find(
                                option =>
                                    option.value ===
                                    formik.values.organizationId,
                            )}
                            onChange={selectedOption => {
                                formik.setFieldValue(
                                    'organizationId',
                                    selectedOption.value,
                                );
                            }}
                            options={selectOptions}
                            classNamePrefix={classes['react-select']}
                        />
                        {formik.errors.organizationId && (
                            <span style={{ color: '#f5315d' }}>
                                {formik.errors.organizationId}
                            </span>
                        )}

                        <div className={classes['user-form']}>
                            <div className={classes['username-wrapper']}>
                                {_renderTextField('name', null, t`Name`)}
                                {_renderTextField(
                                    'username',
                                    null,
                                    t`Username`,
                                )}
                            </div>
                            {_renderTextField('email', null, t`Email`)}
                            {_renderTextField(
                                'password',
                                'password',
                                t`Password`,
                            )}
                            {_renderTextField(
                                'confirm_password',
                                'password',
                                t`Confirm Password`,
                            )}

                            <label
                                className={classes['role-wrapper']}
                                style={{
                                    justifyContent: 'space-between',
                                }}>
                                <span className={classes['pvt-title']}>
                                    <Trans>Admin</Trans>
                                </span>
                                <Switch
                                    onChange={e => setAdmin(e)}
                                    checked={admin}
                                />
                            </label>
                            <label
                                className={classes['role-wrapper']}
                                style={{
                                    justifyContent: 'space-between',
                                }}>
                                <Trans>External</Trans>&nbsp; :
                                <Switch
                                    id="external"
                                    onChange={e =>
                                        formik.setFieldValue('external', e)
                                    }
                                    checked={formik.values['external']}
                                />
                            </label>
                        </div>
                        {_renderButtons()}
                    </div>
                )}
                {/* PAGE-1 */}

                {/* PAGE-2 */}
                {page === '2' && (
                    <OrganizationCreateMemberServerList
                        setPage={props?.setPage}
                        server={props.server}
                        formik={formik}
                        selectedServer={selectedServer}
                        {...otherProps}
                    />
                )}
                {/* PAGE-2 */}
            </form>
        </div>
    );
};

export default OrganizationCreateMember;
