const MessageIcon = ({ fill, style }) => {
    return (
        <svg
            style={style}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="transparent"
            xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.4"
                d="M22.6667 27.3334H9.33341C5.33341 27.3334 2.66675 25.3334 2.66675 20.6667V11.3334C2.66675 6.66669 5.33341 4.66669 9.33341 4.66669H22.6667C26.6667 4.66669 29.3334 6.66669 29.3334 11.3334V20.6667C29.3334 25.3334 26.6667 27.3334 22.6667 27.3334Z"
                fill={fill ? fill : '#A39ED2'}
            />
            <path
                d="M16.0001 17.16C14.8801 17.16 13.7468 16.8134 12.8801 16.1067L8.70678 12.7734C8.28011 12.4267 8.20012 11.8 8.54678 11.3734C8.89345 10.9467 9.52012 10.8667 9.94679 11.2134L14.1201 14.5467C15.1334 15.36 16.8534 15.36 17.8668 14.5467L22.0401 11.2134C22.4668 10.8667 23.1068 10.9334 23.4401 11.3734C23.7868 11.8 23.7201 12.44 23.2801 12.7734L19.1068 16.1067C18.2534 16.8134 17.1201 17.16 16.0001 17.16Z"
                fill={fill ? fill : '#A39ED2'}
            />
        </svg>
    );
};
export default MessageIcon;
