import { memo } from 'react';

import formatFileSize from '@/Utils/fileSzeFormatter';
import { truncateFileName } from '@/Utils/truncateFileName';
import DownloadedTypeIcon from '@/components/download-btn/DownloadedTypeIcon';
import SaveBtn from '@/components/save-btn/SaveBtn';

import classes from './SelectedMediaDisplay.module.css';
import { UnknownMediaProps } from './SelectedMediaTypes';

const UnknowMedia: React.FC<UnknownMediaProps> = props => {
    const { file } = props;
    const outputFileName = truncateFileName(file?.name ?? '');

    return (
        <div
            className={classes['download-file-container']}
            onClick={e => e.stopPropagation()}>
            <DownloadedTypeIcon type={file?.type ?? ''} />

            <div className={classes['mr-auto']}>
                <p className={classes['label-name']}>{outputFileName}</p>
                <p className={classes['label-size']}>
                    {file.data?.size
                        ? `${formatFileSize(+file.data.size)}`
                        : 'Unknown size'}
                </p>
            </div>
            <SaveBtn className={classes['save-btn']} src={file?.src} name={file?.name ?? 'Unknown'} />
        </div>
    );
};
export default memo(UnknowMedia);
