import { motion } from 'framer-motion';
import { Sentry } from 'react-activity';

import { useAppSelector } from '@/hooks/useRedux';

import classes from './UploadServerKeys.module.css';

const P2PLogin: React.FC<{}> = () => {
    const peerFound = useAppSelector(state => state.p2pReducer.peerFound);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className={`${classes['p2p-wrap']}`}>
            <Sentry size={40} speed={0.5} />
            <motion.p
                initial={{ opacity: 0, y: -10 }}
                exit={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}>
                {peerFound}
            </motion.p>
        </motion.div>
    );
};
export default P2PLogin;
