import store from "@/store";
import { indexedDBSearchActions } from "@/store/search-reducer/indexed-db-search-reducer.js";
import { setSearchLoading, setTimeZone } from "@/store/util-reducer";



export const searchChatServerDataHandler = data => {
    // setSearchData(data);
    store.dispatch(indexedDBSearchActions.setSearchResults(data));
    store.dispatch(setSearchLoading(false));
};

export const setSearchTimeZone = data => {
    store.dispatch(setTimeZone(data));
};

export const setChatSearchError = (data: any) => {
   store.dispatch(indexedDBSearchActions.setSearchError(data?.message));
    store.dispatch(setSearchLoading(false));
};