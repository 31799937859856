import { decryptPassword } from '@/Utils/cryptoUtils';
import { onDecryptMessage } from '@/Utils/onDecryptMessage';
import { SESSION_STORAGE } from '@/constants/enum';
import store from '@/store';
import {
    addLastSeenMessage,
    addMessage,
    changeMessagesList,
    deleteMessage,
    editMsgReaction,
    updateMessageList,
    updateNextMessageList,
} from '@/store/messages-reducer/messages-reducer';
import { closeThreadAction } from '@/store/thread-reducer/threadsSlice';
import { wsActions } from '@/store/ws-reducer';
import { IMessageData } from '@/types';

export const handleMessageList = async ({
    messages,
    channel_id,
    privateKey,
    prev,
    privateKeysObject,
    handleMediaMessages,
}: {
    messages: IMessageData[];
    channel_id: string;
    prev: boolean;
    privateKey: string;
    privateKeysObject: any;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
    let channelId = channel_id;

    const startTime = performance.now();

    const messgagePrivateKey =
        messages?.length > 0
            ? privateKeysObject[messages[0]?.subserver_id]?.privateKey ??
              privateKey
            : privateKey;

    let decrypt_jwk = decryptPassword(
        messgagePrivateKey,
        sessionStorage.getItem(SESSION_STORAGE.devicePassword),
    );

    const cryptoKey = await window.crypto.subtle.importKey(
        'jwk',
        decrypt_jwk,
        { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
        false,
        ['decrypt'],
    );

    const decryptDataList = await Promise.all(
        messages?.map(async (item: IMessageData) => {
            let decryptMessage = item.message;
            let MessageData;
            let threadlastMessage;
            try {
                MessageData = await onDecryptMessage(item, cryptoKey);
                if (item?.thread_last_message) {
                    let decryptthreadlastMessage = await onDecryptMessage(
                        item?.thread_last_message,
                        cryptoKey,
                    );
                    threadlastMessage = {
                        ...item?.thread_last_message,
                        ...decryptthreadlastMessage,
                    };
                }
            } catch (error) {
                console.error(error);
            }
            if (
                item.type === 'media' ||
                (item.type === 'forward' &&
                    decryptMessage?.forwardedMessage?.type === 'media')
            ) {
                handleMediaMessages(decryptMessage, item._id, false);
            }
            if (item?.sender_message_id === threadlastMessage?.message_id) {
                channelId = item?.channel_id;
                threadlastMessage = {
                    ...threadlastMessage,
                    message_id: item?._id,
                };
            }

            decryptMessage = {
                ...MessageData,
                thread_last_message: threadlastMessage,
            };

            return { ...item, ...decryptMessage };
        }),
    );
    // dispatch(setMessageLoader(false));
    if (prev === false) {
        store.dispatch(
            updateNextMessageList({
                messages: decryptDataList,
                channel_id: channelId,
            }),
        );
    } else {
        store.dispatch(
            updateMessageList({
                messages: decryptDataList,
                channel_id: channelId,
            }),
        );
    }
};

export const onNewMessage = async ({
    data,
    privateKey,
    handleMediaMessages,
}: {
    data: any;
    privateKey: string;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
    let decryptMessage: any = data.message;
    let messageItem: any = { ...data };

    try {
        decryptMessage = await onDecryptMessage(data, privateKey);
        messageItem = { ...messageItem, ...decryptMessage };
    } catch (error) {
        console.error(error);
    }

    if (data.type === 'forward') {
        if (messageItem.forwardedMessage.type === 'media') {
            await handleMediaMessages(
                messageItem.forwardedMessage.message,
                messageItem.forwardedMessage._id,
                false,
            );
        }
    }
    if (data.type === 'media') {
        await handleMediaMessages(decryptMessage, data._id, false);
    }

    store.dispatch(addMessage(messageItem));
};

export const handleEditReaction = (data: { is_deleted: boolean }) => {
    if (data.is_deleted) {
        store.dispatch(editMsgReaction({ type: 'DELETE', reactionData: data }));
    } else {
        store.dispatch(editMsgReaction({ type: 'ADD', reactionData: data }));
    }
};

export const handleMessagesInRangle = async ({
    data,
    channel_id,
    privateKey,
    handleMediaMessages,
}: {
    data: any;
    channel_id: string;
    privateKey: string;
    handleMediaMessages: (
        decryptMessage: any,
        messageId: string,
        active: boolean,
    ) => void;
}) => {
    const decryptedList = await Promise.all(
        data?.map(async (item: IMessageData) => {
            let decryptMessage: any = item.message;
            try {
                decryptMessage = await onDecryptMessage(item, privateKey);
            } catch (error) {
                console.error(error);
            }
            if (item.type === 'media') {
                await handleMediaMessages(decryptMessage, item._id, false);
            }
            return { ...item, ...decryptMessage };
        }),
    );
    store.dispatch(
        wsActions.messageInRange({ state: true, data: decryptedList }),
    );
    store.dispatch(
        changeMessagesList({
            messages: decryptedList,
            channel_id: channel_id,
        }),
    );
};

export const deleteMessageHandler = (data: {
    message_id: string;
    channel_id: string;
}) => {
    store.dispatch(closeThreadAction());
    store.dispatch(
        deleteMessage({
            channel_id: data.channel_id,
            message_id: data.message_id,
        }),
    );
};

export const handleMessageCreateSeen = (
    messageItem: any,
    lastSeenUsers: any,
) => {
    let users = lastSeenUsers?.map(val => {
        if (messageItem?.seen_users?.includes(val?.user_id)) {
            return {
                ...val,
                message_timestamp: messageItem?.created_at,
            };
        } else {
            return val;
        }
    });
    store.dispatch(addLastSeenMessage(users));
};
