import NoteSidebarHeader from './NoteSidebarHeader';
import classes from './NotesSidebar.module.css';
import SidebarList from './SidebarList';

const NotesSidebar: React.FC<{}> = () => {
    return (
        <div className={`${classes['wrap']}`}>
            <NoteSidebarHeader />
            <SidebarList />
        </div>
    );
};
export default NotesSidebar;
