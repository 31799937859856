import { Trans, t } from '@lingui/macro';
import { useState } from 'react';
import Select from 'react-select';

import { AppButton, Modal } from '@/UI';
import { ReactComponent as KeyIcon } from '@/assets/icons/key-2.svg';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { userPreferencesActions } from '@/store/user-preferences-slice';

import classes from './UploadMessagesKey.module.css';
import { selectStyles } from './selectStyles';

type Props = {
    onClose: () => void;
};

const UploadMessagesKey: React.FC<Props> = props => {
    const { onClose } = props;
    const dispatch = useAppDispatch();

    const [selectedOption, setSelectedOption] = useState(null);

    const cryptoKeys = useAppSelector(state => state.cryptoKeyReducer);
    const { _persist, ...filteredObj } = cryptoKeys;

    const preferenceKey = useAppSelector(
        state => state.userPreferencesReducer.msg_key_hash,
    );

    const saveCryptoKey = () => {
        if (selectedOption) {
            dispatch(
                userPreferencesActions.updateMsgKeyHash(selectedOption.value),
            );
            onClose();
        }
    };

    let options = Object.keys(filteredObj).map(item => {
        return { value: item, label: item };
    });
    options.unshift({ value: null, label: 'Default Server Key' });

    return (
        <Modal
            className={classes.modal}
            header={t`Choose encryption key`}
            headerIcon={<KeyIcon width={20} stroke={'var(--keep-white)'} />}
            onClose={onClose}>
            <div className={classes['wrap']}>
                <p>
                    <Trans>
                        This key will be used to encrypt and decrypt the
                        messages in this server
                    </Trans>
                    .
                </p>
                <Select
                    isSearchable
                    autoFocus
                    styles={selectStyles}
                    placeholder={
                        preferenceKey ? preferenceKey : t`Select key hash`
                    }
                    className={classes['select']}
                    defaultValue={
                        preferenceKey ? preferenceKey : selectedOption
                    }
                    onChange={setSelectedOption}
                    options={options}
                    classNamePrefix={classes['react-select']}
                />

                <AppButton
                    onClick={saveCryptoKey}
                    title={t`Save`}
                    type="button"
                    classStyles={classes['btn']}
                />
            </div>
        </Modal>
    );
};
export default UploadMessagesKey;
