import toggleArrowIcon from '@/assets/icons/arrow-down.svg';

import classes from './MediaViewer.module.css';

const MediaViewNextArrow = props => {
    const { className, onClick, style } = props;
    return (
        <img
            alt=""
            onClick={onClick}
            className={className + ' ' + classes['next-arrow']}
            style={style}
            src={toggleArrowIcon}
        />
    );
};

export default MediaViewNextArrow;
