import { Trans } from '@lingui/macro';
import { useContext } from 'react';

import { SearchContext } from './SearchContext';

const SearchResultTitle: React.FC<{}> = () => {
    const context = useContext(SearchContext);
    const { searchText, searchResult } = context;

    return (
        <>
            {searchResult?.length === 0 && searchText.length > 0 && (
                <h2 style={{ color: 'var(--navy-1)' }}>
                    <Trans>No Result Found</Trans>
                </h2>
            )}
            {searchResult?.length > 0 && (
                <h2
                    style={{
                        marginLeft: '3px',
                        color: 'var(--navy-1)',
                    }}>
                    <Trans>Results Found</Trans>: ({searchResult?.length})
                </h2>
            )}
        </>
    );
};
export default SearchResultTitle;
