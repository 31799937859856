import { t } from '@lingui/macro';
import { useContext } from 'react';

import { TextInput } from '@/UI';
import calender from '@/assets/icons/calendar.svg';

import classes from './Statistics.module.css';
import { StatisticsContext } from './StatisticsContext';

interface IProps {}

const SearchRow: React.FC<IProps> = () => {
    const { startDate, onChangeStartDate, onChangeEndDate, endDate } =
        useContext(StatisticsContext);

    return (
        <div className={classes['date-filter-row']}>
            <TextInput
                icon={calender}
                containerStyle={{ position: 'relative' }}
                type="date"
                wrapClassName={classes['date-input-wrap']}
                value={startDate}
                onChange={e => onChangeStartDate(e)}
                placeHolder={t`First Date`}
            />
            <span style={{ color: 'var(--text-heading)' }}>
                &nbsp; - &nbsp;
            </span>
            <TextInput
                icon={calender}
                type="date"
                value={endDate}
                onChange={e => onChangeEndDate(e)}
                placeHolder={t`End Date`}
                wrapClassName={classes['date-input-wrap']}
            />
        </div>
    );
};
export default SearchRow;
