import { FC, useContext } from 'react';

import { ReactComponent as AddIcon } from '@/assets/icons/add-square-2.svg';
import { NotesContext } from '@/context/notes/NotesContext';

import classes from './NotesSidebar.module.css';

const NoteSidebarHeader: FC = () => {
    const notesContext = useContext(NotesContext);
    const { setIsAddNewGroup, isFullMode } = notesContext;

    const addHandler = () => {
        setIsAddNewGroup(true);
    };

    return (
        <div
            style={{ display: isFullMode ? 'none' : 'flex' }}
            className={`${classes['header']}`}>
            CovChat
            <span className={classes['icons-wrap']}>
                <AddIcon onClick={addHandler} className={`cursor-pointer`} />
            </span>
        </div>
    );
};

export default NoteSidebarHeader;
