import sanitizeHtml from 'sanitize-html';

import { SESSION_STORAGE } from '@/constants/enum';
import SOCKET_ACTIONS from '@/constants/socketActions';
import store from '@/store/index';
import { socketConnectionAcrtions } from '@/store/socket-connection-reducer';
import { setSearchLoading } from '@/store/util-reducer';

import { checkUserInteraction } from './checkUserInteraction';
import { convertJwkToBase64, decryptPassword } from './cryptoUtils';
import { getChannelList_activeServer } from './serverDataHandlers';

let socket: WebSocket | null = null;
let search_socket: WebSocket | null = null;
let search_socket_2: WebSocket | null = null;
let notification_socket: WebSocket | null = null;
let notification_desktop_socket: WebSocket | null = null;
let reconnectInterval: number | null | any = null; // To store the reconnection interval ID
let userToken: string | null = null; // Keep track of the latest userToken
let subserver_id: string | null = null; // Keep track of the latest subserver_id

let eventHandler = events => {};
let searchEventHandler = events => {};
let searchFindEventHandler = events => {};

let notificationEventHandler = events => {};
let desktopNotificationHandler = events => {};

export const setEventHandler = eventHandlers => {
    eventHandler = events => eventHandlers(events);
};
export const setNotificationsEventHandler = eventHandlers => {
    notificationEventHandler = events => eventHandlers(events);
};

export const setDesktopNotificationHandler = eventHandlers => {
    desktopNotificationHandler = events => eventHandlers(events);
};
export const setSearchSocketHandler = eventHandlers => {
    searchEventHandler = events => eventHandlers(events);
};
export const setSearchFindEventHandler = eventHandlers => {
    searchFindEventHandler = events => eventHandlers(events);
};

export const sendFindSearchData = (data: any) => {
    if (search_socket_2 && search_socket_2?.readyState === 1) {
        search_socket_2.send(data);

        return {
            socketSentData: data,
        };
    }
};

export const sendSearchData = (data: any) => {
    if (search_socket && search_socket?.readyState === 1) {
        search_socket.send(data);
        return {
            socketSentData: data,
        };
    }
};

export const connectFindSearchSocket = (privateKey, publicKey) => {
    if (!search_socket_2) {
        search_socket_2 = new WebSocket(process.env.REACT_APP_TEST);
        search_socket_2.onopen = async () => {};
        search_socket_2.onmessage = event => {
            searchFindEventHandler(event);
        };

        search_socket_2.onerror = error => {
            console.error(error);
        };

        search_socket_2.onclose = event => {
            store.dispatch(
                socketConnectionAcrtions.updateFindSearchSocket(false),
            );

            if (event.code === 1000) {
                console.log('Close by user');
                return;
            } else {
                search_socket_2 = null;
                setTimeout(() => {
                    connectFindSearchSocket(privateKey, publicKey);
                }, 1000);
            }
        };
    }
};

export const connectSearchSocket = (privateKey, publicKey) => {
    if (search_socket && search_socket?.readyState === WebSocket.OPEN) {
        search_socket.close();
        search_socket = null;
    }
    if (!search_socket) {
        let error = false;
        search_socket = new WebSocket(process.env.REACT_APP_TEST);
        search_socket.onopen = async () => {
            store.dispatch(socketConnectionAcrtions.updateSearchSocket(true));

            const getPrivatekey = decryptPassword(
                privateKey,
                sessionStorage.getItem(SESSION_STORAGE.devicePassword),
            );

            const getPublickey = decryptPassword(
                publicKey,
                sessionStorage.getItem(SESSION_STORAGE.devicePassword),
            );

            const jwk = convertJwkToBase64({
                publicKey: getPublickey,
                privateKey: getPrivatekey,
            });

            const RSAKeysPayload = {
                event: 'setRSAKeys',
                ...jwk,
            };

            search_socket?.send(JSON.stringify(RSAKeysPayload));
        };
        search_socket.onmessage = event => {
            searchEventHandler(event);
        };

        search_socket.onerror = error => {
            console.error(error);
        };

        search_socket.onclose = () => {
            store.dispatch(socketConnectionAcrtions.updateSearchSocket(false));
            search_socket = null;
            setTimeout(() => {
                connectSearchSocket(privateKey, publicKey);
            }, 1000);
        };
    }
};

//
export const connectNotificationSocketDesktop = () => {
    if (!notification_desktop_socket) {
        notification_desktop_socket = new WebSocket(
            process.env.REACT_APP_WEBSOCKET_NOTIFICATIN_DESKTOP,
        );
        notification_desktop_socket.onopen = () => {
            store.dispatch(
                socketConnectionAcrtions.updateElectronConnectionState(true),
            );
        };
        notification_desktop_socket.onmessage = event => {
            desktopNotificationHandler(event);
        };
        notification_desktop_socket.onclose = () => {
            store.dispatch(
                socketConnectionAcrtions.updateElectronConnectionState(false),
            );
            notification_desktop_socket = null;
            setTimeout(() => {
                connectNotificationSocketDesktop();
            }, 1000);
        };
    }
};

export const sendDesktopNotificationSocketData = (
    data: any,
    serverData,
    user,
) => {
    let channelName;
    let channelList;
    if (data?.thread_id !== undefined || !data?.thread_id) {
        channelList = getChannelList_activeServer(data.subserver_id) ?? {};
        channelName = channelList[data?.channel_id];
    }
    if (
        notification_desktop_socket &&
        notification_desktop_socket?.readyState === 1
    ) {
        let message = sanitizeHtml(
            data?.message === 'object' ? data?.message?.content : data?.message,
            {
                allowedTags: false,
            },
        );
        const doc = new DOMParser().parseFromString(message, 'text/html');
        const textContent = doc.body.textContent || '';
        message = textContent;

        if (
            data.type === 'media' ||
            (data.type === 'forward' && data.forwardedMessage.type === 'media')
        ) {
            message = `${data.message} -> media file`;
        }
        const newFormat = {
            Id: data._id,
            Type: 'notification',
            Title: `${serverData?.name} > #${
                data?.thread_id ? channelName?.name : data.channel_name
            }${data?.thread_id ? '> [THREAD]' : ''}`,
            Icon: serverData?.logo
                ? serverData.logo
                : 'https://cdn2.iconfinder.com/data/images/social-media-2304/64/12-reddit-512.png',
            Message: `${user.name}: ${message}`,
            // isThread: data?.thread_id ? true : false,
        };
        notification_desktop_socket.send(JSON.stringify(newFormat));
    }
    return {
        socketSentData: data,
    };
};

export const connectNotificationSocket = (userToken: string, url: string) => {
    const user = userToken;
    if (!notification_socket) {
        notification_socket = new WebSocket(url);

        notification_socket.onopen = () => {
            store.dispatch(
                socketConnectionAcrtions.updateNotificationConnectionState(
                    true,
                ),
            );
            notification_socket?.send(
                JSON.stringify({
                    command: 'subscribe',
                    data: user,
                }),
            );
        };

        notification_socket.onmessage = event => {
            notificationEventHandler(event);
        };

        notification_socket.onclose = () => {
            store.dispatch(
                socketConnectionAcrtions.updateNotificationConnectionState(
                    false,
                ),
            );
            notification_socket = null;
            setTimeout(() => {
                connectNotificationSocket(user, url);
            }, 1000);
        };
    }
};

// Function to connect to the WebSocket server
export const connectWebSocket = (
    newUserToken?: string,
    newSubserver_id?: string,
    socketServerUrl?: string,
    key_hash?: string,
): void => {
    if (socket && socket?.readyState === WebSocket.OPEN) {
        socket.close();
        socket = null;
        store.dispatch(socketConnectionAcrtions.updateConnectionState(false));
    }

    if (!socket || socket?.readyState === WebSocket.CLOSED) {
        // Only create a new WebSocket if there is no existing connection or if it's closed
        socket = new WebSocket(socketServerUrl);

        socket.onopen = () => {
            store.dispatch(
                socketConnectionAcrtions.updateConnectionState(true),
            );
            // socketConnectionState = true;
            let payload = {
                action: SOCKET_ACTIONS.auth.action,
                data: {
                    user_token: newUserToken,
                    subserver_id: newSubserver_id,
                    key_hash: key_hash,
                },
            };
            socket?.send(JSON.stringify(payload));
        };

        socket.onmessage = event => {
            eventHandler(event);
            // Handle incoming messages here
        };
        socket.onerror = event => {
            store.dispatch(
                socketConnectionAcrtions.updateConnectionState(false),
            );
        };
        socket.onclose = event => {
            if (event.code === 1000) {
                // Code 1000 indicates a normal closure (explicitly closed)
                console.log('WebSocket connection closed by user.');
                return;
            } else {
                console.log(
                    'WebSocket connection dropped and is restarting.',
                    event,
                );
                store.dispatch(
                    socketConnectionAcrtions.updateConnectionState(false),
                );
                reconnectInterval = setTimeout(() => {
                    if (socket?.readyState === WebSocket.CLOSED) {
                        restartWebSocket(
                            newUserToken,
                            newSubserver_id,
                            socketServerUrl,
                            key_hash,
                        );
                    }
                }, 500);
            }
        };
    } else if (socket?.readyState === WebSocket.CONNECTING) {
    } else {
        socket.close();
        socket = null;
    }
};

// Function to clear the reconnection interval
const clearReconnectInterval = () => {
    if (reconnectInterval) {
        clearInterval(reconnectInterval);
        reconnectInterval = null;
    }
};

// Function to close the WebSocket connection
export const closeWebSocket = () => {
    if (socket) {
        socket.close(1000, 'Connection closed by user.');
        socket = null;
    } else {
        console.log('socket not there -> nothing to close ');
    }
};

export const restartWebSocket = (
    newUserToken,
    newSubserver_id,
    socketServerUrl,
    key_hash,
): void => {
    if (socket) {
        socket = null;
        const token = userToken ?? newUserToken;
        const server = subserver_id ?? newSubserver_id;
        connectWebSocket(token, server, socketServerUrl, key_hash);
        // socket.close();
    }
};

// Function to send data to the WebSocket server
export const sendWebSocketData = (data: any) => {
    let socketData = JSON.parse(data).action;
    if (socketData === 'search') {
        store.dispatch(setSearchLoading(true));
    }
    if (socket && socket?.readyState === WebSocket.OPEN) {
        socket.send(data);
    } else {
        console.log(data, socket, socket?.readyState === WebSocket.OPEN);
    }
    return {
        socketSentData: data,
    };
};

// Function to check if the WebSocket connection is open
export const isWebSocketConnected = (): boolean => {
    // socketConnectionState = true;
    return socket && socket?.readyState === WebSocket.OPEN;
};
