import { useCallback, useEffect, useState } from 'react';

import {
    getChannelMember,
    handleAddRoleInChannel,
    inviteUserToChannel,
} from '@/Utils/socket-actions/ChannelActions/channelActions';
import { sortByKey } from '@/Utils/sortByKey';
import { useAppSelector } from '@/hooks/useRedux';
import {
    memberDataSelector,
    rolesListSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import { InviteFriendsTypes } from './InviteFriends';

export const useInviteFriends = (props: InviteFriendsTypes) => {
    const { channelId, channel, sectionChannels } = props;

    const [membersInCh, setMembersInCh] = useState([]);
    const [rolesInCh, setRolesInCh] = useState([]);
    const [searchMembers, setSearchMembers] = useState([]);
    const [searchRoles, setSearchRoles] = useState([]);
    const [inviteMembers, setInviteMembers] = useState([]);
    const [inviteRoles, setInviteRoles] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [searchInputValue, setSearchInputValue] = useState('');
    const [selectedTab, setSelectedTab] = useState<'users' | 'roles'>('users');

    const members = useAppSelector(state => memberDataSelector(state));
    const rolesList = useAppSelector(rolesListSelector);
    const sortByPriority = sortByKey(rolesList, 'priority');

    const handleTabClick = (tab: 'users' | 'roles') => {
        setSelectedTab(tab);
    };

    const channelMembersState = useAppSelector(
        state =>
            state.serverDataReducer.channelList[
                state.serverDataReducer.activeServer
            ][channelId]?.memberList,
    );

    const sendInvitationHandler = (id: string) => {
        if (selectedTab === 'users') {
            setInviteMembers(prevInviteMembers => [...prevInviteMembers, id]);
            setMembersInCh(prevMembersInCh =>
                prevMembersInCh.filter(member => member.id !== id),
            );
        }

        if (selectedTab === 'roles') {
            setInviteRoles(prevInviteRoles => [...prevInviteRoles, id]);
            setRolesInCh(prevRole => prevRole.filter(role => role.id !== id));
        }

        // Remove the member from membersInCh
        if (selectedTab === 'users') {
            setSearchMembers(prevMembersInCh =>
                prevMembersInCh.filter(member => member.id !== id),
            );
        }
        if (selectedTab === 'roles') {
            setSearchRoles(prevRoleInCh =>
                prevRoleInCh.filter(role => role.id !== id),
            );
        }
    };

    const getUsersInCh = useCallback(async () => {
        if (sectionChannels?.length > 0) {
            // sectionChannels.map(id => getChannelMember(id));
            // we use all member for sections
            return;
        }
        getChannelMember(channelId);
    }, [sectionChannels, channelId]);

    const handleMembersInCh = useCallback(() => {
        if (sectionChannels && sectionChannels.length > 0) {
            setMembersInCh(members);
            setSearchMembers(members);
            return;
        }

        let filteredMembers = [];
        if (channelMembersState?.length > 0) {
            members?.forEach((element, key) => {
                const member = channelMembersState?.find(e => e === element.id);
                if (!member) {
                    filteredMembers.push(members[key]);
                }
            });
            setMembersInCh(filteredMembers);
            setSearchMembers(filteredMembers);
        }
    }, [channelMembersState, sectionChannels, members]);

    const handleRolesInCh = useCallback(() => {
        if (sectionChannels?.length > 0) {
            setRolesInCh(sortByPriority);
            setSearchRoles(sortByPriority);
            return;
        }

        let filteredRoles = [];
        if (sortByPriority?.length > 0) {
            sortByPriority?.forEach((element, key) => {
                const role = channel?.roleList?.find(e => e === element.id);

                if (!role) {
                    filteredRoles.push(element);
                }
            });
            setRolesInCh(filteredRoles);
            setSearchRoles(filteredRoles);
        }
    }, [channel?.roleList, sectionChannels?.length, sortByPriority]);

    const onChangeSearchInput = text => {
        setSearchInputValue(text);
    };

    const handleSearch = (keyword: string) => {
        const word = keyword?.toLowerCase();

        let searchData = [];
        if (selectedTab === 'users') {
            searchData = membersInCh?.filter(item =>
                item?.name?.toLowerCase().includes(word),
            );
            setSearchMembers(searchData);
        }
        if (selectedTab === 'roles') {
            searchData = rolesInCh?.filter(item =>
                item?.name?.toLowerCase().includes(word),
            );
            setSearchRoles(searchData);
        }
    };

    const handleInviteSave = ({
        inviteMembers,
        inviteRoles,
        channelId,
        sectionChannels,
    }) => {
        if (inviteMembers?.length > 0) {
            if (sectionChannels?.length > 0) {
                sectionChannels.map(item =>
                    inviteUserToChannel(inviteMembers, item),
                );
                return;
            }
            inviteUserToChannel(inviteMembers, channelId);
        }
        if (inviteRoles?.length > 0) {
            if (sectionChannels?.length > 0) {
                sectionChannels?.map(id =>
                    inviteRoles?.map(item => handleAddRoleInChannel(item, id)),
                );
                return;
            }
            inviteRoles?.map(item => handleAddRoleInChannel(item, channelId));
        }

        setInviteMembers([]);
        setInviteRoles([]);
        setSelectedId('');
    };

    useEffect(() => {
        handleMembersInCh();
    }, [members, channelMembersState]);

    useEffect(() => {
        handleRolesInCh();
    }, [channel?.roleList, rolesList]);

    useEffect(() => {
        // handleInviteSave(); in ListItem add button
        getUsersInCh();
    }, [selectedTab]);

    useEffect(() => {
        handleSearch(searchInputValue);
    }, [searchInputValue, membersInCh, rolesList]);

    return {
        handleInviteSave,
        handleSearch,
        selectedTab,
        handleTabClick,
        sectionChannels,
        membersInCh,
        selectedId,
        rolesInCh,
        searchMembers,
        channel,
        setSelectedId,
        searchRoles,
        sendInvitationHandler,
        searchInputValue,
        onChangeSearchInput,
    };
};
