import { memo } from 'react';

import classes from './SelectedMediaDisplay.module.css';

type Props = {
    onClick: () => void;
    filesLength: number;
};
const LoadMore: React.FC<Props> = props => {
    const { onClick, filesLength } = props;

    return (
        <div
            onClick={e => {
                e.stopPropagation();
                onClick();
            }}
            className={classes['overlay']}>
            <span>+{filesLength - 2}</span>
        </div>
    );
};
export default memo(LoadMore);
