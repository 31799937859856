import React from 'react';
import { shallowEqual } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { SESSION_STORAGE } from '@/constants/enum';
import { useAppSelector } from '@/hooks/useRedux';
import { ROOT_PATH } from '@/routes/router.types';
import {
    privateKeyArray,
    serverDataSelector,
} from '@/store/server-data-reducer/selectors-server-data';

type Props = {};

const AuthPage = (props: Props) => {
    const user = useAppSelector(state => state.userReducer.user);
    const isDevicePassword = sessionStorage.getItem(
        SESSION_STORAGE.devicePassword,
    );

    if (user.token === undefined) {
        sessionStorage.clear();
        return <Navigate to={ROOT_PATH.login} />;
    }
    // else if (isDevicePassword === undefined)
    //     return <Navigate to={ROOT_PATH.devicePassword} />;
    return <Navigate to={'/'} />;
};

export default AuthPage;
