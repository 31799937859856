import { FC, ReactNode } from 'react';

import classes from './Card.module.css';

interface IProps {
    onDrop?: () => void;
    onDragOver?: () => void;
    className?: string;
    children: ReactNode;
}
const Card: FC<IProps> = props => {
    
    const {
        onDrop = () => {},
        onDragOver = () => {},
        className = '',
        children,
    } = props;

    return (
        <div
            onDrop={onDrop}
            onDragOver={onDragOver}
            className={`${classes['card-container']} ${className}`}>
            {children}
        </div>
    );

};
export default Card;
