import { AnimatePresence } from 'framer-motion';
import { useContext } from 'react';

import { NotesContext } from '@/context/notes/NotesContext';

import classes from './NoteDetails.module.css';
import NoteDetailsContent from './NoteDetailsContent';
import NoteDetailsHeader from './NoteDetailsHeader';

const NoteDetails: React.FC<{}> = () => {
    const context = useContext(NotesContext);
    const { activeNote } = context;

    return (
        <div className={`${classes['details-container']}`}>
            <NoteDetailsHeader />
            <AnimatePresence>
                {activeNote && <NoteDetailsContent />}
            </AnimatePresence>
        </div>
    );
};
export default NoteDetails;
