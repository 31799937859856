import React from 'react';
import { Navigate } from 'react-router-dom';

import DevicePassword from '@/components/DevicePassword/DevicePassword';
import { useAppSelector } from '@/hooks/useRedux';
import { ROOT_PATH } from '@/routes/router.types';

type Props = {};

const DevicePasswordPage = (props: Props) => {
    const user = useAppSelector(state => state.userReducer.user);
    if (user.token === undefined) {
        return <Navigate to={ROOT_PATH.login} />;
    }

    return <DevicePassword />;
};

export default DevicePasswordPage;
