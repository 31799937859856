import { t, Trans } from '@lingui/macro';
import React, { useEffect, useRef, useState } from 'react';

import arrow from '@/assets/icons/arrow-down.svg';

import classes from './serverPagination.module.css';

const ServerPagination = ({ metaData, setPageIndex }) => {
    const total_pages = metaData?.total_pages;
    const current_page_number = metaData?.current_page_number;
    const [goPageNumber, setGoPageNumber] = useState(null);
    const pageNotFound = goPageNumber > total_pages;
    const forwardPressTimeout = useRef(null);
    const backPressTimeout = useRef(null);

    const incrementPage = () => {
        setPageIndex(prevPageIndex => Math.min(prevPageIndex + 1, total_pages));
    };

    const decrementPage = () => {
        setPageIndex(prevPageIndex => Math.max(prevPageIndex - 1, 1));
    };

    // LONG PRESS
    const handleForwardMouseDown = () => {
        incrementPage();
        forwardPressTimeout.current = setInterval(incrementPage, 200);
    };

    const handleBackMouseDown = () => {
        decrementPage();
        backPressTimeout.current = setInterval(decrementPage, 200);
    };

    const handleMouseUp = () => {
        clearInterval(forwardPressTimeout.current);
        clearInterval(backPressTimeout.current);
    };

    useEffect(() => {
        return () => {
            clearInterval(forwardPressTimeout.current);
            clearInterval(backPressTimeout.current);
        };
    }, []);

    useEffect(() => {
        const handleDocumentMouseUp = () => {
            clearInterval(forwardPressTimeout.current);
            clearInterval(backPressTimeout.current);
        };

        document.addEventListener('mouseup', handleDocumentMouseUp);

        return () => {
            document.removeEventListener('mouseup', handleDocumentMouseUp);
        };
    }, []);
    // LONG PRESS

    const onChangeGoPageInput = e => {
        const enteredValue = e.target.value;
        const onlyNumbersRegex = /^[0-9\b]+$/;

        if (onlyNumbersRegex.test(enteredValue)) {
            setGoPageNumber(enteredValue);
            setPageIndex(Number(enteredValue));
        } else if (enteredValue === '') {
            setGoPageNumber(enteredValue);
        }
    };

    const generatePageNumbers = () => {
        const numbers = [];
        const selectedPage = current_page_number;

        // Display a range of page numbers only if there is more than one page
        if (total_pages > 1) {
            // Always show 2 page numbers if total pages are 2
            if (total_pages === 2) {
                numbers.push(1, 2);
            } else {
                // Always show 3 page numbers
                for (let i = selectedPage - 1; i <= selectedPage + 1; i++) {
                    if (i > 0 && i <= total_pages) {
                        numbers.push(i);
                    }
                }

                // If less than 3 numbers are generated, adjust the range
                while (
                    numbers.length < 3 &&
                    selectedPage - numbers[0] >
                        numbers[numbers.length - 1] - selectedPage
                ) {
                    numbers.unshift(numbers[0] - 1);
                }

                while (
                    numbers.length < 3 &&
                    selectedPage - numbers[0] <=
                        numbers[numbers.length - 1] - selectedPage
                ) {
                    numbers.push(numbers[numbers.length - 1] + 1);
                }
            }
        } else {
            // If there is only one page, just display the current page number
            numbers.push(selectedPage);
        }

        return numbers;
    };

    return (
        <div className={classes['main-container']}>
            <div className={classes['pagination-container']}>
                <div
                    onMouseDown={() => handleBackMouseDown()}
                    onMouseUp={() => handleMouseUp()}
                    className={classes['pagination-arrow-container']}>
                    <img
                        style={{
                            transform: 'rotate(90deg)',
                        }}
                        src={arrow}
                        alt="Arrow"
                    />
                </div>
                <div className={classes['pagination-number-area']}>
                    {generatePageNumbers()?.map(pageNumber => (
                        <div
                            key={pageNumber}
                            onClick={() => setPageIndex(pageNumber)}
                            className={classes['pagination-number-container']}
                            style={{
                                fontWeight:
                                    current_page_number === pageNumber &&
                                    'bold',
                                opacity:
                                    current_page_number === pageNumber && 1,
                                backgroundColor:
                                    current_page_number === pageNumber &&
                                    'var(--chat-background)',
                            }}>
                            {pageNumber}
                        </div>
                    ))}
                </div>
                <div
                    onMouseDown={() => handleForwardMouseDown()}
                    onMouseUp={() => handleMouseUp()}
                    className={classes['pagination-arrow-container']}>
                    <img
                        style={{
                            transform: 'rotate(-90deg)',
                        }}
                        src={arrow}
                        alt="Arrow"
                    />
                </div>
            </div>
            {/* GO PAGE */}
            <div className={classes['page-input-area']}>
                <input
                    className={classes['go-page-input']}
                    onChange={e => onChangeGoPageInput(e)}
                    value={goPageNumber}
                    placeholder={t`Page`}
                    style={{
                        color: pageNotFound && 'red',
                        opacity: pageNotFound && 0.7,
                    }}
                />
                {pageNotFound && (
                    <span className={classes['no-page-label']}>
                        <Trans>Page Not Found</Trans>!
                    </span>
                )}
            </div>
        </div>
    );
};

export default ServerPagination;
