import { Trans, msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Link } from 'react-router-dom';

import { AppButton, ConfirmationModal, TextInput, Version } from '@/UI';
import SmsIcon from '@/assets/icons/SmsIcon';
import { ReactComponent as PasswordIcon } from '@/assets/icons/password.svg';
import logo from '@/assets/images/login_logo.png';
import { ROOT_PATH } from '@/routes/router.types';

import classes from './Login.module.css';
import LoginLanguageSwitch from './LoginLanguageSwitch';
import useLogin from './useLogin';

const Login = () => {
    const {
        errorState,
        onEmailChange,
        onPasswordChange,
        handleLogin,
        ModalProps,
        modalVisible,
        onCancelModal,
        value,
        loader,
    } = useLogin();

    const pressEnterHandler = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleLogin();
        }
    };

    const { _ } = useLingui();
    return (
        <div
            style={{ height: 'var(--height)' }}
            className="justifyCenter d-flex"
            onKeyDown={pressEnterHandler}>
            <div className="centerContainer">
                <div className={classes['contentWrapper']}>
                    <div className={classes['header-img']}>
                        <img
                            className={classes['main-img']}
                            src={logo}
                            alt="CovChat"
                        />
                    </div>
                    <div className={classes['headingWrapper']}>
                        <h1 className={classes['primaryHeading']}>
                            <Trans>Welcome Covchat</Trans>
                        </h1>
                        <span
                            className={
                                'subHeading margin' +
                                ' ' +
                                classes['sub-heading']
                            }>
                            <Trans>
                                Fill in the required information to login
                            </Trans>
                        </span>
                    </div>
                    <div className={classes['inputWrapper']}>
                        <TextInput
                            name="email"
                            placeHolder={_(msg`Enter Your Email or Username`)}
                            label={_(msg`Email or Username`)}
                            onChange={onEmailChange}
                            value={value.email}
                            error={errorState.emailError}
                            icon={<SmsIcon fill={'var(--text-heading)'} />}
                        />
                        <TextInput
                            type="password"
                            name="pwd"
                            label={_(msg`Password`)}
                            placeHolder={_(msg`Enter Your Password`)}
                            value={value.password}
                            onChange={onPasswordChange}
                            error={errorState.passwordError}
                            icon={<PasswordIcon />}
                        />
                        {/* ----- DON'T REMOVE */}
                        {/* <Link
                            to={ROOT_PATH.resetPassword}
                            onClick={() => {}}
                            className={classes['resetPass']}>
                            <Trans>Reset Password</Trans>
                        </Link> */}
                    </div>
                    <AppButton
                        title={<Trans>Login</Trans>}
                        onClick={handleLogin}
                        loader={loader}
                        disable={loader}
                    />
                    <Version />
                    <div>
                        <LoginLanguageSwitch />
                    </div>
                </div>
            </div>
            <ConfirmationModal
                visible={modalVisible}
                onCancel={onCancelModal}
                {...ModalProps}
            />
        </div>
    );
};
export default Login;
