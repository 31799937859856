import { t } from '@lingui/macro';

import htmlTag from '@/assets/icons/html-tag.svg';
import key from '@/assets/icons/keyIcon.svg';
import status from '@/assets/icons/status.svg';

import ExperimentalMode from './Experimental-mode/ExperimentalMode';
import KeysManagement from './keys-management/KeysManagement';
import ProfileSettings from './profile-settings/ProfileSettings';

export const componentMap = {
    profileSettings: {
        name: t`Profile Settings`,
        icon: status,
        component: ProfileSettings,
    },
    keysManagement: {
        name: t`Keys Management`,
        icon: key,
        component: KeysManagement,
    },
    experimentalMode: {
        name: t`Experimental Mode`,
        icon: htmlTag,
        component: ExperimentalMode,
    },
};

