import { SESSION_STORAGE } from '@/constants/enum';

import { RSA_CONSTANTS, encryptPassword } from './cryptoUtils';

export const handleFileInputChange = onChangeCallback => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';

    fileInput.onchange = (event: any) => {
        const file = event.target?.files?.[0];
        const reader = new FileReader();
        reader.onload = async event => {
            const fileContent = event.target.result as string;
            onChangeCallback(file, fileContent);
        };
        reader.readAsText(file);
    };

    fileInput.click();
};

export const handleEncryptKeyWithPassword = async (
    privateKey_Complete,
    publicKey_Complete,
) => {
    const devicePassword = sessionStorage.getItem(
        SESSION_STORAGE.devicePassword,
    );
    const encrpytedPrivateKey = await encryptPassword(
        {
            ...RSA_CONSTANTS('decrypt'),
            ...privateKey_Complete,
        },
        devicePassword,
    );
    const encrpytedPublicKey = await encryptPassword(
        {
            ...RSA_CONSTANTS('encrypt'),
            ...publicKey_Complete,
        },
        devicePassword,
    );

    return { encrpytedPrivateKey, encrpytedPublicKey };
};
