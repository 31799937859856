import React, {
    ReactNode,
    createContext,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import sanitizeHtml from 'sanitize-html';

import { encryptNote } from '@/Utils/notesHelper';
import { editNoteApi, getNotesList } from '@/api/notes/noteApis';
import { getNoteCategories } from '@/api/notes/noteCategoriesApis';
import { useAppSelector } from '@/hooks/useRedux';
import {
    IFetchNotesResponse,
    INote,
    INoteCategory,
    INoteContextProps,
    INoteSubmitConfig,
    ISaveNote,
} from '@/types/notesTypes';

import { initialContextValues } from './initialContextValues';

interface PropsTypes {
    children?: ReactNode;
}

export const NotesContext =
    createContext<INoteContextProps>(initialContextValues);

export const NotesProvider: React.FC<PropsTypes> = ({ children }) => {
    const saveNoteTimeoutRef = useRef(null);

    const [tabs, setTabs] = useState(initialContextValues.tabs);
    const [isFullMode, setIsFullMode] = useState(
        initialContextValues.isFullMode,
    );
    const [body, setBody] = useState(null);
    const [isEditorChanges, setIsEditorChanges] = useState(
        initialContextValues.isEditorChanges,
    );
    const [selectedNotes, setSelectedNotes] = useState<string[]>([]);

    const [isAddNewGroup, setIsAddNewGroup] = useState(
        initialContextValues.isAddNewGroup,
    );
    const [isEditCategory, setIsEditCategory] = useState(
        initialContextValues.isEditCategory,
    );
    const [isEditNoteModal, setIsEditNoteModal] = useState(
        initialContextValues.isEditNoteModal,
    );

    const [isDeleteCategory, setIsDeleteCategory] = useState(
        initialContextValues.isDeleteCategory,
    );
    const [isAddNewNote, setIsAddNewNote] = useState(
        initialContextValues.isAddNewNote,
    );

    const [filteredNotes, setFilteredNotes] = useState([]);
    const [activeNote, setActiveNote] = useState(
        initialContextValues.activeNote,
    );
    const [activeMobileStep, setActiveMobileStep] = useState(
        initialContextValues.activeMobileStep,
    );
    const [activeCategory, setActiveCategory] = useState(
        initialContextValues.activeCategory,
    );

    const [isForwardModal, setIsForwardModal] = useState(
        initialContextValues.isForwardModal,
    );
    const [isDeleteModal, setIsDeleteModal] = useState(
        initialContextValues.isDeleteModal,
    );

    const [isColorPickModal, setIsColorPickModal] = useState(
        initialContextValues.isColorPickModal,
    );

    const [isFilterModalOpen, setIsFilterModalOpen] = useState(
        initialContextValues.isFilterModalOpen,
    );
    const [filterType, setFilterType] = useState(
        initialContextValues.filterType,
    );

    const [searchValue, setSearchValue] = useState(
        initialContextValues.searchValue,
    );

    const [noteCat, setNoteCat] = useState<INoteCategory[]>([]);
    const [isFetchingCat, setIsFetchingCat] = useState<boolean>(
        initialContextValues.isFetchingCat,
    );
    const [errorFetchCat, setErrorFetchCat] = useState<boolean>(
        initialContextValues.isFetchingCat,
    );
    const [isFetchingNotes, setIsFetchingNotes] = useState(
        initialContextValues.isFetchingNotes,
    );
    const [errorFetchNotes, setErrorFetchNotes] = useState(null);
    const [notes, setNotes] = useState<INote[]>([]);
    const [decryptedNotesBody, setDecryptedNotesBody] = useState<{
        [id: string]: string;
    }>({});

    const activeNoteId = activeNote?.id;

    const subserverId = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const changeNoteBodyHandler = (event: any, editor: any) => {
        setBody(editor?.getData() ? editor.getData() : ' ');
        setIsEditorChanges(1);
    };

    const fetchCategories = async () => {
        setIsFetchingCat(true);
        const { data, error } = await getNoteCategories({
            subserverId: subserverId,
        });
        if (error) {
            setErrorFetchCat(error);
        }
        if (data && !error) {
            setNoteCat(data.data);
        }
        setIsFetchingCat(false);
    };

    const openCreatedNote = useCallback(
        (id: string) => {
            const targetNote = notes.find(note => note.id === id);
            if (targetNote) {
                setActiveNote(targetNote);
            }
        },
        [notes],
    );

    const toggleFiltersModal = (toggleState?: boolean) => {
        setIsFilterModalOpen(prev => {
            return toggleState === undefined ? !prev : toggleState;
        });
    };

    const changeTabs = ({ activeTab, indexShift, indexWidth }) => {
        setTabs({
            activeTab: activeTab,
            indexShift: indexShift,
            indexWidth: indexWidth,
        });
    };

    const onForwardHandler = (config: INoteSubmitConfig) => {
        const { data, receiveType } = config;
    };

    const changeCategory = (cat_id: string | null) => {
        if (cat_id === null || cat_id.trim().toLowerCase() === 'all') {
            return setFilteredNotes(notes);
        }
        const filtered = [...notes].filter(
            note => cat_id && note.note_section_id === cat_id,
        );
        setFilteredNotes(filtered);
    };

    const getNotes = async (): Promise<IFetchNotesResponse> => {
        setIsFetchingNotes(true);
        const { data, error } = await getNotesList({
            subserverId: subserverId,
        });
        if (error || data.status === 'false') {
            setErrorFetchNotes(true);
            setIsFetchingNotes(false);
            return { data: null };
        } else {
            setErrorFetchNotes(false);
            if (data.data) {
                setIsFetchingNotes(false);
                setNotes(data.data);
                return { data: data.data };
            }
        }
    };

    const removeCategory = (idToRemove: string) => {
        const updatedCategories = noteCat.filter(
            item => item.id !== idToRemove,
        );
        setNoteCat(updatedCategories);
    };

    const saveNote = async ({
        title,
        note_section_id,
    }: ISaveNote = {}): Promise<{
        data: { status: boolean; message: any };
    }> => {
        if (!activeNote) {
            return;
        }
        let passedBody = body;
        if (!body) {
            passedBody = ' ';
        }
        clearTimeout(saveNoteTimeoutRef.current);

        setIsEditorChanges(2);
        const { message, token, key_hash } = await encryptNote({
            data: passedBody,
        });

        let params = {
            title: title ? title : activeNote?.title,
            body: message,
            encryption_token: token,
            key_hash: key_hash,
            fav: activeNote.fav,
            note_section_id: note_section_id
                ? note_section_id
                : activeNote?.note_section_id,
        };

        const { data } = await editNoteApi({
            subserverId: subserverId,
            noteId: activeNoteId,
            params,
        });

        if (data.status === true) {
            setIsEditorChanges(3);

            setNotes(prev => {
                const targetIndex = prev.findIndex(
                    note => note.id === activeNoteId,
                );

                const newArray = [
                    ...prev.slice(0, targetIndex),
                    {
                        ...prev[targetIndex],
                        body: message,
                        encryption_token: token,
                    },
                    ...prev.slice(targetIndex + 1),
                ];

                return newArray;
            });

            setDecryptedNotesBody(prev => {
                return { ...prev, [activeNote.id]: body };
            });
            return { data: data };
        }
    };

    const copyNoteContent = () => {
        const sanitizedBody = sanitizeHtml(body, {
            allowedTags: ['br'],
        });
        const message = sanitizedBody
            .replace(/<p>/g, '\n')
            .replace(/<br\s*\/?>/g, '\n')
            .replace(/<\/p>/g, '');

        navigator.clipboard.writeText(message);
    };

    useEffect(() => {
        const pasteHandler = async (event: ClipboardEvent) => {
            const text = await navigator.clipboard.readText();
        };

        document.addEventListener('paste', pasteHandler);

        return () => document.removeEventListener('paste', pasteHandler);
    }, []);

    useEffect(() => {
        getNotes();
    }, []);

    useEffect(() => {
        fetchCategories();
    }, [subserverId]);

    useEffect(() => {
        changeCategory(activeCategory);
    }, [activeCategory, notes]);

    useEffect(() => {
        setActiveNote(null);
    }, [activeCategory]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isEditorChanges === 3) {
                setIsEditorChanges(0);
            }
        }, 2000);

        return () => clearTimeout(timer);
    }, [isEditorChanges]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (activeNoteId && body) {
                saveNote();
            }
        }, 2000);

        saveNoteTimeoutRef.current = timer;

        return () => {
            clearTimeout(saveNoteTimeoutRef.current);
        };
    }, [body]);

    useEffect(() => {
        if (activeNoteId) {
            setBody(decryptedNotesBody?.[activeNote?.id] || '');
            setActiveMobileStep(1);
        } else {
            setBody(null);
            setActiveMobileStep(0);
        }
    }, [activeNoteId]);

    useEffect(() => {
        setSelectedNotes([]);
    }, [activeCategory]);

    const contextValues: INoteContextProps = {
        noteCat,
        isFetchingCat,
        errorFetchCat,
        isFetchingNotes,
        errorFetchNotes,
        notes,
        activeNote,
        activeCategory,
        isForwardModal,
        isDeleteModal,
        isColorPickModal,
        tabs,
        filteredNotes,
        searchValue,
        isFilterModalOpen,
        filterType,
        isAddNewGroup,
        isAddNewNote,
        isEditCategory,
        decryptedNotesBody,
        isEditorChanges,
        body,
        isDeleteCategory,
        isFullMode,
        isEditNoteModal,
        selectedNotes,
        activeMobileStep,
        setActiveMobileStep,
        setSelectedNotes,
        setIsEditNoteModal,
        setIsFullMode,
        copyNoteContent,
        getNotes,
        setIsDeleteCategory,
        saveNote,
        changeNoteBodyHandler,
        setIsEditorChanges,
        setDecryptedNotesBody,
        setNotes,
        setIsEditCategory,
        removeCategory,
        openCreatedNote,
        setIsAddNewNote,
        fetchCategories,
        setIsAddNewGroup,
        setActiveCategory,
        setActiveNote,
        setFilterType,
        onForwardHandler,
        setIsForwardModal,
        setIsDeleteModal,
        setIsColorPickModal,
        setSearchValue,
        changeTabs,
        toggleFiltersModal,
    };

    return (
        <NotesContext.Provider value={contextValues}>
            {children}
        </NotesContext.Provider>
    );
};
