import React from 'react';

import classes from './EmptyMessages.module.css';

const EmptyMessages: React.FC<{ title: string }> = ({ title }) => {
    return (
        <div className={classes['wrap']}>
            <h2>{title}</h2>
        </div>
    );
};
export default EmptyMessages;
