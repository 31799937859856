import { IRole } from '@/types';
import { faker } from '@faker-js/faker';

export const SetRandomRoleNames = (roles:IRole[]) => {
    const newRoles = roles.map(role => ({
        ...role,
        name: faker.internet.userName(),
    }));

    return newRoles;
};

