import { motion } from 'framer-motion';
import { useContext } from 'react';

import { ReactComponent as SaveIcon } from '@/assets/icons/save-note.svg';
import { ReactComponent as SavedIcon } from '@/assets/icons/saved.svg';
import { ReactComponent as SavingIcon } from '@/assets/icons/saving.svg';
import { NotesContext } from '@/context/notes/NotesContext';

import classes from './NoteDetails.module.css';

const NoteSaveStatus: React.FC<{}> = () => {
    const noteContext = useContext(NotesContext);
    const { isEditorChanges, saveNote } = noteContext;
    return (
        <p className={classes['save-state']}>
            {isEditorChanges === 0 && (
                <>
                    <motion.span
                        className={classes['disabled']}
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0, x: -5, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1, x: 0 }}>
                        <SaveIcon height={18} />
                    </motion.span>
                </>
            )}
            {isEditorChanges === 1 && (
                <>
                    <motion.span
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0, x: -5 }}
                        animate={{ opacity: 1, x: 0 }}>
                        <SaveIcon height={18} />
                    </motion.span>
                    <motion.span
                        onClick={saveNote}
                        initial={{ x: 8, opacity: 0 }}
                        exit={{ x: -8, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}>
                        Save
                    </motion.span>
                </>
            )}
            {isEditorChanges === 2 && (
                <>
                    <motion.span
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0, x: -5 }}
                        animate={{ opacity: 1, x: 0 }}>
                        <SavingIcon
                            className={classes['saving-icon']}
                            height={18}
                        />
                    </motion.span>
                    <motion.span
                        initial={{ x: 8, opacity: 0 }}
                        exit={{ x: -8, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}>
                        Saving..
                    </motion.span>
                </>
            )}
            {isEditorChanges === 3 && (
                <>
                    <motion.span
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0, x: -5 }}
                        animate={{ opacity: 1, x: 0 }}>
                        <SavedIcon height={18} />
                    </motion.span>
                    <motion.span
                        initial={{ x: 8, opacity: 0 }}
                        exit={{ x: -8, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}>
                        Saved
                    </motion.span>
                </>
            )}
        </p>
    );
};
export default NoteSaveStatus;
