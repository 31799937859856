import { Badge } from '@/UI';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './ServerBanner.module.css';

const OnlineMembersCount: React.FC<{ membersSelector: any }> = ({
    membersSelector,
}) => {
    const onlineMembers = membersSelector.filter(item => item.state).length;

    return (
        <div>
            <Badge className={classes.dot} />
            {onlineMembers ?? 0}
        </div>
    );
};
export default OnlineMembersCount;
