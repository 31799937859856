import { t } from '@lingui/macro';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';

import { AppButton, CustomInput, EmojiKeyBoard, Modal } from '@/UI';
import BackdropModal from '@/UI/backdrop-modal/BackdropModal';
import {
    createNoteCategory,
    editNoteCategory,
} from '@/api/notes/noteCategoriesApis';
import { ReactComponent as EmojiIcon } from '@/assets/icons/emojiPicker.svg';
import { NotesContext } from '@/context/notes/NotesContext';
import { useAppSelector } from '@/hooks/useRedux';

import classes from './NewNoteSection.module.css';

interface IProps {
    onClose: () => void;
    isEdit?: boolean;
}

const NewNoteSection: React.FC<IProps> = props => {
    const { onClose, isEdit = false } = props;
    const nameRef = useRef<HTMLInputElement>(null);

    const noteContext = useContext(NotesContext);
    const { noteCat, isEditCategory, fetchCategories } = noteContext;

    const editingCat =
        isEditCategory &&
        noteCat.find(category => category.id === isEditCategory);

    const [emoji, setEmoji] = useState('');
    const [isEmojiOpen, setIsEmojiOpen] = useState(false);

    const [isInputReq, setIsInputReq] = useState(false);

    const [isError, setIsError] = useState(null);
    const [isPosting, setIsPosting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);

    const subserverId = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const changeValuesHandler = (ev, el) => {
        if (el === 'name') {
            if (ev.target.value.trim() !== '' && isInputReq) {
                setIsInputReq(false);
            }
        }
    };

    const submitHandler = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const name = nameRef.current.value.trim();
        if (name === '') {
            setIsInputReq(true);
            return;
        }
        setIsPosting(true);

        let params: {
            title: string;
            icon: string;
            encryption_token?: string;
        } = {
            title: name,
            icon: emoji,
        };

        if (isEdit) {
            const { data } = await editNoteCategory({
                subserverId,
                categoryId: isEditCategory,
                params,
            });
            if (data.status === false) {
                setIsError(data.message);
                setIsPosting(false);
                setIsSuccess(false);
            } else {
                setIsError(null);
                setIsSuccess(true);
                fetchCategories();
                setTimeout(() => {
                    setIsPosting(false);
                    onClose();
                }, 700);
            }
            return;
        }
        params = { ...params, encryption_token: 'qqq' };
        const { data } = await createNoteCategory({ subserverId, params });
        if (data.status === false) {
            setIsError(data.message);
            setIsPosting(false);
            setIsSuccess(false);
        } else {
            setIsError(null);
            setIsSuccess(true);
            fetchCategories();
            setTimeout(() => {
                setIsPosting(false);
                onClose();
            }, 700);
        }
    };
    useEffect(() => {
        if (editingCat) {
            setEmoji(editingCat.icon);
        }
    }, [editingCat]);
    return (
        <Modal
            header={t`New Folder`}
            className={classes.modal}
            headerClassName={classes['header']}
            onClose={onClose}>
            <form onSubmit={submitHandler}>
                <div className={`${classes['container']}`}>
                    {!isPosting && isError && (
                        <p className={classes['error']}>{isError}</p>
                    )}
                    {!isPosting && isSuccess && (
                        <p className={classes['success']}>
                            {isEdit
                                ? t`Edited Successfully`
                                : t`Created Successfully`}
                        </p>
                    )}
                    <div className={classes['flex']}>
                        {isEmojiOpen && (
                            <>
                                <BackdropModal
                                    onHide={() => setIsEmojiOpen(false)}
                                />
                                <EmojiKeyBoard
                                    onSelect={event => {
                                        changeValuesHandler(event, 'icon');
                                        setEmoji(event.emoji);
                                        setIsEmojiOpen(false);
                                    }}
                                    width="300px"
                                    height="350px"
                                    skinTonesDisabled={true}
                                    searchDisabled={false}
                                />
                            </>
                        )}

                        <div className={classes['input-wrap']}>
                            <span
                                className={classes['emoji']}
                                onClick={() => setIsEmojiOpen(prev => !prev)}>
                                {emoji && emoji.trim() !== '' ? (
                                    emoji
                                ) : (
                                    <EmojiIcon />
                                )}
                            </span>
                            <CustomInput
                                label={t`Category Name:`}
                                autoFocus
                                ref={nameRef}
                                type={'text'}
                                placeholder={t`name:`}
                                defaultValue={editingCat && editingCat.title}
                                onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                                    changeValuesHandler(ev, 'name')
                                }
                                className={`${
                                    isInputReq ? classes['error'] : ''
                                } ${classes['input']}  ${
                                    classes['section-name']
                                }`}
                            />
                        </div>
                    </div>
                    <div className={classes['btn-wrap']}>
                        <AppButton
                            onClick={onClose}
                            type="button"
                            title={t`Cancel`}
                            classStyles={classes['cancel']}
                            disable={isPosting}
                        />
                        <AppButton
                            title={t`Save`}
                            type="submit"
                            buttonType="primary"
                            classStyles={classes['save']}
                            disable={isPosting}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};
export default NewNoteSection;
