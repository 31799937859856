import classes from './EditChannel.module.css';

interface IProps {
    title: string;
    subtitle: string;
}
const Title: React.FC<IProps> = props => {
    const { title, subtitle } = props;
    return (
        <div className={classes['title-sub-wrapper']}>
            <span className={classes['title']}>{title}</span>
            <span className={classes['subtitle']}>{subtitle}</span>
        </div>
    );
};
export default Title;
