import { PLATFORM } from '@/constants/enum';
import { RootState } from '@/store';

import { getStoreState } from './dataHandler';

export const convertToObjects = (
    arr: Array<object>,
    key: string,
    prev?: Array<object> | object,
) => {
    const initialObj = arr?.reduce((acc, item) => {
        return { ...acc, [item[key]]: item };
    }, {});

    if (prev) {
        const arr2 = Array.isArray(prev) ? prev : Object.values(prev);

        const filteredIds = arr?.map(item => item[key]);
        const filteredArr2 = arr2?.filter(item =>
            filteredIds?.includes(item[key]),
        );

        return filteredArr2?.reduce((acc, item) => {
            const existingItem = acc[item[key]];

            if (existingItem) {
                acc[item[key]] = { ...item, ...existingItem };
            } else {
                acc[item[key]] = item;
            }
            return acc;
        }, initialObj);
    } else return initialObj;
};

export function changeKey(arr, oldKey, newKey) {
    const newarr = arr.map(item => {
        if (item.hasOwnProperty(oldKey)) {
            item[newKey] = item[oldKey];
            delete item[oldKey];
        }
        return item;
    });

    return newarr;
}

export const sortData = (data, key) => {
    if (data === undefined || data?.length === 0) return;
    let sortData = [...data];
    const keyType = typeof sortData[0]?.[key];

    if (keyType === 'string')
        sortData = sortData.sort((a, b) => a[key].localeCompare(b[key]));

    if (keyType === 'boolean')
        sortData = sortData.sort((a, b) => b[key] - a[key]);

    if (keyType === 'object')
        sortData = sortData.sort((a, b) => b[key]?.length - a[key]?.length);

    return sortData;
};

export function getCookie(cookieName) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(cookieName + '=')) {
            return cookie.substring(cookieName.length + 1);
        }
    }
    return null; // Return null if the cookie is not found
}

export function clearCookie(cookieName) {
    document.cookie =
        cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export function formatCreatedDate(strDate) {
    const emptyDate = '--/--/---- --:--';

    if (!strDate) {
        return emptyDate;
    }

    const date = new Date(strDate);

    if (!date || isNaN(date.getTime())) {
        return emptyDate;
    }

    const formattedDate = date.toLocaleString('default', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });

    return formattedDate.split('.').join('/'); 
}

export function checkPlatform() {
    let platform = PLATFORM.BROWSER;

    const store: RootState = getStoreState();

    // const isRunningInElectron = window && window.process && window.process.type;
    // const isRunningOnBrowser = !isRunningInElectron;
    // const isRunningOnWindows =
    // isRunningInElectron && process.platform === 'win32';

    const isElectronConnected = store.socketConnectionReducer.WSElectron;

    if (isElectronConnected || typeof window.require == 'function') {
        platform = PLATFORM.DESKTOP;
    }

    return platform;
}
