import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import remarkGfm from 'remark-gfm';
import remarkHtml from 'remark-html';

import { formatLink } from '@/Utils/messageFormatter';

import classes from './AppMarkdown.module.css';

type Props = {};

const AppMarkdown = (props: any) => {
    const linkPattern = /(?:https?:\/\/)?(?:www\.)?\S+\.\S+/g;

    const renderers = {
        script: ({ children }) => <p style={{ color: 'red' }}>{children}</p>,
        someRandomTags: props => (
            <div style={{ color: 'blue' }}>{props.children}</div>
        ),
        a: ({ href, children }) => (
            <a
                style={{
                    color: 'var(--blue-primary)',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }}
                target={'_blank'}
                href={href ?? children}
                key={children}>
                {children}
            </a>
        ),
        code: ({ children }) => {
            // Check if the code block contains a link
            const containsLink = linkPattern.test(children);
            // If it contains a link, render it as raw HTML
            if (containsLink) {
                let newChild = children.replace(linkPattern, match =>
                    formatLink(match),
                );

                return (
                    // <code>{newChild}</code>
                    <code
                        dangerouslySetInnerHTML={{
                            __html: `<p style='text-wrap:wrap'>${newChild}</p>`,
                        }}
                    />
                );
            }
            // Otherwise, render the code block normally
            return <code>{children?.replaceAll(/&nbsp;/g, ' ')}</code>;
        },
    };

    const components = {
        // Map your custom syntax to the custom renderer
        // script: renderers.script,
        a: renderers.a,
        code: renderers.code,
    };
    return (
        <div className={classes['app-markdown']}>
            <ReactMarkdown
                skipHtml={false}
                remarkPlugins={[remarkGfm, remarkHtml]}
                components={components}
                disallowedElements={['script']}
                rehypePlugins={[rehypeRaw, rehypeSlug]}>
                {props.children}
            </ReactMarkdown>
        </div>
    );
};

export default AppMarkdown;
