import { motion } from 'framer-motion';
import { Spinner } from 'react-activity';

import { ReactComponent as TickIcon } from '@/assets/icons/singleTickWhite.svg';

import classes from './SocketToast.module.css';

const SocketToast = ({ text, className = '', loading = true }) => {
    return (
        <motion.div
            initial={{ y: '-100%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: '-100%', opacity: 0 }}
            transition={{ duration: 0.2, delay: 0 }}
            className={`${classes['toast']} ${className}`}>
            {loading && <Spinner size={16} />}
            {!loading && (
                <TickIcon
                    height={12}
                    style={{
                        filter: 'brightness(100)',
                    }}
                />
            )}
            {text}
        </motion.div>
    );
};
export default SocketToast;
