import { Trans, t } from '@lingui/macro';
import { FC, useState } from 'react';

import AppTable from '@/UI/AppTable/AppTable';
import PermissionsMeControl from '@/Utils/permissionsMeControl';
import blockSVG from '@/assets/icons/close-circle-red.svg';
import editSVG from '@/assets/icons/editManagement.svg';
import authoritySVG from '@/assets/icons/taskAuthority.svg';
import removeSVG from '@/assets/icons/trash.svg';
import useCustomSweetAlert from '@/hooks/useCustomSweetAlert';
import { IServerUser } from '@/types/ImemberData';

import HeaderCell from './HeaderCell';
import HoverIcons from './HoverIcons';
import CreatedAtColumn from './columns/CreatedAtColumn';
import EmailColumn from './columns/EmailColumn';
import GroupColumn from './columns/GroupColumn';
import StateColumn from './columns/StateColumn';
import UserColumn from './columns/UserColumn';

type IModalProps = {
    handleMemberServerBlockUpdate: (userId) => void;
    handleMemberServerUnblockUpdate: (userId) => void;
    isAddUserModal: boolean;
    isEditModal: boolean;
    isRolesModal: boolean;
    selectedUserData: boolean;
    setIsAddUserModal: () => void;
    setIsEditModal: (value: boolean) => void;
    setIsRolesModal: (value: boolean) => void;
    setSelectedUserData: (e: IServerUser) => void;
};

type Props = {
    className?: string;
    data: IServerUser[];
    managementType: string;
    modalProps: IModalProps;
    onSort: (key) => void;
    onRemoveChannel?: (e) => void;
};

const TableMemberManagement: FC<Props> = props => {
    const { onSort, className } = props;
    const {
        setIsEditModal,
        setIsRolesModal,
        setSelectedUserData,
        handleMemberServerBlockUpdate,
        handleMemberServerUnblockUpdate,
    } = props.modalProps;

    const [selectedEditPopup, setSelectedEditPopup] = useState(null);

    const { areYouSureModal } = useCustomSweetAlert();

    const actionIcons = [
        {
            icon: editSVG,
            label: t`Edit`,
            onClick: (e: IServerUser) => {
                setIsEditModal(true);
                setSelectedUserData(e);
            },
            userPermissions: ['owner'],
        },
        {
            icon: authoritySVG,
            label: t`Roles`,
            onClick: (e: IServerUser) => {
                setIsRolesModal(true);
                setSelectedUserData(e);
            },
            userPermissions: ['owner'],
        },
        props?.onRemoveChannel && {
            icon: removeSVG,
            label: t`Remove`,
            onClick: e => {
                props.onRemoveChannel(e);
            },
            userPermissions: ['owner'],
        },
        props?.managementType === 'tablemanagement' && {
            icon: blockSVG,
            label: (
                <span style={{ color: '#FB433A' }}>
                    {selectedEditPopup?.banned ? (
                        <Trans>Unblock</Trans>
                    ) : (
                        <Trans>Block</Trans>
                    )}
                </span>
            ),
            onClick: e => {
                areYouSureModal(null, '', false, () => {
                    selectedEditPopup?.banned
                        ? handleMemberServerUnblockUpdate(e?.id)
                        : handleMemberServerBlockUpdate(e?.id);
                });
            },
            userPermissions: ['owner'],
        },
    ];

    const actionIconsPermissions = PermissionsMeControl(
        actionIcons.filter(Boolean),
        'userPermissions',
    );

    const columns = [
        {
            key: 'name',
            label: t`User`,
            render: (item: IServerUser) => <UserColumn item={item} />,
        },
        {
            key: 'state',
            label: t`State`,
            render: (item: IServerUser) => <StateColumn item={item?.state} />,
        },
        {
            key: 'email',
            label: t`Email`,
            render: (item: IServerUser) => <EmailColumn item={item?.email} />,
            disableSortFilter: true,
        },
        {
            key: 'groups',
            label: t`Roles`,
            render: item => <GroupColumn key={item?.id} itemId={item?.id} />,
            disableSortFilter: true,
        },
        {
            key: 'created_at',
            label: t`Join Date`,
            render: (item: IServerUser) => (
                <CreatedAtColumn item={item?.created_at} />
            ),
        },
    ];

    return (
        <AppTable
            className={className}
            columns={columns}
            data={props?.data}
            header={item => (
                <HeaderCell
                    item={item}
                    onClick={() =>
                        !item?.disableSortFilter ? onSort(item.key) : {}
                    }
                />
            )}
            hoverIcons={data => (
                <HoverIcons
                    data={data}
                    onMouseEnter={() => setSelectedEditPopup(data)}
                    onMouseLeave={() => setSelectedEditPopup(null)}
                    canMap={
                        data?.id === selectedEditPopup?.id && selectedEditPopup
                    }
                    actionIconsPermissions={actionIconsPermissions}
                />
            )}
        />
    );
};

export default TableMemberManagement;
