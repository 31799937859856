import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';
import store from '@/store';

import { sendWebSocketData } from '../../webSocketUtils';
import { onEncryptMessage } from '../MessageActions/messageActions';

export const handleCreateThread = (
    owner_id: string,
    subserver_id: string,
    channel_id: string,
    message_id: string,
    name: string,
) => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.createThread.action,
        data: {
            owner_id: owner_id,
            subserver_id: subserver_id,
            channel_id: channel_id,
            message_id: message_id,
            name: name,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const handlegetThreadMessages = (
    date: string,
    previous: boolean,
    // limit: number,
    thread_id: string,
) => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.getThreadMessages.action,
        data: {
            date: date,
            previous: previous,
            thread_id: thread_id,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};
export const handleOpenThread = (thread_id: string, channel_id?: string) => {
    const serverDataReducer = store.getState().serverDataReducer;
    const channelId =
        serverDataReducer.activeChannelId[serverDataReducer.activeServer];
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.openThread.action,
        data: {
            thread_id: thread_id,
            channel_id: channel_id ? channel_id : channelId,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const sendThreadMessage = async (
    type,
    data,
    mentionArray,
    channel_id,
    thread_id,
    message_id,
    mentionArrayRoles,
    senderChannelId,
    senderSubserverId,
    isMergedChannelOwnerSide,
    selectedMessage,
    filterSenderMessage,
) => {
    const state = store.getState().serverDataReducer;
    let defaultAction = SOCKET_ACTIONS_WITH_TYPES.createThreadMessages.action;
    let defaultMessageID = message_id;
    let hash_key = state.serverData[state.activeServer]?.defaultHashkey;
    if (selectedMessage?.key_hash) {
        hash_key = selectedMessage.key_hash;
    }
    const { encryptedData, encryptToken } = await onEncryptMessage({
        ...data,
        key_hash: hash_key,
    });

    if (!isMergedChannelOwnerSide) {
        defaultMessageID = filterSenderMessage?._id;
    }

    let payload = {
        action: defaultAction,
        data: {
            message: encryptedData,
            token: encryptToken,
            type: type,
            everyone: data.everyone,
            mention_id: mentionArray ?? [],
            mention_roles: mentionArrayRoles ?? [],
            encryption_version: 2,
            thread_id: thread_id,
            channel_id: channel_id,
            message_id: defaultMessageID,
            key_hash: hash_key,
            sender_channel_id: channel_id,
            sender_subserver_id: state.activeServer,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const onLeaveThread = (
    user_id: string,
    subserver_id: string,
    channel_id: string,
    // user_name: string,
    thread_id: string,
) => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.leaveThread.action,
        data: {
            user_id: user_id,
            subserver_id: subserver_id,
            channel_id: channel_id,
            // user_name: user_name,join_thread
            thread_id: thread_id,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const onJoinThread = (
    user_id: string,
    subserver_id: string,
    channel_id: string,
    // user_name: string,
    thread_id: string,
) => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.joinThread.action,
        data: {
            user_id: user_id,
            subserver_id: subserver_id,
            channel_id: channel_id,
            // user_name: user_name,join_thread
            thread_id: thread_id,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const deleteThread = (
    subserver_id: string,
    channel_id: string,
    thread_id: string,
) => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.deleteThread.action,
        data: {
            subserver_id: subserver_id,
            channel_id: channel_id,
            thread_id: thread_id,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const LockThread = (
    user_id: string,
    subserver_id: string,
    channel_id: string,
    // user_name: string,
    thread_id: string,
    is_thread_lock: boolean,
) => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.threadLock.action,
        data: {
            subserver_id: subserver_id,
            channel_id: channel_id,
            thread_id: thread_id,
            user_id: user_id,
            is_thread_lock: is_thread_lock,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const CreateThreadReaction = (
    message_id: string,
    subserver_id: string,
    channel_id: string,
    thread_id: string,
    emoji: string,
) => {
    const serverDataReducer = store.getState().serverDataReducer;
    const channelId =
        serverDataReducer.activeChannelId[serverDataReducer.activeServer];
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.addThreadReaction.action,
        data: {
            subserver_id: subserver_id,
            channel_id: channel_id ? channel_id : channelId,
            thread_id: thread_id,
            message_id: message_id,
            emoji: emoji,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const deleteThreadMessage = (
    message_id: string,
    channel_id: string,
    thread_id: string,
) => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.deleteThreadMessage.action,
        data: {
            channel_id: channel_id,
            thread_id: thread_id,
            message_id: message_id,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const deleteThreadReaction = (
    reaction_id: string,
    channel_id: string,
    thread_id: string,
) => {
    const data = {
        action: SOCKET_ACTIONS_WITH_TYPES.deleteThreadReaction.action,
        data: {
            reaction_id: reaction_id,
            channel_id: channel_id,
            thread_id: thread_id,
        },
    };
    sendWebSocketData(JSON.stringify(data));
};

export const threadEditMessage = async (
    msg: { id: string; message: string },
    thread_id,
    channel_id,
    subserver_id,
) => {
    const { encryptedData, encryptToken } = await onEncryptMessage({
        message: msg.message,
    });

    const data = {
        action: SOCKET_ACTIONS_WITH_TYPES.threadMessageEdit.action,
        data: {
            message_id: msg.id,
            message: encryptedData,
            token: encryptToken,
            thread_id: thread_id,
            channel_id: channel_id,
            subserver_id: subserver_id,
        },
    };
    sendWebSocketData(JSON.stringify(data));
};

export const getThreadListScroll = async (page_number, perPage) => {
    const data = {
        action: SOCKET_ACTIONS_WITH_TYPES.getThreads.action,
        data: {
            page_number: page_number,
            perPage: perPage,
        },
    };
    sendWebSocketData(JSON.stringify(data));
};
