import { t } from '@lingui/macro';
import Swal from 'sweetalert2';

import { useAppSelector } from './useRedux';

function useCustomSweetAlert() {
    const defaultTheme = useAppSelector(
        state => state.themeColorReducer.themeColor,
    );

    const successAlertModal = (
        title?: string,
        description?: string,
        successFunc?: any,
        closeFunc?: any,
    ) => {
        Swal.fire({
            title: `<span style='color:${'var(--text-2)'}'>${
                title ?? 'Successful!'
            }</span>`,
            html: `<span style='color: ${
                defaultTheme == 'LIGHT' ? '#353A75' : '#D2D9EC'
            } '>${description ?? ''}</span>`,
            icon: 'success',
            confirmButtonText: t`Okay`,
            confirmButtonColor: '#009fff',
            background: defaultTheme == 'LIGHT' ? '#fff' : '#100D26',
        }).then(result => {
            if (result.isConfirmed) {
                successFunc && successFunc();
            } else {
                closeFunc && closeFunc();
            }
        });
    };

    const errorAlertModal = (
        title?: string,
        description?: string,
        successFunc?: any,
        closeFunc?: any,
        successText?: string,
    ) => {
        Swal.fire({
            title: `<span style='color:${'var(--text-2)'}'>${
                title ?? 'Unsuccessful!'
            }</span>`,
            html: `<span style='color: ${
                defaultTheme == 'LIGHT' ? '#353A75' : '#D2D9EC'
            } '>${description ?? ''}</span>`,
            icon: 'error',
            confirmButtonText: successText ?? t`Okay`,
            confirmButtonColor: '#009fff',
            background: defaultTheme == 'LIGHT' ? '#fff' : '#100D26',
        }).then(result => {
            if (result.isConfirmed) {
                successFunc && successFunc();
            } else {
                closeFunc && closeFunc();
            }
        });
    };

    const areYouSureModal = (
        title?: string,
        description?: string,
        thenReactionAlert?: boolean,
        successFunc?: any,
        confirmBtnText?: string,
    ) => {
        Swal.fire({
            title: `<span style='color:${'var(--text-2)'}'>${
                title ?? `${t`Are you sure`}?`
            }</span>`,
            html: `<span style='color: ${
                defaultTheme == 'LIGHT' ? '#353A75' : '#D2D9EC'
            } '>${description ?? t`You won't be able to revert this!`}</span>`,
            icon: 'question',
            color: defaultTheme == 'LIGHT' ? '#fff' : '#100D26',
            showCancelButton: true,
            confirmButtonColor: defaultTheme == 'LIGHT' ? '#fff' : '#100D26',
            cancelButtonColor: '#009fff',
            confirmButtonText: `<span style='color:var(--text-2)'>${
                confirmBtnText ? confirmBtnText : t`Confirm`
            }</span>`,
            background: defaultTheme == 'LIGHT' ? '#fff' : '#100D26',
        }).then(result => {
            if (result.isConfirmed) {
                successFunc && successFunc();
                thenReactionAlert && successAlertModal();
            }
        });
    };

    return { areYouSureModal, successAlertModal, errorAlertModal };
}

export default useCustomSweetAlert;
