import classes from "./CloseBtn.module.css";

const AnimatedCloseBtn: React.FC<{ className?: string; onClose: () => void }> = ({
  className = "",
  onClose,
}) => {
  return (
    <div
      onClick={onClose}
      className={`${classes["close-container"]} ${className} cursor-pointer`}
    >
      <div className={classes["leftright"]}></div>
      <div className={classes["rightleft"]}></div>
      <label className={classes["close"]}>close</label>
    </div>
  );
};
export default AnimatedCloseBtn;
