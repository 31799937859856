
import { ReactComponent as TrashIcon } from '@/assets/icons/trash-2.svg';
import Popover from '@/components/popover/Popover';

import DeletePopoverContent from './DeletePopoverContent';

const DeleteNotePopover: React.FC<{}> = () => {
    return (
        <Popover
            trigger={
                <TrashIcon className="cursor-pointer" height={18} width={16} />
            }
            content={<DeletePopoverContent />}
        />
    );
};

export default DeleteNotePopover;
