import { RootState } from '@/store';
import { IChannelData, IServerData } from '@/types';

import { getStoreState } from './dataHandler';

export const getServerDetails = (serverId?: string): IServerData => {
    const store: RootState = getStoreState();
    const server_id = serverId ?? store.serverDataReducer.activeServer;
    const server: IServerData = store.serverDataReducer.serverData[server_id];
    return server;
};

export const getChannelList_activeServer = (
    serverId?: string,
): { [x: string]: IChannelData } => {
    const store: RootState = getStoreState();
    const channelList = store.serverDataReducer.channelList[serverId];
    // const server = getServerDetails(serverId);
    const channel = channelList;
    return channel;
};

export const getChannelDetails_activeServer = (
    channelId?: string,
): IChannelData => {
    const store: RootState = getStoreState();
    const server = getServerDetails();
    const channel_id =
        channelId ??
        store.serverDataReducer.activeChannelId[
            store.serverDataReducer.activeServer
        ];
    const channelList =
        store.serverDataReducer.channelList[
            store.serverDataReducer.activeServer
        ];

    const channel = channelList?.[channel_id];
    return channel;
};
