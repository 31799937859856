import React, { ChangeEvent, Ref, useEffect, useState } from 'react';

import { FileType } from '../display-attachment/DisplayAttachment';

import { MediaInputProps } from './MediaInput';
import classes from './MediaInput.module.css';
import acceptedMediaTypes from './mediaTypes';
import processMedia from './processMedia';

const useMediaInput = (props: MediaInputProps, ref: Ref<HTMLInputElement>) => {
    const { clicker = false, passDefaultFiles, passDefaultLink } = props;

    const [mediaFiles, setMediaFiles] = useState<FileType[]>([]);
    const [defaultImageLink, setDefaultImageLink] = useState<string[]>([]);
    const [errorFileSize, setErrorFileSize] = useState<boolean>(false);

    const openMediaInputHandler = () => {
        if (ref && 'current' in ref && ref.current) {
            ref.current.click();
        }
    };

    const changeHandler = (event: ChangeEvent<HTMLInputElement> | File[]) => {
        setDefaultImageLink([]);

        if (Array.isArray(event)) {
            fetchMedia(event);
        } else {
            if (event.target?.files) {
                fetchMedia(event.target.files);
            }
        }
    };

    const fetchMedia = async (filesObject: FileList | null | File[]) => {
        if (!filesObject) return;
        const { mediaArray } = await processMedia(filesObject);
        let allMedia: any[];

        if (props.multiple) {
            allMedia = [...mediaFiles, ...mediaArray];
            allMedia = allMedia.reduce((uniqueItem, index) => {
                if (
                    !uniqueItem.some(
                        obj =>
                            obj.name === index.name &&
                            obj.size === index.size &&
                            obj.type === index.type,
                    )
                ) {
                    uniqueItem.push(index);
                }
                return uniqueItem;
            }, []);
        } else {
            allMedia = mediaArray;
        }

        const maxSizeInBytes = 50 * 1024 * 1024; // 50MB
        const oversizedFiles = allMedia?.filter(file => {
            return (
                (file?.type?.startsWith('application/pdf') ||
                    file?.type?.startsWith('image')) &&
                file?.size > maxSizeInBytes
            );
        });

        if (oversizedFiles?.length > 0) {
            setErrorFileSize(true);
            return;
        } else {
            setErrorFileSize(false);
        }

        allMedia = allMedia.map((e, key) => {
            return { ...e, id: `media_item_${key}` };
        });
        props.onChange(allMedia);
        setMediaFiles(allMedia);
    };

    const galleryClass = props.multiple
        ? classes['display-items']
        : classes['one-item'];

    const removeMediaHandler = (id: string) => {
        setMediaFiles(prev => prev.filter(e => e.id !== id));
        props.onRemoveMedia(id);
    };

    const removeImageLinkHandler = (key: number) => {
        const newaee = defaultImageLink.filter((e, index) => index !== key);
        setMediaFiles([]);
        setDefaultImageLink(newaee);
        props.onChange([]);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        fetchMedia(droppedFiles);
        const filterAccept = props?.accept ?? acceptedMediaTypes;
        const validFiles = droppedFiles.filter(file =>
            filterAccept?.includes(file.type),
        );
        if (validFiles.length > 0) {
            fetchMedia(validFiles);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (passDefaultFiles) {
            changeHandler(passDefaultFiles);
        }
    }, [passDefaultFiles]);

    useEffect(() => {
        if (passDefaultLink && passDefaultLink.length > 0) {
            setDefaultImageLink(passDefaultLink);
        }
    }, [passDefaultLink]);

    useEffect(() => {
        if (clicker && ref && 'current' in ref && ref.current) {
            ref.current.click();
        }
    }, [clicker]);

    return {
        handleDragOver,
        handleDrop,
        removeImageLinkHandler,
        removeMediaHandler,
        openMediaInputHandler,
        changeHandler,
        errorFileSize,
        galleryClass,
        mediaFiles,
        defaultImageLink,
    };
};
export default useMediaInput;
