import { msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect, useState } from 'react';

import { Modal } from '@/UI';
import PersonalCardIcon from '@/assets/icons/PersonalCardIcon';
import auditLogIcon from '@/assets/icons/audit-log-icon.svg';
import serverIcon from '@/assets/icons/cpu.svg';
import lockIcon from '@/assets/icons/lock.svg';
import managementIcon from '@/assets/icons/management.svg';
import memberIcon from '@/assets/icons/personalcard.svg';
import organizationIcon from '@/assets/icons/profile-user.svg';
import statisticsIcon from '@/assets/icons/statistics.svg';
import downloadIcon from '@/assets/images/download_icon.png';
import { useAppSelector } from '@/hooks/useRedux';
import { userPermissionSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './ManagementModal.module.css';
import AuditLogManagement from './audit-log/AuditLogManagement';
import EncryptionManagement from './encryption/EncryptionManagement';
import MemberManagement from './member-management/MemberManagement';
import OrganizationMember from './organization_member/OrganizationMember';
import RolesManagement from './roles-management/RolesManagement';
import ServerManagement from './server-management/ServerManagement';
import Statistics from './statistics/Statistics';
import VersionDownload from './version-download/VersionDownload';

const ManagementModal = (props: any) => {
    const { _ } = useLingui();

    const componentMap = {
        member: {
            name: _(msg`Member Management`),
            icon: memberIcon,
            component: MemberManagement,
        },
        roles: {
            name: _(msg`Roles Management`),
            icon: (
                <PersonalCardIcon
                    className={classes['navbar-icon']}
                    stroke={'var(--white)'}
                />
            ),
            component: RolesManagement,
        },
        server: {
            name: _(msg`Server Management`),
            icon: serverIcon,
            component: ServerManagement,
        },
        organization_member: {
            name: _(msg`Organization Member`),
            icon: organizationIcon,
            component: OrganizationMember,
        },
        encryption: {
            name: _(msg`Encryption`),
            icon: lockIcon,
            component: EncryptionManagement,
        },
        audit_log: {
            name: _(msg`Audit Log`),
            icon: auditLogIcon,
            component: AuditLogManagement,
        },
        download: {
            name: _(msg`Download`),
            icon: downloadIcon,
            component: VersionDownload,
        },
        statistics: {
            name: _(msg`Statistics`),
            icon: statisticsIcon,
            component: Statistics,
        },
    };

    const [selectedComponent, setSelectedComponent] = useState('member');
    const [componentMapArray, setComponentMapArray] = useState([]);

    const selectComponent = (component: string) =>
        setSelectedComponent(component);

    const permissions = useAppSelector(userPermissionSelector);
    const roleCreatePermission = permissions?.['role.create'];
    const ownerPermission = permissions?.['owner'];

    useEffect(() => {
        handleCheckPermissions();
    }, [roleCreatePermission, ownerPermission]);

    const handleCheckPermissions = () => {
        const TabsData = Object.keys(componentMap)
            .map((componentId, key) => {
                if (roleCreatePermission && !ownerPermission) {
                    return componentId === 'roles' ? componentId : null;
                } else {
                    return componentId;
                }
            })
            .filter(Boolean);

        setComponentMapArray(TabsData);
    };

    useEffect(() => {
        if (componentMapArray.length > 0) {
            selectComponent(componentMapArray[0]);
        }
    }, [componentMapArray]);

    return (
        <Modal
            removeBodyPadding
            header={t`Server Management`}
            className={classes['container']}
            onClose={() => props.onClose()}
            headerIcon={managementIcon}
            headerClassName={classes['container-header']}>
            <div className={classes['modal-container']}>
                <div className={classes['left-navbar']}>
                    {componentMapArray?.map((componentId, key) => (
                        <div
                            className={
                                classes['navbar-item'] +
                                ' ' +
                                (selectedComponent === componentId
                                    ? classes['active']
                                    : '')
                            }
                            key={key}
                            onClick={() => selectComponent(componentId)}>
                            {typeof componentMap[componentId].icon ===
                            'string' ? (
                                <img
                                    alt=""
                                    src={componentMap[componentId].icon}
                                    className={classes['navbar-icon']}
                                />
                            ) : (
                                componentMap[componentId].icon
                            )}

                            <span className={classes['navbar-text']}>
                                {componentMap[componentId].name}
                            </span>
                        </div>
                    ))}
                </div>
                <div className={classes['right-content']}>
                    {selectedComponent &&
                        React.createElement(
                            componentMap[selectedComponent].component,
                        )}
                </div>
            </div>
        </Modal>
    );
};

export default ManagementModal;
