import { Trans } from '@lingui/macro';

import { Card } from '@/UI';
import empychat from '@/assets/images/empty_chat_bg.png';

import classes from './ChatAreaSection.module.css';

const EmptyChatArea: React.FC<{}> = () => {
    return (
        <section className={classes['chat-area-container']}>
            <Card className={`${classes['flex-empty']} ${classes.chatCard}`}>
                <div className={classes['wrap-empty']}>
                    <img src={empychat} alt="start chating" />
                    <h1>
                        <Trans>Welcome to CovChat</Trans>
                    </h1>
                    <p>
                        <Trans>let's start chatting now</Trans>
                    </p>
                </div>
            </Card>
        </section>
    );
};
export default EmptyChatArea;
