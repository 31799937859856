import React from 'react';

import DownloadServerKeys from '@/components/DownloadServerKeys/DownloadServerKeys';

type Props = {};

const DownloadKeysPage = (props: Props) => {
    return <DownloadServerKeys />;
};

export default DownloadKeysPage;
