import { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '@/hooks/useRedux';
import { channelMembersSelector } from '@/store/server-data-reducer/selectors-server-data';

const useChannelMemberDetails = () => {
    const [cachedMembers, setCachedMembers] = useState([]);

    const channelMembers = useAppSelector(channelMembersSelector);
    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );

    useEffect(() => {
        let timer = setTimeout(() => {}, 0);

        if (channelMembers) {
            setCachedMembers(channelMembers);
        } else {
            timer = setTimeout(() => {
                setCachedMembers(channelMembers);
            }, 2000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [channelMembers]);

    const getChannelMembersData = () => {
        if (!cachedMembers) {
            return;
        }
        const channelMembersList = [];
        cachedMembers?.forEach(item =>
            channelMembersList.push(memberList[item]),
        );
        return channelMembersList;
    };

    const members = getChannelMembersData() ?? [];

    const sortedMembers = members.filter(
        member =>
            member &&
            (member.status === undefined || member.status) &&
            member.name &&
            !member.banned,
    );

    const offlineMembers = useMemo(
        () => sortedMembers?.filter(member => !member.state),
        [sortedMembers],
    );

    const onlineMembers = useMemo(
        () => sortedMembers?.filter(member => member.state === true),
        [sortedMembers],
    );

    return { sortedMembers, onlineMembers, offlineMembers };
};

export default useChannelMemberDetails;
