import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';
import { FC, useRef } from 'react';

import { AppButton, Modal } from '@/UI';
import CustomInput from '@/UI/custom-input/CustomInput';
import { createSection } from '@/Utils/socket-actions/ChannelActions/channelActions';
import headerIcon from '@/assets/icons/add-square.svg';
import { BUTTON_TYPE } from '@/constants/enum';

import classes from './CreateSection.module.css';

interface IProps {
    onClose: () => void;
}

const CreateSection: FC<IProps> = props => {
    const { onClose } = props;
    const textRef = useRef<HTMLInputElement>(null);

    const submitNewServerHandler = event => {
        event.preventDefault();
        createSection({ name: textRef.current.value.trim() });
        closeModal();
    };

    const closeModal = () => {
        onClose();
    };

    return (
        <Modal
            className={classes.modal}
            header={t`Create a Group`}
            headerIcon={headerIcon}
            onClose={closeModal}>
            <form autoComplete="new-password" onSubmit={submitNewServerHandler}>
                <h2>
                    <Trans>Group Name</Trans>
                </h2>
                <div className={classes['input-area']}>
                    <CustomInput
                        ref={textRef}
                        defaultValue=""
                        placeholder={t`Section Name`}
                        className={classes['custom-input']}
                    />
                </div>

                <div className={classes['d-flex']}>
                    <AppButton
                        type="button"
                        onClick={closeModal}
                        buttonType={BUTTON_TYPE.whiteWithBorder}
                        classStyles={classes['appButton']}
                        title={t`Cancel`}
                    />
                    <AppButton
                        classStyles={classes['appButton']}
                        type="submit"
                        title={t`Save`}
                    />
                </div>
            </form>
        </Modal>
    );
};

export default CreateSection;
