import React, { Component, ErrorInfo, ReactNode } from 'react';

// usage
export const ErrorComponent = () => {
    const a = undefined;
    return a.map(item => item);
};
//
// <CustomErrorBoundary>
//     <ErrorComponent />
// </CustomErrorBoundary>

interface Props {
    children?: ReactNode;
    showErrorDetail?: boolean;
}

interface State {
    hasError: boolean;
    error: Error | null;
}

class CustomErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: null,
    };

    public static getDerivedStateFromError(error: Error): State {
        return { hasError: true, error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div
                    style={{
                        color: 'white',
                        textAlign: 'start',
                        backgroundColor: 'black',
                        padding: 4,
                        borderRadius: 4,
                        opacity: 0.5,
                        width: '100%',
                    }}>
                    <div>Oops, something went wrong!</div>
                    {this.props.showErrorDetail && (
                        <>
                            <p>We apologize for the inconvenience.</p>
                            <p>Please try again later or contact support.</p>
                            {this.state.error && (
                                <div>
                                    <p>Error Details:</p>
                                    <p>{this.state.error.toString()}</p>
                                    <p>Stack Trace:</p>
                                    <pre>{this.state.error.stack}</pre>
                                </div>
                            )}
                        </>
                    )}
                </div>
            );
        }

        return this.props.children;
    }
}

export default CustomErrorBoundary;
