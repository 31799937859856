import { statusById } from '@/constants/status';
import { useAppSelector } from '@/hooks/useRedux';

function useStatusHelper() {
    const user = useAppSelector(state => state.userReducer.user);

    const getStatusById = (id: string) => {
        return statusById?.[id];
    };

    const getStatusMe = () => {
        return statusById?.[user?.statu];
    };

    return { getStatusById, getStatusMe };
}

export default useStatusHelper;
