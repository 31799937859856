import { Trans } from '@lingui/macro';
import { useContext } from 'react';
import { Spinner } from 'react-activity';

import SearchImage from '@/assets/icons/SearchImage';
import { ReactComponent as FailureIcon } from '@/assets/icons/failure.svg';
import { useAppSelector } from '@/hooks/useRedux';

import classes from './Search.module.css';
import { SearchContext } from './SearchContext';

const SearchResultEmpty: React.FC<{}> = () => {
    const context = useContext(SearchContext);
    const { searchResult, searchText } = context;

    const loading = useAppSelector(state => state.utilReducer.searchLoading);
    const searchError = useAppSelector(
        state => state.indexedDBReducer.searchError,
    );

    return (
        <div className={classes['empty-result']}>
            {loading ? (
                <Spinner />
            ) : searchError &&
              searchResult?.length <= 0 &&
              searchText?.length > 0 ? (
                <div>
                    <FailureIcon className={classes['failure-img']} />
                    <p className={classes['no-result']}>{searchError}</p>
                </div>
            ) : (
                <div>
                    <SearchImage className={classes['search-img']} />
                    <p className={classes['result']}>
                        {searchText?.length > 0 && searchResult?.length <= 0 ? (
                            <Trans>No matches for your search</Trans>
                        ) : (
                            <Trans>Results will be displayed here</Trans>
                        )}
                    </p>
                </div>
            )}
        </div>
    );
};
export default SearchResultEmpty;
