import { t } from '@lingui/macro';
import { useCallback, useEffect, useRef, useState } from 'react';

import ImageLoader from '@/UI/loaders/ImageLoader';
import useLogoutHandler from '@/hooks/useLogoutHandler';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
    channelCountsListSelector,
    channelDataSelector,
    privateKeyArray,
    serverDataSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import { updateServer } from '@/store/server-data-reducer/server-data-reducer';
import { closeThreadAction } from '@/store/thread-reducer/threadsSlice';
import { IServerData } from '@/types';

import LogoutItem from './LogoutItem';
import ServerListItem from './ServerListItem';
import classes from './ServersListBar.module.css';
import VersionPopover from './version-popover';

const ServersListBar = props => {
    const { logoutHandler } = useLogoutHandler();

    const ulRef = useRef<any>();
    const dispatch = useAppDispatch();
    const [isOpenTooltip, setIsOpenTooltip] = useState(false);
    const loader = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const serversData: IServerData[] = useAppSelector(state =>
        serverDataSelector(state),
    );
    const channel = useAppSelector(state => channelDataSelector(state));

    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const countsList = useAppSelector(channelCountsListSelector);
    const isPrivateKeyAvailable = useAppSelector(state =>
        privateKeyArray(state),
    );

    const chooseServerHandler = useCallback(
        serverId => {
            if (activeServer !== serverId) {
                dispatch(closeThreadAction());
                props.onChooseServer(serverId);
            }
        },
        [props.onChooseServer],
    );

    useEffect(() => {
        const initialValue = 0;
        const totalSum = countsList?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            initialValue,
        );
        const privateCount = channel
            .filter(ch => ch.privacy === 'direct' && ch.count)
            ?.reduce(
                (accumulator, ch: any) => accumulator + parseInt(ch.count, 10),
                0,
            );

        if (activeServer !== '')
            dispatch(
                updateServer({
                    id: activeServer,
                    total_count: totalSum,
                    private_count: privateCount,
                }),
            );
    }, [channel, activeServer]);

    useEffect(() => {
        if (serversData.length > 0) {
            const firstServerId = activeServer || serversData[0].id;
            chooseServerHandler(firstServerId);
        }
    }, [serversData]);

    useEffect(() => {
        const handleScroll = () => {
            setIsOpenTooltip(false);
        };

        const mainContainer = ulRef.current;
        mainContainer.addEventListener('scroll', handleScroll);

        return () => {
            mainContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav style={props?.style} className={classes['vertical-bar-container']}>
            <ul className={classes['servers-list']}>
                <div ref={ulRef} className={classes['main-container']}>
                    {serversData
                        ? serversData
                              .sort((a, b) =>
                                  a.privateKey?.localeCompare(b.privateKey),
                              )
                              .map((el: any, key) => (
                                  <ServerListItem
                                      key={key}
                                      el={el}
                                      chooseServerHandler={chooseServerHandler}
                                      activeServer={activeServer}
                                      setIsOpenTooltip={setIsOpenTooltip}
                                      isOpenTooltip={isOpenTooltip}
                                  />
                              ))
                        : loader?.map((val, index) => (
                              <div
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                  }}
                                  key={index}>
                                  <ImageLoader
                                      className={classes['badge-img-loader']}
                                      width={50}
                                      height={50}
                                  />
                              </div>
                          ))}
                </div>
                {isPrivateKeyAvailable.length === 0 && (
                    <LogoutItem logoutHandler={logoutHandler} />
                )}
                <VersionPopover />
            </ul>
        </nav>
    );
};
export default ServersListBar;
