import { Trans } from '@lingui/macro';
import { useEffect, useRef } from 'react';

import { findDifferentObjects } from '@/Utils/findDifferentObjects';
import { sortMemberRoleList } from '@/Utils/memberUtils';
import { ReactComponent as AddIcon } from '@/assets/icons/add-outline.svg';
import closeIcon from '@/assets/icons/close-header.svg';
import { ReactComponent as TrashIcons } from '@/assets/icons/trash-2.svg';
import useUserModal from '@/components/connections-section/user-modal/useUserModal';
import { DEFAULT_IMAGES } from '@/constants';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './MemberRoleEdit.module.css';

function MemberRoleEdit(props: any) {
    const containerRef = useRef(null);

    const { isRolesModal, setIsRolesModal, selectedUserData, setIsDataChange } =
        props.modalProps;

    const useUserModalProps = {
        user: selectedUserData,
    };

    const userRoles = useAppSelector(state =>
        memberDataSelector(state).find(
            (el: any) => el.id === selectedUserData.id,
        ),
    );

    const rolesList = sortMemberRoleList(userRoles?.roles ?? []);

    const reduxRoleList = useAppSelector(
        state =>
            state.serverDataReducer.rolesList[
                state.serverDataReducer.activeServer
            ],
    );

    const { selectRole, deleteRole } = useUserModal(useUserModalProps);

    const unassignedRoles = findDifferentObjects(
        reduxRoleList,
        rolesList,
        '_id',
    );

    const closeModal = () => setIsRolesModal(false);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                closeModal();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef, isRolesModal]);

    const Header = () => (
        <div className={classes['left-side-header']}>
            <h2 className="my-0">
                <Trans>Roles Edit</Trans>
            </h2>
            <div
                onClick={() => setIsRolesModal(false)}
                className={classes['left-side-header-close']}>
                <img alt="close" src={closeIcon} />
            </div>
        </div>
    );

    return (
        <div ref={containerRef} className={classes['left-side-container']}>
            <div className={classes['left-side-inner-container']}>
                <Header />
                <div style={{ overflow: 'auto', paddingBottom: 24 }}>
                    <div className={classes['role-member-img-wrap']}>
                        <img
                            alt="profile"
                            className={classes['role-member-img']}
                            src={
                                selectedUserData?.profile_picture ||
                                DEFAULT_IMAGES.PROFILE_IMAGE
                            }
                        />
                        <p className={classes['main-info-text']}>
                            <b>
                                <Trans>Name</Trans>
                            </b>{' '}
                            : {selectedUserData?.name}
                            <br />
                            <b>
                                <Trans>Username</Trans>
                            </b>{' '}
                            : {selectedUserData?.username}
                            <br />
                            <b>
                                <Trans>Email</Trans>
                            </b>{' '}
                            : {selectedUserData?.email}
                        </p>
                    </div>
                    <div className={classes['assigned-roles']}>
                        <h2>
                            <Trans>Assigned Roles</Trans>
                        </h2>
                        {rolesList.length > 0 ? (
                            <div className={classes['role-container']}>
                                {rolesList.map((item, index) => (
                                    <div
                                        key={index}
                                        className={
                                            classes['role-container-row']
                                        }>
                                        <div
                                            style={{
                                                height: 16,
                                                width: 16,
                                                borderRadius: 999,
                                                backgroundColor: item.color,
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}>
                                            <span>{item.name}</span>
                                            <span
                                                className={
                                                    classes['remove-btn']
                                                }
                                                onClick={() =>
                                                    deleteRole(item)
                                                }>
                                                <TrashIcons width={12} />
                                                <Trans>Remove</Trans>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div
                                className={`${classes.redContainer} ${classes['role-container']}`}>
                                <div
                                    style={{ paddingTop: 4, paddingBottom: 4 }}>
                                    <Trans>No role assigned yet</Trans>
                                </div>
                            </div>
                        )}
                    </div>
                    {unassignedRoles.length >= 0 && (
                        <div
                            className={classes.unassignedRoles}
                            style={{ paddingTop: 8 }}>
                            <h2>
                                <Trans>Unassigned Roles</Trans>
                            </h2>
                            {unassignedRoles.length > 0 ? (
                                <div className={classes['role-container']}>
                                    {unassignedRoles?.map((item, index) => (
                                        <div
                                            key={index}
                                            className={
                                                classes['role-container-row']
                                            }>
                                            <div
                                                style={{
                                                    height: 16,
                                                    width: 16,
                                                    borderRadius: 999,
                                                    backgroundColor: item.color,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                    width: '100%',
                                                }}>
                                                <span>{item.name}</span>
                                                <span
                                                    onClick={() => {
                                                        selectRole(item._id);
                                                    }}
                                                    className={
                                                        classes['add-btn']
                                                    }>
                                                    <AddIcon width={14} />
                                                    <Trans>Add</Trans>
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div
                                    className={`${classes.redContainer} ${classes['role-container']}`}>
                                    <Trans>No role found to be assigned</Trans>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MemberRoleEdit;
