import { createSlice } from '@reduxjs/toolkit';

const WSSlice = createSlice({
    name: 'WSSlice',
    initialState: {
        state: false,
        data: [],
    },
    reducers: {
        messageInRange(state, action) {
            state.state = action.payload.state;
            state.data = action.payload.data;
        },
    },
});
export const wsActions = WSSlice.actions;

export default WSSlice.reducer;
