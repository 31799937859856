import React, {
    CSSProperties,
    HTMLAttributes,
    ReactNode,
    useState,
} from 'react';

import { DEFAULT_IMAGES } from '@/constants';

import Badge from '../badge/Badge';

import classes from './Icon.module.css';

interface propsType extends HTMLAttributes<HTMLDivElement> {
    imageURL?: string;
    onClick?: (event) => void;
    imgStyle?: CSSProperties;
    name?: string;
    imgClass?: string;
    hasBadge?: boolean;
    badgeStyle?: CSSProperties;
    badgeClass?: string;
    children?: ReactNode;
}

const Icon = React.forwardRef<HTMLImageElement, propsType>((props, ref) => {
    const {
        onClick,
        imageURL,
        name = 'icon',
        className = '',
        imgClass = '',
        badgeClass = '',
        children,
    } = props;

    const [imgSrc, setImgSrc] = useState<string>(
        imageURL ?? DEFAULT_IMAGES.SERVER_IMAGE,
    );
    const clickHandler = event => {
        if (onClick) {
            onClick(event);
        }
    };

    const handleImageError = () => {
        setImgSrc(DEFAULT_IMAGES.PROFILE_IMAGE);
    };

    return (
        <div
            className={`${classes['Icon-container']} ${className}`}
            onClick={clickHandler}>
            <img
                ref={ref}
                style={props?.imgStyle}
                src={imgSrc}
                alt={name ? name : 'icon'}
                className={imgClass}
                onError={handleImageError}
            />
            {props.hasBadge && (
                <Badge
                    style={props?.badgeStyle}
                    className={`${classes['Icon-badge']} ${badgeClass}`}>
                    {children}
                </Badge>
            )}
        </div>
    );
});

export default Icon;
