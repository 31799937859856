const SettingIcon = ({ fill, className = '', onClick = () => {} }) => {
    return (
        <svg
            onClick={onClick}
            className={className}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3999 6.14667C12.1933 6.14667 11.6999 5.29333 12.2999 4.24667C12.6466 3.64 12.4399 2.86667 11.8333 2.52L10.6799 1.86C10.1533 1.54666 9.47325 1.73333 9.15992 2.26L9.08659 2.38666C8.48659 3.43333 7.49992 3.43333 6.89325 2.38666L6.81992 2.26C6.51992 1.73333 5.83992 1.54666 5.31325 1.86L4.15992 2.52C3.55325 2.86667 3.34659 3.64667 3.69325 4.25333C4.29992 5.29333 3.80659 6.14667 2.59992 6.14667C1.90659 6.14667 1.33325 6.71333 1.33325 7.41333V8.58667C1.33325 9.28 1.89992 9.85333 2.59992 9.85333C3.80659 9.85333 4.29992 10.7067 3.69325 11.7533C3.34659 12.36 3.55325 13.1333 4.15992 13.48L5.31325 14.14C5.83992 14.4533 6.51992 14.2667 6.83325 13.74L6.90659 13.6133C7.50659 12.5667 8.49325 12.5667 9.09992 13.6133L9.17325 13.74C9.48659 14.2667 10.1666 14.4533 10.6933 14.14L11.8466 13.48C12.4533 13.1333 12.6599 12.3533 12.3133 11.7533C11.7066 10.7067 12.1999 9.85333 13.4066 9.85333C14.0999 9.85333 14.6733 9.28667 14.6733 8.58667V7.41333C14.6666 6.72 14.0999 6.14667 13.3999 6.14667ZM7.99992 10.1667C6.80659 10.1667 5.83325 9.19333 5.83325 8C5.83325 6.80666 6.80659 5.83333 7.99992 5.83333C9.19325 5.83333 10.1666 6.80666 10.1666 8C10.1666 9.19333 9.19325 10.1667 7.99992 10.1667Z"
                fill={fill ? fill : 'white'}
            />
        </svg>
    );
};
export default SettingIcon;
