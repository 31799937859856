import { t, Trans } from '@lingui/macro';
import { useEffect, useRef, useState } from 'react';

import { AppButton } from '@/UI';
import FilterIcon from '@/assets/icons/filter-icon.svg';


import AuditLogDateFilter from '../AuditLogDateFilter';

import classes from './LogTypeFilterModal.module.css';

type LogTypeFilterModalProps = {
    isLogTypeCheckboxSelected: any;
    onChangeCheckboxLogType: any;
    allSelectLogTypeCheckbox: any;
    allClearLogTypeCheckbox: any;
    checkboxData: any;
    // date
    onChangeStartDate: any;
    onChangeEndDate: any;
    startDate: any;
    endDate: any;
    allClearDateFilter: any;
};

function LogTypeFilterModal(props: LogTypeFilterModalProps) {
    const {
        isLogTypeCheckboxSelected,
        onChangeCheckboxLogType,
        allSelectLogTypeCheckbox,
        allClearLogTypeCheckbox,
        checkboxData,
        // date
        onChangeStartDate,
        onChangeEndDate,
        startDate,
        endDate,
        allClearDateFilter,
    } = props;

    const DateFilterProps = {
        onChangeStartDate,
        onChangeEndDate,
        startDate,
        endDate,
    };

    const [isOpencheckboxModal, setIsOpenCheckboxModal] = useState(false);

    const modalRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = event => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsOpenCheckboxModal(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <>
            <img
                alt=""
                style={{
                    filter: isOpencheckboxModal && 'brightness(99)',
                    opacity: !isOpencheckboxModal && 1,
                }}
                className={classes['filter-container-img']}
                onClick={() => setIsOpenCheckboxModal(prev => !prev)}
                src={FilterIcon}
            />
            <div
                style={{
                    transform: isOpencheckboxModal
                        ? 'translateX(0px)'
                        : 'translateX(100%)',
                    transition: 'transform 0.3s ease',
                }}
                ref={modalRef}
                className={classes['modal-container']}>
                <div className="">
                    <AuditLogDateFilter {...DateFilterProps} />
                </div>
                <div className={classes['filter-items-container']}>
                    <div className={classes['filter-items-header']}>
                        <div className={classes['header-title']}>
                            <Trans>Filter</Trans>
                        </div>
                        <div
                            className={classes['header-subtitle']}
                            onClick={() => allSelectLogTypeCheckbox()}>
                            <Trans>Select All</Trans>
                        </div>
                    </div>
                    <div className={classes['filter-items-scroll-area']}>
                        {['ban', 'channel', 'role', 'section', 'more'].map(
                            (itemType, indexType) => (
                                <div
                                    className={classes['filter-item-row']}
                                    key={indexType}>
                                    <div
                                        className={
                                            classes['filter-item-label']
                                        }>
                                        {itemType}
                                    </div>
                                    <div
                                        className={
                                            classes['filter-items-container']
                                        }>
                                        {checkboxData?.map(
                                            (item, index) =>
                                                item?.groupType ===
                                                    itemType && (
                                                    <div
                                                        className=""
                                                        key={index}>
                                                        <input
                                                            checked={isLogTypeCheckboxSelected(
                                                                item,
                                                            )}
                                                            type="checkbox"
                                                            id={`role-checkbox-${item?.value}`}
                                                            name={`role-checkbox-${item?.value}`}
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                            onChange={e => {
                                                                e.preventDefault();
                                                                onChangeCheckboxLogType(
                                                                    item,
                                                                );
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor={`role-checkbox-${item?.value}`}>
                                                            <div
                                                                className={
                                                                    isLogTypeCheckboxSelected(
                                                                        item,
                                                                    )
                                                                        ? classes[
                                                                              'filter-item-active'
                                                                          ]
                                                                        : classes[
                                                                              'filter-item'
                                                                          ]
                                                                }>
                                                                {item?.label}
                                                            </div>
                                                        </label>
                                                    </div>
                                                ),
                                        )}
                                    </div>
                                </div>
                            ),
                        )}
                    </div>
                </div>
                <div className={classes['bottom-buttons-container']}>
                    <AppButton
                        style={{ width: '100%' }}
                        title={t`Clear All Filter`}
                        onClick={() => {
                            allClearLogTypeCheckbox();
                            allClearDateFilter();
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default LogTypeFilterModal;
