import { formatCreatedDate } from '@/Utils/utils';

import classes from '../TableMemberManagement.module.css';

type IProps = {
    item: string | undefined;
};
const CreatedAtColumn: React.FC<IProps> = ({ item }) => {
    return <span className={classes['email']}>{formatCreatedDate(item)}</span>;
};
export default CreatedAtColumn;
