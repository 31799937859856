import { Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';

import classes from './Pagination.module.css';

const Pagination = ({ pagesArray, itemsPerPage, pageRange, onChangePage }) => {
    const [pageNumber, setPageNumber] = useState(0);

    const nextPage = () => {
        if (pageNumber < Math.ceil(pagesArray.length / itemsPerPage) - 1) {
            setPageNumber(prevPageNumber => prevPageNumber + 1);
        }
    };

    const prevPage = () => {
        if (pageNumber > 0) {
            setPageNumber(prevPageNumber => prevPageNumber - 1);
        }
    };

    const goToPageNumber = pageNumber => {
        setPageNumber(pageNumber);
    };

    const totalPages = Math.ceil(pagesArray.length / itemsPerPage);

    const pageNumbers = Array.from(
        { length: totalPages },
        (_, index) => index + 1,
    );

    useEffect(() => {
        if (pagesArray?.length <= 0) {
            setPageNumber(0);
        }
    }, [pagesArray]);

    useEffect(() => {
        if (pagesArray.length > itemsPerPage) {
            const slicedResults = pagesArray.slice(
                pageNumber * itemsPerPage,
                (pageNumber + 1) * itemsPerPage,
            );
            onChangePage(slicedResults);
        } else {
            onChangePage(pagesArray);
        }
    }, [pageNumber, pagesArray]);

    return (
        <div className={classes.pagination}>
            {totalPages > 1 && (
                <>
                    <p className={classes['par']} onClick={prevPage}>
                        <Trans>Prev</Trans>
                    </p>

                    <ul className={classes['page-numbers']}>
                        {pageNumber - pageRange > 1 && (
                            <>
                                <li onClick={() => goToPageNumber(0)}>1</li>
                                <li>...</li>
                            </>
                        )}
                        {pageNumbers.map((page, index) => {
                            const isActive = index === pageNumber;
                            const isInRange =
                                index <= pageNumber + pageRange &&
                                index >= pageNumber - pageRange;

                            const numberClass = isActive
                                ? classes.activePage
                                : isInRange
                                ? classes['in-range']
                                : classes['pageNumber'];

                            return (
                                <li
                                    key={index}
                                    className={numberClass}
                                    onClick={() => goToPageNumber(index)}>
                                    {page}
                                </li>
                            );
                        })}
                        {pageNumber + pageRange < totalPages - 2 && (
                            <>
                                <li>...</li>
                                <li
                                    onClick={() =>
                                        goToPageNumber(totalPages - 1)
                                    }>
                                    {totalPages}
                                </li>
                            </>
                        )}
                    </ul>

                    <p className={classes['par']} onClick={nextPage}>
                        <Trans>Next</Trans>
                    </p>
                </>
            )}
        </div>
    );
};
export default Pagination;
