import { memo } from 'react';

import formatFileSize from '@/Utils/fileSzeFormatter';
import { truncateFileName } from '@/Utils/truncateFileName';
import DownloadedTypeIcon from '@/components/download-btn/DownloadedTypeIcon';

import classes from './ReplyMedia.module.css';

type Props = {
    size: number;
    name: string;
    type: string | undefined;
    className?: string;
    truncateLength?: number;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};
const UnknowMedia: React.FC<Props> = props => {
    const {
        size,
        name,
        type,
        className = '',
        truncateLength = null,
        onClick = () => {},
    } = props;
    const outputFileName = truncateFileName(name, truncateLength);

    return (
        <div
            className={`${className} ${classes['reply-media-wrap']}`}
            onClick={onClick}>
            <div className={classes['header']}>
                <DownloadedTypeIcon type={type} />
                <p className={classes['label-size']}>
                    {size ? `${formatFileSize(+size)}` : '- MB'}
                </p>
            </div>
            <p className={classes['label-name']}>{outputFileName}</p>
        </div>
    );
};
export default memo(UnknowMedia);
