import { t } from '@lingui/macro';

import later from '@/assets/icons/later.svg';
import offline from '@/assets/icons/offline.svg';
import online from '@/assets/icons/online.svg';
import resting from '@/assets/icons/resting.svg';

export type StatusType = {
    id: number;
    name: string;
    color: string;
    icon: HTMLOrSVGElement;
};

export const status = {
    online: {
        id: 1,
        name: t`Available`,
        color: 'var(--green)',
        icon: online,
    },
    offline: {
        id: 2,
        name: t`Can't Speak`,
        color: 'var(--status-offline)',
        icon: offline,
    },
    later: {
        id: 3,
        name: t`Will Come Back`,
        color: 'var(--status-later)',
        icon: later,
    },
    resting: {
        id: 4,
        name: t`Resting`,
        color: 'var(--status-resting)',
        icon: resting,
    },
};
export const getUserStatus = () => {
    return {
        online: {
            id: 1,
            name: t`Available`,
            color: 'var(--green)',
            icon: online,
        },
        offline: {
            id: 2,
            name: t`Can't Speak`,
            color: 'var(--status-offline)',
            icon: offline,
        },
        later: {
            id: 3,
            name: t`Will Come Back`,
            color: 'var(--status-later)',
            icon: later,
        },
        resting: {
            id: 4,
            name: t`Resting`,
            color: 'var(--status-resting)',
            icon: resting,
        },
    };
};
export const statusById = {
    1: {
        id: 1,
        name: t`Available`,
        color: 'var(--green)',
        icon: online,
    },
    2: {
        id: 2,
        name: t`Can't Speak`,
        color: 'var(--status-offline)',
        icon: offline,
    },
    3: {
        id: 3,
        name: t`Will Come Back`,
        color: 'var(--status-later)',
        icon: later,
    },
    4: {
        id: 4,
        name: t`Resting`,
        color: 'var(--status-resting)',
        icon: resting,
    },
};
