import { useEffect, useState } from 'react';

import { ReactComponent as SaveIcon } from '@/assets/icons/save.svg';

import classes from './SaveBtn.module.css';

type Props = {
    src?: string;
    name?: string;
    className?: string;
};
const SaveBtn: React.FC<Props> = props => {
    const { src, name, className = '' } = props;
    const [saved, setSaved] = useState<boolean>(false);

    useEffect(() => {
        if (saved) {
            const timer = setTimeout(() => {
                setSaved(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [saved]);

    return (
        <a
            href={src}
            download={name}
            onClick={e => {
                e.stopPropagation();
                setSaved(true);
            }}
            className={`${saved && classes['disable-link']} ${
                classes['dl-button']
            } ${className} `}>
            <SaveIcon
                height={16}
                width={16}
                className={`${saved && classes['saved']} ${
                    classes['icon-save']
                }`}
            />
        </a>
    );
};
export default SaveBtn;
