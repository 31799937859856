import classes from './TableStatistics.module.css';
import message from '@/assets/icons/message-send.svg';

const MessagesCol: React.FC<{ item }> = ({ item }) => {
    return (
        <div className={classes['message-wrapper']}>
            <img src={message} alt="" />
            <span>{item?.totalConversations}</span>
        </div>
    );
};
export default MessagesCol;
