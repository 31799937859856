import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';

import { sendWebSocketData } from '../../webSocketUtils';

export const handleGetStatisticsRequest = (
    date_from: string,
    date_to: string,
    page_number: number,
    per_page: number,
    selectedCheckboxUsersData: any[],
) => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.getStatistics.action,
        data: {
            date_from: date_from,
            date_to: date_to,
            page_number: page_number,
            per_page: per_page,
            user_ids: selectedCheckboxUsersData?.map(item => item?.id),
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};
