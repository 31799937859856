import { sendWebSocketData } from '@/Utils/webSocketUtils';
import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';

function useChannelUserPermissions() {
    type CreateProps = {
        user_id?: string;
        role_id?: string;
        permissions?: [{ key: string; value: boolean }];
        is_role?: boolean;
        everyone?: boolean;
    };

    type GetProps = {
        user_id?: string;
        role_id?: string;
        channel_id?: string;
        everyone?: boolean;
    };

    const handleGetChannelUsersPermissions = (props: GetProps) => {
        const { user_id, role_id, channel_id, everyone } = props;
        let data = {
            channel_id,
        };
        if (user_id) {
            data.user_id = user_id;
        }
        if (role_id) {
            data.role_id = role_id;
        }
        if (everyone) {
            data.everyone = everyone;
        }
        const payload = {
            action: SOCKET_ACTIONS_WITH_TYPES.getChannelUsersPermissions.action,
            data,
        };
        sendWebSocketData(JSON.stringify(payload));
    };

    const handleCreateChannelUsersPermissions = (props: CreateProps) => {
        const { user_id, role_id, permissions, everyone, is_role } = props;
        let data;

        if (user_id) {
            data = { all_data: [{ user_id, permissions }] };
        }
        if (role_id) {
            data = { all_data: [{ role_id, permissions }] };
        }
        if (everyone) {
            data = {
                everyone: permissions,
            };
        }

        const payload = {
            action: SOCKET_ACTIONS_WITH_TYPES.createChannelUsersPermissions
                .action,
            data: { ...data, is_role },
        };

        sendWebSocketData(JSON.stringify(payload));
    };

    return {
        handleGetChannelUsersPermissions,
        handleCreateChannelUsersPermissions,
    };
}

export default useChannelUserPermissions;
