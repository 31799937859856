import { t } from '@lingui/macro';
import { FC, RefObject, useState } from 'react';
import { useDispatch } from 'react-redux';

import { handleMarkAllRead } from '@/Utils/socket-actions/ChannelActions/channelActions';
import ManagementModal from '@/components/ManagementModal/ManagementModal';
import EditSubServer from '@/components/create-server/EditSubServer';
import { optionTypes } from '@/constants/subServerOptions';
import { useCustomSweetAlert } from '@/hooks';
import { notesActions } from '@/store/notes-reducer';
import { p2pActions } from '@/store/p2p-slice';
import { updatePrivateKey } from '@/store/server-data-reducer/server-data-reducer';
import { setChannelListEditModeToggle } from '@/store/util-reducer';

import ServerDropDown from './server-dropdown-options/ServerDropDown';
import CreateChannel from './server-dropdown-options/create-channel/CreateChannel';
import CreateSection from './server-dropdown-options/create-section/CreateSection';

type Props = {
    toggleSettings: (state: boolean) => void;
    showSettings: boolean;
    id: string;
    dropdownRef: RefObject<HTMLImageElement>;
};

const ServerBannerModals: FC<Props> = props => {
    const dispatch = useDispatch();
    const { areYouSureModal } = useCustomSweetAlert();

    const [openModal, setOpenModal] = useState<null | string>(null);

    const changeKeys = () => {
        areYouSureModal(t`Change Keys`, '', false, () =>
            dispatch(
                updatePrivateKey({
                    id: props.id,
                    defaultHashkey: undefined,
                    privateKey: undefined,
                    publicKey: undefined,
                }),
            ),
        );
    };

    const selectHandler = (option: optionTypes) => {
        props.toggleSettings(false);
        switch (option.id) {
            case 'p2pconnections':
                dispatch(notesActions.toggleNotesWindow(false));
                dispatch(p2pActions.toggleP2PPanel({ state: true }));
                break;
            case 'createChannel':
                setOpenModal(option.id);
                break;
            case 'editServer':
                setOpenModal(option.id);
                break;
            case 'createSection':
                setOpenModal(option.id);
                break;
            case 'management':
                setOpenModal(option.id);
                break;
            case 'changeKeys':
                changeKeys();
                break;
            case 'channelListEditMode':
                dispatch(setChannelListEditModeToggle());
                break;
            case 'leaveServer':
                console.log('LEAVE SERVER');
                break;
            case 'allRead':
                handleMarkAllRead();
                break;
            default:
                console.log('Unknown Select');
                break;
        }
    };

    return (
        <>
            {props.showSettings && (
                <ServerDropDown
                    passedRef={props.dropdownRef}
                    onSelect={selectHandler}
                    onClose={() => props.toggleSettings(false)}
                />
            )}
            {openModal === 'createChannel' && (
                <CreateChannel onClose={() => setOpenModal(null)} />
            )}
            {openModal === 'createSection' && (
                <CreateSection onClose={() => setOpenModal(null)} />
            )}
            {openModal === 'management' && (
                <ManagementModal onClose={() => setOpenModal(null)} />
            )}
            {openModal === 'editServer' && (
                <EditSubServer onClose={() => setOpenModal(null)} />
            )}
        </>
    );
};
export default ServerBannerModals;
