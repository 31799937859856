import { useContext } from 'react';

import { Icon, Line } from '@/UI';
import DateIndicator from '@/UI/date-indicator/DateIndicator';
import { truncateText } from '@/Utils/textUtils';
import { DEFAULT_IMAGES } from '@/constants';
import { useAppSelector } from '@/hooks/useRedux';
import {
    channelDataSelector,
    memberDataSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import MessageContent from '../messages/message-item/message-content/MessageContent';
import RenderReactions from '../messages/message-item/render-reactions/RenderReactions';

import classes from './Search.module.css';
import { SearchContext } from './SearchContext';

type IProps = {
    result: any;
    index: number | string;
};
const SearchMessageItem: React.FC<IProps> = props => {
    const { result, index } = props;

    const context = useContext(SearchContext);
    const { clickMessageHandler, user ,members,channel} = context;    
    const date = new Date(result.created_at);
    const messageChannel = channel.find(ch => ch._id === result.channel_id);
    const sender = members.find(
        e => e.id === (result?.user_id ?? result?.sender_id),
    );
    return (
        <div>
            <li
                key={index}
                className={classes['search-item']}
                onClick={() => clickMessageHandler(result)}
                id={result._id}>
                <div className={classes['d-flex']}>
                    <Icon
                        className={classes['icon']}
                        imageURL={
                            sender
                                ? sender?.profile_picture
                                : DEFAULT_IMAGES.PROFILE_IMAGE
                        }
                        name={sender ? truncateText(sender?.name, 25) : ''}
                    />
                    <div style={{ width: '100%' }}>
                        <div className={classes['d-flex']}>
                            <p className={classes['message']}>
                                {sender ? truncateText(sender?.name, 25) : ''}
                                {messageChannel && (
                                    <span
                                        className={
                                            messageChannel.privacy === 'direct'
                                                ? classes['message-private']
                                                : classes['message-channel']
                                        }>
                                        {messageChannel.privacy === 'channel'
                                            ? messageChannel.name
                                            : 'Direct Chat'}
                                    </span>
                                )}
                                <span
                                    style={{
                                        color: result?.thread_id
                                            ? 'var(--date-color)'
                                            : undefined,
                                    }}
                                    className={classes['channel']}>
                                    {result?.thread_id && '[THREAD]'}
                                </span>
                            </p>
                            <DateIndicator
                                fullDate
                                date={date}
                                className={classes.date}
                            />
                        </div>
                        <MessageContent
                            mentionClickable={false}
                            channel_mentions={result?.channel_mentions}
                            role_mentions={result?.role_mentions}
                            messageId={result?._id}
                            message={
                                result.message?.content
                                    ? result.message.content
                                    : result?.message ?? result?.text
                            }
                        />
                        <RenderReactions
                            clickReactionHandler={() => {}}
                            user={user}
                            members={members}
                            reactions={result?.reactions}
                        />
                    </div>
                </div>
            </li>
            <Line />
        </div>
    );
};
export default SearchMessageItem;
