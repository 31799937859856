import { RootState } from '@/store';
import { IMemberData, IRole } from '@/types';

import { getStoreState } from './dataHandler';
import { getChannelDetails_activeServer } from './serverDataHandlers';
import { isWebSocketConnected, sendWebSocketData } from './webSocketUtils';

//GET HIEST ROLE OF MEMBER
export const getHighestMemberRole = (user: IMemberData): IRole => {
    if (user === undefined) return;
    const store: RootState = getStoreState();

    const roleList =
        store.serverDataReducer.rolesList[store.serverDataReducer.activeServer];
    if (user?.roles?.length > 0) {
        const highestRole = user?.roles
            ?.map(roleId => {
                const role = roleList?.find(r => r?._id === roleId);
                return role;
            })
            ?.sort((a, b) => a.priority - b.priority)?.[0];

        return highestRole;
    }
};

//SORT MEMBER ROLE LIST ACCORDING TO PRIORITY
export const sortMemberRoleList = (memberRoleList: string[]): IRole[] => {
    if (memberRoleList?.length === 0) return [];
    const store: RootState = getStoreState();

    const rolesList =
        store.serverDataReducer.rolesList[store.serverDataReducer.activeServer];
    const sortList = memberRoleList
        ?.map(roleId => {
            const role = rolesList?.find(r => r?._id === roleId);
            return role;
        })
        ?.sort((a, b) => a.priority - b.priority);

    return sortList;
};

// DONOT DELETE

// export const getDirectChannelName = channel_id => {
//     const store: RootState = getStoreState();
//     const server: IServerData =
//         store.serverDataReducer.serverData[
//             store.serverDataReducer.activeServer
//         ];
// };

export const getChannelMemberList = (channel_id?: string): IMemberData[] => {
    const channelDetails = getChannelDetails_activeServer(channel_id);
    const store: RootState = getStoreState();
    const memberList =
        store.serverDataReducer?.memberList[
            store?.serverDataReducer?.activeServer
        ];
    const channelMembersList = [];
    if (channelDetails?.memberList?.length > 0)
        channelDetails?.memberList?.forEach(item => {
            memberList[item] && channelMembersList.push(memberList[item]);
        });
    else {
        isWebSocketConnected() &&
            channel_id &&
            sendWebSocketData(
                JSON.stringify({
                    action: 'channel_member',
                    data: { channel_id: channel_id },
                }),
            );
    }
    return channelMembersList ?? [];
};

export const getMembersbyRoles = (
    members: IMemberData[],
): {
    groupedMembers: (IRole & { members: IMemberData[] })[];
    membersWithoutRoles: IMemberData[];
} => {
    const rolesMap = new Map();
    const store: RootState = getStoreState();

    const membersWithoutRoles = [];
    const rolesList =
        store.serverDataReducer.rolesList[store.serverDataReducer.activeServer];

    if (!members) return;
    // Iterate through members and update the map with highest priority roles
    members.forEach(member => {
        const highestRole = getHighestMemberRole(member);
        if (member?.roles?.length === 0 || !highestRole?.display_separately) {
            membersWithoutRoles.push(member); // Add members without roles to the array
        } else {
            const role = rolesList?.find(r => r?._id === highestRole._id);
            if (role) {
                if (!rolesMap.has(role._id)) {
                    rolesMap.set(role._id, {
                        ...role,
                        members: [],
                    });
                }
                rolesMap.get(role._id).members.push(member);
            }
        }
    });
    // Convert the map values back to an array
    const groupedMembers = Array.from(rolesMap.values())?.sort(
        (a, b) => a.priority - b.priority,
    );
    return { groupedMembers, membersWithoutRoles };
};
