import { Trans } from '@lingui/macro';

import classes from './NewChat.module.css';

const Empty: React.FC<{}> = () => {
    return (
        <p className={classes['no-results']}>
            <Trans>No Results</Trans>
        </p>
    );
};
export default Empty;
