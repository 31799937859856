import { Trans, t } from '@lingui/macro';
import { useEffect, useRef, useState } from 'react';

import { AppButton, Modal } from '@/UI';
import CustomInput from '@/UI/custom-input/CustomInput';
import CustomTextArea from '@/UI/custom-text-area/CustomTextArea';
import {
    deleteSection,
    editSection,
} from '@/Utils/socket-actions/ChannelActions/channelActions';
import trashIcon from '@/assets/icons/trash-red.svg';
import { BUTTON_TYPE } from '@/constants/enum';

import classes from './EditSection.module.css';

const EditSection = props => {
    const editSecRef = useRef(null);
    const descriptionRef = useRef(null);

    const [canSubmit, setCanSubmit] = useState(false);
    const [name, setName] = useState(props.name ? props.name : '');

    const submitEditSectionHandler = event => {
        event.preventDefault();

        const payload = {
            section_id: props.id,
            name: editSecRef.current.value.trim(),
            description: descriptionRef.current.value.trim(),
        };
        editSection(payload);
        props.onClose();
    };
    const deleteSectionHandler = () => {
        deleteSection(props.id);
        props.onClose();
    };

    useEffect(() => {
        if (name.trim() !== '') {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [name]);

    return (
        <Modal
            className={classes.modal}
            header={t`Edit Section`}
            onClose={() => props.onClose()}>
            <form onSubmit={submitEditSectionHandler}>
                <p>
                    <Trans>Section name</Trans>
                </p>
                <CustomInput
                    ref={editSecRef}
                    className={classes['input-field']}
                    value={name}
                    onChange={event => setName(event.target.value)}
                    id={props.id}
                />
                <div>
                    <br />
                </div>
                <CustomTextArea
                    ref={descriptionRef}
                    label={t`description`}
                    name="server-description-input"
                    id="sectionDescriptionInput"
                    defaultValue={props.description}
                    className={classes['input-field']}
                    placeholder={t`Provide Informative content about this section`}
                />
                <div>
                    <br />
                </div>

                <div className={classes['d-flex']}>
                    <AppButton
                        onClick={deleteSectionHandler}
                        title={
                            <div className={classes['d-flex']}>
                                <img
                                    alt={t`delete`}
                                    src={trashIcon}
                                    className={classes['trash-icon']}
                                />
                                <Trans>Delete</Trans>
                            </div>
                        }
                        type="button"
                        buttonType={BUTTON_TYPE.transparent}
                        classStyles={classes['btn']}
                    />
                    <AppButton
                        type="submit"
                        title={t`Save`}
                        disable={!canSubmit}
                        classStyles={classes['btn']}
                    />
                </div>
            </form>
        </Modal>
    );
};
export default EditSection;
