import { createSlice } from '@reduxjs/toolkit';

const initialState = { activeTab: 0 };
const mobileViewSlice = createSlice({
    name: 'mobile-view',
    initialState,
    reducers: {
        changeActiveTab(state, { payload }) {
            state.activeTab = payload;
        },
    },
});

export default mobileViewSlice.reducer;
export const mobileViewActions = mobileViewSlice.actions;
