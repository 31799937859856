import React from 'react';

import closeSvg from '@/assets/icons/Closetitle.svg';
import lineSvg from '@/assets/icons/Line.svg';
import sqrSvg from '@/assets/icons/Square.svg';
import logo from '@/assets/icons/chat-logo-huge.svg';

import classes from './DestopTitleBar.module.css';

type Props = {};

const DesktopTitleBar = (props: Props) => {
    const minimizeHandler = () => {
        try {
            window.require('electron').ipcRenderer.send('minimize');
        } catch (e) {}
    };

    const resizeHandler = () => {
        try {
            window.require('electron').ipcRenderer.send('maximize');
        } catch (e) {}
    };

    const closeHandler = () => {
        try {
            window.require('electron').ipcRenderer.send('exit');
        } catch (e) {}
    };

    return (
        <div className={classes['title-bar']}>
            <div className={classes['title-wrapper']}>
                <img alt="" src={logo} className={classes['icon']} />
                <div className={classes['title']}>Covchat</div>
            </div>
            <div className={classes['window-buttons']}>
                <div className={classes['button']} onClick={minimizeHandler}>
                    <img alt="" src={lineSvg} className={classes['icon']} />
                </div>
                <div className={classes['button']} onClick={resizeHandler}>
                    <img alt="" src={sqrSvg} className={classes['icon']} />
                </div>
                <div className={classes['button']} onClick={closeHandler}>
                    <img alt="" src={closeSvg} className={classes['icon']} />
                </div>
            </div>
        </div>
    );
};

export default DesktopTitleBar;
