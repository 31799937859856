import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';

import { sendWebSocketData } from '@/Utils/webSocketUtils';
import { AUDIT_LOGS_TYPE } from '@/constants/enum';
import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';
import { useAppSelector } from '@/hooks/useRedux';
import {
    channelDataSelector,
    publicChannelDataSelector,
    rolesListSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import AddedFromChannelIcon from './log-icons/added-from-channel.svg';
import BannedIcon from './log-icons/banned.svg';
import CreatedChannelIcon from './log-icons/created-channel.svg';
import CreatedSectionIcon from './log-icons/created-section.svg';
import DeletedChannelIcon from './log-icons/deleted-channel.svg';
import DeletedFromChannelIcon from './log-icons/deleted-from-channel.svg';
import DeletedSectionIcon from './log-icons/deleted-section.svg';
import EditedSectionIcon from './log-icons/edited-section.svg';
import RaportedIcon from './log-icons/raported.svg';

function useAuditLog() {
    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );
    const getAudit = useAppSelector(state => state.serverDataReducer.auditLogs);
    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );
    const channels = useAppSelector(state => channelDataSelector(state));

    const publicChannels = useAppSelector(state =>
        publicChannelDataSelector(state),
    );
    const channelList = channels.concat(publicChannels);
    const sectionList = useAppSelector(
        state =>
            state.serverDataReducer.sectionList[
                state.serverDataReducer.activeServer
            ],
    );
    const roleList = useAppSelector(rolesListSelector);

    const [visibleItems, setVisibleItems] = useState(20);
    const [pageIndex, setPageIndex] = useState(1);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [filteredData, setFilteredData] = useState(getAudit?.audit_log);
    const [metaData, setMetaData] = useState({});
    const [selectedCheckboxLogTypeData, setSelectedCheckboxLogTypeData] =
        useState([]);
    const [selectedCheckboxUsersData, setSelectedCheckboxUsersData] = useState(
        [],
    );

    useEffect(() => {
        if (getAudit) {
            setMetaData(getAudit?.meta_data);
        }
    }, [getAudit]);

    const today = new Date().toISOString().split('T')[0];
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(today);

    function isURL(str) {
        if (typeof str === 'string') {
            return str?.startsWith('http://') || str?.startsWith('https://');
        }
    }

    const handleGetAuditLog = (
        page_number,
        per_page,
        start_date,
        end_date,
        user_ids,
        log_types,
    ) => {
        const data = {
            subserver_id: activeServer,
            page_number,
            per_page,
        };

        if (start_date) {
            data.start_date = start_date;
        }
        if (end_date) {
            data.end_date = end_date;
        }
        if (user_ids?.length > 0) {
            data.user_ids = user_ids?.map(item => item?.id);
        }
        if (log_types?.length > 0) {
            data.log_types = log_types?.map(item => item?.value);
        }

        const payload = {
            action: SOCKET_ACTIONS_WITH_TYPES.getAuditLogs.action,
            data,
        };

        sendWebSocketData(JSON.stringify(payload));
    };

    const increasePageIndex = () => {
        setPageIndex(prev => prev + 1);
    };

    const handleScroll = event => {
        const target = event.currentTarget;
        const isBottom =
            target.scrollHeight - target.scrollTop <= target.clientHeight + 10;
        setIsAtBottom(isBottom);
    };

    // FILTER
    const checkboxData = [
        {
            value: AUDIT_LOGS_TYPE.BANNED,
            label: t`Banned`,
            groupType: 'ban',
        },
        {
            value: AUDIT_LOGS_TYPE.UNBANNED,
            label: t`Unbanned`,
            groupType: 'ban',
        },
        {
            value: AUDIT_LOGS_TYPE.CHANNEL_CREATE,
            label: t`Channel Create`,
            groupType: 'channel',
        },
        {
            value: AUDIT_LOGS_TYPE.CHANNEL_DELETE,
            label: t`Channel Delete`,
            groupType: 'channel',
        },
        {
            value: AUDIT_LOGS_TYPE.CHANNEL_EDIT,
            label: t`Channel Edit`,
            groupType: 'channel',
        },
        {
            value: AUDIT_LOGS_TYPE.UPDATE_PRIORITY,
            label: t`Update Priority`,
            groupType: 'more',
        },
        {
            value: AUDIT_LOGS_TYPE.ROLE_CREATE,
            label: t`Role Create`,
            groupType: 'role',
        },
        {
            value: AUDIT_LOGS_TYPE.ROLE_DELETE,
            label: t`Role Delete`,
            groupType: 'role',
        },
        {
            value: AUDIT_LOGS_TYPE.ROLE_UPDATE,
            label: t`Role Update`,
            groupType: 'role',
        },
        {
            value: AUDIT_LOGS_TYPE.ADD_USER_IN_SECTION,
            label: t`Add User in Section`,
            groupType: 'section',
        },
        {
            value: AUDIT_LOGS_TYPE.SECTION_CREATE,
            label: t`Section Create`,
            groupType: 'section',
        },
        {
            value: AUDIT_LOGS_TYPE.SECTION_DELETE,
            label: t`Section Delete`,
            groupType: 'section',
        },
        {
            value: AUDIT_LOGS_TYPE.SECTION_EDIT,
            label: t`Section Edit`,
            groupType: 'section',
        },
    ];

    // ROLE FILTER
    const isLogTypeCheckboxSelected = item => {
        return selectedCheckboxLogTypeData?.some(
            checkboxItem => checkboxItem?.value === item?.value,
        );
    };

    const onChangeCheckboxLogType = item => {
        if (
            selectedCheckboxLogTypeData?.some(
                checkboxItem => checkboxItem?.value === item?.value,
            )
        ) {
            setSelectedCheckboxLogTypeData(
                selectedCheckboxLogTypeData?.filter(
                    logType => logType?.value !== item?.value,
                ),
            );
        } else {
            setSelectedCheckboxLogTypeData([
                ...selectedCheckboxLogTypeData,
                item,
            ]);
        }
    };

    const onChangeStartDate = e => {
        setStartDate(e.target.value);
    };
    const onChangeEndDate = e => {
        setEndDate(e.target.value);
    };

    const allSelectLogTypeCheckbox = () => {
        setSelectedCheckboxLogTypeData(checkboxData);
    };

    const allClearLogTypeCheckbox = () => {
        setSelectedCheckboxLogTypeData([]);
    };

    const allClearDateFilter = () => {
        setStartDate('');
        setEndDate('');
    };

    useEffect(() => {
        handleGetAuditLog(
            pageIndex,
            visibleItems,
            startDate,
            endDate,
            selectedCheckboxUsersData,
            selectedCheckboxLogTypeData,
        );
    }, [
        startDate,
        endDate,
        pageIndex,
        visibleItems,
        selectedCheckboxLogTypeData,
        selectedCheckboxUsersData,
    ]);

    useEffect(() => {
        setPageIndex(1);
    }, [
        selectedCheckboxLogTypeData,
        selectedCheckboxUsersData,
        startDate,
        endDate,
    ]);

    const handleChangeData = data => {
        setSelectedCheckboxUsersData(data);
    };

    // FILTER

    return {
        increasePageIndex,
        setPageIndex,
        handleScroll,
        isURL,
        getAudit,
        memberList,
        channelList,
        sectionList,
        roleList,
        pageIndex,
        // FILTER
        filteredData,

        isLogTypeCheckboxSelected,
        onChangeCheckboxLogType,

        allSelectLogTypeCheckbox,
        allClearLogTypeCheckbox,
        checkboxData,

        allClearDateFilter,
        onChangeStartDate,
        onChangeEndDate,
        startDate,
        endDate,
        metaData,
        handleChangeData,
        selectedCheckboxUsersData,
    };
}

export default useAuditLog;

export const LOG_TYPE_MAPPING = {
    [AUDIT_LOGS_TYPE.BANNED]: {
        icon: BannedIcon,
        text: t`BANNED`,
        actionByType: 'member',
        targetActionType: 'member',
    },
    [AUDIT_LOGS_TYPE.UNBANNED]: {
        icon: BannedIcon,
        text: t`UNBANNED`,
        actionByType: 'member',
        targetActionType: 'member',
    },
    [AUDIT_LOGS_TYPE.CHANNEL_CREATE]: {
        icon: CreatedChannelIcon,
        text: t`CHANNEL CREATE`,
        actionByType: 'member',
        targetActionType: 'channel',
    },
    [AUDIT_LOGS_TYPE.CHANNEL_DELETE]: {
        icon: DeletedChannelIcon,
        text: t`CHANNEL DELETE`,
        actionByType: 'member',
        targetActionType: 'channel',
    },
    [AUDIT_LOGS_TYPE.CHANNEL_EDIT]: {
        icon: EditedSectionIcon,
        text: t`CHANNEL EDIT`,
        actionByType: 'member',
        targetActionType: 'channel',
    },
    [AUDIT_LOGS_TYPE.UPDATE_PRIORITY]: {
        icon: EditedSectionIcon,
        text: t`UPDATE PRIORITY`,
        actionByType: 'member',
        targetActionType: null,
    },
    [AUDIT_LOGS_TYPE.ROLE_CREATE]: {
        icon: AddedFromChannelIcon,
        text: t`ROLE CREATE`,
        actionByType: 'member',
        targetActionType: 'role',
    },
    [AUDIT_LOGS_TYPE.ROLE_DELETE]: {
        icon: DeletedFromChannelIcon,
        text: t`ROLE CREATE`,
        actionByType: 'member',
        targetActionType: 'role',
    },
    [AUDIT_LOGS_TYPE.ROLE_UPDATE]: {
        icon: EditedSectionIcon,
        text: t`ROLE UPDATE`,
        actionByType: 'member',
        targetActionType: 'role',
    },
    [AUDIT_LOGS_TYPE.ADD_USER_IN_SECTION]: {
        icon: AddedFromChannelIcon,
        text: t`ADD USER IN SECTION`,
        actionByType: 'member',
        targetActionType: 'section',
    },
    [AUDIT_LOGS_TYPE.SECTION_CREATE]: {
        icon: CreatedSectionIcon,
        text: t`SECTION CREATE`,
        actionByType: 'member',
        targetActionType: 'section',
    },
    [AUDIT_LOGS_TYPE.SECTION_DELETE]: {
        icon: DeletedSectionIcon,
        text: t`SECTION DELETE`,
        actionByType: 'member',
        targetActionType: 'section',
    },
    [AUDIT_LOGS_TYPE.SECTION_EDIT]: {
        icon: EditedSectionIcon,
        text: t`SECTION EDIT`,
        actionByType: 'member',
        targetActionType: 'section',
    },
};
