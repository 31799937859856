import { openDB } from 'idb';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { exportKeyToJWK } from '@/Utils/cryptoUtils';

import { useAppSelector } from './useRedux';

async function generateRSAKeyPair() {
    try {
        // Generate an RSA key pair with the following parameters:
        const keyPair = await window.crypto.subtle.generateKey(
            {
                name: 'RSA-OAEP', // Use RSA with OAEP padding
                publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
                modulusLength: 4096,
                hash: { name: 'SHA-256' }, // Use the SHA-256 hash function
            },
            true, // Allow both encryption and decryption operations with the generated keys
            ['encrypt', 'decrypt'], // Specify that we need the keys for encryption and decryption
        );

        // Export the generated publicKey and privateKey to JWK format
        const jwkPublic = await exportKeyToJWK(keyPair.publicKey);
        const jwkPrivate = await exportKeyToJWK(keyPair.privateKey);

        // Return the JWK format of the publicKey and privateKey as an object
        return { publicKey: jwkPublic, privateKey: jwkPrivate };
    } catch (err) {
        // Handle any errors that may occur during key generation
        console.error(err);
    }
}
const useIndexedDBUuid = () => {
    const user_id = useAppSelector(state => state.userReducer.user.id);

    const initializeDatabase = async () => {
        const db = await openDB('uuidDatabase', 6, {
            upgrade(db, oldVersion, newVersion, transaction, event) {
                if (db.objectStoreNames.contains('uuidStore')) {
                    db.deleteObjectStore('uuidStore');
                }
                const uuidStore = db.createObjectStore('uuidStore', {
                    keyPath: 'uuid',
                });
                uuidStore.createIndex('uuidIndex', 'uuid');
            },
        });

        const uuids = await db.getAll('uuidStore');

        if (!uuids || uuids.length === 0) {
            // If no data exists, create a new record
            await addNewRecord(db, user_id);
        } else {
            const existingUser = uuids.find(item => item.user_id === user_id);
            if (!existingUser) {
                await clearDatabase(db);
                await addNewRecord(db, user_id);
            }
        }
    };

    const addNewRecord = async (db, user_id) => {
        const newUuid = uuidv4();
        const keyPair = await generateRSAKeyPair();
        const keypair = {
            publicKey: keyPair.publicKey,
            privateKey: keyPair.privateKey,
        };
        await db.add('uuidStore', {
            uuid: newUuid,
            user_id: user_id,
            keypair: keypair,
        });
    };

    const clearDatabase = async db => {
        await db.clear('uuidStore');
    };

    useEffect(() => {
        if (user_id) {
            initializeDatabase();
        }
    }, [user_id]);
};

export default useIndexedDBUuid;
