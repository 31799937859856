import { Trans, t } from '@lingui/macro';
import { useFormik } from 'formik';
import { useRef, useState } from 'react';
import { Spinner } from 'react-activity';

import { AppButton, TextInput } from '@/UI';
import MediaInput from '@/UI/media-input/MediaInput';
import fetchData from '@/Utils/fetchData';
import { handleUserStatusUpdate } from '@/Utils/socket-actions/UserActions/userActions';
import useStatusHelper from '@/Utils/useStatusHelper';
import { BUTTON_SIZE } from '@/constants/enum';
import { getUserStatus } from '@/constants/status';
import useCustomSweetAlert from '@/hooks/useCustomSweetAlert';
import useLogoutHandler from '@/hooks/useLogoutHandler';
import { useAppSelector } from '@/hooks/useRedux';
import { userPermissionSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './ProfileSettings.module.css';

const ProfileSettings = () => {
    const { logoutHandler } = useLogoutHandler();
    const { getStatusMe } = useStatusHelper();
    const profileStatusData = getStatusMe();
    const status = getUserStatus();

    const user = useAppSelector(state => state.userReducer.user);

    const { successAlertModal, errorAlertModal } = useCustomSweetAlert();
    const [profileImage, setProfileImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const profileRef = useRef();

    const changeStatusHandler = event => {
        handleUserStatusUpdate(event?.id);
    };

    const profileImgChangeHandler = data => {
        setProfileImage(data[0]);
    };
    const removeHandler = () => {
        setProfileImage(null);
    };

    interface FormValues {
        username: string;
        name: string;
        email: string;
        password: string;
        confirm_password: string;
    }

    const initialValues: FormValues = {
        username: user?.username ?? '',
        name: user?.name ?? '',
        email: user?.email ?? '',
        password: '',
        confirm_password: '',
    };

    const onSubmit = (values: FormValues, actions) => {
        fetchUpdateUserInfo(values);
        actions.setSubmitting(false);
    };

    const validate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (values.password) {
            if (!values.confirm_password) {
                errors.confirm_password = 'Confirm Password is required!';
            } else if (values.confirm_password !== values.password) {
                errors.confirm_password = 'Passwords do not match!';
            }
        }

        return errors;
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate,
    });
    // form

    // fetchData
    let formData = new FormData();
    async function fetchUpdateUserInfo(values: FormValues) {
        setLoading(true);
        profileImage && formData.append('profile_picture', profileImage.file);
        values?.username !== user?.username &&
            formData.append('username', values?.username);
        values?.name !== user?.name && formData.append('name', values?.name);
        values?.email !== user?.email &&
            formData.append('email', values?.email);
        values?.password && formData.append('password', values?.password);
        try {
            const { data } = await fetchData(
                'POST',
                `${process.env.REACT_APP_BASE_API_URL}/api/v1/user/info`,
                formData,
            );
            if (data.status) {
                successAlertModal(
                    null,
                    values?.password
                        ? 'Since you updated your password, you are directed to log in again...'
                        : null,
                    () => (values?.password ? logoutHandler() : null),
                    () => (values?.password ? logoutHandler() : null),
                );
            } else {
                errorAlertModal();
                console.error('saveDataerror', data);
            }
        } catch (error) {
            errorAlertModal();
            console.error('catch err', error);
        } finally {
            setLoading(false);
        }
    }
    //fetchData

    const permission = useAppSelector(userPermissionSelector);
    return (
        <div className={classes['container']}>
            {/* inputs */}
            {user && (
                <form
                    autoComplete="new-password"
                    onSubmit={formik.handleSubmit}>
                    <div className={classes['banner']}>
                        <div className={classes['profile_pic']}>
                            <MediaInput
                                mini
                                onChange={profileImgChangeHandler}
                                // value={profileImage}
                                ref={profileRef}
                                onRemoveMedia={removeHandler}
                                className={classes['media-img']}
                                accept="image/jpg, image/png, image/jpeg"
                                passDefaultLink={
                                    profileImage ?? [user?.profile_picture] ??
                                    ''
                                }
                            />
                        </div>
                    </div>
                    <div className={classes['inputs_container']}>
                        <div className={classes['input_col']}>
                            <div
                                style={{
                                    opacity: !permission?.['user.edit']
                                        ? 0.5
                                        : 1,
                                }}>
                                <TextInput
                                    title={t`Username`}
                                    placeHolder={t`Username`}
                                    name="username"
                                    value={formik.values['username']}
                                    onChange={formik.handleChange}
                                    disabled={!permission?.['user.edit']}
                                />
                            </div>
                            <TextInput
                                title={t`System Name`}
                                placeHolder={t`System Name`}
                                name="name"
                                value={formik.values['name']}
                                onChange={formik.handleChange}
                            />
                            <div className="">
                                <span>
                                    <Trans>Status</Trans>
                                </span>
                                <div className={classes['status-container']}>
                                    {Object.keys(status).map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() =>
                                                changeStatusHandler(
                                                    status[item],
                                                )
                                            }
                                            className={
                                                classes['status-container-row']
                                            }
                                            style={{
                                                backgroundColor:
                                                    profileStatusData?.id ==
                                                        status[item]?.id &&
                                                    'var(--bright-hr)',
                                            }}>
                                            <img
                                                src={status[item].icon}
                                                alt=""
                                            />
                                            {status[item].name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={classes['input_col']}>
                            <TextInput
                                title={t`Email`}
                                placeHolder={t`Email`}
                                name="email"
                                value={formik.values['email']}
                                onChange={formik.handleChange}
                            />
                            <TextInput
                                title={t`Password`}
                                placeHolder={t`Password`}
                                name="password"
                                value={formik.values['password']}
                                onChange={formik.handleChange}
                                type="password"
                            />
                            <div className="">
                                <TextInput
                                    title={t`Confirm Password`}
                                    placeHolder={t`Confirm Password`}
                                    name="confirm_password"
                                    value={formik.values['confirm_password']}
                                    onChange={formik.handleChange}
                                    type="password"
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.values.password &&
                                        formik.touched.confirm_password &&
                                        formik.errors?.confirm_password
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                }}>
                                <div style={{ display: 'flex' }}>
                                    <AppButton
                                        classStyles={classes['appButton']}
                                        type="submit"
                                        title={
                                            loading ? (
                                                <Spinner />
                                            ) : (
                                                <Trans>Save</Trans>
                                            )
                                        }
                                        size={BUTTON_SIZE.medium}
                                        disable={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default ProfileSettings;
