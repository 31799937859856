import { useEffect } from 'react';

import { IChannelData } from '@/types';

import classes from '../ChannelUserPermissinons.module.css';

type Props = {
    onClickRole: (item: {}, channel: {}) => void;
    searchText: string;
    channel: IChannelData;
    selectedRole: {};
    loading: {};
    setLoading: any;
    sortByPriority: [];
};

function ChannnelUserPermissinonsRoleList(props: Props) {
    const {
        onClickRole,
        searchText,
        channel,
        selectedRole,
        loading,
        setLoading,
        sortByPriority,
    } = props;

    useEffect(() => {
        onClickRole(selectedRole, channel);
    }, [selectedRole, channel]);

    return (
        <div style={{ position: 'relative' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    opacity: loading ? 0.2 : 1,
                }}>
                {sortByPriority
                    ?.filter(filterItem =>
                        filterItem?.name
                            ?.toLowerCase()
                            .includes(searchText.toLowerCase()),
                    )
                    ?.map((item, index) => (
                        <div
                            key={index}
                            id={item?.id}
                            onClick={() => onClickRole(item, channel)}
                            className={`${classes['role-item']} ${
                                selectedRole?.id === item?.id &&
                                classes['selected']
                            }`}>
                            <div
                                style={{
                                    height: 16,
                                    width: 16,
                                    borderRadius: 50,
                                    backgroundColor: item?.color,
                                }}></div>
                            <div className="">{item?.name}</div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default ChannnelUserPermissinonsRoleList;
