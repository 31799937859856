const HashtagIcon = ({
    width = '20',
    height = '20',
    fill = '#DCDBE7',
    className = '',
}) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5053 17L12.8632 3H14.5474L12.1895 17H10.5053ZM4 13.2539L4.27368 11.6133H15.1649L14.8912 13.2539H4ZM5.45263 17L7.81053 3H9.49474L7.13684 17H5.45263ZM4.83509 8.38672L5.10877 6.74609H16L15.7263 8.38672H4.83509Z"
                fill={fill}
            />
        </svg>
    );
};
export default HashtagIcon;
