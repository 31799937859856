import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ViewportList } from 'react-viewport-list';

import classes from './AppTable.module.css';

const AppTable = props => {
    const {
        columns,
        data,
        header,
        hoverIcons,
        onDragEndCustom,
        className = '',
        setSelectedRow,
        isDragDisabled,
        isDropDisabled,
    } = props;
    const [dataDrag, setDataDrag] = useState(data);

    useEffect(() => {
        setDataDrag(data);
    }, [data]);

    const viewRef = useRef(null);

    const _renderCell = (item, renderFunc) => {
        if (typeof renderFunc === 'function') {
            return renderFunc(item);
        }
        return item[renderFunc];
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const draggedItem = dataDrag[result.source.index];

        const items = Array.from(dataDrag);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setDataDrag(items);
        onDragEndCustom && onDragEndCustom(result, draggedItem, items);
    };

    const _renderListItem = (item, index) => {
        setSelectedRow && setSelectedRow(item);
        return (
            <Draggable
                key={item?.id}
                draggableId={item?.id}
                index={index}
                isDragDisabled={isDragDisabled}>
                {provided => (
                    <tr
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        {columns.map((column, columnIndex) => (
                            <td style={{ height: 0 }} key={columnIndex}>
                                {_renderCell(item, column.render)}
                            </td>
                        ))}
                        <td>{hoverIcons && hoverIcons(item)}</td>
                    </tr>
                )}
            </Draggable>
        );
    };

    return (
        <div className={` ${className} ${classes['table-container']}`}>
            <table className={classes['table']}>
                <thead className={classes['header']}>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index}>{header(column, index)}</th>
                        ))}
                    </tr>
                </thead>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId="droppable"
                        isDropDisabled={isDropDisabled}>
                        {provided => (
                            <tbody
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                <ViewportList
                                    items={dataDrag}
                                    children={(item, index) =>
                                        _renderListItem(item, index)
                                    }
                                    ref={viewRef}
                                    overscan={15}
                                    axis="y"
                                />
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </DragDropContext>
            </table>
        </div>
    );
};

export default AppTable;
