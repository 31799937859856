import {
    CSSProperties,
    FC,
    InputHTMLAttributes,
    ReactNode,
    useRef,
} from 'react';

import classes from './TextInput.module.css';

interface ITextInput
    extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    label?: string;
    title?: string;
    name?: string;
    placeHolder?: string;
    defaultValue?: string;
    textStyle?: CSSProperties;
    containerStyle?: CSSProperties;
    isDisabled?: boolean;
    icon?: any;
    error?: any;
    success?: any;
    onClick?: (e?: any) => void;
    wrapClassName?: string;
    [rest: string]: any;
}

const TextInput: FC<ITextInput> = props => {
    const {
        label,
        title,
        name,
        placeHolder,
        textStyle,
        containerStyle,
        defaultValue,
        isDisabled,
        icon,
        onClick,
        error,
        success,
        wrapClassName = '',
        ...rest
    } = props;

    const inputRef = useRef(null);

    let inputProp = {
        id: name,
        name: name,
        placeholder: placeHolder ?? label,
        disabled: isDisabled,
        className: classes['input'],
        style: {
            ...textStyle,
        },
        ...rest,
        value: rest?.value ? rest.value : '',
    };

    const focusInput = () => {
        if (inputRef && inputRef?.current && props.type === 'date') {
            inputRef?.current?.showPicker();
        }
    };

    return (
        <div className={wrapClassName} style={containerStyle}>
            {title && <span>{title}</span>}
            <div
                className={`${classes['container']} ${
                    error ? classes['error'] : ''
                } ${success ? classes['success'] : ''}`}>
                {label && (
                    <span className={classes['labelHeading']}>{label}</span>
                )}
                <div onClick={focusInput} className={classes['inputWrapper']}>
                    {icon &&
                        (typeof icon === 'object' ? (
                            icon
                        ) : (
                            <img
                                alt=""
                                onClick={e => onClick && onClick(e)}
                                src={icon}
                                className={classes['leftIcon']}
                                style={onClick && { cursor: 'pointer' }}
                            />
                        ))}
                    {rest.multiple ? (
                        <textarea rows={4} {...inputProp} />
                    ) : (
                        <input ref={inputRef} {...inputProp} />
                    )}
                </div>
                {rest.maxLength && (
                    <span className={classes['max-length']}>
                        {rest.maxLength}
                    </span>
                )}
            </div>
            <span className={classes['errorText']}>{error}</span>
            <span className={classes['successText']}>{success}</span>
        </div>
    );
};

export default TextInput;
