import React from 'react';

import classes from './MessageAction.module.css';

interface MessageActionProps {
    actionIcon: string;
    actionName: string;
    msgId: string;
    style?: React.CSSProperties;
    imgStyle?: React.CSSProperties;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const MessageAction: React.FC<MessageActionProps> = ({
    actionIcon,
    actionName,
    msgId,
    style,
    imgStyle,
    onClick,
    ...rest
}) => {
    const takeActionHandler = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        onClick(event);
    };

    const imgStyles = imgStyle ? imgStyle : {};

    return (
        <div
            {...rest}
            id={msgId}
            onClick={takeActionHandler}
            className={classes['action-container']}
            style={style}>
            <img
                src={actionIcon}
                alt={actionName}
                style={{
                    ...imgStyles,
                    height: '16px',
                    width: '16px',
                }}
            />
            <div className={classes['action-name-container']}>
                <div className={classes['triangle']}></div>
                <div className={classes['action-name']}>
                    <p>{actionName}</p>
                </div>
            </div>
        </div>
    );
};

export default MessageAction;
