import { AnimatePresence } from 'framer-motion';
import { useContext, useMemo } from 'react';
import { VList } from 'virtua';

import { NotesContext } from '@/context/notes/NotesContext';
import { INote } from '@/types/notesTypes';

import NoteListLoader from './NoteListLoader';
import classes from './NotesList.module.css';
import NotesListHeader from './NotesListHeader';
import NotesListItem from './NotesListItem';

const NotesList: React.FC<{}> = () => {
    const notesContext = useContext(NotesContext);
    const {
        isFetchingNotes,
        isFullMode,
        decryptedNotesBody,
        searchValue,
        filterType,
    } = notesContext;
    const categorizedNotes = notesContext?.filteredNotes;

    const filteredNotes = useMemo(() => {
        let filtered: INote[] = [...categorizedNotes];

        if (searchValue) {
            filtered = filtered.filter(
                note =>
                    note.title
                        .toLowerCase()
                        .includes(searchValue.toLowerCase().trim()) ||
                    decryptedNotesBody?.[note.id]
                        .toLowerCase()
                        .includes(searchValue.toLowerCase().trim()),
            );
        }

        if (filterType === 'name') {
            filtered = filtered.sort((a, b) => a.title.localeCompare(b.title));
        } else if (filterType === 'date') {
            filtered = filtered.sort((a, b) => {
                const dateA = new Date(a.created_at);
                const dateB = new Date(b.created_at);
                return dateB.getTime() - dateA.getTime();
            });
        } else if (filterType === 'fav') {
            filtered = filtered.sort((a, b) => {
                // Prioritize notes with fav = 1 at the beginning
                if (a.fav === 1 && b.fav !== 1) {
                    return -1;
                } else if (a.fav !== 1 && b.fav === 1) {
                    return 1;
                } else {
                    // If both have the same fav status, sort alphabetically by title
                    return a.title.localeCompare(b.title);
                }
            });
        }

        return filtered;
    }, [categorizedNotes, searchValue, filterType]);

    return (
        <div className={`${classes['list-wrap']}`}>
            <NotesListHeader />
            <VList
                className={classes['list']}
                style={{
                    height: 'calc(100vh - 150px)',
                    display: isFullMode && 'none',
                }}>
                {searchValue !== '' && filteredNotes.length === 0 && (
                    <p>Search results not found!</p>
                )}
                {isFetchingNotes && <NoteListLoader />}
                {!isFetchingNotes &&
                    filteredNotes.map((note: INote, index: number) => (
                        <AnimatePresence key={note.id}>
                            <NotesListItem key={index} note={note} />
                        </AnimatePresence>
                    ))}
            </VList>
        </div>
    );
};

export default NotesList;
