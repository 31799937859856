import { useEffect, useState } from 'react';

import { blockUserToServer, unblockUserToServer } from '@/api/member';
import { useCustomSweetAlert } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
    getOrganizationsData,
    getOrganizationsMembersData,
    getOrganizationsServersData,
} from '@/store/organization-reducer';

type MemberTableProps = {
    tabData?: {
        id: number;
        value: string;
        label: string;
    }[];
    checkboxData?: any[];
};

const useOrganizationMember = (props?: MemberTableProps) => {
    const dispatch = useAppDispatch();
    const myUser = useAppSelector(state => state.userReducer.user);
    let subserver_id = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const [tabData, setTabData] = useState(props?.tabData);
    const [filteredMemberData, setFilteredMemberData] = useState([]);

    const [searchText, setSearchText] = useState('');

    const [checkboxData, setCheckboxData] = useState(props?.checkboxData);
    const [selectedCheckboxData, setSelectedCheckboxData] = useState([]);
    const [isOpencheckboxModal, setIsOpenCheckboxModal] = useState(false);

    const [selectedTabItem, setSelectedTabItem] = useState(tabData?.[0]);

    const [sortToggle, setSortToggle] = useState(true);

    const { successAlertModal, errorAlertModal } = useCustomSweetAlert();

    // ORGANIZATION MEMBER
    const reduxLoading = useAppSelector(
        state => state.organizationReducer.loading,
    );
    const serversData = useAppSelector(
        state => state.organizationReducer.organizationServers,
    );
    const organization = useAppSelector(
        state => state.organizationReducer.organizationList,
    );
    const membersData = useAppSelector(
        state => state.organizationReducer.organizationMembers,
    );
    const organizationById = {};

    // Create an object to map organization IDs to names
    organization?.forEach(org => {
        organizationById[org.id] = org.name;
    });

    useEffect(() => {
        setTabData(props?.tabData);
        setCheckboxData(props?.checkboxData);
    }, []);
    // Create the memberArr using Object.keys and map
    const memberArr = Object.keys(membersData)?.reduce(
        (arr, organizationId) => {
            const values = membersData[organizationId];
            values?.forEach(value => {
                const indexToUpdate = arr.findIndex(obj => obj.id === value.id);
                const organizationName = organizationById[organizationId];
                if (indexToUpdate !== -1) {
                    arr[indexToUpdate].organizations.push({
                        organizationId,
                        organization_name: organizationName ?? '',
                    });
                    return;
                }
                arr.push({
                    ...value,
                    organizations: [
                        {
                            organizationId,
                            organization_name: organizationName ?? '',
                        },
                    ],
                });
            });
            return arr;
        },
        [],
    );

    const [organizationMembers, setMembers] = useState([]);

    useEffect(() => {
        setMembers(memberArr);
    }, [membersData]);

    const servers = [];
    for (const organizationId in serversData) {
        if (serversData.hasOwnProperty(organizationId)) {
            const values = serversData[organizationId];
            values?.forEach(value => {
                const organization_name = organization.filter(
                    el => el.id === organizationId,
                )[0]?.name;
                servers.push({ ...value, organizationId, organization_name });
            });
        }
    }
    // ORGANIZATION MEMBER

    // FILTER DATA
    useEffect(() => {
        let filteredData = [...organizationMembers];

        // ROLE
        if (selectedCheckboxData && selectedCheckboxData?.length > 0) {
            filteredData = filteredData?.filter(item =>
                selectedCheckboxData?.some(
                    organizations =>
                        organizations?.id === item?.organization_id,
                ),
            );
        }

        // SEARCH
        filteredData = filteredData?.filter(item => {
            const usernameMatch = item?.username
                ?.toLowerCase()
                .includes(searchText?.toLowerCase());
            const nameMatch = item?.name
                ?.toLowerCase()
                .includes(searchText?.toLowerCase());

            return usernameMatch || nameMatch;
        });

        setFilteredMemberData(filteredData);
    }, [
        selectedTabItem,
        searchText,
        selectedCheckboxData,
        organizationMembers,
    ]);

    // FILTER DATA

    // ROLE FILTER
    const isRoleSelected = item => {
        return selectedCheckboxData?.includes(item);
    };

    const onChangeCheckbox = item => {
        if (selectedCheckboxData?.includes(item)) {
            setSelectedCheckboxData(
                selectedCheckboxData?.filter(role => role !== item),
            );
        } else {
            setSelectedCheckboxData([...selectedCheckboxData, item]);
        }
    };

    const allSelectCheckbox = () => {
        setSelectedCheckboxData(organization);
    };

    const allClearCheckbox = () => {
        setSelectedCheckboxData([]);
    };
    // ROLE FILTER

    // TAB CHANGE
    const onChangeTab = item => {
        setSelectedTabItem(item);
    };
    // TAB CHANGE

    // HANDLE SEARCH
    const handleSearchInput = text => {
        setSearchText(text);
    };
    // HANDLE SEARCH

    // USER BAN
    const handleMemberServerBlockUpdate = async userId => {
        const payload = {
            userId: userId,
            subserverId: subserver_id,
            currentId: myUser?.id,
        };
        const res = await dispatch(blockUserToServer(payload));
        if (res?.payload?.data?.success) {
            successAlertModal();
        } else {
            errorAlertModal(null, res?.payload?.error);
        }
    };
    // USER BAN

    // USER UNBAN
    const handleMemberServerUnblockUpdate = async userId => {
        const payload = {
            userId: userId,
            subserverId: subserver_id,
            currentId: myUser?.id,
        };
        const res = await dispatch(unblockUserToServer(payload));
        if (res?.payload?.data?.success) {
            successAlertModal();
        } else {
            errorAlertModal(null, res?.payload?.error);
        }
    };
    // USER UNBAN
    useEffect(() => {
        dispatch(getOrganizationsData());
    }, []);

    useEffect(() => {
        if (organization?.length > 0) {
            organization?.forEach(el => {
                dispatch(getOrganizationsMembersData(el.id));
                dispatch(getOrganizationsServersData(el.id));
            });
        }
    }, [organization?.length]);
    // SORT DATA
    const handleSortData = key => {
        setSortToggle(prev => !prev);

        let sortData = [...filteredMemberData];
        const keyType = typeof sortData[0][key];

        if (sortToggle) {
            if (keyType === 'string')
                sortData = sortData?.sort((a, b) =>
                    a[key]?.localeCompare(b[key]),
                );

            if (keyType === 'boolean')
                sortData = sortData?.sort((a, b) => b[key] - a[key]);

            if (keyType === 'object')
                sortData = sortData?.sort(
                    (a, b) => b[key]?.length - a[key]?.length,
                );
        }
        // reverse
        else {
            if (keyType === 'string') {
                sortData = sortData?.sort((a, b) =>
                    b[key]?.localeCompare(a[key]),
                );
            }

            if (keyType === 'boolean') {
                sortData = sortData?.sort((a, b) => a[key] - b[key]);
            }

            if (keyType === 'object') {
                sortData = sortData?.sort(
                    (a, b) => a[key]?.length - b[key]?.length,
                );
            }
        }

        setFilteredMemberData(sortData);
    };
    // SORT DATA

    return {
        filteredMemberData,
        searchText,
        isOpencheckboxModal,
        setIsOpenCheckboxModal,
        isRoleSelected,
        onChangeCheckbox,
        handleSearchInput,
        allClearCheckbox,
        allSelectCheckbox,
        setCheckboxData,
        checkboxData,
        handleMemberServerUnblockUpdate,
        handleMemberServerBlockUpdate,
        handleSortData,
        onChangeTab,
        reduxLoading,
        servers,
        organization,
        organizationMembers,
        memberArr,
    };
};

export default useOrganizationMember;
