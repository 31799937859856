import { createSelector, createSlice } from '@reduxjs/toolkit';

// check storage for theme color -> set default to dark if does not exist
if (!localStorage.getItem('themeColor')) {
    localStorage.setItem('themeColor', 'DARK');
}

// initial state
const initialState = {
    themeColor: localStorage.getItem('themeColor'),
};
// initiate the data theme
document.documentElement.setAttribute('data-theme', initialState.themeColor);

const themeSlice = createSlice({
    name: 'themeColor',
    initialState: initialState,
    reducers: {
        toggleTheme(state, action) {
            switch (action.payload.themeName) {
                case 'LIGHT':
                    state.themeColor = 'LIGHT';
                    document.documentElement.setAttribute(
                        'data-theme',
                        state.themeColor,
                    );
                    localStorage.setItem('themeColor', state.themeColor);
                    break;
                case 'DARK':
                    state.themeColor = 'DARK';
                    document.documentElement.setAttribute(
                        'data-theme',
                        state.themeColor,
                    );
                    localStorage.setItem('themeColor', state.themeColor);
                    break;
                default:
                    state.themeColor = 'DARK';
                    document.documentElement.setAttribute(
                        'data-theme',
                        state.themeColor,
                    );
                    localStorage.setItem('themeColor', state.themeColor);
            }
        },
    },
});

export const themeSelector = createSelector(
    state => state,
    ({ themeColorReducer }) => themeColorReducer.themeColor,
);

export const themeColorActions = themeSlice.actions;
export default themeSlice.reducer;
