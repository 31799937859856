import { SESSION_STORAGE } from '@/constants/enum';
import { convertJwkToBase64, decryptPassword } from './cryptoUtils';

export const exportKeysForNode = (name, privateKey, publicKey) => {
    const devicePassword = sessionStorage.getItem(
        SESSION_STORAGE.devicePassword,
    );

    const privateKeyJWK = decryptPassword(privateKey, devicePassword);
    const publicKeyJWK = decryptPassword(publicKey, devicePassword);

    const fileType = 'application/json';

    const jwk = convertJwkToBase64({
        publicKey: publicKeyJWK,
        privateKey: privateKeyJWK,
    });

    const jwkJson = JSON.stringify(jwk); // Serialize the object to a JSON string
    const blob = new Blob([jwkJson], { type: fileType });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${name.replace(/\s/g, '_')}_node.json`;
    link.click();
    URL.revokeObjectURL(url);
};
