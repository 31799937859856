import bold from '@/assets/icons/bold-solid.svg';
import code from '@/assets/icons/code-solid.svg';
import heading from '@/assets/icons/heading-solid.svg';
import image from '@/assets/icons/image-regular.svg';
import italic from '@/assets/icons/italic-solid.svg';
import link from '@/assets/icons/link-solid.svg';
import checklist from '@/assets/icons/list-check-solid.svg';
import numberlist from '@/assets/icons/list-ol-solid.svg';
import list from '@/assets/icons/list-ul-solid.svg';
import quote from '@/assets/icons/quote-right-solid.svg';
import strike from '@/assets/icons/strikethrough-solid.svg';
import table from '@/assets/icons/table-solid.svg';
import underline from '@/assets/icons/underline-solid.svg';

const TABLE_MARKDOWN_SYNTAX = `|  |  |
|--|--|
|  |  |`;

const useInputController = (props: any) => {
    const input = props.inputRef.current as
        | HTMLInputElement
        | HTMLTextAreaElement;

    const applyMarkdownTag = (
        tag: string,
        closing: boolean = true,
        closetag?: string,
    ) => {
        input.focus();
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const selection2 = range.extractContents();
        const markdownTag = selectedText => {
            return `${tag + selectedText}${closing ? closetag ?? tag : ''}`;
        };

        const modifiedText = markdownTag(selection2.textContent);
        range.deleteContents();

        // Insert the modified text directly into the range
        range.insertNode(document.createTextNode(modifiedText));
        const textupdate = input.innerHTML;

        props.handleController(textupdate);

        input.focus();
        // Set the cursor position
        const newRange = document.createRange();
        newRange.setStart(input.childNodes[0], range.endOffset + 2);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
        return;
    };

    const inputControllersComponent = [
        {
            name: 'bold',
            title: 'Bold',
            icon: bold,
            onClick: () => {
                applyMarkdownTag('**');
            },
        },
        {
            name: 'italic',
            title: 'Italic',
            icon: italic,
            onClick: () => {
                applyMarkdownTag('*');
            },
        },
        {
            name: 'underline',
            title: 'Underline',
            icon: underline,
            onClick: () => {
                applyMarkdownTag('<ins>', true, '</ins>');
            },
        },
        {
            name: 'strikeThrough',
            title: 'Strike Through',
            icon: strike,
            onClick: () => {
                applyMarkdownTag('~~');
            },
        },
        {
            name: 'heading',
            title: 'Heading',
            icon: heading,
            onClick: () => {
                applyMarkdownTag('# ', false);
            },
        },
        {
            name: 'bulletPoints',
            title: 'Bullet Points',
            icon: list,
            onClick: () => {
                applyMarkdownTag('- ', false);
            },
        },
        {
            name: 'numberBullets',
            title: 'Number Bullets',
            icon: numberlist,
            onClick: () => {
                applyMarkdownTag('1. ', false);
            },
        },
        {
            name: 'checklist',
            title: 'Checklist',
            icon: checklist,
            onClick: () => {
                applyMarkdownTag('- [ ] ', false);
            },
        },
        {
            name: 'quote',
            title: 'Quote',
            icon: quote,
            onClick: () => {
                applyMarkdownTag('<blockquote>', true, '</blockquote>');
            },
        },
        {
            name: 'code',
            title: 'Code',
            icon: code,
            onClick: () => {
                applyMarkdownTag('```', false);
            },
        },
        {
            name: 'table',
            title: 'Table',
            icon: table,
            onClick: () => {
                applyMarkdownTag(TABLE_MARKDOWN_SYNTAX, false);
            },
        },
        {
            name: 'link',
            title: 'Link',
            icon: link,
            onClick: () => {
                applyMarkdownTag(`[link text](`, true, ')');
            },
        },
        {
            name: 'image',
            title: 'Image',
            icon: image,
            onClick: () => {
                applyMarkdownTag(`![Alt Text](`, true, ')');
            },
        },
    ];

    return { inputControllersComponent };
};

export default useInputController;
