import { t } from '@lingui/macro';
import { useContext } from 'react';

import AppTable from '@/UI/AppTable/AppTable';
import arrowDown from '@/assets/icons/arrowDownPurple.svg';
import { IMember } from '@/types/ImemberData';

import { StatisticsContext } from '../StatisticsContext';

import ChannelsCol from './ChannelsCol';
import MessagesCol from './MessagesCol';
import StatusCol from './StatusCol';
import classes from './TableStatistics.module.css';
import UserCol from './UserCol';

const TableStatistics = () => {
    const { handleSortData, memberData } = useContext(StatisticsContext);

    const data: IMember[] = memberData ?? [];

    const _renderHeaderCell = item => (
        <div
            onClick={() => {
                if (
                    item?.key === 'name' ||
                    item?.key === 'messages' ||
                    item?.key === 'state'
                ) {
                    handleSortData(item.key);
                }
            }}
            className={classes['header-cell']}>
            <span>{item.label}</span>
            {item?.key !== 'channels' && <img alt="" src={arrowDown} />}
        </div>
    );

    const columns = [
        {
            key: 'name',
            label: t`User`,
            render: item => <UserCol item={item} />,
        },
        {
            key: 'state',
            label: t`Status`,
            render: item => <StatusCol item={item} />,
        },
        {
            key: 'channels',
            label: t`Channels`,
            render: item => <ChannelsCol item={item} />,
        },
        {
            key: 'messages',
            label: t`Messages`,
            render: item => <MessagesCol item={item} />,
        },
    ];

    return (
        <div className={classes['statistic-table']}>
            <AppTable
                className={classes['table']}
                columns={columns}
                data={data}
                header={item => _renderHeaderCell(item)}
                isDragDisabled={true}
            />
        </div>
    );
};

export default TableStatistics;
