import { ALL_PERMISSIONS } from '@/constants/enum';
import { optionTypes } from '@/constants/subServerOptions';
import { useAppSelector } from '@/hooks/useRedux';
import { userPermissionSelector } from '@/store/server-data-reducer/selectors-server-data';

export default function PermissionsMeControl(
    data: optionTypes[],
    permissionsKey,
) {
    const permissionsMe = useAppSelector(userPermissionSelector);

    if (!permissionsMe || !data || !permissionsKey) {
        return data; // Return original data if dependencies are missing
    }

    function getTrueKeys(jsonObj: Record<string, boolean>) {
        const trueKeys: string[] = [];
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key) && jsonObj[key] === true) {
                trueKeys.push(key);
            }
        }
        return trueKeys;
    }

    const filteredSettingsList = data.filter(item => {
        if (!item || !item[permissionsKey]) {
            return false; // Skip items without the expected property
        }

        if (item[permissionsKey] === ALL_PERMISSIONS) return true;
        return item[permissionsKey]?.some(permission =>
            getTrueKeys(permissionsMe)?.includes(permission),
        );
    });

    return filteredSettingsList;
}
