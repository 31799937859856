import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import classes from './MobileViewTabsContent.module.css';

type IProps = {
    children: ReactNode;
    initial: any;
    animate: any;
    exit: any;
    transition?: any;
};

const MobileContentMotion: React.FC<IProps> = ({
    children,
    initial,
    animate,
    exit,
    transition,
}) => {
    return (
        <motion.div
            className={classes['main']}
            initial={initial}
            animate={animate}
            exit={exit}
            transition={transition}>
            {children}
        </motion.div>
    );
};

export default MobileContentMotion;
