import ContentLoader from 'react-content-loader';

const NoteListLoader = props => (
    <ContentLoader
        speed={2}
        width={300}
        height={80}
        viewBox="0 0 300 80"
        backgroundColor="#0d0a23"
        foregroundColor="#302c55"
        {...props}>
        <rect x="25" y="14" rx="3" ry="3" width="100" height="10" />
        <rect x="25" y="30" rx="3" ry="3" width="250" height="10" />
        <rect x="25" y="47" rx="3" ry="3" width="250" height="30" />
    </ContentLoader>
);

export default NoteListLoader;
