import { FC, memo, useEffect, useState } from 'react';

import { sortMediaOrder } from '@/Utils/sortMediaOrder';

import ReplyMediaSort from './ReplyMediaSort';
import classes from './ReplyMedia.module.css';

type Props = {
    files;
};

const DisplayOnly: FC<Props> = props => {
    const { files } = props;

    const [filesArray, setFilesArray] = useState<any[]>([]);
    
    useEffect(() => {
        if (files) {
            const sliceFiles = [...files].sort(sortMediaOrder).slice(0, 4);
            setFilesArray(sliceFiles);
        }
    }, [files]);

    return (
        <div className={classes['download-items-container']}>
            {filesArray
                .filter(file => file)
                .sort(sortMediaOrder)
                ?.map((file, key) => (
                    <ReplyMediaSort key={key} file={file} />
                ))}
            {files.length > 4 && <p>+ {files.length - 4}</p>}
        </div>
    );
};
export default memo(DisplayOnly);
