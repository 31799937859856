import { createPortal } from 'react-dom';

import MediaViewer from '../media-viewer/MediaViewer';

import { useMediaDisplayContext } from './MediaDisplayContext';

const MediaSlider: React.FC<{}> = () => {
    const {
        allowView,
        isMediaView,
        closeMediaHandler,
        fetchedUrls,
        message,
        owner,
    } = useMediaDisplayContext();
    
    return (
        allowView &&
        isMediaView.state &&
        createPortal(
            <MediaViewer
                onClose={closeMediaHandler}
                data={fetchedUrls}
                message={message}
                owner={{
                    name: owner?.name,
                    profile_picture: owner?.profile_picture,
                }}
                initialSlide={isMediaView.initialSlide}
            />,
            document.getElementById('media-viewer-container'),
        )
    );
};
export default MediaSlider;
