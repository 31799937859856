import { Dots } from 'react-activity';

import { Version } from '@/UI';
import ChatLogo from '@/assets/icons/chat-logo-huge.svg';

import classes from './LoadingPage.module.css';

type LoadingPageProps = {
    mainText: string;
};

function LoadingPage(props: LoadingPageProps) {
    const { mainText } = props;

    return (
        <div className={classes['container']}>
            <div className={classes['top-area']}>
                <div className={classes['chat-logo-container']}>
                    <img src={ChatLogo} alt="" />
                    <div
                        style={{
                            position: 'absolute',
                            paddingBottom: 36,
                            paddingLeft: 8,
                        }}>
                        <Dots
                            color="#fff"
                            size={32}
                            speed={1}
                            animating={true}
                        />
                    </div>
                </div>
                <div
                    style={{
                        fontSize: 64,
                        marginTop: 24,
                        fontWeight: 500,
                    }}>
                    {mainText}
                </div>
            </div>
            <div className={classes['bottom-text']}>
                <Version />
            </div>
        </div>
    );
}

export default LoadingPage;
