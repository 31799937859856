import classes from './EditChannel.module.css';
import { useEditChannelContext } from './EditChannelContext';

const TabsContent: React.FC<{}> = props => {
    const { componentMap, selectedComponent } = useEditChannelContext();
    const className =
        selectedComponent !== 'members'
            ? classes['right-container-scroll-area']
            : '';

    return (
        <div className={classes['right-content']}>
            <div className={className}>
                {selectedComponent &&
                    componentMap?.[selectedComponent]?.component}
            </div>
            {selectedComponent && componentMap?.[selectedComponent]?.buttons}
        </div>
    );
};
export default TabsContent;
