export function convertToTitleCase(inputString) {
    return inputString
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export function splitTextInHalf(text) {
    const textLength = text.length;
    const splitIndex = Math.floor(textLength / 2);

    const part1 = text.substring(0, splitIndex);
    const part2 = text.substring(splitIndex);

    return [part1, part2];
}

export function truncateText(text: string, maxLength: any) {
    if (text?.length > maxLength) {
        return text.substring(0, maxLength - 3) + '...';
    }
    return text;
}
