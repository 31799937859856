import { Trans } from '@lingui/macro';
import { t } from '@lingui/macro';

import { AppButton, TextInput, Version } from '@/UI';
import upload from '@/assets/icons/document-upload.svg';
import logo from '@/assets/images/login_logo.png';

import P2PLogin from './P2PLogin';
import classes from './UploadServerKeys.module.css';
import useUploadServerKeys from './useUploadServerKeys';

const UploadServerKeys = () => {
    const { files, handleChange, handleAddPrivateKeys, serverData } =
        useUploadServerKeys();
    return (
        <div style={{ flex: 1, display: 'flex' }}>
            <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                <div
                    className={`${classes['center-container']} centerContainer`}>
                    <div className={classes['contentWrapper']}>
                        <img
                            alt="logo"
                            src={logo}
                            className={classes['logo']}
                        />
                        <div className={classes['headingWrapper']}>
                            <h1 className="secondaryHeading marginVertical-small">
                                <Trans>Upload Encrypted Keys</Trans>
                            </h1>
                            {serverData?.name && (
                                <h2 className="secondaryHeading marginVertical-small">
                                    {serverData.name}
                                </h2>
                            )}
                        </div>
                        <div className={classes['inputWrapper']}>
                            <TextInput
                                label={t`Private Key`}
                                name="privateKey"
                                isDisabled
                                icon={upload}
                                value={
                                    serverData?.privateKey ??
                                    files?.fileName ??
                                    ''
                                }
                                placeHolder={t`Upload your private key`}
                                onClick={handleChange}
                                error={files?.error}
                            />
                        </div>
                        <P2PLogin />

                        <AppButton
                            title={t`Continue`}
                            onClick={handleAddPrivateKeys}
                            classStyles={classes['appButton']}
                        />
                        <Version />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadServerKeys;
