import { Navigate } from 'react-router-dom';

import { getCookie } from '@/Utils/utils';
import { SESSION_STORAGE } from '@/constants/enum';
import { useAppSelector } from '@/hooks/useRedux';

import { ROOT_PATH } from './router.types';

const ProtectedRoute = ({ children }) => {
    const user = useAppSelector(state => state.userReducer.user);

    const isDevicePassword = getCookie(SESSION_STORAGE.devicePassword);

    if (user.token === undefined || !isDevicePassword) {
        return <Navigate to={ROOT_PATH.login} />;
    }

    return children;
};

export default ProtectedRoute;
