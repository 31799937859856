import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type ForwardedMsgType = 'media' | 'reply' | 'text' | 'forward';

export interface Notification {
    channel_id: string;
    created_at: string;
    token: string;
    reply_id: string;
    message: string;
    type: string;
    user_id: string;
    thread_id?: string;
    _id: string;
    channel_name?: string;
    subserver_id?: string;
    mediaFiles?: string[];
    forwardedMessage?: string | { type: ForwardedMsgType };
    replyData?: string;
    message_id?: string;
    emoji?: string;
    channel_mentions?: string[];
    role_mentions?: string[];
}

interface NotificationsState {
    toast: Notification;
}

const initialState: NotificationsState = { toast: null };

const notificationsSlice = createSlice({
    name: 'notifications-toast',
    initialState: initialState,
    reducers: {
        addNewToast(state, action) {
            const load = action.payload;
            const message = load.message;

            state.toast = {
                channel_id: load.channel_id,
                created_at: load.created_at,
                token: load.token,
                reply_id: load.reply_id,
                message: message,
                type: load.type,
                user_id: load.user_id,
                thread_id: load?.thread_id,
                _id: load._id,
                channel_name: load?.channel_name,
                subserver_id: load?.subserver_id,
                mediaFiles: load?.mediaFiles,
                forwardedMessage: load?.forwardedMessage,
                replyData: load?.replyData,
                message_id: load?.message_id,
                emoji: load?.emoji,
                channel_mentions: load?.channel_mentions,
                role_mentions: load?.role_mentions,
            };
        },
    },
});

export const notificationsActions = notificationsSlice.actions;
export default notificationsSlice.reducer;
