import React, {
    FC,
    ReactElement,
    ReactNode,
    RefObject,
    useEffect,
    useRef,
} from 'react';

import { StatusType } from '@/constants/status';
import { optionTypes } from '@/constants/subServerOptions';

import classes from './DropDown.module.css';

type IconTypes = string | React.FC | ReactElement;
type Option =
    | StatusType
    | optionTypes
    | { icon?: IconTypes; name?: string }
    | string;
    
type propsTypes = {
    selectOptions?: any[];
    onClick?: (option: Option) => void;
    pointer?: Boolean;
    className?: String;
    header?: String | ReactNode;
    children?: ReactNode;
    onClose?: () => void;
    avoidableCLose?: RefObject<HTMLElement>;
};

const DropDown: FC<propsTypes> = props => {
    const {
        selectOptions,
        onClick,
        pointer,
        className,
        header,
        children,
        onClose,
        avoidableCLose,
    } = props;

    const dropRef = useRef();

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            const handleClickOutside = (event: any) => {
                if (
                    avoidableCLose?.current &&
                    event.target === avoidableCLose.current
                ) {
                    return;
                }
                if (ref.current && !ref.current.contains(event.target)) {
                    onClose();
                }
            };
            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(dropRef);

    return (
        <div ref={dropRef} className={classes.dropdown + ' ' + className}>
            <ul>
                {pointer && <div className={classes['dropdown-arrow']}></div>}
                {header && <h1>{header}</h1>}
                {selectOptions?.map((option, key) => {
                    let optionImage: IconTypes = '';
                    if (option?.icon && typeof option.icon === 'object') {
                        optionImage = option.icon;
                    } else if (
                        option?.icon &&
                        typeof option.icon === 'string'
                    ) {
                        optionImage = (
                            <img src={option?.icon} alt={option?.name} />
                        );
                    }

                    return (
                        <li key={key} onClick={() => onClick(option)}>
                            {optionImage}
                            {option?.name
                                ? option?.name
                                : typeof option === 'string'
                                ? option
                                : '-'}
                        </li>
                    );
                })}
                {children && <li id={classes['extra-item']}>{children}</li>}
            </ul>
        </div>
    );
};
export default DropDown;
