import React from 'react';

import warningSvg from '@/assets/icons/warning.svg';

import classes from './WarningPage.module.css';

type Props = {};

const WarningPage = (props: Props) => {
    return (
        <div className={classes['container']}>
            <div className={classes['top-area']}>
                <div className={classes['chat-logo-container']}>
                    <img alt="" src={warningSvg} />
                </div>
                <div
                    style={{
                        fontSize: 28,
                        marginTop: 24,
                        marginBottom: 12,
                        fontWeight: 500,
                    }}>
                    You are not added to server!
                </div>
                <div>
                    <span className="subHeading marginVertical-small">
                        you can ask your administrator to add you to the server
                    </span>
                </div>
            </div>
        </div>
    );
};

export default WarningPage;
