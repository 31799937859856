import React from 'react';

import classes from './InputController.module.css';
import useInputController from './useInputController';

type Props = {};

const InputControllers = (props: any) => {
    const { inputControllersComponent } = useInputController(props);
    return (
        <div className={classes['container']}>
            <div className={classes['container-wrapper']}>
                {inputControllersComponent.map((item, key) => (
                    <div
                        key={key}
                        className={classes['button-container']}
                        onClick={event => {
                            event.preventDefault();
                            item.onClick();
                        }}>
                        <span className={classes['tooltiptext']}>
                            {item.title}
                        </span>
                        <img src={item.icon} alt="" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InputControllers;
