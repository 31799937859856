import { t } from "@lingui/macro";


const useValidation = () => {

    const isRequired = (text: string): string => {
        if (text === '') return t`This field is required`;
        else return '';
    };

    const validateEmail = (email: string): string => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (email === '') return t`This field is required`;
        if (!emailRegex.test(email)) return t`Please enter a valid email`;
        return '';
    };

    const validatePassword = (password: string): string => {
        if (password === '') return t`This field is required`;
        if (password.length < 8) return t`Password must contain 8 digits`;
        if (!/(?=.*[a-z])(?=.*[A-Z])/.test(password))
            return t`Password must contain atleast one capital and one lower alphabet`;
        if (!/\d/.test(password))
            return t`Password must contain atleast one digit`;
        return '';
    };

    const validatedErrorObject = errors => {
        const errorObj = Object.keys(errors)?.reduce((acc, key) => {
            if (errors[key] !== '') {
                acc[key] = errors[key];
            }
            return acc;
        }, {});

        return errorObj;
    };

    return {
        isRequired,
        validateEmail,
        validatePassword,
        validatedErrorObject,
    };
};

export default useValidation;
