interface types1 {
    strDate: string;
    isTimeVisible?: boolean;
}

interface types2 {
    date: Date | null;
    returnTime?: Boolean;
}

export function formatDate(props: types1) {
    const { strDate, isTimeVisible } = props;
    const date = new Date(strDate ?? '');
    const day = date?.getDate();
    const month = date?.toLocaleString('default', { month: '2-digit' });
    const year = date?.getFullYear().toString().slice(-2);
    const hour = (date?.getHours() < 10 ? '0' : '') + date?.getHours();
    const minute = (date?.getMinutes() < 10 ? '0' : '') + date?.getMinutes();

    if (isTimeVisible) {
        return `${day}/${month}/${year} ${hour}:${minute}`;
    } else {
        return `${day}/${month}/${year}`;
    }
}

export const formatDate2 = ({ date, returnTime }: types2) => {
    let formattedDate: string | null;

    if (!(date instanceof Date)) {
        console.error('Invalid date object');
        formattedDate = null;
        return formattedDate;
    }

    const day = date.getDate();
    const year = date.getFullYear();
    const shortMonthName: string = new Intl.DateTimeFormat('en-US', {
        month: 'short',
    }).format(date);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;

    if (returnTime) {
        formattedDate = `${day} ${shortMonthName}, ${year} ${hours}:${minutes
            .toString()
            .padStart(2, '0')} ${ampm}`;
    } else {
        formattedDate = `${day} ${shortMonthName}, ${year}`;
    }

    return formattedDate;
};
