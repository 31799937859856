import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';

import { REQUEST_TYPE } from '@/constants/requstTypes';
import useCustomSweetAlert from '@/hooks/useCustomSweetAlert';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
    getOrganizationsMembersData,
    getOrganizationsServersData,
    patchUpdateMembertoServer,
    postAddMembertoServer,
} from '@/store/organization-reducer';

const useOrganizationAddMember = (props: any) => {
    const { successAlertModal, errorAlertModal } = useCustomSweetAlert();
    const { server, organizationList } = props;

    const orgMemberList = useAppSelector(
        state => state.organizationReducer.organizationMembers,
    );
    const allOrgMember = Object.values(orgMemberList)
        ?.map(member => member)
        ?.flat()
        ?.filter(item => item?.id === props.user?.id);

    const memberAllServer = allOrgMember?.map(item => item?.servers)?.flat();

    const [
        organizationIdForDeleteServerList,
        setOrganizationIdForDeleteServerList,
    ] = useState('');

    const [addServers, setAddServers] = useState(
        server?.filter(
            serverItem =>
                !memberAllServer?.some(
                    memberItem => memberItem?.id === serverItem?.id,
                ),
        ),
    );

    const user = useAppSelector(state =>
        state.organizationReducer.organizationMembers?.[
            organizationIdForDeleteServerList
        ]?.find(item => item.id === props.user?.id),
    );

    const [selectedServer, setSelectedServer] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const dispatch = useAppDispatch();
    const tabs = [
        {
            id: 0,
            value: 'add',
            label: t`Add`,
            data: addServers,
        },
        {
            id: 1,
            value: 'delete',
            label: t`Delete`,
            data: memberAllServer,
        },
    ];
    const [selectedTabItem, setSelectedTabItem] = useState(tabs[0]);
    const [data, setData] = useState(selectedTabItem?.data);

    const filterData = text => {
        setSearchText(text);
        if (selectedTabItem.value === 'delete') {
            return;
            // these codes were added because each server is repeated 2 times on the backend side
        }
        let tempData = [...addServers];
        tempData = tempData.filter(item =>
            item?.name?.toLowerCase().includes(text.toLowerCase()),
        );
        setData(tempData);
    };

    useEffect(() => {
        if (selectedTabItem.value === 'delete') {
            let filteredData;

            if (!memberAllServer) {
                setData([]);
                return;
            }

            const arrayHashmap = memberAllServer.reduce((obj, item) => {
                obj[item?.id]
                    ? obj[item?.id]?.elements?.push(...item?.elements)
                    : (obj[item?.id] = { ...item });
                return obj;
            }, {});

            const mergedArray = Object.values(arrayHashmap);
            filteredData = mergedArray;

            if (organizationIdForDeleteServerList) {
                if (organizationIdForDeleteServerList === 'allServers') {
                    filteredData = mergedArray;
                } else {
                    filteredData = filteredData?.filter(
                        item =>
                            item?.customer_id ===
                            organizationIdForDeleteServerList,
                    );
                }
            }

            if (searchText) {
                filteredData = filteredData?.filter(item =>
                    item?.name
                        ?.toLowerCase()
                        .includes(searchText.toLowerCase()),
                );
            }

            setData(filteredData);
        }
    }, [
        user,
        selectedTabItem,
        searchText,
        organizationIdForDeleteServerList,
        organizationList,
        successMessage,
        server,
    ]);

    useEffect(() => {
        if (selectedTabItem?.value === 'add') {
            const updatedAddServers = server?.filter(
                serverItem =>
                    !memberAllServer?.some(
                        memberItem => memberItem?.id === serverItem?.id,
                    ),
            );

            setAddServers(updatedAddServers);
            setData(updatedAddServers);
        }
    }, [user, selectedTabItem, organizationList, successMessage, server]);

    useEffect(() => {
        setSearchText('');
    }, [organizationIdForDeleteServerList]);

    const onChangeTab = item => {
        setSelectedTabItem(item);
        setSelectedServer([]);
        setSearchText('');
        setData(item?.data);
        setOrganizationIdForDeleteServerList('allServers');
    };

    const handleServerClick = item => {
        setSelectedServer(prev => {
            const isSelected = prev?.some(
                selectedItem => selectedItem.id === item.id,
            );

            if (isSelected) {
                // If the item is already selected, remove it
                return prev.filter(selectedItem => selectedItem.id !== item.id);
            } else {
                // If the item is not selected, add it
                return [...prev, item];
            }
        });
    };

    const handleContinue = () => {
        selectedServer?.map(async (item, index) => {
            let requestType =
                selectedTabItem.value === 'add'
                    ? REQUEST_TYPE.POST
                    : REQUEST_TYPE.DELETE;

            const payload = {
                organizationId: item?.customer_id,
                user_id: props.user.id,
                serverId: item?.id,
                is_admin: 0,
                requestType: requestType,
            };
            await dispatch(postAddMembertoServer(payload));

            if (organizationList?.length > 0) {
                organizationList?.forEach(el => {
                    dispatch(getOrganizationsMembersData(el.id));
                    dispatch(getOrganizationsServersData(el.id));
                });
            }
        });

        setSuccessMessage('Added Successfully');
        setSelectedServer([]);
        setTimeout(() => {
            setSuccessMessage('');
        }, 1200);
    };

    const handleMemberUpdate = async ({
        isAlertShown = true,
    }: { isAlertShown?: boolean } = {}) => {
        const data = {};
        props.formData.forEach((value, key) => {
            data[key] = value;
        });
        const payload = {
            organizationId: props.organizationId,
            userId: props.userId,
            formData: data,
        };
        const res = await dispatch(patchUpdateMembertoServer(payload));

        if (res.payload.status) {
            setSuccessMessage(res.payload.message);
            isAlertShown && successAlertModal();
            setTimeout(() => {
                setSuccessMessage('');
            }, 1000);
        } else {
            isAlertShown && errorAlertModal();
        }
    };

    const handleOnClose = () => {
        props.onClose();
        setTimeout(() => {
            setSearchText('');
            setSelectedServer([]);
            setData(selectedTabItem.data);
        }, 400); // run after close animation of 0.3s
    };

    return {
        selectedServer,
        setSelectedServer,
        handleOnClose,
        handleContinue,
        data,
        filterData,
        searchText,
        successMessage,
        handleMemberUpdate,
        handleServerClick,
        onChangeTab,
        tabs,
        selectedTabItem,
        organizationIdForDeleteServerList,
        setOrganizationIdForDeleteServerList,
        memberAllServer,
    };
};

export default useOrganizationAddMember;
