import React from 'react';

import { Icon } from '@/UI';
import { DEFAULT_IMAGES } from '@/constants';

import classes from './MentionsInput.module.css';

const MentionsListItem = ({ item, isSelected, onMouseEnter, onClick }) => {
    return (
        <div
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            style={isSelected ? { backgroundColor: 'var(--purple-2)' } : {}}>
            <li id={item?.id}>
                <div className={classes['d-flex']}>
                    {item.profile_picture !== undefined && (
                        <Icon
                            className={classes['icon']}
                            imageURL={
                                item?.profile_picture
                                    ? item?.profile_picture
                                    : DEFAULT_IMAGES.PROFILE_IMAGE
                            }
                            name={item?.name || 'icon'}
                        />
                    )}
                    <p>{item?.name}</p>
                </div>
            </li>
        </div>
    );
};

export default MentionsListItem;
