const SOCKET_ACTIONS = {
    auth: 'auth',

    // members
    memberOnline: 'members_online',
    memberUpdate: 'members_update',

    //channel
    channelJoin: 'channel_join',
    channelMessages: 'message',
    channelList: 'channel_list',
    channelPermission: 'channel_permission',
    channelSection: 'channel_section',
    channelCreate: 'channel_create',
    userChannelCreate: 'user_channel_create',
    userChannelRemove: 'user_channel_remove',
    channelDelete: 'channel_delete',
    channelEdit: 'channel_edit',
    channelMember: 'channel_member',
    channelHide: 'channel_hide',
    channelLeave: 'leave_channel',
    channelReactions: 'channel_reactions',
    fetchUserData: 'fetch_user_data',
    channelEditPriority: 'update_channels_priority',

    //public channel
    publicChannelList: 'public_channel_list',

    //section
    sectionCreate: 'section_create',
    sectionEdit: 'section_edit',
    sectionDelete: 'section_delete',

    //messages
    reactCreate: 'reaction_create',
    reactionUpdate: 'reaction_update',
    reactionDelete: 'reaction_delete',
    chat: 'message_create',
    messageCount: 'message_count',
    messageDelete: 'message_delete',
    messageEdit: 'message_edit',
    messageUpdate: 'message_update',
    messageLastseen: 'message_lastseen',
    messageRange: 'message_in_range',
    rangeMessage: 'range_message',
    getAllMessages: 'get_all_messages',
    getLastHundredMessages: 'get_last_hundred_messages',
    LastSeenUsers: 'last_seen_users',
    threadMessageDelete: 'thread_message_delete',
    threadMessageEdit: 'thread_message_edit',
    editThreadMessage: 'edit_thread_message',
    getThreads: 'get_threads',
    replyMessage: 'reply_message',

    //role
    role: 'role_list',
    permissionList: 'permission_list',
    rolePermission: 'role_permission',
    roleCreate: 'role_create',
    roleUpdate: 'role_update',
    roleDelete: 'role_delete',

    userRoles: 'user_role',
    userRoleCreate: 'user_role_create',
    userRoleDelete: 'user_role_delete',

    //notifications
    mentionNotificaitionList: 'message_mention',
    mentionNotificationUpdate: 'message_mention_update',
    reactionNotificationList: 'reaction_notification',
    reactionNotificationUpdate: 'reaction_notification_update',
    notification: 'notification',
    getNotifications: 'get_notifications',
    markedAllRead: 'marked_all_read_notifications',

    //channel notifications
    channelNotificationList: 'channel_notifications_list',
    channelNotificationUpdate: 'channel_notification',
    ReadAllMessages:'read_all_messages',
    permissionMe: 'me',

    fetchData: 'fetch_data',

    //user status
    updateUserStatus: 'update_user_status',
    userStatus: 'users_status',
    memberStatusUpdate: 'member_status_update',
    userBanUpdate: 'user_ban_update',

    //add role in channel
    addRoleInChannel: 'add_role_in_channel',
    addUserInChannel: 'add_user_in_channel',

    //remove role in channel
    removeRoleInChannel: 'remove_role_in_channel',
    removeUserFromChannel: 'remove_user_from_channel',

    //search
    search: 'search',
    searchResult: 'search_results',
    timezone: 'timezone',

    // audit log
    getAuditLogs: 'get_audit',

    //statistics
    getStatistics: 'message_statistic',

    // channel users permissions
    getChannelUsersPermissions: 'get_channel_users_permissions',
    createChannelUsersPermissions: 'create_channel_users_permissions',
    fetchChannelPermissions: 'fetch_channel_permissions',

    //message threads
    createThread: 'create_thread',
    getThreadMessages: 'get_thread_messages',
    createThreadMessages: 'create_thread_message',
    threadMessageUpdate: 'thread-message-update',
    joinThread: 'join_thread',
    openThread: 'open_thread',
    leaveThread: 'leave_thread',
    getThreadDetails: 'get_thread_details',
    getThreadMembers: 'get_thread_members',
    threadMessage: 'thread-message',
    deleteThread: 'delete_thread',
    threadDeleted: 'thread-deleted',
    threadLock: 'lock_thread',
    threadLockBroadcast: 'thread-lock',
    addThreadReaction: 'add_thread_reaction',
    deleteThreadMessage: 'delete_thread_message',
    threadReactionUpdate: 'thread_reaction_update',
    deleteThreadReaction: 'delete_thread_reaction',

    //management
    getInterServerList: 'get_inter_subserver_list',
    AddChannelToOtherServer: 'add_channel_to_another_server',
     deleteInterSubserverChannel: 'delete_inter_subserver'
};

type SocketAction = (typeof SOCKET_ACTIONS)[keyof typeof SOCKET_ACTIONS];

type SocketActionTypes = {
    [K in keyof typeof SOCKET_ACTIONS]: {
        action: SocketAction;
        success: SocketAction & '.success';
        error: SocketAction & '.error';
    };
};

const addSuccessAndErrorTypes = actions => {
    const updatedActions = {};
    Object.keys(actions).forEach(key => {
        const action = actions[key];
        updatedActions[key] = {
            action,
            success: `${action}.success`,
            error: `${action}.error`,
        };
    });
    return updatedActions;
};

const SOCKET_ACTIONS_WITH_TYPES = addSuccessAndErrorTypes(SOCKET_ACTIONS);

export default SOCKET_ACTIONS_WITH_TYPES as SocketActionTypes;
