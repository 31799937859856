import React, { useEffect, useMemo, useRef, useState } from 'react';

const useMentionsInput = (props: any) => {
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const { data, onSubmit, filterKey, mentionText } = props;
    const ulRef = useRef(null);

    // Filter out items where selectable is false
    const filteredData = useMemo(() => {
        return data?.filter(
            item =>
                (item?.['username']
                    ?.toLowerCase()
                    ?.includes(mentionText?.toLowerCase()) ||
                    item?.['name']
                        ?.toLowerCase()
                        ?.includes(mentionText?.toLowerCase())) &&
                item?.selectable !== false,
        );
    }, [data, mentionText]);

    // Handle scrolling to the selected item
    const handleScroll = index => {
        const selectedListItem = ulRef.current.children[index];
        if (selectedListItem) {
            selectedListItem.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    };

    const handleSubmit = () => {
        // Check if filteredData is available and the selected item index is within bounds
        if (
            filteredData &&
            filteredData.length > 0 &&
            selectedItemIndex >= 0 &&
            selectedItemIndex < filteredData.length
        ) {
            onSubmit(filteredData[selectedItemIndex]);
        }
    };

    const handleMouseEnter = item => {
        setSelectedItemIndex(item);
    };

    // Function to handle keyboard events
    const handleKeyDown = e => {
        if (filteredData && filteredData.length > 0) {
            if (e.key === 'ArrowUp' && selectedItemIndex > 0) {
                setSelectedItemIndex(selectedItemIndex - 1);
                handleScroll(selectedItemIndex - 2);
            } else if (
                e.key === 'ArrowDown' &&
                selectedItemIndex < filteredData.length - 1
            ) {
                setSelectedItemIndex(selectedItemIndex + 1);
                handleScroll(selectedItemIndex + 2);
            } else if (e.key === 'Enter') {
                handleSubmit();
            }
        }
    };

    // Add event listener on component mount
    useEffect(() => {
        if (ulRef.current) {
            document.addEventListener('keydown', handleKeyDown);
            return () => {
                // Remove event listener on component unmount
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [selectedItemIndex, ulRef]);

    return {
        ulRef,
        selectedItemIndex,
        handleMouseEnter,
        handleSubmit,
        filteredData,
    };
};

export default useMentionsInput;
