const ManageIcon = ({ stroke }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="vuesax/linear/category">
                <g id="category">
                    <path
                        id="Vector"
                        d="M3.3335 6.66671H4.66683C6.00016 6.66671 6.66683 6.00004 6.66683 4.66671V3.33337C6.66683 2.00004 6.00016 1.33337 4.66683 1.33337H3.3335C2.00016 1.33337 1.3335 2.00004 1.3335 3.33337V4.66671C1.3335 6.00004 2.00016 6.66671 3.3335 6.66671Z"
                        stroke={stroke ? stroke : 'white'}
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M11.3335 6.66671H12.6668C14.0002 6.66671 14.6668 6.00004 14.6668 4.66671V3.33337C14.6668 2.00004 14.0002 1.33337 12.6668 1.33337H11.3335C10.0002 1.33337 9.3335 2.00004 9.3335 3.33337V4.66671C9.3335 6.00004 10.0002 6.66671 11.3335 6.66671Z"
                        stroke={stroke ? stroke : 'white'}
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_3"
                        d="M11.3335 14.6667H12.6668C14.0002 14.6667 14.6668 14 14.6668 12.6667V11.3334C14.6668 10 14.0002 9.33337 12.6668 9.33337H11.3335C10.0002 9.33337 9.3335 10 9.3335 11.3334V12.6667C9.3335 14 10.0002 14.6667 11.3335 14.6667Z"
                        stroke={stroke ? stroke : 'white'}
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_4"
                        d="M3.3335 14.6667H4.66683C6.00016 14.6667 6.66683 14 6.66683 12.6667V11.3334C6.66683 10 6.00016 9.33337 4.66683 9.33337H3.3335C2.00016 9.33337 1.3335 10 1.3335 11.3334V12.6667C1.3335 14 2.00016 14.6667 3.3335 14.6667Z"
                        stroke={stroke ? stroke : 'white'}
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
};
export default ManageIcon;
