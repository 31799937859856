import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isNotesOpen: false,
};

const notesSlice = createSlice({
    name: 'notes',
    initialState: initialState,
    reducers: {
        toggleNotesWindow(state, action) {
            state.isNotesOpen = action.payload;
        },
    },
});

export const notesActions = notesSlice.actions;
export default notesSlice.reducer;
