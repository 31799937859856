let store = null;

export function setStore(value) {
    store = value;
}

export function getStore() {
    return store;
}

export function getStoreState() {
    return store?.getState() ?? {};
}

export function dispatchAction(action) {
    const { dispatch } = store;
    dispatch(action);
}
