import { FC, memo } from 'react';

import SelectedMediaDisplay from '@/UI/display-media/SelectedMediaDisplay';
import DisplayOnly from '@/UI/display-media/reply-media/DisplayOnly';

import classes from './MediaMessage.module.css';
import { MediaTypes } from './MediaMessageTypes';

type Props = {
    allowView?: boolean;
    mediaFiles: MediaTypes[];
    className?: string;
    noCopy?: boolean;
    msg?: {
        owner: { name: string; profile_picture?: string };
        message: string;
    };
};

const MediaMessage: FC<Props> = (props: Props) => {
    const {
        allowView = false,
        mediaFiles,
        className = '',
        noCopy,
        msg,
    } = props;

    return (
        <div className={classes['media-message-container'] + ' ' + className}>
            {mediaFiles?.length > 0 && (
                <>
                    {allowView ? (
                        <SelectedMediaDisplay
                            allowView={allowView}
                            msg={msg}
                            noCopy={noCopy}
                            files={mediaFiles}
                        />
                    ) : (
                        <DisplayOnly files={mediaFiles} />
                    )}
                </>
            )}
        </div>
    );
};
export default memo(MediaMessage);
