import { RootState } from '..';
import { createSelector } from '@reduxjs/toolkit';

import { IChannelData, ISectionList } from '@/types';

const channelListState = (state: RootState) =>
    state.serverDataReducer.channelList;
const activeServerState = (state: RootState) =>
    state.serverDataReducer.activeServer;
const activeChannelState = (state: RootState) =>
    state.serverDataReducer.activeServer;
const memberListState = (state: RootState) =>
    state.serverDataReducer.memberList;
const serverDataState = (state: RootState) =>
    state.serverDataReducer.serverData;
const sectionListState = (state: RootState) =>
    state.serverDataReducer.sectionList;
const channelCountListState = (state: RootState) =>
    state.serverDataReducer.channelCounts;
const permissionsMeState = (state: RootState) =>
    state.serverDataReducer.userPermissions;
const otherServerChannelsState = (state: RootState) =>
    state.serverDataReducer.otherServerChannels;

const notificationListState = (state: RootState) =>
    state.serverDataReducer.notifications;

export const activeServerIdSelector = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer }) => serverDataReducer.activeServer,
);

export const activeChannelIdSelector = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer }) =>
        serverDataReducer.activeChannelId[serverDataReducer.activeServer],
);

export const privateKeyArray = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer }) =>
        Object.values(serverDataReducer.serverData)
            .filter(item => item.privateKey)
            .map(item => {
                return { id: item.id, privateKey: item.privateKey };
            }),
);

export const serverDataSelector = createSelector(
    [serverDataState],
    serverData => {
        const servers = serverData || {};
        return Object.values(servers);
    },
);

export const serverDetailSelector = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer }) => {
        if (serverDataReducer.activeServer)
            return (
                serverDataReducer.serverData[serverDataReducer.activeServer] ??
                {}
            );
    },
);

export const channelDataSelector = createSelector(
    [channelListState, activeServerState],
    (channelList, activeServer) => {
        const channels = channelList[activeServer] || {};
        return Object.values(channels);
    },
);

export const directChannelDataSelector = createSelector(
    [channelListState, activeServerState],
    (channelList, activeServer) => {
        const channels = channelList[activeServer] || {};
        return Object.values(channels).filter(
            item => item.privacy === 'direct',
        );
    },
);

export const channelDataDetailsSelector = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer }) =>
        serverDataReducer?.channelList[serverDataReducer?.activeServer]?.[
            serverDataReducer.activeChannelId[serverDataReducer.activeServer]
        ] ?? {},
);

export const publicChannelDataSelector = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer }) =>
        Object.values(
            serverDataReducer?.publicChannelList[
                serverDataReducer?.activeServer
            ] ?? {},
        ),
);

export const allServersChannels = createSelector(
    (state: RootState) => state.serverDataReducer.serverData,
    serverData => {
        if (!serverData) {
            return [];
        }

        let channelsAll = Object.keys(serverData)?.reduce((channels, key) => {
            if (serverData[key]?.channelList) {
                channels.push(...Object.values(serverData[key]?.channelList));
            }

            return channels;
        }, []);

        return channelsAll;
    },
);

export const memberDataSelector = createSelector(
    [activeServerState, memberListState],
    (activeServer, memberList) => {
        const members = memberList[activeServer] || {};
        return Object.values(members);
    },
);

export const interServerDataSelector = createSelector(
    [activeServerState, otherServerChannelsState],
    (activeServer, otherServerChannel) => {
        const channels = otherServerChannel[activeServer] || {};
        return Object.values(channels).reverse();
    },
);

export const sectionDataSelector = createSelector(
    [activeServerState, sectionListState, channelListState],
    (activeServer, sectionList, channelList) => {
        const activeServerId = activeServer || '';
        const sections = sectionList[activeServerId] || {};
        const channels = channelList[activeServerId] || {};

        const mapChanneltoSection = Object.values(sections).map(
            (sect: ISectionList) => ({
                ...sect,
                channelList: Object.values(channels).filter(
                    (item: IChannelData) =>
                        item?.channel_section_id === sect?._id,
                ),
            }),
        );

        return mapChanneltoSection;
    },
);

// Channel Members Selector
export const channelMembersSelector = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer }) =>
        serverDataReducer?.channelList[serverDataReducer.activeServer]?.[
            serverDataReducer?.activeChannelId[serverDataReducer.activeServer]
        ]?.memberList,
);

export const rolesListSelector = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer }) =>
        serverDataReducer.rolesList?.[serverDataReducer.activeServer],
);

export const permissionListSelector = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer }) =>
        serverDataReducer.permissionList[serverDataReducer.activeServer],
);

export const userPermissionSelector = createSelector(
    [permissionsMeState, activeServerState],
    (userPermissions, activeServer) => userPermissions[activeServer],
);

export const notificationSelector = createSelector(
    [notificationListState, activeServerState],
    (notifcationList, activeServer) => notifcationList[activeServer],
);

export const mentionNotificationSelector = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer }) =>
        serverDataReducer.mentionsNotifications[serverDataReducer.activeServer],
);

export const reactionNotificationSelector = createSelector(
    (state: RootState) => state,
    ({ serverDataReducer }) => {
        return serverDataReducer.reactionsNotifications[
            serverDataReducer.activeServer
        ];
    },
);

export const channelCountsListSelector = createSelector(
    [channelListState, channelCountListState, activeServerState],
    (channelList, countList, activeServer) => {
        const channelCounts = Object.values(
            channelList[activeServer] ?? {},
        ).map((ch: any) => countList?.[activeServer]?.[ch._id] || 0);
        return channelCounts;
    },
);
