import { Trans, t } from '@lingui/macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Icon } from '@/UI';
import Card from '@/UI/card/Card';
import { DisabledInput } from '@/UI/disabledInput/DisabledInput';
import Line from '@/UI/hr/Line';
import { getServer, uploadFiles } from '@/Utils/serverMediaHandler.js';
import { hideDirectChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import { sendMessage } from '@/Utils/socket-actions/MessageActions/messageActions';
import EditChannel from '@/components/EditChannel/EditChannel';
import { ThreadList } from '@/components/ThreadList/ThreadList';
import Search from '@/components/search/Search';
import SearchDesktop from '@/components/search/SearchDesktop';
import { useAppSelector } from '@/hooks/useRedux';
import { indexedDBSearchActions } from '@/store/search-reducer/indexed-db-search-reducer.js';
import {
    activeChannelIdSelector,
    channelDataDetailsSelector,
    memberDataSelector,
    serverDetailSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import MessageList from '../messages/message-list/MessageList';
import NewMessageForm from '../messages/new-message/NewMessageForm';
import SearchProvider from '../search/SearchContext';

import classes from './ChatAreaSection.module.css';
import ChatAreaHeader from './chat-area-header/ChatAreaHeader';

const ChatAreaSection = props => {
    const megContainerRef = useRef();
    const [replyData, setReplyData] = useState({});
    const [isReply, setIsReply] = useState(false);
    const [member, setMember] = useState<any>([]);
    const [mediaUploaded, setMediaUploaded] = useState(false);
    const [isSearchInput, setIsSearchInput] = useState(false);
    const [isThreadList, setIsThreadList] = useState(false);
    const [isEditChannel, setIsEditChannel] = useState(false);
    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const dispatch = useDispatch();
    const user = useAppSelector(state => state.userReducer.user);

    const allMembers = useAppSelector(state => memberDataSelector(state));

    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const isConnected = useAppSelector(state => state.socketConnectionReducer);
    const serverData_chat_bg = useAppSelector(
        state => serverDetailSelector(state).chat_bg,
    );
    const serverLink = process.env.REACT_APP_GET_SERVER_LINK_URL;

    let channelDetails: any = useAppSelector(channelDataDetailsSelector);

    let channelPermissions =
        channelDetails?.channelPermissions?.['message.create'];

    const [uploadProgress, setUploadProgress] = useState(0);

    const handleUploadProgress = progress => {
        const roundedProgress = Math.round(progress);
        setUploadProgress(roundedProgress === 100 ? 0 : roundedProgress);
    };

    const sendMediaHandler = async args => {
        const subserver = activeServer;
        const filesArray = [];

        const submitAll = async mediaFile => {
            const { responseLink } = await getServer(mediaFile, serverLink);
            const { uploadedFileLink } = await uploadFiles(
                mediaFile.file,
                subserver,
                responseLink,
                user.token,
                activeChannelId,
                true,
                handleUploadProgress,
            );
            return { uploadedFileLink: uploadedFileLink.data };
        };

        const promises = args?.mediaFiles?.map(async mediaFile => {
            const { uploadedFileLink } = await submitAll(mediaFile);
            filesArray.push({
                ...uploadedFileLink,
                type: mediaFile.type,
                name: mediaFile.name,
                size: mediaFile.size,
            });
        });
        await Promise.all(promises);

        if (filesArray.length === args.mediaFiles.length) {
            const payload = {
                type: 'media',
                replyData: args?.replyData,
                message: args.textMessage ? args.textMessage : '',
                mediaFiles: filesArray,
            };

            sendMessage(payload);
            setMediaUploaded(true);
        } else {
            alert('One or more media files could not be uploaded.');
        }
    };

    useEffect(() => {
        if (mediaUploaded) {
            setMediaUploaded(false);
        }
    }, [mediaUploaded]);

    const sendMessageHandler = message => {
        if (channelDetails?.is_hidden) {
            hideDirectChannel(channelDetails._id, false);
        }
        if (message.type === 'media') {
            sendMediaHandler(message.message);
        } else {
            sendMessage(message);
        }
    };

    const handleReplyMessage = useCallback(data => {
        setIsReply(true);
        setReplyData(data);
    }, []);

    const closeReplyHandler = () => {
        setIsReply(false);
    };

    useEffect(() => {
        setMember('');
        if (channelDetails?.privacy === 'direct') {
            let privateChName = channelDetails.name.split('_');
            if (
                privateChName.length === 1 ||
                privateChName[0] === privateChName[1]
            ) {
                // private message from 1 end (saved messages)
                privateChName = privateChName.find(ch => ch === user.id);
            } else {
                privateChName = privateChName.find(ch => ch !== user.id); // choose the other id than you id
            }

            const chatMember = allMembers.find(e => e.id === privateChName);
            setMember(chatMember);
        }
        setIsReply(false);
    }, [channelDetails]);

    useEffect(() => {
        setIsReply(false);
        setReplyData({});
    }, [mediaUploaded]);

    const handleClose = () => {
        setIsSearchInput(false);
        dispatch(indexedDBSearchActions.setSearchError(''));
    };

    const showDescription =
        props.type === 'ACTIVE_CHANNEL' &&
        channelDetails?.privacy === 'channel' &&
        props?.channel?.description;

    return (
        <section className={classes['chat-area-container']}>
            <Card className={classes.chatCard}>
                <div
                    className={classes['chat-bg']}
                    style={{
                        backgroundImage: `url(${
                            channelDetails?.background_image ??
                            serverData_chat_bg ??
                            ''
                        })`,
                    }}></div>
                <ChatAreaHeader
                    member={member}
                    setIsSearchInput={setIsSearchInput}
                    setIsThreadList={setIsThreadList}
                    setIsEditChannel={setIsEditChannel}
                    {...props}
                />
                {activeChannelId && <Line className={classes['strong-hr']} />}

                <div className={classes['d-flex']}>
                    <div
                        ref={megContainerRef}
                        className={classes['messages-area-container']}>
                        {showDescription && (
                            <>
                                <div className={classes['channel-headline']}>
                                    <Icon
                                        className={classes['channel-img']}
                                        imageURL={props.roomImg}
                                    />
                                    <div>
                                        <h2>
                                            <Trans>Room Greeting</Trans>
                                        </h2>
                                        <p>{channelDetails?.description}</p>
                                    </div>
                                </div>
                                <Line className={classes.hr} />
                            </>
                        )}
                        <div className={classes['messages-container']}>
                            {activeChannelId &&
                            activeChannelId !== '' &&
                            props.type !== 'NEW' ? (
                                <MessageList
                                    onReplyMessage={handleReplyMessage}
                                    onNewChat={props.onNewChat}
                                />
                            ) : (
                                <div></div>
                            )}
                            {(activeChannelId || props?.type === 'NEW') &&
                                (activeChannelId !== undefined &&
                                channelPermissions !== undefined &&
                                !channelPermissions ? (
                                    <DisabledInput
                                        placeholder={t`You don't have permissions to send message`}
                                    />
                                ) : (
                                    <div
                                        className={
                                            classes[
                                                props?.type === 'NEW'
                                                    ? 'new-chat-input'
                                                    : null
                                            ]
                                        }>
                                        <NewMessageForm
                                            mediaUploaded={mediaUploaded}
                                            onSendMessage={sendMessageHandler}
                                            replyTo={replyData}
                                            isReply={isReply}
                                            closeReply={closeReplyHandler}
                                            activeChannelId={activeChannelId}
                                            uploadProgress={uploadProgress}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </Card>
            {isSearchInput &&
                (isConnected.WSSearch ? (
                    <SearchDesktop
                        searchTitle={t`Search Results`}
                        onClose={() => setIsSearchInput(false)}
                    />
                ) : (
                    <SearchProvider
                        onClose={() => handleClose()}
                        from="ChatAreaSection">
                        <Search searchTitle={t`Search From Channel`} />
                    </SearchProvider>
                ))}
            {isThreadList && <ThreadList setIsThreadList={setIsThreadList} />}
            {isEditChannel && (
                <EditChannel
                    channel={props.channel}
                    onClose={() => {
                        setIsEditChannel(false);
                    }}
                />
            )}
        </section>
    );
};

export default React.memo(ChatAreaSection);
