import React, { CSSProperties, forwardRef, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import { NotesContext } from '@/context/notes/NotesContext';

import classes from './Notes.module.css';

const ContentGrid = forwardRef<
    HTMLDivElement,
    {
        children: React.ReactNode;
        style?: CSSProperties;
        className?: string;
    }
>((props, ref) => {
    const { className = '', children, style } = props;
    const context = useContext(NotesContext);
    const { isFullMode, activeMobileStep } = context;
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const styles: CSSProperties = {
        display: 'grid',
        gridTemplateColumns:
            !isMobile && isFullMode
                ? '0px 0px auto'
                : !isMobile && !isFullMode
                ? '240px 350px auto'
                : isMobile && activeMobileStep === 0
                ? '40% 60%'
                : '100%',
        ...style,
    };

    return (
        <div
            style={styles}
            className={`${classes['grid']} ${className}`}
            ref={ref}>
            {children}
        </div>
    );
});

export default ContentGrid;
