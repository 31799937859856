import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Badge } from '@/UI';
import BackdropModal from '@/UI/backdrop-modal/BackdropModal';
import { getMembersbyRoles } from '@/Utils/memberUtils';
import CustomErrorBoundary from '@/components/CustomErrorBoundary/CustomErrorBoundary';
import arrowDownIcon from '@/assets/icons/arrow-down.svg';
import { IMemberData } from '@/types';

import classes from './Connections.module.css';
import ConnectionsSearch from './ConnectionsSearch';
import ConnectionsSearchEmpty from './ConnectionsSearchEmpty';
import ConnectionsViewPort from './ConnectionsViewPort';
import useChannelMemberDetails from './useChannelMemberDetails';
import UserModal from './user-modal/UserModal';
import { t } from '@lingui/macro';

const Connections = () => {
    const viewPortRif = useRef(null);
    const [modalStyle, setModalStyle] = useState({});
    const [searchResult, setSearchResult] = useState([]);
    const [isToggleOnline, setIsToggleOnline] = useState(true);
    const [isToggleGroup, setIsToggleGroup] = useState(true);
    const [isToggleOffline, setIsToggleOffline] = useState(false);
    const [search, setSearch] = useState('');

    const [userModal, setUserModal] = useState({
        isShown: false,
        userData: {},
        ModalYPos: 0,
    });

    const { sortedMembers, offlineMembers, onlineMembers } =
        useChannelMemberDetails();
    const { groupedMembers, membersWithoutRoles } =
        getMembersbyRoles(onlineMembers);

    const hideModalHandler = () => {
        setUserModal({
            isShown: false,
            userData: {},
            ModalYPos: 0,
        });
    };

    const handleSearch = (searchText: string) => {
        setSearch(searchText);
        const search =
            searchText?.length > 0
                ? sortedMembers?.filter((member: IMemberData) => {
                      return (
                          member?.username
                              ?.toLowerCase()
                              ?.includes(searchText?.toLowerCase()) ||
                          member?.name
                              ?.toLowerCase()
                              ?.includes(searchText?.toLowerCase())
                      );
                  })
                : [];
        setSearchResult(search);
    };

    const openUserProfileHandler = useCallback(data => {
        const itemYPos = document
            .getElementById(`user-item-${data.id}`)
            .getBoundingClientRect().top;

        const userModalData = {
            isShown: true,
            userData: data,
            ModalYPos: itemYPos,
        };
        setUserModal(userModalData);
    }, []);

    const AccordionHeader = ({ index, title, length }) => {
        const toggleHandler = () => {
            index === 0 && setIsToggleGroup(prev => !prev);
            index === 1 && setIsToggleOnline(prev => !prev);
            index === 2 && setIsToggleOffline(prev => !prev);
        };

        const style =
            (index === 0 && isToggleGroup) ||
            (index === 1 && isToggleOnline) ||
            (index === 2 && isToggleOffline)
                ? { transform: 'rotate(0deg)' }
                : { transform: 'rotate(-90deg)' };

        return (
            <div onClick={toggleHandler} className={classes.header}>
                <img
                    src={arrowDownIcon}
                    alt={'hide'}
                    style={style}
                    className={classes['arrowDownIcon']}
                />
                <p>{title}</p>
                <Badge className={classes['badge']}>{length}</Badge>
            </div>
        );
    };

    useEffect(() => {
        const dist = viewPortRif.current.offsetWidth + 16;
        setModalStyle({ right: `${dist}px` });
    }, [viewPortRif]);

    return (
        <section ref={viewPortRif} className={classes['connections-section']}>
            <CustomErrorBoundary showErrorDetail>
                <ConnectionsSearch search={search} onSearch={handleSearch} />
                {search.trim() !== '' && searchResult.length === 0 ? (
                    <ConnectionsSearchEmpty />
                ) : (
                    <AnimatePresence>
                        {searchResult.length > 0 ? (
                            <ConnectionsViewPort
                                items={searchResult}
                                viewPortRif={viewPortRif}
                                onOpenProfileModal={openUserProfileHandler}
                            />
                        ) : (
                            <>
                                {groupedMembers?.length > 0 &&
                                    groupedMembers?.map((item, index) => (
                                        <div key={index}>
                                            <AccordionHeader
                                                index={0}
                                                length={item.members.length}
                                                title={item.name}
                                            />
                                            {isToggleGroup && (
                                                <ConnectionsViewPort
                                                    items={item.members}
                                                    viewPortRif={viewPortRif}
                                                    onOpenProfileModal={
                                                        openUserProfileHandler
                                                    }
                                                />
                                            )}
                                        </div>
                                    ))}

                                <AccordionHeader
                                    index={1}
                                    length={membersWithoutRoles?.length}
                                    title={t`Online`}
                                />
                                {isToggleOnline &&
                                    membersWithoutRoles?.length > 0 && (
                                        <ConnectionsViewPort
                                            items={membersWithoutRoles}
                                            viewPortRif={viewPortRif}
                                            onOpenProfileModal={
                                                openUserProfileHandler
                                            }
                                        />
                                    )}

                                <AccordionHeader
                                    index={2}
                                    length={offlineMembers?.length}
                                    title={t`Offline`}
                                />
                                {isToggleOffline &&
                                    offlineMembers?.length > 0 && (
                                        <ConnectionsViewPort
                                            items={offlineMembers}
                                            viewPortRif={viewPortRif}
                                            onOpenProfileModal={
                                                openUserProfileHandler
                                            }
                                        />
                                    )}

                                {userModal.isShown && userModal.userData && (
                                    <>
                                        <BackdropModal
                                            onHide={hideModalHandler}
                                        />
                                        <UserModal
                                            style={modalStyle}
                                            user={userModal.userData}
                                            YPosition={userModal.ModalYPos}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </AnimatePresence>
                )}
            </CustomErrorBoundary>
        </section>
    );
};

export default React.memo(Connections);
