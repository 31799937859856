import { Trans, t } from '@lingui/macro';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { AppButton, TextInput } from '@/UI';
import FormikSelect from '@/UI/formik-select/FormikSelect';
import {
    addNewChannelToInterServer,
    deleteChannelToInterServer,
} from '@/Utils/socket-actions/InterSubServerActions/intersubServerActions';
import { truncateText } from '@/Utils/textUtils';
import { sendWebSocketData } from '@/Utils/webSocketUtils';
import { BUTTON_SIZE } from '@/constants/enum';
import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';
import { useCustomSweetAlert } from '@/hooks';
import { useAppSelector } from '@/hooks/useRedux';
import {
    activeServerIdSelector,
    channelDataSelector,
    interServerDataSelector,
    serverDataSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import {
    setInterServerError,
    setInterServerSuccess,
} from '@/store/util-reducer';

import classes from './ServerManagement.module.css';
import TableServerManagement from './table/TableServerManagement';

const validationSchema = Yup.object().shape({
    current_channel_id: Yup.string().required(t`Select Channel`),
    receiver_subserver_id: Yup.string().required('Select Target Server'),
    receiver_channel_id: Yup.string().required('Enter Target Channel ID'),
});

const ServerManagement = () => {
    const channelList = useAppSelector(channelDataSelector)?.filter(
        item => item.privacy === 'channel',
    );
    const serverList = useAppSelector(serverDataSelector);
    const activeServer = useAppSelector(activeServerIdSelector);
    const visibleChannels = channelList.filter(ch => !ch.is_hidden);
    const otherServerList = useAppSelector(state =>
        interServerDataSelector(state),
    );
    const { errorAlertModal, successAlertModal } = useCustomSweetAlert();
    const dispatch = useDispatch();
    const { interServerError, interServerSuccess } = useAppSelector(
        state => state.utilReducer,
    );

    let visibleChannelsOptions = visibleChannels?.map(channel => ({
        value: channel._id,
        label: truncateText(channel.name, 25),
    }));
    let serverListOptions = serverList?.map(server => ({
        value: server.id,
        label: truncateText(server.name, 25),
    }));

    visibleChannelsOptions.unshift({ value: '', label: t`Select Channel` });
    serverListOptions.unshift({ value: '', label: t`Select Target Server` });

    useEffect(() => {
        if (interServerSuccess) {
            successAlertModal(t`Success`, t`Channel Added Successfully`, () => {
                dispatch(setInterServerSuccess(false));
            });
        }
    }, [interServerSuccess]);

    useEffect(() => {
        if (interServerError.length > 0) {
            errorAlertModal(t`ERROR`, interServerError, () => {
                dispatch(setInterServerError(''));
            });
        }
    }, [interServerError]);

    useEffect(() => {
        let payload = {
            action: SOCKET_ACTIONS_WITH_TYPES.getInterServerList.action,
            data: { subserver_id: activeServer },
        };
        sendWebSocketData(JSON.stringify(payload));
    }, []);

    const handleAddToServer = values => {
        addNewChannelToInterServer(values);
    };

    const handleDeleteInterServer = (id: string) => {
        deleteChannelToInterServer(id);
    };

    return (
        <div className={classes['container']}>
            <div className={classes['header']}>
                <h2 style={{ margin: 0 }}>
                    <Trans>Server Management</Trans>
                </h2>
            </div>
            <Formik
                initialValues={{
                    current_channel_id: '',
                    receiver_subserver_id: '',
                    receiver_channel_id: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    handleAddToServer(values);
                    actions.resetForm();
                }}>
                {({ isSubmitting }) => (
                    <Form>
                        <div className={classes['form-wrap']}>
                            <div className={classes['channel-select-wrap']}>
                                <label
                                    className={classes['section-label']}
                                    htmlFor="channel-section">
                                    <Trans>Select Channel</Trans>
                                </label>
                                <FormikSelect
                                    name="current_channel_id"
                                    className={classes['section-select']}
                                    options={visibleChannelsOptions}
                                    placeholder={t`Select Channel`}
                                    classNamePrefix={classes['react-select']}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                }}>
                                <label
                                    className={classes['section-label']}
                                    htmlFor="channel-section">
                                    <Trans>Select Target Server</Trans>
                                </label>
                                <FormikSelect
                                    name="receiver_subserver_id"
                                    className={classes['section-select']}
                                    options={serverListOptions}
                                    placeholder={t`Select Channel`}
                                    classNamePrefix={classes['react-select']}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    height: 50,
                                }}>
                                <label
                                    className={classes['section-label']}
                                    htmlFor="channel-section">
                                    <Trans>Enter Target Channel ID</Trans>
                                </label>
                                <div className={classes['channel-id-wrap']}>
                                    <Field
                                        type="text"
                                        name="receiver_channel_id"
                                        placeholder={t`Enter Channel Id`}
                                        className={classes['section-input']}>
                                        {({ field }) => (
                                            <TextInput
                                                {...field}
                                                style={{
                                                    fontSize: 14,
                                                    height: 16,
                                                }}
                                                placeholder={t`Enter Target Channel ID`}
                                                value={truncateText(
                                                    field.value,
                                                    25,
                                                )}
                                                onChange={field.onChange}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <ErrorMessage
                                    name="receiver_channel_id"
                                    component="div"
                                    className={classes['error-input']}
                                />
                            </div>
                        </div>
                        <div className={classes['btn-wrap']}>
                            <AppButton
                                type="submit"
                                disabled={isSubmitting}
                                buttonSize={BUTTON_SIZE.small}
                                title={t`Add`}
                                classStyles={classes['btn']}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
            <div style={{ marginTop: 10 }}>
                <TableServerManagement
                    data={otherServerList}
                    deleteInterServer={handleDeleteInterServer}
                />
            </div>
        </div>
    );
};

export default ServerManagement;
