import store from '@/store';
import { updatePing, updatePingsCount } from '@/store/util-reducer';

import { sendWebSocketData } from './webSocketUtils';

export const sendPing = () => {
    let payload = {
        action: 'ping',
        data: {},
    };
    const currentTime = new Date();

    store.dispatch(updatePingsCount());
    sendWebSocketData(JSON.stringify(payload));
    store.dispatch(updatePing(currentTime.getTime()));
};
