import { useCallback, useEffect, useRef, useState } from 'react';

import { sendInitialsAgain } from '@/Utils/socket-actions/AuthActions/send-auth';
import {
    hideDirectChannel,
    onJoinChannel,
} from '@/Utils/socket-actions/ChannelActions/channelActions';
import {
    connect,
    connectNotificationServer,
    connectWSSearch,
} from '@/Utils/socket-actions/connect-socket';
import {
    closeP2PSocket,
    connectP2PSocket,
} from '@/Utils/socket-actions/p2p-connection/p2pConnection';
import { closeWebSocket } from '@/Utils/webSocketUtils';
import roomImg from '@/assets/images/hash_bg.png';
import useChatActions from '@/hooks/useChatActions';
import useLogoutHandler from '@/hooks/useLogoutHandler';
import { useAppSelector } from '@/hooks/useRedux';
import {
    activeChannelIdSelector,
    activeServerIdSelector,
    channelDataDetailsSelector,
    channelDataSelector,
    serverDetailSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import { IServerData } from '@/types';

const useHomeContent = () => {
    const didMountRef = useRef(false);

    const { logoutHandler } = useLogoutHandler();

    const [chatAreaSectionConfig, setChatAreaSectionConfig] = useState({});
    const [prevConnectionLink, setPrevConnectionLink] = useState('');
    const [userArray, setUserArray] = useState<string[]>([]);

    const activeServer = useAppSelector(activeServerIdSelector);
    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );
    const userId = useAppSelector(state => state.userReducer.user.id);
    const userToken = useAppSelector(state => state.userReducer.user.token);

    const channel = useAppSelector(channelDataSelector);
    let channelDetails: any = useAppSelector(channelDataDetailsSelector);
    const server: IServerData = useAppSelector(serverDetailSelector);
    const { createDMChannel } = useChatActions();

    const privateKey = server?.privateKey ?? '';
    const publicKey = server?.publicKey ?? '';

    const createNewChatTemplate = useCallback((userArray: string[]) => {
        setUserArray(userArray);
    }, []);

    const notificationLink = useAppSelector(
        state => state.userReducer.user.nserver,
    );

    useEffect(() => {
        didMountRef.current = true;

        if (server.chat_server?.websocket_url !== prevConnectionLink) {
            closeWebSocket();
            connect(
                privateKey,
                publicKey,
                userToken,
                activeServer,
                server.chat_server?.websocket_url,
            );
        } else if (server.chat_server?.websocket_url === prevConnectionLink) {
            sendInitialsAgain(userToken, activeServer);
        }
        setPrevConnectionLink(server.chat_server?.websocket_url);

    }, [activeServer]);

    useEffect(() => {
        if (!notificationLink || notificationLink === '') {
            logoutHandler();
            return;
        }
        connectNotificationServer(userToken, notificationLink);
        connectWSSearch(privateKey, publicKey);
    }, []);

    useEffect(() => {
        if (activeChannelId && channelDetails) {
            setChatAreaSectionConfig({
                type: 'ACTIVE_CHANNEL',
                channel: channelDetails,
                privacy: channelDetails.privacy,
                roomTypeIcon: channelDetails.type
                    ? channelDetails.type
                    : 'text',
                roomName: channelDetails?.name,
                id: channelDetails?._id,
                subserver_id: activeServer,
                roomImg: roomImg,
            });

            if (channelDetails.privacy === 'direct') {
                const newName = channelDetails.name.split('_');
                const receiverId = newName.filter(id => id !== userId)[0];
                setChatAreaSectionConfig(prev => {
                    return {
                        ...prev,
                        roomName:
                            memberList[receiverId]?.name ?? '- NEW USER -',
                    };
                });
            }
        }
    }, [activeChannelId, channelDetails, activeServer]);

    useEffect(() => {
        if (channel?.length > 0 && userArray?.length > 0 && memberList) {
            const directChannels = channel?.filter(
                (ch: any) => ch.privacy === 'direct',
            );

            const [firstUser] = userArray;
            let selectedDM;

            if (directChannels?.length > 0) {
                selectedDM = directChannels.find((channel: any) => {
                    const channelParts = channel?.name?.split('_');

                    return (
                        (firstUser === channelParts[0] &&
                            userId === channelParts[1]) ||
                        (firstUser === channelParts[1] &&
                            userId === channelParts[0]) ||
                        (firstUser === userId &&
                            firstUser === channelParts[0] &&
                            channelParts.length === 1)
                    );
                });
            }
            if (selectedDM) {
                if (selectedDM.is_hidden) {
                    hideDirectChannel(selectedDM._id, false);
                }
                onJoinChannel(selectedDM._id);
                setUserArray([]);
            } else {
                let chatTemplateName = '';
                if (userArray.length === 1) {
                    if (memberList && memberList?.[userArray[0]]?.name) {
                        chatTemplateName = memberList[userArray[0]].name;
                    }
                } else chatTemplateName = 'Start a new Chat';
                let chnConfig: any = {
                    receivers: userArray,
                    type: 'text',
                };
                if (userArray.length === 1) {
                    chnConfig = { ...chnConfig, privacy: 'direct' };
                }
                createDMChannel(chnConfig);
                setChatAreaSectionConfig({
                    users: userArray,
                    type: 'NEW',
                    roomTypeIcon: 'text',
                    roomName: chatTemplateName,
                    roomImg: roomImg,
                });

                setUserArray([]);
            }
        }
    }, [userArray, channel, memberList]);

    return {
        createNewChatTemplate,
        chatAreaSectionConfig,
    };
};

export default useHomeContent;
