import { i18n } from '@lingui/core';

export const locales = {
    en: 'English',
    tr: 'Turkish',
    ru: 'Russian',
    pt: 'Portuguese',
    es: 'Espanyol',
};
const selectedLanguage = localStorage.getItem('selectedLanguage');
// @ts-ignore
let browserLanguage = navigator.language || navigator.userLanguage;
browserLanguage = browserLanguage.split('-')[0];

export const defaultLocale = selectedLanguage ?? browserLanguage ?? 'en';

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
    const { messages } = await import(`./locales/${locale}`);
    i18n.load(locale, messages);
    i18n.activate(locale);
}
