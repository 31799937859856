import React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

import { useAppSelector } from '@/hooks/useRedux';

import ChannelItem from './ChannelItem';

interface ChannelDragProps {
    channel: {
        _id: string;
        name: string;
        type: string;
        is_public: boolean;
        privacy: string;
    };
    index: number;
}
const ChannelDrag: React.FC<ChannelDragProps> = props => {
    const { channel, index } = props;
    const channelListEditModeValue = useAppSelector(
        state => state.utilReducer.channelListEditMode,
    );

    return (
        <Draggable
            key={channel._id}
            draggableId={channel._id}
            index={index}
            isDragDisabled={!channelListEditModeValue}>
            {(provided: DraggableProvided) => (
                <ChannelItem
                    id={channel._id}
                    channel={channel}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                />
            )}
        </Draggable>
    );
};

export default React.memo(ChannelDrag);
