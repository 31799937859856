import { useAppDispatch } from '@/hooks/useRedux';
import { triggerSettingsModal } from '@/store/util-reducer';

import classes from './MessageItem.module.css';

const MessageHashWarning: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    return (
        <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
                dispatch(
                    triggerSettingsModal({
                        visible: true,
                        component: 'keysManagement',
                    }),
                );
            }}
            className={classes['encryption-key-container']}>
            Encrypted Key not found! Click here to upload Encryption Key
        </div>
    );
};
export default MessageHashWarning;
