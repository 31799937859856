import { t } from '@lingui/macro';
import { Dispatch, SetStateAction, memo } from 'react';

import { ExternalBadge } from '@/UI/externalbadge/ExternalBadge';
import { DEFAULT_IMAGES } from '@/constants';
import { useAppSelector } from '@/hooks/useRedux';
import { sectionDataSelector } from '@/store/server-data-reducer/selectors-server-data';
import { IChannelData } from '@/types';

import AppButton from '../AppButton/AppButton';
import Icon from '../main-icon/Icon';

import classes from './InviteFriends.module.css';

type Props = {
    index: number;
    el: any;
    sendInvitationHandler: any;
    selectedId: any;
    setSelectedId: any;
    selectedTab: any;
    channel: IChannelData;
    section_id: string;
    handleInviteSave: any;
    setAddedItem?: Dispatch<SetStateAction<any>>;
};

function ListItem(props: Props) {
    const {
        el,
        sendInvitationHandler,
        setSelectedId,
        selectedTab,
        section_id,
        channel,
        handleInviteSave,
        setAddedItem,
    } = props;

    const sections = useAppSelector(sectionDataSelector);
    const sectionChannels = sections?.filter(
        section => section?._id === section_id,
    );
    const sectionChannelsIds = sectionChannels[0]?.channelList.map(channel =>
        String(channel._id),
    );

    const btnCase = {
        style: [
            {
                backgroundColor: 'var(--blue-primary)',
            },
            {
                backgroundColor: 'var(--green-2)',
            },
        ],
        content: [t`Add`, t`Added`],
    };

    const handleButtonClick = (item: any) => {
        setAddedItem(item);
        sendInvitationHandler(item?.id);
        setSelectedId(item?.id);

        let savePayload = {
            channelId: channel?._id,
            sectionChannels: sectionChannelsIds,
        };

        switch (selectedTab) {
            case 'users':
                handleInviteSave({
                    inviteMembers: [item?.id],
                    inviteRoles: [],
                    ...savePayload,
                });
                break;
            case 'roles':
                handleInviteSave({
                    inviteMembers: [],
                    inviteRoles: [item?.id],
                    ...savePayload,
                });
                break;
            default:
                break;
        }
    };
    return (
        <div id={`invite-list-item-${el?.id}`} key={el?.id}>
            {selectedTab === 'users' &&
                // el.id !== selectedId &&
                !el?.banned && (
                    <li
                        className={classes['list-item']}
                        style={{
                            height: 34,
                            margin: '4px 0px',
                        }}>
                        <Icon
                            className={classes['profile']}
                            imageURL={
                                el.image
                                    ? el.image
                                    : DEFAULT_IMAGES.PROFILE_IMAGE
                            }
                            name={el.id ? el.id : 'User'}
                        />
                        {el.name ? el.name : 'User'}
                        {el?.external > 0 && <ExternalBadge />}
                        <AppButton
                            onClick={() => handleButtonClick(el)}
                            classStyles={classes['btn']}
                            btnCase={btnCase}
                        />
                    </li>
                )}

            {selectedTab === 'roles' && (
                // el.id !== selectedId &&
                <li
                    className={classes['list-item']}
                    style={{
                        height: 34,
                        margin: '4px 0px',
                    }}>
                    <div
                        style={{
                            width: 16,
                            height: 16,
                            borderRadius: '50%',
                            background: el.color,
                        }}></div>
                    {el.name ? el.name : 'Role'}
                    <AppButton
                        onClick={() => handleButtonClick(el)}
                        classStyles={classes['btn']}
                        btnCase={btnCase}
                    />
                </li>
            )}
        </div>
    );
}

export default memo(ListItem);
