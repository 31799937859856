import { FC } from 'react';

import DropDown from '@/UI/drop-down/DropDown';
import { StatusType, getUserStatus } from '@/constants/status';

import classes from './UserStatus.module.css';

interface propsTypes {
    onChangeStatus: (status: StatusType) => void;
    onCLose: () => void;
}

const UserStatus: FC<propsTypes> = props => {
    const { onChangeStatus, onCLose } = props;
    let statusArray = [];
    const status = getUserStatus();
    Object.keys(status).forEach(el => {
        statusArray.push(status[el]);
    });

    const changeStatusHandler = (status: StatusType) => {
        onChangeStatus(status);
    };

    return (
        <DropDown
            header={'Status'}
            className={classes['container']}
            selectOptions={statusArray}
            onClick={changeStatusHandler}
            onClose={onCLose}
        />
    );
};
export default UserStatus;
