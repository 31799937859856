import classes from './Statistics.module.css';
import { StatisticsProvider } from './StatisticsContext';
import StatisticsHeader from './StatisticsHeader';
import StatisticsPagination from './StatisticsPagination';
import TableStatistics from './table/TableStatistics';

const Statistics = () => {
    return (
        <StatisticsProvider>
            <div className={classes['container']}>
                <StatisticsHeader />
                <TableStatistics />
                <StatisticsPagination />
            </div>
        </StatisticsProvider>
    );
};

export default Statistics;
