import { onJoinChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import { triggerScroll } from '@/Utils/targetMessageService';
import { useAppSelector } from '@/hooks/useRedux';
import forwardIcon from '@/assets/icons/redo.svg';
import {
    activeChannelIdSelector,
    channelDataSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import classes from './ForwardPart.module.css';

const ForwardPart = ({ forwardContent }) => {
    const channels = useAppSelector(state => channelDataSelector(state));
    const sender: any = channels.find(
        (ch: any) => ch._id === forwardContent?.channel_id,
    );

    const senderName =
        sender?.privacy === 'channel' ? sender?.name : 'Direct message';
    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const forwardMessageHandler = () => {
        activeChannelId !== forwardContent?.channel_id &&
            onJoinChannel(forwardContent?.channel_id);
        setTimeout(() => {
            triggerScroll(forwardContent);
        }, 500);
    };
    return (
        <div onClick={forwardMessageHandler} className={classes['forward']}>
            <img alt="" className={classes['img']} src={forwardIcon} />
            <p>Forward from "{senderName}"</p>
        </div>
    );
};
export default ForwardPart;
