import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { getPrevMessages } from '@/Utils/socket-actions/getPrevMessages';
import { sendWsMessageRange } from '@/Utils/socket-actions/MessageActions/messageActions';
import { setScrollCallback } from '@/Utils/targetMessageService';
import { fetchStrangeUserData } from '@/api/member';
import { useAppSelector } from '@/hooks/useRedux';
import { messageDataSelector } from '@/store/messages-reducer/selectors-messages';
import { activeChannelIdSelector } from '@/store/server-data-reducer/selectors-server-data';
import { IMessageData } from '@/types';

const useMessageList = (props?: any) => {
    const listRef = useRef<HTMLDivElement>(null);
    const viewRef = useRef(null);
    const [isMessageListBottom, setIsMessageListBottom] = useState(true);

    const WSReducerState = useAppSelector(state => state.WSReducer);

    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );
    const strangerMemberList = useAppSelector(
        state =>
            state.serverDataReducer.strangerMemberList[
                state.serverDataReducer.activeServer
            ],
    );

    const messageReplyHandler = useCallback((event: Object) => {
        props.onReplyMessage(event);
    }, []);

    const messages: IMessageData[] | any = useAppSelector(state =>
        messageDataSelector(state),
    );

    const memoizedMessages = useMemo(() => messages, [messages]);

    const [sortedDisplayBuffer, setSortedDisplayBuffer] = useState<
        IMessageData[]
    >([]);

    const [displayBuffer, setDisplayBuffer] = useState([]);
    const [loadMore, setLoadMore] = useState(false);
    const [targetScrollMessage, setTargetScrollMessage] = useState(null);

    const [messageRangeReady, setMessageRangeReady] = useState(false);
    const [isItemsMounted, setIsItemsMounted] = useState(false);

    const userId = useAppSelector(state => state.userReducer.user.id);
    const channelId = useAppSelector(activeChannelIdSelector);

    const scrollToEnd = (lastIndex?: any) => {
        if (viewRef.current && listRef.current) {
            viewRef?.current?.scrollToIndex({
                index: lastIndex ?? displayBuffer.length - 1,
            });
            // listRef.current.style.scrollBehavior = 'smooth';
            setTimeout(() => {
                listRef.current.scrollTop = listRef.current?.scrollHeight;
            }, 100);
        }
    };

    const handleNewMessagesRender = () => {
        const lastIndex = messages.length - 1;
        const lastMessage = messages[lastIndex];

        if (
            displayBuffer.length + 1 === messages.length &&
            lastMessage.user_id === userId
        ) {
            scrollToEnd(lastIndex);
        } else if (messages.length > displayBuffer.length) {
            setLoadMore(false);
        }
        setDisplayBuffer(messages);
        setTimeout(() => {
            setIsItemsMounted(true);
        }, 100);
    };

    const handleGetPrevMessages = (dateRange?: string) => {
        let dateStamp: any = messages[0]?.created_at;
        if (dateRange) {
            dateStamp = dateRange;
        }
        if (dateStamp && dateStamp !== '') {
            getPrevMessages(dateStamp, true);
        }
        setTimeout(() => {
            setLoadMore(false);
        }, 900);
    };

    const getNextMessages = (dateRange?: Date) => {
        let dateStamp: any =
            displayBuffer[displayBuffer.length - 1]?.created_at;
        if (dateRange) {
            dateStamp = dateRange;
        }
        getPrevMessages(dateStamp, false);
    };

    const scrollListToBottom = () => {
        setTimeout(() => {
            if (
                listRef.current &&
                listRef.current.scrollTop !== listRef.current?.scrollHeight
            ) {
                listRef.current.scrollTop = listRef.current?.scrollHeight;
            }
        }, 300);
    };
    let secondCheckTriggered = false;

    const shouldRemoveScrollListener = useRef(false);

    const scrollToMessageHandler = target => {
        setTargetScrollMessage(target);

        const indexOfTarget = displayBuffer.findIndex(
            el =>
                el?._id === target?._id ||
                el?._id === target?.original_message_id,
        );

        if (indexOfTarget !== -1) {
            viewRef?.current?.scrollToIndex({
                index: indexOfTarget,
            });
        } else if (indexOfTarget === -1 && !messageRangeReady) {
            setMessageRangeReady(false);
            sendWsMessageRange(target?._id);
        }
    };
    useEffect(() => {
        if (listRef.current) {
            const container = listRef.current;
            const isAtBottom =
                container.scrollHeight - container.scrollTop ===
                container.clientHeight;

            if (
                isAtBottom &&
                messages[messages.length - 1]?.thread_last_message
            ) {
                scrollToEnd(messages.length - 1);
            }
        }
    }, [messages, listRef.current]);

    useEffect(() => {
        listRef.current && (listRef.current.style.scrollBehavior = 'auto');
        if (
            listRef.current &&
            listRef.current.scrollTop !== listRef.current?.scrollHeight
        ) {
            listRef.current.scrollTop = listRef.current?.scrollHeight;

            if (!secondCheckTriggered) {
                secondCheckTriggered = true;
                scrollListToBottom();
            }
        }
    }, [channelId, listRef.current]);

    useEffect(() => {
        setIsItemsMounted(false);
    }, [channelId]);

    useEffect(() => {
        const ulElement = listRef.current;
        if (ulElement === undefined) return;

        const handleScrolling = () => {
            if (shouldRemoveScrollListener.current) {
                return;
            }

            if (Math.abs(ulElement.scrollTop) <= 5) {
                displayBuffer.length > 49 && setLoadMore(true);
                if (listRef.current) {
                    listRef.current.style.scrollBehavior = 'auto';
                }
                ulElement?.removeEventListener('scroll', handleScrolling);
                shouldRemoveScrollListener.current = true;
                handleGetPrevMessages();
            }

            if (messageRangeReady) {
                const isAtBottom =
                    Math.abs(Math.ceil(ulElement.scrollTop)) ===
                    Math.ceil(
                        ulElement.scrollHeight -
                            ulElement.getBoundingClientRect().height,
                    );
                if (isAtBottom) {
                    // fetch next messages
                    if (displayBuffer[displayBuffer.length - 1]?.created_at) {
                        getNextMessages(
                            displayBuffer[displayBuffer.length - 1]?.created_at,
                        );
                    }
                }
            }
        };

        shouldRemoveScrollListener.current = false;
        handleNewMessagesRender();
        ulElement?.addEventListener('scroll', handleScrolling);

        return () => {
            ulElement?.removeEventListener('scroll', handleScrolling);
        };
    }, [memoizedMessages, listRef.current]);

    useEffect(() => {
        if (
            WSReducerState.state &&
            WSReducerState.data.length > 0 &&
            !messageRangeReady
        ) {
            const isInData = WSReducerState.data.find(
                msg => msg?._id === targetScrollMessage?._id,
            );
            if (isInData) {
                setMessageRangeReady(true);
            }
        }
    }, [WSReducerState]);

    useEffect(() => {
        setScrollCallback(scrollToMessageHandler);
        const sortedData = [...displayBuffer]?.sort(
            (a, b) =>
                new Date(a?.created_at)?.getTime() -
                new Date(b?.created_at)?.getTime(),
        );
        setSortedDisplayBuffer(sortedData);
    }, [displayBuffer]);

    useLayoutEffect(() => {
        if (messageRangeReady) {
            scrollToMessageHandler(targetScrollMessage);
        }
    }, [messageRangeReady]);

    // FOR STRANGER USERS
    const userIdSet = new Set();

    sortedDisplayBuffer.forEach(message => {
        userIdSet.add(message.user_id);
    });
    const uniqueUserIds: any[] = Array.from(userIdSet);

    uniqueUserIds?.map(item => {
        if (
            (memberList && memberList?.[item]) ||
            (strangerMemberList && strangerMemberList?.[item])
        ) {
            return;
        } else {
            fetchStrangeUserData({ user_id: item });
        }
    });
    // FOR STRANGER USERS

    return {
        displayBuffer,
        messageReplyHandler,
        listRef,
        loadMore,
        handleGetPrevMessages,
        viewRef,
        targetScrollMessage,
        scrollToEnd,
        isItemsMounted,
        isMessageListBottom,
        setIsMessageListBottom,
        sortedDisplayBuffer,
    };
};

export default useMessageList;
