import { Trans, t } from '@lingui/macro';
import { FC } from 'react';

import { Modal, SearchInput } from '@/UI';
import CustomErrorBoundary from '@/components/CustomErrorBoundary/CustomErrorBoundary';
import { channelType } from '@/components/server-details-section/server-channels/channelDataTypes';

import classes from './InviteFriends.module.css';
import ModalList from './ModalList';
import { useInviteFriends } from './useInviteFriends';

export type InviteFriendsTypes = {
    onClose?: () => void;
    channelName?: string;
    channelId?: string;
    channel?: channelType;
    section_id?: string;
    serverName?: string;
    sectionChannels?: any;
};

const InviteFriends: FC<InviteFriendsTypes> = props => {
    const { onClose, channelName, section_id } = props;
    const {
        handleInviteSave,
        selectedTab,
        handleTabClick,
        sectionChannels,
        membersInCh,
        selectedId,
        rolesInCh,
        searchMembers,
        channel,
        setSelectedId,
        searchRoles,
        sendInvitationHandler,
        searchInputValue,
        onChangeSearchInput,
    } = useInviteFriends(props);

    const onCloseHandler = () => {
        onClose();
    };

    return (
        <Modal
            onClose={onCloseHandler}
            className={classes['modal']}
            removeBodyPadding
            header={`${t`Add Users to`} ${channelName}`}>
            <div style={{ margin: '8px 16px' }}>
                <SearchInput
                    style={{ width: 'calc(100% - 43px)' }}
                    onChange={onChangeSearchInput}
                    value={searchInputValue}
                    placeholder={
                        selectedTab === 'users'
                            ? t`Search Users`
                            : t`Search Roles`
                    }
                />
                <div className={classes['tab-container']}>
                    <div
                        className={`${classes.tab} ${
                            selectedTab === 'users' ? classes.selected : ''
                        }`}
                        onClick={() => handleTabClick('users')}>
                        {sectionChannels?.length > 0 ? t`All Users` : t`Users`}{' '}
                        <span className={classes.count}>
                            {membersInCh?.length}
                        </span>
                    </div>
                    <div
                        className={`${classes.tab} ${
                            selectedTab === 'roles' ? classes.selected : ''
                        }`}
                        onClick={() => handleTabClick('roles')}>
                        {sectionChannels?.length > 0 ? (
                            <Trans>All Roles</Trans>
                        ) : (
                            <Trans>Roles</Trans>
                        )}
                        &nbsp;
                        <span className={classes.count}>
                            {rolesInCh?.length}
                        </span>
                    </div>
                </div>

                {selectedTab === 'users' && (
                    <CustomErrorBoundary showErrorDetail>
                        <ModalList
                            sendInvitationHandler={sendInvitationHandler}
                            data={membersInCh}
                            searchData={searchMembers}
                            setSelectedId={setSelectedId}
                            selectedId={selectedId}
                            selectedTab="users"
                            channel={channel}
                            section_id={section_id}
                            handleInviteSave={handleInviteSave}
                        />
                    </CustomErrorBoundary>
                )}

                {selectedTab === 'roles' && (
                    <CustomErrorBoundary showErrorDetail>
                        <ModalList
                            sendInvitationHandler={sendInvitationHandler}
                            data={rolesInCh}
                            searchData={searchRoles}
                            setSelectedId={setSelectedId}
                            selectedId={selectedId}
                            selectedTab="roles"
                            channel={channel}
                            section_id={section_id}
                            handleInviteSave={handleInviteSave}
                        />
                    </CustomErrorBoundary>
                )}
            </div>
        </Modal>
    );
};
export default InviteFriends;
