import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';
import { FC, RefObject, useEffect, useRef, useState } from 'react';

import { AppButton, Icon } from '@/UI';
import SearchInput from '@/UI/search-input/SearchInput';
import { DEFAULT_IMAGES } from '@/constants/index';
import useClickOutside from '@/hooks/useClickOutside';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './SearchUser.module.css';

interface IProps {
    onSubmitMembers: (selected) => void;
    onClose: () => void;
    parentRef?: RefObject<HTMLElement>;
}

const SearchUser: FC<IProps> = props => {
    const dropRef = useRef();
    const { onSubmitMembers, onClose, parentRef } = props;

    const [keyValue, setKeyValue] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const members = useAppSelector(state => memberDataSelector(state));

    const ChangeSearchValueHandler = search => {
        const trimed = search.trim();
        setKeyValue(trimed);
    };

    const selectItemHandler = event => {
        if (event.target.checked) {
            setSelectedItems(prev => [...prev, event.target.value]);
        } else {
            setSelectedItems(prev =>
                prev.filter(el => el !== event.target.value),
            );
        }
    };

    const submitSelectedUsers = () => {
        onSubmitMembers(selectedItems);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (keyValue === '') {
                setSearchResult(members);
            } else {
                const filterUsers = members.filter(user =>
                    user?.name
                        ?.toLowerCase()
                        ?.includes(keyValue?.toLowerCase()),
                );
                setSearchResult(filterUsers ? filterUsers : []);
            }
        }, 100);

        return () => {
            clearTimeout(timer);
        };
    }, [keyValue, members]);

    const handleClickOutside = () => {
        onClose();
    };

    useClickOutside([dropRef, parentRef], handleClickOutside);

    return (
        <div className={classes.modal} ref={dropRef}>
            <SearchInput
                className={classes['search-input']}
                onChange={ChangeSearchValueHandler}
            />
            <ul>
                {searchResult.length > 0 ? (
                    searchResult.map((res, key) => {
                        return (
                            <div key={key}>
                                <li id={res.user_id}>
                                    <div className={classes['d-flex']}>
                                        <Icon
                                            className={classes['icon']}
                                            imageURL={
                                                res.profile_picture
                                                    ? res.profile_picture
                                                    : DEFAULT_IMAGES.PROFILE_IMAGE
                                            }
                                            name={res.name ? res.name : 'icon'}
                                        />
                                        <p>{res.name}</p>
                                        <div className={classes['select-btn']}>
                                            <input
                                                type="checkbox"
                                                name={'select-user-item'}
                                                id={`user-${key}`}
                                                className={classes['select']}
                                                value={res.id}
                                                onChange={selectItemHandler}
                                            />
                                            <div
                                                className={
                                                    classes['plus-btn']
                                                }></div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        );
                    })
                ) : (
                    <p>
                        <Trans>No Result Found</Trans>
                    </p>
                )}
            </ul>
            <AppButton
                type="button"
                onClick={submitSelectedUsers}
                classStyles={classes['appButton']}
                title={t`Add`}
            />
        </div>
    );
};
export default SearchUser;
