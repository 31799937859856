
import { FC, useState } from 'react';
import { Spinner } from 'react-activity';

import fetchData from '@/Utils/fetchData';
import { getServer, uploadFiles } from '@/Utils/serverMediaHandler.js';
import { useAppSelector } from '@/hooks/useRedux';

import classes from './CreateServer.module.css';
import CustomizationStep from './CustomizationStep';

const EditSubServerBody: FC<{ onClose: () => void }> = ({ onClose }) => {
    const serverLink = process.env.REACT_APP_GET_SERVER_LINK_URL;
    const [loading, setLoading] = useState(false);

    const user = useAppSelector(state => state.userReducer.user);
    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const submitImage = async file => {
        const { responseLink } = await getServer(file, serverLink);

        const { uploadedFileLink } = await uploadFiles(
            file,
            activeServer,
            responseLink,
            user.token,
            '',
            false,
        );
        return { uploadedFileLink: uploadedFileLink.data };
    };

    const submitAllData = async formData => {
        setLoading(true);
        const uri = `${process.env.REACT_APP_BASE_API_URL}/api/v4/organisation/e53eca77-2b46-44b7-8961-02249fe40af6/server/${activeServer}`;
        let logo = formData.serverLogo[0];
        let banner = formData.serverBanner[0];
        let serverBanner = formData.serverBanner[0];
        let serverLogo = formData.serverLogo[0];
        let chatBg = formData.serverChatBg[0];
        let serverChatBg = formData.serverChatBg[0];

        if (typeof serverBanner === 'object') {
            serverBanner = await submitImage(serverBanner.file);
            banner = `https://${serverBanner.uploadedFileLink.server}/api/download/${serverBanner.uploadedFileLink.fileKey}`;
        }

        if (typeof serverLogo === 'object') {
            serverLogo = await submitImage(formData.serverLogo[0].file);
            logo = `https://${serverLogo.uploadedFileLink.server}/api/download/${serverLogo.uploadedFileLink.fileKey}`;
        }

        if (typeof serverChatBg === 'object') {
            serverChatBg = await submitImage(serverChatBg.file);
            chatBg = `https://${serverChatBg.uploadedFileLink.server}/api/download/${serverChatBg.uploadedFileLink.fileKey}`;
        }

        const params = {
            logo: logo,
            banner: banner,
            name: formData.serverName,
            description: formData.serverDescription,
            chat_bg: chatBg ?? 'null',
        };

        await fetchData('PATCH', uri, params);
        setLoading(false);
        onClose();
    };

    const submitStepHandler = data => {
        submitAllData(data);
    };

    return (
        <div className={classes['wrap']}>
            <CustomizationStep
                className={loading ? classes['fade'] : classes['clear']}
                onSubmit={submitStepHandler}
            />
            {loading && (
                <Spinner
                    className={classes['spinner']}
                    style={{ fontSize: '35px', margin: 'auto' }}
                />
            )}
        </div>
    );
};
export default EditSubServerBody;
