import { t } from '@lingui/macro';
import { useContext } from 'react';
import { Dots } from 'react-activity';

import EmojiKeyBoard from '@/UI/emoji-keyboard/EmojiKeyBoard';
import Modal from '@/UI/modal/Modal';

import AttachementMenuForm from './AttachementMenuForm';
import classes from './AttachmentMenu.module.css';
import { MediaMenuContext } from './AttachmentMenuContext';
import ReplyContent from './AttachmentReplyContent';
import { AttachMenuProps } from './AttachmentTypes';
import UploadProgress from './UploadProgress';
import useAttachmentMenu from './useAttachementMenu';

const AttachementMenuStore: React.FC<AttachMenuProps> = props => {
    const {
        mediaUploaded,
        onToggleVisibility,
        passDefaultFiles,
        reply,
        onSendMessage,
        uploadProgress,
    } = props;
    const menuContext = useContext(MediaMenuContext);
    const { emojiIsShown, isLoading, selectEmojiHandler } = menuContext;
    const { isReplySection, pastedFiles } = useAttachmentMenu({
        reply,
        passDefaultFiles,
        mediaUploaded,
        onToggleVisibility,
    });

    return (
        <Modal
            className={classes.modal}
            header={t`Send an Attachment`}
            onClose={onToggleVisibility}>
            {uploadProgress > 0 && (
                <UploadProgress uploadProgress={uploadProgress} />
            )}

            {isLoading && (
                <div className={classes['spinner']}>
                    <Dots />
                </div>
            )}

            {emojiIsShown && <EmojiKeyBoard onSelect={selectEmojiHandler} />}

            {isReplySection && (
                <ReplyContent
                    context={{
                        messageText: reply.messageText,
                        replyfor: reply.replyfor,
                        type: reply.type,
                    }}
                />
            )}
            <AttachementMenuForm
                reply={reply}
                onSendMessage={onSendMessage}
                pastedFiles={pastedFiles}
            />
        </Modal>
    );
};
export default AttachementMenuStore;
