import { ErrorMessage, useField, useFormikContext } from 'formik';
import Select from 'react-select';

import { addMemberSelectStyles } from '@/components/ManagementModal/organization_member/organization-create-member/selectStyles';

import classes from './FormikSelect.module.css';

const FormikSelect = ({ name, options, placeholder, ...props }) => {
    const { setFieldValue, setFieldTouched } =
        useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = selectedOption => {
        setFieldValue(name, selectedOption ? selectedOption.value : '');
        setFieldTouched(name, true);
        if (props.onBlur) {
            props.onBlur();
        } else {
            setTimeout(() => setFieldTouched(name, true), 0);
        }
    };

    const handleBlur = () => {
        setFieldTouched(name, true);
    };

    return (
        <div>
            <Select
                {...props}
                name={name}
                value={
                    options
                        ? options.find(option => option.value === field.value)
                        : null
                }
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={() => console.log('focus')}
                options={options}
                styles={addMemberSelectStyles}
                placeholder={placeholder}
            />
            {meta.touched && meta.error ? (
                <ErrorMessage
                    name={name}
                    component="div"
                    className={classes['error-message']}
                />
            ) : null}
        </div>
    );
};

export default FormikSelect;
