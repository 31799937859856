import { Trans } from '@lingui/macro';
import { FC, useRef, useState } from 'react';

import { AppButton, Line, Modal } from '@/UI';
import CustomInput from '@/UI/custom-input/CustomInput';
import CustomTextArea from '@/UI/custom-text-area/CustomTextArea';
import { createChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import headerIcon from '@/assets/icons/add-square.svg';
import peopleIcon from '@/assets/icons/add-user.svg';
import lockIcon from '@/assets/icons/lock.svg';
import plusIcon from '@/assets/icons/plus.svg';
import SearchUser from '@/components/search/SearchUser';
import { BUTTON_TYPE } from '@/constants/enum';
import { t } from '@lingui/macro';
import classes from './CreateChannel.module.css';
import ChannelTypeOptions from './channel-type-options/ChannelTypeOptions';

type Props = {
    sectionId?: string | undefined;
    onClose: () => void;
};

const CreateChannel: FC<Props> = ({ sectionId, onClose }) => {
    const clickRef = useRef<HTMLImageElement>(null);

    const textRef = useRef<HTMLInputElement>(null);
    const descripRef = useRef<HTMLTextAreaElement>(null);
    const privateRef = useRef<HTMLInputElement>(null);

    const [channelType, setChannelType] = useState<string>('text');
    const [addMembers, setAddMembers] = useState<boolean>(false);
    const [searchMembersModal, setSearchMembersModal] =
        useState<boolean>(false);
    const [channelMembers, setChannelMembers] = useState<string[]>([]);

    const createChannelHelper = async (formData: any) => {
        createChannel(formData);
        onClose();
    };

    const submitNewServerHandler = (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();

        let formData: any = {
            type: channelType,
            name: textRef.current?.value ? textRef.current.value.trim() : '',
            privacy: 'channel',
            description: descripRef.current?.value
                ? descripRef.current.value.trim()
                : '',
            channel_section: sectionId ? sectionId : '',
            is_public: !privateRef.current?.checked,
        };

        if (privateRef.current?.checked) {
            formData = {
                ...formData,
                receivers: channelMembers,
            };
        }

        try {
            createChannelHelper(formData);
        } catch (err) {
            console.log(err);
        }
    };

    const submitMembersHandler = (usersArray: string[]) => {
        setChannelMembers(usersArray);
        setSearchMembersModal(false);
    };

    const changePrivacyHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setAddMembers(event.target.checked);
    };

    return (
        <Modal
            className={classes.modal}
            header={t`Create a Channel`}
            headerIcon={headerIcon}
            bodyStyles={{ maxHeight: 'max(75vh, 400px)', overflowY: 'auto' }}
            onClose={onClose}>
            <p className={classes['mt-0']}>
                <Trans>
                    You are creating in the category of audio channels
                </Trans>
            </p>
            <form autoComplete="new-password" onSubmit={submitNewServerHandler}>
                <h2 className={classes['line-after']}>
                    <Trans>Channel Type</Trans>
                </h2>
                <ChannelTypeOptions
                    onChangeType={(value: string) => {
                        setChannelType(value);
                    }}
                />
                <Line />
                <h2>
                    <Trans>Channel Name</Trans>
                </h2>
                <div className={classes['input-area']}>
                    <CustomInput
                        ref={textRef}
                        placeholder={t`Channel Name`}
                        className={classes['custom-input']}
                        required
                    />
                </div>
                <Line />
                <h2>
                    <Trans>Channel Description</Trans>
                </h2>
                <div className={classes['input-area']}>
                    <CustomTextArea
                        name="description"
                        ref={descripRef}
                        placeholder={t`Channel Description`}
                        className={classes['custom-input']}
                    />
                </div>
                <div className={classes['private-choise']}>
                    <input
                        ref={privateRef}
                        type="checkbox"
                        value="isPrivate"
                        onChange={changePrivacyHandler}
                    />
                    <h2 className={classes['privacy-title']}>
                        <img src={lockIcon} alt="Private" />
                        <Trans>Private Channel</Trans>
                    </h2>
                    <p>
                        <Trans>
                            Only selected members and roles can view this
                            channel
                        </Trans>
                        .
                    </p>

                    <div className={classes['checkbox-indicator']}>
                        <div className={classes['check-dot']}></div>
                    </div>
                </div>
                {addMembers && (
                    <>
                        <Line />
                        <div className={classes['add-members-section']}>
                            <h2 className={classes['privacy-title']}>
                                <img src={peopleIcon} alt="Private" />
                                <Trans>Add Members</Trans>
                            </h2>
                            <p>
                                <Trans>
                                    Add users to this channel in order to view
                                    this it
                                </Trans>
                                .
                            </p>
                            <img
                                src={plusIcon}
                                ref={clickRef}
                                className={classes['add-members']}
                                alt="add members"
                                onClick={() => setSearchMembersModal(true)}
                            />
                        </div>

                        {channelMembers.length > 0 && (
                            <p>
                                {t`you have added ${channelMembers.length} to this
                                channel`}
                            </p>
                        )}
                    </>
                )}

                {searchMembersModal && (
                    <div className={classes['search-relative']}>
                        <SearchUser
                            onClose={() => setSearchMembersModal(false)}
                            onSubmitMembers={submitMembersHandler}
                            parentRef={clickRef}
                        />
                    </div>
                )}
                <div className={classes['d-flex']}>
                    <AppButton
                        type="button"
                        onClick={onClose}
                        buttonType={BUTTON_TYPE.whiteWithBorder}
                        classStyles={classes['appButton']}
                        title={t`Cancel`}
                    />
                    <AppButton
                        classStyles={classes['appButton']}
                        type="submit"
                        title={t`Create Channel`}
                    />
                </div>
            </form>
        </Modal>
    );
};

export default CreateChannel;
