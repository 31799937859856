import { Trans, t } from '@lingui/macro';
import { QRCodeSVG } from 'qrcode.react';

import { AppButton, TextInput, Version } from '@/UI';
import download from '@/assets/icons/document-download.svg';
import logo from '@/assets/images/login_logo.png';

import classes from './DownloadServerKeys.module.css';
import useDownloadServerKeys from './useDownloadServerKeys';

type Props = {};

const DownloadServerKeys = (props: Props) => {
    const { handleContinue, keys, handleDownload } = useDownloadServerKeys();
    return (
        <div className="justifyCenter d-flex">
            <div className="centerContainer">
                <div className={classes['contentWrapper']}>
                    <img alt="" src={logo} style={{ maxHeight: '25vh' }} />

                    <div className={classes['headingWrapper']}>
                        <span className="secondaryHeading marginVertical-small">
                            <Trans>Download Encrypted Keys</Trans>
                        </span>
                        <div className={classes['qr']}>
                            <QRCodeSVG value={JSON.stringify(keys)} />
                        </div>
                    </div>
                    <div className={classes['inputWrapper']}>
                        <TextInput
                            name="publicKey"
                            label={t`Public Key`}
                            icon={download}
                            isDisabled
                            defaultValue={keys?.publicKey && 'Public Key'}
                            onClick={handleDownload}
                        />
                        <TextInput
                            label={t`Private Key`}
                            name="privateKey"
                            isDisabled
                            icon={download}
                            defaultValue={keys?.privateKey && 'Private Key'}
                            onClick={handleDownload}
                        />
                    </div>
                    <AppButton
                        title={t`Continue`}
                        onClick={handleContinue}
                        classStyles={classes['appButton']}
                    />
                    <Version />
                </div>
            </div>
        </div>
    );
};

export default DownloadServerKeys;
