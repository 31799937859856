import { SESSION_STORAGE } from '@/constants/enum';

import { decryptPassword } from './cryptoUtils';

const devicePassword = sessionStorage.getItem(SESSION_STORAGE.devicePassword);

export const exportKeys = (name, privateKey, publicKey) => {

    const privateKeyJWK = decryptPassword(privateKey, devicePassword);
    const publicKeyJWK = decryptPassword(publicKey, devicePassword);

    const fileType = 'application/json';

    const JSON_USER = JSON.stringify({
        publicKey: publicKeyJWK,
        privateKey: privateKeyJWK,
    });
    const blob = new Blob([JSON_USER], { type: fileType });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${name.replace(/\s/g, '_')}.json`;
    link.click();
    URL.revokeObjectURL(url);
};
