import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IMessageData } from '@/types';

interface IThreadMessageList {
    [channelId: number]: IMessageData;
}
interface ThreadsStateTypes {
    lastActiveThread: {
        channel_id: string;
        id: string;
        message_id: string;
        name: string;
        subserver_id: string;
    };
    isOpenThread: boolean;
    activeThreadmessagesList: IThreadMessageList;
    ownerId: string;
    activeThreadMembers: string[];
    isLockThread: boolean;
    threadList: any;
    metaData: any;
}

const initialState: ThreadsStateTypes = {
    lastActiveThread: {
        channel_id: '',
        id: '',
        message_id: '',
        name: '',
        subserver_id: '',
    },
    isOpenThread: false,
    activeThreadmessagesList: {},
    ownerId: '',
    activeThreadMembers: [],
    isLockThread: false,
    threadList: {},
    metaData: {},
};

const threadsSlice = createSlice({
    name: 'messages-threads',
    initialState,
    reducers: {
        updatethreadMessageList: (
            state: ThreadsStateTypes,
            {
                payload,
            }: PayloadAction<{ messages: IMessageData[]; thread_id: string }>,
        ) => {
            const threadId = payload.thread_id;
            const updatedMessagesList = {
                ...(state.activeThreadmessagesList?.[threadId] || {}),
                ...payload.messages?.reduce((acc, item) => {
                    return {
                        ...acc,
                        [item._id]: { ...acc[item._id], ...item },
                    };
                }, {}),
            };

            const sortedMessages = Object.values(updatedMessagesList).sort(
                (a: any, b: any) => b.created_at - a.created_at,
            );

            const slicedMessages = sortedMessages.slice(0, 30);
            return {
                ...state,
                activeThreadmessagesList: {
                    ...state.activeThreadmessagesList,
                    [threadId]: updatedMessagesList,
                },
            };
        },
        updatethreadNextMessageList: (
            state: ThreadsStateTypes,
            {
                payload,
            }: PayloadAction<{ messages: IMessageData[]; thread_id: string }>,
        ) => {
            const threadId = payload.thread_id;
            const updatedMessagesList = {
                ...payload.messages?.reduce((acc, item) => {
                    return {
                        ...acc,
                        [item._id]: { ...acc[item._id], ...item },
                    };
                }, {}),
                ...(state.activeThreadmessagesList?.[threadId] || {}),
            };

            return {
                ...state,
                activeThreadmessagesList: {
                    ...state.activeThreadmessagesList,
                    [threadId]: updatedMessagesList,
                },
            };
        },
        addThreadMessage: (
            { activeThreadmessagesList }: ThreadsStateTypes,
            { payload }: PayloadAction<IMessageData>,
        ) => {
            const messageList =
                activeThreadmessagesList[payload?.thread_id] ?? {};
            const messageId = payload?._id;

            if (messageList) {
                if (
                    !activeThreadmessagesList[payload?.thread_id]?.[messageId]
                ) {
                    activeThreadmessagesList[payload?.thread_id] = {
                        [payload._id]: payload,
                        ...activeThreadmessagesList[payload.thread_id],
                    };
                }
            } else {
                activeThreadmessagesList[payload?.thread_id] = {
                    ...activeThreadmessagesList,
                    [payload?.thread_id]: { [payload._id]: payload },
                };
            }
        },

        openThreadAction(
            state,
            action: PayloadAction<{
                channel_id?: string;
                message_id?: string;
                name?: string;
                _id?: string;
                subserver_id?: string;
            }>,
        ) {
            state.isOpenThread = true;
            state.lastActiveThread = {
                ...state.lastActiveThread,
                id: action.payload?._id,
                channel_id: action.payload?.channel_id,
                message_id: action.payload.message_id,
                name: action.payload.name,
                subserver_id: action.payload?.subserver_id,
            };
        },
        deleteThreadMessage(
            state,
            action: PayloadAction<{
                thread_id: string;
                message_id: string;
            }>,
        ) {
            delete state.activeThreadmessagesList[action.payload?.thread_id]?.[
                action.payload.message_id
            ];
        },
        updateOwnerId(
            state,
            action: PayloadAction<{
                ownerId: string;
            }>,
        ) {
            state.ownerId = action.payload.ownerId;
        },
        updateThreadMembers(
            state,
            action: PayloadAction<{
                activeThreadMembers: string[];
            }>,
        ) {
            state.activeThreadMembers = action.payload.activeThreadMembers;
        },
        updateLockThread(
            state,
            action: PayloadAction<{
                isLockThread: boolean;
            }>,
        ) {
            state.isLockThread = action.payload.isLockThread;
        },

        closeThreadAction(state) {
            state.isOpenThread = false;
            state.lastActiveThread = {
                channel_id: null,
                id: null,
                message_id: null,
                name: null,
            };
            state.ownerId = null;
            state.activeThreadMembers = null;
            state.activeThreadmessagesList = {};
        },
        updateThreadMessage(
            { activeThreadmessagesList }: ThreadsStateTypes,
            { payload },
        ) {
            activeThreadmessagesList[payload._id][payload.message_id] = payload;
        },
        editThreadMessage(
            { activeThreadmessagesList }: ThreadsStateTypes,
            { payload },
        ) {
            activeThreadmessagesList[payload.thread_id][payload.message_id] = {
                ...activeThreadmessagesList[payload.thread_id][
                    payload.message_id
                ],
                message: payload.msg,
            };
        },
        getThreadList(
            { threadList, metaData }: ThreadsStateTypes,
            { payload },
        ) {
            if (payload && payload.thread_list && payload.metaData) {
                metaData[payload.subserver_id] = payload.metaData;

                threadList[payload.subserver_id] =
                    threadList[payload.subserver_id] || {};

                payload.thread_list.forEach(item => {
                    threadList[payload.subserver_id][item._id] = item;
                });
            }
        },

        updateThreadList({ threadList }: ThreadsStateTypes, { payload }) {
            const thread = threadList[payload?.subserver_id][payload?._id];
            if (payload?.isDeletedThread) {
                delete threadList[payload?.subserver_id][payload?._id];
            }
            if (thread) {
                threadList[payload?.subserver_id][payload?._id] = {
                    ...threadList[payload.subserver_id][payload._id],
                    lastMessage: payload?.lastMessage,
                };
            } else {
                threadList[payload?.subserver_id] = {
                    ...threadList[payload?.subserver_id],
                    [payload?._id]: {
                        _id: payload?._id,
                        lastMessage: {
                            created_at: new Date().toISOString(),
                            user_id: payload?.user_id,
                        },
                        messageCount: 0,
                        thread_name: payload?.name,
                    },
                };
            }
        },

        editThreadMsgReaction(
            { activeThreadmessagesList, lastActiveThread }: ThreadsStateTypes,
            { payload },
        ) {
            const reactionData = payload.reactionData;
            const messageList = activeThreadmessagesList[lastActiveThread.id];
            const messagesArray = Object.values(messageList).reverse();

            // filter
            const filterSenderMessage = messagesArray?.find(
                item =>
                    item?.sender_thread_message_id === reactionData?.message_id,
            );
            const filterSenderMessageId =
                filterSenderMessage?._id || reactionData?.message_id;
            const filterSenderChannelId =
                filterSenderMessage?.channel_id || reactionData?.channel_id;
            //filter

            if (
                messageList &&
                messageList[filterSenderMessageId] !== undefined
            ) {
                const messageReactions =
                    messageList[filterSenderMessageId]?.reactions || [];

                const targetReactionIndex = messageReactions.findIndex(
                    (el: { emoji: string }) => el.emoji === reactionData.emoji,
                );
                // Check if the user has already added the same emoji
                const userAddedEmoji = messageReactions?.some(reaction =>
                    reaction.list?.some(
                        userReaction =>
                            userReaction.user_id === reactionData.user_id &&
                            reaction.emoji === reactionData.emoji,
                    ),
                );

                if (payload.type === 'DELETE' && targetReactionIndex !== -1) {
                    const reactionList =
                        messageReactions[targetReactionIndex].list;

                    if (reactionList.length === 1) {
                        messageReactions.splice(targetReactionIndex, 1);
                    } else {
                        const updatedReactionList = reactionList.filter(
                            (reaction: { _id: string }) =>
                                reaction._id !== reactionData._id,
                        );
                        messageReactions[targetReactionIndex].list =
                            updatedReactionList;
                    }
                } else if (payload.type === 'ADD' && filterSenderChannelId) {
                    if (!userAddedEmoji) {
                        if (messageReactions.length > 0) {
                            if (targetReactionIndex === -1) {
                                const newReaction = {
                                    emoji: reactionData.emoji,
                                    list: [
                                        {
                                            user_id: reactionData.user_id,
                                            _id: reactionData._id,
                                        },
                                    ],
                                };
                                messageReactions.unshift(newReaction);
                            } else {
                                const reactionList =
                                    messageReactions[targetReactionIndex].list;
                                const updatedReactionList = [
                                    {
                                        user_id: reactionData.user_id,
                                        _id: reactionData._id,
                                    },
                                    ...reactionList,
                                ];
                                messageReactions[targetReactionIndex].list =
                                    updatedReactionList;
                            }
                        } else {
                            const newMessageReactions = [
                                {
                                    emoji: reactionData.emoji,
                                    list: [
                                        {
                                            user_id: reactionData.user_id,
                                            _id: reactionData._id,
                                        },
                                    ],
                                },
                            ];

                            messageList[filterSenderMessageId].reactions =
                                newMessageReactions;
                        }
                    }
                }
            }
        },
    },
});

export const {
    addThreadMessage,
    openThreadAction,
    closeThreadAction,
    updatethreadMessageList,
    updatethreadNextMessageList,
    updateOwnerId,
    updateThreadMembers,
    updateLockThread,
    deleteThreadMessage,
    editThreadMsgReaction,
    updateThreadMessage,
    editThreadMessage,
    getThreadList,
    updateThreadList,
} = threadsSlice.actions;
export default threadsSlice.reducer;
