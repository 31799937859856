import { FC, useEffect } from 'react';

import {
    getPermissionList,
    updateRolewithPermission,
} from '@/Utils/socket-actions/getChannelPermission';
import { useAppSelector } from '@/hooks/useRedux';
import { permissionListSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './RolesManagement.module.css';
import EditRoles from './edit-roles/EditRoles';

type Props = {};

const RolesManagement: FC = () => {
    const permissionList = useAppSelector(permissionListSelector);
    useEffect(() => {
        getPermissionList();
        updateRolewithPermission();
    }, []);

    return (
        <div className={classes['container']}>
            <EditRoles defaultRole={{}} permissionList={permissionList} />
        </div>
    );
};

export default RolesManagement;
