import CryptoJS from 'crypto-js';

import { SESSION_STORAGE } from '@/constants/enum';

import { getCookie } from './utils';

interface KeyPair {
    publicKey: CryptoKey;
    privateKey: CryptoKey;
}

// This function generates an RSA key pair using the Web Crypto API
// and returns it as an object with publicKey and privateKey properties.

// Other common modulus lengths include:
// - 2048 bits: Offers a good balance between security and performance and
//   is widely used in practice.
// - 3072 bits: Provides higher security than 2048 bits but requires more
//   computational resources for key generation and encryption.
export async function generateRSAKeyPair(): Promise<KeyPair> {
    try {
        // Generate an RSA key pair with the following parameters:
        const keyPair = await window.crypto.subtle.generateKey(
            {
                name: 'RSA-OAEP', // Use RSA with OAEP padding
                modulusLength: 2048, // Set the modulus length to 4096 bits for strong security
                publicExponent: new Uint8Array([0x01, 0x00, 0x01]), // Specify the public exponent (usually 65537)
                hash: { name: 'SHA-256' }, // Use the SHA-256 hash function
            },
            true, // Allow both encryption and decryption operations with the generated keys
            ['encrypt', 'decrypt'], // Specify that we need the keys for encryption and decryption
        );

        // Return the generated publicKey and privateKey as an object
        return { publicKey: keyPair.publicKey, privateKey: keyPair.privateKey };
    } catch (err) {
        // Handle any errors that may occur during key generation
        console.error(err);
    }
}

// Helper function to generate a random AES key.
export async function generateAESKey() {
    // Generate a random AES-GCM key with the following parameters:
    const key = await window.crypto.subtle.generateKey(
        {
            name: 'AES-GCM', // Use AES-GCM encryption algorithm
            length: 256, // Set the key size (can be 128, 192, or 256 bits) the higher the lenght the more secure, but requires more computational resource + time
        },
        true, // Make the key extractable so it can be used for encryption and decryption
        ['encrypt', 'decrypt'], // Specify that we need the key for encryption and decryption operations
    );
    return key; // Return the generated AES key
}

export const RSA_CONSTANTS = (key_ops: 'decrypt' | 'encrypt') => {
    return {
        alg: 'RSA-OAEP-256',
        e: 'AQAB',
        ext: true,
        kty: 'RSA',
        key_ops: [key_ops],
    };
};

export async function encryptDataWithRSA(
    token: object | string,
    publicKey: CryptoKey | string,
): Promise<string> {
    let cryptoKey: CryptoKey;

    if (typeof publicKey === 'string') {
        let decrypt_jwk = decryptPassword(
            publicKey,
            sessionStorage.getItem(SESSION_STORAGE.devicePassword),
        );

        cryptoKey = await window.crypto.subtle.importKey(
            'jwk',
            decrypt_jwk,
            { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
            false,
            ['encrypt'],
        );
    } else {
        cryptoKey = publicKey;
    }

    const encoder = new TextEncoder();

    const val = encoder.encode(JSON.stringify(token));

    const encryptedData = await window.crypto.subtle.encrypt(
        {
            name: 'RSA-OAEP',
            // hash: { name: 'SHA-256' },
        },
        cryptoKey,
        val,
    );

    const encryptedArray = Array.from(new Uint8Array(encryptedData));
    const encryptedText = encryptedArray
        .map(byte => String.fromCharCode(byte))
        .join('');

    return btoa(encryptedText);
}

// Function to encrypt data with AES-GCM using a random AES key
export async function encryptDataWithAES(data, aesKey) {
    try {
        const iv = window.crypto.getRandomValues(new Uint8Array(12)); // 32-bit IV
        const encryptedData = await window.crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: iv,
            },
            aesKey,
            new TextEncoder().encode(data),
        );
        const cipherText = concatArrayBuffers(iv.buffer, encryptedData);
        const cipeherData = arrayBufferToBase64(cipherText);

        return { encryptedData: cipeherData };
    } catch (error) {
        console.error('Error encrypting with AES:', error);
    }
}

// concatenate two array buffers
const concatArrayBuffers = (buffer1, buffer2) => {
    let tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp.buffer;
};

export async function decryptDataWithRSA(
    ciphertext: string,
    privateKey: CryptoKey | string,
): Promise<object | any> {
    let cryptoKey: CryptoKey;
    if (typeof privateKey === 'string') {
        let decrypt_jwk = decryptPassword(
            privateKey,
            sessionStorage.getItem(SESSION_STORAGE.devicePassword),
        );

        cryptoKey = await window.crypto.subtle.importKey(
            'jwk',
            decrypt_jwk,
            { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
            false,
            ['decrypt'],
        );
    } else {
        cryptoKey = privateKey;
    }

    try {
        const encryptedText = atob(ciphertext);
        const encryptedArray = new Uint8Array(encryptedText.length);

        for (let i = 0; i < encryptedText.length; ++i) {
            encryptedArray[i] = encryptedText.charCodeAt(i);
        }

        const decryptedData = await window.crypto.subtle.decrypt(
            {
                name: 'RSA-OAEP',
            },
            cryptoKey,
            encryptedArray,
        );

        const decoder = new TextDecoder();
        const decryptedText = decoder.decode(decryptedData);
        return JSON.parse(decryptedText);
    } catch (error) {
        // If there is an error with atob() or JSON.parse(), return the original ciphertext
        // console.error(ciphertext, 'Error decrypting with RSA:', error);

        return undefined;
    }
}

// Function to decrypt data with AES-GCM using an AES key
export async function decryptWithAES(encryptedData, aesKey, ivToken) {
    try {
        // Extract the IV (first 12 bytes) from the concatenated data
        const iv = ivToken ?? encryptedData.slice(0, 12);
        const cipherText = ivToken ? encryptedData : encryptedData.slice(12);
        // Decrypt the ciphertext using AES-GCM
        const decryptedData = await window.crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: iv,
            },
            aesKey,
            cipherText,
        );

        // Convert the decrypted data (ArrayBuffer) to a string
        const decryptedText = new TextDecoder().decode(decryptedData);

        return decryptedText;
    } catch (error) {
        console.error('Error decrypting with AES:', error);
    }
}

export async function importKey(key: string | object, type?: any) {
    const parsedKey = typeof key === 'string' ? JSON.parse(key) : key;

    if (!window.crypto || !window.crypto.subtle) {
        console.error('Web Crypto API is not supported in this browser.');
    }
    const cryptoKey = await window.crypto.subtle.importKey(
        'jwk',
        parsedKey,
        type ?? { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
        false,
        ['decrypt'],
    );
    return cryptoKey;
}

export async function exportKeyToJWK(cryptoKey) {
    const jwk = await window.crypto.subtle.exportKey('jwk', cryptoKey);
    return jwk;
}

export async function encryptPassword(
    privateKey: CryptoKey | JsonWebKey,
    password: string,
): Promise<string> {
    const dataString = JSON.stringify(privateKey);
    const encryptedData = CryptoJS.AES.encrypt(dataString, password).toString();
    return encryptedData;
}

export function decryptPassword(
    encryptedData: string,
    password?: string,
): object {
    // if(password === null)
    const CONST_PASSWORD = getCookie(SESSION_STORAGE.devicePassword);
    // const decryptKey = importKey(encryptedData);
    try {
        const decryptedBytes = CryptoJS.AES.decrypt(
            encryptedData,
            password ?? CONST_PASSWORD,
        );
        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
    } catch (error) {
        console.error('Error decrypting data:', error);
    }
}

// Helper function to convert base64 string to ArrayBuffer
export function base64ToArrayBuffer(base64String) {
    const binaryString = window.atob(base64String);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

// Helper function to convert ArrayBuffer to base64 string
export function arrayBufferToBase64(arrayBuffer) {
    const binaryString = String.fromCharCode.apply(
        null,
        new Uint8Array(arrayBuffer),
    );
    return window.btoa(binaryString);
}

export function convertJwkToBase64(jwk) {
    function base64UrlToBase64(base64Url) {
        var padding = '=='.slice(0, (4 - (base64Url.length % 4)) % 4);
        return base64Url.replace(/-/g, '+').replace(/_/g, '/') + padding;
    }

    if (jwk.privateKey) {
        jwk.privateKey.n = base64UrlToBase64(jwk.privateKey.n);
        jwk.privateKey.d = base64UrlToBase64(jwk.privateKey.d);
        jwk.privateKey.p = base64UrlToBase64(jwk.privateKey.p);
        jwk.privateKey.q = base64UrlToBase64(jwk.privateKey.q);
        jwk.privateKey.dp = base64UrlToBase64(jwk.privateKey.dp);
        jwk.privateKey.dq = base64UrlToBase64(jwk.privateKey.dq);
        jwk.privateKey.qi = base64UrlToBase64(jwk.privateKey.qi);
    }

    if (jwk.publicKey) {
        jwk.publicKey.n = base64UrlToBase64(jwk.publicKey.n);
    }

    return jwk;
}
