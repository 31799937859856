import React from 'react';

import { Badge } from '@/UI';
import { DEFAULT_IMAGES } from '@/constants';

import classes from '../MessageItem.module.css';

type Props = {
    reactions: any;
    user: any;
    members: any;
    clickReactionHandler: any;
    strangerMemberList?: {};
};

const RenderReactions = (props: Props) => {
    const {
        reactions,
        user,
        members,
        clickReactionHandler,
        strangerMemberList,
    } = props;
    const foundStyle = {
        border: ' 1px solid #AAAEDE',
        background: '#4D4878',
        padding: '3px 9px',
        transition: 'all 0.3s ease',
    };

    return (
        <div className={classes['d-flex']} style={{ marginBottom: 10 }}>
            {reactions?.length > 0 &&
                reactions?.map(
                    (el: any, key: number) =>
                        Object.keys(el)?.length > 0 && (
                            <div
                                className={classes['reactions-container']}
                                key={key}>
                                <Badge
                                    style={
                                        el?.list?.find(
                                            list => list.user_id === user.id,
                                        )
                                            ? foundStyle
                                            : {}
                                    }
                                    className={classes['reactions-badge']}
                                    onClick={() =>
                                        clickReactionHandler(el?.emoji)
                                    }>
                                    {el?.emoji}
                                    &nbsp;{el?.list?.length}
                                </Badge>
                                <div
                                    className={
                                        classes['reaction-users-container']
                                    }>
                                    <div className={classes['reaction-users']}>
                                        <ul>
                                            {el?.list?.map((e, key) => {
                                                const mem: any =
                                                    members?.find(
                                                        (member: any) =>
                                                            member?.id ===
                                                            e?.user_id,
                                                    ) ||
                                                    strangerMemberList?.[
                                                        e?.user_id
                                                    ];
                                                return (
                                                    <li
                                                        key={key}
                                                        className={
                                                            classes[
                                                                'react-user-item'
                                                            ]
                                                        }>
                                                        <img
                                                            className={
                                                                classes[
                                                                    'reaction-user-img'
                                                                ]
                                                            }
                                                            src={
                                                                mem?.profile_picture
                                                                    ? mem.profile_picture
                                                                    : DEFAULT_IMAGES.PROFILE_IMAGE
                                                            }
                                                            alt="#"
                                                        />
                                                        {mem?.name}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ),
                )}
        </div>
    );
};

export default RenderReactions;
