import { sendWebSocketData } from '@/Utils/webSocketUtils';
import { fetchBannedToServer } from '@/api/member';
import { FETCHUSER_TYPES } from '@/constants/enum';
import store from '@/store';
import { addLastSeenMessage } from '@/store/messages-reducer/messages-reducer';
import {
    setActiveChannel,
    updateMemberListUpdate,
    updatePermissions,
    updateProfileMember,
} from '@/store/server-data-reducer/server-data-reducer';
import { updateUserData } from '@/store/user-reducer';

export const handleUserBanUpdate = ({
    data,
    activeServer,
    userSelector,
    logoutHandler,
}: {
    data: any;
    activeServer: string;
    userSelector: any;
    logoutHandler: () => void;
}) => {
    store.dispatch(fetchBannedToServer(activeServer));
    if (data.user_id === userSelector.id) {
        logoutHandler();
    }
};

export const handlePermissionMe = data => {
    store.dispatch(updatePermissions(data.permissions));
};

export const getLastSeenUsers = data => {
    store.dispatch(addLastSeenMessage(data));
};

export const handleFetchData = (activeServer: string) => {
    sendWebSocketData(
        JSON.stringify({
            action: 'me',
            data: { subserver_id: activeServer },
        }),
    );
    sendWebSocketData(JSON.stringify({ action: 'channel' }));
    sendWebSocketData(JSON.stringify({ action: 'role_list' }));
    sendWebSocketData(JSON.stringify({ action: 'user_role' }));
};

export const handleProfileStatusUpdate = (data: any, userSelector: any) => {
    store.dispatch(updateProfileMember(data));
    const findMe = data.find(item => item?.id === userSelector.id);
    if (findMe?.id) {
        store.dispatch(updateUserData(findMe));
    }
};

export const handleFetchUserData = data => {
    if (data?.type === FETCHUSER_TYPES.REMOVEUSER_INCHANNEL) {
        store.dispatch(setActiveChannel(''));
    }
    sendWebSocketData(JSON.stringify({ action: 'user_data', data: {} }));
};

export const handleMemberListUpdate = data => {
    store.dispatch(updateMemberListUpdate(data));
};
