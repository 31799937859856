import { editNoteApi } from '@/api/notes/noteApis';
import store from '@/store';
import { INote } from '@/types/notesTypes';

export const editNoteFav = async (
    ev: React.MouseEvent<SVGElement>,
    note: INote,
    successFunc: (newfavValue: number) => void,
) => {
    ev.stopPropagation();
    const subserverId = store.getState().serverDataReducer.activeServer;

    const newfavValue = note.fav ? 0 : 1;

    const params = {
        title: note.title,
        body: note.body,
        fav: newfavValue,
    };

    const { data } = await editNoteApi({
        subserverId,
        noteId: note.id,
        params,
    });

    if (data.status) {
        successFunc(newfavValue);
    }
};
