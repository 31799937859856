import { ExternalBadge } from '@/UI/externalbadge/ExternalBadge';
import {
    getChannelMemberList,
    getHighestMemberRole,
} from '@/Utils/memberUtils';
import { truncateText } from '@/Utils/textUtils';

import classes from './MessageItem.module.css';

type Props = {
    owner;
    userId: string;
    time;
    onClick;
};
const MessageItemHeader: React.FC<Props> = props => {
    const { owner, userId, time, onClick } = props;

    const ownerHighestRole = getHighestMemberRole(owner);
    const channelMembersData = getChannelMemberList();

    const isUserAvaible = channelMembersData.find(item => item?.id === userId);
    return (
        <p
            className={classes['owner-title']}
            style={{
                color: ownerHighestRole?.color,
            }}>
            <span
                style={{
                    textDecoration:
                        isUserAvaible || !owner?.banned
                            ? 'none'
                            : 'line-through',
                    color: isUserAvaible || !owner?.banned ? 'none' : 'red',
                    cursor: 'pointer',
                }}
                onClick={onClick}>
                {truncateText(owner?.name, 30) ?? '--USER--'}
            </span>
            {owner?.banned && (
                <span className={classes['user-banned-badge']}>
                    Banned User
                </span>
            )}
            {!isUserAvaible && channelMembersData === undefined && (
                <span className={classes['user-banned-badge']}>
                    Deleted User
                </span>
            )}
            {owner?.external > 0 && <ExternalBadge />}
            <span className={classes.dot}>
                {time}
            </span>
        </p>
    );
};
export default MessageItemHeader;
