import { htmlParser } from '@/Utils/htmlParser';
import { triggerScroll } from '@/Utils/targetMessageService';
import { truncateText } from '@/Utils/textUtils';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './MessageItem.module.css';

const ReplyPart = ({ replyProps }) => {
    const members = useAppSelector(state => memberDataSelector(state));

    const originalUserName = members.find(
        (e: { id: string }) => e.id === replyProps?.original_user_id,
    );

    let replyMsgContent = '';

    if (
        replyProps?.original_message?.content &&
        typeof replyProps?.original_message === 'object'
    ) {
        replyMsgContent = replyProps?.original_message?.content;
    } else if (
        replyProps?.original_message &&
        typeof replyProps?.original_message === 'string'
    ) {
        replyMsgContent = replyProps?.original_message;
    }

    if (replyMsgContent === '') {
        replyMsgContent = '-> Attachment File';
    }
    const scrollToOriginalHandler = () => {
        triggerScroll(replyProps);
    };
    return (
        <div
            className={classes['reply-chat']}
            onClick={scrollToOriginalHandler}>
            <p>Replied To: {truncateText(originalUserName?.name, 25)}</p>
            {htmlParser(replyMsgContent)}
        </div>
    );
};

export default ReplyPart;
