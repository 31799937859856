import { Trans } from '@lingui/macro';
import { FC } from 'react';

import classes from '../TableMemberManagement.module.css';

type IProps = {
    item: boolean | undefined;
};

const StateColumn: FC<IProps> = ({ item }) => {
    return (
        <div className={classes['status-wrapper']}>
            <div
                className={classes['status']}
                style={{
                    backgroundColor: item ? 'var(--green-2)' : 'var(--gray)',
                }}
            />
            <span>{item ? <Trans>Online</Trans> : <Trans>Offline</Trans>}</span>
        </div>
    );
};
export default StateColumn;
