import { FC } from 'react';

import { ExternalBadge } from '@/UI/externalbadge/ExternalBadge';
import { truncateText } from '@/Utils/textUtils';
import { ReactComponent as LockIcon } from '@/assets/icons/lock.svg';
import { ReactComponent as DoorOpenIcon } from '@/assets/icons/open-door.svg';
import { ReactComponent as VolumeIcon } from '@/assets/icons/volume-audio.svg';
import { IChannelData } from '@/types';

import classes from './NewChat.module.css';

interface IProps {
    onChoose: () => void;
    item: IChannelData;
}

const ChannelListItem: FC<IProps> = props => {
    const { onChoose, item } = props;
    return (
        <li onClick={onChoose} className={classes['body-item']}>
            {!item.is_public ? (
                <LockIcon
                    fill={'var(--text-2)'}
                    className={classes['room-type-icon']}
                />
            ) : item.type === 'text' ? (
                <DoorOpenIcon fill={'var(--green-4)'} />
            ) : (
                <VolumeIcon fill={'var(--text-2)'} />
            )}
            {item.name ? truncateText(item.name, 25) : '---'}
        </li>
    );
};
export default ChannelListItem;
