import fetchData from '@/Utils/fetchData';
import { REQUEST_TYPE } from '@/constants/requstTypes';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const postLoginApi = async data => {
    const url = BASE_URL + '/api/v1/login';
    const res = await fetchData(REQUEST_TYPE.POST, url, data);
    return res;
};
