import { AnimatePresence } from 'framer-motion';
import { useContext } from 'react';

import ForwardNote from '@/components/forward-note/ForwardNote';
import { NotesContext } from '@/context/notes/NotesContext';

import ColorPickerPopup from '../color-picker-popup/ColorPickerPopup';
import DeleteCategoryModal from '../delete-category/DeleteCategoryModal';
import DeleteNoteModal from '../delete-note/DeletePopoverContent';
import NewNoteModal from '../new-note-section/NewNoteModal';
import NewNoteSection from '../new-note-section/NewNoteSection';

const NotesPopups: React.FC<{}> = () => {
    const notesContext = useContext(NotesContext);
    const {
        isEditNoteModal,
        isEditCategory,
        setIsEditCategory,
        isAddNewNote,
        isAddNewGroup,
        setIsAddNewGroup,
        isColorPickModal,
        isDeleteModal,
        onForwardHandler,
        isForwardModal,
        isDeleteCategory,
        setIsForwardModal,
    } = notesContext;

    return (
        <>
            <AnimatePresence>
                {isForwardModal && (
                    <ForwardNote
                        onForward={onForwardHandler}
                        onClose={() => setIsForwardModal(false)}
                    />
                )}
                {isDeleteCategory.status && <DeleteCategoryModal />}

                {isEditNoteModal && (
                    <NewNoteModal
                        isEdit={{ state: true, item: isEditNoteModal }}
                    />
                )}
                {isAddNewNote && <NewNoteModal />}
                {isDeleteModal && <DeleteNoteModal />}
                {isColorPickModal && <ColorPickerPopup />}
                {isAddNewGroup && (
                    <NewNoteSection
                        key={'add-key'}
                        onClose={() => setIsAddNewGroup(false)}
                    />
                )}
                {isEditCategory && (
                    <NewNoteSection
                        key={'edit-key'}
                        onClose={() => setIsEditCategory(null)}
                        isEdit
                    />
                )}
            </AnimatePresence>
        </>
    );
};
export default NotesPopups;
