import React, { createContext, useState } from 'react';

import { sortByKey } from '@/Utils/sortByKey';
import useMemberTable from '@/hooks/useMemberTable';
import { useAppSelector } from '@/hooks/useRedux';
import {
    memberDataSelector,
    rolesListSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import { initialValues } from './initialValues';
import { ContextProps, IProps } from './types';
import { t } from '@lingui/macro';

export const MemberManagementContext =
    createContext<ContextProps>(initialValues);

export const MemberManagementProvider: React.FC<IProps> = ({ children }) => {
    const memberData = useAppSelector(state => memberDataSelector(state));
    const rolesList = useAppSelector(rolesListSelector);
    const sortByPriority = sortByKey(rolesList, 'priority');

    const tabs = [
        {
            id: 0,
            value: 'all-member',
            label: t`All`,
        },
        {
            id: 2,
            value: 'online-member',
            label: t`Online`,
        },
        {
            id: 3,
            value: 'offline-member',
            label: t`Offline`,
        },
        {
            id: 4,
            value: 'banned-member',
            label: t`Banned`,
        },
    ];

    const {
        handleSearchInput,
        searchText,
        onChangeCheckbox,
        isOpencheckboxModal,
        setIsOpenCheckboxModal,
        allClearCheckbox,
        allSelectCheckbox,
        handleMemberServerBlockUpdate,
        handleMemberServerUnblockUpdate,
        handleSortData,
        isRoleSelected,
        filteredMemberData,
        onChangeTab,
        checkedRolesFilterCount,
    } = useMemberTable({
        tabData: tabs,
        checkboxData: sortByPriority,
        mainMemberData: memberData,
    });

    const [isEditModal, setIsEditModal] = useState(false);
    const [isRolesModal, setIsRolesModal] = useState(false);
    const [isAddUserModal, setIsAddUserModal] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState(false);

    const tabsManagementProps = {
        handleSearchInput,
        tabs,
        searchText,
        onChangeTab,
        isOpencheckboxModal,
        setIsOpenCheckboxModal,
        checkboxFilterOptionsData: sortByPriority,
        allSelectCheckbox,
        allClearCheckbox,
        isRoleSelected,
        onChangeCheckbox,
    };

    const modalProps = {
        isEditModal,
        setIsEditModal,
        isRolesModal,
        setIsRolesModal,
        isAddUserModal,
        setIsAddUserModal,
        selectedUserData,
        setSelectedUserData,
        handleMemberServerBlockUpdate,
        handleMemberServerUnblockUpdate,
    };

    const contextValues: ContextProps = {
        modalProps,
        tabsManagementProps,
        setIsAddUserModal,
        filteredMemberData,
        handleSortData,
        isEditModal,
        isRolesModal,
        isAddUserModal,
        checkedRolesFilterCount,
    };

    return (
        <MemberManagementContext.Provider value={contextValues}>
            {children}
        </MemberManagementContext.Provider>
    );
};
