import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';
import store from '@/store';
import { notesActions } from '@/store/notes-reducer';
import { p2pActions } from '@/store/p2p-slice';
import {
    deleteSectionReducer,
    removeChannel,
    setActiveChannel,
    updateChannelCount,
    updateMemberRoles,
} from '@/store/server-data-reducer/server-data-reducer';

import { sendWebSocketData } from '../../webSocketUtils';

type priorityDataType = {
    channel_id: string;
    priority: number;
};

export const getChannelListAction = () => {
    let payload = { action: 'channel' };
    sendWebSocketData(JSON.stringify(payload));
};

export const editChannelsPriority = (data: priorityDataType[]) => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.channelEditPriority.action,
        data: { channels: data },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const editChannel = data => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.channelEdit.action,
        data: data,
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const hideDirectChannel = (channel_id: string, is_hidden: boolean) => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.channelHide.action,
        data: {
            channel_id: channel_id,
            is_hidden: is_hidden,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const onJoinChannel = (channelId: string) => {
    // if (store.getState().notesReducer.isNotesOpen) {
    // }
    // if (store.getState().p2pReducer.isP2PPanelOpen) {
    // }
    
    store.dispatch(notesActions.toggleNotesWindow(false));
    store.dispatch(p2pActions.toggleP2PPanel({ state: false }));

    const serverDataReducer = store.getState().serverDataReducer;
    const activeChannelId =
        serverDataReducer?.activeChannelId[serverDataReducer.activeServer] ??
        '';
    const channelList = store.getState().serverDataReducer?.channelList ?? [];
    if (!channelList[channelId]?.channelPermissions === undefined) {
        if (channelId === undefined || activeChannelId === channelId) return;
    }
    const dispatch = store.dispatch;
    const cacheList = store.getState().messageReducer.cacheMessages;
    dispatch(setActiveChannel(channelId));
    dispatch(updateChannelCount({ channel_id: channelId, count: 0 }));

    // (cacheList[channelId] === undefined ||
    //     Object.values(cacheList[channelId])?.length === 0) &&
    //     dispatch(setMessageLoader(true));

    let join = {
        action: SOCKET_ACTIONS_WITH_TYPES.channelJoin.action,
        data: {
            channel_id: channelId,
        },
    };
    sendWebSocketData(JSON.stringify(join));
};

export const removeUserFromChannel = (
    userIds: string[],
    channel_id: string,
) => {
    const data = {
        action: SOCKET_ACTIONS_WITH_TYPES.userChannelRemove.action,
        data: { users: userIds, channel_id: channel_id },
    };
    sendWebSocketData(JSON.stringify(data));
};

export const leaveChannel = async (channel_id: string) => {
    let config = {
        action: SOCKET_ACTIONS_WITH_TYPES.channelLeave.action,
        data: { channel_id: channel_id },
    };
    sendWebSocketData(JSON.stringify(config));
};

export const createChannel = (chnConfig: {}) => {
    let config = {
        action: SOCKET_ACTIONS_WITH_TYPES.channelCreate.action,
        data: { ...chnConfig },
    };
    sendWebSocketData(JSON.stringify(config));
};

export const createSection = async (chnConfig: {}) => {
    let config = {
        action: SOCKET_ACTIONS_WITH_TYPES.sectionCreate.action,
        data: { ...chnConfig },
    };
    sendWebSocketData(JSON.stringify(config));
};

export const deleteSection = (section_id: string) => {
    let config = {
        action: SOCKET_ACTIONS_WITH_TYPES.sectionDelete.action,
        data: { section_id: section_id },
    };
    sendWebSocketData(JSON.stringify(config));
};

export const editSection = (data: {}) => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.sectionEdit.action,
        data: data,
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const inviteUserToChannel = (userIds: string[], channel_id: string) => {
    const data = {
        action: SOCKET_ACTIONS_WITH_TYPES.userChannelCreate.action,
        data: { users: userIds, channel_id: channel_id },
    };
    sendWebSocketData(JSON.stringify(data));
};

export const deleteChannel = (channelId: string) => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.channelDelete.action,
        data: {
            channel_id: channelId,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};

export const deleteChannelHandler = data => {
    store.dispatch(removeChannel(data));
};

export const getChannelMember = (channelId: string) => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.channelMember.action,
        data: {
            channel_id: channelId,
        },
    };
    channelId && sendWebSocketData(JSON.stringify(payload));
};

export const handleAddRoleInChannel = (role_id: string, channel_id: string) => {
    const data = {
        action: SOCKET_ACTIONS_WITH_TYPES.addRoleInChannel.action,
        data: { role_id: role_id, channel_id: channel_id },
    };
    sendWebSocketData(JSON.stringify(data));
};

export const handleRemoveRoleInChannel = (
    role_id: string,
    channel_id: string,
) => {
    const data = {
        action: SOCKET_ACTIONS_WITH_TYPES.removeRoleInChannel.action,
        data: { role_id: role_id, channel_id: channel_id },
    };
    sendWebSocketData(JSON.stringify(data));
};

export const handleMarkAllRead = () => {
    const data = {
        action: SOCKET_ACTIONS_WITH_TYPES.ReadAllMessages.action,
        data: {},
    };
    sendWebSocketData(JSON.stringify(data));
};

export const deleteSectionHandler = data => {
    store.dispatch(deleteSectionReducer(data));
};

export const leaveChannelHandler = data => {
    store.dispatch(removeChannel(data));
    store.dispatch(setActiveChannel(''));
};

export const getRolesList = () => {
    let payload = { action: SOCKET_ACTIONS_WITH_TYPES.role.action };
    sendWebSocketData(JSON.stringify(payload));
};

export const handleMemberRoles = data => {
    store.dispatch(updateMemberRoles(data));
};

export const handleMemberRolesUpdate = () => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.userRoles.action,
        data: {},
    };
    sendWebSocketData(JSON.stringify(payload));
};
