import { t, Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';

import { AppButton, SearchInput } from '@/UI';
import arrow from '@/assets/icons/arrow-down.svg';
import checkedSVG from '@/assets/icons/check-blue-circle.svg';
import { DEFAULT_IMAGES } from '@/constants';
import { BUTTON_SIZE, BUTTON_TYPE } from '@/constants/enum';
import { IServerData } from '@/types';

import classes from '../organization-add-members/OrganizationAddMember.module.css';

function OrganizationCreateMemberServerList(props: any) {
    const {
        setPage,
        handleServerClick,
        selectedServer,
        filterServerData,
        searchText,
        serverData,
        onSubmit,
        handleSave,
        formik,
    } = props;

    const [customData, setCustomData] = useState([]);

    useEffect(() => {
        if (serverData?.length > 0) {
            setCustomData(serverData);
        } else {
            setCustomData(props?.server);
        }
    }, [serverData, props?.server]);

    const _renderServerItem = (item: IServerData) => {
        const PROFILE_PICTURE =
            item?.settings.logo ?? DEFAULT_IMAGES.SERVER_IMAGE;

        const IS_ACTIVE = selectedServer?.some(
            rowItem => rowItem?.id === item?.id,
        );

        return (
            <div
                className={`${classes['server-column']} ${
                    IS_ACTIVE ? classes['active'] : ''
                }`}
                onClick={() => {
                    handleServerClick(item);
                }}>
                <img alt="" src={PROFILE_PICTURE} />
                <div className={classes['server-column-text']}>
                    <span className={classes['server-name']}>{item.name}</span>
                    <span className={classes['server-sub']}>
                        {item.organization_name ?? 0}
                    </span>
                </div>
                {IS_ACTIVE && (
                    <img
                        alt=""
                        src={checkedSVG}
                        className={classes['checked-icon']}
                    />
                )}
            </div>
        );
    };

    const _renderButtons = () => (
        <div className={classes['button-wrapper']}>
            <AppButton
                size={BUTTON_SIZE.medium}
                buttonType={BUTTON_TYPE.primary}
                title={t`Save`}
                style={{ width: '100%' }}
                onClick={async () => {
                    await onSubmit(formik.values).then(res => {
                        if (res) {
                            handleSave(res, selectedServer);
                        }
                    });
                }}
                type={'button'}
            />
        </div>
    );

    return (
        <div className={classes['content-container']}>
            <span
                onClick={() => setPage('1')}
                style={{
                    color: '#7C7896',
                    border: '2px solid #7C7896',
                    width: 'fit-content',
                    height: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 8,
                    cursor: 'pointer',
                    padding: '0px 8px 0px 2px',
                    gap: 4,
                }}>
                <img
                    alt=""
                    style={{ height: 12, width: 12, rotate: '90deg' }}
                    src={arrow}
                />
                <span>
                    <Trans>Back</Trans>
                </span>
            </span>
            <div className={classes['search-wrapper']}>
                <SearchInput
                    onChange={filterServerData}
                    autoFocus={false}
                    id="SearchInput"
                    value={searchText}
                    placeholder={t`Search Server`}
                    style={{ width: '332px' }}
                />
            </div>
            <div className={classes['server-list-container']}>
                {customData?.map((item: any) => _renderServerItem(item))}
            </div>
            {_renderButtons()}
        </div>
    );
}

export default OrganizationCreateMemberServerList;
