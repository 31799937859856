import { AppButton, TextInput } from '@/UI';
import upload from '@/assets/icons/document-upload.svg';
import useUploadServerKeys from '@/components/UploadServerKeys/useUploadServerKeys';
import { BUTTON_SIZE } from '@/constants/enum';
import { useAppSelector } from '@/hooks/useRedux';

import classes from './KeysManagement.module.css';
import { t } from '@lingui/macro';

type Props = {};

const RowContainer = props => {
    const { title, desc, children } = props;
    return (
        <div className={classes['row-container']}>
            <h3>{title}</h3>
            <span className={classes['desc']}>{desc}</span>
            <div className={classes['row-wrapper']}>{children}</div>
        </div>
    );
};
const KeysManagement = (props: Props) => {
    const { files, handleChange, handleAddPrivateKeys } = useUploadServerKeys();
    const cryptoKeys = useAppSelector(state => state.cryptoKeyReducer);
    const { _persist, ...filteredObj } = cryptoKeys;

    return (
        <div className={classes['container']}>
            <RowContainer
                title={t`Add Private Key`}
                desc={t`Update private keys encryption to decrypt messages`}>
                <div className={classes['inputWrapper']}>
                    <TextInput
                        isDisabled
                        icon={upload}
                        value={files?.fileName ?? ''}
                        placeHolder={t`Upload private keys`}
                        onClick={handleChange}
                        error={files?.error}
                        type="input"
                        success={files?.success}
                    />
                </div>
                <AppButton
                    title={t`Add Keys`}
                    onClick={() => handleAddPrivateKeys(false)}
                    classStyles={classes['appButton']}
                    size={BUTTON_SIZE.medium}
                />
            </RowContainer>
            <RowContainer title={t`Available Private Key Hashes`}>
                <div
                    style={{
                        flex: 1,
                        backgroundColor: 'var(--chat-background)',
                        padding: 24,
                        gap: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 8,
                        maxHeight: 350,
                        overflowY: 'auto',
                    }}>
                    {Object.keys(filteredObj).map(item => {
                        return <div>{item}</div>;
                    })}
                </div>
            </RowContainer>
        </div>
    );
};

export default KeysManagement;
