import { t } from '@lingui/macro';

import { htmlParser } from '@/Utils/htmlParser';
import { truncateText } from '@/Utils/textUtils';
import { formatCreatedDate } from '@/Utils/utils';
import { DEFAULT_IMAGES } from '@/constants';
import { useAppSelector } from '@/hooks/useRedux';

import classes from './ThreadList.module.css';

type IProps = {
    val;
    index;
    handleClickThread;
};
const ThreadListItem: React.FC<IProps> = props => {
    const { val, index, handleClickThread } = props;

    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );
    const user = memberList[val?.lastMessage?.user_id];
    let headerName = htmlParser(val?.thread_name);
    let lastMessage = htmlParser(val?.lastMessage?.message);

    return (
        val?._id && (
            <div
                onClick={() =>
                    handleClickThread(
                        val?.lastMessage?.thread_id,
                        val?.lastMessage?.channel_id,
                    )
                }
                className={classes['list-item']}
                key={`${index}-${val._id}`}>
                <div className={classes['item-details']}>
                    <p className={classes['item-title']}>
                        {val?.thread_name && headerName?.length > 0
                            ? truncateText(headerName, 25)
                            : '[THREAD]'}
                    </p>
                    <p className={classes['item-date']}>
                        {formatCreatedDate(val?.lastMessage?.created_at)}
                    </p>
                </div>

                <div className={classes['item-content']}>
                    <img
                        className={classes['img']}
                        src={
                            user?.profile_picture
                                ? user?.profile_picture
                                : DEFAULT_IMAGES.PROFILE_IMAGE
                        }
                        alt=""
                    />
                    <p className={classes['item-message']}>
                        {val?.lastMessage?.type &&
                            (val?.lastMessage?.type === 'text' ||
                            val?.lastMessage?.type === 'reply'
                                ? truncateText(lastMessage, 20)
                                : t`Send an attachment`)}
                    </p>
                </div>
            </div>
        )
    );
};
export default ThreadListItem;
