import store from '@/store';
import { addOtherServerChannelList, deleteOtherServerChannelList, updateOtherServerChannelList } from '@/store/server-data-reducer/server-data-reducer';
import { setInterServerError, setInterServerSuccess } from '@/store/util-reducer';

export const handleAddChannelToOtherServer = data => {
    store.dispatch(setInterServerSuccess(true));
    addOtherServerChannelList(store.dispatch(addOtherServerChannelList(data)));
};

export const handleOtherServerChannels = data => {
    store.dispatch(updateOtherServerChannelList(data));
};

export const handleDeleteIneterServer = data => {
    store.dispatch(deleteOtherServerChannelList({ _id: data?.id }));
};

export const handleInterServerError = data => {
    store.dispatch(setInterServerError(data));
};