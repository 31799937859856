import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import StatusIcon from '@/assets/icons/StatusIcon';
import { ReactComponent as LanguageIcon } from '@/assets/icons/language-icon.svg';
import LogoutIcon from '@/assets/icons/logout.svg';

let stroke = 'var(--drop-down-item)';

export const profileOptions = {
    language: {
        id: 9,
        name: i18n._('Language'),
        icon: <LanguageIcon stroke={stroke} />,
    },
    status: {
        id: 0,
        name: t`Status`,
        icon: <StatusIcon stroke={stroke} />,
    },
    logout: {
        id: 8,
        name: t`Logout`,
        icon: LogoutIcon,
    },
};

export const getProfileOptions = () => {
    return {
        language: {
            id: 9,
            name: i18n._('Language'),
            icon: <LanguageIcon stroke={stroke} />,
        },
        status: {
            id: 0,
            name: t`Status`,
            icon: <StatusIcon stroke={stroke} />,
        },
        logout: {
            id: 8,
            name: t`Logout`,
            icon: LogoutIcon,
        },
    };
};
