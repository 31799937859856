import { checkFileExist } from './mediaFilesUtils';

export const checkfileAvailability = async (file): Promise<boolean> => {
    let isChecked = false;
    try {
        isChecked = await checkFileExist(file.fileKey);
    } catch (error) {
        console.error('ERROR');
    }
    return isChecked;
};
