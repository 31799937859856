import { ReactComponent as BackwardIcon } from '@/assets/icons/arrow-left.svg';

import classes from './MobileBackward.module.css';

type IProps = {
    onClick?: () => void;
    className?: string;
};
const MobileBackward: React.FC<IProps> = props => {
    const { onClick, className = '' } = props;
    return (
        <div onClick={onClick} className={`${classes['wrap']} ${className}`}>
            <BackwardIcon />
        </div>
    );
};
export default MobileBackward;
