import { t } from '@lingui/macro';
import React from 'react';

import { useAppSelector } from '@/hooks/useRedux';
import {
    activeChannelIdSelector,
    userPermissionSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import DMSection from '../dm-section/DMSection';

import Sections from './sections/Sections';

const ChannelsGroups = props => {
    const permission = useAppSelector(userPermissionSelector);
    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const channel = useAppSelector(
        state =>
            state.serverDataReducer.channelList[
                state.serverDataReducer.activeServer
            ]?.[activeChannelId],
    );
    return (
        <>
            {permission?.['pvt_message.create'] && (
                <DMSection
                    unreadOnly={props.unreadOnly}
                    activeChannel={
                        channel?.privacy === 'direct' ? channel?._id : ''
                    }
                    title={t`Direct Messages`}
                />
            )}
            <Sections unreadOnly={props.unreadOnly} />
        </>
    );
};

export default React.memo(ChannelsGroups);
