import { Trans, t } from '@lingui/macro';
import { motion } from 'framer-motion';
import { useContext } from 'react';

import { AppButton } from '@/UI';
import { deleteNoteApi } from '@/api/notes/noteApis';
import { ReactComponent as DeleteIcon } from '@/assets/icons/delete-note.svg';
import { NotesContext } from '@/context/notes/NotesContext';

import classes from './DeleteNote.module.css';

interface DeletePopoverContentProps {}

const DeletePopoverContent: React.FC<DeletePopoverContentProps> = ({}) => {
    const notesContext = useContext(NotesContext);
    const {
        setNotes,
        notes,
        selectedNotes,
        setSelectedNotes,
        setActiveNote,
        activeNote,
        setIsDeleteModal,
    } = notesContext;

    const deleteHandler = async () => {
        let notesToRemove: string[] = [];

        if (selectedNotes.length > 0) {
            notesToRemove = selectedNotes;
        } else if (activeNote?.id) {
            notesToRemove.push(activeNote.id);
        }
        const successfullyDeletedNotes: string[] = [];

        const deletePromises = notesToRemove.map(async noteId => {
            try {
                const { data } = await deleteNoteApi({
                    subserverId: notes.find(el => el.id === noteId)
                        .chat_subserver_id,
                    noteId: noteId,
                });

                if (data.status) {
                    successfullyDeletedNotes.push(noteId);
                    if (selectedNotes.includes(noteId)) {
                        // If note was selected, remove it from selectedNotes
                        setSelectedNotes(prevSelectedNotes =>
                            prevSelectedNotes.filter(id => id !== noteId),
                        );
                    }
                    if (activeNote?.id === noteId) setActiveNote(null); // If active note was deleted, set it to null
                }
            } catch (error) {
                console.error(
                    `Failed to delete note with ID ${noteId}:`,
                    error,
                );
            }
        });
        await Promise.all(deletePromises);
        setNotes(prevNotes =>
            prevNotes.filter(
                note => !successfullyDeletedNotes.includes(note.id),
            ),
        );

        // Reset delete modal state
        setIsDeleteModal(false);
    };

    return (
        <motion.div className={classes['modal']}>
            <div className={classes['modal-header']}>
                <DeleteIcon height={20} width={20} />
                <Trans>Delete notes</Trans>
            </div>
            <div className={classes['body']}>
                <p className={classes['p']}>
                    <Trans>
                        Are you sure you want to delete selected notes
                    </Trans>
                    ?
                </p>
                <div className={classes['btn-wrap']}>
                    <AppButton
                        onClick={deleteHandler}
                        title={t`Delete`}
                        buttonType="primary"
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default DeletePopoverContent;
