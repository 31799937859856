const TrashIcon = ({ stroke, className, onClick = () => {} }) => {
    return (
        <svg
            onClick={onClick}
            className={className}
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="trash">
                <g id="vuesax/linear/trash">
                    <g id="trash_2">
                        <path
                            id="Vector"
                            d="M19.25 5.48177C16.1975 5.17927 13.1267 5.02344 10.065 5.02344C8.25 5.02344 6.435 5.1151 4.62 5.29844L2.75 5.48177"
                            stroke={stroke ? stroke : '#fff'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Vector_2"
                            d="M7.79175 4.556L7.99341 3.35516C8.14008 2.48433 8.25008 1.8335 9.79925 1.8335H12.2009C13.7501 1.8335 13.8692 2.521 14.0067 3.36433L14.2084 4.556"
                            stroke={stroke ? stroke : '#fff'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Vector_3"
                            d="M17.2793 8.37842L16.6834 17.6092C16.5826 19.0484 16.5001 20.1667 13.9426 20.1667H8.05761C5.50011 20.1667 5.41761 19.0484 5.31678 17.6092L4.72095 8.37842"
                            stroke={stroke ? stroke : '#fff'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Vector_4"
                            d="M9.46924 15.125H12.5217"
                            stroke={stroke ? stroke : '#fff'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Vector_5"
                            d="M8.70825 11.4585H13.2916"
                            stroke={stroke ? stroke : '#fff'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
export default TrashIcon;
