import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
    closeP2PSocket,
    connectP2PSocket,
    isP2PSocketConnected,
    sendP2PSocketData,
} from '@/Utils/socket-actions/p2p-connection/p2pConnection';
import { closeWebSocket } from '@/Utils/webSocketUtils';
import { useChatHelper } from '@/hooks';
import useNotificationServerHelper from '@/hooks/useNotificationServerHelper';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
    channelDataSelector,
    serverDataSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import {
    getServerData,
    setActiveServer,
} from '@/store/server-data-reducer/server-data-reducer';
import {
    setMemberCountLoader,
    triggerSettingsModal,
} from '@/store/util-reducer';
import { IServerData } from '@/types';

const useHome = () => {
    const dispatch = useAppDispatch();

    const [emptyData, setEmptyData] = useState(false);
    const [listError, setListError] = useState(false);

    useChatHelper();
    useNotificationServerHelper();
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    // redux selectors
    const activeMobileTab = useAppSelector(
        state => state.mobileViewReducer.activeTab,
    );
    const isElectronConnected = useAppSelector(
        state => state.socketConnectionReducer.WSElectron,
    );
    const channelList = useAppSelector(state => channelDataSelector(state));
    const settingsModal = useAppSelector(
        state => state.utilReducer.settingsModal,
    );
    const serverData = useAppSelector(state => serverDataSelector(state));
    const serverDataSelect = useAppSelector(state => state.serverDataReducer);

    const { activeServer } = serverDataSelect;
    const serverList = serverDataSelect.serverData;
    const serverDetails: IServerData = serverList[activeServer];

    // const
    const serverCount = serverData.map((el: any) => el?.total_count);
    const initialValue = 0;
    const filteredCount = serverCount.filter(
        element => element && typeof element === 'number',
    );

    const sumWithInitial = filteredCount?.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        initialValue,
    );

    // memoized objects
    const memoizedChannelList = useMemo(() => channelList, [channelList]);
    const memoizedActiveServer = useMemo(() => activeServer, [activeServer]);
    const memoizedCount = useMemo(() => sumWithInitial, [sumWithInitial]);
    const privateKey = useMemo(
        () => serverDetails?.privateKey ?? '',
        [serverDetails?.privateKey],
    );

    const chooseServerHandler = useCallback(
        (serverId: string) => {
            dispatch(setMemberCountLoader(true));
            dispatch(setActiveServer(serverId));
            const newServer = serverList[serverId];
            if (!newServer.privateKey) {
                closeWebSocket();
            }
        },
        [serverList, dispatch],
    );

    const handleCloseSettingsModal = useCallback(() => {
        dispatch(triggerSettingsModal({ visible: false }));
    }, [dispatch]);

    useEffect(() => {
        const getList = results => {
            const { data, error } = results.payload;
            error ? setListError(true) : setListError(false);
            setEmptyData(!error && data?.length === 0);
        };

        dispatch(getServerData()).then(results => getList(results));

        const intervalId = setInterval(() => {
            if ((!emptyData && serverData.length === 0) || listError) {
                dispatch(getServerData()).then(results => getList(results));
            } else {
                clearInterval(intervalId);
            }
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (isElectronConnected) {
            try {
                window
                    .require('electron')
                    .ipcRenderer.send('setBadge', memoizedCount);
            } catch (e) {
                console.log(e, 'could not send electron badge');
            }
        }
    }, [memoizedCount, isElectronConnected]);

    return {
        activeServer: memoizedActiveServer,
        channelList: memoizedChannelList,
        privateKey,
        chooseServerHandler,
        handleCloseSettingsModal,
        settingsModal,
        isElectronConnected,
        serverData,
        isMobile,
        emptyData,
        activeMobileTab,
        listError,
    };
};

export default useHome;
