import EmojiPicker, {
    EmojiClickData,
    EmojiStyle,
    Theme,
} from 'emoji-picker-react';
import { PickerConfig } from 'emoji-picker-react/dist/config/config';
import { useSelector } from 'react-redux';

import { RootState } from '@/store';

import './EmojiKeyBoard.css';

interface Props extends PickerConfig {
    onSelect: Function;
    emjTheme?: string;
    width?: string;
    height?: string;
}

const EmojiKeyBoard = (props: Props) => {
    const themeColor: any = useSelector<RootState>(
        state => state.themeColorReducer.themeColor,
    );

    function onClick(emojiData: EmojiClickData, event: MouseEvent) {
        props.onSelect(emojiData);
    }

    return (
        <>
            <EmojiPicker
                width={props?.width ? props?.width : '300px'}
                height={props.height ? props?.height : '430px'}
                onEmojiClick={onClick}
                emojiStyle={EmojiStyle.NATIVE}
                theme={Theme[themeColor]}
                {...props}
            />
        </>
    );
};
export default EmojiKeyBoard;
