import { t } from '@lingui/macro';
import { FC, useEffect, useState } from 'react';

import { Badge } from '@/UI';
import { leaveChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import { sendWebSocketData } from '@/Utils/webSocketUtils';
import ExitIcon from '@/assets/icons/ExitIcon';
import allMessages from '@/assets/icons/MentionsMessages.svg';
import SearchIcon from '@/assets/icons/SearchIcon';
import SettingIcon from '@/assets/icons/SettingIcon';
import { ReactComponent as ThreadListIcon } from '@/assets/icons/ThreadList.svg';
import { ReactComponent as BellWhiteIcon } from '@/assets/icons/bell-white.svg';
import { ReactComponent as KeyIcon } from '@/assets/icons/key-2.svg';
import mentionIcon from '@/assets/icons/mention.svg';
import soundMuteIcon from '@/assets/icons/sound-mute.svg';
import UploadMessagesKey from '@/components/upload-messages-key/UploadMessagesKey';
import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';
import { useCustomSweetAlert } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
    activeChannelIdSelector,
    channelDataDetailsSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import { updateFocusNotification } from '@/store/util-reducer';

import classes from '../ChatAreaSection.module.css';

type Props = {
    setIsSearchInput: any;
    setIsThreadList: any;
    setIsEditChannel: any;
};

const ChatAreaIcons: FC<Props> = props => {
    const dispatch = useAppDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [isUploadKey, setIsUploadKey] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const channelDetails: any = useAppSelector(channelDataDetailsSelector);
    const notificationsCount = useAppSelector(
        state =>
            state.serverDataReducer.notifications?.[
                state.serverDataReducer.activeServer
            ]?.metaData?.total_unread_notification,
    );

    const isDevMode = useAppSelector(
        state => state.userPreferencesReducer.isDevMode,
    );

    const actionIcons = [
        {
            icon: allMessages,
            label: t`All Messages`,
            status: 1,
            onClick: e => {
                setSelectedOption(e);
                handleNotificationStatusUpdate(e.status);
            },
        },
        {
            icon: mentionIcon,
            label: t`Only Mentioned`,
            status: 2,
            onClick: e => {
                setSelectedOption(e);
                handleNotificationStatusUpdate(e.status);
            },
        },
        {
            icon: soundMuteIcon,
            label: t`Mute`,
            status: 3,
            onClick: e => {
                setSelectedOption(e);
                handleNotificationStatusUpdate(e.status);
            },
        },
    ];

    const leaveChannelHandler = () => {
        activeChannelId && leaveChannel(activeChannelId);
    };

    const handleNotificationStatusUpdate = status => {
        const payload = {
            action: SOCKET_ACTIONS_WITH_TYPES.channelNotificationUpdate.action,
            data: {
                channel_id: activeChannelId,
                notification_state: status,
            },
        };
        sendWebSocketData(JSON.stringify(payload));
    };

    useEffect(() => {
        setSelectedOption(
            channelDetails &&
                (actionIcons?.find(
                    item => item.status === channelDetails?.notification_state,
                ) ??
                    actionIcons[0]),
        );
    }, [channelDetails]);

    const { areYouSureModal } = useCustomSweetAlert();
    return (
        <div className={classes['areaIconsContainer']}>
            {isDevMode && (
                <div
                    className={classes['areaIconsItem']}
                    onClick={() => setIsUploadKey(true)}>
                    <KeyIcon
                        fill="#ffffff"
                        style={{ height: 26, cursor: 'pointer' }}
                    />
                </div>
            )}

            <div
                className={classes['areaIconsItem']}
                onClick={() => {
                    props?.setIsThreadList(true);
                }}>
                <ThreadListIcon style={{ height: 26, cursor: 'pointer' }} />
            </div>
            <div
                className={classes['areaIconsItem']}
                onClick={() => {
                    props.setIsSearchInput(true);
                }}>
                <SearchIcon stroke="white" />
            </div>
            <div
                onClick={() => {
                    props?.setIsEditChannel(true);
                }}>
                <SettingIcon
                    className={classes['areaIconsItem']}
                    fill={undefined}
                />
            </div>
            <div
                className={classes['areaIconsItem']}
                onClick={() => {
                    areYouSureModal(
                        t`Confirm Leave`,
                        t`By clicking on Delete button you wont be able to use
                        this channel anymore.`,
                        false,
                        leaveChannelHandler,
                        t`Leave Channel`,
                    );
                }}>
                <ExitIcon className={`room-icon ${classes['exit-icon']}`} />
            </div>
            <div
                className={classes['areaIconsItem']}
                id="header-bell-icon"
                style={{ position: 'relative' }}
                onClick={() => dispatch(updateFocusNotification(true))}>
                {!isNaN(notificationsCount) && (
                    <Badge className={classes['badge']} limit={true}>
                        {notificationsCount}
                    </Badge>
                )}
                <BellWhiteIcon
                    className={`room-icon ${classes['exit-icon']}`}
                />
            </div>
            <div
                onMouseLeave={() => setIsOpen(false)}
                className={`${classes['tree-dots-container']} ${classes['areaIconsItem']}`}>
                <div className={classes['room-header-notificaiton-container']}>
                    <div onMouseEnter={() => setIsOpen(true)}>
                        <img
                            alt=""
                            className={classes['badge-image']}
                            src={selectedOption?.icon}
                        />
                    </div>
                </div>
                {isUploadKey && (
                    <UploadMessagesKey onClose={() => setIsUploadKey(false)} />
                )}

                {isOpen && (
                    <div
                        onMouseLeave={() => setIsOpen(false)}
                        className={classes['edit-image-popup-container']}>
                        <div className={classes['edit-image-popup']}>
                            <div className={classes['triangle']}></div>
                            {actionIcons.map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => item?.onClick(item)}
                                    className={classes['edit-image-popup-row']}>
                                    <img
                                        alt=""
                                        height={18}
                                        width={18}
                                        src={item?.icon}
                                    />
                                    <p
                                        className={
                                            classes[
                                                'edit-image-popup-row-label'
                                            ]
                                        }>
                                        {item?.label}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatAreaIcons;
