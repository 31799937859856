import { motion } from 'framer-motion';
import React, { useCallback } from 'react';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/library.css';
import { ViewportList } from 'react-viewport-list';

import DateLineBreak from '@/UI/date-line-beak/DateLineBreak';
import { dateFormatHHMM } from '@/Utils/dateUtils';
import arrowDownIcon from '@/assets/icons/arrow-down.svg';
import { IMessageData } from '@/types';

import MessageItem from '../message-item/MessageItem';

import classes from './MessageList.module.css';
import useMessageList from './useMessageList';

const Messages: React.FC<{
    members?: [];
    messages?: {
        _id: string;
        created_at: string;
        user_name: string;
        user_id: string;
        message: string;
        className: string;
        ownerImg: string;
        reactions: {}[];
        isSending: boolean;
    }[];

    onReplyMessage?: Function;
    scrollToMessage?: { _id: string; created_at: string | Date };
    onNewChat: Function;
}> = props => {
    const {
        sortedDisplayBuffer,
        messageReplyHandler,
        listRef,
        loadMore,
        viewRef,
        targetScrollMessage,
        scrollToEnd,
        isMessageListBottom,
        setIsMessageListBottom,
    } = useMessageList(props);

    const handleNewChat = useCallback((id: string) => props.onNewChat(id), []);
    const handleReaction = index => {
        if (index === sortedDisplayBuffer?.length - 1) {
            setTimeout(() => {
                scrollToEnd();
            }, 200);
        }
    };

    const _renderMessagesItem = (item: IMessageData, index) => {
        if (item === undefined) {
            console.log('undefined ERRROR');
            return <></>;
        }

        const reversedIndex = sortedDisplayBuffer?.length - index;
        const date = new Date(item?.created_at);
        const prevDate = new Date(sortedDisplayBuffer[index - 1]?.created_at);
        const showDate =
            reversedIndex < sortedDisplayBuffer?.length &&
            date.toLocaleDateString() !== prevDate.toLocaleDateString() &&
            prevDate.toLocaleDateString !== undefined;

        return (
            <motion.div
                initial={{ opacity: 0, y: 10 }}
                exit={{ opacity: 0, y: 10 }}
                animate={{
                    opacity: 1,
                    y: 0,
                    transition: { delay: 0, duration: 0.2 },
                }}
                style={{ margin: '8px 0' }}
                id={`message-list-item-${index}`}
                key={`message-list-item-${index}-${item?._id}`}>
                <div
                    style={{
                        padding: '0 16px',
                        marginLeft: 16,
                    }}>
                    {reversedIndex === sortedDisplayBuffer?.length && (
                        <DateLineBreak date={date} />
                    )}
                    {showDate && <DateLineBreak date={date} />}
                </div>

                <MessageItem
                    id={item?._id}
                    messageStyle={
                        (targetScrollMessage?.original_message_id ===
                            item?._id ||
                            targetScrollMessage?._id === item?._id) &&
                        true
                    }
                    key={`message-list-item-${index}-${item?._id}`}
                    item={item}
                    onReplyMessage={messageReplyHandler}
                    time={dateFormatHHMM(date)}
                    onNewChat={handleNewChat}
                    onReaction={() => handleReaction(index)}
                    isThreadAllowd={true}
                    isThreadMessage={item?.isthread ? item?.isthread : true}
                />
            </motion.div>
        );
    };

    if (sortedDisplayBuffer?.length === 0)
        return <div className={classes['message-container']}></div>;

    const handleScroll = event => {
        const target = event.currentTarget;
        const isBottom =
            target.scrollHeight - target.scrollTop <= target.clientHeight + 50;

        setIsMessageListBottom(isBottom);
    };

    return (
        <div
            ref={listRef}
            onScroll={handleScroll}
            className={classes['message-container']}>
            {sortedDisplayBuffer?.length > 0 && (
                <>
                    {loadMore && (
                        <div className={classes['load-more']}>
                            <Spinner />
                        </div>
                    )}
                    <ViewportList
                        items={sortedDisplayBuffer}
                        initialIndex={sortedDisplayBuffer?.length - 1}
                        indexesShift={sortedDisplayBuffer?.length - 1}
                        initialAlignToTop={false}
                        initialPrerender={10}
                        overscan={10}
                        children={_renderMessagesItem}
                        ref={viewRef}
                        viewportRef={listRef}
                    />
                    {!isMessageListBottom && (
                        <div
                            onClick={() => scrollToEnd()}
                            className={classes['float-scroll-button']}>
                            <img alt="" src={arrowDownIcon} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
export default React.memo(Messages);
