import { createSlice } from '@reduxjs/toolkit';

interface UserPreferencesState {
    isInUploadKeyPage: boolean | null;
    peerFound: string;
    p2pConnection: string | null;
    p2pKeys;
    subserverKeys: { publicKey?: {}; privateKey?: {} };
    isP2PPanelOpen: boolean;
    waitingList: {
        [connection_id: string]: { connection_id: string; user_id: string };
    };
}

const initialState: UserPreferencesState = {
    isInUploadKeyPage: null,
    peerFound: '',
    p2pConnection: null,
    p2pKeys: {},
    subserverKeys: {},
    isP2PPanelOpen: false,
    waitingList: {
        '1234': {
            user_id: '8dcdd7d2-4419-4e49-bbc3-570bfd633b53',
            connection_id: '1234',
        },
    },
};

const p2pSlice = createSlice({
    name: 'user-preferences',
    initialState,
    reducers: {
        updateIsInKeyUpload(state, action) {
            state.isInUploadKeyPage = action.payload;
        },
        updatePeerFound(state, action) {
            state.peerFound = action.payload;
        },
        updateP2PSConnection(state, action) {
            state.p2pConnection = action.payload;
        },
        setP2PKeys(state, action) {
            state.p2pKeys = {
                JSON_SERVER: action.payload.JSON_SERVER,
                JSON_USER: action.payload.JSON_USER,
            };
        },
        updateP2PKeys(state, action) {
            state.subserverKeys = action.payload;
        },
        toggleP2PPanel(state, action) {
            if (action?.payload?.state) {
                state.isP2PPanelOpen = action?.payload?.state;
            } else {
                state.isP2PPanelOpen = !state.isP2PPanelOpen;
            }
        },
        updateWaitingUser(state, action) {
            state.waitingList[action.payload?.connection_id] = action.payload;
        },
    },
});

export const p2pActions = p2pSlice.actions;
export default p2pSlice.reducer;
