import { createSlice } from '@reduxjs/toolkit';

const initialState = { searchResults: [] };

const BackendSearchResultsSlice = createSlice({
    name: 'backend_search_results',
    initialState: initialState,
    reducers: {
        setBackEndSearchResult(state, action) {
            state.searchResults = action.payload;
        },
    },
});

export const backendSearchActions = BackendSearchResultsSlice.actions;
export default BackendSearchResultsSlice.reducer;
