import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';

import { sendWebSocketData } from '../../webSocketUtils';

export const sendInitialsAgain = (userToken, subserver_id) => {
    let payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.auth.action,
        data: {
            user_token: userToken,
            subserver_id: subserver_id,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};
