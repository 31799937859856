import store from '@/store';
import { IMessageData } from '@/types';

import {
    base64ToArrayBuffer,
    decryptDataWithRSA,
    decryptWithAES,
    importKey,
} from './cryptoUtils';

export const onDecryptMessage = async (data: IMessageData, pvtKey?: any) => {
    let decryptToken: { key?: string; iv?: string | any } =
        await decryptDataWithRSA(data?.token, pvtKey);

    if (!decryptToken && data.key_hash) {
        const privateKeysObject = store.getState().cryptoKeyReducer;
        if (privateKeysObject?.[data.key_hash]?.privateKey) {
            decryptToken = await decryptDataWithRSA(
                data?.token,
                privateKeysObject?.[data.key_hash]?.privateKey,
            );
        } else {
            return { is_encrypt: true };
        }

        if (!decryptToken) return { is_encrypt: true };
    }

    const algorithm = { name: 'AES-GCM', length: 256 };

    const AESKey = await importKey(
        JSON.stringify(decryptToken?.key ?? decryptToken ?? {}),
        algorithm,
    );
    const encryptedData = base64ToArrayBuffer(data.message);
    const iv =
        decryptToken.iv && new Uint8Array(Object.values(decryptToken.iv));
    const decryptMessage = await decryptWithAES(encryptedData, AESKey, iv);

    return JSON.parse(decryptMessage);
};
