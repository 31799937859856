import React, { useEffect, useReducer } from 'react';
import { ViewportList } from 'react-viewport-list';

import Card from '@/UI/card/Card';
import Line from '@/UI/hr/Line';
import InviteFriends from '@/UI/invite-modal/InviteFriends';
import EditSection from '@/components/edit-section/EditSection';
import { useAppSelector } from '@/hooks/useRedux';
import {
    activeChannelIdSelector,
    userPermissionSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import CreateChannel from '../../server-banner/server-dropdown-options/create-channel/CreateChannel';
import ChannelDrag from '../channel/ChannelDrag';
import {
    ActionTypes,
    ChannelSectionPropsTypes,
    channelType,
    initialStateTypes,
} from '../channelDataTypes';
import SectionHeader from '../section-header/SectionHeader';

import classes from './ChannelsGroup.module.css';

const initialState: initialStateTypes = {
    isEditSection: false,
    isCreatChannel: false,
    isInvite: false,
    isAccordion: true,
    sectionChannels: [],
};

const channelSectionReducer = (
    state: initialStateTypes,
    action: ActionTypes,
) => {
    switch (action.type) {
        case 'CHANGE_EDIT_MODE':
            return {
                ...state,
                isEditSection: action.value,
            };
        case 'CHANGE_CREATE_CHANNEL_MODE':
            return {
                ...state,
                isCreatChannel: action.value,
            };
        case 'CHANGE_INVITE_MODE':
            return {
                ...state,
                isInvite: action.value,
            };
        case 'CHANGE_TOGGLE_MODE':
            return {
                ...state,
                isAccordion: !state.isAccordion,
            };
        case 'CHANGE_SECTION_CHANNELS':
            return {
                ...state,
                sectionChannels: action.value,
            };
        default:
            return state;
    }
};

const ChannelsGroup: React.FC<ChannelSectionPropsTypes> = props => {
    const { section, unreadOnly } = props;

    const [sectionState, dispatchReducer] = useReducer(
        channelSectionReducer,
        initialState,
    );

    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const permissions = useAppSelector(userPermissionSelector);
    const channelCount = useAppSelector(
        state =>
            state.serverDataReducer.channelCounts?.[
                state.serverDataReducer.activeServer
            ],
    );

    const addRoomHandler = () => {
        dispatchReducer({ type: 'CHANGE_CREATE_CHANNEL_MODE', value: true });
    };

    const onInviteHandler = event => {
        event.stopPropagation();
        dispatchReducer({ type: 'CHANGE_INVITE_MODE', value: true });
    };

    const toggleAccordionHandler = () => {
        dispatchReducer({ type: 'CHANGE_TOGGLE_MODE' });
    };

    const accordionClass = sectionState.isAccordion
        ? classes['accordion']
        : classes['accordion-disactive'];

    useEffect(() => {
        let channelsOfSection = section?.channelList?.sort((a, b) => {
            if (a.hasOwnProperty('priority') && !b.hasOwnProperty('priority')) {
                return -1;
            } else if (
                !a.hasOwnProperty('priority') &&
                b.hasOwnProperty('priority')
            ) {
                return 1;
            } else {
                return (a.priority || 0) - (b.priority || 0);
            }
        });

        if (unreadOnly) {
            channelsOfSection = channelsOfSection?.filter(
                (ch: channelType) =>
                    channelCount?.[ch._id] > 0 || ch._id === activeChannelId,
            );
        }
        dispatchReducer({
            type: 'CHANGE_SECTION_CHANNELS',
            value: channelsOfSection,
        });
    }, [unreadOnly, section]);

    return (
        <>
            {(sectionState.sectionChannels?.length > 0 ||
                permissions?.['channel.edit']) && (
                <>
                    {section?._id === undefined ? (
                        <ul
                            id={section._id}
                            className={`${classes.ul} ${accordionClass}`}>
                            {sectionState.sectionChannels?.length > 0 && (
                                <ViewportList
                                    overscan={4}
                                    initialIndex={0}
                                    indexesShift={0}
                                    initialAlignToTop={false}
                                    items={sectionState.sectionChannels}>
                                    {(channel, key) => {
                                        channel?._id && (
                                            <ChannelDrag
                                                key={key}
                                                // @ts-ignore
                                                channel={channel}
                                                index={key}
                                            />
                                        );
                                    }}
                                </ViewportList>
                            )}
                        </ul>
                    ) : (
                        <Card className={classes.card}>
                            <SectionHeader
                                isAccordion={sectionState.isAccordion}
                                id={section._id}
                                title={section.name}
                                onSectionInvite={addRoomHandler}
                                onInviteSection={onInviteHandler}
                                onToggleAccordion={toggleAccordionHandler}
                                onEditSection={() => {
                                    dispatchReducer({
                                        type: 'CHANGE_EDIT_MODE',
                                        value: true,
                                    });
                                }}
                            />
                            <Line
                                className={`${classes.hr} ${accordionClass}`}
                            />
                            <ul
                                id={section._id}
                                className={`${classes.ul} ${accordionClass}`}>
                                <ViewportList
                                    overscan={4}
                                    indexesShift={0}
                                    initialAlignToTop={true}
                                    items={sectionState.sectionChannels}>
                                    {(channel, key) => (
                                        <ChannelDrag
                                            key={key}
                                            // @ts-ignore
                                            channel={channel}
                                            index={key}
                                        />
                                    )}
                                </ViewportList>
                            </ul>
                        </Card>
                    )}

                    {sectionState.isInvite && (
                        <InviteFriends
                            onClose={() => {
                                dispatchReducer({
                                    type: 'CHANGE_INVITE_MODE',
                                    value: false,
                                });
                            }}
                            channelName={section.name}
                            channelId={sectionState.sectionChannels[0]?._id}
                            channel={sectionState.sectionChannels[0]}
                            sectionChannels={sectionState.sectionChannels.map(
                                el => el._id,
                            )}
                            section_id={section._id}
                        />
                    )}

                    {sectionState.isCreatChannel && (
                        <CreateChannel
                            onClose={() => {
                                dispatchReducer({
                                    type: 'CHANGE_CREATE_CHANNEL_MODE',
                                    value: false,
                                });
                            }}
                            sectionId={section._id}
                        />
                    )}

                    {sectionState.isEditSection && (
                        <EditSection
                            name={section.name}
                            description={section?.description}
                            id={section._id}
                            onClose={() => {
                                dispatchReducer({
                                    type: 'CHANGE_EDIT_MODE',
                                    value: false,
                                });
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};
export default ChannelsGroup;
