import { createAsyncThunk } from '@reduxjs/toolkit';

import fetchData from '@/Utils/fetchData';
import { REQUEST_TYPE } from '@/constants/requstTypes';

export const getVersionList = async () => {
    const url = 'https://api.cht-root.com/api/version/list';
    const res = await fetchData(REQUEST_TYPE.GET, url);
    return res;
};

export const fetchVersionList = createAsyncThunk(
    'versionDownload/fetchVersionList',
    async () => {
        const res = await getVersionList();
        return res.data;
    },
);

function useVersionDownload() {
    return {};
}

export default useVersionDownload;
