import { REQUEST_TYPE } from '@/constants/requstTypes';

import fetchData from './fetchData';
import { decryptFile, encryptFile } from './mediaCrypto.js';

export const getServer = async (mediaFile, serverLink) => {
    const params = {
        size: mediaFile.size,
        type: mediaFile.type,
    };

    const { data, error } = await fetchData(
        REQUEST_TYPE.GET,
        `${serverLink}/server`,
        params,
    );
    if (data && data.data) {
        return { responseLink: data?.data };
    } else {
        console.error('an error happend');
        return { error: error };
    }
};

export const uploadFiles = async (
    mediaFile,
    subserver,
    responseLink,
    token,
    channelId,
    encrypt = true,
    onUploadProgressCallback,
) => {
    let encryptedFile = mediaFile;
    if (encrypt) {
        encryptedFile = await encryptFile(mediaFile);
        encryptedFile = encryptedFile.encryptedFile;
    }
    let formData = new FormData();
    formData.append('upload', encryptedFile, encryptedFile.name);
    formData.append('subserver_id', subserver);
    formData.append('token', token);

    const { data } = await fetchData(
        REQUEST_TYPE.POST,
        responseLink,
        formData,
        {
            meta_data: JSON.stringify({
                type: mediaFile.type,
                name: mediaFile.name,
            }),
            subserver_id: subserver,
            channel_id: channelId,
        },
        {
            Authorization: `Bearer ${token}`,
        },
        null,
        null,
        onUploadProgressCallback,
    );

    return {
        uploadedFileLink: data,
    };
};

export const downloadFile = async (
    props,
    uploadedFileLink,
    token,
    onDownloadProgressCallback,
) => {
    const link = `https://${uploadedFileLink.server}/api/download/${uploadedFileLink.fileKey}?token=${token}`;

    const { error, data } = await fetchData(
        REQUEST_TYPE.POST,
        link,
        {},
        {},
        {},
        { responseType: 'blob' },
        onDownloadProgressCallback, // Pass the callback function for download progress
    );
    if (error) return { error };
    const decryptedData = await decryptFile(data);
    const getFileUrl = `https://${uploadedFileLink.server}/api/info/${uploadedFileLink.fileKey}?token=${token}`;
    const getFileInfo = await fetchData(REQUEST_TYPE.POST, getFileUrl);

    return {
        fileLink: decryptedData?.decryptedFile,
        fileInfo: getFileInfo.data,
    };
};
