import { FC, memo, useCallback, useEffect, useState } from 'react';

import { checkfileAvailability } from '@/Utils/checkFileAvailability';
import { getFileById } from '@/Utils/mediaFilesUtils';

import AudioMedia from './AudioMedia';
import DownloadMedia from './DownloadMedia';
import ImageMedia from './ImageMedia';
import LoadMore from './LoadMore';
import { useMediaDisplayContext } from './MediaDisplayContext';
import PdfMedia from './PdfMedia';
import classes from './SelectedMediaDisplay.module.css';
import { SortedMediaProps } from './SelectedMediaTypes';
import UnknowMedia from './UnknowMedia';
import VideoMedia from './VideoMedia';

const SelectedMediaDisplaySort: FC<SortedMediaProps> = props => {
    const { file, index, noCopy } = props;

    const [fileExists, setFileExists] = useState<null | boolean>(null);
    const [updatedFile, setUpdatedFile] = useState(file);

    const {
        filesLength,
        setFilesArray,
        files,
        viewMediaHandler,
        newLoadedMediaHandler,
        showReadMore,
    } = useMediaDisplayContext();

    const addReadMore = showReadMore(index);
    
    const updateFileUrl = useCallback(async () => {
        let mediaFile: any = {};
        try {
            mediaFile = await getFileById(file?.fileKey);
            const fileBlob = new Blob([mediaFile.data], {
                type: mediaFile.type,
            });
            mediaFile.src = URL.createObjectURL(fileBlob);
            setUpdatedFile(mediaFile);
            newLoadedMediaHandler(mediaFile.src, file);
        } catch (error) {
            console.error('Error getting file from DB');
        }
    }, [file]);

    const downloadCompleteHandler = () => {
        getIfChecked();
    };

    async function getIfChecked() {
        if (!fileExists) {
            const isChecked = await checkfileAvailability(file);
            setFileExists(isChecked);
        }
    }

    useEffect(() => {
        if (!file) {
            return;
        }
        getIfChecked();
    }, [file, fileExists]);

    useEffect(() => {
        if (fileExists && file && !file?.src) {
            updateFileUrl();
        }
    }, [fileExists, file, updateFileUrl]);

    return (
        <article
            className={`${classes['wrap-0']} ${
                fileExists &&
                ((!updatedFile.type?.startsWith('image/svg') &&
                    updatedFile?.type.startsWith('image/')) ||
                    file.type?.startsWith('video/'))
                    ? classes['double']
                    : classes['single']
            }`}>
            {!updatedFile?.data || !fileExists ? (
                <DownloadMedia
                    onDownloadComplete={downloadCompleteHandler}
                    fileExists={fileExists}
                    file={updatedFile}
                />
            ) : updatedFile?.src && updatedFile?.type ? (
                <div
                    className={classes['wrap-1']}
                    onClick={() => viewMediaHandler(index)}>
                    {updatedFile.type?.startsWith('image/') &&
                    !updatedFile.type?.startsWith('image/svg') ? (
                        <ImageMedia
                            name={updatedFile?.name ?? ''}
                            index={index}
                            noCopy={noCopy}
                            src={updatedFile.src}
                        />
                    ) : file.type?.startsWith('video/') ? (
                        <VideoMedia
                            index={index}
                            src={updatedFile.src}
                            name={updatedFile?.name ?? ''}
                            type={file.type}
                        />
                    ) : file.type?.startsWith('audio/') ? (
                        <AudioMedia file={updatedFile} />
                    ) : updatedFile.type?.startsWith('application/pdf') ? (
                        <PdfMedia file={updatedFile} />
                    ) : (
                        <UnknowMedia file={updatedFile} />
                    )}
                </div>
            ) : (
                <UnknowMedia file={updatedFile} />
            )}
            {addReadMore && (
                <LoadMore
                    onClick={() => setFilesArray(files)}
                    filesLength={filesLength}
                />
            )}
        </article>
    );
};
export default memo(SelectedMediaDisplaySort);
