import { Trans, t } from '@lingui/macro';
import { ReactNode, useEffect, useState } from 'react';

import EmptyTick from '@/assets/icons/EmptyTick.svg';
import ExitIcon from '@/assets/icons/ExitIcon';
import ManageIcon from '@/assets/icons/ManageIcon';
import SettingIcon from '@/assets/icons/SettingIcon';
import SquareAddIcon from '@/assets/icons/SquareAddIcon';
import EditButton from '@/assets/icons/editManagement.svg';
import { ReactComponent as LockIcon } from '@/assets/icons/lock.svg';
import { useAppSelector } from '@/hooks/useRedux';
import { channelCountsListSelector } from '@/store/server-data-reducer/selectors-server-data';

import { ALL_PERMISSIONS } from './enum';

export type optionTypes = {
    id: string;
    icon: ReactNode | string;
    name: string | ReactNode;
    userPermissions: string[];
};

function useSubServerOptions(channelListEditModeValue) {
    const [btnVisible, setBtnVisible] = useState<boolean>(false);
    const isConnected = useAppSelector(state => state.socketConnectionReducer);
    const countList = useAppSelector(channelCountsListSelector);
    const unreadChannel = isConnected.mainWS
        ? countList?.reduce((acc, curr) => acc + (curr ?? 0), 0)
        : 0;

    useEffect(() => {
        setBtnVisible(!!unreadChannel);
    }, [unreadChannel]);

    const listArray: optionTypes[] = [
        {
            id: 'createChannel',
            icon: <SquareAddIcon fill="var(--drop-down-item)" />,
            name: t`Create Channel`,
            userPermissions: ['channel.create', 'owner', 'administrator'],
        },
        {
            id: 'createSection',
            icon: <SquareAddIcon fill="var(--drop-down-item)" />,
            name: t`Create Section`,
            userPermissions: ['channel.create', 'owner', 'administrator'],
        },
        // {
        //     id: 'p2pconnections',
        //     icon: (
        //         <P2PCOnnectionIcon
        //             stroke="var(--drop-down-item)"
        //             width={16}
        //             height={16}
        //         />
        //     ),
        //     name: 'P2P Connections',
        //     userPermissions: ['owner', 'administrator'],
        // },
        {
            id: 'management',
            icon: <ManageIcon stroke="var(--drop-down-item)" />,
            name: t`Management`,
            userPermissions: ['role.create', 'owner', 'administrator'],
        },
        {
            id: 'editServer',
            icon: <SettingIcon fill="var(--drop-down-item)" />,
            name: t`Edit Server Profile`,
            userPermissions: ['server.edit', 'owner', 'administrator'],
        },
        {
            id: 'changeKeys',
            icon: <LockIcon />,
            name: t`Change Keys`,
            userPermissions: ALL_PERMISSIONS,
        },
        {
            id: 'channelListEditMode',
            icon: <img height={18} src={EditButton} alt="" />,
            name: (
                <div className="">
                    <Trans>Edit Mode</Trans>:{' '}
                    <b
                        style={{
                            backgroundColor: channelListEditModeValue
                                ? 'green'
                                : 'red',
                            padding: '1px 2px',
                            borderRadius: 2,
                            fontSize: 8,
                        }}>
                        {channelListEditModeValue ? t`ON` : t`OFF`}
                    </b>
                </div>
            ),
            userPermissions: ALL_PERMISSIONS,
        },
        btnVisible && {
            id: 'allRead',
            icon: EmptyTick,
            name: t`Mark All Read`,
            userPermissions: ALL_PERMISSIONS,
        },
        {
            id: 'leaveServer',
            icon: <ExitIcon />,
            name: (
                <span style={{ color: 'var(--red)' }}>
                    <Trans>Leave Server</Trans>
                </span>
            ),
            userPermissions: ALL_PERMISSIONS,
        },
    ];

    return { listArray };
}

export default useSubServerOptions;
