import { memo } from 'react';

import { useAppSelector } from '@/hooks/useRedux';
import {
    activeChannelIdSelector,
    channelDataDetailsSelector,
} from '@/store/server-data-reducer/selectors-server-data';

import Notification from '../Notification/Notification';
import ChatAreaSection from '../chat-area-section/ChatAreaSection';
import EmptyChatArea from '../chat-area-section/EmptyChatArea';
import Connections from '../connections-section/Connections';
import MessageThreadChat from '../message-thread-chat/MessageThreadChat';
import NotesStore from '../notes/NotesStore';

interface IProps {
    callbackHandler: (id: string) => void;
    chatAreaSectionConfig;
}

const RenderSections: React.FC<IProps> = props => {
    const { callbackHandler, chatAreaSectionConfig } = props;
    const isNotesOpen = useAppSelector(state => state.notesReducer.isNotesOpen);

    const channelDetails: any = useAppSelector(channelDataDetailsSelector);
    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const isThreadOpen = useAppSelector(
        state => state.threadsReducer.isOpenThread,
    );
    const focusNotification = useAppSelector(
        state => state.utilReducer.focusNotification,
    );

    const isP2PPanelOpen = useAppSelector(
        state => state.p2pReducer.isP2PPanelOpen,
    );
    const userId = useAppSelector(state => state.userReducer.user.id);
    const memberList = useAppSelector(
        state =>
            state.serverDataReducer.memberList[
                state.serverDataReducer.activeServer
            ],
    );
    const isUserExternal = memberList?.[userId]?.external;

    if (isNotesOpen) {
        return <NotesStore />;
    }
    //  else if (isP2PPanelOpen) {
    //     return <P2PAdminPanel />;
    // }
    else if (channelDetails) {
        return (
            <>
                {!activeChannelId ? (
                    <EmptyChatArea />
                ) : (
                    <>
                        <ChatAreaSection
                            onNewChat={callbackHandler}
                            {...chatAreaSectionConfig}
                        />

                        {!isThreadOpen &&
                        activeChannelId &&
                        channelDetails.privacy !== 'direct' &&
                        isUserExternal === 0 ? (
                            <Connections />
                        ) : (
                            isThreadOpen && (
                                <MessageThreadChat
                                    handleNewChat={callbackHandler}
                                />
                            )
                        )}

                        {focusNotification && (
                            <Notification alignToParent="header-bell-icon" />
                        )}
                    </>
                )}
            </>
        );
    }
};
export default memo(RenderSections);
