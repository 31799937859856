import { ReactComponent as ImageIcon } from '@/assets/icons/Image square.svg';
import { ReactComponent as VideoIcon } from '@/assets/icons/Video.svg';
import { ReactComponent as DocumentIcon } from '@/assets/icons/document-2.svg';
import { ReactComponent as MelodyIcon } from '@/assets/icons/melody.svg';

import classes from './DownloadBtn.module.css';

const DownloadedTypeIcon: React.FC<{ type: string | null }> = props => {
    const { type } = props;

    let ContentType = <></>;

    switch (true) {
        case type.includes('video'):
            ContentType = <VideoIcon width={24} className={classes['icon']} />;
            break;

        case type.includes('image'):
            ContentType = <ImageIcon width={24} className={classes['icon']} />;
            break;

        case type.includes('audio'):
            ContentType = <MelodyIcon width={24} className={classes['icon']} />;
            break;
        default:
            ContentType = (
                <DocumentIcon width={24} className={classes['icon']} />
            );
            break;
    }
    return ContentType;
};
export default DownloadedTypeIcon;
