import { useContext, useEffect, useState } from 'react';

import { MediaMenuContext } from './AttachmentMenuContext';

export const useAttachmentMenu = ({
    reply,
    passDefaultFiles,
    mediaUploaded,
    onToggleVisibility,
}) => {
    const menuContext = useContext(MediaMenuContext);

    const {
        toggleBtnDisability,
        msgInputValue,
        mediaInputValue,
        toggleLoading,
    } = menuContext;

    const [pastedFiles, setPastedFiles] = useState<File[]>(
        passDefaultFiles ?? null,
    );

    const isReplySection = reply?.messageText && reply?.replyfor && reply?.type;

    useEffect(() => {
        if (mediaUploaded) {
            toggleLoading(false);
            onToggleVisibility();
        }
    }, [mediaUploaded, onToggleVisibility, toggleLoading]);

    useEffect(() => {
        mediaInputValue?.length > 0
            ? toggleBtnDisability(false)
            : toggleBtnDisability(true);
    }, [msgInputValue, mediaInputValue, toggleBtnDisability]);

    useEffect(() => {
        const pasteHandler = (event: ClipboardEvent) => {
            const clipboardData = event.clipboardData;
            const files = clipboardData?.files;
            event.preventDefault();
            if (!files || files?.length === 0) {
                return;
            }
            const FilesArray = Object.values(files);
            setPastedFiles(FilesArray);
        };

        document.addEventListener('paste', pasteHandler);

        return () => document.removeEventListener('paste', pasteHandler);
    }, []);

    return {
        isReplySection,
        pastedFiles,
    };
};
export default useAttachmentMenu;
