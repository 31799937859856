import { addMemberSelectStyles } from "@/components/ManagementModal/organization_member/organization-create-member/selectStyles";

export const styles = {
    ...addMemberSelectStyles,
    control: styles => ({
        ...styles,
        backgroundColor: 'var(--dark-input)',
        width: '100%',
        borderColor: 'transparent',
        outline: 0,
        boxShadow: 'none',
        ':active': {
            borderColor: 'transparent',
            outline: 0,
        },
        ':hover': {
            borderColor: 'transparent',
            outline: 0,
        },
    }),
};