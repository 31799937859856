import { copyImageToClipboard } from 'copy-image-clipboard';
import { Key, MouseEvent, useEffect, useRef, useState } from 'react';

import MediaViewNextArrow from './MediaViewNextArrow';
import MediaViewPrevArrow from './MediaViewPrevArrow';
import classes from './MediaViewer.module.css';
import { dataType, mediaType } from './MediaViewerTypes';

const useMediaViewer = (data: dataType[], initialSlide: number | Key) => {
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);

    const [mediaToShow, setMediaToShow] = useState<mediaType[]>([]);
    const [isCopiedPhoto, setIsCopiedPhoto] = useState<boolean>(false);
    const [isImage, setIsImage] = useState<boolean | undefined>(undefined);
    const [hasChanged, setHasChanged] = useState<boolean>(false);
    const [initialRender, setInitialRender] = useState<boolean>(true);

    const copyBlobImageToClipboard = () => {
        let targetMedia = document.querySelector('.slick-active img');

        if (!targetMedia) {
            return;
        }
        const imgUrl = targetMedia.getAttribute('src');

        copyImageToClipboard(imgUrl)
            .then(() => {
                setIsCopiedPhoto(true);
            })
            .catch(error => {
                console.error('error -> ', error);
            });

        setTimeout(() => {
            setIsCopiedPhoto(false);
        }, 3000);
    };

    const settings = {
        dots: true,
        dotsClass: 'slick-dots',
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        fade: true,
        className: classes['slick-slider'],
        lazyLoad: 'ondemand',
        focusOnSelect: true,
        initialSlide: initialSlide ? initialSlide : 0,
        nextArrow: <MediaViewNextArrow />,
        prevArrow: <MediaViewPrevArrow />,
        beforeChange: (currentSlide: number) => {
            const currentMediaItem = mediaToShow[currentSlide];
            // if the current slide is video pause it
            if (currentMediaItem?.type.includes('video')) {
                const videoElement = document.querySelector(
                    `.slick-slide[data-index="${currentSlide}"] video`,
                ) as HTMLVideoElement | null;

                if (videoElement) {
                    videoElement.pause();
                }
            }
            if (!hasChanged) {
                const firstMediaItem = mediaToShow[currentSlide];
                if (firstMediaItem) {
                    setIsImage(firstMediaItem.type.includes('image'));
                }
                setHasChanged(true);
            }
        },

        afterChange: (currentSlide: number) => {
            const currentMediaItem = mediaToShow[currentSlide];

            if (currentMediaItem && hasChanged) {
                setIsImage(currentMediaItem.type.includes('image'));
            }
        },

        customPaging: function (i: number) {
            const mediaItem = mediaToShow && mediaToShow[i];
            return (
                <a className={classes['arrow-link']} href="/">
                    {mediaItem.type.includes('video') && (
                        <video
                            src={mediaItem.src}
                            className={classes['arrow-img']}>
                            <source src={mediaItem.src} type={'video/mp4'} />
                        </video>
                    )}
                    {mediaItem.type.includes('image') && (
                        <img
                            alt=""
                            className={classes['arrow-img']}
                            src={mediaItem.src}
                        />
                    )}
                </a>
            );
        },
    };

    useEffect(() => {
        if (initialRender && mediaToShow.length > 0) {
            const firstMediaItem = mediaToShow[0];
            setIsImage(firstMediaItem.type.includes('image'));
            setHasChanged(true);
            setInitialRender(false);
        }
    }, [initialRender, mediaToShow]);

    useEffect(() => {
        setMediaToShow(
            data.filter(
                e =>
                    e?.type &&
                    ((!e.type?.startsWith('image/svg') &&
                        e.type?.startsWith('image/')) ||
                        e.type?.startsWith('video/')),
            ),
        );
    }, [data]);

    const downloadMediaItemHandler = (event: MouseEvent<HTMLImageElement>) => {
        event.preventDefault();
        let targetMedia = document.querySelector(
            '.slick-active img, .slick-active.slick-current video source',
        ) as HTMLImageElement | HTMLSourceElement | null;

        if (!targetMedia) {
            return; // handle the case when targetMedia is null
        }
        const srcAttribute = targetMedia.getAttribute('src');
        const datanameAttribute = targetMedia.getAttribute('data-name');

        if (srcAttribute && datanameAttribute) {
            const downloadLink = downloadLinkRef.current as HTMLAnchorElement;

            downloadLink.setAttribute('href', srcAttribute);
            downloadLink.setAttribute('download', datanameAttribute);
            downloadLink.click();
        }
    };

    return {
        settings,
        mediaToShow,
        isCopiedPhoto,
        isImage,
        downloadLinkRef,
        copyBlobImageToClipboard,
        downloadMediaItemHandler,
    };
};
export default useMediaViewer;
