export function findDifferentObjects(array1, array2, key) {
    const result = [];

    // İlk array'deki objeleri kontrol edin
    for (const item1 of array1) {
        const id1 = item1[key];

        // İkinci array'de aynı _id'ye sahip bir obje var mı kontrol edin
        const foundInArray2 = array2.some(item2 => item2[key] === id1);

        // Bulunamazsa, bu objeyi sonuca ekleyin
        if (!foundInArray2) {
            result.push(item1);
        }
    }

    return result;
}