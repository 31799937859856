import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { postLoginApi } from '@/api/auth';
import { IUser } from '@/types/slices/UserTypes';

const initialState: { user: IUser } = {
    user: {} as IUser,
};

export const getUserData = createAsyncThunk<any, any>(
    'auth/userData',
    async payload => {
        const res = await postLoginApi(payload);
        return res.data;
    },
);

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addUserRoles: (state: any, { payload }) => {
            state.user.user_roles = payload;
        },
        updateUserData(state, action) {
            state.user = { ...state.user, ...action.payload };
        },
    },
    extraReducers: builder => {
        builder.addCase(getUserData.fulfilled, (state: any, { payload }) => {
            if (payload?.status) {
                state.user = payload.user;
                state.user.token = payload.token;
                state.user.nserver = payload.nserver.replace(/\\(.)/g, '$1');
            }
        });
    },
});

export const { updateUserData, addUserRoles } = UserSlice.actions;
export default UserSlice.reducer;
