import { t } from '@lingui/macro';
import { FC, memo, useState } from 'react';

import { useAppSelector } from '@/hooks/useRedux';
import { userPermissionSelector } from '@/store/server-data-reducer/selectors-server-data';

import classes from './ServerSection.module.css';
import ServerBanner from './server-banner/ServerBanner';
import ChannelsGroups from './server-channels/ChannelsGroups';
import ServerEditor from './server-editor/ServerEditor';
import NewChat from './server-editor/new-chat/NewChat';
import ShortProfile from './short-profile/ShortProfile';

type propsType = {
    onNewChat: (User: {}) => void;
};

const ServerSection: FC<propsType> = props => {
    const { onNewChat } = props;

    const user = useAppSelector(state => state.userReducer.user);
    const [newChat, setNewChat] = useState(false);
    const [unreadOnly, setUnreadOnly] = useState(false);

    const newChatEditorHandler = () => {
        setNewChat(true);
    };

    const submitNewChatHandler = usersArray => {
        onNewChat(usersArray[0]);
        setNewChat(false);
    };

    const permissions = useAppSelector(userPermissionSelector);

    const unreadChOnlyHandler = data => {
        setUnreadOnly(data);
    };

    const tabs = [
        t`Channel`,
        permissions?.['pvt_message.create'] ? t`Private` : undefined,
    ];

    return (
        <section className={classes['server-details-container']}>
            <ServerBanner />
            <ServerEditor
                onNewChat={newChatEditorHandler}
                onUnreadChOnly={unreadChOnlyHandler}
            />
            <ChannelsGroups unreadOnly={unreadOnly} />

            <ShortProfile name={user.name} username={user.username} />
            {newChat && (
                <NewChat
                    isForward={false}
                    onClose={() => {
                        setNewChat(false);
                    }}
                    tabs={tabs.filter(item => item !== undefined)}
                    tabsBody={[]}
                    onSubmitNewChat={submitNewChatHandler}
                />
            )}
        </section>
    );
};
export default memo(ServerSection);
