import { Trans, msg, t } from '@lingui/macro';
import React, { useEffect, useState } from 'react';

import { AppButton } from '@/UI';
import downloadIcon from '@/assets/icons/download.svg';
import { BUTTON_SIZE } from '@/constants/enum';

import classes from './VersionItem.module.css';

interface VersionItemProps {
    versionItem: {
        version: string;
        branch: string;
        description: object;
        dl_link: string;
    };
    isSelected: any;
    onSelect: (id?: any) => void;
    index: any;
}

const VersionItem: React.FC<VersionItemProps> = ({
    versionItem,
    isSelected,
    onSelect,
    index,
}) => {
    const versionType = [t`ALFA`, t`BETA`, t`RELEASE`];
    const [itemSelected, setItemSelected] = useState(isSelected);

    useEffect(() => {
        setItemSelected(isSelected);
    }, [isSelected]);

    useEffect(() => {
        if (!itemSelected) {
            onSelect();
        }
    }, [itemSelected]);

    const handleClick = (id: any) => {
        onSelect(id);
        setItemSelected(!itemSelected);
    };

    return (
        <div
            key={index}
            onClick={e => {
                e.stopPropagation();
                handleClick(index);
            }}
            className={`${
                isSelected
                    ? classes['version-item-active']
                    : classes['version-item']
            }`}>
            {true && (
                <div className={classes['new-container']}>
                    <h6 style={{ margin: 0 }}>
                        <Trans>New</Trans>
                    </h6>
                </div>
            )}

            <div className={classes['right-container']}>
                <div className={classes['date-size']}>
                    <div className={classes['version-type']}>
                        {versionType?.map((_, key) => (
                            <div
                                key={key}
                                className={classes['cell-container']}
                            />
                        ))}
                        <span className={classes['cell-text']}>
                            {versionItem?.branch}
                        </span>
                    </div>
                </div>
            </div>
            <div className={classes['text-container']}>
                <div style={{ marginLeft: '10px' }}>
                    <div className={classes['header-name']}>
                        <span className={classes['version-name-heading']}>
                            {versionItem?.version}
                        </span>
                    </div>
                    {isSelected && (
                        <>
                            <div style={{ marginTop: '20px' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                        }}>
                                        <Trans>Version</Trans>
                                    </span>
                                    <div style={{ marginLeft: 50 }}>
                                        <span>:</span>
                                        <span style={{ marginLeft: 10 }}>
                                            {versionItem?.version}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3>
                                    <Trans>Features</Trans>
                                </h3>
                                {versionItem?.description?.en
                                    ?.split('\n')
                                    .map((future, index) => (
                                        <div
                                            key={index}
                                            className={
                                                classes['version-container']
                                            }>
                                            <div
                                                className={
                                                    classes['small-points']
                                                }
                                            />
                                            <span
                                                className={
                                                    classes['feature-text']
                                                }>
                                                {future}
                                            </span>
                                        </div>
                                    ))}
                            </div>
                            <div
                                className={classes['download-a-container']}
                                style={{
                                    margin: '50px 0px',
                                }}>
                                <a download href={versionItem?.dl_link}>
                                    <AppButton
                                        size={BUTTON_SIZE.medium}
                                        title={t`Download`}
                                        rightIcon={
                                            <img
                                                alt={t`download`}
                                                src={downloadIcon}
                                                className={
                                                    classes['download-icon']
                                                }
                                            />
                                        }
                                    />
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VersionItem;
