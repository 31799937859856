import CryptoJS from 'crypto-js';

import { ButtonSize, ButtonType } from './enumTypes';

export const BUTTON_TYPE: Record<ButtonType, string> = {
    primary: 'primary',
    secondary: 'secondary',
    transparent: 'transparent',
    whiteWithBorder: 'whiteWithBorder',
};

export const BUTTON_SIZE: Record<ButtonSize, string> = {
    small: 'small',
    medium: 'medium',
    large: 'large',
};

export const LOCAL_STORAGE = {
    theme: 'theme',
    isUserLogged: 'isUserLogged',
    lang: 'i18nextLng',
};

export const SESSION_STORAGE = {
    devicePassword: CryptoJS.MD5('devicePassword').toString(),
};

export const ALL_PERMISSIONS = ['allPermissions'];

export const AUDIT_LOGS_TYPE = {
    BANNED: 'banned',
    UNBANNED: 'un_banned',
    CHANNEL_CREATE: 'channel_create',
    CHANNEL_DELETE: 'channel_delete',
    CHANNEL_EDIT: 'channel_edit',
    UPDATE_PRIORITY: 'update_priority',
    ROLE_CREATE: 'role_create',
    ROLE_DELETE: 'role_delete',
    ROLE_UPDATE: 'role_update',
    ADD_USER_IN_SECTION: 'add_user_in_section',
    SECTION_CREATE: 'section_create',
    SECTION_DELETE: 'section_delete',
    SECTION_EDIT: 'section_edit',
};

export const NOTIFICATION_TYPES = {
    WARNING: 'warning',
    ERROR: 'error',
    PRIMARY: 'primary',
    SUCCESS: 'success',
};

export const PLATFORM = {
    BROWSER: 'browser',
    DESKTOP: 'desktop',
};

export const INPUT_TYPES = {
    THREADINPUT: 'threadInput',
    NORMALINPUT: 'normalInput',
};

export const FETCHUSER_TYPES = {
    REMOVEUSER_INCHANNEL:'remove_user_in_channel'
}

export const MANAGEMENT_TYPES = {
    MEMBERMANAGEMENT: 'membermanagement',
    TABLEMANAGEMENT: 'tablemanagement',
};

export const CHANNEL_PERMISSIONS = {
    'channel.view': true,
    'message.create': true,
    'message.delete': true,
    'message.edit': true,
};
