import { ChangeEvent } from 'react';

import { Icon } from '@/UI';
import { ExternalBadge } from '@/UI/externalbadge/ExternalBadge';
import { truncateText } from '@/Utils/textUtils';
import { ReactComponent as CheckIcon } from '@/assets/icons/check.svg';
import { DEFAULT_IMAGES } from '@/constants';
import { IMemberData } from '@/types';

import classes from './ForwardNote.module.css';

interface IProps {
    checked: boolean;
    item: IMemberData;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
const MembersListItem: React.FC<IProps> = props => {
    const { item, onChange, checked } = props;
    return (
        <div className={classes['body-item-wrap']}>
            <li className={classes['body-item']}>
                <input
                    checked={checked}
                    value={item.id}
                    onChange={onChange}
                    type="checkbox"
                    className={classes['select']}
                />
                <div className={classes['index']}>
                    <CheckIcon />
                </div>
                <Icon
                    className={classes['person-badge']}
                    imageURL={
                        item.profile_picture || DEFAULT_IMAGES.PROFILE_IMAGE
                    }
                    name={item.name || 'User'}
                />
                {truncateText(item.name || '---', 25)}
                {item.external && <ExternalBadge />}
            </li>
        </div>
    );
};
export default MembersListItem;
