import { Trans, t } from '@lingui/macro';
import { useEffect, useRef } from 'react';
import { Spinner } from 'react-activity';

import { AppButton, Switch, TextInput } from '@/UI';
import closeIcon from '@/assets/icons/close-header.svg';
import { BUTTON_SIZE } from '@/constants/enum';

import classes from './MemberAddUser.module.css';
import useMemberAddUser from './useMemberAddUser';

function MemberAddUser(props: any) {
    const containerRef = useRef(null);

    const { isAddUserModal, setIsAddUserModal } = props.modalProps;

    const { loading, isAdmin, setIsAdmin, serverData, formik } =
        useMemberAddUser(props.modalProps);

    const _renderHeader = () => (
        <div className={classes['left-side-header']}>
            <h2>
                <Trans>Add Member</Trans>
            </h2>
            <div
                onClick={() => setIsAddUserModal(false)}
                className={classes['left-side-header-close']}>
                <img alt="" src={closeIcon} />
            </div>
        </div>
    );

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef, isAddUserModal]);

    return (
        <div ref={containerRef} className={classes['left-side-container']}>
            <div className={classes['left-side-inner-container']}>
                {_renderHeader()}
                <div className={classes.topContainer}>
                    <img
                        src={serverData.logo}
                        className={classes.logo}
                        alt="Server Logo"
                    />
                    <div className={classes.textContainer}>
                        <span className={classes.desc}>
                            <Trans>Create Member</Trans>
                        </span>
                        <b className={classes.title}>{serverData?.name}</b>
                    </div>
                </div>
                <form
                    autoComplete="new-password"
                    onSubmit={formik.handleSubmit}
                    className={classes['form-container']}>
                    <div className={classes['input-area']}>
                        <div className={classes['name-username']}>
                            <TextInput
                                title={t`Name`}
                                placeHolder={t`Name`}
                                name="name"
                                value={formik.values['name']}
                                onChange={formik.handleChange}
                                textStyle={{ width: '100%' }}
                                onBlur={formik.handleBlur}
                                error={formik.errors.name}
                            />
                            <TextInput
                                title={t`Username`}
                                placeHolder={t`Username`}
                                name="username"
                                value={formik.values['username']}
                                onChange={formik.handleChange}
                                textStyle={{ width: '100%' }}
                                onBlur={formik.handleBlur}
                                error={formik.errors.username}
                            />
                        </div>
                        <TextInput
                            title={t`Email`}
                            placeHolder={t`Email`}
                            name="email"
                            value={formik.values['email']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.email}
                        />
                        <TextInput
                            title={t`Password`}
                            placeHolder={t`Password`}
                            name="password"
                            value={formik.values['password']}
                            onChange={formik.handleChange}
                            type="password"
                            autoComplete="new-password"
                            onBlur={formik.handleBlur}
                            error={formik.errors.password}
                        />
                        <TextInput
                            title={t`Confirm Password`}
                            placeHolder={t`Confirm Password`}
                            name="confirm_password"
                            value={formik.values['confirm_password']}
                            onChange={formik.handleChange}
                            type="password"
                            autoComplete="new-password"
                            onBlur={formik.handleBlur}
                            error={
                                formik.values.password &&
                                formik.touched.confirm_password &&
                                formik.errors?.confirm_password
                            }
                        />
                        <label className={classes['external-switch']}>
                            <Trans>Admin</Trans>
                            <Switch
                                onChange={e => setIsAdmin(e)}
                                checked={isAdmin}
                            />
                        </label>
                        <label className={classes['external-switch']}>
                            <Trans>External</Trans>
                            <Switch
                                id="external"
                                onChange={e =>
                                    formik.setFieldValue('external', e)
                                }
                                checked={formik.values['external']}
                            />
                        </label>
                        <div className={classes.appBtnWrap}>
                            <AppButton
                                classStyles={classes['appButton']}
                                type="submit"
                                title={loading ? <Spinner /> : t`Save`}
                                size={BUTTON_SIZE.medium}
                                disable={loading}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default MemberAddUser;
