import { Trans, t } from '@lingui/macro';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext } from 'react';

import TabsManagement from '@/UI/TabsManagement/TabsManagement';
import addMembersSVG from '@/assets/icons/add-user-outline.svg';
import CustomErrorBoundary from '@/components/CustomErrorBoundary/CustomErrorBoundary';
import { MANAGEMENT_TYPES } from '@/constants/enum';

import classes from './MemberManagement.module.css';
import { MemberManagementContext } from './context/MemberManagementContext';
import MemberAddUser from './member-add-user/MemberAddUser';
import MemberProfileEdit from './member-profile-edit/MemberProfileEdit';
import MemberRoleEdit from './member-role-edit/MemberRoleEdit';
import TableMemberManagement from './table/TableMemberManagement';

const MemberManagementContent = () => {
    const {
        modalProps,
        tabsManagementProps,
        setIsAddUserModal,
        filteredMemberData,
        handleSortData,
        isEditModal,
        isRolesModal,
        isAddUserModal,
    } = useContext(MemberManagementContext);

    return (
        <CustomErrorBoundary>
            <div className={classes['container']}>
                <div className={classes['header']}>
                    <h2 style={{ margin: 0 }}>{t`Member Managment`}</h2>
                    <TabsManagement {...tabsManagementProps}>
                        <img
                            alt=""
                            src={addMembersSVG}
                            onClick={() => setIsAddUserModal(true)}
                        />
                    </TabsManagement>
                </div>
                <div
                    style={{
                        height: '100%',
                        overflow: 'hidden',
                    }}>
                    {filteredMemberData?.length > 0 ? (
                        <TableMemberManagement
                            onSort={handleSortData}
                            data={filteredMemberData}
                            modalProps={modalProps}
                            managementType={MANAGEMENT_TYPES.TABLEMANAGEMENT}
                            className={classes['membersTabel']}
                        />
                    ) : (
                        <div
                            style={{
                                margin: '0px auto',
                                width: 'fit-content',
                                textAlign: 'center',
                                fontSize: 12,
                            }}>
                            <b>
                                <Trans>No Result Found</Trans>!
                            </b>
                        </div>
                    )}
                </div>
                <AnimatePresence>
                    {isEditModal && (
                        <motion.div
                            className={classes['side-modal']}
                            transition={{ duration: 0.2 }}
                            initial={{
                                x: '100%',
                            }}
                            animate={{ x: 0 }}
                            exit={{ x: '100%' }}>
                            <MemberProfileEdit
                                inputAreaClassName={
                                    classes['server-members-form-inputs']
                                }
                                modalProps={modalProps}
                            />
                        </motion.div>
                    )}
                    {isRolesModal && (
                        <motion.div
                            transition={{ duration: 0.2 }}
                            initial={{
                                x: '100%',
                            }}
                            animate={{ x: 0 }}
                            className={classes['motion-wrap']}
                            exit={{ x: '100%' }}>
                            <MemberRoleEdit modalProps={modalProps} />
                        </motion.div>
                    )}
                    {isAddUserModal && (
                        <motion.div
                            className={classes['right-motion-container']}
                            transition={{ duration: 0.2 }}
                            initial={{
                                x: '100%',
                            }}
                            animate={{ x: 0 }}
                            exit={{ x: '100%' }}>
                            <MemberAddUser modalProps={modalProps} />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </CustomErrorBoundary>
    );
};

export default MemberManagementContent;
