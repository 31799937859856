import { Spinner } from 'react-activity';

import profileUsers from '@/assets/icons/profile-user.svg';
import { useAppSelector } from '@/hooks/useRedux';
import { memberDataSelector } from '@/store/server-data-reducer/selectors-server-data';

const OfflineMembersCount: React.FC<{ membersSelector: any }> = ({
    membersSelector,
}) => {
    const memberCountLoader = useAppSelector(
        state => state.utilReducer.memberCountLoader,
    );

    const members = membersSelector.filter(item => !item?.banned).length;

    return (
        <div>
            <img src={profileUsers} alt="profile-users" />
            {!members && memberCountLoader ? (
                <Spinner size={8} />
            ) : (
                members ?? 0
            )}
        </div>
    );
};
export default OfflineMembersCount;
